<form [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Relationship Manager" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item" *ngIf="district.inheritedRelationshipManager">
			<div class="property-block__value property-block__value--no-label">
				<label class="checkbox u-m-b-0">
					<input type="checkbox" class="checkbox__input" formControlName="inheritRelationshipManager" opusAuthorize="District.EditInheritManager" opusAuthorizeAction="disable">
					Inherit Manager
				</label>
			</div>
		</li>
		<ng-container *ngIf="!editor.value.inheritRelationshipManager">
			<li class="property-block__list-item">
				<label for="editManagerId" class="property-block__label">Manager ID</label>
				<div class="property-block__value">
					<div class="input-group u-m-b-0">
						<input type="text" id="editManagerId" class="input-group-field" formControlName="relationshipManagerCode" opusAuthorize="District.EditRelationshipManager" opusAuthorizeAction="disable">
						<div class="input-group-button">
							<button type="button" [disabled]="inLocationLookup" (click)="onApplyManagerId()" opusAuthorize="District.EditRelationshipManager" opusAuthorizeAction="disable">Apply</button>
						</div>
					</div>
					<ul class="error" *ngIf="locationLookupError">
						<li class="error__message" *ngIf="locationLookupError">{{ locationLookupError }}</li>
					</ul>
				</div>
			</li>
			<li class="property-block__list-item">
				<div class="property-block__label">
					<label for="editManagerName">Manager Name</label>
					<p class="typography--caption u-m-b-0" id="editManagerNameDescription">
						Start typing the name of the manager.
					</p>
				</div>
				<div class="property-block__value">
					<span data-toggle="managerNameTypeAhead">
						<input type="text" class="input-group-field" id="editManagerName" autocomplete="off" (keyup)="lookupManagerByName($event)" (keydown)="$event.stopPropagation()" formControlName="managerName" role="combobox" opusAuthorize="District.EditRelationshipManager" opusAuthorizeAction="disable">
					</span>
					<ul class="dropdown-menu" role="listbox" id="managerNameTypeAhead" #managerDropdown data-close-on-click="true">
						<li *ngFor="let manager of managerByNameLookup?.managers">
							<button type="button" class="dropdown-menu__action" role="option" data-close (click)="selectManager(manager)">
								<span class="l-block">{{ manager.manager }} ({{ manager.code }})</span>
							</button>
						</li>
					</ul>
				</div>
			</li>
		</ng-container>
		<ng-container *ngIf="editor.value.inheritRelationshipManager">
			<li class="property-block__list-item">
				<div class="property-block__label" id="managerId">Manager ID</div>
				<div class="property-block__value" role="definition" aria-labelledby="managerId">{{ district.inheritedRelationshipManager.locationCode }}</div>
			</li>
			<li class="property-block__list-item">
				<div class="property-block__label" id="managerName">Manager Name</div>
				<div class="property-block__value" role="definition" aria-labelledby="managerName">{{ district.inheritedRelationshipManager.name }}</div>
			</li>
		</ng-container>
		<li class="property-block__list-item">
			<label for="editRelationshipType" class="property-block__label">Relationship Type</label>
			<div class="property-block__value">
				<label class="checkbox u-m-b-8" *ngIf="district.inheritedRelationshipType">
					<input type="checkbox" class="checkbox__input" formControlName="inheritRelationshipType" opusAuthorize="District.EditInheritRelationshipType" opusAuthorizeAction="disable">
					Inherit Relationship Type
				</label>
				<ng-container *ngIf="!editor.value.inheritRelationshipType">
					<select id="editRelationshipType" formControlName="relationshipType" opusAuthorize="District.EditRelationshipType" opusAuthorizeAction="disable">
						<option value="0">Unknown</option>
						<option value="1">Exclusive: Competitor(s)</option>
						<option value="2">Exclusive: M&A</option>
						<option value="3">Competitive: Select Vendors</option>
						<option value="4">Competitive: Wide Open</option>
					</select>
				</ng-container>
				<ng-container *ngIf="editor.value.inheritRelationshipType">
					<div class="property-block__value" role="definition" aria-labelledby="relationshipType">{{ inheritedRelationshipType }}</div>
				</ng-container>
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
