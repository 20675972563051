  <!-- always display -->
<div class="property-block__content-wrapper">
    <div class="property-block__content">
        <div class="table table--align-left" role="table">
            <div role="rowgroup">
                <div class="table__row table__row--header" role="rowheader">
                    <span class="table__cell table__cell--shrink" role="columnheader" *ngIf="editMode">
                        <span class="sr-only">Remove</span>
                    </span>
                    <span class="table__cell table__cell--half" role="columnheader">Teacher ID</span>
                    <span class="table__cell table__cell" role="columnheader">Teacher Name</span>
                    <span class="table__cell table__cell--half table__cell--align-center" role="columnheader">Primary</span>
                </div>
            </div>
            <div role="rowgroup">
                <div class="table__row table__row--align-middle table__row--last table__row--alt" role="row" *ngFor="let teacher of program.programTeachers">
                    <span class="table__cell table__cell--shrink table__cell--no-padding" role="cell" *ngIf="editMode">
                        <button class="btn--delete" title="Remove teacher" (click)="removeTeacher(teacher)">
                            <!--ng-click="ProgramTeachersAttendingCtrl.removeTeacher($index)"-->
                            <span class="sr-only">Remove teacher</span>
                        </button>
                    </span>
                    <span class="table__cell table__cell--half" role="cell">{{teacher.teacherId}}</span>
                    <span class="table__cell table__cell" role="cell">
                        <a class="link--tappable" routerLink="/teachers/{{ teacher.teacherId }}">{{teacher.teacherName}}</a>
                    </span>
                    <span class="table__cell table__cell--half table__cell--align-center" role="cell">
                        <span class="radio radio--no-label">
                            <input name="primary" type="radio" class="radio__input" (change)="isPrimaryTeacher(teacher)" [checked]="teacher.isPrimary ? 'checked' : ''" disabled="{{ editMode ? '' : 'disabled' }}" />
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /always display -->