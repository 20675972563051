import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Message, MessageType } from '../models';
import * as fromStore from '../store';

@Component({
    selector: 'opus-message',
    templateUrl: './message.component.html',
})
export class MessageComponent implements OnInit {
    isActive = true;
	messageType = 'messages--info';
    message: Message;
    message$: Observable<fromStore.MessageState>;

    constructor(private store: Store<fromStore.CoreState>, private ref: ChangeDetectorRef) {
        this.message$ = this.store.pipe(select(fromStore.getMessageState));
        this.message$.subscribe(m => {
            this.message = m.message;

            if (this.message) {
                this.messageType = 'messages--' + MessageType[this.message.messageType];
                this.ref.detectChanges();
            }

            if (this.message && this.message.toast) {
                setTimeout(() => {
                    this.store.dispatch(new fromStore.ClearMessage());
                    this.ref.detectChanges();
                }, 3000);
            }
        }
        );
    }

    ngOnInit() {

    }

    action() {
        this.store.dispatch(new fromStore.ClearMessage());
    }

}

