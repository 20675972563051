import { Params, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ActionReducerMap, createFeatureSelector, ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import { env } from '../../../../environments/environment';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */
export interface RouterStateUrl {
	url: string;
	queryParams: Params;
	params: Params;
}

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('routerReducer');

@Injectable()
export class CustomRouterStateSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
	serialize(routerState: RouterStateSnapshot): RouterStateUrl {
		const { url } = routerState;
		const { queryParams } = routerState.root;

		let state: ActivatedRouteSnapshot = routerState.root;
		while (state.firstChild) {
			state = state.firstChild;
		}
		const { params } = state;

		return { url, queryParams, params };
	}
}

export interface State {
	routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}
/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
	routerReducer: fromRouter.routerReducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
	return function (state: State, action: any): State {
		console.log('state', state);
		console.log('action', action);

		return reducer(state, action);
	};
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !env.production
	? [logger]
	: [];

//CONFIGURATION DETAILS

import * as fromConfigReducer from './configuration.reducer';
import * as fromMessageReducer from './message.reducer';
import * as fromSecurityReducer from './security.reducer';

export interface CoreState {
    config: fromConfigReducer.ConfigurationState;
    message: fromMessageReducer.MessageState;
    security: fromSecurityReducer.SecurityState;
}

export const coreReducers: ActionReducerMap<CoreState> = {
    config: fromConfigReducer.reducer,
    message: fromMessageReducer.reducer,
    security: fromSecurityReducer.reducer,
};

export const getCoreState = createFeatureSelector<CoreState>('core');

import { createSelector } from '@ngrx/store';
import { Injectable } from "@angular/core";

export const getConfigurationState = createSelector(
    getCoreState,
    (state: CoreState) => state.config
);

export const getMessageState = createSelector(
    getCoreState,
    (state: CoreState) => state.message
);

export { ConfigurationState } from './configuration.reducer';
export { MessageState } from './message.reducer';
export { SecurityState } from './security.reducer';
