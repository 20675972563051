import { Component, Input } from '@angular/core';

import { TeacherDetails } from '../../models';

@Component({
	selector: 'opus-teacher-district',
	templateUrl: './teacher-district.component.html',
})
export class TeacherDistrictComponent {
	@Input() teacher: TeacherDetails;

    get districtId() {
        return this.teacher.districtId;
    }

    get districtName() {
        return this.teacher.districtName;
    }
}
