import { Component, Input } from '@angular/core';

import { District } from '../../models';

@Component({
	selector: 'opus-district-calendar',
	templateUrl: './district-calendar.component.html',
})
export class DistrictCalendarComponent {
	@Input() district: District;
	monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	get schoolStart() {
		return this.getDateRangeText(this.district.schoolStartFrom, this.district.schoolStartTo);
	}

	get schoolEnd() {
		return this.getDateRangeText(this.district.schoolEndFrom, this.district.schoolEndTo);
	}

	private getDate(s: string): Date {
		const ms: number = Date.parse(s);

		if (isNaN(ms)) {
			return null;
		}

		return new Date(ms);
	}

	private getDateText(d: Date): string {
		return d === null ? 'Unspecified' : `${this.monthNames[d.getMonth()]} ${d.getDate().toString()}`;
	}

	private getDateRangeText(from: string, to: string): string {
		const fromDate = this.getDate(from);
		const toDate = this.getDate(to);

		if (fromDate === null && toDate === null) {
			return '';
		}

		return `${this.getDateText(fromDate)} - ${this.getDateText(toDate)}`;
	}
}

