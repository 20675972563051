import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';

import { ProductSearchResult } from '../../preferences/models';
import { FindCriteria, FindType } from '../../preferences/store/reducers';

@Injectable()
export class ProductService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

    findProducts(criteria: FindCriteria): Observable<ProductSearchResult[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				const params = this.getFindParams(criteria);

				return this.http.get<ProductSearchResult[]>(`${c.config.apiServer}api/product/search`, { params });
			})
		);
	}

	private getFindParams(criteria: FindCriteria) {
		let params = new HttpParams();
		let find;

		switch (criteria.findType) {
			case FindType.Book:
				find = criteria.bookFind;
				break;

			case FindType.Group:
				find = criteria.groupFind;
				break;

			case FindType.Keyword:
				find = criteria.keywordFind;
				break;
		}

		if (find) {
			Object.keys(find).forEach(k => {
				if (find[k] !== null) {
					params = params.append(k, find[k]);
				}
			});
		}

		return params;
	}
}
