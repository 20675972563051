import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import { TeacherDetails } from '../models';

@Component({
	selector: 'opus-school-page',
	templateUrl: './teacher-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherDetailsComponent implements OnInit, AfterViewInit {
	teacherDetails$: Observable<TeacherDetails>;
	panels$: Observable<{ [id: string]: fromStore.PanelState }>;
	scrollTo: Element = null;

	constructor(private store: Store<fromStore.TeachersState>, private router: Router) {
		router.events.subscribe(s => {
			if (s instanceof NavigationEnd) {
				const tree = router.parseUrl(router.url);

				if (tree.fragment) {
					const element = document.querySelector('#' + tree.fragment);

					if (element) {
						this.scrollTo = element;
						element.scrollIntoView();
					}
				}
			}
		});
}

	ngOnInit() {
		this.teacherDetails$ = this.store.pipe(select(fromStore.getTeacherDetailsEntity));
		this.panels$ = this.store.pipe(select(fromStore.getDetailsStatePanels));
	}

	ngAfterViewInit() {
		if (this.scrollTo) {
			console.log('scrollIntoView: ', this.scrollTo);

			this.scrollTo.scrollIntoView();
			this.scrollTo = null;
		}
	}

	onToggleEditor(key: string, open: boolean) {
		this.store.dispatch(new fromStore.UpdateEditorState({ key, open }));
	}
}
