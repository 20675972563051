import { Component, Input } from '@angular/core';

@Component({
	selector: 'opus-history-header',
	templateUrl: './history-header.component.html',
})
export class HistoryHeaderComponent {
	@Input() context;
}

