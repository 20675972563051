<form aria-label="Contacts" [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editPhone" class="property-block__label">Phone</label>
			<div class="property-block__value">
				<input type="text" id="editPhone" formControlName="phone">
				<div class="error" *ngIf="editor.controls.phone.invalid && (editor.controls.phone.dirty || editor.controls.phone.touched)">
					<div class="error__message" *ngIf="editor.controls.phone.hasError('required')">Phone is required for this type of school.</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editFax" class="property-block__label">Fax</label>
			<div class="property-block__value">
				<input type="text" id="editFax" formControlName="fax">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editWebsite" class="property-block__label">Website</label>
			<div class="property-block__value">
				<input type="text" id="editWebsite" formControlName="website">
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
