import { RouterEffects } from './router.effect';
import { ConfigurationEffects } from './configuration.effects';
import { SecurityEffects } from './security.effects';
import { OidcEffects } from './oidc.effects';

export const effects: any[] = [RouterEffects, OidcEffects];
export const coreEffects: any[] = [ConfigurationEffects, SecurityEffects];

export * from './router.effect';
export * from './configuration.effects';
export * from './security.effects';
export * from './oidc.effects';
