import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError, map, mergeMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';


@Injectable()
export class ProgramDetailsExistsGuard implements CanActivate {
  constructor(private _store: Store<fromStore.ProgramsState>) {
    console.log('ProgramDetailsExistsGuard()...');
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    //	get the id of the program we need to load from the route
    const id = +route.paramMap.get('id');
    //	check for a specific redirect route to use if the program isn't found
    const redirectTo = !!route.data.redirectTo ? route.data.redirectTo : '/';

    console.log(`ProgramDetailsExistsGuard: canActivate called for program id: ${id}...`);

    //	this will attempt to load the program by dispatching the appropriate action then either redirect or allow the activation
    return this.checkStore(id).pipe(
      switchMap(found => {
        console.log(`ProgramDetailsExistsGuard: checkStore returned: ${found ? 'Found' : 'Not Found'}.`);

        return this.allowOrRedirect(found, redirectTo);
      }),
      catchError(err => {
        console.log(`ProgramDetailsExistsGuard: activation failed for program ${id}.  Error: `, err);

        return this.allowOrRedirect(false, redirectTo);
      }),
    );
  }

  checkStore(id: number): Observable<boolean> {
    return this._store.select(fromRoot.getUserAuthInfo).pipe(
      filter(x => !!x),
      mergeMap(x => {

        if (!x.policyCodes.has('Program.ViewDetails')) return of(false);

        //	start with the program details state...
        return this._store.select(fromStore.getProgramDetailsState).pipe(
          //	if there aren't program details, or they are for a different program, dispatch an action to load the program
          tap(state => {
            if ((state.programDetails === null || state.programDetails.id !== id || !state.loaded) && !state.loading && state.errors === null) {
              console.log(`ProgramDetailsExistsGuard: Loading program ${id}.  Current Program Details state: `, state);
              this._store.dispatch(new fromStore.LoadProgramDetails(id));
            }
          }),
          //	wait for it to be in the loaded state for the program in question or for any errors
          filter(state => (state.loaded && state.programDetails.id == id) || state.errors !== null),
          //	map it to a boolean observable
          map(state => state.loaded),
          //	get the first result & complete
          take(1),
        );

      })
    );
  }

  allowOrRedirect(found: boolean, redirectTo: string): Observable<boolean> {
    if (!found) {
      console.log(`Redirecting to '${redirectTo}'...`);
      this._store.dispatch(new fromRoot.Go({ path: [redirectTo] }));
    }

    return of(found);
  }
}
