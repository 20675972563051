<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="active">Active</div>
        <div class="property-block__value" role="definition" aria-labelledby="active">{{ active }}</div>
    </li>

    <li class="property-block__list-item" *ngIf="!activeBit">
        <div class="property-block__label" id="teacherId">Inactive Reason</div>
        <div class="property-block__value" role="definition">{{ inactiveReason }}</div>
    </li>

    <li class="property-block__list-item" *ngIf="!activeBit && inactiveReasonIsDuplicate">
        <div class="property-block__label" id="teacherId">Primary ID</div>
        <div class="property-block__value" role="definition">{{ duplicateTeacherId }}</div>
    </li>

    <li class="property-block__list-item">
        <div class="property-block__label" id="teacherId">Teacher ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="teacherId">{{ id }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="teacherName">Teacher Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="teacherName">{{ name }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="gender">Gender</div>
        <div class="property-block__value" role="definition" aria-labelledby="gender">{{ gender }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="teacherType">Type</div>
        <div class="property-block__value" role="definition" aria-labelledby="teacherType">{{ schoolType }}</div>
    </li>
</ul>