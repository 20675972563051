import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';

export function ValidateCurrentDateOrLater(control: AbstractControl) {
  let date: Date;

  if (typeof control.value === 'object') {
    date = new Date(control.value);
  } else {
    date = new Date(control.value + ' 23:59:59');
  }

  const nowDate = new Date(new Date().toDateString());
  const now = moment(nowDate);

  console.log(nowDate);
  console.log(date);

  if (now.isSameOrAfter(date)) {
    return { validCurrentDate: true };
  }
  return null;
}
