<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="phone">Phone</div>
        <div class="property-block__value" role="definition" aria-labelledby="phone">{{ phone }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="fax">Fax</div>
        <div class="property-block__value" role="definition" aria-labelledby="fax">{{ fax }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="email">Email Address</div>
        <div class="property-block__value" role="definition" aria-labelledby="email"><a href="mailto:{{ email }}">{{ email }}</a></div>
    </li>
</ul>