import { Component, Input, Output, EventEmitter } from '@angular/core';

import { HistoryEvent } from '../models/history.model';

@Component({
	selector: 'opus-history-sidebar',
	templateUrl: './history-sidebar.component.html',
})
export class HistorySidebarComponent {
	@Input() events;
	@Output() selectEvent = new EventEmitter<HistoryEvent>();

	selectedEvent: HistoryEvent;

	onSelectEvent(event) {
		console.log('HistorySidebarComponent.onSelectEvent(): ', event);

		this.selectedEvent = event;
		this.selectEvent.emit(event);
	}
}

