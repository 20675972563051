import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';

import { InstrumentRate } from '../../preferences/models';

@Injectable()
export class RatePackageService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	getByAgreementId(id: number): Observable<InstrumentRate[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<InstrumentRate[]>(`${c.config.apiServer}api/rentalpackage/agreement/${id}`);
			})
		);
	}

	getByZip(zip: string): Observable<InstrumentRate[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<InstrumentRate[]>(`${c.config.apiServer}api/rentalpackage/zipcode/${zip}`);
			})
		);
	}
}
