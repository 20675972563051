<div class="search-results">
	<p class="nav-back" *ngIf="!embeddedVersion">
		<a class="nav-back__link" routerLink="/search/programs">Back to Search</a>
	</p>
	<div class="card">
		<div class="card__section">
			<div class="row align-bottom">
				<div class="columns">
					<h1 class="typography--display1" *ngIf="!embeddedVersion">Programs</h1>
					<h1 class="card__title card__title--large" *ngIf="embeddedVersion">Programs</h1>
				</div>
				<div class="columns shrink">
					<button class="btn btn--hollow btn--add" type="button" data-open="createProgram" (click)="createProgram()" opusAuthorize="Program.Create">Add Program</button>
				</div>
			</div>
		</div>
		<div class="card__section">
			<div class="display-options">
				<div class="display-options__count">
					Showing {{(searchResultsState$ | async).pagingSorting.pageSize * ((searchResultsState$ | async).pagingSorting.currentPage - 1) + 1}} -
					{{(searchResultsState$ | async).pagingSorting.pageSize > (searchResultsState$ | async).pagingSorting.totalCount ? (searchResultsState$ | async).pagingSorting.totalCount : (searchResultsState$ | async).pagingSorting.pageSize * (searchResultsState$ | async).pagingSorting.currentPage}} of
					{{(searchResultsState$ | async).pagingSorting.totalCount}}
				</div>
				<label class="display-options__per-page">
					Show
					<select (change)="setPageSize($event.target.value)">
						<option value="15" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 15">15</option>
						<option value="25" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 25">25</option>
						<option value="50" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 50">50</option>
						<option value="100" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 100">100</option>
						<option value="200" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 200">200</option>
					</select>
				</label>
				<!--<input type="text" class="display-options__filter" placeholder="Type to filter..." aria-label="Type to filter">-->
				<div class="display-options__filter"></div>
				<div class="display-options__pagination">
					<ul class="pagination" role="navigation" aria-label="Pagination">
						<!--<li class="pagination__previous disabled">Previous<span class="show-for-sr"> page</span></li>-->
						<li class="pagination__previous" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage <= 1">
							{{ (searchResultsState$ | async).pagingSorting.currentPage <= 1 ? "Previous" : "" }}
							<a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage > 1" aria-label="Previous page" (click)="prevPage()">Previous<span class="show-for-sr"> page</span></a>
						</li>
						<li class="pagination__current">
							<span class="show-for-sr">You are on page </span>
							{{(searchResultsState$ | async).pagingSorting.currentPage}}
						</li>
						<li aria-hidden="true">of</li>
						<li><a aria-label="Page 10" (click)="lastPage()">{{(searchResultsState$ | async).pagingSorting.totalPages}}</a></li>
						<li class="pagination__next" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages">
							{{ (searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages ? "Next" : "" }}
							<a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage < (searchResultsState$ | async).pagingSorting.totalPages" aria-label="Next page" (click)="nextPage()">Next<span class="show-for-sr"> page</span></a>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="full-page-spinner" [class.full-page-spinner--is-active]="(searchResultsState$ | async).loading">
			<div class="full-page-spinner__icon"></div>
		</div>

		<div class="card__section">
			<div class="table" role="table">
				<div role="rowgroup">
					<div class="table__row table__row--header" role="row">
						<span class="table__cell table__cell--align-left table__cell--half" role="columnheader">
							<button class="table__sorter-btn"
									(click)="sortBy('Id')"
									[class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Id' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
									[class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Id' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
								ID
							</button>
						</span>
						<span class="table__cell table__cell--align-left" role="columnheader">
							<button class="table__sorter-btn table__sorter-btn--sorted-asc"
									(click)="sortBy('School')"
									[class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'School' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
									[class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'School' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
								School
							</button>
						</span>
						<span class="table__cell table__cell--align-left" role="columnheader">
							<button class="table__sorter-btn"
									(click)="sortBy('Teacher')"
									[class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Teacher' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
									[class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Teacher' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
								Teacher
							</button>
						</span>
						<span class="table__cell table__cell--align-left" role="columnheader">
							<button class="table__sorter-btn"
									(click)="sortBy('Program')"
									[class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Program' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
									[class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Program' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
								Program
							</button>
						</span>
						<span class="table__cell table__cell--half" role="columnheader" title="Relationship Manager Location ID">
							<span class="table__sorter-btn table__sorter-btn--text">
								Rel. Mgr.
							</span>
						</span>
						<span class="table__cell table__cell--half" role="columnheader">
							<button class="table__sorter-btn"
									(click)="sortBy('Active')"
									[class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Active' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
									[class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Active' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
								Active
							</button>
						</span>
					</div>
				</div>
				<div role="rowgroup">
					<a class="table__row" role="row" *ngFor="let program of (searchResultsState$ | async).searchResults; odd as isOdd; last as isLast"
						 [class.table__row--alt]="isOdd"
                         [class.table__row--last]="isLast"
						 routerLink="/programs/{{ program.id }}" opusAuthorize="Program.ViewDetails">
                        <span class="table__cell table__cell--align-left table__cell--half" role="cell">{{ program.id }}</span>
						<span class="table__cell table__cell--align-left" role="cell">{{ program.school }}</span>
						<span class="table__cell table__cell--align-left" role="cell">{{ program.teacher }}</span>
						<span class="table__cell table__cell--align-left" role="cell">{{ program.program }}</span>
						<span class="table__cell table__cell--half" role="cell">{{ program.relationshipManager }}</span>
						<span class="table__cell table__cell--half" role="cell">{{ program.active }}</span>
					</a>
				</div>
			</div>
		</div>
		<div class="card__section">
			<div class="display-options">
				<div class="display-options__count">
					Showing {{(searchResultsState$ | async).pagingSorting.pageSize * ((searchResultsState$ | async).pagingSorting.currentPage - 1) + 1}} -
					{{(searchResultsState$ | async).pagingSorting.pageSize > (searchResultsState$ | async).pagingSorting.totalCount ? (searchResultsState$ | async).pagingSorting.totalCount : (searchResultsState$ | async).pagingSorting.pageSize * (searchResultsState$ | async).pagingSorting.currentPage}} of
					{{(searchResultsState$ | async).pagingSorting.totalCount}}
				</div>
				<label class="display-options__per-page">
					Show
					<select (change)="setPageSize($event.target.value)">
						<option value="15" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 15">15</option>
						<option value="25" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 25">25</option>
						<option value="50" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 50">50</option>
						<option value="100" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 100">100</option>
						<option value="200" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 200">200</option>
					</select>
				</label>
				<!--<input type="text" class="display-options__filter" placeholder="Type to filter..." aria-label="Type to filter">-->
				<div class="display-options__filter"></div>
				<div class="display-options__pagination">
					<ul class="pagination" role="navigation" aria-label="Pagination">
						<!--<li class="pagination__previous disabled">Previous<span class="show-for-sr"> page</span></li>-->
						<li class="pagination__previous" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage <= 1">
							{{ (searchResultsState$ | async).pagingSorting.currentPage <= 1 ? "Previous" : "" }}
							<a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage > 1" aria-label="Previous page" (click)="prevPage()">Previous<span class="show-for-sr"> page</span></a>
						</li>
						<li class="pagination__current">
							<span class="show-for-sr">You are on page </span>
							{{(searchResultsState$ | async).pagingSorting.currentPage}}
						</li>
						<li aria-hidden="true">of</li>
						<li><a aria-label="Page 10" (click)="lastPage()">{{(searchResultsState$ | async).pagingSorting.totalPages}}</a></li>
						<li class="pagination__next" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages">
							{{ (searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages ? "Next" : "" }}
							<a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage < (searchResultsState$ | async).pagingSorting.totalPages" aria-label="Next page" (click)="nextPage()">Next<span class="show-for-sr"> page</span></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<opus-program-create [visible]="showModal" [school]="school" [teacher]="teacher" [activeProgramTypes]="activeProgramTypes" (action)="createProgramClosed($event)"></opus-program-create>
