<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="ncesDistrictId">NCES District ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="ncesDistrictId">{{ district.ncesDistrictId }}</div>
	</li>
	<!--<li class="property-block__list-item">
		<div class="property-block__label" id="ncesIdSource">NCES ID Source</div>
		<div class="property-block__value" role="definition" aria-labelledby="ncesIdSource">{{ district.ncesIdSource }}</div>
	</li>-->
	<li class="property-block__list-item">
		<div class="property-block__label" id="budgetEnd">Budget End</div>
		<div class="property-block__value" role="definition" aria-labelledby="budgetEnd">{{ budgetEnd }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="latitude">Latitude</div>
		<div class="property-block__value" role="definition" aria-labelledby="latitude">{{ district.latitude }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="longitude">Longitude</div>
		<div class="property-block__value" role="definition" aria-labelledby="longitude">{{ district.longitude }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="timezone">Time Zone</div>
		<div class="property-block__value" role="definition" aria-labelledby="timezone">{{ district.ianaTimeZoneId }}</div>
	</li>
</ul>
