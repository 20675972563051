import * as fromScp from '../actions';
import * as fromModels from '../../models';

export interface LocationManagerState {
	pending: boolean;
	manager: fromModels.LocationManager;
	error: any;
}

export interface ManagerByNameLookupState {
    name: string;
    pending: boolean;
    managers: fromModels.LocationManager[];
    errors: any;
}

export interface LocationsState {
	locationManagers: { [code: string]: LocationManagerState };
	managerByNameLookup: ManagerByNameLookupState;
}

export const initialLookupState: ManagerByNameLookupState = {
    name: null,
    pending: false,
    managers: null,
    errors: null
};

export const initialState: LocationsState = {
    locationManagers: {},
    managerByNameLookup: initialLookupState
};

export const initialLocationManagerState: LocationManagerState = {
	pending: false,
	manager: null,
	error: null,
};

export function reducer(state = initialState, action: fromScp.ScpActions): LocationsState {
	switch (action.type) {
		case fromScp.ScpActionTypes.GetLocation: {
			const { code } = action.payload;
			const managerState: LocationManagerState = state.locationManagers[code] ? state.locationManagers[code] : initialLocationManagerState;

			return {
				...state,
				locationManagers: {
					...state.locationManagers,
					[code]: {
						...managerState,
						pending: true,
					},
				},
			};
		}

		case fromScp.ScpActionTypes.GetLocationFailure: {
			const { code, error } = action.payload;

			return {
				...state,
				locationManagers: {
					...state.locationManagers,
					[code]: {
						pending: false,
						manager: null,
						error,
					},
				},
			};
		}

		case fromScp.ScpActionTypes.GetLocationSuccess: {
			const { code, manager } = action.payload;

			return {
				...state,
				locationManagers: {
					...state.locationManagers,
					[code]: {
						pending: false,
						manager,
					},
				},
			};
        }

        case fromScp.ScpActionTypes.GetManagersByName: {
            const { name } = action.payload;
            const managerByNameLookup: ManagerByNameLookupState = {
                name,
                pending: true,
                errors: null,
                managers: null,
            };

            return {
                ...state,
                managerByNameLookup,
            };
        }

        case fromScp.ScpActionTypes.GetManagersByNameFailure: {
            const { errors } = action.payload;
            const managerByNameLookup: ManagerByNameLookupState = {
                ...state.managerByNameLookup,
                pending: false,
                errors,
                managers: null,
            };

            return {
                ...state,
                managerByNameLookup,
            };
        }

        case fromScp.ScpActionTypes.GetManagersByNameSuccess: {
            const { managers } = action.payload;
            const managerByNameLookup: ManagerByNameLookupState = {
                ...state.managerByNameLookup,
                pending: false,
                managers,
            };

            return {
                ...state,
                managerByNameLookup,
            };
        }
	}

	return state;
}
