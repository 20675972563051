import { Component, Input } from '@angular/core';

import { TeacherDetails } from '../../models';
import { SchoolType } from '../../../schools/models';

@Component({
	selector: 'opus-teacher-additional',
	templateUrl: './teacher-additional.component.html',
})
export class TeacherAdditionalComponent {
	@Input() teacher: TeacherDetails;

    get externalSystemId() {
        return this.teacher.externalSystemId;
    }

    get bio() {
        return this.teacher.bio;
    }

    get education() {
        return this.teacher.education;
    }

    get instruments() {
        return this.teacher.instruments;
    }

}
