import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, RequiredValidator } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { TeacherDetails } from '../models';
import * as fromStore from '../store';
import { PrefixLookup, SuffixLookup } from '../store/reducers/teacher-details.reducer';

@Component({
	selector: 'opus-teacher-info-editor',
	templateUrl: './teacher-info-editor.component.html',
})
export class TeacherInfoEditorComponent implements OnInit, OnDestroy, OnChanges {
	@Input() teacher: TeacherDetails;

	alive = true;
	panelState$: Observable<fromStore.PanelState>;
	panelState: fromStore.PanelState;
	editor: FormGroup;

	constructor(private store: Store<fromStore.TeachersState>, private fb: FormBuilder) {
		console.log('TeacherInfoEditorComponent()...');

		this.createForm();
	}

	ngOnInit() {
		console.log('TeacherInfoEditorComponent.ngOnInit()');

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateInfoPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('TeacherInfoEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(ps => {
			console.log('panelState$ sub: ', ps);
			this.panelState = ps;
		});
	}

	ngOnDestroy() {
		console.log('TeacherInfoEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	ngOnChanges() {
		this.resetForm();
	}

    createForm() {
		this.editor = this.fb.group({
			active: ['Yes'],
            inactiveReason: [0],
            duplicateTeacherId: [''],
            prefix: ['notset'],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            suffix: ['notset'],
            gender: ['', Validators.required],
            schoolType: ['notset', Validators.pattern(/^(?!.*notset).*$/)],
		});
	}

	resetForm() {
		console.log('resetForm()...');

        this.editor.reset({
            active: this.teacher.active,
            inactiveReason: this.teacher.inactiveReason == null ? 0 : this.teacher.inactiveReason,
            duplicateTeacherId: this.teacher.duplicateTeacherId,
            prefix: this.teacher.prefix == null ? 'notset' : this.teacher.prefix,
            firstName: this.teacher.firstName,
            lastName: this.teacher.lastName,
            suffix: this.teacher.suffix == null ? 'notset' : this.teacher.suffix,
            gender: this.teacher.gender,
            schoolType: this.teacher.schoolType == null ? 'notset' : this.teacher.schoolType
		});
	}

    get teacherId() {
        return this.teacher.id;
    }

    get panelId(): string {
        return fromStore.PanelId.Contact;
	}

	get canSave(): boolean {
		return this.editor.dirty && this.editor.valid;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

    onSubmit() {
        const teacher = { ...this.teacher };
        Object.keys(this.editor.value).forEach(key => {
            const value: any = this.editor.value[key] == 'notset' ? null : this.editor.value[key];
            teacher[key] = value;
        });

        teacher.name = (teacher.prefix != null ? PrefixLookup[teacher.prefix].name + ' ' : '')
            + teacher.firstName + ' ' + teacher.lastName
            + (teacher.suffix != null ? ' ' + SuffixLookup[teacher.suffix].name : '');

        // Make sure inactive reason is cleared out if active
        if (teacher.active) {
            teacher.inactiveReason = null;
        }

        this.store.dispatch(new fromStore.UpdateTeacherPanel({ panel: fromStore.PanelId.Info, teacher }));
    }
}
