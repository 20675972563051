<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="active">Active</div>
        <div class="property-block__value" role="definition" aria-labelledby="active">{{ program.active | yesNo }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="!program.active">
        <div class="property-block__label" id="inactiveReason">Inactive Reason</div>
        <div class="property-block__value" role="definition">{{ inactiveReason }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="schoolId">Program ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="schoolId">{{ program.id }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">Program Type</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ programType }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">Program Start</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ program.startFrom | date:'M/d' }} {{ to }} {{ program.startTo | date:'M/d' }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">School ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName"><a [routerLink]="['/schools/' + program.school.id]">{{ program.school.id }}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">School Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName"><a [routerLink]="['/schools/' + program.school.id]">{{ program.school.longName }}</a></div>
    </li>
</ul>
