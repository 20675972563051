import { ValidationErrors } from '@angular/forms';

import { Action } from '@ngrx/store';

import { Address, AddressVerification } from '../../../scp-common/models';
import { TeacherDetails, TeacherCreate } from '../../models';
import { DistrictLookup } from '../../../districts/models';

export enum TeacherDetailsActionTypes {
	LoadTeacherDetails = '[Teacher] Load Teacher Details',
	LoadTeacherDetailsComplete = '[Teacher] Load Teacher Details Complete',
	LoadTeacherDetailsFailure = '[Teacher] Load Teacher Details Failure',

    TeacherLoadCounties = '[Teacher] Teacher Load Counties',
    TeacherLoadCountiesSuccess = '[Teacher] Teacher Load Counties Success',

    TeacherAddressLookupCityState = '[Teacher] Teacher Address Lookup CityState',
    TeacherAddressLookupCityStateSuccess = '[Teacher] Teacher Address Lookup CityState Success',
    TeacherAddressLookupCityStateFailure = '[Teacher] Teacher Address Lookup CityState Failure',

    TeacherVerifyAddress = '[Teacher] Teacher Verify Address',
    TeacherVerifyAddressSuccess = '[Teacher] Teacher Verify Address Success',

    DistrictLookupById = '[Teacher] Lookup District By Id',
    DistrictLookupByIdSuccess = '[Teacher] Lookup District By Id Success',
    DistrictLookupByIdFailure = '[Teacher] Lookup District By Id Failure',
    DistrictLookupByName = '[Teacher] Lookup District By Name',
    DistrictLookupByNameSuccess = '[Teacher] Lookup District By Name Success',
    DistrictLookupByNameFailure = '[Teacher] Lookup District By Name Failure',

	UpdateEditorState = '[Teacher] Update Editor State',

	UpdateTeacherPanel = '[Teacher] Update Teacher Panel',
	UpdateTeacherPanelSuccess = '[Teacher] Update Teacher Panel Success',
    UpdateTeacherPanelFailure = '[Teacher] Update Teacher Panel Failure',

    CreateTeacher = '[Teacher] Create Teacher',
    CreateTeacherSuccess = '[Teacher] Create Teacher Success',
    CreateTeacherFailure = '[Teacher] Create Teacher Failed',
}

export class LoadTeacherDetails implements Action {
	readonly type = TeacherDetailsActionTypes.LoadTeacherDetails;
	constructor(public payload: number) { }
}

export class LoadTeacherDetailsComplete implements Action {
	readonly type = TeacherDetailsActionTypes.LoadTeacherDetailsComplete;
	constructor(public payload: TeacherDetails) { }
}

export class LoadTeacherDetailsFailure implements Action {
	readonly type = TeacherDetailsActionTypes.LoadTeacherDetailsFailure;
	constructor(public payload: any) { }
}

export class TeacherLoadCounties implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherLoadCounties;
    constructor(public payload: { panel: string, stateCode: string }) { }
}

export class TeacherLoadCountiesSuccess implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherLoadCountiesSuccess;
    constructor(public payload: { panel: string, counties: string[] }) { }
}

export class TeacherAddressLookupCityState implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherAddressLookupCityState;
    constructor(public payload: { panel: string, zip: string }) { }
}

export class TeacherAddressLookupCityStateSuccess implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherAddressLookupCityStateSuccess;
    constructor(public payload: { panel: string, cityState: { city: string, state: string } }) { }
}

export class TeacherAddressLookupCityStateFailure implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherAddressLookupCityStateFailure;
    constructor(public payload: { panel: string, errors: any[] }) { }
}

export class TeacherVerifyAddress implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherVerifyAddress;
    constructor(public payload: { panel: string, address: Address }) { }
}

export class TeacherVerifyAddressSuccess implements Action {
    readonly type = TeacherDetailsActionTypes.TeacherVerifyAddressSuccess;
    constructor(public payload: { panel: string, verification: AddressVerification }) { }
}

export class DistrictLookupById implements Action {
    readonly type = TeacherDetailsActionTypes.DistrictLookupById;
    constructor(public payload: { panel: string, districtId: number }) { }
}

export class DistrictLookupByIdSuccess implements Action {
    readonly type = TeacherDetailsActionTypes.DistrictLookupByIdSuccess;
    constructor(public payload: { panel: string, district: DistrictLookup }) { }
}

export class DistrictLookupByIdFailure implements Action {
    readonly type = TeacherDetailsActionTypes.DistrictLookupByIdFailure;
    constructor(public payload: { panel: string, errors: any }) { }
}

export class DistrictLookupByName implements Action {
    readonly type = TeacherDetailsActionTypes.DistrictLookupByName;
    constructor(public payload: { panel: string, name: string }) { }
}

export class DistrictLookupByNameSuccess implements Action {
    readonly type = TeacherDetailsActionTypes.DistrictLookupByNameSuccess;
    constructor(public payload: { panel: string, districts: DistrictLookup[] }) { }
}

export class DistrictLookupByNameFailure implements Action {
    readonly type = TeacherDetailsActionTypes.DistrictLookupByNameFailure;
    constructor(public payload: { panel: string, errors: any }) { }
}


export class UpdateEditorState implements Action {
	readonly type = TeacherDetailsActionTypes.UpdateEditorState;
	constructor(public payload: { key: string, open: boolean }) { }
}

export class UpdateTeacherPanel implements Action {
	readonly type = TeacherDetailsActionTypes.UpdateTeacherPanel;
	constructor(public payload: { panel: string, teacher: TeacherDetails }) { }
}

export class UpdateTeacherPanelSuccess implements Action {
	readonly type = TeacherDetailsActionTypes.UpdateTeacherPanelSuccess;
	constructor(public payload: { panel: string, teacher: TeacherDetails }) { }
}

export class UpdateTeacherPanelFailure implements Action {
	readonly type = TeacherDetailsActionTypes.UpdateTeacherPanelFailure;
	constructor(public payload: { panel: string, errors: any[] }) { }
}

export class CreateTeacher implements Action {
    readonly type = TeacherDetailsActionTypes.CreateTeacher;
    constructor(public payload: { teacher: TeacherCreate }) { }
}

export class CreateTeacherSuccess implements Action {
    readonly type = TeacherDetailsActionTypes.CreateTeacherSuccess;
    constructor(public payload: { id: number }) { }
}

export class CreateTeacherFailure implements Action {
    readonly type = TeacherDetailsActionTypes.CreateTeacherFailure;
    constructor(public payload: { errors: any }) { }
}

export type TeacherDetailsActions =
	| LoadTeacherDetails
	| LoadTeacherDetailsComplete
	| LoadTeacherDetailsFailure
	| TeacherLoadCounties
	| TeacherLoadCountiesSuccess
	| TeacherAddressLookupCityState
	| TeacherAddressLookupCityStateSuccess
	| TeacherAddressLookupCityStateFailure
	| TeacherVerifyAddress
	| TeacherVerifyAddressSuccess
	| DistrictLookupById
	| DistrictLookupByIdSuccess
	| DistrictLookupByIdFailure
	| DistrictLookupByName
	| DistrictLookupByNameSuccess
	| DistrictLookupByNameFailure
	| UpdateEditorState
	| UpdateTeacherPanel
	| UpdateTeacherPanelSuccess
    | UpdateTeacherPanelFailure
    | CreateTeacher
    | CreateTeacherSuccess
    | CreateTeacherFailure;

