import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, AbstractControl, Validators, ValidatorFn } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, filter, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { MessageType } from '../../../core/models';
import { Address, states } from '../../scp-common/models';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';
import * as fromModels from '../models';

function validatePhoneNumber(group: FormGroup) {
	const type = group.get('schoolType').value;
	const phone = group.get('phone').value;
	const isPhoneRequired = type === fromModels.SchoolType.PUBLIC || type === fromModels.SchoolType.PRIVATE || type === fromModels.SchoolType.COLLEGE || type === fromModels.SchoolType.CHARTER;
	
	if (isPhoneRequired && !phone)
		return { isRequired: true }
	return null;
}

@Component({
	selector: 'opus-school-create',
	templateUrl: './school-create.component.html',
})
export class SchoolCreateComponent implements OnInit, OnDestroy	 {
	@Input() visible = false;
	@Output() action = new EventEmitter<boolean>();

	title = 'Create New School';
	primaryLabel = 'Create School';
	secondaryLabel = 'Cancel';

	editor: FormGroup;
	validating = false;
	submitting = false;

	countyLookup$: Observable<fromStore.CountyLookupState>;
	counties: string[] = [];
	countyState: string;
	pZipLookup$: Observable<fromStore.ZipLookupState>;
	pZipLookup: fromStore.ZipLookupState;
	mZipLookup$: Observable<fromStore.ZipLookupState>;
	mZipLookup: fromStore.ZipLookupState;
	details$: Observable<fromStore.SchoolDetailsState>;

	showCorrection = false;
	ps$: Observable<fromStore.AddressPanelState>;
	enteredAddress: Address;
	suggestedAddress: Address;

	private alive = true;
	private closing = false;
	private sameAsPhysical = false;
	private verifying = false;

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
		console.log('SchoolCreateComponent()...');
	}

	ngOnInit() {
		console.log('SchoolCreateComponent.ngOnInit()...');

		this.createForm();
		this.initZipLookups();

		this.countyLookup$ = this.store.pipe(select(fromStore.getDetailsStateCountyLookup));
		this.countyLookup$.pipe(takeWhile(() => this.alive)).subscribe(cls => {
			if (cls.lookupStateCode != this.pState.value) {
				return;
			}

			this.pCounty[cls.pending ? 'disable' : 'enable']();
			this.counties = [];
			this.pCounty.patchValue('');

			if (cls.pending) {
				return;
			}

			if (cls.errors || cls.counties[this.pState.value].length === 0) {
				this.store.dispatch(new fromRoot.DisplayMessage({
					message: 'Unable to retrieve county list for state',
					messageType: MessageType.alert,
					toast: false,
				}));

				return;
			}

			this.counties = cls.counties[this.pState.value];
			this.cdr.detectChanges();
		});

		this.details$ = this.store.pipe(select(fromStore.getSchoolDetailsState));
		this.details$.pipe(
			takeWhile(() => this.alive),
		).subscribe(s => {
			console.log('detail$: ', s);

			if (s.creating) {
				this.submitting = true;
			}

			if (this.submitting && !s.creating) {
				this.submitting = false;
				this.cdr.detectChanges();

				if (s.createdId !== null) {
					this.resetForm();
					this.action.emit(true);
					this.store.dispatch(new fromRoot.Go({ path: [`/schools/${s.createdId}`] }));

					return;
				}

				this.toggleForm(true);
			}
		});
	}

	ngOnDestroy() {
		console.log('SchoolCreateComponent.ngOnDestroy()...');

		this.alive = false;
	}

	private createForm() {
		console.log('SchoolCreateComponent.createForm()...');

		this.editor = this.fb.group({
			longName: ['', Validators.required],
			schoolType: [fromModels.SchoolType.PUBLIC],
			schoolLevel: [fromModels.SchoolLevel.ELEMENTARY],
			pAddress: this.fb.group({
				line1: ['', Validators.required],
				line2: [''],
				city: ['', Validators.required],
				state: ['', Validators.required],
				zip: ['', [Validators.required, Validators.pattern(/^\d{5}(?:-\d{4})?$/)]],
				county: [''],
			}),
			mAddress: this.fb.group({
				line1: ['', Validators.required],
				line2: [''],
				city: ['', Validators.required],
				state: ['', Validators.required],
				zip: ['', [Validators.required, Validators.pattern(/^\d{5}(?:-\d{4})?$/)]],
			}),
			phone: ['', [Validators.nullValidator, Validators.pattern(/^([0-9]{10})?$/)]],
		}, { validator: validatePhoneNumber });

		this.pState.valueChanges.pipe(
			takeWhile(() => this.alive),
		).subscribe(val => {
			console.log('SchoolCreateComponent.pState.valueChanges(): ', val);

			if (!val || this.pState.disabled || this.countyState == this.pState.value) {
				return;
			}

			this.countyState = this.pState.value;
			this.store.dispatch(new fromStore.SchoolLoadCounties({ panel: fromStore.PanelId.PhysAddr, stateCode: val }));
    });
	}

	private initZipLookups() {
		this.pZipLookup$ = this.store.pipe(select(fromStore.getDetailsStatePhysAddrZipLookup));
		this.pZipLookup$.pipe(
			takeWhile(() => this.alive),
		).subscribe(zls => {
			if (zls.zip != this.pZip.value) {
				return;
			}

			if (this.pZipLookup && this.pZipLookup.pending != zls.pending) {
				const method = zls.pending ? 'disable' : 'enable';

				this.pZip[method]();
				this.pCity[method]();
				this.pState[method]();
				this.pCounty[method]();
			}

			if (zls.errors) {
				this.store.dispatch(new fromRoot.DisplayMessage({
					message: 'Unable to look up city and state',
					messageType: MessageType.alert,
					toast: false,
				}));
			}

			if (zls.cityState) {
				this.pCity.patchValue(zls.cityState.city);
				this.pState.patchValue(zls.cityState.state);
			}

			this.pZipLookup = zls;

			this.cdr.detectChanges();
		});
		this.mZipLookup$ = this.store.pipe(select(fromStore.getDetailsStateMailAddrZipLookup));
		this.mZipLookup$.pipe(
			takeWhile(() => this.alive),
		).subscribe(zls => {
			if (zls.zip != this.mZip.value) {
				return;
			}

			if (this.mZipLookup && this.mZipLookup.pending != zls.pending) {
				const method = zls.pending ? 'disable' : 'enable';

				this.mZip[method]();
				this.mCity[method]();
				this.mState[method]();
			}

			if (zls.errors) {
				this.store.dispatch(new fromRoot.DisplayMessage({
					message: 'Unable to look up city and state',
					messageType: MessageType.alert,
					toast: false,
				}));
			}

			if (zls.cityState) {
				this.mCity.patchValue(zls.cityState.city);
				this.mState.patchValue(zls.cityState.state);
			}

			this.mZipLookup = zls;

			this.cdr.detectChanges();
		});
	}

	private resetForm() {
		const addr = {
			line1: '',
			line2: '',
			city: '',
			state: '',
			zip: '',
		};

		this.editor.reset({
			longName: '',
			schoolType: fromModels.SchoolTypeLookup[fromModels.SchoolType.PUBLIC].id,
			schoolLevel: fromModels.SchoolLevelLookup[fromModels.SchoolLevel.ELEMENTARY].id,
			pAddress: { ...addr, county: '' },
      mAddress: { ...addr },
      phone: '',
		});

		this.sameAsPhysical = false;
		this.mAddress.enable();
		this.validating = false;
		this.submitting = false;
	}

	get states(): string[] {
		return states;
	}

	get longName(): FormControl {
		return this.editor.controls.longName as FormControl;
	}

	get schoolTypes() {
		return fromModels.SchoolTypeLookup;
	}

	get schoolType(): FormControl {
		return this.editor.controls.schoolType as FormControl;
	}

	get schoolLevels() {
		return fromModels.SchoolLevelLookup;
	}

	get pAddress(): FormGroup {
		return this.editor.controls.pAddress as FormGroup;
	}

	get pLine1(): FormControl {
		return this.pAddress.controls.line1 as FormControl;
	}

	get pZip(): FormControl {
		return this.pAddress.controls.zip as FormControl;
	}

	get pCity(): FormControl {
		return this.pAddress.controls.city as FormControl;
	}

	get pState(): FormControl {
		return this.pAddress.controls.state as FormControl;
	}

	get pCounty(): FormControl {
		return this.pAddress.controls.county as FormControl;
	}

	get pZipLookupDisabled(): boolean {
		return this.pZip.disabled || this.pZip.invalid;
	}

	get mAddress(): FormGroup {
		return this.editor.controls.mAddress as FormGroup;
	}

	get mLine1(): FormControl {
		return this.mAddress.controls.line1 as FormControl;
	}

	get mCity(): FormControl {
		return this.mAddress.controls.city as FormControl;
	}

	get mState(): FormControl {
		return this.mAddress.controls.state as FormControl;
	}

	get mZip(): FormControl {
		return this.mAddress.controls.zip as FormControl;
	}

	get mZipLookupDisabled(): boolean {
		return this.mZip.disabled || this.mZip.invalid;
	}

  get pPhone(): FormControl {
    return this.editor.controls.phone as FormControl;
  }

	onApplyZip(isPhysical: boolean) {
		const zip = isPhysical ? this.pZip.value : this.mZip.value;
		const panel = isPhysical ? fromStore.PanelId.PhysAddr : fromStore.PanelId.MailAddr;

		console.log('SchoolCreateComponent.onZipLookup(): ', zip);

		this.store.dispatch(new fromStore.SchoolAddressLookupCityState({ panel, zip }));
	}

	onSameAsPhysical(e: Event) {
		this.sameAsPhysical = (e.currentTarget as HTMLInputElement).checked;

		if (!this.sameAsPhysical) {
			this.mAddress.enable();

			return;
		}

		this.mAddress.disable();
		this.mirrorPhysical(this.pAddress.value);

		this.pAddress.valueChanges.pipe(
			takeWhile(() => this.sameAsPhysical),
		).subscribe(val => {
			this.mirrorPhysical(val);
		});
	}

	private mirrorPhysical(addr) {
		const { county, ...mAddress } = addr;
		this.mAddress.patchValue(mAddress);
	}

	onModalAction(accept: boolean) {
		if (this.showCorrection) {
			this.onCorrectionModalAction(accept);

			return;
		}

		console.log('SchoolCreateComponent.onModalAction(): ', accept);

		//	this 'closing' nonsense is due to a bug in the modal component that is emitting the action event a second time with accept = false
		if (this.closing) {
			this.closing = false;

			return;
		}

		if (!accept) {
			this.resetForm();
			this.action.emit(accept);

			return;
		}

		this.validating = true;

		if (this.editor.invalid) {
			console.log('SchoolCreateComponent.onModalAction(): form invalid!');

			return;
		}

		this.verifyAddress(true);
	}

	onCorrectionModalAction(accept: boolean) {
		console.log('SchoolCreateComponent.onCorrectionModalAction(): ', accept);

		const isPhysical = this.compareAddresses(this.enteredAddress, this.pAddress.value);

		if (!accept) {
			this.showCorrection = false;
			this.toggleForm(isPhysical);

			return;
		}

		if (isPhysical) {
			this.pAddress.patchValue({ ...this.suggestedAddress, county: this.pAddress.value.county });

			if (!this.sameAsPhysical) {
				this.verifyAddress(false);

				return;
			}

			this.mAddress.patchValue(this.suggestedAddress);
			this.createSchool();

			return;
		}

		this.mAddress.patchValue(this.suggestedAddress);
		this.createSchool();
	}

	verifyAddress(isPhysical: boolean) {
		console.log('SchoolCreateComponent.verifyAddress(): ', isPhysical ? 'Physical' : 'Mailing');

		const panel = isPhysical ? fromStore.PanelId.PhysAddr : fromStore.PanelId.MailAddr;
		const address = isPhysical ? this.pAddress.value : this.mAddress.value;

		this.verifying = true;
		this.store.dispatch(new fromStore.SchoolVerifyAddress({ panel, address }));

		//	need to observe the store for verification state changes
		this.ps$ = this.store.pipe(
			select(isPhysical ? fromStore.getDetailsStatePhysAddrPanel : fromStore.getDetailsStateMailAddrPanel)
		);
		this.ps$.pipe(
			takeWhile(() => this.verifying),
		).subscribe(ps => {
			console.log(ps);

			if (ps.verifying) {
				return;
			}

			this.verifying = false;

			const verifiedAddress = ps.verifiedAddress;
			const address = isPhysical ? this.pAddress.value : this.mAddress.value;

			console.log(verifiedAddress);

			if (verifiedAddress == null || this.compareAddresses(address, verifiedAddress)) {
				if (isPhysical && !this.sameAsPhysical) {
					this.verifyAddress(false);

					return;
				}

				this.createSchool();

				return;
			}

			this.enteredAddress = address;
			this.suggestedAddress = {
				...verifiedAddress,
			};

			this.showCorrection = true;
			this.toggleForm(isPhysical);
			this.cdr.detectChanges();
		});
	}

	private toggleForm(isPhysical: boolean) {
		if (this.showCorrection) {
			this.title = `${isPhysical ? 'Physical' : 'Mailing'} Address Correction`;
			this.primaryLabel = 'Accept';
			this.secondaryLabel = 'Edit';
		} else {
			this.title = 'Create New School';
			this.primaryLabel = 'Create School';
			this.secondaryLabel = 'Cancel';
		}
	}

	private compareAddresses(first: Address, second: Address): boolean {
		return first.line1 == second.line1
			&& first.line2 == second.line2
			&& first.city == second.city
			&& first.state == second.state
			&& first.zip == second.zip;
	}

	private createSchool() {
		const school: fromModels.NewSchool = {
			longName: this.longName.value,
			schoolType: this.editor.value.schoolType,
			schoolLevel: this.editor.value.schoolLevel,
			physicalAddress: this.pAddress.value,
			mailingAddress: this.mAddress.value,
			phone: this.pPhone.value,
		};

		console.log('SchoolCreateComponent.createSchool(): ', school);

		this.validating = false;
		this.showCorrection = false;
		this.store.dispatch(new fromStore.CreateSchool({ school }));
	}
}
