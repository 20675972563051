import { Component, Input } from '@angular/core';

import { Event } from '../../models';

@Component({
    selector: 'event-staffing',
    templateUrl: './event-staffing.component.html',
})
export class EventStaffingComponent {
    @Input() event: Event;

}
