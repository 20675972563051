import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Operation, compare } from 'fast-json-patch';

import { State } from '../../../../core/store';
import { CountyLookupState } from '..';
import * as teacherDetailsActions from '../actions/teacher-details.action';
import * as fromServices from '../../../scp-common/services';
import * as fromSelectors from '../selectors';
import * as fromModels from '../../models';
import * as fromRoot from '../../../../core/store';

@Injectable()
export class TeacherDetailsEffects {
    constructor(
        private actions$: Actions,
        private teacherService: fromServices.TeacherService,
        private addressService: fromServices.AddressService,
        private districtService: fromServices.DistrictService,
        private store$: Store<State>,
    ) { }

    
    loadTeacherDetails$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.LoadTeacherDetails),
        tap(a => {
            console.log('Entering loadTeacherDetails$ Effect: ', a);
        }),
        switchMap((action: teacherDetailsActions.LoadTeacherDetails) => this.teacherService.loadTeacher(action.payload).pipe(
            tap(res => console.log(`teacherService.loadTeacher( ${action.payload} ) returned: `, res)),
            map(teacher => new teacherDetailsActions.LoadTeacherDetailsComplete(teacher)),
            catchError(error => of(new teacherDetailsActions.LoadTeacherDetailsFailure(error.message))),
        )),
        tap(a => {
            console.log('Exiting loadTeacherDetails$ Effect: ', a);
        }),
    ));

    
    updateTeacherPanel$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.UpdateTeacherPanel),
        tap(a => {
            console.log('Entering updateTeacherPanel$ Effect: ', a);
        }),
        //	get the current value of the district from the store
        withLatestFrom(this.store$.pipe(select(fromSelectors.getTeacherDetailsEntity))),
        //	create a json patch by comparing the current district with the updated version
        //	typescript note: using array destructuring to pull the withLatestFrom output into individual parameters to map
        map(([action, teacher]: [teacherDetailsActions.UpdateTeacherPanel, fromModels.TeacherDetails]) => {
            const patch = compare(teacher, action.payload.teacher);

            console.log('new teacher:', action.payload.teacher, 'current teacher:', teacher, 'patch:', patch);

            //	the district service needs the id & the json patch
            return { panel: action.payload.panel, teacher, patch };
        }),
        switchMap(({ panel, teacher, patch }: { panel: string, teacher: fromModels.TeacherDetails, patch: Operation[] }) => {
            if (patch.length == 0) {
                console.log('updateTeacherPanel$ Effect: Skipping empty patch...');

                return of(new teacherDetailsActions.UpdateTeacherPanelSuccess({ panel, teacher }));
            }

            console.log('updateTeacherPanel$ Effect: Calling teacherService.patchTeacher(', teacher.id, ', ', patch, ')...');

            return this.teacherService.patchTeacher(teacher.id, patch).pipe(
                map(teacher => new teacherDetailsActions.UpdateTeacherPanelSuccess({ panel, teacher })),
                //	TODO: in the case of a bad request, we should really be grabbing the validation errors from the response body
                //	However, since everything should already be validated client side, this is low priority.
                catchError(errors => {
                    return of(new teacherDetailsActions.UpdateTeacherPanelFailure({ panel, errors }));
                }),
            );
        }),
        tap(a => {
            console.log('Exiting updateTeacherPanel$ Effect: ', a);
        }),
    ));

    
    lookupCityState$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.TeacherAddressLookupCityState),
        tap(a => console.log('Entering lookupCityState$ Effect: ', a)),
        switchMap((a: teacherDetailsActions.TeacherAddressLookupCityState) => {
            const { panel, zip } = a.payload;

            return this.addressService.lookupCityState(zip).pipe(
                tap(res => console.log(`addressService.lookupCityState( ${zip} ) returned: `, res)),
                map(cityState => {
                    return new teacherDetailsActions.TeacherAddressLookupCityStateSuccess({ panel, cityState });
                }),
                catchError(errors => {
                    return of(new teacherDetailsActions.TeacherAddressLookupCityStateFailure({ panel, errors }));
                }),
            );
        }),
        tap(a => console.log('Exiting lookupCityState$ Effect: ', a)),
    ));

    
    loadCounties$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.TeacherLoadCounties),
        tap(a => console.log('Entering loadCounties$ Effect: ', a)),
        //	pull in the county lookup state
        withLatestFrom(this.store$.select(fromSelectors.getDetailsStateCountyLookup)),
        //	grab the county list for the request state code
        map(([action, countyLookup]: [teacherDetailsActions.TeacherLoadCounties, CountyLookupState]) => {
            const counties = countyLookup.counties[action.payload.stateCode];

            return { action, counties };
        }),
        switchMap(({ action, counties }: { action: teacherDetailsActions.TeacherLoadCounties, counties: string[] }) => {
            const { panel, stateCode } = action.payload;

            //	skip service call if we already have a county list for this state
            if (counties) {
                console.log(`loadCounties$: county list for ${action.payload.stateCode} already loaded.`);

                return of(new teacherDetailsActions.TeacherLoadCountiesSuccess({ panel, counties }));
            }

            return this.addressService.loadCounties(stateCode).pipe(
                tap(res => console.log(`addressService.loadCounties( ${stateCode} ) returned: `, res)),
                map((counties: string[]) => {
                    return new teacherDetailsActions.TeacherLoadCountiesSuccess({ panel, counties });
                }),
            );
        }),
        tap(a => console.log('Exiting loadCounties$ Effect: ', a)),
    ));

    
    districtLookupById$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.DistrictLookupById),
        switchMap((action: teacherDetailsActions.DistrictLookupById) => {
            console.log('Load District Lookup By ID Effect');

            return this.districtService.lookupDistrictById(action.payload.districtId).pipe(
                tap(d => {
                    console.log('districtLookupById$ returned:', d);
                }),
                map(districtResult => {
                    if (districtResult === null) {
                        return new teacherDetailsActions.DistrictLookupByIdFailure({ panel: action.payload.panel, errors: 'District Not Found' });
                    }

                    return new teacherDetailsActions.DistrictLookupByIdSuccess({ panel: action.payload.panel, district: districtResult });
                }),
                catchError(error => of(new teacherDetailsActions.DistrictLookupByIdFailure(error)))
            );
        })
    ));

    
    districtLookupByName$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.DistrictLookupByName),
        switchMap((action: teacherDetailsActions.DistrictLookupByName) => {
            console.log('Load District Lookup By ID Effect');

            return this.districtService.lookupDistrictByName(action.payload.name).pipe(
                tap(d => {
                    console.log('districtLookupById$ returned:', d);
                }),
                map(districtResult => {
                    if (districtResult === null) {
                        return new teacherDetailsActions.DistrictLookupByNameFailure({ panel: action.payload.panel, errors: 'District Not Found' });
                    }

                    return new teacherDetailsActions.DistrictLookupByNameSuccess({ panel: action.payload.panel, districts: districtResult });
                }),
                catchError(error => of(new teacherDetailsActions.DistrictLookupByNameFailure(error)))
            );
        })
    ));

    
    verifyAddress$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.TeacherVerifyAddress),
        tap(a => console.log('Entering verifyAddress$ Effect: ', a)),
        switchMap((a: teacherDetailsActions.TeacherVerifyAddress) => {
            const { panel, address } = a.payload;

            return this.addressService.verifyAddress(address).pipe(
                tap(res => console.log(`addressService.verifyAddress( ${address} ) returned: `, res)),
                map(verification => {
                    return new teacherDetailsActions.TeacherVerifyAddressSuccess({ panel, verification });
                }),
            );
        }),
        tap(a => console.log('Exiting verifyAddress$ Effect: ', a)),
    ));

    
    createTeacher$ = createEffect(() => this.actions$.pipe(
        ofType(teacherDetailsActions.TeacherDetailsActionTypes.CreateTeacher),
        switchMap((action: teacherDetailsActions.CreateTeacher) => {
            console.log('CreateTeacher Effect');

            return this.teacherService.createTeacher(action.payload.teacher).pipe(
                tap(d => {
                    console.log('createTeacher$ returned:', d);
                }),
                map(createTeacherResult => {
                    if (createTeacherResult === null) {
                        return new teacherDetailsActions.CreateTeacherFailure({ errors: 'Error creating teacher' });
                    }

                    return new fromRoot.Go({
                        path: [`teachers/${createTeacherResult}`]
                    });
                    //return new detailsActions.CreateTeacherSuccess({ id: createTeacherResult });
                }),
                catchError(error => of(new teacherDetailsActions.CreateTeacherFailure({ errors: 'Error creating teacher' })))
            );
        })
    ));
}
