<div class="search-results">
    <p class="nav-back">
        <a class="nav-back__link" routerLink="/search/districts">Back to Search</a>
    </p>
    <div class="card">
        <div class="card__section">
            <div class="row align-bottom">
                <div class="columns">
                    <h1 class="typography--display1">Districts</h1>
                </div>
                <div class="columns shrink">
                    <button class="btn btn--hollow btn--add" type="button" data-open="createDistrict" (click)="createDistrict()" opusAuthorize="District.Create">Create New District</button>
                </div>
            </div>
        </div>
        <div class="card__section">
            <div class="display-options">
                <div class="display-options__count">
                    Showing {{(searchResultsState$ | async).pagingSorting.pageSize * ((searchResultsState$ | async).pagingSorting.currentPage - 1) + 1}} -
                    {{(searchResultsState$ | async).pagingSorting.pageSize > (searchResultsState$ | async).pagingSorting.totalCount ? (searchResultsState$ | async).pagingSorting.totalCount : (searchResultsState$ | async).pagingSorting.pageSize * (searchResultsState$ | async).pagingSorting.currentPage}} of
                    {{(searchResultsState$ | async).pagingSorting.totalCount}}
                </div>
                <label class="display-options__per-page">
                    Show
                    <select (change)="setPageSize($event.target.value)">
                        <option value="15" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 15">15</option>
                        <option value="25" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 25">25</option>
                        <option value="50" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 50">50</option>
                        <option value="100" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 100">100</option>
                        <option value="200" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 200">200</option>
                    </select>
                </label>
                <!--<input type="text" class="display-options__filter" placeholder="Type to filter..." aria-label="Type to filter">-->
                <div class="display-options__filter"></div>
                <div class="display-options__pagination">
                    <ul class="pagination" role="navigation" aria-label="Pagination">
                        <!--<li class="pagination__previous disabled">Previous<span class="show-for-sr"> page</span></li>-->
                        <li class="pagination__previous" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage <= 1">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage <= 1 ? "Previous" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage > 1" aria-label="Previous page" (click)="prevPage()">Previous<span class="show-for-sr"> page</span></a>
                        </li>
                        <li class="pagination__current">
                            <span class="show-for-sr">You are on page </span>
                            {{(searchResultsState$ | async).pagingSorting.currentPage}}
                        </li>
                        <li aria-hidden="true">of</li>
                        <li><a aria-label="Page 10" (click)="lastPage()">{{(searchResultsState$ | async).pagingSorting.totalPages}}</a></li>
                        <li class="pagination__next" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages ? "Next" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage < (searchResultsState$ | async).pagingSorting.totalPages" aria-label="Next page" (click)="nextPage()">Next<span class="show-for-sr"> page</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="full-page-spinner" [class.full-page-spinner--is-active]="(searchResultsState$ | async).loading">
            <div class="full-page-spinner__icon"></div>
        </div>

        <div class="card__section">
            <!--Don't show the search results if we're loading...-->
            <div class="table" role="grid">
                <div role="rowgroup">
                    <div class="table__row table__row--header" role="row">
                        <span class="table__cell table__cell--align-left table__cell--half" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('Id')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Id' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Id' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                ID
                            </button>
                        </span>
                        <span class="table__cell table__cell--align-left table__cell--one-half" role="columnheader">
                            <!--table__sorter-btn--sorted-dsc-->
                            <button class="table__sorter-btn"
                                    (click)="sortBy('LongName')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'LongName' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'LongName' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                District Name
                            </button>
                        </span>
                        <span class="table__cell table__cell--align-left" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('City')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'City' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'City' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                City
                            </button>
                        </span>
                        <span class="table__cell table__cell--half" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('State')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'State' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'State' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                State
                            </button>
                        </span>
                        <span class="table__cell" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('County')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'County' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'County' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                County
                            </button>
                        </span>
                        <span class="table__cell" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('Phone')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Phone' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Phone' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Phone
                            </button>
                        </span>
                        <span class="table__cell table__cell--half" role="columnheader" title="Relationship Manager Location ID">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('Code')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Code' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Code' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Rel. Mgr.
                            </button>
                        </span>
                        <span class="table__cell table__cell--half" role="columnheader" title="Supervisory">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('IsSupervisory')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'IsSupervisory' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'IsSupervisory' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Sup.
                            </button>
                        </span>
                        <span class="table__cell table__cell--half" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('IsActive')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'IsActive' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'IsActive' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Active
                            </button>
                        </span>
                    </div>
                </div>
                <div role="rowgroup">
                    <a class="table__row ng-scope" role="row" *ngFor="let district of (searchResultsState$ | async).searchResults; odd as isOdd; last as isLast"
                        [class.table__row--alt]="isOdd"
                        [class.table__row--last]="isLast"
                        routerLink="/districts/{{ district.id }}" opusAuthorize="District.ViewDetails">
                        <span class="table__cell table__cell--align-left table__cell--half" role="cell">{{ district.id }}</span>
                        <span class="table__cell table__cell--align-left table__cell--one-half" role="cell">{{ district.longName }}</span>
                        <span class="table__cell table__cell--align-left" role="cell">{{ district.city }}</span>
                        <span class="table__cell table__cell--half" role="cell">{{ district.state }}</span>
                        <span class="table__cell" role="cell">{{ district.county }}</span>
                        <span class="table__cell" role="cell">{{ district.phone }}</span>
                        <span class="table__cell table__cell--half" role="cell">{{ district.code }}</span>
                        <span class="table__cell table__cell--half" role="cell">{{ district.isSupervisory ? 'Y' : 'N' }}</span>
                        <span class="table__cell table__cell--half" role="cell">{{ district.isActive ? 'Y' : 'N' }}</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="card__section">
            <div class="display-options">
                <div class="display-options__count">
                    Showing {{(searchResultsState$ | async).pagingSorting.pageSize * ((searchResultsState$ | async).pagingSorting.currentPage - 1) + 1}} -
                    {{(searchResultsState$ | async).pagingSorting.pageSize > (searchResultsState$ | async).pagingSorting.totalCount ? (searchResultsState$ | async).pagingSorting.totalCount : (searchResultsState$ | async).pagingSorting.pageSize * (searchResultsState$ | async).pagingSorting.currentPage}} of
                    {{(searchResultsState$ | async).pagingSorting.totalCount}}
                </div>
                <label class="display-options__per-page">
                    Show
                    <select (change)="setPageSize($event.target.value)">
                        <option value="15" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 15">15</option>
                        <option value="25" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 25">25</option>
                        <option value="50" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 50">50</option>
                        <option value="100" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 100">100</option>
                        <option value="200" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 200">200</option>
                    </select>
                </label>
                <!--<input type="text" class="display-options__filter" placeholder="Type to filter..." aria-label="Type to filter">-->
                <div class="display-options__filter"></div>
                <div class="display-options__pagination">
                    <ul class="pagination" role="navigation" aria-label="Pagination">
                        <!--<li class="pagination__previous disabled">Previous<span class="show-for-sr"> page</span></li>-->
                        <li class="pagination__previous" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage <= 1">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage <= 1 ? "Previous" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage > 1" aria-label="Previous page" (click)="prevPage()">Previous<span class="show-for-sr"> page</span></a>
                        </li>
                        <li class="pagination__current">
                            <span class="show-for-sr">You are on page </span>
                            {{(searchResultsState$ | async).pagingSorting.currentPage}}
                        </li>
                        <li aria-hidden="true">of</li>
                        <li><a aria-label="Page 10" (click)="lastPage()">{{(searchResultsState$ | async).pagingSorting.totalPages}}</a></li>
                        <li class="pagination__next" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages ? "Next" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage < (searchResultsState$ | async).pagingSorting.totalPages" aria-label="Next page" (click)="nextPage()">Next<span class="show-for-sr"> page</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<opus-district-create [visible]="showModal" (action)="createDistrictClosed($event)"></opus-district-create>