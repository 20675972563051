import { MerchComboGuard } from './merch-combos.guard';
import { MerchProductGroupsGuard } from './merch-product-groups.guard';
import { MerchRentalCategoryGuard } from './merch-rental-categories.guard';

export const guards: any[] = [
    MerchComboGuard, 
    MerchProductGroupsGuard,
    MerchRentalCategoryGuard
];

export * from './merch-combos.guard';
export * from './merch-product-groups.guard';
export * from './merch-rental-categories.guard';
