export enum MessageType {
    alert,
    info,
    success
}

export interface Message {
    message: string;
    messageType: MessageType;
    toast: boolean;
}
