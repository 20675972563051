import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEventSearchResults from '../reducers/searchresults.reducer';

export const getEventSearchState = createSelector(
    fromFeature.getEventsState,
    (state: fromFeature.EventsState) => state.search
);
export const getEventSearchResultsState = createSelector(
    getEventSearchState,
    fromEventSearchResults.getEventSearchResults
);
