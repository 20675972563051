import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import * as fromModels from '../models';
import { SchoolDetails } from '../../schools/models';

@Component({
	selector: 'opus-event-searchresults-page',
	templateUrl: './event-searchresults.component.html',
})
export class EventSearchResultsComponent implements OnInit, OnChanges {
    @Input() school: SchoolDetails;
    searchResultsState$: Observable<fromStore.SearchResultsState>;
    embeddedVersion = false;

    showModal = false;

    private _subscription: Subscription;
    private _searchCriteria: fromModels.EventSearchCriteria;
    private _pagingSorting: fromModels.EventPagingSorting;
    
    constructor(private route: ActivatedRoute, private router: Router, private _store: Store<fromStore.EventsState>) {
		console.log('DistrictSearchResultsComponent: constructor().');
	}

    ngOnInit() {
        console.log('EventSearchResultsComponent: ngOnInit().');
        // Get results of search (so our html can populate)
        this.searchResultsState$ = this._store.pipe(select(fromStore.getEventSearchState));
        // Grab the current search criteria stored in the state
        console.log(this.searchResultsState$);
        this._subscription = this.searchResultsState$.subscribe(s => {
            this._searchCriteria = s.searchCriteria;
            this._pagingSorting = s.pagingSorting;
        });

        if (this.school === null) {
            // If query params are changed, process them
            this.route.queryParams.subscribe(val => {
                this._store.dispatch(new fromStore.ProcessQueryString(this.router.url));
            });
        }
    }

    ngOnChanges() {
        if (this.school != null) {
            this.embeddedVersion = true;
            // Load results based on specified school
            this._store.dispatch(new fromStore.GetResultsForSchool(this.school, this._searchCriteria));
        }
    }

    nextPage() {
        console.log('EventSearchResultsComponent: nextPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.NextPage(this._searchCriteria, this._pagingSorting));
    }

    prevPage() {
        console.log('EventSearchResultsComponent: prevPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.PrevPage(this._searchCriteria, this._pagingSorting));
    }

    setPageSize(pageSize: number) {
        console.log('EventSearchResultsComponent: setPageSize().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SetPageSize(pageSize, this._searchCriteria));
    }

    lastPage() {
        console.log('EventSearchResultsComponent: lastPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.LastPage(this._searchCriteria, this._pagingSorting));
    }

    firstPage() {
        console.log('EventSearchResultsComponent: firstPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.FirstPage(this._searchCriteria, this._pagingSorting));
    }

    sortBy(column: string) {
        console.log('EventSearchResultsComponent: sortBy().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SortBy(column, this._searchCriteria, this._pagingSorting));
    }

    selectPreferenceSet(eventId: Number) {
        // TODO: How do I navigate to a preference set?
        console.log(`selectPreferenceSet for ${eventId}`);
        console.log('TODO: Need to implement preferences route.');
        //this._store.dispatch(new fromRoot.Go({
        //    path: [`events/${eventId}/preferences`]
        //}));
    }

    createEvent() {
        console.log('createEvent');
        this.showModal = true;
        //this._store.dispatch(new fromRoot.Go({
        //    path: [`events/create`]
        //}));
    }

    createEventClosed(created) {
        if (created) {
            console.log('Event created');
        }
        else {
            console.log('Event create was canceled');
        }

        // Reset our flag so we can show the modal again later if we want to.
        this.showModal = false;
    }
}
