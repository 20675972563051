import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { NgxMaskModule } from 'ngx-mask';

import { AuthModule, OidcConfigService, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, RouterStateSerializer, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CoreModule } from './core/core.module';
import { ScpCommonModule } from './scp/scp-common/scp-common.module';

import { routes } from './routes';
import { reducers, metaReducers, CustomRouterStateSerializer, effects } from './core/store';

import { AppComponent } from './core/containers/app.component';
import { env } from '../environments/environment';

import * as fromTeachers from './scp/teachers';
import * as fromSchools from './scp/schools';
import * as fromDistricts from './scp/districts';
import * as fromEvents from './scp/events';
import * as fromPrograms from './scp/programs';
import * as fromMerch from './merch';
import * as fromDistribution from './distribution';

import { AuthInterceptor } from './core/services/index';
import { map, switchMap } from 'rxjs/operators';

export function configureAuth(oidcConfigService: OidcConfigService, httpClient: HttpClient) {
  const setupAction$ = httpClient.get<any>(`${window.location.origin}/api/ClientAppSettings`).pipe(
    map((customConfig) => {
      const authWellKnownEndpoints: AuthWellKnownEndpoints = {
        issuer: customConfig.stsServer,
        jwksUri: customConfig.stsServer + '/.well-known/openid-configuration/jwks',
        authorizationEndpoint: customConfig.stsServer + '/connect/authorize',
        tokenEndpoint: customConfig.stsServer + '/connect/token',
        userinfoEndpoint: customConfig.stsServer + '/connect/userinfo',
        endSessionEndpoint: customConfig.stsServer + '/connect/endsession',
        checkSessionIframe: customConfig.stsServer + '/connect/checksession',
        revocationEndpoint: customConfig.stsServer + '/connect/revocation',
        introspectionEndpoint: customConfig.stsServer + '/connect/introspect',
      };

      return {
        stsServer: customConfig.stsServer,
        redirectUrl: customConfig.redirect_url,
        // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer
        // identified by the iss (issuer) Claim as an audience.
        // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience,
        // or if it contains additional audiences not trusted by the Client.
        clientId: customConfig.client_id,
        responseType: customConfig.response_type,
        scope: customConfig.scope,
        postLogoutRedirectUri: customConfig.post_logout_redirect_uri,
        startCheckSession: customConfig.start_checksession,
        silentRenew: customConfig.silent_renew,
        silentRenewUrl: customConfig.silent_renew_url,
        postLoginRoute: customConfig.startup_route,
        triggerAuthorizationResultEvent: true,
        // HTTP 403
        forbiddenRoute: customConfig.forbidden_route,
        // HTTP 401
        unauthorizedRoute: customConfig.unauthorized_route,
        // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
        // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
        maxIdTokenIatOffsetAllowedInSeconds: customConfig.max_id_token_iat_offset_allowed_in_seconds,
        useRefreshToken: customConfig.use_refresh_token,
        ignoreNonceAfterRefresh: customConfig.ignore_nonce_after_refresh,
        autoUserinfo: customConfig.auto_userinfo,
        authWellKnownEndpoints: authWellKnownEndpoints,        
      };
    }),
    switchMap((config) => {
      return oidcConfigService.withConfig(config, config.authWellKnownEndpoints);
    })
  );

  return () => setupAction$.toPromise();
}

@NgModule({
  imports: [
    BrowserModule,
    NgxMaskModule.forRoot({}),
    HttpClientModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'routerReducer'
    }),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    !env.production
      ? StoreDevtoolsModule.instrument() //	can specify { maxAge: 25 }
      : [],

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot(effects),
    AuthModule.forRoot(),
    StoreModule.forFeature('teachers', fromTeachers.reducers),
    StoreModule.forFeature('schools', fromSchools.reducers),
    StoreModule.forFeature('districts', fromDistricts.reducers),
    StoreModule.forFeature('events', fromEvents.reducers),
    StoreModule.forFeature('programs', fromPrograms.reducers),
    StoreModule.forFeature('merch', fromMerch.reducers),
    StoreModule.forFeature('distribution', fromDistribution.reducers),
    EffectsModule.forFeature(fromTeachers.effects),
    EffectsModule.forFeature(fromSchools.effects),
    EffectsModule.forFeature(fromDistricts.effects),
    EffectsModule.forFeature(fromEvents.effects),
    EffectsModule.forFeature(fromPrograms.effects),
    EffectsModule.forFeature(fromMerch.effects),
    EffectsModule.forFeature(fromDistribution.effects),

    CoreModule.forRoot(),
    ScpCommonModule
  ],
  providers: [
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService, HttpClient],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ...fromTeachers.guards,
    ...fromSchools.guards,
    ...fromDistricts.guards,
    ...fromEvents.guards,
    ...fromPrograms.guards,
    ...fromMerch.guards,
    ...fromMerch.services,
    ...fromDistribution.guards,
    ...fromDistribution.services
  ],
  bootstrap: [AppComponent],

  declarations: [
    ...fromTeachers.containers,
    ...fromTeachers.components,
    ...fromSchools.containers,
    ...fromSchools.components,
    ...fromDistricts.containers,
    ...fromDistricts.components,
    ...fromEvents.containers,
    ...fromEvents.components,
    ...fromPrograms.containers,
    ...fromPrograms.components,
    ...fromMerch.containers,
    ...fromMerch.components,
    ...fromDistribution.containers,
    ...fromDistribution.components,
  ],
  exports: [
    ...fromTeachers.containers,
    ...fromTeachers.components,
    ...fromSchools.containers,
    ...fromSchools.components,
    ...fromDistricts.containers,
    ...fromDistricts.components,
    ...fromEvents.containers,
    ...fromEvents.components,
    ...fromPrograms.containers,
    ...fromPrograms.components,
    ...fromMerch.containers,
    ...fromMerch.components,
    ...fromDistribution.containers,
    ...fromDistribution.components,
  ]
})
export class AppModule {
  constructor () { }
}
