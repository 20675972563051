import { Action } from '@ngrx/store';

import { ShippingChargesCriteria, ShippingCharge } from '../../models';

export enum DistributionActionTypes {
	LoadShippingCharges = '[Distribution] Load Shipping Charges',
	LoadShippingChargesSuccess = '[Distribution] Load Shipping Charges Success',
    LoadShippingChargesFailure = '[Distribution] Load Shipping Charges Failure',
}

export class LoadShippingCharges implements Action {
	readonly type = DistributionActionTypes.LoadShippingCharges;
    constructor(public payload: ShippingChargesCriteria) { }
}

export class LoadShippingChargesSuccess implements Action {
	readonly type = DistributionActionTypes.LoadShippingChargesSuccess;
	constructor(public payload: ShippingCharge[]) { }
}

export class LoadShippingChargesFailure implements Action {
	readonly type = DistributionActionTypes.LoadShippingChargesFailure;
	constructor(public payload: any) { }
}

export type DistributionActions =
    LoadShippingCharges
    | LoadShippingChargesFailure
    | LoadShippingChargesSuccess
