import { Component, Input } from '@angular/core';

import { SchoolDetails } from '../../models';

@Component({
	selector: 'opus-school-rental-returns',
	templateUrl: './school-rental-returns.component.html',
})
export class SchoolRentalReturnsComponent {
	@Input() school: SchoolDetails;
	monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	get blackoutPeriod() {
		return this.getDateRangeText(this.school.blackoutStart, this.school.blackoutEnd);
	}

	private getDate(s: string): Date {
		const ms: number = Date.parse(s);

		if (isNaN(ms)) {
			return null;
		}

		return new Date(ms);
	}

	private getDateText(d: Date): string {
		return d === null ? 'Unspecified' : `${this.monthNames[d.getMonth()]} ${d.getDate().toString()}`;
	}

	private getDateRangeText(from: string, to: string): string {
		const fromDate = this.getDate(from);
		const toDate = this.getDate(to);

		if (fromDate === null && toDate === null) {
			return '';
		}

		return `${this.getDateText(fromDate)} - ${this.getDateText(toDate)}`;
	}
}
