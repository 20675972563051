import { DistrictHeaderComponent } from './district-header.component';
import { DistrictInfoComponent } from './district-info.component';
import { DistrictRelationshipComponent } from './district-relationship.component';
import { DistrictAddressComponent } from './district-address.component';
import { DistrictContactsComponent } from './district-contacts.component';
import { DistrictCalendarComponent } from './district-calendar.component';
import { DistrictAddlDetailsComponent } from './district-addl-details.component';

export const detailsComponents: any[] = [
	DistrictHeaderComponent,
	DistrictInfoComponent,
	DistrictRelationshipComponent,
	DistrictAddressComponent,
	DistrictContactsComponent,
	DistrictCalendarComponent,
	DistrictAddlDetailsComponent,
];

export * from './district-header.component';
export * from './district-info.component';
export * from './district-relationship.component';
export * from './district-address.component';
export * from './district-contacts.component';
export * from './district-calendar.component';
export * from './district-addl-details.component';
