import { EventInfoComponent } from './event-info.component';
import { EventHostComponent } from './event-host.component';
import { EventDateTimeComponent } from './event-date-time.component';
import { EventLocationComponent } from './event-location.component';
import { EventStaffingComponent } from './event-staffing.component';
import { EventMeetingDetailsComponent } from './event-meeting-details.component';
import { EventSchoolsAttendingComponent } from './event-schools-attending.component';
import { EventPromoComponent } from './event-promo.component';
import { EventCopyComponent } from './event-copy.component';

export const detailsComponents: any[] = [
    EventInfoComponent,
    EventHostComponent,
    EventDateTimeComponent,
    EventLocationComponent,
    EventStaffingComponent,
    EventMeetingDetailsComponent,
    EventSchoolsAttendingComponent,
	EventPromoComponent,
	EventCopyComponent,
];

export * from './event-info.component';
export * from './event-host.component';
export * from './event-date-time.component';
export * from './event-location.component';
export * from './event-staffing.component';
export * from './event-meeting-details.component';
export * from './event-schools-attending.component';
export * from './event-promo.component';
export * from './event-copy.component';
