import { Component, Input } from '@angular/core';

import { SchoolDetails } from '../../models/school-details.model';

@Component({
	selector: 'opus-school-header',
	templateUrl: './school-header.html',
})
export class SchoolHeaderComponent {
	@Input() schoolDetails: SchoolDetails;

	get id() {
		return this.schoolDetails.id;
	}

	get longName() {
		return this.schoolDetails.longName;
	}
}
