import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, AsyncValidatorFn, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { takeWhile, tap, filter, map, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { SchoolDetails, SchoolType, InactiveReason } from '../models';
import * as fromStore from '../store';
import { DistrictLookup } from '../../districts/models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-school-info-editor',
    templateUrl: './school-info-editor.component.html',
})
export class SchoolInfoEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('districtDropdown', {static: true})
    districtDropdown: ElementRef;

    districtFoundationDropdown: any;

    alive = true;
    schoolDetails$: Observable<SchoolDetails>;
    panelState$: Observable<fromStore.PanelState>;
    school: SchoolDetails;
    panelState: fromStore.PanelState;
    editor: FormGroup;

    inLookup = false;
    districtId: number;
    districtName: string;
    districtLookups$: Observable<fromStore.DistrictLookupsState>;
    districtByNameLookup$: Observable<fromStore.DistrictByNameLookupState>;
    districtByNameLookup: fromStore.DistrictByNameLookupState;
    lookupDistrictName: string;

    constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
        console.log('SchoolInfoEditorComponent()...');
    }

    ngOnInit() {
        console.log('SchoolInfoEditorComponent.ngOnInit()...');

        this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
        this.schoolDetails$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(s => {
            this.school = s;
            this.districtId = s.districtId;
            this.districtName = s.districtName;
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateInfoPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    console.log('SchoolInfoEditorComponent.panelState$: clearing form...');
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.createForm();

        this.districtLookups$ = this.store.pipe(select(fromStore.getDistrictLookupsState));

        this.districtByNameLookup$ = this.store.pipe(select(fromStore.getDistrictByNameLookupState));
        this.districtByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(districtByNameLookup => {
            this.districtByNameLookup = districtByNameLookup;

            if (districtByNameLookup && districtByNameLookup.districts && districtByNameLookup.name == this.lookupDistrictName) {
                //Trigger the drop down
                if (!this.districtFoundationDropdown) {
                    this.districtFoundationDropdown = new Foundation.Dropdown($(this.districtDropdown.nativeElement).first());
                }

                const native = $(this.districtDropdown.nativeElement).first();

                if (!native.hasClass('is-open')) {
                    native.foundation('open');
                }

                this.cdr.detectChanges();
            }
        });
    }

    ngAfterViewInit() {
        console.log('SchoolInfoEditorComponent.ngAfterViewInit(): ', this.districtDropdown.nativeElement);
    }

    ngOnDestroy() {
        console.log('SchoolInfoEditorComponent.ngOnDestroy()...');
        this.alive = false;
    }

    schoolTypeValidator(): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const type = +control.value;
        const isPhoneRequired = type === SchoolType.PUBLIC || type === SchoolType.PRIVATE || type === SchoolType.COLLEGE || type === SchoolType.CHARTER;
        const phone = this.school.phone;

        if (isPhoneRequired && !phone)
          return { type: true }

        return null;
      };
    }

    createForm() {
        this.editor = this.fb.group({
            isActive: [this.isActive],
            inactiveReason: [this.school.inactiveReason == null ? 0 : this.school.inactiveReason],
            shortName: [this.school.shortName],
            longName: [this.school.longName],
            districtId: [this.school.districtId, { asyncValidators: [this.districtIdValidator()], updateOn: 'blur' }],
            districtName: [this.school.districtName],
            type: [this.type, this.schoolTypeValidator()],
            level: [this.level],
            lowGrade: [this.school.lowGrade],
            highGrade: [this.school.highGrade],
        });
    }

    resetForm() {
        this.editor.reset({
            isActive: this.isActive,
            inactiveReason: this.school.inactiveReason == null ? 0 : this.school.inactiveReason,
            shortName: this.school.shortName,
            longName: this.school.longName,
            districtId: this.school.districtId,
            districtName: this.school.districtName,
            type: this.type,
            level: this.level,
            lowGrade: this.school.lowGrade,
            highGrade: this.school.highGrade,
        });
    }

    get isActive() {
        return this.school.isActive ? 'Yes' : 'No';
    }

    get inactiveReason(): string {
        return InactiveReason[this.school.inactiveReason];
    }

    get type() {
        return this.school.type;
    }

    get level() {
        return this.school.level;
    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty || (this.editor.valid && this.editor.value.isActive == 'No' && this.school.inactiveReason == null);
    }

    get saveButtonLabel(): string {
        return this.panelState.updating ? 'Saving...' : 'Save';
    }

    onSubmit() {
        console.log('onSubmit: ', this.editor.status, this.editor.value);

        const isActive = this.editor.value.isActive == 'Yes';

        const school: SchoolDetails = {
            ...this.school,
            isActive: isActive,
            inactiveReason: isActive ? null : this.editor.value.inactiveReason,
            shortName: this.editor.value.shortName,
            longName: this.editor.value.longName,
            districtId: this.editor.value.districtId,
            type: +this.editor.value.type,
            level: +this.editor.value.level,
            lowGrade: +this.editor.value.lowGrade,
            highGrade: +this.editor.value.highGrade,
        };

        console.log('onSubmit: ', school);

        this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: fromStore.PanelId.Info, school }));
    }

    lookupDistrictByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupDistrictName = this.editor.value.districtName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.districtDropdown.nativeElement).first();

            if (native.hasClass('is-open')) {
                native.foundation('close');
            }
        } else {
            if (this.lookupDistrictName && this.lookupDistrictName.length > 3) {
                this.store.dispatch(new fromStore.LookupDistrictByName({ name: this.lookupDistrictName }));
            }
        }
    }

    selectDistrict(district: DistrictLookup) {
        if (district) {
            this.editor.controls.districtName.patchValue(district.longName);
            this.editor.controls.districtId.patchValue(district.id);
        }
    }

    districtIdValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> | null => {
            console.log('districtIdValidator(): ', control);

            if (!control.dirty || +control.value == this.districtId) {
                console.log('districtIdValidator(): Value unchanged...');
                return of(null);
            }

            if (isNaN(+control.value) || +control.value < 0) {
                console.log('districtIdValidator(): Value not a number or not positive...');

                return of({ number: 'ID Must be a positive number.' });
            }

            this.districtId = +control.value;

            if (!this.districtId) {
                console.log('districtIdValidator(): Value cleared...');

                if (this.editor.value.type == SchoolType.PUBLIC) {
                    return of({ publicNoDistrict: true });
                }

                this.districtName = null;
                this.editor.controls.districtName.patchValue('');

                return of(null);
            }

            if (this.districtId == this.school.districtId) {
                console.log('districtIdValidator(): Value equal to model value...');
                this.districtName = this.school.districtName;
                this.editor.controls.districtName.patchValue(this.districtName);

                return of(null);
            }

            console.log(`districtIdValidator(): Validating value of ${this.districtId}...`);
            this.store.dispatch(new fromStore.LookupDistrictById({ id: this.districtId }));

            return this.districtLookups$.pipe(
                tap(ls => {
                    if (ls.idLookups[this.districtId].pending) {
                        this.inLookup = true;
                        this.districtName = null;
                    }
                }),
                filter(ls => {
                    console.log('filter: ', this.districtId, ls);

                    return !ls.idLookups[this.districtId].pending;
                }),
                map(ls => {
                    const lookup = ls.idLookups[this.districtId];
                    const error = lookup.errors;

                    console.log('map: ', this.districtId, error);

                    this.inLookup = false;

                    if (lookup.district) {
                        this.districtId = lookup.district.id;
                        this.districtName = lookup.district.longName;
                        this.editor.controls.districtName.patchValue(this.districtName);
                    }

                    this.cdr.markForCheck();

                    return error ? { server: error } : null;
                }),
                take(1),
            );
        };
    }

    onValidateDistrictId() {
        console.log('onValidateDistrictId(): ', this.editor.value.districtId);

        this.editor.controls.districtId.updateValueAndValidity();
        //this.districtLookup.emit(this.formState.controls.districtId.value);
    }
}
