import * as fromMessageAction from '../actions/message.action';
import * as fromModels from '../../models';

export interface MessageState {
    message: fromModels.Message;
    errors: any;	//	TODO: formalize error handling
}

export const initialState: MessageState = {
    message: null,
    errors: null
};

export function reducer(state = initialState, action: fromMessageAction.MessageActions): MessageState {
    switch (action.type) {
        case fromMessageAction.MessageActionTypes.DisplayMessage: {
            const message = action.payload;

            return {
                ...state,
                message
            };
        }
        case fromMessageAction.MessageActionTypes.ClearMessage: {
            const message = null;

            return {
                ...state,
                message
            };
        }
    }

    return state;
}

export const getMessageState = (state: MessageState) => state.message;
