import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { tap, switchMap, map, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as EventSearchResultsActions from '../actions/event-searchresults.action';
import * as fromServices from '../../../scp-common/services';
import * as fromModels from '../../models';
import * as fromReducers from '../reducers';

import { SearchResultsState } from '..';

@Injectable()
export class EventSearchResultsEffects {
    constructor(
        private actions$: Actions,
        private EventService: fromServices.EventService,
        private store$: Store<SearchResultsState>,
    ) { }

    
    processQueryString$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.ProcessQueryString),
        switchMap((action: EventSearchResultsActions.ProcessQueryString) => {
            // Grab an object representation of the query string
            const query = this.parseQueryStringFromUrl(action.payload);
            // Convert query object into our strict-typed object
            let searchCriteria: fromModels.EventSearchCriteria;

            // Populate search criteria with the query object params
            searchCriteria = {
                id: query['id'],
                isActive: query['isActive'],
                name: query['name'],
                attendingSchoolId: query['attendingSchoolId'],
                hostingState: query['hostingState'],
                eventType: query['eventType'],
                eventDateFrom: query['eventDateFrom'],
                eventDateTo: query['eventDateTo'],
                districtId: query['districtId'],
                code: query['code'],
                primaryRentalFulfillment: query['primaryRentalFulfillment'],
                PageNumber: query['PageNumber'],
                PageSize: query['PageSize'],
                SortBy: query['SortBy'],
                SortDir: query['SortDir']
            };

            return of(new EventSearchResultsActions.LoadEventSearchResults(searchCriteria));
        })
    ));

    
    getResultsForSchool$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.GetResultsForSchool),
        switchMap((action: EventSearchResultsActions.GetResultsForSchool) => {
            console.log(action.payload);

            // Whatever state we have, reset it to initial
            const newCriteria: fromModels.EventSearchCriteria = {
                ...fromReducers.initialState.searchCriteria,
                // Use the school's id as our search criteria
                attendingSchoolId: action.payload.id
            };

            return of(new EventSearchResultsActions.LoadEventSearchResults(newCriteria));
        })
    ));

    
    nextPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.NextPage),
        switchMap((action: EventSearchResultsActions.NextPage) => {
            console.log(action.pagingSorting);
            const currentPage: number = action.pagingSorting.currentPage;
            let nextPage: number;

            // If page number is null, maybe we never got first page?
            if (currentPage == null) {
                // Go to the first one
                nextPage = 1;
            }
            else if (currentPage < action.pagingSorting.totalPages) {
                // Increment to next page
                nextPage = currentPage + 1;
            }
            else {
                // Don't change the page
                nextPage = currentPage;
            }

            // Set the next page to pass to our search request
            const newPayload: fromModels.EventSearchCriteria = {
                ...action.payload,
                PageNumber: nextPage
            };
            // TODO: Figure out some way to not do any action here if page number didn't change
            return of(new EventSearchResultsActions.LoadEventSearchResults(newPayload));
        })
    ));

    
    prevPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.PrevPage),
        switchMap((action: EventSearchResultsActions.PrevPage) => {
            console.log(action.pagingSorting);
            const currentPage: number = action.pagingSorting.currentPage;
            let prevPage: number;

            // If page number is null, maybe we never got first page?
            if (currentPage == null) {
                // Go to the first one
                prevPage = 1;
            }
            else if (currentPage > 1) {
                // Increment to next page
                prevPage = currentPage - 1;
            }
            else {
                // Don't change the page
                prevPage = currentPage;
            }

            // Set the next page to pass to our search request
            const newPayload: fromModels.EventSearchCriteria = {
                ...action.payload,
                PageNumber: prevPage
            };
            // TODO: Figure out some way to not do any action here if page number didn't change
            return of(new EventSearchResultsActions.LoadEventSearchResults(newPayload));
        })
    ));

    
    sortByResult$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.SortBy),
        switchMap((action: EventSearchResultsActions.SortBy) => {
            // Default to ascending sort order
            let sortDir = 0;

            // If user is sorting by same column again
            if (action.payload == action.pagingSorting.sortBy) {
                // Change the direction of the sort since it's the same column
                sortDir = action.pagingSorting.sortDir == 0 ? 1 : 0;
            }
            const lastPage: number = action.pagingSorting.totalPages;

            // Set the next page to pass to our search request
            const newPayload: fromModels.EventSearchCriteria = {
                ...action.searchCriteria,
                SortDir: sortDir,
                SortBy: action.payload
            };

            return of(new EventSearchResultsActions.LoadEventSearchResults(newPayload));
        })
    ));

    
    lastPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.LastPage),
        switchMap((action: EventSearchResultsActions.LastPage) => {
            const lastPage: number = action.pagingSorting.totalPages;

            // Set the next page to pass to our search request
            const newPayload: fromModels.EventSearchCriteria = {
                ...action.payload,
                PageNumber: lastPage
            };

            return of(new EventSearchResultsActions.LoadEventSearchResults(newPayload));
        })
    ));

    
    firstPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.FirstPage),
        switchMap((action: EventSearchResultsActions.FirstPage) => {
            const firstPage = 1;

            // Set the next page to pass to our search request
            const newPayload: fromModels.EventSearchCriteria = {
                ...action.payload,
                PageNumber: firstPage
            };

            return of(new EventSearchResultsActions.LoadEventSearchResults(newPayload));
        })
    ));

    
    pageSizeResult$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.SetPageSize),
        switchMap((action: EventSearchResultsActions.SetPageSize) => {
            const pageSize: number = action.payload;

            // Set the next page to pass to our search request
            const newPayload: fromModels.EventSearchCriteria = {
                ...action.searchCriteria,
                PageSize: pageSize
            };

            return of(new EventSearchResultsActions.LoadEventSearchResults(newPayload));
        })
    ));

    
    searchResults$ = createEffect(() => this.actions$.pipe(
        ofType(EventSearchResultsActions.EventSearchResultsActionTypes.LoadEventSearchResults),
        switchMap((action: EventSearchResultsActions.LoadEventSearchResults) => {
            console.log('Load Event Search Results Effect');
            console.log(action.payload);

            let query = '';

            Object.keys(action.payload).forEach(key => {
                const value = action.payload[key];
                if (value === undefined) {
                    // Any missing value we just ignore
                    return;
                }

                // If item has a value...
                if (value !== null && value !== '') {
                    // If it's one of these keys and value is "Any"
                    const anyKeys = ['state', 'isSupervisory', 'isActive'];
                    if (anyKeys.some(i => i === key) && value == 'Any') {
                        // Treat "any" like null.
                        return;
                    }
                    // Else, add it to query string
                    else {
                        query += `&${key}=${value}`;
                    }
                }
            });

            return this.EventService.searchEvents(query).pipe(
                tap(d => {
                    console.log('searchEvents returned:', d);
                }),
                map(resp => {
                    if (!resp.ok || resp.body == null) {
                        return new EventSearchResultsActions.LoadEventSearchResultsFailure('Search results not returned');
                    }

                    return new EventSearchResultsActions.LoadEventSearchResultsSuccess(resp.body, resp.headers);
                }),
                catchError(error => of(new EventSearchResultsActions.LoadEventSearchResultsFailure(error)))
            );
        })
    ));

    private parseQueryStringFromUrl(url: string): object {
        const returnObject: object = {
        };

        // Parse query string into chunks
        const splitted = url.split('?', 2);
        // If we have a query string, then process it further
        if (splitted.length == 2) {
            // Split out each key/value pair
            const splittedItems = splitted[1].split('&');
            splittedItems.forEach(item => {
                // Split out the key and value
                const splittedItem = item.split('=');
                const key = splittedItem[0];
                const value = splittedItem.length > 1 ? splittedItem[1] : '';
                // Add is as a key/value pair in our return object.
                returnObject[key] = value;
            });
        }

        return returnObject;
    }
}
