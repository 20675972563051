import { Injectable } from '@angular/core';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { MessageType, ProblemDetails } from '../../../core/models';
import * as fromRoot from '../../../core/store';

import * as fromActions from '../actions';
import * as fromModels from '../../models';
import * as fromServices from '../../services';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class DistributionEffects {
	constructor(
		private actions$: Actions,
		private distributionService: fromServices.DistributionService
	) { }

    loadShippingCharges$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.DistributionActionTypes.LoadShippingCharges),
		switchMap((action: fromActions.LoadShippingCharges) => {
			if (!action.payload.rentalCategoryCode && !action.payload.state)
				return of(new fromActions.LoadShippingChargesSuccess([]));

			return this.distributionService.getShippingCharges(action.payload).pipe(
				map((results: fromModels.ShippingCharge[]) => {
					return new fromActions.LoadShippingChargesSuccess(results);
				}),
				catchError((res: HttpErrorResponse) => of(new fromActions.LoadShippingChargesFailure(res)))
			)
		})
    ))

	loadShippingChargesFailure$ = createEffect(() => this.actions$.pipe(
		ofType(fromActions.DistributionActionTypes.LoadShippingChargesFailure),
		switchMap((action: fromActions.LoadShippingChargesFailure) =>
			of(new fromRoot.DisplayMessage({
				message: this.getErrorMessage(action.payload),
				messageType: MessageType.alert,
				toast: false
			}))
		)
	))

	private getErrorMessage(response: HttpErrorResponse): string {
		if (response.status === 400 && response.error) {
            try {
                const problemDetails = response.error as ProblemDetails;
                if (problemDetails) {
                    const firstError = problemDetails.errors ? problemDetails.errors[Object.keys(problemDetails.errors)[0]] : null;
                    if (firstError)
                        return firstError;
                    else if (problemDetails.title)
                        return problemDetails.title;
                }
            }
            catch (ex) {
                console.warn(ex);
            }
        }

        return response.error ? response.error.message : response.message;
	}
}
