import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { District } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-district-calendar-editor',
	templateUrl: './district-calendar-editor.component.html',
})
export class DistrictCalendarEditorComponent implements OnInit, OnDestroy {
	alive = true;
	district$: Observable<District>;
	panelState$: Observable<fromStore.PanelState>;
	district: District;
	panelState: fromStore.PanelState;
	panelId = fromStore.PanelId.Calendar;
	editor: FormGroup;
	months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	days: { [id: string]: string[] } = {};
	schoolStartFrom: Date;
	schoolStartTo: Date;
	schoolEndFrom: Date;
	schoolEndTo: Date;

	constructor(private store: Store<fromStore.DistrictsState>, private fb: FormBuilder) {
		console.log('DistrictCalendarEditorComponent()...');
	}

	ngOnInit() {
		console.log('DistrictCalendarEditorComponent.ngOnInit()...');

		this.district$ = this.store.pipe(select(fromStore.getDetailsStateDistrict));
		this.district$.pipe(
			takeWhile(() => this.alive)
		).subscribe(d => this.district = d);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateCalendarPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('DistrictCalendarEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('DistrictCalendarEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		this.schoolStartFrom = this.district.schoolStartFrom
			? new Date(Date.parse(this.district.schoolStartFrom))
			: new Date(9999, 8, 1);
		this.schoolStartTo = this.district.schoolStartTo
			? new Date(Date.parse(this.district.schoolStartTo))
			: new Date(9999, 8, 1);
		this.schoolEndFrom = this.district.schoolEndFrom
			? new Date(Date.parse(this.district.schoolEndFrom))
			: new Date(9999, 5, 1);
		this.schoolEndTo = this.district.schoolEndTo
			? new Date(Date.parse(this.district.schoolEndTo))
			: new Date(9999, 5, 1);

		this.editor = this.fb.group({
			schoolStartFromMonth: [this.schoolStartFrom.getMonth()],
			schoolStartFromDay: [this.schoolStartFrom.getDate()],
			schoolStartToMonth: [this.schoolStartTo.getMonth()],
			schoolStartToDay: [this.schoolStartTo.getDate()],
			schoolEndFromMonth: [this.schoolEndFrom.getMonth()],
			schoolEndFromDay: [this.schoolEndFrom.getDate()],
			schoolEndToMonth: [this.schoolEndTo.getMonth()],
			schoolEndToDay: [this.schoolEndTo.getDate()],
		});

		this.initMonthChanges();
	}

	resetForm() {
		this.editor.reset({
			schoolStartFromMonth: this.schoolStartFrom.getMonth(),
			schoolStartFromDay: this.schoolStartFrom.getDate(),
			schoolStartToMonth: this.schoolStartTo.getMonth(),
			schoolStartToDay: this.schoolStartTo.getDate(),
			schoolEndFromMonth: this.schoolEndFrom.getMonth(),
			schoolEndFromDay: this.schoolEndFrom.getDate(),
			schoolEndToMonth: this.schoolEndTo.getMonth(),
			schoolEndToDay: this.schoolEndTo.getDate(),
		});
	}

	initMonthChanges() {
		const keys: string[] = ['schoolStartFrom', 'schoolStartTo', 'schoolEndFrom', 'schoolEndTo'];

		for (const key of keys) {
			this.days[this[key].getMonth()] = this.getDaysInMonth(this[key].getMonth());

			this.editor.get(key + 'Month').valueChanges.pipe(
				takeWhile(() => this.alive),
			).subscribe(val => {
				console.log(key + 'Month.valueChanges: ', val);

				if (!this.days[val]) {
					this.days[val] = this.getDaysInMonth(val);
				}

				const ctrl = this.editor.get(key + 'Day');
				const lastDay = this.days[val][this.days[val].length - 1];

				if (+ctrl.value > +lastDay) {
					ctrl.setValue(lastDay, { emitEvent: false });
				}
			});
		}
	}

	getDaysInMonth(month) {
		const days: string[] = [];

		for (let i = 1; i <= new Date(9999, +month + 1, 0).getDate(); i++) {
			days.push(i.toString());
		}

		return days;
	}

	get canSave(): boolean {
		return this.editor.valid && this.editor.dirty && !this.panelState.updating;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status);

		const schoolStartFrom = `${+this.editor.value.schoolStartFromMonth + 1}/${this.editor.value.schoolStartFromDay}/9999`;
		const schoolStartTo = `${+this.editor.value.schoolStartToMonth + 1}/${this.editor.value.schoolStartToDay}/9999`;
		const schoolEndFrom = `${+this.editor.value.schoolEndFromMonth + 1}/${this.editor.value.schoolEndFromDay}/9999`;
		const schoolEndTo = `${+this.editor.value.schoolEndToMonth + 1}/${this.editor.value.schoolEndToDay}/9999`;

		const district = {
			...this.district,
			schoolStartFrom,
			schoolStartTo,
			schoolEndFrom,
			schoolEndTo,
		};

		this.store.dispatch(new fromStore.UpdateDistrictPanel({ panel: fromStore.PanelId.Calendar, district }));
	}
}
