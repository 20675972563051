import { Component, Input } from '@angular/core';

import { Event } from '../../models';

@Component({
    selector: 'event-date-time',
    templateUrl: './event-date-time.component.html',
})
export class EventDateTimeComponent {
    @Input() event: Event;

}
