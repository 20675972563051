import { Action } from '@ngrx/store';

export enum OidcActionTypes {
    ConfigLoaded = '[OIDC] Config Loaded',
    AuthorizationResult = '[OIDC] Authorization Result'
}

export class OidcConfigLoaded implements Action {
    readonly type = OidcActionTypes.ConfigLoaded;
}

export class OidcAuthorizationResult implements Action {
    readonly type = OidcActionTypes.AuthorizationResult;
    constructor(readonly payload: boolean) { }
}

export type OidcActions =
    | OidcConfigLoaded
    | OidcAuthorizationResult
    ;
