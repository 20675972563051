import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

declare var $: any;
declare var Foundation: any;

import { ProgramType, ProgramTypeLookup } from '../../scp-common/models';
import { SchoolDetails, SchoolLookup } from '../../schools/models';
import { TeacherDetails } from '../../teachers/models';
import * as fromStore from '../store';

@Component({
    selector: 'opus-program-create',
    templateUrl: './program-create.component.html',
})
export class ProgramCreateComponent implements OnInit, OnDestroy, OnChanges {
    @Input() school: SchoolDetails;
    @Input() teacher: TeacherDetails;
    @Input() visible = false;
    @Input() activeProgramTypes: string[] = [];
    @Output() action = new EventEmitter<boolean>();

    @ViewChild('schoolDropdown', {static: true})
    schoolDropdown: ElementRef;
    schoolFoundationDropdown: any = null;
    lookupSchoolName: string;

    alive = true;
    state$: Observable<fromStore.ProgramCreateState>;
    state: fromStore.ProgramCreateState;
    form: FormGroup;
    submitting = false;
    lookupSchoolId: number;

    constructor(private store: Store<fromStore.ProgramCreateState>, private cdr: ChangeDetectorRef, private fb: FormBuilder) {
        console.log('ProgramCreateComponent()...');
    }

    ngOnInit() {
        console.log('ProgramCreateComponent.ngOnInit()...');

        this.createForm();

        this.state$ = this.store.pipe(select(fromStore.getProgramCreateState));
        this.state$.pipe(
            takeWhile(() => this.alive),
        ).subscribe(s => {
            this.state = s;

            if (this.submitting && s.errors !== null) {
                this.submitting = false;
            }

            if (this.submitting && s.created) {
                this.submitting = false;
                this.resetForm();
                this.action.emit(true);
            }

            if (s.schoolLookup.pending && this.lookupSchoolId == s.schoolLookup.id) {
                this.form.controls.schoolName.disable();
            } else if (!this.school) {
                this.form.controls.schoolName.enable();
            }

            if (s.schoolLookup.errors && !s.schoolLookup.pending) {
                this.form.controls.schoolId.setErrors({ 'id': true });
            }

            if (s.schoolLookup.school) {
                this.form.controls.schoolName.setValue(s.schoolLookup.school.longName);
            }

            if (s.schoolByNameLookup.schools && s.schoolByNameLookup.name == this.lookupSchoolName) {
                const native = $(this.schoolDropdown.nativeElement).first();
                console.log('ProgramCreateComponent: Creating dropdown...', native);
                this.schoolFoundationDropdown = new Foundation.Dropdown(native);

                if (!native.hasClass('is-open')) {
                    native.foundation('open');
                }
            }

            this.cdr.detectChanges();
        });

        //console.log(this.school);
    }

    ngOnDestroy() {
        console.log('ProgramCreateComponent.ngOnDestroy()...');

        this.alive = false;

        if (this.schoolFoundationDropdown != null) {
            this.schoolFoundationDropdown.destroy();
        }

        this.schoolFoundationDropdown = null;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.school && this.form) {
            this.resetForm();
        }
    }

    createForm() {
        this.form = this.fb.group({
            programType: ['', Validators.required],
            schoolId: [this.school ? { value: this.school.id + '', disabled: true } : '', this.idValidator()],
            schoolName: [this.school ? { value: this.school.longName, disabled: true } : ''],
        });
    }

    resetForm() {
        this.lookupSchoolName = null;
        this.lookupSchoolId = null;
        this.form.reset({
            programType: '',
            schoolId: this.school ? this.school.id + '' : '',
            schoolName: this.school ? this.school.longName : '',
        });
    }

    get programTypes(): { id: ProgramType, name: string }[] {
        //const pts = ProgramTypeLookup.filter(ptl => ptl.id != ProgramType.NoMTDSubscription);
        const pts = ProgramTypeLookup.filter(ptl => ptl.id != ProgramType.NoMTDSubscription && (this.activeProgramTypes ? !this.activeProgramTypes.includes(ptl.name) : true));

        //console.log('programTypes(): ', pts);

        return pts;
    }

    get isValid(): boolean {
        return this.form.valid;
    }

    lookupSchool() {
        this.lookupSchoolId = +this.form.value.schoolId;

        this.store.dispatch(new fromStore.SchoolLookupById({ id: this.lookupSchoolId }));
    }

    lookupSchoolByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupSchoolName = this.form.value.schoolName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.schoolDropdown.nativeElement).first();

            if (native.hasClass('is-open')) {
                native.foundation('close');
            }
        } else {
            if (this.lookupSchoolName && this.lookupSchoolName.length > 3) {
                this.store.dispatch(new fromStore.SchoolLookupReset());
                this.store.dispatch(new fromStore.SchoolLookupByName({ name: this.lookupSchoolName }));
            }
        }
    }

    selectSchool(school: SchoolLookup) {
        if (school) {
            this.schoolFoundationDropdown = null;
            this.form.controls.schoolName.patchValue(school.longName);
            this.form.controls.schoolId.patchValue(school.id + '');
        }
    }

    idValidator(): ValidatorFn {
        return (ctrl: FormControl): { [key: string]: boolean } | null => {
            const val = Number(ctrl.value);

            if (!Number.isInteger(val) || val <= 0) {
                return { 'id': true };
            }

            return null;
        };
    }

    onModalAction(accept) {
        console.log('ProgramCreateComponent.onModalAction(): ', accept);

        if (!accept) {
            this.action.emit(false);
            this.resetForm();

            return;
        }

        const program = {
            programTypeId: ProgramType[ProgramType[this.form.value.programType]],
            schoolId: this.school ? this.school.id : +this.form.value.schoolId,
            teacherId: this.teacher ? this.teacher.id : null,
        };

        this.submitting = true;
        this.store.dispatch(new fromStore.CreateProgram({ program }));
    }
}
