import { createSelector } from "@ngrx/store";

import * as fromReducers from '../reducers';

export const getDistributionDetailsState = createSelector(
    fromReducers.getDistributionState,
    (state: fromReducers.DistributionState) => state.details
);

export const getShippingCharges = createSelector(
    getDistributionDetailsState,
    (state: fromReducers.DistributionDetailsState) => state.shippingCharges
);
