import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { TabComponent } from './tab.component';

@Component({
	selector: 'opus-tabs',
	templateUrl: './tabs.component.html',
})
export class TabsComponent implements OnInit {
	@Input('activeTabIndex')
	set activeTabIndex(value: number) {
		if (value >= 0 && value < this.tabs.length) {
			this.selectTab(value);
		}
	}

	@Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();
	tabs: TabComponent[] = [];
	private _activeTabIndex?: number = null;

	constructor() {
		console.log('TabsComponent()...');
	}

	ngOnInit() {
		console.log('TabsComponent.ngOnInit(): ', this._activeTabIndex);

		if (this._activeTabIndex === null) {
			this.activeTabIndex = 0;
		}
	}

	get activeTabIndex() {
		return this._activeTabIndex;
	}

	addTab(tab: TabComponent) {
		console.log('TabsComponent.AddTab(): ', tab);

		this.tabs.push(tab);
	}

	selectTab(index: number) {
		this.tabs.forEach(tab => {
			tab.active = false;
		});

		this.tabs[index].active = true;
		this._activeTabIndex = index;
		this.tabSelected.emit(index);
	}
}
