import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProgramDetails from '../reducers/program-details.reducer';

export const getProgramDetailsState = createSelector(
	fromFeature.getProgramsState,
	(state: fromFeature.ProgramsState) => state.programDetails
);

export const getProgramDetailsEntity = createSelector(
	getProgramDetailsState,
	fromProgramDetails.getProgramDetailsEntity,
);

export const getProgramDetailsSchool = createSelector(
	getProgramDetailsEntity,
	fromProgramDetails.getProgramDetailsSchool,
);

export const getProgramDetailsLoaded = createSelector(
	getProgramDetailsState,
	fromProgramDetails.getProgramDetailsLoaded,
);

export const getProgramDetailsLoading = createSelector(
	getProgramDetailsState,
	fromProgramDetails.getProgramDetailsLoading,
);

export const getDetailsStatePanels = createSelector(
	getProgramDetailsState,
	fromProgramDetails.getDetailsStatePanels
);

export const getDetailsStateInfoPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Info],
);

export const getDetailsStateTeacherLookup = createSelector(
    getProgramDetailsState,
    fromProgramDetails.getDetailsStateTeacherLookup,
);

export const getDetailsStateTeacherByCriteriaLookup = createSelector(
    getProgramDetailsState,
    fromProgramDetails.getDetailsStateTeacherByCriteriaLookup,
);

export const getDetailsStateAdditionalDetailsPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.AdditionalDetails],
);

export const getDetailsStateProgramTeachersPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.ProgramTeachers],
);
