import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';

import { HistoryDetails, HistoryEvent, HistoryContext } from '../models';

@Component({
  selector: 'opus-history',
  templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit, OnDestroy {
  bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue' };
  history$: Observable<HistoryDetails>;
  selectedEvent: HistoryEvent;
  historySearchForm: FormGroup;
  context: HistoryContext;

  private alive = true;

  //function to validate date ranges
  dateRangeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const fromDateControl = control.get('fromDate');
    const toDateControl = control.get('toDate');

    if (!fromDateControl.value || !toDateControl.value) {
      return null;
    }

    const fromDate = new Date(fromDateControl.value);
    const toDate = new Date(toDateControl.value);

    const isInvalid = fromDate > toDate;
    return isInvalid ? { invalidrange: true } : null;
  }

  constructor(private store: Store<fromStore.DetailsState>, private formBuilder: FormBuilder) {
    console.log('HistoryComponent()...');
  }

  get fromDate() {
    return this.historySearchForm.get('fromDate');
  }

  get toDate() {
    return this.historySearchForm.get('toDate');
  }

  ngOnInit() {
    console.log('HistoryComponent.ngOnInit()...');
    const today = new Date();
    const fromDate = new Date(today);
    fromDate.setDate(today.getDate() - 30);

    this.historySearchForm = this.formBuilder.group(
      {
        fromDate: [fromDate, Validators.required],
        toDate: [today, Validators.required]
      },
      { validator: this.dateRangeValidator }
    );

    this.history$ = this.store.pipe(select(fromStore.getHistoryDetails));
    this.history$.pipe(takeWhile(() => this.alive)).subscribe(state => {
      console.log('$history: ', state);
      if (state && state.context) {
        this.context = state.context;
      }
    });
  }

  ngOnDestroy() {
    console.log('HistoryComponent.ngOnInit()...');
    this.alive = false;
  }

  onSelectEvent($event) {
    console.log('HistoryComponent.onSelectEvent(): ', $event);

    this.selectedEvent = $event;
  }

  filterHistoryEvents() {
    const { fromDate, toDate } = this.historySearchForm.value;
    const { entityTypeName: contextName, entityId: contextId, scope } = this.context;

    this.store.dispatch(
      new fromStore.LoadHistory({
        contextName: (contextName || '').toLowerCase(),
        contextId,
        scope: (scope || '').toLowerCase(),
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString()
      })
    );
  }
}
