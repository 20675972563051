import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';

import { Observable } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';

import { Operation } from 'fast-json-patch';

import { Event, EventSearchResultsItem, EventCreate, EventPromo } from '../../events/models';

@Injectable()
export class EventService {
  private baseUrl: string;

  constructor(private http: HttpClient, private configuration: ConfigurationService) {}

  loadEvent(eventId: number): Observable<Event> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.get<Event>(`${c.config.apiServer}api/events/details/${eventId}`);
      })
    );
  }

  loadRelationshipManagers(eventId: number): Observable<string[]> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.get<string[]>(`${c.config.apiServer}api/events/${eventId}/relationshipManagers`);
      })
    );
  }

  patchEvent(id: number, patch: Operation[]): Observable<Event> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.patch<Event>(`${c.config.apiServer}api/events/${id}`, patch);
      })
    );
  }

  searchEvents(query: string): Observable<HttpResponse<EventSearchResultsItem[]>> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        // TODO: Change to api/events when implemented
        return this.http.get<EventSearchResultsItem[]>(`${c.config.apiServer}api/events?${query}`, { observe: 'response' });
      })
    );
  }

  createEvent(event: EventCreate): Observable<number> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.post<number>(`${c.config.apiServer}api/events`, event);
      })
    );
  }

  copyEvent(id: number, name: string, date: Date): Observable<Event> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.post<Event>(`${c.config.apiServer}api/events/${id}/copy?name=${name}&date=${date.toISOString()}`, event);
      })
    );
  }

  loadApprovedPromos(): Observable<EventPromo[]> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.get<EventPromo[]>(`${c.config.apiServer}api/events/approvedpromotions`);
      })
    );
  }


  getQrCodesFile(id: number): Observable<boolean | HttpErrorResponse> {
    return this.configuration.config$.pipe(
      switchMap((c: ConfigurationState) => {
        return this.http.get(`${c.config.apiServer}api/events/${id}/qrcodes`, { responseType: 'blob' }).pipe(
          tap(rsp => {
            console.log(rsp);
          }),
          map(rsp => {
            const fileName = `Event-${id}-QRCodes.zip`;
            this.saveReport(rsp, fileName);
            return true;
          }),
        );
      })
    );
  }

  private saveReport(blob: Blob, fileName: string) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(function () { window.URL.revokeObjectURL(url); }, 1000);
      a.remove(); // remove the element
  }
}
