import * as fromConfigurationAction from '../actions/configuration.action';
import * as fromModels from '../../models';

export interface ConfigurationState {
    loading: boolean;
    loaded: boolean;
    config: fromModels.Configuration;
    errors: any;	//	TODO: formalize error handling
}

export const initialState: ConfigurationState = {
    loading: false,
    loaded: false,
    config: null,
    errors: null,
};

export function reducer(state = initialState, action: fromConfigurationAction.ConfigurationActions): ConfigurationState {
    switch (action.type) {
        case fromConfigurationAction.ConfigurationActionTypes.LoadConfiguration: {
            return {
                ...state,
                loading: true,
            };
        }

        case fromConfigurationAction.ConfigurationActionTypes.LoadConfigurationSuccess: {
            const event = action.payload;

            return {
                ...updateDetailsState(state, event),
                loaded: true,
                loading: false,
            };
        }

        case fromConfigurationAction.ConfigurationActionTypes.LoadConfigurationFailure: {
            console.log('reducer:', action);

            return {
                ...state,
                loaded: false,
                loading: false,
                errors: action.payload,
            };
        }
    }

    return state;
}

function updateDetailsState(state: ConfigurationState, config: fromModels.Configuration) {
    return {
        ...state,
        config,
    };
}

export const getConfigStateDistrict = (state: ConfigurationState) => state.config;
export const getConfigStateLoading = (state: ConfigurationState) => state.loading;
export const getConfigStateLoaded = (state: ConfigurationState) => state.loaded;
