  <!-- always display -->
<div class="property-block__content-wrapper">
    <div class="property-block__content">
        <div class="table table--align-left" role="table">
            <div role="rowgroup">
                <div class="table__row table__row--header" role="rowheader">
                    <span class="table__cell table__cell--shrink" role="columnheader" *ngIf="editMode">
                        <span class="sr-only">Remove</span>
                    </span>
                    <span class="table__cell table__cell--half" role="columnheader">School ID</span>
                    <span class="table__cell table__cell--double" role="columnheader">School Name</span>
                    <span class="table__cell table__cell--half" role="columnheader">OLR Link</span>
                </div>
            </div>
            <div role="rowgroup">
                <!-- Host school is automatically added as an attending school and cannot be removed -->
                <div class="table__row table__row--align-middle" role="row">
                    <span class="table__cell table__cell--shrink table__cell--no-padding" role="cell" *ngIf="editMode"></span>
                    <span class="table__cell table__cell--half" role="cell">{{ event.hostingSchoolId}}</span>
                    <span class="table__cell table__cell--double" role="cell">
                        <a class="link--tappable" routerLink="/schools/{{ event.hostingSchoolId }}">{{ event.hostingSchoolName }}</a>
                    </span>
                    <span class="table__cell table__cell--half" role="cell">
                        <a class="link--tappable" href="{{event.hostingSchoolOlrLink}}" target="_blank">Click for OLR</a>
                    </span>
                </div>
                <div class="table__row table__row--align-middle table__row--last table__row--alt" role="row" *ngFor="let school of event.eventSchools">
                    <span class="table__cell table__cell--shrink table__cell--no-padding" role="cell" *ngIf="editMode">
                        <button class="btn--delete" title="Remove school" (click)="removeSchool(school)">
                            <!--ng-click="EventSchoolsAttendingCtrl.removeSchool($index)"-->
                            <span class="sr-only">Remove school</span>
                        </button>
                    </span>
                    <span class="table__cell table__cell--half" role="cell">{{school.schoolId}}</span>
                    <span class="table__cell table__cell--double" role="cell">
                        <a class="link--tappable" routerLink="/schools/{{ school.schoolId }}">{{school.schoolName}}</a>
                    </span>
                    <span class="table__cell table__cell--half" role="cell">
                        <a class="link--tappable" href="{{school.olrLink}}" target="_blank">Click for OLR</a>
                    </span>
                </div>
            </div>
            <div class="spinner-section" [class.spinner-section--is-active]="(qrCodeFiledownloadState$ | async).loading" *ngIf="!editMode">
                <div class="spinner-section__icon"></div>
                <div class="property-block__action-wrapper">
                    <div>
                        <button (click)="downloadQrCodes(event)" class="property-block__action-btn">Download QR Codes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /always display -->
