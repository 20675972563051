import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromMerch from './merch.reducer';

export interface MerchState {
	combosState: fromMerch.CombosState;
}

export const reducers: ActionReducerMap<MerchState> = {
	combosState: fromMerch.reducer,
};

export const getMerchState = createFeatureSelector<MerchState>('merch');

export { CombosState } from './merch.reducer';
