import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ShippingCharge, ShippingChargesCriteria } from '../models';
import { DistributionDetailsState, DistributionState } from '../store';
import { takeWhile } from 'rxjs/operators';

import * as fromStore from '../store';

@Component({
    selector: 'opus-distribution-landing',
    templateUrl: './distribution-landing.component.html',
})
export class DistributionLandingComponent implements OnInit, OnDestroy {
    private _alive = true;
    criteria: ShippingChargesCriteria;
    shippingCharges$: Observable<ShippingCharge[]>
    vm: DistributionDetailsState;
    vm$: Observable<DistributionDetailsState>;

    constructor(
        private store: Store<DistributionState>
    ) {
        this.vm$ = this.store.pipe(select(fromStore.getDistributionDetailsState));
        this.shippingCharges$ = this.store.pipe(select(fromStore.getShippingCharges));
    }

    ngOnInit(): void {
        this.vm$.pipe(
            takeWhile(() => this._alive),
        ).subscribe(vm => {
            this.vm = vm;
        });
        
        this.shippingCharges$.pipe(
            takeWhile(() => this._alive)
        );
    }

    ngOnDestroy(): void {
        this._alive = false;
    }

    onCriteriaChange(criteria: ShippingChargesCriteria) {
        this.criteria = criteria;
        this.store.dispatch(new fromStore.LoadShippingCharges(criteria));
    }
}
