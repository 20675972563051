import { ShippingChargesHeader } from './shipping-charges-header.component';
import { ShippingChargesTableComponent } from './shipping-charges-table.component';

export const components: any[] = [
	ShippingChargesHeader,
	ShippingChargesTableComponent
];

export * from './shipping-charges-header.component';
export * from './shipping-charges-table.component';
