<form name="schoolInformationForm" aria-label="School Information" [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>

    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editBeginningGrade" class="property-block__label">Beginning Grade</label>
            <div class="property-block__value">
                <select id="editBeginningGrade" class="ng-pristine ng-untouched ng-valid ng-not-empty" formControlName="beginningGrade">
                    <option value="-1">Unknown</option>
                    <option value="0">K</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">College</option>
                </select>
            </div>
        </li>
		<li class="property-block__list-item">
			<label for="editNumberBeginningGrade" class="property-block__label">Beginning Grade Student Count</label>
			<div class="property-block__value">
				<input type="text" class="ng-pristine ng-untouched ng-valid ng-not-empty" formControlName="numberBeginningGrade" id="editNumberBeginningGrade">
			</div>
			<ul class="error" *ngIf="editor.controls.numberBeginningGrade.invalid">
				<li class="error__message">Beginning Grade Student Count must be greater to or equal to zero, if provided.</li>
			</ul>
		</li>
		<li class="property-block__list-item">
			<label for="editNumberBeginningMusicians" class="property-block__label">Beginning Grade Musician Count</label>
			<div class="property-block__value">
				<input type="text" class="ng-pristine ng-untouched ng-valid ng-not-empty" formControlName="numberBeginningMusicians" id="editNumberBeginningMusicians">
			</div>
			<ul class="error" *ngIf="editor.controls.numberBeginningMusicians.invalid">
				<li class="error__message">Beginning Grade Musician Count must be greater to or equal to zero, if provided.</li>
			</ul>
		</li>
		<li class="property-block__list-item">
			<label for="editNumberSchoolInstruments" class="property-block__label">School Instrument Count</label>
			<div class="property-block__value">
				<input type="text" class="ng-pristine ng-untouched ng-valid ng-not-empty" formControlName="numberSchoolInstruments" id="editNumberSchoolInstruments">
			</div>
			<ul class="error" *ngIf="editor.controls.numberSchoolInstruments.invalid">
				<li class="error__message">School Instrument Count must be greater to or equal to zero, if provided.</li>
			</ul>
		</li>
    </ul>

    <ul class="error" *ngIf="false">
        <li class="error__message" *ngIf="false">Server error</li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
        </div>
    </div>
</form>
