<opus-layout>
	<header>
		<div class="header">
			<div class="row u-m-b-16">
				<div class="header__logo-wrapper">
					<a routerLink="/" class="link--on-dark">
						<span class="icon icon--logo"></span><span class="header__title">OPUS</span>
					</a>
				</div>
				<div class="header__search-wrapper">
					<opus-quick-search></opus-quick-search>
				</div>
				<div class="header__account-wrapper">
					<opus-account-dropdown></opus-account-dropdown>
				</div>
			</div>
			<div class="row">
				<div class="header__nav-wrapper">
					<opus-global-nav ></opus-global-nav>
				</div>
			</div>
		</div>
	</header>
	<main class="row">
		<div class="main-content">
			<router-outlet></router-outlet>
		</div>
	</main>
    <opus-message>

    </opus-message>
</opus-layout>