import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { ShippingCharge, ShippingChargesCriteria } from '../models';

@Component({
    selector: 'opus-shipping-charges-table',
    templateUrl: './shipping-charges-table.component.html'
})
export class ShippingChargesTableComponent {
    @Input() criteria: ShippingChargesCriteria;
    @Input() shippingCharges$: Observable<ShippingCharge[]>;
}
