import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Event, EventPromo } from '../../models';

@Component({
    selector: 'event-promo',
    templateUrl: './event-promo.component.html',
})
export class EventPromoComponent {
    @Input() event: Event;
    @Input() editMode = false;
    @Output() remove: EventEmitter<EventPromo> = new EventEmitter<EventPromo>();

    removePromo(promo: EventPromo) {
        this.remove.emit(promo);
    }

}
