import { CombosComponent } from './combos.component';
import { ProductGroupsComponent } from './product-groups.component';
import { RentalCategoriesDetailsComponent } from './rental-categories-details.component';
import { RentalCategoriesComponent } from './rental-categories.component';

export const containers: any[] = [
  CombosComponent,
  ProductGroupsComponent,
  RentalCategoriesComponent,
  RentalCategoriesDetailsComponent
];

export * from './combos.component';
export * from './product-groups.component';
export * from './rental-categories.component';
export * from './rental-categories-details.component';
