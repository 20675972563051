import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTeacherDetails from '../reducers/teacher-details.reducer';

export const getTeacherDetailsState = createSelector(
	fromFeature.getTeachersState,
	(state: fromFeature.TeachersState) => state.teacherDetails
);

export const getTeacherDetailsEntity = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getTeacherDetailsEntity,
);

export const getTeacherDetailsLoaded = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getTeacherDetailsLoaded,
);

export const getTeacherDetailsLoading = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getTeacherDetailsLoading,
);

export const getDetailsStatePanels = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStatePanels
);

export const getDetailsStateAdditionalPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.Additional],
);

export const getDetailsStateContactPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.Contact],
);

export const getDetailsStateDistrictLookup = createSelector(
    getTeacherDetailsState,
    fromTeacherDetails.getDetailsStateDistrictLookup,
);

export const getDetailsStateDistrictByNameLookup = createSelector(
    getTeacherDetailsState,
    fromTeacherDetails.getDetailsStateDistrictByNameLookup,
);


export const getDetailsStateDistrictPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.District],
);

export const getDetailsStateInfoPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.Info],
);

export const getDetailsStateMailAddrPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.Address] as fromFeature.AddressPanelState,
);

export const getDetailsStateCountyLookup = createSelector(
    getDetailsStateMailAddrPanel,
    fromTeacherDetails.getDetailsStateCountyLookup,
);

export const getDetailsStateCounties = createSelector(
    getDetailsStateCountyLookup,
    (cl) => cl.lookupStateCode != null ? cl.counties[cl.lookupStateCode] : [],
);
