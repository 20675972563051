import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getCombosState = createSelector(
	fromFeature.getMerchState,
	(state: fromFeature.MerchState) => state.combosState
);

export const getAllRentalCategoryState = createSelector (
	getCombosState,
	(state: fromFeature.CombosState) => state.rentalCategories
);
