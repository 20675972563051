import { Component, Input } from '@angular/core';

import { ProgramDetails, InactiveReason } from '../../models';
import { ProgramTypeLookup } from '../../../scp-common/models';

@Component({
	selector: 'opus-program-info',
	templateUrl: './program-info.component.html',
})
export class ProgramInfoComponent {
    @Input() program: ProgramDetails;

    get programType() {
        return ProgramTypeLookup[this.program.programTypeId].name;
    }

    get inactiveReason(): string {
        return InactiveReason[this.program.inactiveReason];
    }

    get to() {
        if (this.program.startTo != null) {
            return 'to';
        }

        return '';
    }
}
