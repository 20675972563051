<section class="property-block">
	<div class="property-block__title-wrapper">
		<h2 class="property-block__title">{{ label }}</h2>
		<button class="property-block__edit-link link" (click)="onToggleEditor()" [opusAuthorize]="policy" [opusRelationshipManagerEmail]="opusRelationshipManagerEmail" [opusRelationshipManagerRoles]="opusRelationshipManagerRoles" *ngIf="canEdit">
			{{ isEditing ? 'Cancel' : 'Edit' }}
		</button>
	</div>

	<ng-content select="[viewer]" *ngIf="!isEditing"></ng-content>

	<ng-content select="[editor]" *ngIf="isEditing"></ng-content>
</section>