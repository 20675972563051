import { ProgramHeaderComponent } from './program-header';
import { ProgramInfoComponent } from './program-info.component';
import { ProgramAdditionalComponent } from './program-additional.component';
import { ProgramRelationshipManagerComponent } from './program-relationshipmanager.component';
import { ProgramProgramTeachersComponent } from './program-program-teachers.component';

export const detailsComponents: any[] = [
    ProgramHeaderComponent,
    ProgramInfoComponent,
    ProgramAdditionalComponent,
    ProgramRelationshipManagerComponent,
    ProgramProgramTeachersComponent
];

export * from './program-header';
export * from './program-info.component';
export * from './program-additional.component';
export * from './program-relationshipmanager.component';
export * from './program-program-teachers.component';
