import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from './ConfigurationService';
import { ConfigurationState } from '../store/reducers';

import { RolePolicy } from '../models';

@Injectable()
export class SecurityService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) { }

    getRolePolicies(): Observable<RolePolicy[]> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<RolePolicy[]>(`${c.config.apiServer}api/security`);
            })
        );
    }
}
