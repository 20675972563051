import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDetails from './details.reducer';
import * as fromSearchResults from './searchresults.reducer';

export interface EventsState {
    details: fromDetails.DetailsState;
    search: fromSearchResults.SearchResultsState;
}

export const reducers: ActionReducerMap<EventsState> = {
    details: fromDetails.reducer,
    search: fromSearchResults.reducer
};

export const getEventsState = createFeatureSelector<EventsState>('events');

export { DetailsState, PanelState, PanelId, ValidationStatus, SchoolLookupState, SchoolByNameLookupState, TeacherLookupState, TeacherByCriteriaLookupState as TeacherByCriteriaLookupState, AddressPanelState, CopyEventState, RelationshipManagerState } from './details.reducer';
export { SearchResultsState, initialState } from './searchresults.reducer';
