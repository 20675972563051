import { DeliveryTimePeriodType } from '../../scp-common/models';

export interface EventCreate {
  name: string;
  eventTypeId: number;
  eventDate: Date;
  eventQuality: EventQuality;
  rentalMeetingTypeId: number;
  rentalFulfillmentTypeId: number;
  hostSchoolId: number;
  hostTeacherId: number;
}

export interface Event {
  id: number;
  isActive: boolean;
  name: string;
  eventTypeId: number;
  eventTypeName: string;
  note: string;
  hostingSchoolDistrictId: number;
  hostingSchoolDistrictName: string;
  hostingSchoolId: number;
  hostingSchoolName: string;
  hostingSchoolRelationshipManagerName: string;
  hostingSchoolOlrLink?: string;
  hostingTeacherId: number;
  hostingTeacherName: string;
  arrival: Date | null;
  start: Date;
  end: Date;
  expirationDate: Date;
  eventAtHostSchool: boolean;
  eventAddress: Address;
  hostingSchoolAddress: Address;
  location: string;
  supervisor: string;
  staffNeeded: number;
  staffNames: string;
  rentalMeetingTypeId: number;
  rentalMeetingTypeName: string;
  rentalFulfillmentTypeId: number;
  rentalFulfillmentTypeName: string;
  mouthpieceTesting: boolean;
  bandProgramAttending: boolean;
  orchestraProgramAttending: boolean;
  estimatedBandRentals: number;
  estimatedOrchestraRentals: number;
  isVirtualEvent: boolean;
  virtualEventUrl: string;
  eventQuality: EventQuality;
  deliveryTimePeriod: DeliveryTimePeriodType;
  eventSchools: EventSchool[];
  eventPromos: EventPromo[];
}

export enum EventQuality {
  Exclusive,
  Competitive
}

export interface EventSchool {
  id: number;
  schoolId: number;
  schoolName: string;
}

export interface EventPromo {
  id: number;
  promoId: string;
  couponCode: string;
  name: string;
  description: string;
  start: Date;
  end: Date;
  isAvailable: boolean;
}

export interface Address {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  ianaTimeZoneId?: string;
}

export interface AddressVerification {
  verifiedAddress: Address;
  picklist: Address[];
  errorMessage: string;
}
