import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { catchError, takeWhile, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { RentalCategory, RentalCategoryAttribute } from "../models";
import * as fromStore from '../store';
import * as fromServices from '../services';
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { compare } from "fast-json-patch";

@Component({
	selector: 'rental-categories-details',
	templateUrl: './rental-categories-details.component.html',
})

export class RentalCategoriesDetailsComponent implements OnInit {
    private _alive = true;
    vm$: Observable<fromStore.CombosState>;
    vm: fromStore.CombosState;
    rentalCategoryDetails$: Observable<RentalCategoryAttribute>;
    rentalCategoryAttributes: RentalCategoryAttribute;
    rentalCategory$: Observable<RentalCategory>;
    rentalCategory: RentalCategory;
    isEditing: boolean = false;
    rentalCategoryId: number;
    detailEditor: FormGroup;

    constructor(private merchService: fromServices.MerchService, 
                private cdr: ChangeDetectorRef, 
                private route: ActivatedRoute,
                private fb: FormBuilder) {

    }

    ngOnInit(): void {
        this.rentalCategoryId = +this.route.snapshot.paramMap.get('id');

        this.rentalCategoryDetails$ = this.merchService.getRentalCategoryAttributes(this.rentalCategoryId).pipe();
        this.rentalCategory$ = this.merchService.getRentalCategoryDetails(this.rentalCategoryId).pipe();

        this.rentalCategoryDetails$
            .pipe(takeWhile(() => this._alive))
            .subscribe(rc => {
                this.rentalCategoryAttributes = rc;
                console.log(`getRentalCategoryAttributes returned:`, this.rentalCategoryAttributes);
                this.createForm();
                this.cdr.detectChanges();
            }
        );

        this.rentalCategory$
            .pipe(takeWhile(() => this._alive))
            .subscribe(rc => {
                this.rentalCategory = rc;
                console.log(`getRentalCategoryDetails returned:`, this.rentalCategory);
                this.cdr.detectChanges();
            }
        );

        this.isEditing = false;
    }

    onToggleEditor(open: boolean) {
		this.isEditing = open;
        this.resetForm();
    }

    onSubmit() {
        console.log('onSubmit: ', this.detailEditor.status) 
        console.log('Original: ', this.rentalCategoryAttributes)
        
        const newAttributes: RentalCategoryAttribute = this.detailEditor.value;
        newAttributes.id = this.rentalCategoryAttributes.id;
        if (this.detailEditor.value.isEnabled == 'Yes') {
            newAttributes.isEnabled = true;
        } else {
            newAttributes.isEnabled = false;
        }
        if (this.detailEditor.value.allowShipToHome == 'Yes') {
            newAttributes.allowShipToHome = true;
        } else {
            newAttributes.allowShipToHome = false;
        }
        if (this.detailEditor.value.allowShippingToHomeOrBusiness == 'Yes') {
            newAttributes.allowShippingToHomeOrBusiness = true;
        } else {
            newAttributes.allowShippingToHomeOrBusiness = false;
        }
            
        console.log('New: ', newAttributes);

        this.detailEditor.value.id = this.rentalCategoryAttributes.id;
        const patch = compare(this.rentalCategoryAttributes, newAttributes);
        
        console.log('Patch: ', patch);

        try {
            this.merchService.patchRentalCategoryAttributes(this.rentalCategoryId, patch)
                .pipe (takeWhile(() => this._alive))
                .subscribe(rc => {
                    this.rentalCategoryAttributes = rc;
                    console.log(`patchRentalCategoryAttributes returned:`, this.rentalCategoryAttributes);
                    this.createForm();
                    this.cdr.detectChanges();
                });
        } catch (error) {
            console.log(error);
        }

        this.isEditing = false;
    }

    createForm() {
        this.detailEditor = this.fb.group({
            id: [this.id],
            isEnabled: [this.enabled],
            displayName: [this.displayname],
            description: [this.description],
            includes: [this.includes],
            allowShipToHome: [this.allowShipToHome],
            allowShippingToHomeOrBusiness: [this.allowShipToHomeOrBusiness]
        });
    }

    resetForm() {
        this.detailEditor.reset({
            isEnabled: this.enabled,
            displayName: this.displayname,
            description: this.description,
            includes: this.includes,
            allowShipToHome: this.allowShipToHome,
            allowShippingToHomeOrBusiness: this.allowShipToHomeOrBusiness
        });
    }

    get id() {
        return this.rentalCategoryAttributes?.id;
    }
    get enabled() {
        return this.rentalCategoryAttributes?.isEnabled ? 'Yes' : 'No';
    }
    get displayname() {
        return this.rentalCategoryAttributes?.displayName;
    }
    get description() {
        return this.rentalCategoryAttributes?.description;
    }
    get includes() {
        return this.rentalCategoryAttributes?.includes;
    }
    get allowShipToHome() {
        return this.rentalCategoryAttributes?.allowShipToHome ? 'Yes' : 'No';
    }
    get allowShipToHomeOrBusiness() {
        return this.rentalCategoryAttributes?.allowShippingToHomeOrBusiness ? 'Yes' : 'No';
    }
    get canSave(): boolean {
        return this.detailEditor.valid && this.detailEditor.dirty;
    }
}