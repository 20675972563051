import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { District } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-district-contacts-editor',
	templateUrl: './district-contacts-editor.component.html',
})
export class DistrictContactsEditorComponent implements OnInit, OnDestroy {
	alive = true;
	districtDetails$: Observable<District>;
	panelState$: Observable<fromStore.PanelState>;
	district: District;
	panelState: fromStore.PanelState;
	editor: FormGroup;

	constructor(private store: Store<fromStore.DistrictsState>, private fb: FormBuilder) {
		console.log('DistrictContactsEditorComponent()...');
	}

	ngOnInit() {
		console.log('DistrictContactsEditorComponent.ngOnInit()...');

		this.districtDetails$ = this.store.pipe(select(fromStore.getDetailsStateDistrict));
		this.districtDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(d => this.district = d);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateContactsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('DistrictContactsEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('DistrictContactsEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		this.editor = this.fb.group({
			musicSupervisor: [this.district.musicSupervisor],
			officeContact: [this.district.officeContact],
			phone: [this.district.phone, Validators.pattern('^([0-9]{10})?$')],	//	TODO: need a better validator for this...
			fax: [this.district.fax, Validators.pattern('^([0-9]{10})?$')],		//	TODO: need a better validator for this...
			website: [this.district.website],									//	TODO: url validation...
		});
	}

	resetForm() {
		this.editor.reset({
			musicSupervisor: this.district.musicSupervisor,
			officeContact: this.district.officeContact,
			phone: this.district.phone,
			fax: this.district.fax,
			website: this.district.website,
		});
	}

	get canSave(): boolean {
		return this.editor.valid;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	onSubmit() {
		const district = { ...this.district };
		Object.keys(this.editor.value).forEach(key => {
			district[key] = this.editor.value[key];
		});

		this.store.dispatch(new fromStore.UpdateDistrictPanel({ panel: fromStore.PanelId.Contacts, district }));
	}
}
