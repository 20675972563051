import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTeacherSearchResults from '../reducers/searchresults.reducer';

export const getTeacherSearchState = createSelector(
    fromFeature.getTeachersState,
    (state: fromFeature.TeachersState) => state.search
);
export const getTeacherSearchResultsState = createSelector(
    getTeacherSearchState,
    fromTeacherSearchResults.getTeacherSearchResults
);
/*
export const getDetailsStateTeacher = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStateTeacher
);

export const getDetailsStateLoaded = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStateLoaded
);

export const getDetailsStateLoading = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStateLoading
);

export const getDetailsStateInfoState = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStateInfoState
);

export const getDetailsStateErrors = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStateErrors
);

export const getDetailsRelationshipManagerState = createSelector(
	getTeacherDetailsState,
	fromTeacherDetails.getDetailsStateRelationshipManagerState
);
*/
