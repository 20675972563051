import { Component, Input } from '@angular/core';

import { StepComponent } from './step.component';
import { TabsComponent } from './tabs.component';

@Component({
	selector: 'opus-steps',
	templateUrl: './steps.component.html',
})
export class StepsComponent extends TabsComponent {
    @Input() stepSelector: (index: number) => void = super.selectTab;
	@Input('activeStep')
	set activeStep(value: number) {
		this.activeTabIndex = value;
	}

	get activeStep() {
		return this.activeTabIndex;
	}

    onSelectStep(index: number) {
        console.log('StepsComponent.onSelectStep(): ', index);

        //	if we've been given a step selector, use it. otherwise use default tab selection
        this.stepSelector(index);
    }
}
