import { TeacherHeaderComponent } from './teacher-header';
import { TeacherInfoComponent } from './teacher-info.component';
import { TeacherAdditionalComponent } from './teacher-additional.component';
import { TeacherAddressComponent } from './teacher-address.component';
import { TeacherDistrictComponent } from './teacher-district.component';
import { TeacherContactComponent } from './teacher-contact.component';


export const detailsComponents: any[] = [
    TeacherHeaderComponent,
    TeacherInfoComponent,
    TeacherAdditionalComponent,
    TeacherAddressComponent,
    TeacherDistrictComponent,
    TeacherContactComponent
];

export * from './teacher-header';
export * from './teacher-info.component';
export * from './teacher-additional.component';
export * from './teacher-address.component';
export * from './teacher-contact.component';
export * from './teacher-district.component';
