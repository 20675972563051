<div>   
    <!-- always display -->
    <div class="property-block__content-wrapper">
        <div class="property-block__content">
            <p class="typography--body" *ngIf="!event.eventPromos.length">
                There are no approved promotions for this event.
            </p>
            <div class="table" role="table" *ngIf="event?.eventPromos.length > 0"> <!--ng-show="EventPromotionsCtrl.event.promotions.length"-->
                <div role="rowgroup">
                    <div class="table__row table__row--header" role="rowheader">
                        <span class="table__cell table__cell--half" role="columnheader" *ngIf="editMode"><div class="sr-only" opusAuthorize="Event.DisapprovePromo">Remove promotion</div></span>
                        <span class="table__cell table__cell--align-left table__cell--one-half" role="columnheader">
                            Promotion Name
                        </span>
                        <span class="table__cell table__cell--align-left table__cell--double" role="columnheader"> Description </span>
                        <span class="table__cell table__cell--align-left table__cell" role="columnheader"> Coupon </span>
                        <span class="table__cell table__cell--align-left" role="columnheader"> Starting </span>
                        <span class="table__cell table__cell--align-left" role="columnheader"> Ending </span>
                        <span class="table__cell table__cell--half" role="columnheader"> Available </span>
                    </div>
                </div>
                <div role="rowgroup">
                    <p class="typography--body" *ngIf="!event?.eventPromos.length">There are no approved promotions for this event.</p>
                        <div class="table__row" role="row" *ngFor="let promo of event?.eventPromos;let isOdd = odd; let isLast = last;" [ngClass]="{'table__row--alt': isOdd, 'table__row--last': isLast, 'table__row--highlight': !promo.isAvailable }">
                            <span class="table__cell table__cell--half table__cell--no-padding" role="cell" *ngIf="editMode" opusAuthorize="Event.DisapprovePromo">
                                <button class="btn--delete" title="Remove promotion" (click)="removePromo(promo)" type="button" >
                                    <span class="sr-only">Remove promotion</span>
                                </button>
                            </span>
                            <span class="table__cell table__cell--align-left table__cell--one-half" role="cell">
                                {{ promo.name }}
                            </span>
                            <span class="table__cell table__cell--align-left table__cell--double" role="cell">
                                {{ promo.description }}
                            </span>
                            <span class="table__cell table__cell--align-left" role="cell">
                                {{ promo.couponCode }}
                            </span>
                            <span class="table__cell table__cell--align-left" role="cell"> {{ promo.start | date:'M/d/yyyy h:mm a' }} </span>
                            <span class="table__cell table__cell--align-left" role="cell"> {{ promo.end | date:'M/d/yyyy h:mm a' }} </span>
                            <span class="table__cell table__cell--half" role="cell"> {{ promo.isAvailable | yesNo }} </span>
                        </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /always display -->
</div>
