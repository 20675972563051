<form [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editOnlineRental" class="property-block__label">Online Rental</label>
			<div class="property-block__value">
				<select id="editOnlineRental" formControlName="onlineRental" opusAuthorize="School.EditOnlineRental" opusAuthorizeAction="disable" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editShipToHome" class="property-block__label">Ship to Home</label>
			<div class="property-block__value">
				<select id="editShipToHome" formControlName="shipToHome" opusAuthorize="School.EditShipToHome" opusAuthorizeAction="disable" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editSchoolDelivery" class="property-block__label">School Delivery</label>
			<div class="property-block__value">
				<select id="editSchoolDelivery" formControlName="schoolDelivery" opusAuthorize="School.EditSchoolDelivery" opusAuthorizeAction="disable" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail" (change)="onSchoolDeliveryChange()">
					<option value="0">No</option>
					<option value="1">Ship</option>
					<option value="2">Deliver</option>
				</select>
			</div>
			<div class="error error--adjust-margin" *ngIf="showDeliveryError">
				<div class="error__message">School Delivery is not allowed for this Relationship Manager</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="EditExclusiveServiceAccountID" class="property-block__label">Exclusive Service Account ID</label>
			<div class="property-block__value">
				<div class="input-group u-m-b-0">
					<input type="text" id="EditExclusiveServiceAccountID" class="input-group-field" formControlName="exclusiveServiceAccountCode" opusAuthorize="School.EditExclusiveService" opusAuthorizeAction="disable">
					<div class="input-group-button">
						<button type="button" (click)="onValidateExclusiveServiceAccountCode()" [disabled]="inLookup">Apply</button>
					</div>
				</div>
				<div class="error" *ngIf="editor.controls.exclusiveServiceAccountCode.invalid && editor.controls.exclusiveServiceAccountCode.dirty && !inLookup">
					<div class="error__message" *ngIf="editor.controls.exclusiveServiceAccountCode.errors.server">{{ editor.controls.exclusiveServiceAccountCode.errors.server }}</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="exclusiveServiceAccountName">Exclusive Service Account Name</div>
			<div class="property-block__value" role="definition" aria-labelledby="exclusiveServiceAccountName">{{ locationName }}</div>
		</li>
		<li class="property-block__list-item">
			<label for="editOffersBandshare" class="property-block__label">Offers Bandshare</label>
			<div class="property-block__value">
				<select id="editOffersBandshare" formControlName="offersBandshare" opusAuthorize="School.EditOffersBandshare" opusAuthorizeAction="disable" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
