<div class="entity-detail">
	<nav class="u-m-b-16">
		<ul class="breadcrumbs">
			<li><a routerLink="/schools/{{ hostingSchoolId }}">{{hostingSchoolName}}</a></li>
			<li>{{ name }}</li>
		</ul>
	</nav>
	<header class="row u-m-b-40">
		<div class="columns">
			<h1 class="typography--display1">Event Detail</h1>
			<h2 class="typography--headline">{{ name }}</h2>
			<h3 class="typography--subheading">{{hostingSchoolName}}</h3>
			<dl class="definitions--inline">
				<dt>Event ID</dt>
				<dd>{{ eventId }}</dd>
			</dl>
			<button type="button" class="link typography--subheading" (click)="onCopyEvent()"
					opusAuthorize="Event.Create"
					[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
					[opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				Copy to New Event <span class="icon icon--add"></span>
			</button>
		</div>
		<div class="columns small-4">
			<aside class="card">
				<div class="card__section">
					<opus-history-snapshot contextName="Event" [contextId]="(eventDetails$ | async).id"></opus-history-snapshot>
				</div>
			</aside>
		</div>
	</header>

	<div class="full-page-spinner" [class.full-page-spinner--is-active]="copying">
		<div class="full-page-spinner__icon"></div>
	</div>

	<div class="row">
		<div class="columns">
			<opus-property-block label="Event Information" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-info'].editing" (toggleEditor)="onToggleEditor('event-info', $event)" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-info viewer [event]="eventDetails$ | async"></event-info>
				<event-info-editor editor *ngIf="(panels$ | async)['event-info'].editing"></event-info-editor> <!---->
			</opus-property-block>

			<opus-property-block label="Date & Time" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-date-time'].editing" (toggleEditor)="onToggleEditor('event-date-time', $event)" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-date-time viewer [event]="eventDetails$ | async"></event-date-time>
				<event-date-time-editor editor *ngIf="(panels$ | async)['event-date-time'].editing"></event-date-time-editor>
			</opus-property-block>

			<opus-property-block label="Staffing" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-staffing'].editing" (toggleEditor)="onToggleEditor('event-staffing', $event)" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-staffing viewer [event]="eventDetails$ | async"></event-staffing>
				<event-staffing-editor editor *ngIf="(panels$ | async)['event-staffing'].editing"></event-staffing-editor>
			</opus-property-block>

			<opus-property-block label="Rental Event Details" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-meeting-details'].editing" (toggleEditor)="onToggleEditor('event-meeting-details', $event)" *ngIf="(eventDetails$ | async).eventTypeId == 1" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-meeting-details viewer [event]="eventDetails$ | async"></event-meeting-details>
				<event-meeting-details-editor editor *ngIf="(panels$ | async)['event-meeting-details'].editing"></event-meeting-details-editor>
			</opus-property-block>
		</div>
		<div class="columns">
			<opus-property-block label="Host School & Teacher" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-host'].editing" (toggleEditor)="onToggleEditor('event-host', $event)" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-host viewer [event]="eventDetails$ | async"></event-host>
				<event-host-editor editor *ngIf="(panels$ | async)['event-host'].editing"></event-host-editor>
			</opus-property-block>

			<opus-property-block label="Location" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-location'].editing" (toggleEditor)="onToggleEditor('event-location', $event)" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-location viewer [event]="eventDetails$ | async"></event-location>
				<event-location-editor editor *ngIf="(panels$ | async)['event-location'].editing"></event-location-editor>
			</opus-property-block>

			<opus-property-block label="Schools Attending" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-schools-attending'].editing" (toggleEditor)="onToggleEditor('event-schools-attending', $event)" policy="Event.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(relationshipManagerState$ | async).relationshipManagerEmails">
				<event-schools-attending viewer [event]="eventDetails$ | async"></event-schools-attending>
				<event-schools-attending-editor editor *ngIf="(panels$ | async)['event-schools-attending'].editing"></event-schools-attending-editor>
			</opus-property-block>
		</div>
	</div>
	<opus-property-block label="Approved Promotions" [canEdit]="canEdit" [isEditing]="(panels$ | async)['event-promo'].editing" (toggleEditor)="onToggleEventPromoEditor('event-promo', $event)" *ngIf="(eventDetails$ | async).eventTypeId == 1">
		<event-promo viewer [event]="eventDetails$ | async"></event-promo>
		<event-promo-editor editor *ngIf="(panels$ | async)['event-promo'].editing"></event-promo-editor>
	</opus-property-block>
</div>

<opus-modal [visible]="showCopyModal" (action)="onCopyModalAction($event)" title="Copy Event"
			primaryLabel="Copy"
			[primaryEnabled]="eventCopy?.editor.valid || false"
			secondaryLabel="Cancel" showClose="true">
	<opus-event-copy *ngIf="showCopyModal" [eventName]="(eventDetails$ | async).name + ' Copy'"></opus-event-copy>
</opus-modal>
