import { Injectable } from '@angular/core';

import { tap, switchMap, catchError, map/*, withLatestFrom*/ } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as historyActions from '../actions/history.actions';
import { HistoryService } from '../../services/history.service';
import { HistoryDetails } from '../../models';

@Injectable()
export class HistoryEffects {
	constructor(private actions$: Actions, private historyService: HistoryService) { }

	
  loadSnapshot$ = createEffect(() => this.actions$.pipe(
    ofType(historyActions.HistoryActionTypes.LoadSnapshot),
		tap(a => console.log('Entering loadSnapshot$ Effect: ', a)),
		switchMap((a: historyActions.LoadSnapshot) => {
			const { contextName, contextId, scope } = a.payload;

			console.log(`Calling historyService.loadSnapshot('${contextName}', ${contextId})...`);

			return this.historyService.loadSnapshot(contextName, contextId, scope).pipe(
				tap(rsp => console.log('historyService.loadSnapshot returned: ', rsp)),
				map(rsp => {
					if (!rsp) {
						return new historyActions.LoadSnapshotSuccess(null);
					}

					const { displayName } = rsp;
					const timestamp = new Date(rsp.timestamp);

					return new historyActions.LoadSnapshotSuccess({ displayName, timestamp });
				}),
				catchError(error => of(new historyActions.LoadSnapshotFailure(error)))
			);
		}),
		tap(a => console.log('Exiting loadSnapshot$ Effect: ', a)),
	));

	
  loadHistory$ = createEffect(() => this.actions$.pipe(
    ofType(historyActions.HistoryActionTypes.LoadHistory),
		tap(a => console.log('Entering loadHistory$ Effect: ', a)),
		switchMap((a: historyActions.LoadHistory) => {
			const { contextName, contextId, scope, fromDate, toDate } = a.payload;

			console.log(`Calling historyService.loadHistory('${contextName}', ${contextId}, '${scope}', ${fromDate}, ${toDate})...`);

			return this.historyService.loadHistory(contextName, contextId, scope, fromDate, toDate).pipe(
				tap(rsp => console.log('historyService.LoadHistory returned: ', rsp)),
				map(rsp => {
					const payload: HistoryDetails = {
						...rsp,
						events: rsp.events.map(e => {
							return {
								...e,
								timestamp: new Date(e.timestamp),
							};
						}),
					};

					return new historyActions.LoadHistorySuccess(payload);
				}),
				catchError(error => of(new historyActions.LoadHistoryFailure(error)))
			);
		}),
		tap(a => console.log('Exiting loadHistory$ Effect: ', a)),
	));
}
