import { Component } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, ValidationErrors, AsyncValidatorFn, Validators } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { takeWhile, tap, map, filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { schoolNcesIdSourcesPublic, schoolNcesIdSourcesPrivate } from '../../scp-common/models/ncesIdSources';
import { SchoolDetails, SchoolType } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-school-additional-details-editor',
	templateUrl: './school-additional-details-editor.component.html',
})
export class SchoolAdditionalDetailsEditorComponent {
	alive = true;
	schoolDetails$: Observable<SchoolDetails>;
	panelState$: Observable<fromStore.PanelState>;
	school: SchoolDetails;
	panelState: fromStore.PanelState;
	panelId = fromStore.PanelId.AdditionalDetails;
	editor: FormGroup;
	months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	customerInHandMonths: string[] = this.months.concat('N/A');
	summerBilling: Date;
	fallBilling: Date;
	summerCustomerInHandDate: Date | null;
	fallCustomerInHandDate: Date | null;
    schoolNcesIdSourcesPublic: string[] = schoolNcesIdSourcesPublic;
    schoolNcesIdSourcesPrivate: string[] = schoolNcesIdSourcesPrivate;
	readonly days: { [id: number]: string[] };

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder) {
		console.log('SchoolAdditionalDetailsEditorComponent()...');

		this.days = this.calculateDaysInMonths();

		console.log('days', this.days);
	}

	calculateDaysInMonths() {
		const days: { [id: number]: string[] } = {};

		for (let i = 0; i < this.customerInHandMonths.length; i++) {
			days[i] = this.getDaysInMonth(i);
		}

		return days;
	}

	getDaysInMonth(month: number) {
		if (month === 12)
			return ['N/A'];

		const days: string[] = [];

		for (let i = 1; i <= new Date(9999, +month + 1, 0).getDate(); i++) {
			days.push(i.toString());
		}

		return days;
	}

    get schoolNcesIdSources(): string[] {
      if (this.school.type == SchoolType.PUBLIC)
        return schoolNcesIdSourcesPublic;

      if (this.school.type == SchoolType.PRIVATE)
        return schoolNcesIdSourcesPrivate;

      const sources: string[] = [];
      return sources;
    }

	ngOnInit() {
		console.log('SchoolAdditionalDetailsEditorComponent.ngOnInit()...');

		this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
		this.schoolDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => this.school = s);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateAdditionalDetailsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('SchoolAdditionalDetailsEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => {
			console.log('SchoolAdditionalDetailsEditorComponent.panelState$: ', s);

			this.panelState = s;
		});

		this.createForm();
	}

	ngOnDestroy() {
		console.log('SchoolAdditionalDetailsEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		this.summerBilling = this.school.summerBillingDate
			? new Date(Date.parse(this.school.summerBillingDate))
			: new Date(this.getBillingYear(1, 5), 5, 1);

		this.fallBilling = this.school.fallBillingDate
			? new Date(Date.parse(this.school.fallBillingDate))
			: new Date(this.getBillingYear(1, 8), 8, 1);

		this.summerCustomerInHandDate = this.school.summerCustomerInHandDate
			? new Date(Date.parse(this.school.summerCustomerInHandDate))
			: null;

		console.log('SchoolAdditionalDetailsEditorComponent.createForm.summerCustomerInHandDate', this.summerCustomerInHandDate);

		this.fallCustomerInHandDate = this.school.fallCustomerInHandDate
			? new Date(Date.parse(this.school.fallCustomerInHandDate))
			: null;

		this.editor = this.fb.group({
			primaryId: [this.school.primaryId, { updateOn: 'blur', asyncValidators: [this.primaryIdValidator()] }],
            ncesSchoolId: [this.school.ncesSchoolId],
            ncesIdSource: [this.school.ncesIdSource || ''],
			summerBillingMonth: [this.summerBilling.getMonth()],
			summerBillingDay: [this.summerBilling.getDate()],
			fallBillingMonth: [this.fallBilling.getMonth()],
			fallBillingDay: [this.fallBilling.getDate()],
			summerCustomerInHandDateMonth: [this.summerCustomerInHandDate ? this.summerCustomerInHandDate.getMonth() : 12],
			summerCustomerInHandDateDay: [this.summerCustomerInHandDate ? this.summerCustomerInHandDate.getDate() : 'N/A'],
			fallCustomerInHandDateMonth: [this.fallCustomerInHandDate ? this.fallCustomerInHandDate.getMonth() : 12],
			fallCustomerInHandDateDay: [this.fallCustomerInHandDate ? this.fallCustomerInHandDate.getDate() : 'N/A'],
			summerFree: [this.school.summerFree ? 'Yes' : 'No'],
		});

		this.initMonthChanges();
	}

	resetForm() {
		this.editor.reset({
			primaryId: this.school.primaryId,
            ncesSchoolId: this.school.ncesSchoolId,
            ncesIdSource: [this.school.ncesIdSource || ''],
			summerBillingMonth: this.summerBilling.getMonth(),
			summerBillingDay: this.summerBilling.getDate(),
			fallBillingMonth: this.fallBilling.getMonth(),
			fallBillingDay: this.fallBilling.getDate(),
			summerCustomerInHandDateMonth: this.summerCustomerInHandDate ? this.summerCustomerInHandDate.getMonth() : 12,
			summerCustomerInHandDateDay: this.summerCustomerInHandDate ? this.summerCustomerInHandDate.getDate() : 'N/A',
			fallCustomerInHandDateMonth: this.fallCustomerInHandDate ? this.fallCustomerInHandDate.getMonth() : 12,
			fallCustomerInHandDateDay: this.fallCustomerInHandDate ? this.fallCustomerInHandDate.getDate() : 'N/A',
			summerFree: this.school.summerFree ? 'Yes' : 'No',
		});
	}

	initMonthChanges() {
		const keys: string[] = ['summerBilling', 'fallBilling', 'summerCustomerInHandDate', 'fallCustomerInHandDate'];

		for (const propKey of keys) {
			this.editor.get(propKey + 'Month').valueChanges
				.pipe(takeWhile(() => this.alive))
				.subscribe(monthIndex => {
					const dayEditor = this.editor.get(propKey + 'Day');
					const daysOfSelectedMonth = this.days[monthIndex];
					const lastDay = daysOfSelectedMonth[daysOfSelectedMonth.length - 1];
					const selectedDay = dayEditor.value;

					const changingFromNotApplicableMonth = selectedDay === 'N/A';
					const changingToNotApplicableMonth = lastDay === 'N/A';
					const changingToMonthWithLessDaysThanCurrentlySelectedDay = +selectedDay > +lastDay;

					if (changingFromNotApplicableMonth ||
						changingToNotApplicableMonth ||
						changingToMonthWithLessDaysThanCurrentlySelectedDay) {
						dayEditor.setValue(lastDay, { emitEvent: false });
					}
				});
		}
	}

	get canSave(): boolean {
		return this.editor.valid && this.editor.dirty;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	get primaryIdStatus() {
		return this.panelState.validationStatus['primaryId'] || {
			pending: false,
			errors: null,
		};
	}

	get primaryIdErrors(): ValidationErrors {
		return this.primaryIdStatus.errors;
	}

	get primaryIdPending(): boolean {
		return this.primaryIdStatus.pending;
	}

	get hasPrimaryIdErrors(): boolean {
		return this.primaryIdErrors != null;
	}

	onSubmit() {
		const summerBillingYear = this.getBillingYear(this.editor.value.summerBillingDay, this.editor.value.summerBillingMonth);
		const fallBillingYear = this.getBillingYear(this.editor.value.fallBillingDay, this.editor.value.fallBillingMonth);
		const summerBillingDate = `${+this.editor.value.summerBillingMonth + 1}/${this.editor.value.summerBillingDay}/${summerBillingYear}`;
		const fallBillingDate = `${+this.editor.value.fallBillingMonth + 1}/${this.editor.value.fallBillingDay}/${fallBillingYear}`;
		const summerCustomerInHandDate = +this.editor.value.summerCustomerInHandDateMonth === 12 ? null : `${+this.editor.value.summerCustomerInHandDateMonth + 1}/${this.editor.value.summerCustomerInHandDateDay}/${(new Date()).getFullYear()}`;
		const fallCustomerInHandDate = +this.editor.value.fallCustomerInHandDateMonth === 12 ? null : `${+this.editor.value.fallCustomerInHandDateMonth + 1}/${this.editor.value.fallCustomerInHandDateDay}/${(new Date()).getFullYear()}`;

		const school = {
			...this.school,
			primaryId: this.editor.value.primaryId,
            ncesSchoolId: this.editor.value.ncesSchoolId || null,
            ncesIdSource: this.editor.value.ncesIdSource || null,
			summerBillingDate,
			fallBillingDate,
			summerCustomerInHandDate,
			fallCustomerInHandDate,
			summerFree: this.editor.value.summerFree == 'Yes',
		};

		this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: fromStore.PanelId.AdditionalDetails, school }));
	}

	primaryIdValidator(): AsyncValidatorFn {
		const validator = 'primaryId';

		return (control: AbstractControl): Observable<ValidationErrors> | null => {
			//	NOTE: this is getting called several times during page load... i see no way to mitigate this except to not take action unless actually needed
			console.log('primaryIdValidator(): ', control);

			if (!control.dirty) {
				return of(null);
			}

			//	if the control value changed at all, we always need to dispatch an action in order to at least clear previous validation errors
			this.store.dispatch(new fromStore.ValidateSchoolPrimaryId({ panel: this.panelId, validator, primaryId: control.value, duplicateId: this.school.id, duplicateIsActive: this.school.isActive }));

			//	the result of the validation is an observable of the validation status in the state of our panel
			return this.panelState$.pipe(
				//	wait for the status to complete
				filter(ps => {
					const status = ps.validationStatus[validator];

					return status != null && !status.pending;
				}),
				tap(ps => {
					console.log('primaryIdValidator(): panelState: ', ps);
				}),
				//	once that validation has completed, the errors property will either be a ValidationErrors object or null - exactly what we need to return
				map(ps => {
					return of(ps.validationStatus[validator].errors);
				}),
			);
		};
  	}

	private getBillingYear(billingDay: number, billingMonth: number) {
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const currentDay = new Date().getDate();

		if (currentMonth < billingMonth || (currentMonth == billingMonth && currentDay <= billingDay))
			return currentYear;
		else
			return (currentYear + 1);
	}
}
