<form novalidate [formGroup]="editor" (ngSubmit)="onSubmit()">
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editNcesDistrictId" class="property-block__label">NCES District ID</label>
			<div class="property-block__value">
				<input type="text" id="editNcesDistrictId" formControlName="ncesDistrictId" maxlength="15"
					   opusAuthorize="District.EditNcesDistrictId"
					   opusAuthorizeAction="disable"
					   [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
					   [opusRelationshipManagerEmail]="(district$ | async).relationshipManagerEmail">
			</div>
		</li>
		<!--<li class="property-block__list-item">
			<label for="editNcesIdSource" class="property-block__label">NCES ID Source</label>
			<div class="property-block__value">
				<select name="editNcesIdSource" id="editNcesIdSource" required formControlName="ncesIdSource"
					   opusAuthorize="District.EditNcesDistrictId"
					   opusAuthorizeAction="disable"
					   [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
					   [opusRelationshipManagerEmail]="(district$ | async).relationshipManagerEmail">
					<option value="">Select ID Source</option>
					<option *ngFor="let s of districtNcesIdSources" [value]="s">{{ s }}</option>
				</select>
			</div>
		</li>-->
		<li class="property-block__list-item">
			<label for="editBudgetEnd" class="property-block__label">Budget End</label>
			<div class="property-block__value">
				<div class="md-selector" role="group" aria-labelledby="editBudgetEnd">
					<div class="md-selector__month">
						<label>
							Month
							<select formControlName="budgetEndMonth" opusAuthorize="District.EditBudgetEnd" opusAuthorizeAction="disable">
								<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
							</select>
						</label>
					</div>
					<div class="md-selector__day">
						<label>
							Day
							<select formControlName="budgetEndDay" opusAuthorize="District.EditBudgetEnd" opusAuthorizeAction="disable">
								<option *ngFor="let d of days" [value]="d">{{ d }}</option>
							</select>
						</label>
					</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="latitude">Latitude</div>
			<div class="property-block__value" role="definition" aria-labelledby="latitude">{{ district.latitude }}</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="longitude">Longitude</div>
			<div class="property-block__value" role="definition" aria-labelledby="longitude">{{ district.longitude }}</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="timezone">Time Zone</div>
			<div class="property-block__value" role="definition" aria-labelledby="timezone">{{ district.ianaTimeZoneId }}</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
