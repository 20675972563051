<form [formGroup]="editor" novalidate>
    <label for="eventName">Event Name</label>
    <input id="eventName" type="text" formControlName="name" placeholder="Event Name" aria-label="Event Name" maxlength="100">
    <div class="error" *ngIf="editor.get('name').invalid && (editor.get('name').dirty || editor.get('name').touched)">
        <div class="error__message" *ngIf="editor.get('name').hasError('required')">Required</div>
    </div>

    <label for="eventDate">Event Date</label>
    <input id="eventDate" type="text" placeholder="Event Date" class="form-control" bsDatepicker [bsConfig]="bsConfig" [maxDate]="maxDate" aria-label="Event Date" title="Event Date" formControlName="date">
    <div class="error" *ngIf="editor.get('date').invalid && (editor.get('date').dirty || editor.get('date').touched)">
        <div class="error__message" *ngIf="editor.get('date').hasError('validCurrentDate')">Date must be today or later</div>
    </div>
</form>