import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';

import * as fromStore from '../store';

@Injectable()
export class HomeGuard implements CanActivate {
    constructor(private _store: Store<fromStore.CoreState>) {

    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

        return of(true);
        //check for a specific route if a user group exists
    }

}
