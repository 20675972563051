import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap } from 'rxjs/operators';
import { Operation } from 'fast-json-patch';

import { ProgramDetails, ProgramSearchResultsItem, ProgramCreate } from '../../programs/models';

@Injectable()
export class ProgramService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	loadProgram(programId: number): Observable<ProgramDetails> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<ProgramDetails>(`${c.config.apiServer}api/programs/${programId}`);
            })
        );
	}

	patchProgram(id: number, patch: Operation[]): Observable<ProgramDetails> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.patch<ProgramDetails>(`${c.config.apiServer}api/programs/${id}`, patch);
			})
		);
	}

	updateProgram(id: number, diff: Operation[]): Observable<ProgramDetails> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.patch<ProgramDetails>(`${c.config.apiServer}api/programs/${id}`, diff);
            })
        );
	}

    searchPrograms(query: string): Observable<HttpResponse<ProgramSearchResultsItem[]>> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<ProgramSearchResultsItem[]>(`${c.config.apiServer}api/programs?${query}`, { observe: 'response' });
            })
        );
	}

	createProgram(program: ProgramCreate): Observable<number> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.post<number>(`${c.config.apiServer}api/programs`, program);
			})
		);
	}
}
