import * as fromSecurityAction from '../actions/security.actions';
import * as fromModels from '../../models';

//interface PolicyResult {
//    permissionName: string;
//    authorized: boolean;
//}


export interface SecurityState {
    userData: any;
    loading: boolean;
    loaded: boolean;
    rolePolicies: fromModels.RolePolicy[];
    message: fromModels.Message;
    errors: any;	//	TODO: formalize error handling
}

export const initialState: SecurityState = {
    userData: null,
    message: null,
    errors: null,
    rolePolicies: null,
    loading: false,
    loaded: false
};

export function reducer(state = initialState, action: fromSecurityAction.SecurityActions): SecurityState {
    switch (action.type) {
        case fromSecurityAction.SecurityActionTypes.SetUserData: {
            return {
                ...state,
                userData: action.payload
            };
        }
        case fromSecurityAction.SecurityActionTypes.LoadRolePolicies: {
            return {
                ...state,
                loading: true,
            };
        }
        case fromSecurityAction.SecurityActionTypes.LoadRolePoliciesSuccess: {
            const { rolePolicies } = action.payload;

            return {
                ...state,
                rolePolicies,
                loading: false,
                loaded: true
            };
        }
        case fromSecurityAction.SecurityActionTypes.LoadRolePoliciesFailure: {
            return {
                ...state,
                errors: action.payload,
                loading: false,
                loaded: true
            };
        }
    }

    return state;
}

export const getRolePolicies = (state: SecurityState) => state.rolePolicies;
export const getUserData = (state: SecurityState) => state.userData;
