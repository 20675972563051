import { HttpErrorResponse } from '@angular/common/http';

import * as fromHistory from '../actions/history.actions';

import { HistorySnapshot, HistoryDetails } from '../../models';

export interface SnapshotState {
	loading: boolean;
	error: any;
	data: HistorySnapshot;
}

export interface DetailsState {
	loading: boolean;
	loaded: boolean;
	error: HttpErrorResponse;
    data: HistoryDetails;
    fromDate: string;
    toDate: string;
}

export interface HistoryState {
	snapshot: SnapshotState;
	details: DetailsState;
}

export const initialSnapshotState: SnapshotState = {
	loading: false,
	error: null,
	data: null,
};

export const initialDetailsState: DetailsState = {
	loading: false,
	loaded: false,
	error: null,
    data: undefined,
    fromDate: null,
    toDate: null
};

export const initialState: HistoryState = {
	snapshot: initialSnapshotState,
	details: initialDetailsState,
};

export function reducer(state = initialState, action: fromHistory.HistoryActions): HistoryState {
	switch (action.type) {
		case fromHistory.HistoryActionTypes.LoadSnapshot: {
			return {
				...state,
				snapshot: {
					...state.snapshot,
					loading: true,
					data: null,
					error: null,
				},
			};
		}

		case fromHistory.HistoryActionTypes.LoadSnapshotSuccess: {
			const snapshot = action.payload;
			return {
				...state,
				snapshot: {
					...state.snapshot,
					loading: false,
					data: snapshot,
				},
			};
		}

		case fromHistory.HistoryActionTypes.LoadSnapshotFailure: {
			return {
				...state,
				snapshot: {
					...state.snapshot,
					loading: false,
					error: action.payload,
				},
			};
		}

        case fromHistory.HistoryActionTypes.LoadHistory: {
            const { fromDate, toDate } = action.payload;
			return {
				...state,
				details: {
					...state.details,
					loading: true,
					loaded: false,
					data: undefined,
                    error: null,
                    fromDate,
                    toDate
				},
			};
		}

		case fromHistory.HistoryActionTypes.LoadHistorySuccess: {
			const details = action.payload;
			return {
				...state,
				details: {
					...state.details,
					loading: false,
					loaded: details !== null,
					data: details,
					error: null,
				},
			};
		}

		case fromHistory.HistoryActionTypes.LoadHistoryFailure: {
			return {
				...state,
				details: {
					...state.details,
					loading: false,
					loaded: false,
					error: action.payload,
				},
			};
		}
	}

	return state;
}

export const getHistorySnapshot = (state: HistoryState) => state.snapshot;
export const getDetailsState = (state: HistoryState) => state.details;
export const getHistoryDetails = (state: DetailsState) => state.data;
