import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import { SchoolDetails } from '../models';

@Component({
	selector: 'opus-school-page',
	templateUrl: './school-details.component.html',
})
export class SchoolDetailsComponent implements OnInit, AfterViewInit {
	schoolDetails$: Observable<SchoolDetails>;
	panels$: Observable<{ [id: string]: fromStore.PanelState }>;
    scrollTo: Element = null;
    navigatingToPreferences = false;

    constructor(private store: Store<fromStore.SchoolsState>, private router: Router, private route: ActivatedRoute) {
		router.events.subscribe(s => {
			if (s instanceof NavigationEnd) {
				const tree = router.parseUrl(router.url);

				if (tree.fragment) {
					const element = document.querySelector('#' + tree.fragment);

					if (element) {
						this.scrollTo = element;
						element.scrollIntoView();
					}
				}
			}
		});
	}

	ngOnInit() {
		this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
		this.panels$ = this.store.pipe(select(fromStore.getDetailsStatePanels));
	}

	ngAfterViewInit() {
		if (this.scrollTo) {
			console.log('scrollIntoView: ', this.scrollTo);

			this.scrollTo.scrollIntoView();
			this.scrollTo = null;
		}
    }

    navigateToPreferences() {
        this.navigatingToPreferences = true;

        setTimeout(() => {
            this.router.navigate(['./', 'preferences'], { relativeTo: this.route });
        }, 100);
    }

	onToggleEditor(key: string, open: boolean) {
		this.store.dispatch(new fromStore.UpdateEditorState({ key, open }));
    }
}
