<ul class="list--inline">
	<li *ngFor="let tab of tabs; let i = index">
		<a class="radio" [ngClass]="{ 'radio--active': activeTabIndex == i }" (click)="selectTab(i)">
			<span class="radio__input"></span>{{ tab.name }}
		</a>
	</li>
</ul>

<div class="row">
	<div class="columns small-9 large-7">
		<div>
			<ng-content></ng-content>
		</div>
	</div>
</div>
