<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="districtId">District ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="districtId">
            <a *ngIf="teacher.districtId" [routerLink]="['/districts', teacher.districtId]">{{ districtId }}</a>
        </div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="districtName">District Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="districtName">
            <a *ngIf="teacher.districtId" [routerLink]="['/districts', teacher.districtId]">{{ districtName }}</a>
        </div>
    </li>
</ul>