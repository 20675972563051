import * as fromEventSearchResults from '../actions/event-searchresults.action';
import * as fromModels from '../../models';
import { HttpHeaders } from '@angular/common/http';

export interface SearchResultsState {
    searchResults: fromModels.EventSearchResultsItem[];
    searchCriteria: fromModels.EventSearchCriteria;
    pagingSorting: fromModels.EventPagingSorting;
    errors: any;	//	TODO: formalize error handling
    loading: boolean;
    loaded: boolean;
}

export const initialState: SearchResultsState = {
    loading: false,
    loaded: false,
    searchResults: null,
    errors: null,
    searchCriteria: {
        id: null,
        isActive: null,
        name: null,
        attendingSchoolId: null,
        hostingState: null,
        eventType: null,
        eventDateFrom: null,
        eventDateTo: null,
        districtId: null,
        code: null,
        primaryRentalFulfillment: null,
        PageNumber: null,
        PageSize: null,
        SortBy: null,
        SortDir: null
    },
    pagingSorting: {
        totalCount: null,
        pageSize: null,
        currentPage: null,
        totalPages: null,
        sortBy: null,
        sortDir: null
    }
};

export function reducer(state = initialState, action: fromEventSearchResults.EventSearchResultsActions): SearchResultsState {
    switch (action.type) {
        case fromEventSearchResults.EventSearchResultsActionTypes.ProcessQueryString: {
            console.log('ProcessQueryString');
            console.log(action.payload);

            return {
                ...state,
                loaded: false,
                loading: false,
                errors: null
            };
        }

        case fromEventSearchResults.EventSearchResultsActionTypes.LoadEventSearchResults: {
            console.log('LoadEventSearchResults');
            console.log(action.payload);
			return {
                ...state,
                loaded: false,
                loading: true,
                errors: null,
                // Anytime we request search results, let's update the state to include that
                // most recent criteria that was just passed.
                searchCriteria: action.payload
			};
		}

        case fromEventSearchResults.EventSearchResultsActionTypes.LoadEventSearchResultsSuccess: {
            const searchResults = action.payload;
            const headers = action.httpHeaders;

			return {
                ...updateEventSearchResultsState(state, searchResults, headers),
				loaded: true,
                loading: false
			};
		}

        case fromEventSearchResults.EventSearchResultsActionTypes.LoadEventSearchResultsFailure: {
			console.log('reducer:', action);

			return {
				...state,
				loaded: false,
				loading: false,
                errors: action.payload
			};
        }
	}

	return state;
}

function updateEventSearchResultsState(state: SearchResultsState, searchResults: fromModels.EventSearchResultsItem[], headers: HttpHeaders) {
    let pagingSorting: fromModels.EventPagingSorting;

    // Grab special header we use for paging
    const pagingHeader: string = headers.get('X-Pagination');
    const pagingObject: object = JSON.parse(pagingHeader);

    // Populate paging information from http headers
    pagingSorting = {
        totalCount: pagingObject['totalCount'],
        pageSize: pagingObject['pageSize'],
        currentPage: pagingObject['currentPage'],
        totalPages: pagingObject['totalPages'],
        sortBy: pagingObject['sortBy'],
        sortDir: pagingObject['sortDir']
    };

	return {
		...state,
        searchResults,
        pagingSorting
	};
}

export const getEventSearchResults = (state: SearchResultsState) => state.searchResults;
export const getEventSearchLoading = (state: SearchResultsState) => state.loading;
export const getEventSearchLoaded = (state: SearchResultsState) => state.loaded;
export const getEventSearchErrors = (state: SearchResultsState) => state.errors;
