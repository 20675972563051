import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as RouterActions from '../actions/router.action';

import { tap, map, filter, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { SecurityActionTypes, SetUserData } from '../actions';
import { getUserAuthInfo } from '../selectors';
import { CoreState } from '../reducers';
import { RouterNavigationAction, ROUTER_NAVIGATION } from '@ngrx/router-store';

@Injectable()
export class RouterEffects {

    constructor(private actions$: Actions, private router: Router, private location: Location, private store: Store<CoreState>) {
        const data = window.sessionStorage.getItem('redirect');
        if (!data) window.sessionStorage.setItem('redirect', JSON.stringify(window.location.pathname));
    }


    
    navigate$ = createEffect(() => this.actions$.pipe(
      ofType(RouterActions.GO),
        map((action: RouterActions.Go) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
            this.router.navigate(path, { queryParams, ...extras });
        })
    ), { dispatch: false });

     navigateBack$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActions.BACK),
        tap(() => this.location.back())
    ), { dispatch: false });

     navigateForward$ = createEffect(() => this.actions$.pipe(
        ofType(RouterActions.FORWARD),
        tap(() => this.location.forward())
    ), { dispatch: false });

    
    setUserData$ = createEffect(() => this.actions$.pipe(
        ofType<SetUserData>(SecurityActionTypes.SetUserData),
        filter(x => !!x.payload),
        tap(() => {
            const data = window.sessionStorage.getItem('redirect');
            const route = data && JSON.parse(data);

            if (!route) return;

            window.sessionStorage.removeItem('redirect');
            this.router.navigate([route]);
        })
    ), { dispatch: false });

    
    redirect$ = createEffect(() => this.actions$.pipe(
        ofType<RouterNavigationAction>(ROUTER_NAVIGATION),
        filter(x => x.payload.routerState.url === '/'),
        withLatestFrom(
            this.store.pipe(select(getUserAuthInfo)),
            (action, user) => {

                if (!user) return;

                if (user.roleNames.has('Affiliate Users')) return 'credit-check';

                const policy = [
                    { policyName: 'School.Search', route: 'search/schools' },
                    { policyName: 'District.Search', route: 'search/districts' },
                    { policyName: 'Teacher.Search', route: 'search/teachers' },
                    { policyName: 'Event.Search', route: 'search/events' },
                ].find(x => user.policyCodes.has(x.policyName));

                if (policy) return policy.route;

                return 'unauthorized';
            }
        ),
        filter(Boolean),
        tap(x => this.router.navigate([x]))
    ), { dispatch: false });
}
