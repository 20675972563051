<nav class="u-m-b-16">
	<ul class="breadcrumbs">
		<li><a routerLink="/search/{{ context.entityTypeName.toLowerCase() }}s">{{ context.entityTypeName }}s</a></li>
		<li><a routerLink="/{{ context.entityTypeName.toLowerCase() }}s/{{ context.entityId }}">{{ context.entityName }}</a></li>
		<li>{{ context.scope }} History</li>
	</ul>
</nav>

<header class="row u-m-b-40">
	<div class="columns">
		<h1 class="typography--display1">{{ context.scope }} History</h1>
		<h2 class="typography--headline">{{ context.entityName }}</h2>
		<dl class="definitions--inline">
			<dt>{{ context.entityTypeName }} ID</dt>
			<dd>{{ context.entityId }}</dd>
		</dl>
	</div>
</header>
