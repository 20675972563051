import * as fromCommonModels from '../../../scp-common/models';
import * as fromSchoolDetails from '../actions/school-details.action';
import * as fromPrefs from '../../../preferences/store/actions';

import { DistrictLookup }from '../../../districts/models';
import { SchoolDetails, RateAgreement } from '../../models';

export enum PanelId {
	Info = 'info',
	PhysAddr = 'physAddr',
	MailAddr = 'mailAddr',
	Contacts = 'contacts',
	CurrentRate = 'currentRate',
	FutureRate = 'futureRate',
	Calendar = 'calendar',
	Relationship = 'relationship',
	OnlineRentals = 'onlineRentals',
	RentalReturns = 'rentalReturns',
	AdditionalDetails = 'additionalDetails',
}

export interface ValidationStatus {
	[id: string]: {
		pending: boolean;
		errors: { [key: string]: any };
	};
}

export interface PanelState {
	editing: boolean;
	updating: boolean;
	validationStatus: ValidationStatus;
	errors: any;	//	TODO: formalize error handling
}

export interface CountyLookupState {
	pending: boolean;
	lookupStateCode: string;
	errors: any;
	counties: { [stateCode: string]: string[] };
}

export interface ZipLookupState {
	zip: string;
	pending: boolean;
	errors: any;
	cityState: { city: string, state: string };
}

export interface AddressPanelState extends PanelState {
	countyLookup: CountyLookupState;
	zipLookup: ZipLookupState;
	verifying: boolean;
	verifiedAddress: fromCommonModels.Address;
	suggestedAddresses: fromCommonModels.Address[];
}

export interface RateLookupState {
	rateCode: string;
	pending: boolean;
	errors: any;
}

export interface RatePanelState extends PanelState {
	rateLookup: RateLookupState;
}

export interface RateAgreementState {
	pending: boolean;
	rateAgreement: RateAgreement;
}

export interface DistrictIdLookupState {
	pending: boolean;
	errors: any;
	district: DistrictLookup;
}

export interface DistrictByNameLookupState {
	name: string;
	pending: boolean;
	errors: any;
	districts: DistrictLookup[];
}

export interface DistrictLookupsState {
	idLookups: { [id: number]: DistrictIdLookupState };
	nameLookup: DistrictByNameLookupState;
}

export interface SchoolDetailsState {
	schoolDetails: SchoolDetails;
	loaded: boolean;
	loading: boolean;
	creating: boolean;
	createdId?: number;
	errors: any;
	panels: { [id: string]: PanelState };
	rateAgreements: { [rateCode: string]: RateAgreementState };
	districtLookups: DistrictLookupsState;
}

export const initialPanelState: PanelState = {
	editing: false,
	validationStatus: {},
	updating: false,
	errors: null,
};

export const initialZipLookupState: ZipLookupState = {
	zip: '',
	pending: false,
	cityState: null,
	errors: null,
};

export const initialCountyLookupState: CountyLookupState = {
	pending: false,
	lookupStateCode: null,
	errors: null,
	counties: {},
};

export const initialAddressPanelState: AddressPanelState = {
	...initialPanelState,
	zipLookup: initialZipLookupState,
	countyLookup: initialCountyLookupState,
	verifying: false,
	verifiedAddress: null,
	suggestedAddresses: null,
};

export const initialRateLookupState: RateLookupState = {
	rateCode: '',
	pending: false,
	errors: null,
};

export const initialRatePanelState: RatePanelState = {
	...initialPanelState,
	rateLookup: initialRateLookupState,
};

export const initialDistrictIdLookupState: DistrictIdLookupState = {
	district: null,
	errors: null,
	pending: false,
};

export const initialDistrictNameLookupState: DistrictByNameLookupState = {
	name: null,
	districts: null,
	errors: null,
	pending: false,
};

export const initialDistrictLookupsState: DistrictLookupsState = {
	idLookups: {},
	nameLookup: initialDistrictNameLookupState,
};

export const initialState: SchoolDetailsState = {
	schoolDetails: null,
	loaded: false,
	loading: false,
	creating: false,
	createdId: null,
	errors: null,
	panels: {
		[PanelId.Info]: { ...initialPanelState },
		[PanelId.PhysAddr]: { ...initialAddressPanelState } as PanelState,
		[PanelId.MailAddr]: { ...initialAddressPanelState } as PanelState,
		[PanelId.Contacts]: { ...initialPanelState },
		[PanelId.CurrentRate]: { ...initialRatePanelState } as PanelState,
		[PanelId.FutureRate]: { ...initialRatePanelState } as PanelState,
		[PanelId.Calendar]: { ...initialPanelState },
		[PanelId.Relationship]: { ...initialPanelState },
		[PanelId.OnlineRentals]: { ...initialPanelState },
		[PanelId.RentalReturns]: { ...initialPanelState },
		[PanelId.AdditionalDetails]: { ...initialPanelState },
	},
	rateAgreements: {},
	districtLookups: initialDistrictLookupsState,
};

export function reducer(state = initialState, action: fromSchoolDetails.SchoolDetailsActions | fromPrefs.PrefsActions): SchoolDetailsState {
	switch (action.type) {
		case fromSchoolDetails.SchoolDetailsActionTypes.LoadSchoolDetails: {
			return {
				...state,
				loading: true,
				errors: null,
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LoadSchoolDetailsComplete: {
			const schoolDetails = action.payload;

			return {
				...state,
				loaded: true,
				loading: false,
				errors: null,
				schoolDetails,
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LoadSchoolDetailsFailure: {
			return {
				...state,
				loaded: false,
				loading: false,
				errors: action.payload,
			};
    }

    case fromPrefs.PrefsActionTypes.CreateOverridePrefsSuccess: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: null
      };
    }

    case fromPrefs.PrefsActionTypes.CopyPrefsSuccess: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: null
      };
    }

    case fromPrefs.PrefsActionTypes.DeletePrefsSuccess: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: null
      };
    }

		case fromSchoolDetails.SchoolDetailsActionTypes.GetRateAgreement: {
			const { rateCode } = action.payload;

			return {
				...state,
				rateAgreements: {
					...state.rateAgreements,
					[rateCode]: {
						pending: true,
						rateAgreement: null,
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.GetRateAgreementSuccess: {
			const { panel, rateCode, rateAgreement } = action.payload;

			return {
				...state,
				rateAgreements: {
					...state.rateAgreements,
					[rateCode]: {
						pending: false,
						rateAgreement,
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.GetRateAgreementFailure: {
			const { panel, rateCode, errors } = action.payload;

			return {
				...state,
				rateAgreements: {
					...state.rateAgreements,
					[rateCode]: {
						pending: false,
						rateAgreement: null,
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LookupDistrictById: {
			const { id } = action.payload;
			const lookupState: DistrictIdLookupState = state.districtLookups.idLookups[id] || initialDistrictIdLookupState;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					idLookups: {
						...state.districtLookups.idLookups,
						[id]: {
							...lookupState,
							pending: true,
						},
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LookupDistrictByIdSuccess: {
			const { id, dl } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					idLookups: {
						...state.districtLookups.idLookups,
						[id]: {
							pending: false,
							errors: null,
							district: dl,
						},
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LookupDistrictByIdFailure: {
			const { id, error } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					idLookups: {
						...state.districtLookups.idLookups,
						[id]: {
							pending: false,
							errors: error,
							district: null,
						},
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LookupDistrictByName: {
			const { name } = action.payload;
			const nameLookup: DistrictByNameLookupState = {
				name,
				pending: true,
				errors: null,
				districts: null,
			};

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					nameLookup,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LookupDistrictByNameFailure: {
			const { error, name } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					nameLookup: {
						name,
						pending: false,
						errors: error,
						districts: null,
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.LookupDistrictByNameSuccess: {
			const { name, dls } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					nameLookup: {
						name,
						pending: false,
						errors: null,
						districts: dls,
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.UpdateEditorState: {
			const key = action.payload.key;
			const editing = action.payload.open;

			if (editing == state.panels[key].editing) {
				return state;
			}

			const panel = { ...initialState.panels[key], editing };	//	NOTE: initialPanelState will not have any custom panel initialization...
			const panels = {
				...state.panels,
				[key]: panel,
			};

			return {
				...state,
				panels,
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.UpdateSchoolPanel: {
			const panel = {
				...state.panels[action.payload.panel],
				updating: true,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[action.payload.panel]: panel,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.UpdateSchoolPanelFailure: {
			const panel = {
				...state.panels[action.payload.panel],
				updating: false,
				errors: action.payload.errors,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[action.payload.panel]: panel,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.UpdateSchoolPanelSuccess: {
			const schoolDetails = action.payload.school;

			return {
				...state,
				schoolDetails,
				panels: {
					...state.panels,
					[action.payload.panel]: initialState.panels[action.payload.panel],
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolAddressLookupCityState: {
			const { zip, panel } = action.payload;
			const zipLookup: ZipLookupState = {
				...(state.panels[panel] as AddressPanelState).zipLookup,
				zip,
				pending: true,
				errors: null,
				cityState: null,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						zipLookup,
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolAddressLookupCityStateFailure: {
			const { errors, panel } = action.payload;
			const zipLookup: ZipLookupState = {
				...(state.panels[panel] as AddressPanelState).zipLookup,
				pending: false,
				errors,
				cityState: null,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						zipLookup,
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolAddressLookupCityStateSuccess: {
			const { panel, cityState } = action.payload;
			const zipLookup: ZipLookupState = {
				...(state.panels[panel] as AddressPanelState).zipLookup,
				pending: false,
				cityState,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						zipLookup,
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolLoadCounties: {
			const { panel, stateCode } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						countyLookup: {
							...(state.panels[panel] as AddressPanelState).countyLookup,
							pending: true,
							lookupStateCode: stateCode,
						},
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolLoadCountiesSuccess: {
			const { panel, counties } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						countyLookup: {
							...(state.panels[panel] as AddressPanelState).countyLookup,
							pending: false,
							counties: {
								...(state.panels[panel] as AddressPanelState).countyLookup.counties,
								[(state.panels[panel] as AddressPanelState).countyLookup.lookupStateCode]: counties,
							},
						},
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolVerifyAddress: {
			const { panel, address } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						verifying: true,
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolVerifyAddressSuccess: {
			const { panel, verification } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						verifying: false,
						verifiedAddress: verification.verifiedAddress,
						suggestedAddresses: verification.picklist,
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolVerifyAddressFailure: {
			const { panel } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						verifying: false,
						verifiedAddress: null,
						suggestedAddresses: null,
					} as AddressPanelState,
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.ValidateExclusiveServiceAccountCode: {
			const { panel, validator, exclusiveServiceAccountCode } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...state.panels[panel].validationStatus,
							[validator]: {
								pending: true,
								errors: null,
							},
						},
					},
				},
			};
		}

		//case fromSchoolDetails.SchoolDetailsActionTypes.ValidateRateAgreement: {
		//	const { panel, validator, rateCode } = action.payload;

		//	return {
		//		...state,
		//		panels: {
		//			...state.panels,
		//			[panel]: {
		//				...state.panels[panel],
		//				validationStatus: {
		//					...state.panels[panel].validationStatus,
		//					[validator]: {
		//						pending: true,
		//						errors: null,
		//					},
		//				},
		//			},
		//		},
		//	};
		//}

		//case fromSchoolDetails.SchoolDetailsActionTypes.RateAgreementValidated: {
		//	const { panel, validator, errors } = action.payload;

		//	return {
		//		...state,
		//		panels: {
		//			...state.panels,
		//			[panel]: {
		//				...state.panels[panel],
		//				validationStatus: {
		//					...state.panels[panel].validationStatus,
		//					[validator]: {
		//						pending: false,
		//						errors,
		//					},
		//				},
		//			},
		//		},
		//	};
		//}

		case fromSchoolDetails.SchoolDetailsActionTypes.CreateSchool: {
			return {
				...state,
				creating: true,
				createdId: null,
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.CreateSchoolSuccess: {
			return {
				...state,
				creating: false,
				createdId: action.payload.id,
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.CreateSchoolFailure: {
			return {
				...state,
				creating: false,
				createdId: null,
				errors: action.payload.errors,
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.ValidateSchoolPrimaryId: {
			const { panel, validator } = action.payload;
			const validationStatus = { ...state.panels[panel].validationStatus, };
			const { canDeactivate, schoolExists, primaryId, ...rest } = validationStatus;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...rest,
							[validator]: {
								pending: true,
								errors: null,
							},
						},
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolPrimaryIdValidated: {
			const { panel, validator } = action.payload;
			const validationStatus = { ...state.panels[panel].validationStatus, };
			const { canDeactivate, schoolExists, primaryId, ...rest } = validationStatus;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...rest,
							[validator]: {
								pending: false,
								errors: action.payload.errors,
							},
						},
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.ValidateSchoolExists:
		case fromSchoolDetails.SchoolDetailsActionTypes.ValidateSchoolCanDeactivate: {
			const { panel, validator } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...state.panels[panel].validationStatus,
							[validator]: {
								pending: true,
								errors: null,
							},
						},
					},
				},
			};
		}

		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolExistsValidated:
		case fromSchoolDetails.SchoolDetailsActionTypes.SchoolCanDeactivateValidated: {
			const { panel, validator } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...state.panels[panel].validationStatus,
							[validator]: {
								pending: false,
								errors: action.payload.errors,
							},
						},
					},
				},
			};
		}

		default: {
			if (Object.values(fromSchoolDetails.SchoolDetailsActionTypes).includes(action.type as any)) {
				console.log('school-details.reducer: unhandled action: ', action);
			}
		}
	}

	return state;
}

export const getSchoolDetailsEntity = (state: SchoolDetailsState) => state.schoolDetails;
export const getSchoolDetailsLoading = (state: SchoolDetailsState) => state.loading;
export const getSchoolDetailsLoaded = (state: SchoolDetailsState) => state.loaded;
export const getDetailsStatePanels = (state: SchoolDetailsState) => state.panels;
export const getDetailsStateCountyLookup = (state: AddressPanelState) => state.countyLookup;
export const getDetailsStateZipLookup = (state: AddressPanelState) => state.zipLookup;
export const getDetailsStateRateAgreements = (state: SchoolDetailsState) => state.rateAgreements;
export const getDistrictLookupsState = (state: SchoolDetailsState) => state.districtLookups;
export const getDistrictByNameLookupState = (state: DistrictLookupsState) => state.nameLookup;
