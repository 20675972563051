import { Action } from '@ngrx/store';

import { ProgramCreate } from '../../models';
import { SchoolLookup } from '../../../schools/models';

export enum ProgramCreateActionTypes {
  CreateProgram = '[Program] Create Program',
  CreateProgramSuccess = '[Program] Create Program Success',
  CreateProgramFailure = '[Program] Create Program Failure',

  SchoolLookupById = '[Program] Lookup School By Id',
  SchoolLookupByIdSuccess = '[Program] Lookup School By Id Success',
  SchoolLookupByIdFailure = '[Program] Lookup School By Id Failure',

  SchoolLookupByName = '[Program] Lookup School By Name',
  SchoolLookupByNameSuccess = '[Program] Lookup School By Name Success',
  SchoolLookupByNameFailure = '[Program] Lookup School By Name Failure',
  SchoolLookupReset = '[Program] Lookup School Reset'
}

export class CreateProgram implements Action {
  readonly type = ProgramCreateActionTypes.CreateProgram;
  constructor(public payload: { program: ProgramCreate }) { }
}

export class CreateProgramSuccess implements Action {
  readonly type = ProgramCreateActionTypes.CreateProgramSuccess;
  constructor(public payload: { id: number, teacherId: number }) { }
}

export class CreateProgramFailure implements Action {
  readonly type = ProgramCreateActionTypes.CreateProgramFailure;
  constructor(public payload: any) { }
}

export class SchoolLookupById implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupById;
  constructor(public payload: { id: number }) { }
}

export class SchoolLookupByIdSuccess implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupByIdSuccess;
  constructor(public payload: { school: SchoolLookup }) { }
}

export class SchoolLookupByIdFailure implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupByIdFailure;
  constructor(public payload: any) { }
}

export class SchoolLookupByName implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupByName;
  constructor(public payload: { name: string }) { }
}

export class SchoolLookupByNameSuccess implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupByNameSuccess;
  constructor(public payload: { schools: SchoolLookup[] }) { }
}

export class SchoolLookupByNameFailure implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupByNameFailure;
  constructor(public payload: any) { }
}

export class SchoolLookupReset implements Action {
  readonly type = ProgramCreateActionTypes.SchoolLookupReset;
  constructor() { }
}

export type ProgramCreateActions =
  | CreateProgram
  | CreateProgramSuccess
  | CreateProgramFailure
  | SchoolLookupById
  | SchoolLookupByIdSuccess
  | SchoolLookupByIdFailure
  | SchoolLookupByName
  | SchoolLookupByNameSuccess
  | SchoolLookupByNameFailure
  | SchoolLookupReset;
