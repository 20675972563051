import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { districtNcesIdSources } from '../../scp-common/models/ncesIdSources';
import { District } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-district-addl-details-editor',
	templateUrl: './district-addl-details-editor.component.html',
})
export class DistrictAddlDetailsEditorComponent implements OnInit, OnDestroy {
	alive = true;
	district$: Observable<District>;
	panelState$: Observable<fromStore.PanelState>;
	district: District;
	panelState: fromStore.PanelState;
	panelId = fromStore.PanelId.AddlDetails;
	editor: FormGroup;
	months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	days: string[];
    districtNcesIdSourcesAll: string[] = districtNcesIdSources;

	constructor(private store: Store<fromStore.DistrictsState>, private fb: FormBuilder) {
		console.log('DistrictAddlDetailsEditorComponent()...');
	}

	ngOnInit() {
		console.log('DistrictAddlDetailsEditorComponent.ngOnInit()...');

		this.district$ = this.store.pipe(select(fromStore.getDetailsStateDistrict));
		this.district$.pipe(
			takeWhile(() => this.alive)
		).subscribe(d => this.district = d);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateAddlDetailsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('DistrictAddlDetailsEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('DistrictAddlDetailsEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		const budgetEnd: Date = this.district.budgetEnd
			? new Date(Date.parse(this.district.budgetEnd))
			: new Date(9999, 0, 1);

		this.editor = this.fb.group({
            ncesDistrictId: [this.district.ncesDistrictId],
            ncesIdSource: [this.district.ncesIdSource || ''],
			budgetEndMonth: [budgetEnd.getMonth()],
			budgetEndDay: [budgetEnd.getDate()],
		});

		this.days = this.getDaysInMonth(budgetEnd.getMonth());
		this.onBudgetEndChanges();
	}

	resetForm() {
		const budgetEnd: Date = this.district.budgetEnd
			? new Date(Date.parse(this.district.budgetEnd))
			: new Date(9999, 0, 1);

		this.editor.reset({
            ncesDistrictId: this.district.ncesDistrictId,
            ncesIdSource: [this.district.ncesIdSource || ''],
			budgetEndMonth: budgetEnd.getMonth(),
			budgetEndDay: budgetEnd.getDate(),
		});
	}

	onBudgetEndChanges() {
		this.editor.get('budgetEndMonth').valueChanges.pipe(
			takeWhile(() => this.alive),
		).subscribe(val => {
			console.log('budgetEndMonth.valueChanges: ', val);

			this.days = this.getDaysInMonth(val);

			if (+this.editor.get('budgetEndDay').value > +this.days[this.days.length - 1]) {
				this.editor.get('budgetEndDay').setValue(this.days[this.days.length - 1], { emitEvent: false });
			}
		});
	}

	getDaysInMonth(month) {
		const days: string[] = [];

		for (let i = 1; i <= new Date(9999, +month + 1, 0).getDate(); i++) {
			days.push(i.toString());
		}

		return days;
	}

    get districtNcesIdSources(): string[] {
      return this.districtNcesIdSourcesAll;
    }

	get canSave(): boolean {
		return !this.panelState.updating;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status);

		const budgetEnd = `${+this.editor.value.budgetEndMonth + 1}/${this.editor.value.budgetEndDay}/9999`;
        const ncesDistrictId = this.editor.value.ncesDistrictId || null;
        const ncesIdSource = this.editor.value.ncesIdSource || null;
        const isActive = this.district.isActive;

		const district = {
			...this.district,
			isActive,
            ncesDistrictId,
            ncesIdSource,
			budgetEnd,
		};

		this.store.dispatch(new fromStore.UpdateDistrictPanel({ panel: fromStore.PanelId.AddlDetails, district }));
	}
}
