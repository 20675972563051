<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="phone">Phone</div>
		<div class="property-block__value" role="definition" aria-labelledby="phone">{{ school.phone }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="fax">Fax</div>
		<div class="property-block__value" role="definition" aria-labelledby="fax">{{ school.fax }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="website">Website</div>
		<div class="property-block__value" role="definition" aria-labelledby="website"><a href="{{ school.website }}" target="_blank">{{ school.website }}</a></div>
	</li>
</ul>
