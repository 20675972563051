<ul class="property-block__list">
	<li class="property-block__list-item" *ngIf="district.inheritedRelationshipManager">
		<div class="property-block__label" id="inheritManager">Inherit Manager</div>
		<div class="property-block__value" role="definition" aria-labelledby="inheritManager">{{ district.inheritRelationshipManager | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="managerId">Manager ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="managerId">{{ district.relationshipManager.locationCode }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="managerName">Manager Name</div>
		<div class="property-block__value" role="definition" aria-labelledby="managerName">{{ district.relationshipManager.name }}</div>
	</li>
	<li class="property-block__list-item" *ngIf="district.inheritedRelationshipType">
		<div class="property-block__label" id="inheritRelationshipType">Inherit Relationship Type</div>
		<div class="property-block__value" role="definition" aria-labelledby="inheritRelationshipType">{{ district.inheritRelationshipType | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="relationshipType">Relationship Type</div>
		<ng-container *ngIf="!district.inheritRelationshipType">
			<div class="property-block__value" role="definition" aria-labelledby="relationshipType">{{ relationshipType }}</div>
		</ng-container>
		<ng-container *ngIf="district.inheritRelationshipType">
			<div class="property-block__value" role="definition" aria-labelledby="relationshipType">{{ inheritedRelationshipType }}</div>
		</ng-container>
	</li>
</ul>
