import { Component, Input } from '@angular/core';

import { TeacherDetails } from '../../models/teacher-details.model';

@Component({
	selector: 'opus-teacher-header',
	templateUrl: './teacher-header.html',
})
export class TeacherHeaderComponent {
	@Input() teacherDetails: TeacherDetails;

    get id() {
        return this.teacherDetails.id;
	}

	get name() {
        return this.teacherDetails.name;
    }
}
