import { detailsComponents } from './teacher-details';
import { TeacherCreateComponent } from './teacher-create';

export const components: any[] = [
    ...detailsComponents,
    TeacherCreateComponent
];

export * from './teacher-details';
export * from './teacher-create';
