import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'values' })
export class ValuesPipe implements PipeTransform {
	transform(value: any, key?: any): Object[] {
		if (!value) {
			return [];
		}

		const keys: any[] = Object.keys(value);
		const items: any[] = keys.map(k => value[k]);

		return items;
	}
}
