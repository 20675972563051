<div *ngIf="!event">
	<div class="history__details__no-item"></div>
</div>
<div *ngIf="event">
	<header class="history__details__header">
		<div class="row">
			<div class="columns">
				<h1 class="history__details__header-user">
					{{ event.displayName }}
					<a href="mailto:{{ event.email }}">&lt;{{ event.email }}&gt;</a>
				</h1>
				<p class="history__details__header-summary">{{ event.summary }}</p>
				<p class="history__details__header-tag">{{ event.tag }}</p>
			</div>
			<div class="columns shrink">
				<p class="history__details__header-date">{{ event.timestamp | date:'EEE, MMMM d, y h:mm:ss a' }}</p>
			</div>
		</div>
	</header>
	<ul class="history__details__field-list">
		<!--<li class="history__details__field-item">Item</li>-->
		<li class="history__details__field-item" *ngFor="let change of event.changes">
			<div class="history__details__field-label">{{ change.label }}</div>
			<div class="history__details__field-value" *ngIf="change.oldValue">
				<span class="history__details__field-value--old">{{ change.oldValue }}</span>
			</div>
			<div class="history__details__field-value" *ngIf="change.newValue">
				<span class="history__details__field-value--new">{{ change.newValue }}</span>
			</div>
		</li>
	</ul>
</div>

<!-- <pre class="typography--caption">{{ $ctrl.item | json }}</pre> -->
<!--
<div ng-hide="$ctrl.item">
	<div class="history__details__no-item"></div>
</div>
<div ng-show="$ctrl.item">
	<header class="history__details__header">
		<div class="row">
			<div class="columns">
				<h1 class="history__details__header-user">
					{{ $ctrl.item.user.displayName }}
					<a ng-href="mailto:{{ $ctrl.item.user.email }}">&lt;{{ $ctrl.item.user.email }}&gt;</a>
				</h1>
				<p class="history__details__header-summary">{{ $ctrl.item.summary }}</p>
				<p class="history__details__header-tag">{{ $ctrl.item.tag }}</p>
			</div>
			<div class="columns shrink">
				<p class="history__details__header-date">{{ $ctrl.item.timestamp | date:'EEE, MMMM d, y h:mm:ss a' }}</p>
			</div>
		</div>
	</header>
	<ul class="history__details__field-list">
		<li class="history__details__field-item" ng-repeat="field in $ctrl.item.fields | orderBy:'label'">
			<div class="history__details__field-label">{{ field.label }}</div>
			<div class="history__details__field-value"
				 ng-repeat="(type, value) in field.values">
				<span class="history__details__field-value--{{ type }}">{{ value }}</span>
			</div>
		</li>
	</ul>
</div>
-->
<!-- <pre class="typography--caption">{{ $ctrl.item | json }}</pre> -->
