<form novalidate aria-label="Physical Address" [formGroup]="editor" (ngSubmit)="onSubmit()">
	<ul class="property-block__list">
		<li class="property-block__list-item" *ngIf="!this.isPhysical">
			<div class="property-block__value property-block__value--no-label">
				<label class="checkbox u-m-b-0">
					<input type="checkbox" class="checkbox__input" formControlName="sameAsPhysical">
					Same as physical
				</label>
			</div>
		</li>
		<ng-container *ngIf="!editor.controls.sameAsPhysical.value">
			<li class="property-block__list-item">
				<label for="editPAddressLine1" class="property-block__label">Address Line 1</label>
				<div class="property-block__value">
					<input type="text" id="editPAddressLine1" formControlName="addressLine1">
				</div>
			</li>
			<li class="property-block__list-item">
				<label for="editPAddressLine2" class="property-block__label">Address Line 2</label>
				<div class="property-block__value">
					<input type="text" id="editPAddressLine2" formControlName="addressLine2">
				</div>
			</li>
			<li class="property-block__list-item">
				<label for="editPCity" class="property-block__label">City</label>
				<div class="property-block__value">
					<input type="text" id="editPCity" formControlName="addressCity">
				</div>
			</li>
			<li class="property-block__list-item">
				<div class="property-block__label" id="editPState">State</div>
				<div class="property-block__value" role="definition" aria-labelledby="editPState">
					<select id="editPState" formControlName="addressState">
						<option value="">Select</option>
						<option *ngFor="let s of states" [value]="s">{{ s }}</option>
					</select>
				</div>
			</li>
			<li class="property-block__list-item">
				<label for="editPZipCode" class="property-block__label">Zip Code</label>
				<div class="property-block__value">
					<div class="input-group u-m-b-0">
						<input type="text" id="editPZipCode" formControlName="addressZipcode">
						<div class="input-group-button">
							<button type="button" [disabled]="disableZipLookup" (click)="onApplyZip()" data-open="myModal">Apply</button>
						</div>
					</div>
					<ul class="error" *ngIf="hasZipLookupError">
						<li class="error__message" *ngIf="!editor.get('addressZipcode').valid">Zip code must be a sequence of 5 digits, optionally followed by a hyphen and an additional 4 digits.</li>
						<li class="error__message" *ngIf="this.zipLookup && this.zipLookup.errors">Unable to lookup city &amp; state.</li>
					</ul>
				</div>
			</li>
			<li class="property-block__list-item" *ngIf="this.isPhysical">
				<label for="editPCounty" class="property-block__label">County</label>
				<div class="property-block__value">
					<select id="editPCounty" formControlName="addressCounty">
						<option value="">Select</option>
						<option *ngFor="let c of counties$ | async" [value]="c.toUpperCase()">{{ c }}</option>
					</select>
				</div>
			</li>
		</ng-container>
		<ng-container *ngIf="editor.controls.sameAsPhysical.value">
			<li class="property-block__list-item">
				<div class="property-block__label" id="pAddressLine1">Address Line 1</div>
				<div class="property-block__value" role="definition" aria-labelledby="pAddressLine1">{{ district.physicalAddress.line1 }}</div>
			</li>
			<li class="property-block__list-item" *ngIf="address.line2">
				<div class="property-block__label" id="pAddressLine2">Address Line 2</div>
				<div class="property-block__value" role="definition" aria-labelledby="pAddressLine2">{{ district.physicalAddress.line2 }}</div>
			</li>
			<li class="property-block__list-item">
				<div class="property-block__label" id="pCityStateZip">City, State Zip</div>
				<div class="property-block__value" role="definition" aria-labelledby="pCityStateZip">{{ district.physicalAddress.city }}, {{ district.physicalAddress.state }} {{ district.physicalAddress.zip }}</div>
			</li>
		</ng-container>
		<li class="property-block__list-item" *ngIf="this.isPhysical">
			<div class="property-block__value property-block__value--no-label">
				<label class="checkbox u-m-b-0">
					<input type="checkbox" class="checkbox__input" formControlName="updateMailing">
					Update mailing address
				</label>
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
		</div>
	</div>
	<!--<ul class="property-block__list">
		<li class="property-block__list-item">
			<div class="property-block__label">Form Value</div>
			<div class="property-block__value">{{ editor.value | json }}</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label">Form Status</div>
			<div class="property-block__value">{{ editor.status | json }}</div>
		</li>
	</ul>-->
</form>
<opus-modal [visible]="showModal" (action)="onModalAction($event)" (actionTertiary)="onModalTertiaryAction($event)" title="Address Correction" [primaryLabel]="suggestedAddress ? 'Accept Suggestion' : 'Save as Entered'" secondaryLabel="Edit" 
            [tertiaryLabel]="suggestedAddress ? 'Save as Entered' : ''" showClose="true">
    <ng-container *ngIf="enteredAddress">
        <div class="property-block">
            <div class="property-block__title-wrapper">
                <h2 class="property-block__title">Entered Address:</h2>
            </div>
            <ul class="property-block__list">
                <li class="property-block__list-item">
                    <div class="property-block__label" id="eAddressLine1">Address Line 1</div>
                    <div class="property-block__value" role="definition" aria-labelledby="eAddressLine1">{{ enteredAddress.line1 }}</div>
                </li>
                <li class="property-block__list-item" *ngIf="enteredAddress.line2">
                    <div class="property-block__label" id="eAddressLine2">Address Line 2</div>
                    <div class="property-block__value" role="definition" aria-labelledby="eAddressLine2">{{ enteredAddress.line2 }}</div>
                </li>
                <li class="property-block__list-item">
                    <div class="property-block__label" id="eCityStateZip">City, State Zip</div>
                    <div class="property-block__value" role="definition" aria-labelledby="eCityStateZip">{{ enteredAddress.city }}, {{ enteredAddress.state }} {{ enteredAddress.zip }}</div>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="suggestedAddress">
        <div class="property-block">
            <div class="property-block__title-wrapper">
                <h2 class="property-block__title">Suggested Address:</h2>
            </div>
            <ul class="property-block__list">
                <li class="property-block__list-item">
                    <div class="property-block__label" id="sAddressLine1">Address Line 1</div>
                    <div class="property-block__value" role="definition" aria-labelledby="sAddressLine1">{{ suggestedAddress.line1 }}</div>
                </li>
                <li class="property-block__list-item" *ngIf="suggestedAddress.line2">
                    <div class="property-block__label" id="sAddressLine2">Address Line 2</div>
                    <div class="property-block__value" role="definition" aria-labelledby="sAddressLine2">{{ suggestedAddress.line2 }}</div>
                </li>
                <li class="property-block__list-item">
                    <div class="property-block__label" id="sCityStateZip">City, State Zip</div>
                    <div class="property-block__value" role="definition" aria-labelledby="sCityStateZip">{{ suggestedAddress.city }}, {{ suggestedAddress.state }} {{ suggestedAddress.zip }}</div>
                </li>
            </ul>
        </div>
    </ng-container>
	<ng-container *ngIf="!suggestedAddress">
		<ul class="error">
			<li class="error__message">The United States Postal Service is unable to locate this address.</li>
		</ul>
	</ng-container>
</opus-modal>

