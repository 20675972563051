import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Operation, compare } from 'fast-json-patch';

import { State } from '../../../../core/store';
import * as programDetailsActions from '../actions/program-details.action';
import * as fromServices from '../../../scp-common/services';
import * as fromSelectors from '../selectors';
import * as fromModels from '../../models';
import * as fromRoot from '../../../../core/store';
import { MessageType, ProblemDetails } from '../../../../core/models';

@Injectable()
export class ProgramDetailsEffects {
    constructor(
        private actions$: Actions,
        private programService: fromServices.ProgramService,
        private teacherService: fromServices.TeacherService,
        private store$: Store<State>,
    ) { }

    
    loadProgramDetails$ = createEffect(() => this.actions$.pipe(
        ofType(programDetailsActions.ProgramDetailsActionTypes.LoadProgramDetails),
        tap(a => {
            console.log('Entering loadProgramDetails$ Effect: ', a);
        }),
        switchMap((action: programDetailsActions.LoadProgramDetails) => this.programService.loadProgram(action.payload).pipe(
            tap(res => console.log(`programService.loadProgram( ${action.payload} ) returned: `, res)),
            map(program => new programDetailsActions.LoadProgramDetailsComplete(program)),
            catchError(error => of(new programDetailsActions.LoadProgramDetailsFailure(error.message))),
        )),
        tap(a => {
            console.log('Exiting loadProgramDetails$ Effect: ', a);
        }),
    ));

    
    updateProgramPanel$ = createEffect(() => this.actions$.pipe(
        ofType(programDetailsActions.ProgramDetailsActionTypes.UpdateProgramPanel),
        tap(a => {
            console.log('Entering updateProgramPanel$ Effect: ', a);
        }),
        //	get the current value of the district from the store
        withLatestFrom(this.store$.select(fromSelectors.getProgramDetailsEntity)),
        //	create a json patch by comparing the current district with the updated version
        //	typescript note: using array destructuring to pull the withLatestFrom output into individual parameters to map
        map(([action, program]: [programDetailsActions.UpdateProgramPanel, fromModels.ProgramDetails]) => {
            const patch = compare(program, action.payload.program);

            console.log('new program:', action.payload.program, 'current program:', program, 'patch:', patch);

            //	the district service needs the id & the json patch
            return { panel: action.payload.panel, program, patch };
        }),
        switchMap(({ panel, program, patch }: { panel: string, program: fromModels.ProgramDetails, patch: Operation[] }) => {
            if (patch.length == 0) {
                console.log('updateProgramPanel$ Effect: Skipping empty patch...');

                return of(new programDetailsActions.UpdateProgramPanelSuccess({ panel, program }));
            }

            console.log('updateProgramPanel$ Effect: Calling programService.patchProgram(', program.id, ', ', patch, ')...');

            return this.programService.patchProgram(program.id, patch).pipe(
                map(program => new programDetailsActions.UpdateProgramPanelSuccess({ panel, program })),
                //	TODO: in the case of a bad request, we should really be grabbing the validation errors from the response body
                //	However, since everything should already be validated client side, this is low priority.
                catchError(errors => {
                    return of(new programDetailsActions.UpdateProgramPanelFailure({ panel, errors }));
                }),
            );
        }),
        tap(a => {
            console.log('Exiting updateProgramPanel$ Effect: ', a);
        }),
    ));

    
    updateProgramPanelFailure$ = createEffect(() => this.actions$.pipe(
        ofType(programDetailsActions.ProgramDetailsActionTypes.UpdateProgramPanelFailure),
        tap(a => {
            console.log('Entering updateProgramPanelFailure$ Effect: ', a);
        }),
        map((a: programDetailsActions.UpdateProgramPanelFailure) => {
            const { errors } = a.payload;

            //if (errors && errors.error) {
            //	var error = errors.error[0];
            //	return new fromRoot.DisplayMessage({
            //		message: errors.error[Object.keys(errors.error)[0]],
            //		messageType: MessageType.alert,
            //		toast: false
            //	});
            //} else {
            return new fromRoot.DisplayMessage({
                message: this.getErrorMessage(errors),
                messageType: MessageType.alert,
                toast: false,
            });
            //}
        })
    ));

    
    teacherLookupById$ = createEffect(() => this.actions$.pipe(
        ofType(programDetailsActions.ProgramDetailsActionTypes.TeacherLookupById),
        switchMap((action: programDetailsActions.TeacherLookupById) => {
            console.log('Teacher Lookup By ID Effect');

            return this.teacherService.lookupTeacherById(action.payload.teacherId).pipe(
                tap(d => {
                    console.log('teacherLookupById$ returned:', d);
                }),
                map(teacherResult => {
                    if (teacherResult === null) {
                        return new programDetailsActions.TeacherLookupByIdFailure({ panel: action.payload.panel, errors: 'Teacher Not Found' });
                    }

                    return new programDetailsActions.TeacherLookupByIdSuccess({ panel: action.payload.panel, teacher: teacherResult });
                }),
                catchError(error => {
                    return of(new programDetailsActions.TeacherLookupByIdFailure({ panel: action.payload.panel, errors: error }));
                }),
            );
        })
    ));

    
    teacherLookupByIdFailure$ = createEffect(() => this.actions$.pipe(
        ofType(programDetailsActions.ProgramDetailsActionTypes.TeacherLookupByIdFailure),
        tap(a => console.log('Entering teacherLookupByIdFailure$ Effect: ', a)),
        map((a: programDetailsActions.TeacherLookupByIdFailure) => {
            return new fromRoot.DisplayMessage({
                message: a.payload.errors.status == 404 ? 'Teacher Not Found' : this.getErrorMessage(a.payload.errors),
                messageType: MessageType.alert,
                toast: false,
            });
        }),
        tap(a => console.log('Exiting teacherLookupByIdFailure$ Effect: ', a)),
    ));

    
    teacherLookupByCriteria$ = createEffect(() => this.actions$.pipe(
        ofType(programDetailsActions.ProgramDetailsActionTypes.TeacherLookupByCriteria),
        switchMap((action: programDetailsActions.TeacherLookupByCriteria) => {
            console.log('Load Teacher Lookup By Criteria Effect');

            return this.teacherService.lookupTeacherByCriteria(action.payload.criteria).pipe(
                tap(d => {
                    console.log('teacherLookupByCriteria$ returned:', d);
                }),
                map(teacherResult => {
                    if (teacherResult === null) {
                        return new programDetailsActions.TeacherLookupByCriteriaFailure({ panel: action.payload.panel, errors: 'Teacher Not Found' });
                    }

                    return new programDetailsActions.TeacherLookupByCriteriaSuccess({ panel: action.payload.panel, teachers: teacherResult });
                }),
                catchError(error => of(new programDetailsActions.TeacherLookupByCriteriaFailure(error)))
            );
        })
    ));

  private getErrorMessage(response: HttpErrorResponse): string {
    if (response.status === 400 && response.error) {
      try {
        const problemDetails = response.error as ProblemDetails;
        if (problemDetails) {
          const firstError = problemDetails.errors ? problemDetails.errors[Object.keys(problemDetails.errors)[0]] : null;
          if (firstError)
            return firstError;
          else if (problemDetails.title)
            return problemDetails.title;
        }
      }
      catch (ex) {
        console.warn(ex);
      }
    }

    return response.error ? response.error.message : response.message;
  }
}
