import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSchoolSearchResults from '../reducers/searchresults.reducer';

export const getSchoolSearchState = createSelector(
    fromFeature.getSchoolsState,
    (state: fromFeature.SchoolsState) => state.search
);
export const getSchoolSearchResultsState = createSelector(
    getSchoolSearchState,
    fromSchoolSearchResults.getSchoolSearchResults
);
