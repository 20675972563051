import { Component, Input } from '@angular/core';

import { District, InactiveReason } from '../../models';

@Component({
	selector: 'opus-district-info',
	templateUrl: './district-info.component.html',
})
export class DistrictInfoComponent {
	@Input() district: District;

	get id(): string {
		return this.district.id.toString();
	}

	get isActive(): boolean {
		return this.district.isActive;
	}

	get inactiveReason(): string {
		return InactiveReason[this.district.inactiveReason];
    }

	get longName(): string {
		return this.district.longName;
	}

	get shortName(): string {
		return this.district.shortName;
	}

	get isSupervisory(): boolean {
		return this.district.isSupervisory;
	}

	get supervisoryDistrictId(): string {
		return this.district.supervisoryDistrictId ? this.district.supervisoryDistrictId.toString() : '';
	}

	get supervisoryDistrictName(): string {
		return this.district.supervisoryDistrictName;
    }

    get displayPrimaryId(): boolean {
        return !this.district.isActive && this.district.inactiveReason === InactiveReason.Duplicate;
    }

    get primaryId(): number {
        return this.district.primaryId;
    }
}
