import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Event } from '../models';
import * as fromStore from '../store';
import * as validators from '../../../core/validators';

@Component({
    selector: 'event-staffing-editor',
    templateUrl: './event-staffing-editor.component.html',
})
export class EventStaffingEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    alive = true;
    event$: Observable<Event>;
    event: Event;
    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;

    editor: FormGroup;

    constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder) {

    }

    ngOnInit() {
        this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
        this.event$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(d => this.event = d);

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventStaffingPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.createForm();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.alive = false;
    }

    createForm() {
        this.editor = new FormGroup({
            supervisor: new FormControl(this.event.supervisor),
            staffNeeded: new FormControl(this.event.staffNeeded, [validators.NumericValidators.minOrNull(1)]),
            staffNames: new FormControl(this.event.staffNames),
        });
    }

    resetForm() {

    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty && !this.panelState.updating;
    }

    onSubmit() {
        const supervisor = this.editor.value.supervisor;
        const staffNeeded = this.editor.value.staffNeeded;
        const staffNames = this.editor.value.staffNames;

        const event = {
            ...this.event,
            supervisor,
            staffNeeded,
            staffNames,
        };

        this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventStaffing, event }));
    }


}
