<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="primary">Primary ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="primary">{{ school.primaryId }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="ncesSchoolId">NCES School ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="ncesSchoolId">{{ school.ncesSchoolId }}</div>
	</li>
	<!--<li class="property-block__list-item">
		<div class="property-block__label" id="ncesIdSource">NCES ID Source</div>
		<div class="property-block__value" role="definition" aria-labelledby="ncesIdSource">{{ school.ncesIdSource }}</div>
	</li>-->
	<li class="property-block__list-item">
		<div class="property-block__label" id="summerBilling">Summer Billing</div>
		<div class="property-block__value" role="definition" aria-labelledby="summerBilling">{{ summerBilling }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="summerCustomerInHand">Summer Customer in-hand</div>
		<div class="property-block__value" role="definition" aria-labelledby="summerCustomerInHand">{{ summerCustomerInHand }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="fallBilling">Fall Billing</div>
		<div class="property-block__value" role="definition" aria-labelledby="fallBilling">{{ fallBilling }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="fallCustomerInHand">Fall Customer in-hand</div>
		<div class="property-block__value" role="definition" aria-labelledby="fallCustomerInHand">{{ fallCustomerInHand }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="summerFree">Summer Free</div>
		<div class="property-block__value" role="definition" aria-labelledby="summerFree">{{ school.summerFree | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="latitude">Latitude</div>
		<div class="property-block__value" role="definition" aria-labelledby="latitude">{{ school.latitude }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="longitude">Longitude</div>
		<div class="property-block__value" role="definition" aria-labelledby="longitude">{{ school.longitude }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="timezone">Time Zone</div>
		<div class="property-block__value" role="definition" aria-labelledby="timezone">{{ school.ianaTimeZoneId }}</div>
	</li>
</ul>
