import * as fromEventDetails from '../actions/details.actions';
import * as fromModels from '../../models';
import * as fromSchoolModels from '../../../schools/models';
import { TeacherLookupCriteria } from '../../../teachers/models';

export enum PanelId {
    EventInfo = 'event-info',
    EventHost = 'event-host',
    EventDateTime = 'event-date-time',
    EventLocation = 'event-location',
    EventStaffing = 'event-staffing',
    EventMeetingDetails = 'event-meeting-details',
    EventSchoolsAttending = 'event-schools-attending',
    EventPromo = 'event-promo'
    //RelMgr = 'relmgr',
    //Contacts = 'contacts',
    //Calendar = 'calendar',
    //AddlDetails = 'addl-details',
}

export interface ValidationStatus {
    [id: string]: {
        pending: boolean;
        errors: { [key: string]: any };
    };
}

export interface PanelState {
    editing: boolean;
    updating: boolean;
    validationStatus: ValidationStatus;
    errors: any;	//	TODO: formalize error handling
}

export interface AddressPanelState extends PanelState {
    verifying: boolean;
    verifiedAddress: fromModels.Address;
    suggestedAddresses: fromModels.Address[];
}

export interface SchoolLookupState {
    schoolId: number;
    pending: boolean;
    errors: any;
    school: fromSchoolModels.SchoolLookup;
}

export interface SchoolByNameLookupState {
    name: string;
    pending: boolean;
    errors: any;
    schools: fromSchoolModels.SchoolLookup[];
}

export interface TeacherLookupState {
    teacherId: number;
    pending: boolean;
    errors: any;
    teacher: fromSchoolModels.TeacherLookup;
}

export interface TeacherByCriteriaLookupState {
    criteria: TeacherLookupCriteria;
    pending: boolean;
    errors: any;
    teachers: fromSchoolModels.TeacherLookup[];
}

export interface EventHostPanelState extends PanelState {
    teacherLookup: TeacherLookupState;
    teacherByCriteriaLookup: TeacherByCriteriaLookupState;
    verifying: boolean;
}

export interface RelationshipManagerState {
    id: number;
    loading: boolean;
    loaded: boolean;
    relationshipManagerEmails: string[];
    errors: any;
}

export interface DetailsState {
    approvedPromos: fromModels.EventPromo[];
    loading: boolean;
    loaded: boolean;
    creating: boolean;
    createdId: number;
    event: fromModels.Event;
    errors: any;	//	TODO: formalize error handling
    //districtInfoState: DistrictInfoState;
    schoolLookup: SchoolLookupState;
    schoolByNameLookup: SchoolByNameLookupState;
    teacherLookup: TeacherLookupState;
    panels: { [id: string]: PanelState };
    copyEventState: CopyEventState;
    relationshipManagerState: RelationshipManagerState;
    qrCodeDownloadFileState: QrCodeFiledownloadState;
}

export interface CopyEventState {
    loading: boolean;
    loaded: boolean;
    event: fromModels.Event;
    errors: any;
}

export const initialPanelState: PanelState = {
    editing: false,
    validationStatus: {},
    updating: false,
    errors: null,
};

export const initialAddressPanelState: AddressPanelState = {
    ...initialPanelState,
    verifying: false,
    verifiedAddress: null,
    suggestedAddresses: null,
};

export const initialSchoolLookupState: SchoolLookupState = {
    schoolId: null,
    pending: false,
    school: null,
    errors: null,
};

export const initialSchoolByNameLookupState: SchoolByNameLookupState = {
    name: null,
    pending: false,
    errors: null,
    schools: null
};

export const initialTeacherLookupState: TeacherLookupState = {
    teacherId: null,
    pending: false,
    teacher: null,
    errors: null,
};

export const initialTeacherByCriteriaLookupState: TeacherByCriteriaLookupState = {
    criteria: { name: null },
    pending: false,
    errors: null,
    teachers: null
};

export const initialEventHostPanelState: EventHostPanelState = {
    ...initialPanelState,
    //schoolLookup: initialSchoolLookupState,
    //schoolByNameLookup: initialSchoolByNameLookupState,
    teacherLookup: initialTeacherLookupState,
    teacherByCriteriaLookup: initialTeacherByCriteriaLookupState,
    verifying: false,
};

export const initialRelationshipManagerState: RelationshipManagerState = {
  id: null,
  errors: null,
  loaded: false,
  loading: false,
  relationshipManagerEmails: null
};

export interface QrCodeFiledownloadState {
  id: number;
  loading: boolean;
  loaded: boolean;
  errors: any;
}

export const initialState: DetailsState = {
    approvedPromos: null,
    loading: false,
    loaded: false,
    creating: false,
    createdId: null,
    event: null,
    errors: null,
    schoolLookup: initialSchoolLookupState,
    schoolByNameLookup: initialSchoolByNameLookupState,
    teacherLookup: initialTeacherLookupState,
    copyEventState: {
        loaded: false,
        loading: false,
        event: null,
        errors: null
    },
    relationshipManagerState: initialRelationshipManagerState,
    panels: {
        [PanelId.EventInfo]: { ...initialPanelState },
        [PanelId.EventDateTime]: { ...initialPanelState },
        [PanelId.EventLocation]: { ...initialAddressPanelState } as AddressPanelState,
        [PanelId.EventHost]: { ...initialEventHostPanelState } as PanelState,
        [PanelId.EventStaffing]: { ...initialPanelState },
        [PanelId.EventMeetingDetails]: { ...initialPanelState },
        [PanelId.EventSchoolsAttending]: { ...initialPanelState },
        [PanelId.EventPromo]: { ...initialPanelState },
    },
    qrCodeDownloadFileState: {
      id: null,
      loading: false,
      loaded: false,
      errors: null,
    },
};

export function reducer(state = initialState, action: fromEventDetails.EventActions): DetailsState {
    switch (action.type) {
        case fromEventDetails.EventActionTypes.CreateEvent: {
            return {
                ...state,
                creating: true,
                createdId: null,
            };
        }

        case fromEventDetails.EventActionTypes.CreateEventSuccess: {
            return {
                ...state,
                creating: false,
                createdId: action.payload.id,
            };
        }

        case fromEventDetails.EventActionTypes.CreateEventFailure: {
            return {
                ...state,
                creating: false,
                createdId: null,
                errors: action.payload.errors,
            };
        }

        case fromEventDetails.EventActionTypes.LoadEvent: {
            return {
                ...state,
                loading: true,
            };
        }

        case fromEventDetails.EventActionTypes.LoadEventSuccess: {
            const event = action.payload;

            return {
                ...updateDetailsState(state, event),
                loaded: true,
                loading: false,
            };
        }

        case fromEventDetails.EventActionTypes.LoadEventFailure: {
            console.log('reducer:', action);

            return {
                ...state,
                loaded: false,
                loading: false,
                errors: action.payload,
            };
        }

        case fromEventDetails.EventActionTypes.CopyEvent: {
            return {
                ...state,
                copyEventState: {
                    ...state.copyEventState,
                    loading: true,
                    loaded: false,
                    errors: null,
                }
            };
        }

        case fromEventDetails.EventActionTypes.CopyEventSuccess: {
            const event = action.payload;

            return {
                ...state,
                copyEventState: {
                    ...state.copyEventState,
                    loaded: true,
                    loading: false,
                    event: event.event
                }
            };
        }

        case fromEventDetails.EventActionTypes.CopyEventFailure: {
            return {
                ...state,
                copyEventState: {
                    ...state.copyEventState,
                    loaded: false,
                    loading: false,
                    errors: action.payload,
                }
            };
        }

        case fromEventDetails.EventActionTypes.LoadApprovedPromos: {
            return {
                ...state,
                loading: true,
            };
        }

        case fromEventDetails.EventActionTypes.LoadApprovedPromosSuccess: {
            const approvedPromos = action.payload;

            return {
                ...state,
                approvedPromos: approvedPromos,
                loaded: true,
                loading: false,
            };
        }

        case fromEventDetails.EventActionTypes.LoadApprovedPromosFailure: {
            console.log('reducer:', action);

            return {
                ...state,
                loaded: false,
                loading: false,
                approvedPromos: null,
                errors: action.payload,
            };
        }

        case fromEventDetails.EventActionTypes.UpdateEditorState: {
            const key = action.payload.key;
            const open = action.payload.open;

            //	TODO: convert district info & do away with this switch
            switch (key) {
                default: {
                    const panel = { ...state.panels[key], editing: open, validationStatus: {} };
                    const panels = {
                        ...state.panels,
                        [key]: panel,
                    };

                    return {
                        ...state,
                        panels,
                    };
                }
            }
        }

        case fromEventDetails.EventActionTypes.UpdateEventPanel: {
            const panel = {
                ...state.panels[action.payload.panel],
                updating: true,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.payload.panel]: panel,
                },
            };
        }

        case fromEventDetails.EventActionTypes.UpdateEventPanelFailure: {
            const panel = {
                ...state.panels[action.payload.panel],
                updating: false,
                errors: action.payload.errors,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.payload.panel]: panel,
                },
            };
        }

        case fromEventDetails.EventActionTypes.UpdateEventPanelSuccess: {
            const event = action.payload.event;

            return {
                ...state,
                event,
                panels: {
                    ...state.panels,
                    [action.payload.panel]: initialPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.SchoolLookupById: {
            const { schoolId } = action.payload;
            const schoolLookup: SchoolLookupState = {
                schoolId,
                pending: true,
                errors: null,
                school: null,
            };

            return {
                ...state,
                schoolLookup
            };
        }

        case fromEventDetails.EventActionTypes.SchoolLookupByIdFailure: {
            const { errors } = action.payload;
            const schoolLookup: SchoolLookupState = {
                ...state.schoolLookup,
                pending: false,
                errors,
                school: null,
            };

            return {
                ...state,
                schoolLookup,
            };
        }

        case fromEventDetails.EventActionTypes.SchoolLookupByIdSuccess: {
            const { school } = action.payload;
            const schoolLookup: SchoolLookupState = {
                ...state.schoolLookup,
                pending: false,
                school,
            };

            return {
                ...state,
                schoolLookup,
            };
        }

        case fromEventDetails.EventActionTypes.SchoolLookupByName: {
            const { name } = action.payload;
            const schoolByNameLookup: SchoolByNameLookupState = {
                name,
                pending: true,
                errors: null,
                schools: null,
            };

            return {
                ...state,
                schoolByNameLookup,
            };
        }

        case fromEventDetails.EventActionTypes.SchoolLookupByNameFailure: {
            const { errors, panel } = action.payload;
            const schoolByNameLookup: SchoolByNameLookupState = {
                ...state.schoolByNameLookup,
                pending: false,
                errors,
                schools: null,
            };

            return {
                ...state,
                schoolByNameLookup,
            };
        }

        case fromEventDetails.EventActionTypes.SchoolLookupByNameSuccess: {
            const { panel, schools } = action.payload;
            const schoolByNameLookup: SchoolByNameLookupState = {
                ...state.schoolByNameLookup,
                pending: false,
                schools,
            };

            return {
                ...state,
                schoolByNameLookup,
            };
        }
        //Teacher
        case fromEventDetails.EventActionTypes.TeacherLookupById: {
            const { teacherId, panel } = action.payload;
            const teacherLookup: TeacherLookupState = {
                ...(state.panels[panel] as EventHostPanelState).teacherLookup,
                teacherId,
                pending: true,
                errors: null,
                teacher: null,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        teacherLookup,
                    } as EventHostPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.TeacherLookupByIdFailure: {
            const { errors, panel } = action.payload;
            const teacherLookup: TeacherLookupState = {
                ...state.teacherLookup,
                pending: false,
                errors,
                teacher: null,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        teacherLookup,
                    } as EventHostPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.TeacherLookupByIdSuccess: {
            const { panel, teacher } = action.payload;
            const teacherLookup: TeacherLookupState = {
                ...(state.panels[panel] as EventHostPanelState).teacherLookup,
                pending: false,
                teacher,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        teacherLookup,
                    } as EventHostPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.TeacherLookupByCriteria: {
            const { criteria, panel } = action.payload;
            const teacherByCriteriaLookup: TeacherByCriteriaLookupState = {
                ...(state.panels[panel] as EventHostPanelState).teacherByCriteriaLookup,
                criteria,
                pending: true,
                errors: null,
                teachers: null,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        teacherByCriteriaLookup: teacherByCriteriaLookup,
                    } as EventHostPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.TeacherLookupByCriteriaFailure: {
            const { errors, panel } = action.payload;
            const teacherByCriteriaLookup: TeacherByCriteriaLookupState = {
                ...(state.panels[panel] as EventHostPanelState).teacherByCriteriaLookup,
                pending: false,
                errors,
                teachers: null,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        teacherByCriteriaLookup: teacherByCriteriaLookup,
                    } as EventHostPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.TeacherLookupByCriteriaSuccess: {
            const { panel, teachers } = action.payload;
            const teacherByCriteriaLookup: TeacherByCriteriaLookupState = {
                ...(state.panels[panel] as EventHostPanelState).teacherByCriteriaLookup,
                pending: false,
                teachers,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        teacherByCriteriaLookup: teacherByCriteriaLookup,
                    } as EventHostPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.EventVerifyAddress: {
            const { panel, address } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        verifying: true,
                    } as AddressPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.EventVerifyAddressSuccess: {
            const { panel, verification } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        verifying: false,
                        verifiedAddress: verification.verifiedAddress,
                        suggestedAddresses: verification.picklist,
                    } as AddressPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.EventVerifyAddressFailure: {
            const { panel, address, errors } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        errors,
                        verifying: false,
                        verifiedAddress: address,
                        suggestedAddresses: [],
                    } as AddressPanelState,
                },
            };
        }

        case fromEventDetails.EventActionTypes.LoadRelationshipManagers: {
            return {
                ...state,
                relationshipManagerState: {
                    ...state.relationshipManagerState,
                    loading: true,
                    loaded: false
                }
            };
        }

        case fromEventDetails.EventActionTypes.LoadRelationshipManagersSuccess: {
            const { id, relationshipManagerEmails } = action.payload;

            return {
                ...state,
                relationshipManagerState: {
                    ...state.relationshipManagerState,
                    id: id,
                    loaded: true,
                    loading: false,
                    relationshipManagerEmails: relationshipManagerEmails
                }
            };
        }

        case fromEventDetails.EventActionTypes.LoadRelationshipManagersFailure: {
            return {
                ...state,
                relationshipManagerState: {
                    ...state.relationshipManagerState,
                    loaded: false,
                    loading: false,
                    errors: action.payload.errors,
                }
            };
      }

      case fromEventDetails.EventActionTypes.DownloadQrCodes: {
        return {
          ...state,
          qrCodeDownloadFileState: {
            ...state.qrCodeDownloadFileState,

            id: action.payload.id,
            loading: true,
            loaded: false,
            errors: null,
          },
        };
      }

      case fromEventDetails.EventActionTypes.DownloadQrCodesSuccess: {
        return {
          ...state,
          qrCodeDownloadFileState: {
            ...state.qrCodeDownloadFileState,

            loading: false,
            loaded: true,
          },
        };
      }

      case fromEventDetails.EventActionTypes.DownloadQrCodesFailure: {
        return {
          ...state,
          qrCodeDownloadFileState: {
            ...state.qrCodeDownloadFileState,

            loading: false,
            loaded: false,
            errors: action.payload.error,
          },
        };
      }

    }

    return state;
}

function updateDetailsState(state: DetailsState, event: fromModels.Event) {
    //	extract the district info properties
    //const { id, isActive, longName, shortName, isSupervisory, supervisoryDistrictId, supervisoryDistrictName } = district;
    //	create a district info object from them
    //const districtInfo = { id, isActive, longName, shortName, isSupervisory, supervisoryDistrictId, supervisoryDistrictName };
    //const districtInfoState = {
    //    editing: false,
    //    updating: false,
    //    errors: null,
    //    districtInfo,
    //};

    //const { /*relationshipManagerId,*/ inheritRelationshipManager, relationshipManagerCode, relationshipManagerName, hasCommissions, /*inheritedManagerId, inheritedManagerCode, inheritedManagerName, inheritedHasCommissions,*/ inheritRelationshipType, relationshipType, /*inheritedRelationshipType*/ } = district;
    //const relationshipManager = { /*relationshipManagerId,*/ inheritRelationshipManager, relationshipManagerCode, relationshipManagerName, hasCommissions, /*inheritedManagerId, inheritedManagerCode, inheritedManagerName, inheritedHasCommissions,*/ inheritRelationshipType, relationshipType, /*inheritedRelationshipType*/ };

    return {
        ...state,
        event,
        //districtInfoState,
    };
}

export const getDetailsStateDistrict = (state: DetailsState) => state.event;
export const getDetailsStateLoading = (state: DetailsState) => state.loading;
export const getDetailsStateLoaded = (state: DetailsState) => state.loaded;
export const getDetailsStateApprovedPromos = (state: DetailsState) => state.approvedPromos;
//export const getDetailsStateErrors = (state: DetailsState) => state.errors;
//export const getDetailsStateInfoState = (state: DetailsState) => state.districtInfoState;
export const getDetailsStatePanels = (state: DetailsState) => state.panels;
//export const getDetailsStateContactsPanel = (state: PanelState) => state[PanelId.Contacts];
export const getDetailsStateSchoolLookup = (state: DetailsState) => state.schoolLookup;
export const getDetailsStateSchoolByNameLookup = (state: DetailsState) => state.schoolByNameLookup;
export const getDetailsStateTeacherLookup = (state: EventHostPanelState) => state.teacherLookup;
export const getDetailsStateTeacherByCriteriaLookup = (state: EventHostPanelState) => state.teacherByCriteriaLookup;
export const getDetailsStateRelationshipManager = (state: DetailsState) => state.relationshipManagerState;
export const getQrCodeFileDownloadState = (state: DetailsState) => state.qrCodeDownloadFileState;
