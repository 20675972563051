import { Component, Input } from '@angular/core';

import { SchoolDetails, RoutineService, CallDay } from '../../models';
import { RelationshipType } from '../../../scp-common/models';

@Component({
	selector: 'opus-school-relationship',
	templateUrl: './school-relationship.component.html',
})
export class SchoolRelationshipComponent {
	@Input() school: SchoolDetails;

	get relationshipType() {
		return RelationshipType[this.school.relationshipType.relationshipType];
	}

	get routineService() {
		return RoutineService[this.school.routineService];
	}

	get callDay() {
		return CallDay[this.school.callDay];
	}
}
