import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable,  Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import * as fromModels from '../models';
import { TeacherDetails } from '../../teachers/models';
import { District } from '../../districts/models';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';


@Component({
	selector: 'opus-school-searchresults-page',
	templateUrl: './school-searchresults.component.html',
})
export class SchoolSearchResultsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() teacher: TeacherDetails;
    @Input() district: District;
    searchResultsState$: Observable<fromStore.SearchResultsState>;
	showModal = false;
    embeddedVersion = false;

    alive = true;
    apiUrl: string;

    private _subscription: Subscription;
    private _searchCriteria: fromModels.SchoolSearchCriteria;
    private _pagingSorting: fromModels.SchoolPagingSorting;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _store: Store<fromStore.SchoolsState>,
        private configuration: ConfigurationService
    ) {
		console.log('DistrictSearchResultsComponent: constructor().');
	}

    ngOnInit() {
        console.log('SchoolSearchResultsComponent: ngOnInit().');

        this.configuration.config$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(c => {
            this.apiUrl = c.config.apiServer;
        });

        // Get results of search (so our html can populate)
        this.searchResultsState$ = this._store.pipe(select(fromStore.getSchoolSearchState));
        // Grab the current search criteria stored in the state
        console.log(this.searchResultsState$);
        this._subscription = this.searchResultsState$.subscribe(s => {
            this._searchCriteria = s.searchCriteria;
            this._pagingSorting = s.pagingSorting;
        });

        // If a teacher is specified, we want to treat the results
        // as an embedded version (in teacher details specifically)
        if (this.teacher != null) {
            this.embeddedVersion = true;
            // Load results based on specified teacher
            this._store.dispatch(new fromStore.GetResultsForTeacher(this.teacher, this._searchCriteria));
        }
        // Same if a district is specified
        else if (this.district != null) {
          this.embeddedVersion = true;
          // Load results based on specified teacher
          this._store.dispatch(new fromStore.GetResultsForDistrict(this.district, this._searchCriteria));
        }
        else {
            // If query params are changed, process them
            this.route.queryParams.subscribe(val => {
                this._store.dispatch(new fromStore.ProcessQueryString(this.router.url));
            });
        }
    }

    ngOnChanges() {

    }

    ngOnDestroy() {
        this.alive = false;
    }

    nextPage() {
        console.log('SchoolSearchResultsComponent: nextPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.NextPage(this._searchCriteria, this._pagingSorting));
    }

    prevPage() {
        console.log('SchoolSearchResultsComponent: prevPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.PrevPage(this._searchCriteria, this._pagingSorting));
    }

    setPageSize(pageSize: number) {
        console.log('SchoolSearchResultsComponent: setPageSize().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SetPageSize(pageSize, this._searchCriteria));
    }

    lastPage() {
        console.log('SchoolSearchResultsComponent: lastPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.LastPage(this._searchCriteria, this._pagingSorting));
    }

    firstPage() {
        console.log('SchoolSearchResultsComponent: firstPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.FirstPage(this._searchCriteria, this._pagingSorting));
    }

    sortBy(column: string) {
        console.log('SchoolSearchResultsComponent: sortBy().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SortBy(column, this._searchCriteria, this._pagingSorting));
    }

    createNewDistrict() {
        console.log('TODO: Need to implement create new school route.');
        //this._store.dispatch(new fromRoot.Go({
        //    path: [`schools/create`]
        //}));
	}

	createSchool() {
		console.log('SchoolSearchResultsComponent.createSchool()...');

		this.showModal = true;
	}

	createSchoolClosed(created: boolean) {
		console.log(`SchoolSearchResultsComponent.createSchoolClosed(): ${created ? 'School created.' : 'School create cancelled.'}`);

		this.showModal = false;
	}
}
