import { Component, Input } from '@angular/core';

import { SchoolDetails, SchoolDelivery, ServiceLevel } from '../../models';

@Component({
	selector: 'opus-school-online-rentals',
	templateUrl: './school-online-rentals.component.html',
})
export class SchoolOnlineRentalsComponent {
	@Input() school: SchoolDetails;

	get schoolDelivery() {
		return SchoolDelivery[this.school.schoolDelivery];
	}

	get serviceLevel() {
		return ServiceLevel[this.school.serviceLevel];
	}
}
