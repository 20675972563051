import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

import { RentalCategory } from "../models";

import * as fromStore from '../store';

import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';


@Component({
	selector: 'rental-categories',
	templateUrl: './rental-categories.component.html',
})

export class RentalCategoriesComponent implements OnInit, OnDestroy {
	private _alive = true;
	rentalCategoryControl2 = new FormControl();
	combo2: any;
	isCombo2Enabled: boolean = false;
	rentalCategoryControl3 = new FormControl();
	combo3: any;
	selectedRentalCategoryCode: string = null;
	vm$: Observable<fromStore.CombosState>;
	vm: fromStore.CombosState;
	filteredRentalCategories: any;
	
	constructor(private store: Store<fromStore.CombosState>, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.vm$ = this.store.pipe(select(fromStore.getCombosState));
		
		this.vm$
			.pipe(takeWhile(() => this._alive))
			.subscribe(vm => {
				this.vm = vm;
		
				if (!vm.loadedRentalCategories && !vm.loadingRentalCategories && !vm.errors) {
					this.store.dispatch(new fromStore.LoadRentalCategories(""));			  
				}
				this.setRentalCategoryTiers();
				this.rentalCategoryControl2.setValue("", {emitEvent: false, emitViewToModelChange: false});
				this.rentalCategoryControl3.setValue("", {emitEvent: false, emitViewToModelChange: false});
				this.rentalCategoryControl3.disable({emitEvent: false});
				this.cdr.detectChanges();
			}
		);
		this.rentalCategoryControl2.valueChanges
			.pipe(takeWhile(() => this._alive))
			.subscribe(val => {
				let codeValue = val;
				if ((val === null || val == "") && this.selectedRentalCategoryCode.length >= 1 ) {
					this.rentalCategoryControl3.disable({emitEvent: false});
				} else {
					this.rentalCategoryControl3.enable({emitEvent: false});
				}
				console.log('rentalCategoryControl2.valueChanges(): ', codeValue);
				if (codeValue !== null && codeValue != this.selectedRentalCategoryCode) {
					this.selectedRentalCategoryCode = codeValue;
					this.setRentalCategoryTiers();
					this.rentalCategoryControl2.enable({emitEvent: false});
					this.rentalCategoryControl3.setValue("", {emitEvent: false, emitViewToModelChange: false});
				}
			}
		);
		this.rentalCategoryControl3.valueChanges
			.pipe(takeWhile(() => this._alive))
			.subscribe(val => {
				let codeValue = val;
				if ((val === null || val == "") && this.selectedRentalCategoryCode.length >= 2 ) {
					codeValue = this.selectedRentalCategoryCode.substring(0, 2);
				}
				console.log('rentalCategoryControl3.valueChanges(): ', codeValue);
				if (codeValue !== null && codeValue != this.selectedRentalCategoryCode) {
					this.selectedRentalCategoryCode = codeValue;
					this.setRentalCategoryTiers();
					this.rentalCategoryControl2.enable({emitEvent: false});
					this.rentalCategoryControl3.enable({emitEvent: false});
				}
			}
		);
	}

	ngOnDestroy(): void {
        this._alive = false;
    }
	
	get isLoading(): boolean {
		return false;
	}

	get rentalCategories(): RentalCategory[] {
		return this.selectedRentalCategoryCode ? 
			this.vm.rentalCategories.filter((x) => x.code.startsWith(this.selectedRentalCategoryCode)) || []
			: [];
	}

	setRentalCategoryTiers() {
		if (!this.vm.rentalCategories) { 
			return 
		};
		
		if (!this.selectedRentalCategoryCode || this.selectedRentalCategoryCode === null) {
			this.selectedRentalCategoryCode = "";
		}

		this.combo2 = this.vm.rentalCategories.filter((x) => x.code.length == 2);;
		this.combo3 = [];

		if (this.selectedRentalCategoryCode.length >= 2)
		{
			this.combo3 = this.vm.rentalCategories.filter((x) => 
				x.code.length == 3
				&& x.code.startsWith(this.selectedRentalCategoryCode.substring(0, 2))
			);
		}

		this.filterRentalCategories();
	}

	filterRentalCategories() {
		this.filteredRentalCategories = this.vm.rentalCategories
			.filter((x) => x.code.length > 2 && x.code.length < 5)
			.sort((a, b) => ((a.rentalCategoryAttribute?.displayName || a.name) > (b.rentalCategoryAttribute?.displayName || b.name) ? 1 : -1));
		
		if(!this.selectedRentalCategoryCode || this.selectedRentalCategoryCode.trim() == "")
		{
			return;
		}

		this.filteredRentalCategories = this.filteredRentalCategories
			.filter((x) => x.code.startsWith(this.selectedRentalCategoryCode));
	}
}