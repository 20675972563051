import { Component, Input } from '@angular/core';

import { Address } from '../../../scp-common/models/address.model';

@Component({
	selector: 'opus-school-address',
	templateUrl: './school-address.component.html',
})
export class SchoolAddressComponent {
    @Input() address: Address;
    @Input() isPhysical: boolean;
}

