import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { PreferenceSet, ProductGroup, ProductSearchResult, SupplyItem, InstrumentRate } from '../../models';
import { OpusCategory } from '../../../scp-common/models';
import { FindCriteria, AddSuppliesStep } from '..';
import { SchoolLookup } from '../../../schools/models/index';
import { DistrictLookup } from '../../../districts/models/district-lookup.model';

export enum PrefsActionTypes {
    LoadPrefs = '[Prefs] Load Preferences',
    LoadPrefsSuccess = '[Prefs] Load Preferences Success',
    LoadPrefsFailure = '[Prefs] Load Preferences Failure',
    UpdatePrefsPanel = '[Prefs] Update Prefs Panel',
    UpdatePrefsPanelSuccess = '[Prefs] Update Prefs Panel Success',
    UpdatePrefsPanelFailure = '[Prefs] Update Prefs Panel Failure',
    InitializeAddSupplies = '[Prefs] Initialize Add Supplies Data',
    AddSuppliesInitialized = '[Prefs] Add Supplies Data Initialized',
    LoadBookCategories = '[Prefs] Load Book Catagories',
    BookCategoriesLoaded = '[Prefs] Book Categories Loaded',
    LoadProductGroups = '[Prefs] Load Product Groups',
    ProductGroupsLoaded = '[Prefs] Product Groups Loaded',
    FindProducts = '[Prefs] Find Products',
    FindProductsSuccess = '[Prefs] Find Products Success',
    FindProductsFailure = '[Prefs] Find Products Failure',
    UpdateSupplyItems = '[Prefs] Update Supply Items',
    RemoveSupplyItems = '[Prefs] Remove Supply Items',
    SelectAddSuppliesStep = '[Prefs] Select Add Supplies Step',
    CopyPrefs = '[Prefs] Copy Preferences',
    CopyPrefsSuccess = '[Prefs] Copy Preferences Success',
    CopyPrefsFailure = '[Prefs] Copy Preferences Failure',
    CreateOverridePrefs = '[Prefs] Create Override Preferences',
    CreateOverridePrefsSuccess = '[Prefs] Create Override Preferences Success',
    CreateOverridePrefsFailure = '[Prefs] Create Override Preferences Failure',
    DeletePrefs = '[Prefs] Delete Preferences',
    DeletePrefsSuccess = '[Prefs] Delete Preferences Success',
    DeletePrefsFailure = '[Prefs] Delete Preferences Failure',
    SchoolLookupById = '[Prefs] School Lookup By Id',
    SchoolLookupByIdFailure = '[Prefs] School Lookup By Id Failure',
    SchoolLookupByIdSuccess = '[Prefs] School Lookup By Id Success',
    SchoolLookupByName = '[Prefs] School Lookup By Name',
    SchoolLookupByNameFailure = '[Prefs] School Lookup By Name Failure',
    SchoolLookupByNameSuccess = '[Prefs] School Lookup By Name Success',
    DistrictLookupById = '[Prefs] District Lookup By Id',
    DistrictLookupByIdFailure = '[Prefs] District Lookup By Id Failure',
    DistrictLookupByIdSuccess = '[Prefs] District Lookup By Id Success',
    DistrictLookupByName = '[Prefs] District Lookup By Name',
    DistrictLookupByNameFailure = '[Prefs] District Lookup By Name Failure',
    DistrictLookupByNameSuccess = '[Prefs] District Lookup By Name Success',
    GetRates = '[Prefs] Get Rates',
    GetRatesSuccess = '[Prefs] Get Rates Success',
    GetRatesFailure = '[Prefs] Get Rates Failure',
}

export class LoadPrefs implements Action {
    readonly type = PrefsActionTypes.LoadPrefs;
    constructor(public payload: number) { }
}

export class LoadPrefsSuccess implements Action {
    readonly type = PrefsActionTypes.LoadPrefsSuccess;
    constructor(public payload: PreferenceSet) { }
}

export class LoadPrefsFailure implements Action {
    readonly type = PrefsActionTypes.LoadPrefsFailure;
    constructor(public payload: any) { }
}

export class CreateOverridePrefs implements Action {
    readonly type = PrefsActionTypes.CreateOverridePrefs;
    constructor(public payload: { preferenceSetId: number, dtoName: string, entityId: number }) { }
}

export class CreateOverridePrefsSuccess implements Action {
    readonly type = PrefsActionTypes.CreateOverridePrefsSuccess;
    constructor(public payload: PreferenceSet) { }
}

export class CreateOverridePrefsFailure implements Action {
    readonly type = PrefsActionTypes.CreateOverridePrefsFailure;
    constructor(public payload: any) { }
}

export class CopyPrefs implements Action {
    readonly type = PrefsActionTypes.CopyPrefs;
    constructor(public payload: { preferenceSetId: number, dtoName: string, entityId: number }) { }
}

export class CopyPrefsSuccess implements Action {
    readonly type = PrefsActionTypes.CopyPrefsSuccess;
    constructor(public payload: PreferenceSet) { }
}

export class CopyPrefsFailure implements Action {
    readonly type = PrefsActionTypes.CopyPrefsFailure;
    constructor(public payload: any) { }
}

export class DeletePrefs implements Action {
    readonly type = PrefsActionTypes.DeletePrefs;
    constructor(public payload: { preferenceSetId: number, dtoName: string, entityId: number }) { }
}

export class DeletePrefsSuccess implements Action {
    readonly type = PrefsActionTypes.DeletePrefsSuccess;
    constructor(public payload: PreferenceSet) { }
}

export class DeletePrefsFailure implements Action {
    readonly type = PrefsActionTypes.DeletePrefsFailure;
    constructor(public payload: any) { }
}

export class UpdatePrefsPanel implements Action {
    readonly type = PrefsActionTypes.UpdatePrefsPanel;
    constructor(public payload: { preferenceSet: PreferenceSet }) { }
}

export class UpdatePrefsPanelSuccess implements Action {
    readonly type = PrefsActionTypes.UpdatePrefsPanelSuccess;
    constructor(public payload: { preferenceSet: PreferenceSet }) { }
}

export class UpdatePrefsPanelFailure implements Action {
    readonly type = PrefsActionTypes.UpdatePrefsPanelFailure;
    constructor(public payload: { errors: HttpErrorResponse }) { }
}

export class InitializeAddSupplies implements Action {
    readonly type = PrefsActionTypes.InitializeAddSupplies;
    constructor() { }
}

export class AddSuppliesInitialized implements Action {
    readonly type = PrefsActionTypes.AddSuppliesInitialized;
    constructor() { }
}

export class LoadBookCategories implements Action {
    readonly type = PrefsActionTypes.LoadBookCategories;
    constructor() { }
}

export class BookCategoriesLoaded implements Action {
    readonly type = PrefsActionTypes.BookCategoriesLoaded;
    constructor(public payload: OpusCategory[]) { }
}

export class LoadProductGroups implements Action {
    readonly type = PrefsActionTypes.LoadProductGroups;
    constructor() { }
}

export class ProductGroupsLoaded implements Action {
    readonly type = PrefsActionTypes.ProductGroupsLoaded;
    constructor(public payload: ProductGroup[]) { }
}

export class FindProducts implements Action {
    readonly type = PrefsActionTypes.FindProducts;
    constructor(public payload: FindCriteria) { }
}

export class FindProductsSuccess implements Action {
    readonly type = PrefsActionTypes.FindProductsSuccess;
    constructor(public payload: { [id: number]: ProductSearchResult }) { }
}

export class FindProductsFailure implements Action {
    readonly type = PrefsActionTypes.FindProductsFailure;
    constructor(public payload: any) { }
}

export class UpdateSupplyItems implements Action {
    readonly type = PrefsActionTypes.UpdateSupplyItems;
    constructor(public payload: SupplyItem[]) { }
}

export class RemoveSupplyItems implements Action {
    readonly type = PrefsActionTypes.RemoveSupplyItems;
    constructor(public payload: number[]) { }
}

export class SelectAddSuppliesStep implements Action {
    readonly type = PrefsActionTypes.SelectAddSuppliesStep;
    constructor(public payload: AddSuppliesStep) { }
}

export class SchoolLookupById implements Action {
    readonly type = PrefsActionTypes.SchoolLookupById;
    constructor(public payload: { schoolId: number }) { }
}

export class SchoolLookupByIdSuccess implements Action {
    readonly type = PrefsActionTypes.SchoolLookupByIdSuccess;
    constructor(public payload: { school: SchoolLookup }) { }
}

export class SchoolLookupByIdFailure implements Action {
    readonly type = PrefsActionTypes.SchoolLookupByIdFailure;
    constructor(public payload: { errors: any }) { }
}

export class SchoolLookupByName implements Action {
    readonly type = PrefsActionTypes.SchoolLookupByName;
    constructor(public payload: { name: string }) { }
}

export class SchoolLookupByNameSuccess implements Action {
    readonly type = PrefsActionTypes.SchoolLookupByNameSuccess;
    constructor(public payload: { schools: SchoolLookup[] }) { }
}

export class SchoolLookupByNameFailure implements Action {
    readonly type = PrefsActionTypes.SchoolLookupByNameFailure;
    constructor(public payload: { errors: any }) { }
}

export class DistrictLookupById implements Action {
    readonly type = PrefsActionTypes.DistrictLookupById;
    constructor(public payload: { districtId: number }) { }
}

export class DistrictLookupByIdSuccess implements Action {
    readonly type = PrefsActionTypes.DistrictLookupByIdSuccess;
    constructor(public payload: { district: DistrictLookup }) { }
}

export class DistrictLookupByIdFailure implements Action {
    readonly type = PrefsActionTypes.DistrictLookupByIdFailure;
    constructor(public payload: { errors: any }) { }
}

export class DistrictLookupByName implements Action {
    readonly type = PrefsActionTypes.DistrictLookupByName;
    constructor(public payload: { name: string }) { }
}

export class DistrictLookupByNameSuccess implements Action {
    readonly type = PrefsActionTypes.DistrictLookupByNameSuccess;
    constructor(public payload: { districts: DistrictLookup[] }) { }
}

export class DistrictLookupByNameFailure implements Action {
    readonly type = PrefsActionTypes.DistrictLookupByNameFailure;
    constructor(public payload: { errors: any }) { }
}

export class GetRates implements Action {
    readonly type = PrefsActionTypes.GetRates;
    constructor(public payload: { id?: number, zip: string }) { }
}

export class GetRatesSuccess implements Action {
    readonly type = PrefsActionTypes.GetRatesSuccess;
    constructor(public payload: InstrumentRate[]) { }
}

export class GetRatesFailure implements Action {
    readonly type = PrefsActionTypes.GetRatesFailure;
    constructor(public payload: any) { }
}

export type PrefsActions =
    | LoadPrefs
    | LoadPrefsSuccess
    | LoadPrefsFailure
    | UpdatePrefsPanel
    | UpdatePrefsPanelSuccess
    | UpdatePrefsPanelFailure
    | InitializeAddSupplies
    | AddSuppliesInitialized
    | LoadBookCategories
    | BookCategoriesLoaded
    | LoadProductGroups
    | ProductGroupsLoaded
    | FindProducts
    | FindProductsSuccess
    | FindProductsFailure
    | UpdateSupplyItems
    | RemoveSupplyItems
    | SelectAddSuppliesStep
    | CopyPrefs
    | CopyPrefsSuccess
    | CopyPrefsFailure
    | CreateOverridePrefs
    | CreateOverridePrefsSuccess
    | CreateOverridePrefsFailure
    | DeletePrefs
    | DeletePrefsSuccess
    | DeletePrefsFailure
    | SchoolLookupById
    | SchoolLookupByIdFailure
    | SchoolLookupByIdSuccess
    | SchoolLookupByName
    | SchoolLookupByNameFailure
    | SchoolLookupByNameSuccess
    | DistrictLookupById
    | DistrictLookupByIdFailure
    | DistrictLookupByIdSuccess
    | DistrictLookupByName
    | DistrictLookupByNameFailure
    | DistrictLookupByNameSuccess
    | GetRates
    | GetRatesSuccess
    | GetRatesFailure;
