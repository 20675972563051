import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import * as fromRoot from '../../../core/store';
import * as fromModels from '../models';
import { SchoolDetails } from '../../schools/models';
import { District } from '../../districts/models';

@Component({
	selector: 'opus-teacher-searchresults-page',
	templateUrl: './teacher-searchresults.component.html',
})
export class TeacherSearchResultsComponent implements OnInit, OnChanges {
    //@Input() teachersState: fromStore.TeachersState;
    @Input() school: SchoolDetails;
    @Input() district: District;
    searchResultsState$: Observable<fromStore.SearchResultsState>;

    showModal = false;

    private _subscription: Subscription;
    private _searchCriteria: fromModels.TeacherSearchCriteria;
    private _pagingSorting: fromModels.TeacherPagingSorting;
    embeddedVersion = false;

    constructor(private route: ActivatedRoute, private router: Router, private _store: Store<fromStore.TeachersState>) {
		console.log('TeacherSearchResultsComponent: constructor().');
	}

	ngOnInit() {
        console.log('TeacherSearchResultsComponent: ngOnInit().');
        // Get results of search (so our html can populate)
        this.searchResultsState$ = this._store.pipe(select(fromStore.getTeacherSearchState));
        // Grab the current search criteria stored in the state
        console.log(this.searchResultsState$);
        this._subscription = this.searchResultsState$.subscribe(s => {
            this._searchCriteria = s.searchCriteria;
            this._pagingSorting = s.pagingSorting;
        });

        // If a teacher is specified, we want to treat the results
        // as an embedded version (in teacher details specifically)
        if (this.school != null) {
          this.embeddedVersion = true;
          // Load results based on specified tacher
          this._store.dispatch(new fromStore.GetResultsForSchool(this.school, this._searchCriteria));
        }
        // Same goes for a district
        else if (this.district != null) {
          this.embeddedVersion = true;
          // Load results based on specified district
          this._store.dispatch(new fromStore.GetResultsForDistrict(this.district, this._searchCriteria));
        }
      if (this.school === null && this.district === null) {
          // If query params are changed, process them
          this.route.queryParams.subscribe(val => {
            this._store.dispatch(new fromStore.ProcessQueryString(this.router.url));
          });
        }
    }

  ngOnChanges() {

    }

    nextPage() {
        console.log('TeacherSearchResultsComponent: nextPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.NextPage(this._searchCriteria, this._pagingSorting));
    }

    prevPage() {
        console.log('TeacherSearchResultsComponent: prevPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.PrevPage(this._searchCriteria, this._pagingSorting));
    }

    setPageSize(pageSize: number) {
        console.log('TeacherSearchResultsComponent: setPageSize().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SetPageSize(pageSize, this._searchCriteria));
    }

    lastPage() {
        console.log('TeacherSearchResultsComponent: lastPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.LastPage(this._searchCriteria, this._pagingSorting));
    }

    firstPage() {
        console.log('TeacherSearchResultsComponent: firstPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.FirstPage(this._searchCriteria, this._pagingSorting));
    }

    sortBy(column: string) {
        console.log('TeacherSearchResultsComponent: sortBy().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SortBy(column, this._searchCriteria, this._pagingSorting));
    }

    selectTeacher(teacher: Number) {
        console.log(`selectTeacher ${teacher}`);
        this._store.dispatch(new fromRoot.Go({
            path: [`teachers/${teacher}`]
        }));
    }

    selectPreferenceSet(teacherId: Number) {
        // TODO: How do I navigate to a preference set?
        console.log(`selectPreferenceSet for ${teacherId}`);
        console.log('TODO: Need to implement preferences route.');
        /*
        this._store.dispatch(new fromRoot.Go({
            path: [`teachers/${teacherId}/preferences`]
        }));
        */
    }

    createTeacher() {
        console.log('createTeacher');
        this.showModal = true;
        //this._store.dispatch(new fromRoot.Go({
        //    path: [`events/create`]
        //}));
    }

    createTeacherClosed(created) {
        if (created) {
            console.log('Teacher created');
        }
        else {
            console.log('Teacher create was canceled');
        }

        // Reset our flag so we can show the modal again later if we want to.
        this.showModal = false;
    }
}
