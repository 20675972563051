import { Component, Input } from '@angular/core';

import { TeacherDetails } from '../../models';
import { SchoolType } from '../../../schools/models';

@Component({
	selector: 'opus-teacher-contact',
	templateUrl: './teacher-contact.component.html',
})
export class TeacherContactComponent {
	@Input() teacher: TeacherDetails;

    get phone() {
        return this.teacher.phone;
    }

    get fax() {
        return this.teacher.fax;
    }

    get email() {
        return this.teacher.email;
    }
}
