<div #opusModal class="reveal" data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
	<header class="reveal__header">
		<h1 class="reveal__title">{{ title }}</h1>
		<button class="reveal__close-btn" aria-label="Close modal" type="button" (click)="onSecondary()"></button>
	</header>
	<section class="reveal__content">
		<ng-content></ng-content>
		<div class="reveal__btns" *ngIf="_displayButtons">
			<button class="reveal__btn-secondary" (click)="onSecondary()">{{ secondaryLabel }}</button>
			<button class="reveal__btn-secondary" (click)="onTertiary()" *ngIf="_displayTertiaryButton">{{ _tertiaryLabel }}</button>
			<button class="reveal__btn-primary" (click)="onPrimary()" [disabled]="!primaryEnabled">{{ primaryLabel }}</button>
		</div>
	</section>
</div>
