import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';

import { LocationManager } from '../models';

@Injectable()
export class LocationService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	getLocationManager(code: number): Observable<LocationManager> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<LocationManager>(`${c.config.apiServer}api/location/${code}/manager`);
			})
		);
    }

	lookupManagerByName(managerName: string): Observable<LocationManager[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<LocationManager[]>(`${c.config.apiServer}api/location/${managerName}/managername`);
			})
		);
    }

    isLocationValid(locationId: number): Observable<boolean> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<boolean>(`${c.config.apiServer}api/location/${locationId}/exists`);
            })
        );
    }
}
