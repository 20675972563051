import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap } from 'rxjs/operators';
import { Operation } from 'fast-json-patch';

import { PreferenceSet } from '../../preferences/models';

@Injectable()
export class PrefsService {
    constructor(private _http: HttpClient, private configuration: ConfigurationService) { }

	loadPrefs(prefsId: number): Observable<PreferenceSet> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this._http.get<PreferenceSet>(`${c.config.apiServer}api/preferencesets/${prefsId}`);
            })
        );
    }

    patchPrefs(id: number, patch: Operation[], modifiedDate: string): Observable<PreferenceSet> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                const options = modifiedDate
                    ? { params: new HttpParams().set('modifiedDate', modifiedDate) }
                    : {};

                return this._http.patch<PreferenceSet>(`${c.config.apiServer}api/preferencesets/${id}`, patch, options);
            })
        );
    }

    copy(id: number, dtoName: string, entityId: number): Observable<PreferenceSet> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this._http.post<PreferenceSet>(`${c.config.apiServer}api/preferencesets/${id}/copy/${dtoName}/${entityId}`, {});
            })
        );
    }

    delete(id: number): Observable<PreferenceSet> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this._http.delete<PreferenceSet>(`${c.config.apiServer}api/preferencesets/${id}`, {});
            })
        );
    }

}
