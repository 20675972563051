import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
	transform(items: any[], val: any, predicate: (o: any, val: any) => boolean): any[] {
        //console.log('FilterPipe: ', items, val);

		if (!items) {
			return [];
		}

		if (!val) {
			return items;
		}

		if (!predicate) {
			return items;
		}

		return items.filter(item => predicate(item, val));
	}
}

