import { Component, Input } from '@angular/core';

import { District } from '../../models';

@Component({
	selector: 'opus-district-contacts',
	templateUrl: './district-contacts.component.html',
})
export class DistrictContactsComponent {
	@Input() district: District;
}

