import { Injectable} from '@angular/core';

import { tap, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import * as securityActions from '../actions/security.actions';
import * as fromServices from '../../services';
import * as fromSelectors from '../selectors';
import * as fromReducer from '../reducers';

@Injectable()
export class SecurityEffects {
    constructor(
        private actions$: Actions,
        private securityService: fromServices.SecurityService,
        private store$: Store<fromReducer.CoreState>,
    ) { }

    
    loadRolePolicies$ = createEffect(() => this.actions$.pipe(
        ofType(securityActions.SecurityActionTypes.LoadRolePolicies),
        withLatestFrom(this.store$.pipe(select(fromSelectors.getSecurityState))),
        map(([action, securityState]: [securityActions.LoadRolePolicies, fromReducer.SecurityState]) => {
            return [action, securityState];
        }),
        switchMap(([, securityState]: [securityActions.LoadRolePolicies, fromReducer.SecurityState]) => {
            console.log('Load Security Effect');

            if (securityState.rolePolicies != null) {
                return of(new securityActions.LoadRolePoliciesSuccess({ rolePolicies: securityState.rolePolicies }));
            }
            return this.securityService.getRolePolicies().pipe(
                tap(d => {
                    console.log('loadRolePolicies returned:', d);
                }),
                map(rolePolicies => {
                    if (rolePolicies === null) {
                        return new securityActions.LoadRolePoliciesFailure('RolePolicies Not Found');
                    }

                    return new securityActions.LoadRolePoliciesSuccess({ rolePolicies: rolePolicies });
                }),
                catchError(error => of(new securityActions.LoadRolePoliciesFailure(error)))
            );
        })
    ));
}
