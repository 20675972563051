import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEventDetails from '../reducers/details.reducer';
import * as fromModels from '../../models';

export const getEventsDetailsState = createSelector(
    fromFeature.getEventsState,
    (state: fromFeature.EventsState) => state.details
);

export const getDetailsStateEvent = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateDistrict
);

export const getDetailsStateLoaded = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateLoaded
);

export const getDetailsRelationshipManagerState = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateRelationshipManager
);

export const getDetailsStateApprovedPromos = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateApprovedPromos
);

export const getDetailsStateLoading = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateLoading
);

export const getDetailsStatePanels = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStatePanels
);

export const getDetailsStateEventHostPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventHost]
);

export const getDetailsStateEventInfoPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventInfo]
);

export const getDetailsStateEventStaffingPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventStaffing]
);

export const getDetailsStateEventSchoolsAttendingPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventSchoolsAttending]
);

export const getDetailsStateSchoolLookup = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateSchoolLookup,
);

export const getDetailsStateEventDateTimePanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventDateTime]
);

export const getDetailsStateEventPromoPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventPromo]
);

export const getDetailsStateEventMeetingDetailsPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventMeetingDetails]
);

export const getDetailsStateSchoolByNameLookup = createSelector(
    getEventsDetailsState,
    fromEventDetails.getDetailsStateSchoolByNameLookup,
);

export const getDetailsStateTeacherLookup = createSelector(
    getDetailsStateEventHostPanel,
    fromEventDetails.getDetailsStateTeacherLookup,
);

export const getDetailsStateTeacherByCriteriaLookup = createSelector(
    getDetailsStateEventHostPanel,
    fromEventDetails.getDetailsStateTeacherByCriteriaLookup,
);

export const getDetailsStateEventAddrPanel = createSelector(
    getDetailsStatePanels,
    (panels) => panels[fromFeature.PanelId.EventLocation] as fromFeature.AddressPanelState,
);

export const getDetailsStateCopyEventState = createSelector(
    getEventsDetailsState,
    (state: fromFeature.DetailsState) => state.copyEventState,
);

export const getQrCodeFileDownloadState = createSelector(
  getEventsDetailsState,
  fromEventDetails.getQrCodeFileDownloadState,
);
