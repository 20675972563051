import { Component, Input, OnInit } from '@angular/core';

import { RateAgreement, NodeLevel, NodeLevelNames } from '../../models';

@Component({
	selector: 'opus-school-rate',
	templateUrl: './school-rate.component.html',
})
export class SchoolRateComponent implements OnInit {
	@Input() rateAgreement: RateAgreement;
	@Input() nodeLevel?: NodeLevel;

	constructor() {	}

	ngOnInit() {
		console.log(this.rateAgreement);
	}

	get rateCode() {
		return this.rateAgreement  ? this.rateAgreement.code : '';
	}

	get rateDescription() {
		return this.rateAgreement ? this.rateAgreement.description : '';
	}

	get rateEffectivity() {
		return this.rateAgreement ? this.getDateRangeText(this.rateAgreement.startDate, this.rateAgreement.endDate) : '';
	}

	get nodeLevelName(): string {
		return NodeLevelNames[this.nodeLevel || ''];
	}

	private getDate(s: string): Date {
		const ms: number = Date.parse(s);

		if (isNaN(ms)) {
			return null;
		}

		return new Date(ms);
	}

	private getDateText(d: Date): string {
		return d === null ? 'Unspecified' : `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
	}

	private getDateRangeText(from: string, to: string): string {
		const fromDate = this.getDate(from);
		const toDate = this.getDate(to);

		if (fromDate === null && toDate === null) {
			return '';
		}

		return `${this.getDateText(fromDate)} - ${this.getDateText(toDate)}`;
	}
}
