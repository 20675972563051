import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, reduce, takeWhile } from 'rxjs/operators';

import { RentalCategory, states } from '../../scp/scp-common/models';
import { ShippingCharge, ShippingChargesCriteria } from '../models';

import * as fromStore from '../../scp/scp-common/store';
import { TaxonomyState } from '../../scp/scp-common/store';

@Component({
    selector: 'opus-shipping-charges-header',
    templateUrl: './shipping-charges-header.component.html',
})
export class ShippingChargesHeader implements OnInit, OnDestroy {
    @Input() shippingCharges$: Observable<ShippingCharge[]>;
    @Output() criteriaChangeEvent = new EventEmitter<ShippingChargesCriteria>();

    private _alive = true;
    
    rentalCategoryCtrl = new FormControl();
    rentalCategories$: Observable<RentalCategory[]>;
    stateCtrl = new FormControl();
    states: string[] = states;

    constructor(private store: Store<TaxonomyState>) {
        this.rentalCategories$ = this.store.pipe(select(fromStore.getRentalCategoryState));
    }

    ngOnInit(): void {
        this.rentalCategoryCtrl.valueChanges.pipe(
            takeWhile(() => this._alive),
        ).subscribe((rentalCategoryCode: string) => {
            const state = this.stateCtrl.value;
            this.criteriaChangeEvent.emit({ rentalCategoryCode: rentalCategoryCode || '', state: state || '' });
        });

        this.stateCtrl.valueChanges.pipe(
            takeWhile(() => this._alive),
        ).subscribe((state: string) => {
            const rentalCategoryCode = this.rentalCategoryCtrl.value;
            this.criteriaChangeEvent.emit({ rentalCategoryCode: rentalCategoryCode || '', state: state || '' });
        })

        this.store.dispatch(new fromStore.GetRentalCategories());
    }

    ngOnDestroy(): void {
        this._alive = false;
    }
}
