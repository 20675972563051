<nav class="u-m-b-16">
	<ul class="breadcrumbs">
		<li><a routerLink="../">Merchandising</a></li>
		<li>Rental Categories</li>
	</ul>
</nav>

<section class="card">
	<div class="card__section spinner-section" [class.spinner-section--is-active]="isLoading">
        <div class="spinner-section__icon"></div>
        <div class="row align-middle">
            <div class="columns small-1">
                <label for="rentalCategory">Family</label>
            </div>
            <div class="columns small-3">
                <select id="rentalCategory2" class="u-m-b-0" [formControl]="rentalCategoryControl2">
                    <option value="">Select</option>
                    <option *ngFor="let g of combo2" value="{{ g.code }}">
						{{ g.rentalCategoryAttribute?.displayName || g.name }}
					</option>
                </select>
            </div>
            <div class="columns small-1">
                <label for="rentalCategory">Class</label>
            </div>
            <div class="columns small-3">
                <select id="rentalCategory3" class="u-m-b-0" [formControl]="rentalCategoryControl3">
                    <option value="">Select</option>
                    <option *ngFor="let g of combo3" value="{{ g.code }}">
						{{ g.rentalCategoryAttribute?.displayName || g.name }}
					</option>
                </select>
            </div>
        </div>
    </div>

	<div class="card__section spinner-section" [class.spinner-section--is-active]="isLoading">
		<div class="spinner-section__icon"></div>
        <p *ngIf="!isLoading && filteredRentalCategories && !filteredRentalCategories.length">
            The selected product group has no products to view.
        </p>
        <div class="table" role="table" *ngIf="filteredRentalCategories && filteredRentalCategories?.length">
            <span role="rowgroup">
                <span class="table__row table__row--header" role="row">
                    <span class="table__cell table__cell--align-left" role="columnheader">Category Name</span>
                    <span class="table__cell" role="columnheader">Category Code</span>
                    <span class="table__cell" role="columnheader">Enabled</span>
                </span>
            </span>
            <span role="rowgroup">
                <a class="table__row ng-scope" role="row" *ngFor="let g of filteredRentalCategories; odd as isOdd; last as isLast"
                    [class.table__row--alt]="isOdd"
                    [class.table__row--last]="isLast"
                    routerLink="/merchandising/rental-categories/{{ g.id }}">
                    <span class="table__cell table__cell--align-left" role="cell">
                        {{ g.rentalCategoryAttribute?.displayName || g.name }}
                    </span>
                    <span class="table__cell" role="cell">{{ g.code }}</span>
                    <span *ngIf="g.rentalCategoryAttribute?.isEnabled" class="table__cell" role="cell">YES</span>
                    <span *ngIf="!g.rentalCategoryAttribute?.isEnabled" class="table__cell" role="cell">NO</span>
                </a>
            </span>
        </div>
	</div>
</section>
