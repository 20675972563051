import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, filter } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import * as fromStore from '../store';

@Injectable()
export class MerchRentalCategoryGuard implements CanActivate {

    constructor(private _store: Store<fromStore.CombosState>) {
    }
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      let redirectTo;
  
      return this.checkStore().pipe(
        switchMap(allow => {
          if (!allow) {
            redirectTo = '/401';
          }
  
          return this.allowOrRedirect(allow, redirectTo);
        }),
        catchError(() => {
          return this.allowOrRedirect(false, '/500');
        }),
      );
    }
  
    checkStore(): Observable<boolean> {
      return this._store.pipe(
        select(fromRoot.getUserAuthInfo),
        filter(x => !!x),
        map(x => true)
        //map(x => x.policyCodes.has('RentalCategories.View'))
      );
    }
  
    allowOrRedirect(allow: boolean, redirectTo: string): Observable<boolean> {
      if (!allow) {
        console.log(`Redirecting to '${redirectTo}'...`);
        this._store.dispatch(new fromRoot.Go({ path: [redirectTo] }));
      }
  
      return of(allow);
    }
}
