import { NgModule, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ValuesPipe } from './pipes/values.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import * as fromContainers from './containers';
import * as fromGuards from './guards';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import * as fromServices from './services';
import * as fromValidators from './validators';
import { coreReducers, coreEffects, CoreState, LoadConfiguration } from './store';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
        ReactiveFormsModule,
        StoreModule.forFeature('core', coreReducers),
        EffectsModule.forFeature(coreEffects),
    ],
    declarations: [...fromContainers.containers, ...fromComponents.components, ValuesPipe, FilterPipe, ...fromDirectives.Directives],
    exports: [...fromContainers.containers, ...fromComponents.components, ValuesPipe, FilterPipe, ...fromDirectives.Directives],
    providers: [...fromValidators.validators,
        ...fromGuards.guards,
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
    return {
        ngModule: CoreModule,
        providers: [fromServices.services, {
                provide: APP_INITIALIZER,
                useFactory: (store: Store<CoreState>) => {
                    return () => {
                        store.dispatch(new LoadConfiguration(null));
                    }
                },
                deps: [Store],
                multi: true
            }]
    };
}
}
