<!-- display mode -->
<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="event.atHostSchool">At Host School</div>
        <div class="property-block__value" role="definition" aria-labelledby="event.atHostSchool">{{ event.eventAtHostSchool | yesNo }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="event.isVirtualEvent">Virtual Event</div>
        <div class="property-block__value" role="definition" aria-labelledby="event.isVirtualEvent">{{ event.isVirtualEvent | yesNo }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="event.virtualEventUrl">Virtual Event URL</div>
        <div class="property-block__value" role="definition" aria-labelledby="event.virtualEventUrl">
            <a *ngIf="event.isVirtualEvent && event.virtualEventUrl; else noVirtualEventUrl" href="{{ event.virtualEventUrl }}">{{ event.virtualEventUrl }}</a>
            <ng-template #noVirtualEventUrl>N/A</ng-template>
        </div>
    </li>
    <li class="property-block__list-item" *ngIf="event.eventAtHostSchool">
        <div class="property-block__label" id="addressLine1">Address Line 1</div>
        <div class="property-block__value" role="definition" aria-labelledby="addressLine1">{{ event.hostingSchoolAddress?.line1}}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.eventAtHostSchool && event.eventAddress?.line2">
        <div class="property-block__label" id="addressLine2">Address Line 2</div>
        <div class="property-block__value" role="definition" aria-labelledby="addressLine2">{{ event.hostingSchoolAddress?.line2}}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.eventAtHostSchool">
        <div class="property-block__label" id="mCityStateZip">City, State Zip</div>
        <div class="property-block__value" role="definition" aria-labelledby="mCityStateZip">{{ event.hostingSchoolAddress?.city}}, {{ event.hostingSchoolAddress?.state}} {{ event.hostingSchoolAddress?.zip}}</div>
    </li>

    <li class="property-block__list-item" *ngIf="event.eventAddress && !event.eventAtHostSchool">
        <div class="property-block__label" id="addressLine1">Address Line 1</div>
        <div class="property-block__value" role="definition" aria-labelledby="addressLine1">{{ event.eventAddress?.line1 }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.eventAddress?.line2 && !event.eventAtHostSchool">
        <div class="property-block__label" id="addressLine2">Address Line 2</div>
        <div class="property-block__value" role="definition" aria-labelledby="addressLine2">{{ event.eventAddress?.line2 }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.eventAddress && !event.eventAtHostSchool">
        <div class="property-block__label" id="cityStateZip">City, State Zip</div>
        <div class="property-block__value" role="definition" aria-labelledby="cityStateZip">{{ event.eventAddress?.city }}, {{ event.eventAddress?.state }} {{ event.eventAddress?.zip }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="locationInfo">Location Info</div>
        <div class="property-block__value u-text--preformatted" role="definition" aria-labelledby="locationInfo">{{ event.location }}</div>
    </li>
</ul>
<!-- /display mode -->