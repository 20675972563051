<nav class="u-m-b-16">
	<ul class="breadcrumbs">
        <li><a routerLink="..">Rental Categories</a></li>
		<li>{{rentalCategoryAttributes?.displayName || rentalCategory?.name}}</li>
	</ul>
</nav>

<div>
	<h1 class="typography--display1">Rental Category Detail</h1>
	<h2 class="typography--headline">
        {{rentalCategoryAttributes?.displayName || rentalCategory?.name}}
    </h2>
	<dl class="definitions--inline">
		<dt>Rental Category Code:</dt>
		<dd>{{ rentalCategory?.code }}</dd>
	</dl>
</div>
<br>
<opus-property-block label="Attributes" 
                    [isEditing]="isEditing" 
                    [canEdit]="true"
                    (toggleEditor)="onToggleEditor($event)"> 
    <!-- <rental-categories-attributes viewer [rentalCategory]="rentalCategoryDetails$ | async"></rental-categories-attributes> -->
	<ul viewer class="property-block__list">
		<li class="property-block__list-item">
			<div class="property-block__label" id="active">Enabled</div>
			<div class="property-block__value" role="definition" aria-labelledby="active">
				{{ rentalCategoryAttributes?.isEnabled | yesNo }}
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="active">Display Name</div>
			<div class="property-block__value" role="definition" aria-labelledby="active">
				{{ rentalCategoryAttributes?.displayName }}
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="active">Description</div>
			<div class="property-block__value" role="definition" aria-labelledby="active">
				{{ rentalCategoryAttributes?.description }}
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="active">Includes</div>
			<div class="property-block__value" role="definition" aria-labelledby="active">
				{{ rentalCategoryAttributes?.includes }}
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="active">Allow Ship To Home</div>
			<div class="property-block__value" role="definition" aria-labelledby="active">
				{{ rentalCategoryAttributes?.allowShipToHome | yesNo }}
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="active">Allow Ship To Home Or Business</div>
			<div class="property-block__value" role="definition" aria-labelledby="active">
				{{ rentalCategoryAttributes?.allowShippingToHomeOrBusiness | yesNo }}
			</div>
		</li>
	</ul>
	<!-- <rental-categories-attributes-edit editor [rentalCategoryInput]="rentalCategoryDetails$"></rental-categories-attributes-edit> -->
	<form editor name="rentalCategoryAttributes" 
		aria-label="Rental Category Attributes"  
		(ngSubmit)="onSubmit()" 
		[formGroup]="detailEditor" 
		*ngIf="detailEditor"
		novalidate 
		class="spinner-section" 
		[class.spinner-section--is-active]="false">
		<div class="spinner-section__icon"></div>
		<ul class="property-block__list">
			<li class="property-block__list-item">
				<label for="editActive" class="property-block__label">Enabled</label>
				<div class="property-block__value">
					<select id="isEnabled"
							formControlName="isEnabled"
							opusAuthorize="InstrumentCombinations.View" 
							opusAuthorizeAction="disable">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</li>
		</ul>
		<ul class="property-block__list">
			<li class="property-block__list-item">
				<label for="editActive" class="property-block__label">Display Name</label>
				<div class="property-block__value">
					<input type="text" 
						id="displayName"  
						formControlName="displayName"
						opusAuthorize="InstrumentCombinations.View" 
						opusAuthorizeAction="disable">
				</div>
			</li>
		</ul>
		<ul class="property-block__list">
			<li class="property-block__list-item">
				<label for="editActive" class="property-block__label">Description</label>
				<div class="property-block__value">
					<textarea type="text" 
						id="description"  
						formControlName="description"
						opusAuthorize="InstrumentCombinations.View" 
						opusAuthorizeAction="disable"
						rows="4">
					</textarea>
				</div>
			</li>
		</ul>
		<ul class="property-block__list">
			<li class="property-block__list-item">
				<label for="editActive" class="property-block__label">Includes</label>
				<div class="property-block__value">
					<textarea type="text" 
						id="includes"  
						formControlName="includes"
						opusAuthorize="InstrumentCombinations.View" 
						opusAuthorizeAction="disable"
						rows="4">
					</textarea>
				</div>
			</li>
		</ul>
		<ul class="property-block__list">
			<li class="property-block__list-item">
				<label for="editActive" class="property-block__label">Allow Ship To Home</label>
				<div class="property-block__value">
					<select id="allowShipToHome"
							formControlName="allowShipToHome"
							opusAuthorize="InstrumentCombinations.View" 
							opusAuthorizeAction="disable">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</li>
		</ul>
		<ul class="property-block__list">
			<li class="property-block__list-item">
				<label for="editActive" class="property-block__label">Allow Ship To Home Or Business</label>
				<div class="property-block__value">
					<select id="allowShippingToHomeOrBusiness"
							formControlName="allowShippingToHomeOrBusiness"
							opusAuthorize="InstrumentCombinations.View" 
							opusAuthorizeAction="disable">
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</li>
		</ul>
		<ul class="error" *ngIf="false">
			<li class="error__message" *ngIf="false">Server error</li>
		</ul>
		<div class="property-block__action-wrapper">
			<div class="property-block__action">
				<button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
			</div>
		</div>
	</form>
</opus-property-block>