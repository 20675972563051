import { detailsComponents } from './district-details';
import { DistrictCreateComponent } from './district-create';

export const components: any[] = [
    ...detailsComponents,
    DistrictCreateComponent
];

export * from './district-details';
export * from './district-create';
