<form name="schoolInformationForm" aria-label="School Information" [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editActive" class="property-block__label">Active</label>
            <div class="property-block__value">
                <select id="editActive" formControlName="isActive" opusAuthorize="School.EditActive" opusAuthorizeAction="disable">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.isActive == 'No' && editor.controls.isActive.valid">
            <label for="inactiveReason" class="property-block__label">Inactive Reason</label>
            <div class="property-block__value">
                <select id="inactiveReason" formControlName="inactiveReason" opusAuthorize="School.EditActive" opusAuthorizeAction="disable">
                    <option value="0">Closed</option>
                    <option value="1">Duplicate</option>
                    <option value="2">Pending</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="schoolId">School ID</div>
            <div class="property-block__value" role="definition" aria-labelledby="schoolId">{{ school.id }}</div>
        </li>
        <li class="property-block__list-item">
            <label for="editShortName" class="property-block__label">Short Name</label>
            <div class="property-block__value">
                <input type="text" id="editShortName" formControlName="shortName" opusAuthorize="School.EditShortName" opusAuthorizeAction="disable">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editLongName" class="property-block__label">Long Name</label>
            <div class="property-block__value">
                <input type="text" id="editLongName" formControlName="longName" required opusAuthorize="School.EditLongName" opusAuthorizeAction="disable">
                <ul class="error" *ngIf="false">
                    <li class="error__message">Required</li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editDistrictId" class="property-block__label">District ID</label>
            <div class="property-block__value">
                <div class="input-group u-m-b-0">
                    <input type="text" id="editDistrictId" class="input-group-field" formControlName="districtId" opusAuthorize="School.EditDistrictId" opusAuthorizeAction="disable">
                    <div class="input-group-button">
                        <button type="button" (click)="onValidateDistrictId()" [disabled]="inLookup" opusAuthorize="School.EditDistrictId" opusAuthorizeAction="disable">Apply</button>
                    </div>
                </div>
                <div class="error" *ngIf="editor.controls.districtId.invalid && (editor.controls.districtId.dirty || editor.controls.districtId.touched)">
                    <div class="error__message" *ngIf="editor.controls.districtId.hasError('number')">ID Must be a positive number.</div>
                    <div class="error__message" *ngIf="editor.controls.districtId.hasError('server')">District not found.</div>
                    <div class="error__message" *ngIf="editor.controls.districtId.hasError('publicNoDistrict')">Public schools must belong to a District.</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editDistrictName" class="property-block__label">District Name</label>
            <div class="property-block__value">
                <span data-toggle="districtNameTypeAhead">
                    <input type="text" class="input-group-field" id="editDistrictName" autocomplete="off" (keyup)="lookupDistrictByName($event)" (keydown)="$event.stopPropagation()" formControlName="districtName" role="combobox" opusAuthorize="School.EditDistrictId" opusAuthorizeAction="disable">
                </span>
                <ul class="dropdown-menu" role="listbox" id="districtNameTypeAhead" #districtDropdown data-close-on-click="true">
                    <li *ngFor="let district of districtByNameLookup?.districts">
                        <button type="button" class="dropdown-menu__action" role="option" data-close (click)="selectDistrict(district)">
                            <span class="l-block">{{ district.longName }} ({{ district.id }})</span>
                            <span class="l-block" *ngIf="district.county">{{ district.county }}</span>
                            <span class="l-block">{{ district.city }}, {{ district.state}}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editType" class="property-block__label">Type</label>
            <div class="property-block__value">
                <select id="editType" formControlName="type" opusAuthorize="School.EditType" opusAuthorizeAction="disable">
                    <option value="1">Public</option>
                    <option value="2">Private</option>
                    <option value="3">College</option>
                    <option value="4">Other</option>
                    <option value="5">Adult</option>
                    <option value="6">Charter</option>
                    <option value="7">National</option>
                    <option value="8">Home</option>
                    <option value="9">Not Listed</option>
                </select>
                <div class="error" *ngIf="editor.controls.type.invalid && (editor.controls.type.dirty || editor.controls.type.touched)">
                    <div class="error__message" *ngIf="editor.controls.type.hasError('type')">Phone is required for this type of school.</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editLevel" class="property-block__label">Level</label>
            <div class="property-block__value">
                <select id="editLevel" formControlName="level" opusAuthorize="School.EditLevel" opusAuthorizeAction="disable">
                    <option value="1">Elementary</option>
                    <option value="2">Middle</option>
                    <option value="3">High</option>
                    <option value="4">College</option>
                    <option value="5">Other</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editLowGrade" class="property-block__label">Low Grade</label>
            <div class="property-block__value">
                <select id="editLowGrade" formControlName="lowGrade" opusAuthorize="School.EditLowGrade" opusAuthorizeAction="disable" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                    <option value="-1">N/A</option>
                    <option value="0">K</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">College</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editHighGrade" class="property-block__label">High Grade</label>
            <div class="property-block__value">
                <select id="editHighGrade" formControlName="highGrade" opusAuthorize="School.EditHighGrade" opusAuthorizeAction="disable" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                    <option value="-1">N/A</option>
                    <option value="0">K</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">College</option>
                </select>
            </div>
        </li>
    </ul>
	<ul class="error" *ngIf="false">
		<li class="error__message" *ngIf="false">Server error</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
