<nav class="u-m-b-16">
    <ul class="breadcrumbs">
        <li><a routerLink="../">Merchandising</a></li>
        <li>Product Groups</li>
    </ul>
</nav>

<section class="card">
    <div class="card__section spinner-section" [class.spinner-section--is-active]="isLoading">
        <div class="spinner-section__icon"></div>
        <div class="row align-middle">
            <div class="columns shrink">
                <label for="productGroup">Product Group</label>
            </div>
            <div class="columns small-5">
                <select id="productGroup" class="u-m-b-0" [formControl]="productGroupControl">
                    <option value="">Select</option>
                    <option *ngFor="let g of vm.productGroups" value="{{ g.id }}">{{ g.name }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="card__section spinner-section" [class.spinner-section--is-active]="isLoading">
        <div class="spinner-section__icon"></div>
        <p *ngIf="!isLoading && !filteredProducts.length && selectedProductGroupId">The selected product group has no products to view.</p>
        <div class="table table--align-left" role="table" *ngIf="!isLoading && filteredProducts.length && selectedProductGroupId">
            <div role="rowgroup">
                <div class="table__row table__row--header" role="rowheader">
                    <span class="table__cell" role="columnheader">
                        <button type="button" (click)="sortBy('itemId')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'itemId' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'itemId' && !sort.reverse
                            }">
                            Item ID
                        </button>
                    </span>
                    <span class="table__cell" role="columnheader">
                        <button type="button" (click)="sortBy('variantId')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'variantId' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'variantId' && !sort.reverse
                            }">
                            Variant ID
                        </button>
                    </span>
                    <span class="table__cell table__cell--one-half" role="columnheader">
                        <button type="button" (click)="sortBy('active')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'active' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'active' && !sort.reverse
                            }">
                            Active
                        </button>
                    </span>
                    <span class="table__cell table__cell--one-half" role="columnheader">
                        <button type="button" (click)="sortBy('brand')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'brand' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'brand' && !sort.reverse
                            }">
                            Brand
                        </button>
                    </span>
                    <span class="table__cell table__cell--double" role="columnheader">
                        <button type="button" (click)="sortBy('itemName')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'itemName' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'itemName' && !sort.reverse
                            }">
                            Item Name
                        </button>
                    </span>
                    <span class="table__cell table__cell--double" role="columnheader" id="activeHeader">
                        <button type="button" (click)="sortBy('variantName')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'variantName' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'variantName' && !sort.reverse
                            }">
                            Variant Name
                        </button>
                    </span>
                    <span class="table__cell table__cell--one-half" role="columnheader">
                        <button type="button" (click)="sortBy('rentalInstruments')" class="table__sorter-btn"
                                [ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'rentalInstruments' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'rentalInstruments' && !sort.reverse
                            }">
                            Rental Instruments
                        </button>
                    </span>
                </div>
                <div role="rowgroup">
                    <div class="table__row table__row--align-middle" role="row" [ngClass]="{'table__row--last': isLast, 'table__row--alt': isOdd, 'table__row--highlight': product.active === false}" *ngFor="let product of filteredProducts; let isOdd = odd; let isLast = last;">
                        <span class="table__cell" role="cell">{{ product.productCode }}</span>
                        <span class="table__cell" role="cell">{{ product.variantId }}</span>
                        <span class="table__cell" role="cell">{{ product.active ? "Yes" : "No" }}</span>
                        <span class="table__cell table__cell--one-half" role="cell">{{ product.brandName }}</span>
                        <span class="table__cell table__cell--double" role="cell">{{ product.productName }}</span>
                        <span class="table__cell table__cell--double" role="cell">{{ product.optionName }}</span>
                        <span class="table__cell table__cell--one-half" role="cell">{{ product.rentalInstrumentsCsv }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
