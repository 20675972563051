<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="musicSupervisor">Music Supervisor</div>
		<div class="property-block__value" role="definition" aria-labelledby="musicSupervisor">{{ district.musicSupervisor }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="officeContact">Office Contact</div>
		<div class="property-block__value" role="definition" aria-labelledby="officeContact">{{ district.officeContact }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="phone">Phone</div>
		<div class="property-block__value" role="definition" aria-labelledby="phone">{{ district.phone }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="fax">Fax</div>
		<div class="property-block__value" role="definition" aria-labelledby="fax">{{ district.fax }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="website">Website</div>
		<div class="property-block__value" role="definition" aria-labelledby="website"><a href="{{ district.website }}" target="_blank">{{ district.website }}</a></div>
	</li>
</ul>
