import { Component, Input } from '@angular/core';

import { District } from '../../models';

@Component({
	selector: 'opus-district-addl-details',
	templateUrl: './district-addl-details.component.html',
})
export class DistrictAddlDetailsComponent {
	@Input() district: District;
	monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	get budgetEnd() {
		const budgetEnd = this.district.budgetEnd == null
			? new Date(9999, 0, 1)
			: new Date(Date.parse(this.district.budgetEnd));

		return `${this.monthNames[budgetEnd.getMonth()]} ${budgetEnd.getDate().toString()}`;
	}
}

