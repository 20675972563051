import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';

import { Action } from '@ngrx/store';

import { ProgramDetails } from '../../models';
import { TeacherLookup } from '../../../schools/models';
import { TeacherLookupCriteria } from '../../../teachers/models';

export enum ProgramDetailsActionTypes {
  LoadProgramDetails = '[Program] Load Program Details',
  LoadProgramDetailsComplete = '[Program] Load Program Details Complete',
  LoadProgramDetailsFailure = '[Program] Load Program Details Failure',

  UpdateEditorState = '[Program] Update Editor State',

  UpdateProgramPanel = '[Program] Update Program Panel',
  UpdateProgramPanelSuccess = '[Program] Update Program Panel Success',
  UpdateProgramPanelFailure = '[Program] Update Program Panel Failure',

  TeacherLookupById = '[Program] Lookup Teacher By Id',
  TeacherLookupByIdSuccess = '[Program] Lookup Teacher By Id Success',
  TeacherLookupByIdFailure = '[Program] Lookup Teacher By Id Failure',
  TeacherLookupByCriteria = '[Program] Lookup Teacher By Criteria',
  TeacherLookupByCriteriaSuccess = '[Program] Lookup Teacher By Criteria Success',
  TeacherLookupByCriteriaFailure = '[Program] Lookup Teacher By Criteria Failure',
}

export class LoadProgramDetails implements Action {
  readonly type = ProgramDetailsActionTypes.LoadProgramDetails;
  constructor(public payload: number) { }
}

export class LoadProgramDetailsComplete implements Action {
  readonly type = ProgramDetailsActionTypes.LoadProgramDetailsComplete;
  constructor(public payload: ProgramDetails) { }
}

export class LoadProgramDetailsFailure implements Action {
  readonly type = ProgramDetailsActionTypes.LoadProgramDetailsFailure;
  constructor(public payload: any) { }
}

export class UpdateEditorState implements Action {
  readonly type = ProgramDetailsActionTypes.UpdateEditorState;
  constructor(public payload: { key: string, open: boolean }) { }
}

export class UpdateProgramPanel implements Action {
  readonly type = ProgramDetailsActionTypes.UpdateProgramPanel;
  constructor(public payload: { panel: string, program: ProgramDetails }) { }
}

export class UpdateProgramPanelSuccess implements Action {
  readonly type = ProgramDetailsActionTypes.UpdateProgramPanelSuccess;
  constructor(public payload: { panel: string, program: ProgramDetails }) { }
}

export class UpdateProgramPanelFailure implements Action {
  readonly type = ProgramDetailsActionTypes.UpdateProgramPanelFailure;
  constructor(public payload: { panel: string, errors: any }) { }
}

export class TeacherLookupById implements Action {
  readonly type = ProgramDetailsActionTypes.TeacherLookupById;
  constructor(public payload: { panel: string, teacherId: number }) { }
}

export class TeacherLookupByIdSuccess implements Action {
  readonly type = ProgramDetailsActionTypes.TeacherLookupByIdSuccess;
  constructor(public payload: { panel: string, teacher: TeacherLookup }) { }
}

export class TeacherLookupByIdFailure implements Action {
  readonly type = ProgramDetailsActionTypes.TeacherLookupByIdFailure;
  constructor(public payload: { panel: string, errors: any }) { }
}

export class TeacherLookupByCriteria implements Action {
  readonly type = ProgramDetailsActionTypes.TeacherLookupByCriteria;
  constructor(public payload: { panel: string, criteria: TeacherLookupCriteria }) { }
}

export class TeacherLookupByCriteriaSuccess implements Action {
  readonly type = ProgramDetailsActionTypes.TeacherLookupByCriteriaSuccess;
  constructor(public payload: { panel: string, teachers: TeacherLookup[] }) { }
}

export class TeacherLookupByCriteriaFailure implements Action {
  readonly type = ProgramDetailsActionTypes.TeacherLookupByCriteriaFailure;
  constructor(public payload: { panel: string, errors: any }) { }
}

export type ProgramDetailsActions =
  | LoadProgramDetails
  | LoadProgramDetailsComplete
  | LoadProgramDetailsFailure
  | UpdateEditorState
  | UpdateProgramPanel
  | UpdateProgramPanelSuccess
  | UpdateProgramPanelFailure
  | TeacherLookupById
  | TeacherLookupByIdSuccess
  | TeacherLookupByIdFailure
  | TeacherLookupByCriteria
  | TeacherLookupByCriteriaSuccess
  | TeacherLookupByCriteriaFailure;
