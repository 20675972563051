import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDistribution from './distribution.reducer';

export interface DistributionState {
	details: fromDistribution.DistributionDetailsState;
}

export const reducers: ActionReducerMap<DistributionState> = {
	details: fromDistribution.reducer,
};

export const getDistributionState = createFeatureSelector<DistributionState>('distribution');

export { DistributionDetailsState } from './distribution.reducer';
