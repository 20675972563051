<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="active">Active</div>
        <div class="property-block__value" role="definition" aria-labelledby="active">{{ school.isActive | yesNo }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="!school.isActive">
        <div class="property-block__label" id="inactiveReason">Inactive Reason</div>
        <div class="property-block__value" role="definition">{{ inactiveReason }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="schoolId">School ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="schoolId">{{ school.id }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">Short Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ school.shortName }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="longName">Long Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="longName">{{ school.longName }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="districtId">District ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="districtId"><a *ngIf="school.districtId" [routerLink]="['/districts', school.districtId]">{{ school.districtId }}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="districtName">District Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="districtName"><a *ngIf="school.districtId" [routerLink]="['/districts', school.districtId]">{{ school.districtName }}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="type">Type</div>
        <div class="property-block__value" role="definition" aria-labelledby="type">{{ schoolType }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="level">Level</div>
        <div class="property-block__value" role="definition" aria-labelledby="level">{{ level }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="gradeRange">Grade Range</div>
        <div class="property-block__value" role="definition" aria-labelledby="gradeRange">{{ gradeRange }}</div>
    </li>
</ul>
