import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { SchoolDetails, SchoolType } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-school-contacts-editor',
	templateUrl: './school-contacts-editor.component.html',
})
export class SchoolContactsEditorComponent implements OnInit, OnDestroy {
	alive = true;
	schoolDetails$: Observable<SchoolDetails>;
	panelState$: Observable<fromStore.PanelState>;
	school: SchoolDetails;
	panelState: fromStore.PanelState;
	editor: FormGroup;

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder) {
		console.log('SchoolContactsEditorComponent()...');
	}

	ngOnInit() {
		console.log('SchoolContactsEditorComponent.ngOnInit()...');

		this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
		this.schoolDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => this.school = s);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateContactsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('SchoolContactsEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('SchoolContactsEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

  createForm() {
    const isPhoneRequired = this.school.type === SchoolType.PUBLIC || this.school.type === SchoolType.PRIVATE || this.school.type === SchoolType.COLLEGE || this.school.type === SchoolType.CHARTER;
		this.editor = this.fb.group({
      phone: [this.school.phone, [isPhoneRequired ? Validators.required : Validators.nullValidator, Validators.pattern('^([0-9]{10})?$')]],	//	TODO: need a better validator for this...
			fax: [this.school.fax, Validators.pattern('^([0-9]{10})?$')],		//	TODO: need a better validator for this...
			website: [this.school.website],									//	TODO: url validation...
		});
	}

	resetForm() {
		this.editor.reset({
			phone: this.school.phone,
			fax: this.school.fax,
			website: this.school.website,
		});
	}

	get canSave(): boolean {
		return this.editor.valid && this.editor.dirty;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	onSubmit() {
		const school = { ...this.school };
		Object.keys(this.editor.value).forEach(key => {
			school[key] = this.editor.value[key];
		});

		this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: fromStore.PanelId.Contacts, school }));
	}
}
