<div>
    <h1 class="typography--display1">Teacher Detail</h1>
    <h2 class="typography--headline">{{ name }}</h2>
    <dl class="definitions--inline u-m-b-40">
        <dt>Teacher ID</dt>
        <dd>{{ id }}</dd>
    </dl>
    <ul class="menu menu--anchors" role="menu">
        <li role="menuitem"><a routerLink="." fragment="schools">Schools</a></li>
        <li role="menuitem"><a routerLink="." fragment="programs">Programs</a></li>
    </ul>
</div>