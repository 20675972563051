import { RelationshipType, ProgramType } from '../../scp-common/models';
import { SchoolDetails } from '../../schools/models';

export enum InactiveReason {
    Inactive,
    Duplicate,
    Pending
}

export interface ProgramTeacher {
    id: number;
    teacherId: number;
    teacherName: string;
    isPrimary: boolean;
}

export interface ProgramCreate {
	programTypeId: ProgramType;
	schoolId: number;
	teacherId?: number;
}

export interface ProgramDetails {
    id: number;
    active: boolean;
    inactiveReason: InactiveReason;
    programTypeId: ProgramType;
    startFrom: string;
    startTo: string;
    school: SchoolDetails;
    beginningGrade: string;
    numberBeginningGrade: number;
    numberBeginningMusicians: number;
    numberSchoolInstruments: number;
    programTeachers: ProgramTeacher[];
}
