import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Configuration } from '../models/Configuration';
import { filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { CoreState, getConfigurationState, ConfigurationState } from '../store/reducers';

@Injectable()
export class ConfigurationHttpService {
    // Inject the http service and the app's BASE_URL
    constructor(private http: HttpClient) { }

    loadConfiguration(): Observable<Configuration> {
        return this.http.get<Configuration>(window.location.origin + `/api/clientconfiguration`);
    }
}

@Injectable()
export class ConfigurationService {
    config$: Observable<ConfigurationState>;

    constructor(private _store: Store<CoreState>) {
        this.config$ = this._store.pipe(
            select(getConfigurationState),
            filter((c) => c.config != null)
        );
    }
}
