import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { takeWhile, tap, map, filter, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromScpStore from '../../scp-common/store';

import { SchoolDetails, SchoolDelivery, ServiceLevel } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-school-online-rentals-editor',
	templateUrl: './school-online-rentals-editor.component.html',
})
export class SchoolOnlineRentalsEditorComponent {
	alive = true;
	schoolDetails$: Observable<SchoolDetails>;
	school: SchoolDetails;
	panelState$: Observable<fromStore.PanelState>;
	panelState: fromStore.PanelState;
	locationState$: Observable<fromScpStore.LocationsState>;
	locationState: fromScpStore.LocationsState;

	panelId = fromStore.PanelId.OnlineRentals;
	editor: FormGroup;
	inLookup = false;
	locationCode: number;
	locationId: number;
	locationName: string;
  showDeliveryError = false;

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
		console.log('SchoolOnlineRentalsEditorComponent()...');
	}

	ngOnInit() {
		console.log('SchoolOnlineRentalsEditorComponent.ngOnInit()...');

		this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
		this.schoolDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => {
			this.school = s;
			this.locationName = s.exclusiveServiceAccountName;
			this.locationId = s.exclusiveServiceAccountId;
			this.locationCode = s.exclusiveServiceAccountCode;
		});

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateOnlineRentalsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('SchoolOnlineRentalsEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();

		this.locationState$ = this.store.pipe(select(fromScpStore.getLocationsState));
	}

	ngOnDestroy() {
		console.log('SchoolOnlineRentalsEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		this.editor = this.fb.group({
			onlineRental: [this.onlineRental],
			shipToHome: [this.shipToHome],
			schoolDelivery: [this.school.schoolDelivery],
			serviceLevel: [this.school.serviceLevel],
			exclusiveServiceAccountCode: [this.school.exclusiveServiceAccountCode, { asyncValidators: [this.locationValidator()], updateOn: 'blur' }],
			offersBandshare: [this.offersBandshare],
		});
	}

	resetForm() {
		this.editor.reset({
			onlineRental: this.onlineRental,
			shipToHome: this.shipToHome,
			schoolDelivery: this.school.schoolDelivery,
			serviceLevel: this.school.serviceLevel,
			exclusiveServiceAccountCode: this.school.exclusiveServiceAccountCode,
			offersBandshare: [this.offersBandshare],
		});
	}

	get canSave(): boolean {
		console.log(`canSave(): valid = ${this.editor.valid}, dirty = ${this.editor.dirty}, validationStatus = `, this.panelState.validationStatus);

		return this.editor.valid && this.editor.dirty && !this.inLookup;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	get onlineRental() {
		return this.school.onlineRental ? 'Yes' : 'No';
	}

	get shipToHome() {
		return this.school.shipToHome ? 'Yes' : 'No';
	}

	get schoolDelivery() {
		return SchoolDelivery[this.school.schoolDelivery];
	}

	get serviceLevel() {
		return ServiceLevel[this.school.serviceLevel];
	}

	get offersBandshare() {
		return this.school.offersBandshare ? 'Yes' : 'No';
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status, this.editor.value);

    const school: SchoolDetails = {
      ...this.school,
      onlineRental: this.editor.value.onlineRental === 'Yes',
      shipToHome: this.editor.value.shipToHome === 'Yes',
      schoolDelivery: +this.editor.value.schoolDelivery,
      serviceLevel: +this.editor.value.serviceLevel,
      exclusiveServiceAccountId: this.locationId,
      offersBandshare: this.editor.value.offersBandshare === 'Yes',
    };

    this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: this.panelId, school }));
	}

  onSchoolDeliveryChange() {
    if (this.school.relationshipManager.locationCode == 9999 && this.school.schoolDelivery == 0 && this.editor.value.schoolDelivery > 0) {
      this.editor.controls['schoolDelivery'].setValue(0);
      this.showDeliveryError = true;
    }
  }

	locationValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors> | null => {
			console.log('locationValidator(): ', control);

			if (!control.dirty || +control.value == this.locationCode) {
				return of(null);
			}

			this.locationCode = +control.value;

			if (!this.locationCode) {
				this.locationId = null;
				this.locationName = null;

				return of(null);
			}

			if (this.locationCode == this.school.exclusiveServiceAccountCode) {
				this.locationId = this.school.exclusiveServiceAccountId;
				this.locationName = this.school.exclusiveServiceAccountName;

				return of(null);
			}


			this.store.dispatch(new fromScpStore.GetLocation({ code: this.locationCode }));

			return this.locationState$.pipe(
				tap(ls => {
					if (ls.locationManagers[this.locationCode].pending) {
						this.inLookup = true;
						this.locationId = null;
						this.locationName = null;
					}
				}),
				filter(ls => {
					console.log('filter: ', this.locationCode, ls);

					return !ls.locationManagers[this.locationCode].pending;
				}),
				map(ls => {
					const loc = ls.locationManagers[this.locationCode];
					const error = loc.error;

					console.log('map: ', this.locationCode, error);

					this.inLookup = false;

					if (loc.manager) {
						this.locationId = loc.manager.id;
						this.locationName = loc.manager.name;
					}

					this.cdr.markForCheck();

					return error ? { server: error } : null;
				}),
				take(1),
			);
		};
	}

	onValidateExclusiveServiceAccountCode() {
		console.log('onValidateExclusiveServiceAccountCode(): ', this.editor.value.exclusiveServiceAccountCode);

		//	do nothing - the blur validation on the code field will resolve.
	}
}
