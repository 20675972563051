import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Event } from '../models';
import * as fromStore from '../store';
import * as moment from 'moment';
import { ValidateCurrentDateOrLater } from '../event.validators';

const DATE_REGEX = new RegExp(/^(\d{2}|\d)\/(\d{2}|\d)\/\d{4}$/);
const TIME_REGEX = new RegExp(/^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$/);

@Component({
    selector: 'event-date-time-editor',
    templateUrl: './event-date-time-editor.component.html'
})
export class EventDateTimeEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue' };
    maxDate = new Date('9999-12-31');
    alive = true;
    event$: Observable<Event>;
    event: Event;
    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;
    editor: FormGroup;

    constructor(private formBuilder: FormBuilder, private store: Store<fromStore.EventsState>, private fb: FormBuilder) {
        console.log('EventInfoEditorComponent()...');
    }

    ngOnInit() {
        console.log('EventInfoEditorComponent.ngOnInit()...');

        this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
        this.event$.pipe(takeWhile(() => this.alive)).subscribe(d => (this.event = d));

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventDateTimePanel));
        this.panelState$
            .pipe(
                takeWhile(() => this.alive),
                tap(s => {
                    if (!s.editing && this.panelState && this.panelState.editing) {
                        this.resetForm();
                    }
                })
            )
            .subscribe(s => (this.panelState = s));

        this.createForm();
    }

    ngAfterViewInit() { }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty && !this.panelState.updating;
    }

    ngOnDestroy() {
        this.alive = false;
    }

    createForm() {
        const startDate = new Date(this.event.start);

        const eventStartDate = new FormControl(startDate, [Validators.required, ValidateCurrentDateOrLater]);
        this.editor = this.formBuilder.group(
            {
                eventStartDate: eventStartDate,
                eventArrivalTime: new FormControl(this.event.arrival),
                eventStartTime: new FormControl(this.event.start, [Validators.required]),
                eventEndTime: new FormControl(this.event.end, [Validators.required])
            },
            { validator: TimeAfterValidator('eventStartTime', 'eventEndTime') }
        );
    }

    resetForm() { }

    onSubmit() {
        const startDate = this.editor.value.eventStartDate;
        const arrivalTime = this.editor.value.eventArrivalTime;
        const startTime = this.editor.value.eventStartTime;
        const endTime = this.editor.value.eventEndTime;

        const startDateMoment = moment(startDate);
        const startDateString = startDateMoment.format('YYYY-MM-DD');

        const startDateTime = moment(startDateString + 'T' + startTime + ':00');
        const endDateTime = moment(startDateString + 'T' + endTime + ':00');

        // NOTE(collin): we must add the timezone offset to the local datetime
        // so that when it coverts to ISO-8601 using UTC +00:00 it is actually local time
        const timezoneOffset = startDateTime.utcOffset();
        startDateTime.add(timezoneOffset, 'minutes');
        endDateTime.add(timezoneOffset, 'minutes');

        let event: Event = {
            ...this.event,
            start: startDateTime.toDate(),
            end: endDateTime.toDate()
        };

        if (arrivalTime) {
            const arrivalDateTime = moment(startDateString + 'T' + arrivalTime + ':00');
            arrivalDateTime.add(timezoneOffset, 'minutes');

            event = {
                ...event,
                arrival: arrivalDateTime.toDate()
            };
        } else {
            event = {
                ...event,
                arrival: null
            };
        }

        this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventDateTime, event }));
    }
}

export function TimeAfterValidator(startKey: string, endKey: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
        const startControl = group.controls[startKey];
        const endControl = group.controls[endKey];

        const startDate = new Date(new Date().toDateString() + ' ' + startControl.value);
        const endDate = new Date(new Date().toDateString() + ' ' + endControl.value);

        const now = moment(startDate);

        if (now.isSameOrAfter(endDate)) {
            return { validTimeAfter: 'Some error' };
        }

        return {};
    };
}

export function DateAfterValidator(afterDateKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control && control.parent) {
            const afterDateControl = control.parent.controls[afterDateKey];

            const sourceDate = new Date(afterDateControl.value);
            const controlDate = new Date(control.value);

            const sourceMoment = moment(sourceDate);
            const controlMoment = moment(controlDate);

            if (!controlMoment.isAfter(sourceMoment)) {
                return { validDateAfter: true };
            }
        }
        return null;
    };
}

export function MaxDateValidator(sourceKey: string, days: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (control && control.parent) {
            const sourceControl = control.parent.controls[sourceKey];

            const sourceDate = new Date(sourceControl.value + ' 00:00:00');
            const controlDate = new Date(control.value + ' 00:00:00');

            const sourceMoment = moment(sourceDate).add(days, 'days');
            const controlMoment = moment(controlDate);

            if (controlMoment.isAfter(sourceMoment)) {
                return { validMaxDate: true };
            }
        }
        return null;
    };
}
