import { ValidationErrors } from '@angular/forms';
import { Action } from '@ngrx/store';

import { Address, AddressVerification } from '../../../scp-common/models';
import { District, DistrictInfo, DistrictCreate, DistrictLookup } from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

export enum DistrictActionTypes {
	LoadDistrict = '[District] Load District',
	LoadDistrictSuccess = '[District] Load District Success',
	LoadDistrictFailure = '[District] Load District Details Failure',

	UpdateEditorState = '[District] Update Editor State',

	LookupDistrictById = '[District] Lookup District',
	LookupDistrictByIdSuccess = '[District] Lookup District By Id Success',
	LookupDistrictByIdFailure = '[District] Lookup District By Id Failure',

	LookupDistrictByName = '[District] Lookup District By Name',
	LookupDistrictByNameSuccess = '[District] Lookup District By Name Success',
	LookupDistrictByNameFailure = '[District] Lookup District By Name Failure',

	UpdateDistrictPanel = '[District] Update District Panel',
	UpdateDistrictPanelSuccess = '[District] Update District Panel Success',
	UpdateDistrictPanelFailure = '[District] Update District Panel Failure',

	ValidateDistrictIsSupervisory = '[District] Validate District Is Supervisory',
	ValidateDistrictPrimaryId = '[District] Validate District Primary Id',
	ValidateDistrictExists = '[District] Validate District Exists',
	ValidateDistrictCanDeactivate = '[District] Validate District Can Deactivate',
	DistrictIsSupervisoryValidated = '[District] District Is Supervisory Validated',
	DistrictCanDeactivateValidated = '[District] District Can Deactivate Validated',
	DistrictExistsValidated = '[District] District Exists Validated',
	DistrictPrimaryIdValidated = '[District] District Primary Id Validated',
	DistrictPrimaryIdValidationFailed = '[District] District Primary Id Validation Failed',

	DistrictAddressLookupCityState = '[District] District Address Lookup CityState',
	DistrictAddressLookupCityStateSuccess = '[District] District Address Lookup CityState Success',
	DistrictAddressLookupCityStateFailure = '[District] District Address Lookup CityState Failure',

	DistrictVerifyAddress = '[District] District Verify Address',
    DistrictVerifyAddressSuccess = '[District] District Verify Address Success',
    DistrictVerifyAddressFailure = '[District] District Verify Address Failure',

	DistrictLoadCounties = '[District] District Load Counties',
    DistrictLoadCountiesSuccess = '[District] District Load Counties Success',

    CreateDistrict = '[District] Create District',
    CreateDistrictSuccess = '[District] Create District Success',
    CreateDistrictFailure = '[District] Create District Failed',
}

export class LoadDistrict implements Action {
	readonly type = DistrictActionTypes.LoadDistrict;
	constructor(public payload: number) { }
}

export class LoadDistrictSuccess implements Action {
	readonly type = DistrictActionTypes.LoadDistrictSuccess;
	constructor(public payload: District) { }
}

export class LoadDistrictFailure implements Action {
	readonly type = DistrictActionTypes.LoadDistrictFailure;
	constructor(public payload: any) { }
}

export class UpdateEditorState implements Action {
	readonly type = DistrictActionTypes.UpdateEditorState;
	constructor(public payload: { key: string, open: boolean }) { }
}

export class LookupDistrictById implements Action {
	readonly type = DistrictActionTypes.LookupDistrictById;
	constructor(public payload: { id: number }) { }
}

export class LookupDistrictByIdSuccess implements Action {
	readonly type = DistrictActionTypes.LookupDistrictByIdSuccess;
	constructor(public payload: { id: number, dl: DistrictLookup }) { }
}

export class LookupDistrictByIdFailure implements Action {
	readonly type = DistrictActionTypes.LookupDistrictByIdFailure;
	constructor(public payload: { id: number, error: any }) { }
}

export class LookupDistrictByName implements Action {
	readonly type = DistrictActionTypes.LookupDistrictByName;
	constructor(public payload: { name: string, isActive: boolean, isSupervisory: boolean }) { }
}

export class LookupDistrictByNameSuccess implements Action {
	readonly type = DistrictActionTypes.LookupDistrictByNameSuccess;
	constructor(public payload: { name: string, dls: DistrictLookup[] }) { }
}

export class LookupDistrictByNameFailure implements Action {
	readonly type = DistrictActionTypes.LookupDistrictByNameFailure;
	constructor(public payload: { name: string, error: any }) { }
}

export class UpdateDistrictPanel implements Action {
	readonly type = DistrictActionTypes.UpdateDistrictPanel;
	constructor(public payload: { panel: string, district: District }) { }
}

export class UpdateDistrictPanelSuccess implements Action {
	readonly type = DistrictActionTypes.UpdateDistrictPanelSuccess;
	constructor(public payload: { panel: string, district: District }) { }
}

export class UpdateDistrictPanelFailure implements Action {
	readonly type = DistrictActionTypes.UpdateDistrictPanelFailure;
	constructor(public payload: { panel: string, errors: HttpErrorResponse }) { }
}

export class ValidateDistrictIsSupervisory implements Action {
	readonly type = DistrictActionTypes.ValidateDistrictIsSupervisory;
	constructor(public payload: { panel: string, validator: string, id: number, isSupervisory: boolean }) { }
}

export class ValidateDistrictPrimaryId implements Action {
	readonly type = DistrictActionTypes.ValidateDistrictPrimaryId;
	constructor(public payload: { panel: string, validator: string, primaryId: string, duplicateId: number, duplicateIsActive: boolean, duplicateIsSupervisory: boolean }) { }
}

export class ValidateDistrictExists implements Action {
	readonly type = DistrictActionTypes.ValidateDistrictExists;
	constructor(public payload: { panel: string, validator: string, id: number }) { }
}

export class ValidateDistrictCanDeactivate implements Action {
	readonly type = DistrictActionTypes.ValidateDistrictCanDeactivate;
	constructor(public payload: { panel: string, validator: string, id: number, isSupervisory: boolean }) { }
}

export class DistrictPrimaryIdValidated implements Action {
	readonly type = DistrictActionTypes.DistrictPrimaryIdValidated;
	constructor(public payload: { panel: string, validator: string, errors: ValidationErrors }) { }
}

export class DistrictIsSupervisoryValidated implements Action {
	readonly type = DistrictActionTypes.DistrictIsSupervisoryValidated;
	constructor(public payload: { panel: string, id: number, validator: string, errors: ValidationErrors }) { }
}

export class DistrictExistsValidated implements Action {
	readonly type = DistrictActionTypes.DistrictExistsValidated;
	constructor(public payload: { panel: string, id: number, validator: string, errors: ValidationErrors }) { }
}

export class DistrictCanDeactivateValidated implements Action {
	readonly type = DistrictActionTypes.DistrictCanDeactivateValidated;
	constructor(public payload: { panel: string, id: number, validator: string, errors: ValidationErrors }) { }
}

export class DistrictPrimaryIdValidationFailed implements Action {
	readonly type = DistrictActionTypes.DistrictPrimaryIdValidationFailed;
	constructor(public payload: { panel: string, validator: string, errors: any[] }) { }
}

export class DistrictAddressLookupCityState implements Action {
	readonly type = DistrictActionTypes.DistrictAddressLookupCityState;
	constructor(public payload: { panel: string, zip: string }) { }
}

export class DistrictAddressLookupCityStateSuccess implements Action {
	readonly type = DistrictActionTypes.DistrictAddressLookupCityStateSuccess;
	constructor(public payload: { panel: string, cityState: { city: string, state: string } }) { }
}

export class DistrictAddressLookupCityStateFailure implements Action {
	readonly type = DistrictActionTypes.DistrictAddressLookupCityStateFailure;
	constructor(public payload: { panel: string, errors: any[] }) { }
}

export class DistrictVerifyAddress implements Action {
	readonly type = DistrictActionTypes.DistrictVerifyAddress;
	constructor(public payload: { panel: string, address: Address }) { }
}

export class DistrictVerifyAddressSuccess implements Action {
	readonly type = DistrictActionTypes.DistrictVerifyAddressSuccess;
	constructor(public payload: { panel: string, verification: AddressVerification }) { }
}

export class DistrictVerifyAddressFailure implements Action {
    readonly type = DistrictActionTypes.DistrictVerifyAddressFailure;
    constructor(public payload: { panel: string }) { }
}

export class DistrictLoadCounties implements Action {
	readonly type = DistrictActionTypes.DistrictLoadCounties;
	constructor(public payload: { panel: string, stateCode: string }) { }
}

export class DistrictLoadCountiesSuccess implements Action {
	readonly type = DistrictActionTypes.DistrictLoadCountiesSuccess;
	constructor(public payload: { panel: string, counties: string[] }) { }
}

export class CreateDistrict implements Action {
    readonly type = DistrictActionTypes.CreateDistrict;
    constructor(public payload: { district: DistrictCreate }) { }
}

export class CreateDistrictSuccess implements Action {
    readonly type = DistrictActionTypes.CreateDistrictSuccess;
    constructor(public payload: { id: number }) { }
}

export class CreateDistrictFailure implements Action {
    readonly type = DistrictActionTypes.CreateDistrictFailure;
    constructor(public payload: { errors: any }) { }
}

export type DistrictActions =
	| LoadDistrict
	| LoadDistrictSuccess
	| LoadDistrictFailure
	| UpdateEditorState
	| LookupDistrictById
	| LookupDistrictByIdSuccess
	| LookupDistrictByIdFailure
	| LookupDistrictByName
	| LookupDistrictByNameSuccess
	| LookupDistrictByNameFailure
	| UpdateDistrictPanel
	| UpdateDistrictPanelSuccess
	| UpdateDistrictPanelFailure
	| ValidateDistrictIsSupervisory
	| DistrictIsSupervisoryValidated
	| ValidateDistrictPrimaryId
	| DistrictPrimaryIdValidated
	| ValidateDistrictExists
	| DistrictExistsValidated
	| ValidateDistrictCanDeactivate
	| DistrictCanDeactivateValidated
	| DistrictAddressLookupCityState
	| DistrictAddressLookupCityStateSuccess
	| DistrictAddressLookupCityStateFailure
	| DistrictVerifyAddress
    | DistrictVerifyAddressSuccess
    | DistrictVerifyAddressFailure
	| DistrictLoadCounties
	| DistrictLoadCountiesSuccess
    | CreateDistrict
    | CreateDistrictSuccess
    | CreateDistrictFailure;
