import { RelationshipType } from '../../scp-common/models/relationship-type';
import { Address, PreferencesEntity } from '../../scp-common/models';
import { RelationshipManager } from './relationship-manager.model';

export interface DistrictCreate {
    longName: string;
    relationshipManagerCode: number;
    physicalAddress: Address;
    mailingAddress: Address;
}

export enum InactiveReason {
	Closed,
	Duplicate,
	Pending,
}

export interface District extends PreferencesEntity {
	//	District Info
	id: number;
	isActive: boolean;
	inactiveReason: InactiveReason;
	longName: string;
	shortName: string;
	isSupervisory: boolean;
	supervisoryDistrictId?: number;
	supervisoryDistrictName?: string;

	//	Physical Address
	physicalAddressId: number;
	physicalAddress: Address;

	//	 Mailing Address
	mailingAddressId: number;
	mailingAddress: Address;

	//	Contacts
	musicSupervisor: string;
	officeContact: string;
	phone: string;
	fax: string;
	website: string;

	//	School Calendar
	schoolStartFrom: string;
	schoolStartTo: string;
	schoolEndFrom: string;
	schoolEndTo: string;

	//	Relationship Manager
	inheritRelationshipManager: boolean;
	relationshipManager: RelationshipManager;
	relationshipManagerEmail: string;
	inheritedRelationshipManager: RelationshipManager;
	inheritRelationshipType: boolean;
	relationshipType: { id: number, relationshipType: RelationshipType };
	inheritedRelationshipType: { id: number, relationshipType: RelationshipType };

	//	Additional Details
    ncesDistrictId: string;
    ncesIdSource: string;
	primaryId: number;
	budgetEnd: string;
	longitude: string;
	latitude: string;
	ianaTimeZoneId: string;

	//	Preferences
	inheritPreferenceSet: boolean;
	preferenceSetId: number;
}
