import { Injectable } from '@angular/core';

import { tap, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { MessageType } from '../../../core/models';
import * as fromRoot from '../../../core/store';

import { MerchState, CombosState } from '../reducers';
import * as merchActions from '../actions';
import * as fromServices from '../../services';
import * as fromSelectors from '../selectors';
import { RentalCategory } from '../../models';

@Injectable()
export class MerchEffects {
	constructor(private actions$: Actions, private merchService: fromServices.MerchService, private store: Store<MerchState>) { }

	
	loadClasses$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadClasses),
		tap(a => console.log('Entering loadClasses$ effect: ', a)),
    withLatestFrom(this.store.pipe(select(fromSelectors.getCombosState))),
		switchMap(([action, state]: [merchActions.LoadClasses, CombosState]) => {
			if (state.classes !== null) {
				console.log('loadClasses$: Instrument classes already loaded: ', state.classes);

				return of(new merchActions.LoadClassesSuccess(state.classes));
			}

			return this.merchService.getInstrumentClasses().pipe(
				tap(classes => {
					console.log(`getInstrumentClasses returned:`, classes);
				}),
				map(classes => {
					return new merchActions.LoadClassesSuccess(classes);
				}),
				catchError(error => of(new merchActions.LoadClassesFailure(this.getErrorMessage(error)))),
			);
		}),
		tap(a => console.log('Exiting loadClasses$ effect: ', a)),
	));

	
	loadClassesFailure$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadClassesFailure),
		switchMap((a: merchActions.LoadClassesFailure) => {
			return of(new fromRoot.DisplayMessage({
				message: a.payload,
				messageType: MessageType.alert,
				toast: false,
			}));
		})
	));

	
	loadCombos$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadCombos),
		tap(a => console.log('Entering loadCombos$ effect: ', a)),
		withLatestFrom(this.store.pipe(select(fromSelectors.getCombosState))),
		switchMap(([action, state]: [merchActions.LoadCombos, CombosState]) => {
			const classId = action.payload;
			const combos = state.classCombos[classId].combos;

			if (state.classCombos[classId] && state.classCombos[classId].combos) {
				console.log(`loadCombos$: Instrument combos for class ${classId} already loaded: `, state.classes);

				return of(new merchActions.LoadCombosSuccess({ classId, combos }));
			}

			return this.merchService.getInstrumentCombos(classId).pipe(
				tap(combos => {
					console.log(`getInstrumentCombos returned:`, combos);
				}),
				map(combos => {
					return new merchActions.LoadCombosSuccess({ classId, combos });
				}),
				catchError(error => of(new merchActions.LoadCombosFailure({ classId, error }))),
			);
		}),
		tap(a => console.log('Exiting loadCombos$ effect: ', a)),
	));

	
	loadCombosFailure$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadCombosFailure),
		switchMap((a: merchActions.LoadCombosFailure) => {
			return of(new fromRoot.DisplayMessage({
				message: this.getErrorMessage(a.payload.error),
				messageType: MessageType.alert,
				toast: false,
			}));
		})
  ));

  	loadRentalCategories$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadRentalCategories),
		tap(a => console.log('Entering loadRentalCategories$ Effect: ', a)),
		withLatestFrom(this.store.pipe(select(fromSelectors.getCombosState))),
		map(([action, state]: [merchActions.LoadRentalCategories, CombosState]) => {
			return { action, state };
		}),
		switchMap(({ action, state }: { action: merchActions.LoadRentalCategories, state: CombosState }) => {
			const rentalCategoryCode = action.payload;
			
			if (state && state.rentalCategories) {
				console.log(`loadRentalCategories$: Rental Categories already loaded.`);
				return of(new merchActions.LoadRentalCategoriesSuccess({ rentalCategories: state.rentalCategories }));
			}

			return this.merchService.getRentalCategories(rentalCategoryCode).pipe(
				tap(rentalCategories => {
					console.log(`getRentalCategories returned:`, rentalCategories);
				}),
				map((rentalCategories: RentalCategory[]) => {
					return new merchActions.LoadRentalCategoriesSuccess({ rentalCategories: rentalCategories });
				}),
				catchError(error => of(new merchActions.LoadRentalCategoriesFailure({ error })))
			);
		}),
		catchError(err => {
			return of(new merchActions.LoadRentalCategoriesFailure({ error: err }));
		}),
		tap(a => console.log('Exiting loadRentalCategories$ Effect: ', a)),
	));

	loadRentalCategoriesFailure$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadRentalCategoriesFailure),
		switchMap((a: merchActions.LoadRentalCategoriesFailure) => {
			return of(new fromRoot.DisplayMessage({
			message: this.getErrorMessage(a.payload.error),
			messageType: MessageType.alert,
			toast: false,
			}));
		})
	));

	loadRentalCategoryDetails$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadRentalCategoryDetails),
		tap(a => console.log('Entering loadRentalCategoryDetails$ Effect: ', a)),
		withLatestFrom(this.store.pipe(select(fromSelectors.getCombosState))),
		map(([action, state]: [merchActions.LoadRentalCategoryDetails, CombosState]) => {
			return { action, state };
		}),
		switchMap(({ action, state }: { action: merchActions.LoadRentalCategoryDetails, state: CombosState }) => {
			const rentalCategoryId = action.payload;
			
			if (state && state.rentalCategoryDetails) {
				console.log(`loadRentalCategoryDetails$: Rental Categories Details already loaded.`);
				return of(new merchActions.LoadRentalCategoryDetailsSuccess({ rentalCategory: state.rentalCategoryDetails }));
			}

			return this.merchService.getRentalCategoryDetails(rentalCategoryId).pipe(
				tap(rentalCategoryDetails => {
					console.log(`getRentalCategoryDetails returned:`, rentalCategoryDetails);
				}),
				map((rentalCategory: RentalCategory) => {
					return new merchActions.LoadRentalCategoryDetailsSuccess({ rentalCategory: rentalCategory });
				}),
				catchError(error => of(new merchActions.LoadRentalCategoryDetailsFailure({ error })))
			);
		}),
	));
  
  loadProductGroups$ = createEffect(() => this.actions$.pipe(
	ofType(merchActions.MerchActionTypes.LoadProductGroups),
    tap(a => console.log('Entering loadProductGroups$ effect: ', a)),
    withLatestFrom(this.store.pipe(select(fromSelectors.getCombosState))),
    switchMap(([action, state]: [merchActions.LoadProductGroups, CombosState]) => {
      if (state.productGroups !== null) {
        console.log('loadProductGroups$: Product Groups already loaded: ', state.productGroups);

        return of(new merchActions.LoadProductGroupsSuccess(state.productGroups));
      }

      return this.merchService.getProductGroups().pipe(
        tap(productGroups => {
          console.log(`getProductGroups returned:`, productGroups);
        }),
        map(classes => {
          return new merchActions.LoadProductGroupsSuccess(classes);
        }),
        catchError(error => of(new merchActions.LoadProductGroupsFailure(this.getErrorMessage(error)))),
      );
    }),
    tap(a => console.log('Exiting loadProductGroups$ effect: ', a)),
  ));

  
  loadProductGroupsFailure$ = createEffect(() => this.actions$.pipe(
	ofType(merchActions.MerchActionTypes.LoadProductGroupsFailure),
    switchMap((a: merchActions.LoadProductGroupsFailure) => {
      return of(new fromRoot.DisplayMessage({
        message: a.payload,
        messageType: MessageType.alert,
        toast: false,
      }));
    })
  ));

  	


  
  loadProducts$ = createEffect(() => this.actions$.pipe(
	ofType(merchActions.MerchActionTypes.LoadProducts),
    tap(a => console.log('Entering loadProducts$ effect: ', a)),
    withLatestFrom(this.store.pipe(select(fromSelectors.getCombosState))),
    switchMap(([action, state]: [merchActions.LoadProducts, CombosState]) => {
      const productGroupId = action.payload;
      const products = state.products[productGroupId].products;

      if (state.products[productGroupId] && state.products[productGroupId].products) {
        console.log(`loadProducts$: Products for Product Group ${productGroupId} already loaded: `, state.productGroups);

        return of(new merchActions.LoadProductsSuccess({ productGroupId, products }));
      }

      return this.merchService.getProducts(productGroupId).pipe(
        tap(products => {
          console.log(`getProducts returned:`, products);
        }),
        map(products => {
          if (products) {
            products.forEach(p => {
              if (p.rentalInstruments) {
                p.rentalInstrumentsCsv = p.rentalInstruments.map(ri => ri.name).join(', ');
              }
            })
          }

          return new merchActions.LoadProductsSuccess({ productGroupId, products });
        }),
        catchError(error => of(new merchActions.LoadProductsFailure({ productGroupId, error }))),
      );
    }),
    tap(a => console.log('Exiting loadProducts$ effect: ', a)),
  ));

  
	loadProductsFailure$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.LoadProductsFailure),
		switchMap((a: merchActions.LoadProductsFailure) => {
		return of(new fromRoot.DisplayMessage({
			message: this.getErrorMessage(a.payload.error),
			messageType: MessageType.alert,
			toast: false,
		}));
		})
	));

	
	setComboAvailability$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.SetComboAvailability),
		tap(a => console.log('Entering setComboAvailability$ effect: ', a)),
		switchMap((a: merchActions.SetComboAvailability) => {
			const { classId, available, ids } = a.payload;

			return this.merchService.setComboAvailability(available, ids).pipe(
				map(() => {
					return new merchActions.SetComboAvailabilitySuccess(a.payload);
				}),
				catchError(error => of(new merchActions.SetComboAvailabilityFailure({ classId, error }))),
			);
		}),
		tap(a => console.log('Exiting setComboAvailability$ effect: ', a)),
	));

	
	setComboAvailabilitySuccess$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.SetComboAvailabilitySuccess),
		switchMap((a: merchActions.SetComboAvailabilitySuccess) => {
			return of(new fromRoot.DisplayMessage({
				message: `Successfully made ${a.payload.ids.length} combinations ${a.payload.available ? 'available' : 'unavailable'}`,
				messageType: MessageType.success,
				toast: true,
			}));
		})
	));

	
	setComboAvailabilityFailure$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.SetComboAvailabilityFailure),
		switchMap((a: merchActions.SetComboAvailabilityFailure) => {
			return of(new fromRoot.DisplayMessage({
				message: this.getErrorMessage(a.payload.error),
				messageType: MessageType.alert,
				toast: true,
			}));
		})
	));

	
	deleteCombo$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.DeleteCombo),
		tap(a => console.log('Entering deleteCombo$ effect: ', a)),
		switchMap((a: merchActions.DeleteCombo) => {
			const { classId, id } = a.payload;

			return this.merchService.deleteCombo(id).pipe(
				map(() => {
					return new merchActions.DeleteComboSuccess(a.payload);
				}),
				catchError(error => of(new merchActions.DeleteComboFailure({ classId, id, error }))),
			);
		}),
		tap(a => console.log('Exiting deleteCombo$ effect: ', a)),
	));

	
	deleteComboFailure$ = createEffect(() => this.actions$.pipe(
		ofType(merchActions.MerchActionTypes.DeleteComboFailure),
		switchMap((a: merchActions.DeleteComboFailure) => {
			return of(new fromRoot.DisplayMessage({
				message: this.getErrorMessage(a.payload.error),
				messageType: MessageType.alert,
				toast: true,
			}));
		})
	));

	private getErrorMessage(error: any): string {
		if (error.status != 400) {
			return error.message;
		}

		return Object.keys(error.error).reduce((res, e) => {
			return res + res == '' ? error.error[e] : '\n' + error.error[e];
		}, '');
	}
}
