import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromHistory from '../reducers/history.reducer';

export const getHistoryState = createSelector(
	fromFeature.getScpState,
	(state: fromFeature.ScpState) => state.history,
);

export const getHistorySnapshotState = createSelector(
	getHistoryState,
	fromHistory.getHistorySnapshot,
);

export const getHistoryDetailsState = createSelector(
	getHistoryState,
	fromHistory.getDetailsState,
);

export const getHistoryDetails = createSelector(
	getHistoryDetailsState,
	fromHistory.getHistoryDetails,
);
