<opus-modal [visible]="visible" (action)="onModalAction($event)" title="Create New Teacher" primaryLabel="Create Teacher" secondaryLabel="Cancel" showClose="true">
    <section *ngIf="visible">
        <form name="createTeacherForm" #createTeacherForm [formGroup]="editor" novalidate (ngSubmit)="onSubmit()">
            <fieldset class="fieldset">
                <legend>Teacher Name</legend>
                <div class="row row--tight">
                    <div class="columns columns--tight shrink">
                        <label for="ctTeacherPrefix">Prefix</label>
                        <select formControlName="prefix">
                            <option value="notset">Select</option>
                            <option value="1" label="Mr">Mr</option>
                            <option value="2" label="Mrs">Mrs</option>
                            <option value="3" label="Ms" selected="selected">Ms</option>
                            <option value="4" label="Dr">Dr</option>
                            <option value="5" label="Capt">Capt</option>
                            <option value="6" label="Major">Major</option>
                            <option value="7" label="Sgt">Sgt</option>
                            <option value="8" label="Col">Col</option>
                            <option value="9" label="Fr">Fr</option>
                            <option value="10" label="Father">Father</option>
                            <option value="11" label="Brother">Brother</option>
                            <option value="12" label="Sister">Sister</option>
                            <option value="13" label="Pastor">Pastor</option>
                            <option value="14" label="Rev">Rev</option>
                            <option value="15" label="Rabbi">Rabbi</option>
                            <option value="16" label="Sr">Sr</option>
                        </select>
                    </div>
                    <div class="columns columns--tight">
                        <label for="ctTeacherFirstName">First Name</label>
                        <input type="text" id="ctTeacherFirstName" formControlName="firstName">
                    </div>
                    <div class="columns columns--tight">
                        <label for="ctTeacherLastName" class="label--required">Last Name</label>
                        <input type="text" id="ctTeacherLastName" name="lastName" formControlName="lastName">
                        <div class="error error--adjust-margin">
                            <div class="error__message" *ngIf="editor.get('lastName').invalid && (editor.get('lastName').dirty || submitted)">Required</div>
                        </div>
                    </div>
                    <div class="columns columns--tight shrink">
                        <label for="ctTeacherSuffix">Suffix</label>
                        <select formControlName="suffix">
                            <option value="notset" selected>Select</option>
                            <option label="Phd" value="1">Phd</option>
                            <option label="Esq" value="2">Esq</option>
                            <option label="Md" value="3">Md</option>
                            <option label="Jr" value="4">Jr</option>
                            <option label="II" value="5">II</option>
                            <option label="III" value="6">III</option>
                            <option label="IV" value="7">IV</option>
                            <option label="Doctor" value="8">Doctor</option>
                            <option label="Do" value="9">Do</option>
                            <option label="A" value="10">A</option>
                            <option label="W" value="11">W</option>
                            <option label="J" value="12">J</option>
                            <option label="M" value="13">M</option>
                            <option label="Ms" value="14">Ms</option>
                            <option label="Ms D" value="15">Ms D</option>
                            <option label="Ms Z" value="16">Ms Z</option>
                            <option label="Ms O" value="17">Ms O</option>
                            <option label="Ms Coo" value="18">Ms Coo</option>
                            <option label="Mis" value="19">Mis</option>
                            <option label="Eng" value="20">Eng</option>
                        </select>
                    </div>
                </div>
            </fieldset>

            <fieldset class="fieldset">
                <legend>District</legend>
                <div class="row row--tight">
                    <div class="columns columns--tight">
                        <label for="ctDistrictId">District ID</label>
                        <div class="input-group u-m-b-0">
                            <input type="text" id="ctDistrictId" class="input-group-field" formControlName="districtId" (change)="changeDistrictId()">
                            <div class="input-group-button">
                                <button type="button" (click)="lookupDistrict()">Apply</button>
                            </div>
                        </div>
                        <div class="error">
                            <div class="error__message" *ngIf="editor.get('districtId').invalid && (editor.get('districtId').dirty || submitted)">District ID not found</div>
                        </div>
                    </div>
                    <div class="columns columns--tight">
                        <label for="ctDistrictName">District Name</label>
                        <div>
                            <span data-toggle="districtTypeAhead">
                                <input type="text" id="editDistrictName" autocomplete="off" (keyup)="lookupDistrictByName($event)" (keydown)="$event.stopPropagation()" formControlName="districtName" role="combobox">
                            </span>
                            <ul class="dropdown-menu" role="listbox" id="districtTypeAhead" #districtDropdown data-v-offset="-16">
                                <li *ngFor="let district of districtByNameLookup?.districts">
                                    <button type="button" class="dropdown-menu__action" role="option" data-close="districtTypeAhead" (click)="selectDistrict(district)">
                                        <span class="l-block">{{ district.longName }} ({{ district.id }})</span>
                                        <span class="l-block">{{ district.county }}</span>
                                        <span class="l-block">{{ district.city }}, {{ district.state}}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <p class="typography--caption u-m-b-0" id="ctDistrictNameDescription">
                            Start typing the name of the district or search for the ID.
                        </p>
                    </div>
                </div>
            </fieldset>

            <div class="row">
                <div class="columns">
                    <label for="ctTeacherType" class="label--required">Type</label>
                    <select id="ctTeacherType" name="type" formControlName="schoolType">
                        <option value="notset" selected>Select</option>
                        <option value="1" label="Public">Public</option>
                        <option value="2" label="Private">Private</option>
                        <option value="3" label="College">College</option>
                        <option value="4" label="Other">Other</option>
                        <option value="5" label="Adult">Adult</option>
                        <option value="6" label="Charter">Charter</option>
                        <option value="7" label="National">National</option>
                        <option value="9" label="Home">Home</option>
                        <option value="10" label="Notlisted">Notlisted</option>
                    </select>
                    <div class="error error--adjust-margin">
                        <div class="error__message" *ngIf="editor.get('schoolType').invalid && (editor.get('schoolType').dirty || submitted)">Required</div>
                    </div>
                </div>
                <div class="columns">
                    <!-- we could do the whole address instead but it seems like overkill -->
                    <label for="ctZipCode" class="label--required">Zip Code</label>
                    <input type="text" id="ctZipCode" formControlName="zip">
                    <div class="error ng-hide" *ngIf="editor.get('zip').invalid && (editor.get('zip').dirty || submitted)">
                        <div class="error__message">Required and must be a valid zipcode.</div>
                    </div>
                </div>
            </div>

            <label for="ctEmail">Email Address</label>
            <input type="email" id="ctEmail" formControlName="email">
            <div class="error ng-hide" *ngIf="editor.get('email').invalid && (editor.get('email').dirty || submitted)">
                <div class="error__message">Invalid Email.</div>
            </div>
        </form>
    </section>
</opus-modal>
<div class="full-page-spinner" [class.full-page-spinner--is-active]="this.hasSubmitted">
    <div class="full-page-spinner__icon"></div>
</div>
