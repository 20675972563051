<div class="messages"
     [class.messages--active]="message"
     [ngClass]="messageType"
     aria-live="assertive"
     aria-atomic="true">
    <div class="messages__text">{{ message?.message }}</div>
    <div class="messages__action-wrapper" *ngIf="!message?.toast ">
        <button type="button" class="messages__action-btn" (click)="action()">
                Ok
        </button>
    </div>
</div>