<nav class="u-m-b-16">
    <ul class="breadcrumbs">
        <li>Distribution</li>
    </ul>
</nav>
<header class="u-m-b-40">
    <div class="row">
        <div class="columns">
            <h1 class="typography--display1">Distribution</h1>
        </div>
    </div>
</header>
<main role="main">
    <div class="row">
        <div class="columns">
            <div class="card spinner-section" [class.spinner-section--is-active]="vm.loadingCharges">
                <div class="spinner-section__icon"></div>
                <opus-shipping-charges-header
                    [shippingCharges$]="shippingCharges$"
                    (criteriaChangeEvent)="onCriteriaChange($event)"></opus-shipping-charges-header>
                <opus-shipping-charges-table
                    [criteria]="criteria"
                    [shippingCharges$]="shippingCharges$"></opus-shipping-charges-table>
            </div>
        </div>
    </div>
</main>
