<opus-modal [visible]="visible" (action)="onModalAction($event)" [title]="title" [primaryLabel]="primaryLabel" [secondaryLabel]="secondaryLabel" showClose="true">
	<ng-container *ngIf="visible && !showCorrection">
		<form name="createSchoolForm" [formGroup]="editor" novalidate>
			<input type="text" name="longName" formControlName="longName" placeholder="School Long Name" aria-label="School Long Name">
			<div class="error error--adjust-margin" *ngIf="longName.invalid && (longName.touched || validating)">
				<div class="error__message" *ngIf="longName.errors.required">Required</div>
			</div>
			<div class="row">
				<div class="columns">
					<label>
						Type
						<select formControlName="schoolType">
							<option *ngFor="let st of schoolTypes | slice:1" [value]="st.id">{{ st.name }}</option>
						</select>
					</label>
				</div>
				<div class="columns">
					<label>
						Level
						<select formControlName="schoolLevel">
							<option *ngFor="let sl of schoolLevels | slice:1" [value]="sl.id">{{ sl.name }}</option>
						</select>
					</label>
				</div>
			</div>

			<fieldset class="fieldset" [formGroup]="pAddress">
				<legend>Physical Address</legend>
				<input type="text" placeholder="Address Line 1" aria-label="Address Line 1" name="pAddressLine1" formControlName="line1">

				<input type="text" placeholder="Address Line 2" aria-label="Address Line 2" formControlName="line2">

				<div class="row">
					<div class="columns">
						<div class="input-group u-m-b-0">
							<input type="text" class="input-group-field" placeholder="Zip Code" aria-label="Zip Code" formControlName="zip">
							<div class="input-group-button">
								<button type="button" [disabled]="pZipLookupDisabled" (click)="onApplyZip(true)">Apply</button>
							</div>
						</div>
						<ul class="error" *ngIf="pZip.invalid && (pZip.touched || validating)">
							<li class="error__message" *ngIf="pZip.errors.required">Required and must be a valid zip code</li>
							<li class="error__message" *ngIf="pZip.errors.pattern">Must be a valid zip code</li>
						</ul>
					</div>
					<div class="columns">
						<input type="text" placeholder="City" title="City" formControlName="city">
					</div>
					<div class="columns shrink">
						<select aria-label="State" title="State" formControlName="state">
							<option value="">Select State</option>
							<option *ngFor="let s of states" [value]="s">{{ s }}</option>
						</select>
					</div>
				</div>
				<label>
					County
					<select aria-label="County" title="County" formControlName="county">
						<option value="">Select County</option>
						<option *ngFor="let c of counties" [value]="c">{{ c }}</option>
					</select>
				</label>
			</fieldset>

			<fieldset class="fieldset" [formGroup]="mAddress">
				<legend>Mailing Address</legend>
				<label class="checkbox">
					<input class="checkbox__input" type="checkbox" (change)="onSameAsPhysical($event)" [checked]="sameAsPhysical"> Same as physical address
				</label>

				<input type="text" placeholder="Address Line 1" aria-label="Address Line 1" name="mAddressLine1" formControlName="line1">

				<input type="text" placeholder="Address Line 2" aria-label="Address Line 2" formControlName="line2">

				<div class="row">
					<div class="columns">
						<div class="input-group u-m-b-0">
							<input type="text" class="input-group-field" placeholder="Zip Code" aria-label="Zip Code" formControlName="zip">
							<div class="input-group-button">
								<button type="button" [disabled]="mZipLookupDisabled" (click)="onApplyZip(false)">Apply</button>
							</div>
						</div>
						<ul class="error" *ngIf="mZip.invalid && (mZip.touched || validating)">
							<li class="error__message" *ngIf="mZip.errors.required">Required and must be a valid zip code</li>
							<li class="error__message" *ngIf="mZip.errors.pattern">Must be a valid zip code</li>
						</ul>
					</div>
					<div class="columns">
						<input type="text" placeholder="City" title="City" formControlName="city">
					</div>
					<div class="columns shrink">
						<select aria-label="State" title="State" formControlName="state">
							<option value="">Select State</option>
							<option *ngFor="let s of states" [value]="s">{{ s }}</option>
						</select>
					</div>
				</div>
			</fieldset>
			<div *ngIf="editor.value.schoolType == 1 || editor.value.schoolType == 2 || editor.value.schoolType == 3 || editor.value.schoolType == 6">
				<input type="text" placeholder="Phone Number" aria-label="Phone Number" name="phone" formControlName="phone">
				<div class="error error--adjust-margin" *ngIf="pPhone.invalid && (pPhone.touched || validating)">
					<div class="error__message" *ngIf="pPhone.errors.required">Required</div>
				</div>
			</div>
				
		</form>
	</ng-container>

	<ng-container *ngIf="visible && showCorrection">
		<ng-container *ngIf="enteredAddress">
            <div class="property-block">
			    <div class="property-block__title-wrapper">
				    <h2 class="property-block__title">Entered Address:</h2>
			    </div>
			    <ul class="property-block__list">
				    <li class="property-block__list-item">
					    <div class="property-block__label" id="eAddressLine1">Address Line 1</div>
					    <div class="property-block__value" role="definition" aria-labelledby="eAddressLine1">{{ enteredAddress.line1 }}</div>
				    </li>
				    <li class="property-block__list-item" *ngIf="enteredAddress.line2">
					    <div class="property-block__label" id="eAddressLine2">Address Line 2</div>
					    <div class="property-block__value" role="definition" aria-labelledby="eAddressLine2">{{ enteredAddress.line2 }}</div>
				    </li>
				    <li class="property-block__list-item">
					    <div class="property-block__label" id="eCityStateZip">City, State Zip</div>
					    <div class="property-block__value" role="definition" aria-labelledby="eCityStateZip">{{ enteredAddress.city }}, {{ enteredAddress.state }} {{ enteredAddress.zip }}</div>
				    </li>
			    </ul>
            </div>
		</ng-container>
        <ng-container *ngIf="suggestedAddress">
            <div class="property-block">
                <div class="property-block__title-wrapper">
                    <h2 class="property-block__title">Suggested Address:</h2>
                </div>
                <ul class="property-block__list">
                    <li class="property-block__list-item">
                        <div class="property-block__label" id="sAddressLine1">Address Line 1</div>
                        <div class="property-block__value" role="definition" aria-labelledby="sAddressLine1">{{ suggestedAddress.line1 }}</div>
                    </li>
                    <li class="property-block__list-item" *ngIf="suggestedAddress.line2">
                        <div class="property-block__label" id="sAddressLine2">Address Line 2</div>
                        <div class="property-block__value" role="definition" aria-labelledby="sAddressLine2">{{ suggestedAddress.line2 }}</div>
                    </li>
                    <li class="property-block__list-item">
                        <div class="property-block__label" id="sCityStateZip">City, State Zip</div>
                        <div class="property-block__value" role="definition" aria-labelledby="sCityStateZip">{{ suggestedAddress.city }}, {{ suggestedAddress.state }} {{ suggestedAddress.zip }}</div>
                    </li>
                </ul>
            </div>
        </ng-container>
		<ng-container *ngIf="!suggestedAddress">
			<ul class="error">
				<li class="error__message">The United states Postal Service is unable to locate this address.</li>
			</ul>
		</ng-container>
	</ng-container>
</opus-modal>

<div class="full-page-spinner" [class.full-page-spinner--is-active]="this.submitting">
	<div class="full-page-spinner__icon"></div>
</div>
