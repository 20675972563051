import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
	selector: 'opus-property-block',
	templateUrl: './property-block.component.html',
})
export class PropertyBlockComponent implements OnInit {
	@Input() label: string;
	@Input() canEdit = true;
    @Input() isEditing: boolean;
    @Input() policy: string;
    @Input() opusRelationshipManagerEmail: any;
    @Input() opusRelationshipManagerRoles: string[];

	@Output() toggleEditor = new EventEmitter<boolean>();

	ngOnInit() {
		console.log(`PropertyBlockComponent.ngOnInit(): label = '${this.label}', isEditing = ${this.isEditing}` );
	}

	onToggleEditor() {
		this.toggleEditor.emit(!this.isEditing);
	}
}

