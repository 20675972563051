<form [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editSchoolReturns" class="property-block__label">School Returns Allowed</label>
			<div class="property-block__value">
				<select id="editSchoolReturns" formControlName="returnsAllowed">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editLocationInSession" class="property-block__label">Location in Session</label>
			<div class="property-block__value">
				<input type="text" id="editLocationInSession" formControlName="sessionLocation">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editLocationInSummer" class="property-block__label">Location in Summer</label>
			<div class="property-block__value">
				<input type="text" id="editLocationInSummer" formControlName="summerLocation">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editReturnsBlackoutPeriod" class="property-block__label">Returns Blackout Period</label>
			<div class="property-block__value">
				<select id="editReturnsBlackoutPeriod" formControlName="hasBlackoutPeriod">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
		</li>
		<ng-container *ngIf="editor.value.hasBlackoutPeriod == 'Yes'">
			<li class="property-block__list-item">
				<label for="editReturnsBlackoutStart" class="property-block__label">Blackout Period Start</label>
				<div class="property-block__value">
					<div class="md-selector" role="group" aria-labelledby="editReturnsBlackoutStart">
						<div class="md-selector__month">
							<label>
								Month
								<select formControlName="blackoutStartMonth">
									<!--<option value="">N/A</option>-->
									<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
								</select>
							</label>
						</div>
						<div class="md-selector__day">
							<label>
								Day
								<select formControlName="blackoutStartDay">
									<!--<option value="">N/A</option>-->
									<option *ngFor="let d of days[editor.value.blackoutStartMonth]" [value]="d">{{ d }}</option>
								</select>
							</label>
						</div>
					</div>
				</div>
			</li>
			<li class="property-block__list-item">
				<label for="editReturnsBlackoutEnd" class="property-block__label">Blackout Period End</label>
				<div class="property-block__value">
					<div class="md-selector" role="group" aria-labelledby="editReturnsBlackoutEnd">
						<div class="md-selector__month">
							<label>
								Month
								<select formControlName="blackoutEndMonth">
									<!--<option value="">N/A</option>-->
									<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
								</select>
							</label>
						</div>
						<div class="md-selector__day">
							<label>
								Day
								<select formControlName="blackoutEndDay">
									<!--<option value="">N/A</option>-->
									<option *ngFor="let d of days[editor.value.blackoutEndMonth]" [value]="d">{{ d }}</option>
								</select>
							</label>
						</div>
					</div>
				</div>
			</li>
		</ng-container>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
