<!-- display mode -->
<ul class="property-block__list" >
    <li class="property-block__list-item">
        <div class="property-block__label" id="active">Active</div>
        <div class="property-block__value" role="definition" aria-labelledby="active">{{ event.isActive | yesNo }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventId">Event ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventId">{{ event.id }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventName">Event Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventName">{{ event.name}}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventType">Event Type</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventType">{{ event.eventTypeName}}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventNotes">Notes</div>
        <div class="property-block__value u-text--preformatted" role="definition" aria-labelledby="eventNotes" >{{ event.note }}</div>
    </li>
</ul>
<!-- /display mode -->

