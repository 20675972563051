import * as fromProgramCreate from '../actions/program-create.actions';
import * as fromProgramDetails from '../actions/program-details.action';
import * as fromSchoolModels from '../../../schools/models';
import { ProgramDetails } from '../../models';
import { TeacherLookupCriteria } from '../../../teachers/models';

export enum PanelId {
  Info = 'info',
  AdditionalDetails = 'additionalDetails',
  RelationshipManager = 'relationshipManager',
  ProgramTeachers = 'programTeachers'
}

export interface ValidationStatus {
  [id: string]: {
    pending: boolean;
    errors: { [key: string]: any };
  };
}

export interface PanelState {
  editing: boolean;
  updating: boolean;
  validationStatus: ValidationStatus;
  errors: any;	//	TODO: formalize error handling
}

export interface ProgramDetailsState {
  programDetails: ProgramDetails;
  loaded: boolean;
  loading: boolean;
  errors: any;
  teacherLookup: TeacherLookupState;
  teacherByCriteriaLookup: TeacherByCriteriaLookupState;
  panels: { [id: string]: PanelState };
}

export const initialPanelState: PanelState = {
  editing: false,
  validationStatus: {},
  updating: false,
  errors: null,
};

export interface TeacherLookupState {
  teacherId: number;
  pending: boolean;
  errors: any;
  teacher: fromSchoolModels.TeacherLookup;
}

export interface TeacherByCriteriaLookupState {
  criteria: TeacherLookupCriteria;
  pending: boolean;
  errors: any;
  teachers: fromSchoolModels.TeacherLookup[];
}

export const initialTeacherLookupState: TeacherLookupState = {
  teacherId: null,
  pending: false,
  teacher: null,
  errors: null,
};

export const initialTeacherByCriteriaLookupState: TeacherByCriteriaLookupState = {
  criteria: { name: null },
  pending: false,
  errors: null,
  teachers: null
};

export const initialState: ProgramDetailsState = {
  programDetails: null,
  loaded: false,
  loading: false,
  errors: null,
  teacherLookup: initialTeacherLookupState,
  teacherByCriteriaLookup: initialTeacherByCriteriaLookupState,
  panels: {
    [PanelId.Info]: { ...initialPanelState },
    [PanelId.AdditionalDetails]: { ...initialPanelState },
    [PanelId.RelationshipManager]: { ...initialPanelState },
    [PanelId.ProgramTeachers]: { ...initialPanelState },
  }
};

export function reducer(state = initialState, action: fromProgramDetails.ProgramDetailsActions | fromProgramCreate.ProgramCreateActions): ProgramDetailsState {
  switch (action.type) {
    case fromProgramCreate.ProgramCreateActionTypes.CreateProgramSuccess: {
      const programCreate = action.payload;

      if (!state.programDetails || state.programDetails.id !== programCreate.id) {
        return {
          ...state
        }
      }

      return {
        ...state,
        loaded: false
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.LoadProgramDetails: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.LoadProgramDetailsComplete: {
      const programDetails = action.payload;

      return {
        ...state,
        loaded: true,
        loading: false,
        errors: null,
        programDetails,
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.LoadProgramDetailsFailure: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: action.payload,
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.UpdateEditorState: {
      const key = action.payload.key;
      const editing = action.payload.open;

      if (editing == state.panels[key].editing) {
        return state;
      }

      const panel = { ...initialState.panels[key], editing };	//	NOTE: initialPanelState will not have any custom panel initialization...
      const panels = {
        ...state.panels,
        [key]: panel,
      };

      return {
        ...state,
        panels,
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.UpdateProgramPanel: {
      const panel = {
        ...state.panels[action.payload.panel],
        updating: true,
      };

      return {
        ...state,
        panels: {
          ...state.panels,
          [action.payload.panel]: panel,
        },
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.UpdateProgramPanelFailure: {
      const panel = {
        ...state.panels[action.payload.panel],
        updating: false,
        errors: action.payload.errors,
      };

      return {
        ...state,
        panels: {
          ...state.panels,
          [action.payload.panel]: panel,
        },
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.UpdateProgramPanelSuccess: {
      const programDetails = action.payload.program;

      return {
        ...state,
        programDetails,
        panels: {
          ...state.panels,
          [action.payload.panel]: initialState.panels[action.payload.panel],
        },
      };
    }

    //Teacher
    case fromProgramDetails.ProgramDetailsActionTypes.TeacherLookupById: {
      const { teacherId, panel } = action.payload;
      const teacherLookup: TeacherLookupState = {
        ...state.teacherLookup,
        teacherId,
        pending: true,
        errors: null,
        teacher: null,
      };

      return {
        ...state,
        teacherLookup
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.TeacherLookupByIdFailure: {
      const { errors, panel } = action.payload;
      const teacherLookup: TeacherLookupState = {
        ...state.teacherLookup,
        pending: false,
        errors,
        teacher: null,
      };

      return {
        ...state,
        teacherLookup
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.TeacherLookupByIdSuccess: {
      const { panel, teacher } = action.payload;
      const teacherLookup: TeacherLookupState = {
        ...state.teacherLookup,
        pending: false,
        teacher,
      };

      return {
        ...state,
        teacherLookup
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.TeacherLookupByCriteria: {
      const { criteria } = action.payload;
      const teacherByCriteriaLookup: TeacherByCriteriaLookupState = {
        criteria,
        pending: true,
        errors: null,
        teachers: null,
      };

      return {
        ...state,
        teacherByCriteriaLookup: teacherByCriteriaLookup,
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.TeacherLookupByCriteriaFailure: {
      const { errors, panel } = action.payload;
      const teacherByCriteriaLookup: TeacherByCriteriaLookupState = {
        ...state.teacherByCriteriaLookup,
        pending: false,
        errors,
        teachers: null,
      };

      return {
        ...state,
        teacherByCriteriaLookup: teacherByCriteriaLookup,
      };
    }

    case fromProgramDetails.ProgramDetailsActionTypes.TeacherLookupByCriteriaSuccess: {
      const { panel, teachers } = action.payload;
      const teacherByCriteriaLookup: TeacherByCriteriaLookupState = {
        ...state.teacherByCriteriaLookup,
        pending: false,
        teachers,
      };

      return {
        ...state,
        teacherByCriteriaLookup: teacherByCriteriaLookup,
      };
    }
  }

  return state;
}

export const getProgramDetailsEntity = (state: ProgramDetailsState) => state.programDetails;
export const getProgramDetailsSchool = (state: ProgramDetails) => state.school;
export const getProgramDetailsLoading = (state: ProgramDetailsState) => state.loading;
export const getProgramDetailsLoaded = (state: ProgramDetailsState) => state.loaded;
export const getDetailsStatePanels = (state: ProgramDetailsState) => state.panels;
export const getDetailsStateTeacherLookup = (state: ProgramDetailsState) => state.teacherLookup;
export const getDetailsStateTeacherByCriteriaLookup = (state: ProgramDetailsState) => state.teacherByCriteriaLookup;
