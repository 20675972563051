import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService, } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html'
})

export class UnauthorizedComponent implements OnInit, OnDestroy {
    lang: any;

    constructor(public oidcSecurityService: OidcSecurityService)
    {
        //this.oidcSecurityService.onModuleSetup.subscribe(() => { this.onModuleSetup(); });
    }

    ngOnInit() {
        //if (this.oidcSecurityService.moduleSetup) {
        //    this.onModuleSetup();
        //}
    }

    ngOnDestroy(): void {
        //this.oidcSecurityService.onModuleSetup.unsubscribe();
    }

    private onModuleSetup() {
        //this.oidcSecurityService.authorize();
    }
}
