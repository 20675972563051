import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromLocation from './location.reducer';
import * as fromTaxonomy from './taxonomy.reducer';
import * as fromHistory from './history.reducer';

export interface ScpState {
    locations: fromLocation.LocationsState;
	taxonomy: fromTaxonomy.TaxonomyState;
	history: fromHistory.HistoryState;
}

export const reducers: ActionReducerMap<ScpState> = {
    locations: fromLocation.reducer,
	taxonomy: fromTaxonomy.reducer,
	history: fromHistory.reducer,
};

export const getScpState = createFeatureSelector<ScpState>('scp');

export { LocationsState, LocationManagerState, ManagerByNameLookupState } from './location.reducer';
export { TaxonomyState } from './taxonomy.reducer';
export { HistoryState, SnapshotState, DetailsState } from './history.reducer';
