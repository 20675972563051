<nav class="u-m-b-16">
    <ul class="breadcrumbs">
        <li>Merchandising</li>
    </ul>
</nav>
<h1 class="typography--display1 u-m-b-16">Merchandising</h1>

<nav role="navigation">
    <ul class="menu menu--simple">
        <li opusAuthorize="InstrumentCombinations.View">
            <a routerLink="instrument-combinations">Instrument Combinations</a>
        </li>
        <li opusAuthorize="InstrumentCombinations.View">
            <a routerLink="product-groups">Product Groups</a>
        </li>
        <li opusAuthorize="InstrumentCombinations.View">
            <a routerLink="rental-categories">Rental Categories</a>
        </li>
    </ul>
</nav>
