import { SchoolDetailsComponent } from './school-details.component';
import { SchoolSearchResultsComponent } from './school-searchresults.component';
import { SchoolInfoEditorComponent } from './school-info-editor.component';
import { SchoolAddressEditorComponent } from './school-address-editor.component';
import { SchoolContactsEditorComponent } from './school-contacts-editor.component';
import { SchoolRateEditorComponent } from './school-rate-editor.component';
import { SchoolCalendarEditorComponent } from './school-calendar-editor.component';
import { SchoolRelationshipEditorComponent } from './school-relationship-editor.component';
import { SchoolOnlineRentalsEditorComponent } from './school-online-rentals-editor.component';
import { SchoolRentalReturnsEditorComponent } from './school-rental-returns-editor.component';
import { SchoolAdditionalDetailsEditorComponent } from './school-additional-details-editor.component';
import { SchoolCreateComponent } from './school-create.component';

export const containers: any[] = [
    SchoolDetailsComponent,
	SchoolSearchResultsComponent,
	SchoolInfoEditorComponent,
	SchoolAddressEditorComponent,
	SchoolContactsEditorComponent,
	SchoolRateEditorComponent,
	SchoolCalendarEditorComponent,
	SchoolRelationshipEditorComponent,
	SchoolOnlineRentalsEditorComponent,
	SchoolRentalReturnsEditorComponent,
	SchoolAdditionalDetailsEditorComponent,
	SchoolCreateComponent,
];

export * from './school-details.component';
export * from './school-searchresults.component';
export * from './school-info-editor.component';
export * from './school-address-editor.component';
export * from './school-contacts-editor.component';
export * from './school-rate-editor.component';
export * from './school-calendar-editor.component';
export * from './school-relationship-editor.component';
export * from './school-online-rentals-editor.component';
export * from './school-rental-returns-editor.component';
export * from './school-additional-details-editor.component';
export * from './school-create.component';
