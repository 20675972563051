import { TeacherSearchResultsComponent } from './teacher-searchresults.component';
import { TeacherDetailsComponent } from './teacher-details.component';
import { TeacherAddressEditorComponent } from './teacher-address-editor.component';
import { TeacherContactEditorComponent } from './teacher-contact-editor.component';
import { TeacherAdditionalEditorComponent } from './teacher-additional-editor.component';
import { TeacherDistrictEditorComponent } from './teacher-district-editor.component';
import { TeacherInfoEditorComponent } from './teacher-info-editor.component';

export const containers: any[] = [
    TeacherSearchResultsComponent,
    TeacherDetailsComponent,
    TeacherAddressEditorComponent,
    TeacherContactEditorComponent,
    TeacherAdditionalEditorComponent,
    TeacherDistrictEditorComponent,
    TeacherInfoEditorComponent,
];

export * from './teacher-searchresults.component';
export * from './teacher-details.component';
export * from './teacher-address-editor.component';
export * from './teacher-contact-editor.component';
export * from './teacher-additional-editor.component';
export * from './teacher-district-editor.component';
export * from './teacher-info-editor.component';
