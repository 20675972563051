import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../core/services/ConfigurationService';
import { ConfigurationState } from '../../core/store/reducers';

import { ShippingCharge, ShippingChargesCriteria } from '../models';

@Injectable()
export class DistributionService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	getShippingCharges(criteria: ShippingChargesCriteria): Observable<ShippingCharge[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				const params = new HttpParams({
					fromObject: {
						rentalCategoryCode: criteria.rentalCategoryCode || '',
						state: criteria.state || ''
					}
				});
				return this.http.get<ShippingCharge[]>(`${c.config.apiServer}api/distribution`, { params });
			})
		);
	}
}
