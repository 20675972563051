import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';

import { Action } from '@ngrx/store';

import { Address, AddressVerification } from '../../../scp-common/models';
import { SchoolDetails, RateAgreement, NewSchool } from '../../models';
import { DistrictLookup, DistrictSummary } from '../../../districts/models';

export enum SchoolDetailsActionTypes {
	LoadSchoolDetails = '[School] Load School Details',
	LoadSchoolDetailsComplete = '[School] Load School Details Complete',
	LoadSchoolDetailsFailure = '[School] Load School Details Failure',

	//ValidateDistrictId = '[School] Validate District',
	//ValidateDistrictIdComplete = '[School] Validate District Complete',

	LookupDistrictById = '[School] Lookup District By Id',
	LookupDistrictByIdSuccess = '[School] Lookup District By Id Success',
	LookupDistrictByIdFailure = '[School] Lookup District By Id Failure',

	LookupDistrictByName = '[School] Lookup District By Name',
	LookupDistrictByNameSuccess = '[School] Lookup District By Name Success',
	LookupDistrictByNameFailure = '[School] Lookup District By Name Failure',

	GetRateAgreement = '[School] Get Rate Agreement',
	GetRateAgreementSuccess = '[School] Get Rate Agreement Success',
	GetRateAgreementFailure = '[School] Get Rate Agreement Failure',

	SchoolLoadCounties = '[School] School Load Counties',
	SchoolLoadCountiesSuccess = '[School] School Load Counties Success',

	SchoolAddressLookupCityState = '[School] School Address Lookup CityState',
	SchoolAddressLookupCityStateSuccess = '[School] School Address Lookup CityState Success',
	SchoolAddressLookupCityStateFailure = '[School] School Address Lookup CityState Failure',

	SchoolVerifyAddress = '[School] School Verify Address',
	SchoolVerifyAddressSuccess = '[School] School Verify Address Success',
	SchoolVerifyAddressFailure = '[School] School Verify Address Failure',

	UpdateEditorState = '[School] Update Editor State',

	UpdateSchoolPanel = '[School] Update School Panel',
	UpdateSchoolPanelSuccess = '[School] Update School Panel Success',
	UpdateSchoolPanelFailure = '[School] Update School Panel Failure',

	ValidateExclusiveServiceAccountCode = '[School] Validate Exclusive Service Account Code',

	//ValidateRateAgreement = '[School] Validate Rate Agreement',
	//RateAgreementValidated = '[School] Rate Agreement Validated',

	CreateSchool = '[School] Create School',
	CreateSchoolSuccess = '[School] Create School Success',
	CreateSchoolFailure = '[School] Create School Failure',

	ValidateSchoolPrimaryId = '[School] Validate School Primary Id',
	ValidateSchoolExists = '[School] Validate School Exists',
	ValidateSchoolCanDeactivate = '[School] Validate School Can Deactivate',
	SchoolCanDeactivateValidated = '[School] School Can Deactivate Validated',
	SchoolExistsValidated = '[School] School Exists Validated',
	SchoolPrimaryIdValidated = '[School] School Primary Id Validated',
	SchoolPrimaryIdValidationFailed = '[School] School Primary Id Validation Failed',
}

export class LoadSchoolDetails implements Action {
	readonly type = SchoolDetailsActionTypes.LoadSchoolDetails;
	constructor(public payload: number) { }
}

export class LoadSchoolDetailsComplete implements Action {
	readonly type = SchoolDetailsActionTypes.LoadSchoolDetailsComplete;
	constructor(public payload: SchoolDetails) { }
}

export class LoadSchoolDetailsFailure implements Action {
	readonly type = SchoolDetailsActionTypes.LoadSchoolDetailsFailure;
	constructor(public payload: any) { }
}

//export class ValidateDistrictId implements Action {
//	readonly type = SchoolDetailsActionTypes.ValidateDistrictId;
//	constructor(public payload: DistrictLookup) { }
//}

//export class ValidateDistrictIdComplete implements Action {
//	readonly type = SchoolDetailsActionTypes.ValidateDistrictIdComplete;
//	constructor(public payload: boolean) { }
//}

export class LookupDistrictById implements Action {
	readonly type = SchoolDetailsActionTypes.LookupDistrictById;
	constructor(public payload: { id: number }) { }
}

export class LookupDistrictByIdSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.LookupDistrictByIdSuccess;
	constructor(public payload: { id: number, dl: DistrictLookup }) { }
}

export class LookupDistrictByIdFailure implements Action {
	readonly type = SchoolDetailsActionTypes.LookupDistrictByIdFailure;
	constructor(public payload: { id: number, error: any }) { }
}

export class LookupDistrictByName implements Action {
	readonly type = SchoolDetailsActionTypes.LookupDistrictByName;
	constructor(public payload: { name: string }) { }
}

export class LookupDistrictByNameSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.LookupDistrictByNameSuccess;
	constructor(public payload: { name: string, dls: DistrictLookup[] }) { }
}

export class LookupDistrictByNameFailure implements Action {
	readonly type = SchoolDetailsActionTypes.LookupDistrictByNameFailure;
	constructor(public payload: { name: string, error: any }) { }
}

export class GetRateAgreement implements Action {
	readonly type = SchoolDetailsActionTypes.GetRateAgreement;
	constructor(public payload: { panel: string, rateCode: string }) { }
}

export class GetRateAgreementSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.GetRateAgreementSuccess;
	constructor(public payload: { panel: string, rateCode: string, rateAgreement: RateAgreement }) { }
}

export class GetRateAgreementFailure implements Action {
	readonly type = SchoolDetailsActionTypes.GetRateAgreementFailure;
	constructor(public payload: { panel: string, rateCode: string, errors: any }) { }
}

export class SchoolLoadCounties implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolLoadCounties;
	constructor(public payload: { panel: string, stateCode: string }) { }
}

export class SchoolLoadCountiesSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolLoadCountiesSuccess;
	constructor(public payload: { panel: string, counties: string[] }) { }
}

export class SchoolAddressLookupCityState implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolAddressLookupCityState;
	constructor(public payload: { panel: string, zip: string }) { }
}

export class SchoolAddressLookupCityStateSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolAddressLookupCityStateSuccess;
	constructor(public payload: { panel: string, cityState: { city: string, state: string } }) { }
}

export class SchoolAddressLookupCityStateFailure implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolAddressLookupCityStateFailure;
	constructor(public payload: { panel: string, errors: any[] }) { }
}

export class SchoolVerifyAddress implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolVerifyAddress;
	constructor(public payload: { panel: string, address: Address }) { }
}

export class SchoolVerifyAddressSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolVerifyAddressSuccess;
	constructor(public payload: { panel: string, verification: AddressVerification }) { }
}

export class SchoolVerifyAddressFailure implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolVerifyAddressFailure;
	constructor(public payload: { panel: string }) { }
}

export class UpdateEditorState implements Action {
	readonly type = SchoolDetailsActionTypes.UpdateEditorState;
	constructor(public payload: { key: string, open: boolean }) { }
}

export class UpdateSchoolPanel implements Action {
	readonly type = SchoolDetailsActionTypes.UpdateSchoolPanel;
	constructor(public payload: { panel: string, school: SchoolDetails }) { }
}

export class UpdateSchoolPanelSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.UpdateSchoolPanelSuccess;
	constructor(public payload: { panel: string, school: SchoolDetails }) { }
}

export class UpdateSchoolPanelFailure implements Action {
	readonly type = SchoolDetailsActionTypes.UpdateSchoolPanelFailure;
	constructor(public payload: { panel: string, errors: string }) { }
}

export class ValidateExclusiveServiceAccountCode implements Action {
	readonly type = SchoolDetailsActionTypes.ValidateExclusiveServiceAccountCode;
	constructor(public payload: { panel: string, validator: string, exclusiveServiceAccountCode: string }) { }
}

//export class ValidateRateAgreement implements Action {
//	readonly type = SchoolDetailsActionTypes.ValidateRateAgreement;
//	constructor(public payload: { panel: string, validator: string, rateCode: string }) { }
//}

//export class RateAgreementValidated implements Action {
//	readonly type = SchoolDetailsActionTypes.RateAgreementValidated;
//	constructor(public payload: { panel: string, validator: string, errors: ValidationErrors }) { }
//}

export class CreateSchool implements Action {
	readonly type = SchoolDetailsActionTypes.CreateSchool;
	constructor(public payload: { school: NewSchool }) { }
}

export class CreateSchoolSuccess implements Action {
	readonly type = SchoolDetailsActionTypes.CreateSchoolSuccess;
	constructor(public payload: { id: number }) { }
}

export class CreateSchoolFailure implements Action {
	readonly type = SchoolDetailsActionTypes.CreateSchoolFailure;
	constructor(public payload: { errors: any }) { }
}

export class ValidateSchoolPrimaryId implements Action {
	readonly type = SchoolDetailsActionTypes.ValidateSchoolPrimaryId;
	constructor(public payload: { panel: string, validator: string, primaryId: string, duplicateId: number, duplicateIsActive: boolean }) { }
}

export class ValidateSchoolExists implements Action {
	readonly type = SchoolDetailsActionTypes.ValidateSchoolExists;
	constructor(public payload: { panel: string, validator: string, id: number }) { }
}

export class ValidateSchoolCanDeactivate implements Action {
	readonly type = SchoolDetailsActionTypes.ValidateSchoolCanDeactivate;
	constructor(public payload: { panel: string, validator: string, id: number }) { }
}

export class SchoolPrimaryIdValidated implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolPrimaryIdValidated;
	constructor(public payload: { panel: string, validator: string, errors: ValidationErrors }) { }
}

export class SchoolExistsValidated implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolExistsValidated;
	constructor(public payload: { panel: string, id: number, validator: string, errors: ValidationErrors }) { }
}

export class SchoolCanDeactivateValidated implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolCanDeactivateValidated;
	constructor(public payload: { panel: string, id: number, validator: string, errors: ValidationErrors }) { }
}

export class SchoolPrimaryIdValidationFailed implements Action {
	readonly type = SchoolDetailsActionTypes.SchoolPrimaryIdValidationFailed;
	constructor(public payload: { panel: string, validator: string, errors: any[] }) { }
}

export type SchoolDetailsActions =
	| LoadSchoolDetails
	| LoadSchoolDetailsComplete
	| LoadSchoolDetailsFailure
	//| ValidateDistrictId
	//| ValidateDistrictIdComplete
	| LookupDistrictById
	| LookupDistrictByIdSuccess
	| LookupDistrictByIdFailure
	| LookupDistrictByName
	| LookupDistrictByNameSuccess
	| LookupDistrictByNameFailure
	| GetRateAgreement
	| GetRateAgreementSuccess
	| GetRateAgreementFailure
	| SchoolLoadCounties
	| SchoolLoadCountiesSuccess
	| SchoolAddressLookupCityState
	| SchoolAddressLookupCityStateSuccess
	| SchoolAddressLookupCityStateFailure
	| SchoolVerifyAddress
	| SchoolVerifyAddressSuccess
	| SchoolVerifyAddressFailure
	| UpdateEditorState
	| UpdateSchoolPanel
	| UpdateSchoolPanelSuccess
	| UpdateSchoolPanelFailure
	| ValidateExclusiveServiceAccountCode
	//| ValidateRateAgreement
	//| RateAgreementValidated
	| CreateSchool
	| CreateSchoolSuccess
	| CreateSchoolFailure
	| ValidateSchoolPrimaryId
	| ValidateSchoolExists
	| ValidateSchoolCanDeactivate
	| SchoolPrimaryIdValidated
	| SchoolExistsValidated
	| SchoolCanDeactivateValidated
	| SchoolPrimaryIdValidationFailed;
