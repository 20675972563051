import { Action } from '@ngrx/store';

import { EventSearchResultsItem, EventSearchCriteria, EventPagingSorting } from '../../models';
import { HttpHeaders } from '@angular/common/http';
import { SchoolDetails } from '../../../schools/models';

export enum EventSearchResultsActionTypes {
    ProcessQueryString = '[EventSearchResults] Process Query String',
	LoadEventSearchResults = '[EventSearchResults] Load Search Results',
    LoadEventSearchResultsSuccess = '[EventSearchResults] Load Event Success',
    LoadEventSearchResultsFailure = '[EventSearchResults] Load Event Details Failure',
    UpdateEditorState = '[EventSearchResults] Update Editor State',
    UpdateEventInfo = '[EventSearchResults] Update Event Info',
    UpdateEventInfoSuccess = '[EventSearchResults] Update Event Info Success',
    UpdateEventInfoFailure = '[EventSearchResults] Update Event Info Failure',
    NextPage = '[EventSearchResults] Next Page',
    PrevPage = '[EventSearchResults] Prev Page',
    SetPageSize = '[EventSearchResults] Set Page Size',
    LastPage = '[EventSearchResults] Last Page',
    FirstPage = '[EventSearchResults] First Page',
    SortBy = '[EventSearchResults] Sort By',
    GetResultsForSchool = '[EventSearchResults] Get Results for School',
}

export class ProcessQueryString implements Action {
    readonly type = EventSearchResultsActionTypes.ProcessQueryString;
    constructor(public payload: string) { }
}

export class LoadEventSearchResults implements Action {
    readonly type = EventSearchResultsActionTypes.LoadEventSearchResults;
    constructor(public payload: EventSearchCriteria) { }
}

export class LoadEventSearchResultsSuccess implements Action {
    readonly type = EventSearchResultsActionTypes.LoadEventSearchResultsSuccess;
    constructor(public payload: EventSearchResultsItem[], public httpHeaders: HttpHeaders) { }
}

export class LoadEventSearchResultsFailure implements Action {
    readonly type = EventSearchResultsActionTypes.LoadEventSearchResultsFailure;
	constructor(public payload: any) { }
}

export class NextPage implements Action {
    readonly type = EventSearchResultsActionTypes.NextPage;
    constructor(public payload: EventSearchCriteria, public pagingSorting: EventPagingSorting) { }
}

export class PrevPage implements Action {
    readonly type = EventSearchResultsActionTypes.PrevPage;
    constructor(public payload: EventSearchCriteria, public pagingSorting: EventPagingSorting) { }
}

export class SetPageSize implements Action {
    readonly type = EventSearchResultsActionTypes.SetPageSize;
    constructor(public payload: number, public searchCriteria: EventSearchCriteria, ) { }
}

export class LastPage implements Action {
    readonly type = EventSearchResultsActionTypes.LastPage;
    constructor(public payload: EventSearchCriteria, public pagingSorting: EventPagingSorting) { }
}

export class FirstPage implements Action {
    readonly type = EventSearchResultsActionTypes.FirstPage;
    constructor(public payload: EventSearchCriteria, public pagingSorting: EventPagingSorting) { }
}

export class SortBy implements Action {
    readonly type = EventSearchResultsActionTypes.SortBy;
    constructor(public payload: string, public searchCriteria: EventSearchCriteria, public pagingSorting: EventPagingSorting) { }
}

export class GetResultsForSchool implements Action {
    readonly type = EventSearchResultsActionTypes.GetResultsForSchool;
    constructor(public payload: SchoolDetails, public searchCriteria: EventSearchCriteria) { }
}

export type EventSearchResultsActions =
    | ProcessQueryString
    | LoadEventSearchResults
    | LoadEventSearchResultsSuccess
    | LoadEventSearchResultsFailure
    | NextPage
    | PrevPage
    | SetPageSize
    | LastPage
    | FirstPage
    | SortBy
    | GetResultsForSchool;
