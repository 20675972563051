import { ProgramDetailsComponent } from './program-details.component';
import { ProgramSearchResultsComponent } from './program-searchresults.component';
import { ProgramInfoEditorComponent } from './program-info-editor.component';
import { ProgramProgramTeachersEditorComponent } from './program-program-teachers-editor.component';
import { ProgramAdditionalEditorComponent } from './program-additional-editor.component';
import { ProgramCreateComponent } from './program-create.component';

export const containers: any[] = [
    ProgramDetailsComponent,
    ProgramSearchResultsComponent,
    ProgramInfoEditorComponent,
    ProgramAdditionalEditorComponent,
	ProgramProgramTeachersEditorComponent,
	ProgramCreateComponent,
];

export * from './program-details.component';
export * from './program-searchresults.component';
export * from './program-info-editor.component';
export * from './program-additional-editor.component';
export * from './program-program-teachers-editor.component';
export * from './program-create.component';
