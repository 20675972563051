<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="isActive">Active</div>
        <div class="property-block__value" role="definition" aria-labelledby="isActive">{{ isActive | yesNo }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="!isActive">
        <div class="property-block__label" id="inactiveReason">Inactive Reason</div>
        <div class="property-block__value" role="definition">{{ inactiveReason }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="displayPrimaryId">
        <div class="property-block__label" id="primaryId">Primary ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="primaryId">{{ primaryId }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="id">District ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="id">{{ id }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="longName">Long Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="longName">{{ longName }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">Short Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ shortName }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="isSupervisoryDistrict">Supervisory District</div>
        <div class="property-block__value" role="definition" aria-labelledby="isSupervisoryDistrict">{{ isSupervisory | yesNo }}</div>
    </li>
    <ng-container *ngIf="!isSupervisory && supervisoryDistrictId">
        <li class="property-block__list-item">
            <div class="property-block__label" id="supervisoryDistrictId">Supervisory District ID</div>
            <div class="property-block__value" role="definition" aria-labelledby="supervisoryDistrictId"><a [routerLink]="['/districts', supervisoryDistrictId]">{{ supervisoryDistrictId }}</a></div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="supervisoryDistrictName">Supervisory District Name</div>
            <div class="property-block__value" role="definition" aria-labelledby="supervisoryDistrictName"><a [routerLink]="['/districts', supervisoryDistrictId]">{{ supervisoryDistrictName }}</a></div>
        </li>
    </ng-container>
</ul>
