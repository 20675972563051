import { Injectable } from '@angular/core';

import { tap, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { State } from '../../../../core/store';
import * as scpActions from '../actions';
import * as fromServices from '../../services';
import * as fromSelectors from '../selectors';
import { RentalCategory } from '../../models';
import { TaxonomyState } from '..';

@Injectable()
export class TaxonomyEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private taxonomyService: fromServices.TaxonomyService,
    ) { }

    
    getRentalCategories$ = createEffect(() => this.actions$.pipe(
        ofType(scpActions.ScpActionTypes.GetRentalCategories),
        tap(a => console.log('Entering getRentalCategories$ Effect: ', a)),
        withLatestFrom(this.store.pipe(select(fromSelectors.getTaxonomyState))),
        map(([action, taxonomyState]: [scpActions.GetRentalCategories, TaxonomyState]) => {
            return { action, taxonomyState };
        }),
        switchMap(({ action, taxonomyState }: { action: scpActions.GetRentalCategories, taxonomyState: TaxonomyState }) => {
            if (taxonomyState && taxonomyState.rentalCategories) {
                return of(new scpActions.GetRentalCategoriesSuccess({ rentalCategories: taxonomyState.rentalCategories }));
            }

            return this.taxonomyService.loadRentalCategories().pipe(
                map((rentalCategories: RentalCategory[]) => new scpActions.GetRentalCategoriesSuccess({ rentalCategories: rentalCategories }))
            );
        }),
        catchError(err => {
            return of(new scpActions.GetRentalCategoriesFailure({ error: err }));
        }),
        tap(a => console.log('Exiting getRentalCategories$ Effect: ', a)),
    ));
}
