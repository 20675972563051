<div class="row align-center u-m-t-40">
    <div class="columns small-8 large-6">
        <section class="card card--error">
            <div class="card__section">
                <h1 class="card__title card__title--large">404 Not Found</h1>
            </div>
            <hr class="card__divider">
            <div class="card__section typography--body">
                <p>The requested resource cannot be found.</p>
                <p>If you feel you have received this in error, please contact support.</p>                
            </div>
            <div class="card__section">
                <a class="btn btn--small" routerLink="/">Go Home</a>
            </div>
        </section>
    </div>
</div>