import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromTeacherDetails from './teacher-details.reducer';
import * as fromSearchResults from './searchresults.reducer';

export interface TeachersState {
    teacherDetails: fromTeacherDetails.TeacherDetailsState;
    search: fromSearchResults.SearchResultsState;
}

export const reducers: ActionReducerMap<TeachersState> = {
    teacherDetails: fromTeacherDetails.reducer,
    search: fromSearchResults.reducer
};

export const getTeachersState = createFeatureSelector<TeachersState>('teachers');

export { TeacherDetailsState, PanelId, PanelState, AddressPanelState, ZipLookupState, CountyLookupState, DistrictLookupState, DistrictByNameLookupState } from './teacher-details.reducer';
export { SearchResultsState, initialState } from './searchresults.reducer';
