<div class="row align-center u-m-b-40">
	<div class="columns small-10">
		<div class="stepper" role="menu">
			<div class="stepper__step" *ngFor="let tab of tabs; let i = index" [ngClass]="{ 'stepper__step--complete': activeTabIndex > i, 'stepper__step--active': activeTabIndex == i }">
				<a class="stepper__step-link" (click)="onSelectStep(i)" role="menuitem">
					<div class="stepper__step-icon"></div>
					<div class="stepper__step-label">{{ tab.name }}</div>
				</a>
			</div>
		</div>
	</div>
</div>

<div class="card">
	<div class="card__section">
		<ng-content></ng-content>
	</div>
</div>
