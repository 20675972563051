import * as fromActions from '../actions/merch.actions';
import * as fromModels from '../../models';

export interface ClassCombos {
	loading: boolean;
  combos: fromModels.InstrumentCombo[];
}

export interface ProductGroups {
  loading: boolean;
  products: fromModels.Product[];
}

export interface CombosState {
	loadingClasses: boolean;
	loadedClasses: boolean;
	loadingProductGroups: boolean;
	loadedProductGroups: boolean;
	loadingRentalCategories: boolean;
	loadedRentalCategories: boolean;
	loadingRentalCategoryDetails: boolean;
	loadedRentalCategoryDetails: boolean;
	updating: boolean;
	deleting: boolean;
	errors: any[];
	classes: fromModels.InstrumentClass[];
	classCombos: { [classId: number]: ClassCombos };
	productGroups: fromModels.ProductGroup[];
	products: { [productGroupId: number]: ProductGroups };
	rentalCategories: fromModels.RentalCategory[];
	rentalCategoryDetails: fromModels.RentalCategory;
}

export const initialState: CombosState = {
	loadingClasses: false,
	loadedClasses: false,
	loadingProductGroups: false,
	loadedProductGroups: false,
	loadingRentalCategories: false,
	loadedRentalCategories: false,
	loadingRentalCategoryDetails: false,
	loadedRentalCategoryDetails: false,
	updating: false,
	deleting: false,
	errors: null,
	classes: null,
	classCombos: {},
	productGroups: null,
	products: {},
	rentalCategories: null,
	rentalCategoryDetails: null
};

export function reducer(state = initialState, action: fromActions.MerchActions): CombosState {
	switch (action.type) {
		case fromActions.MerchActionTypes.LoadClasses: {
			return {
				...state,
				loadingClasses: true,
				loadedClasses: false,
				classes: null,
			};
		}

		case fromActions.MerchActionTypes.LoadClassesSuccess: {
			return {
				...state,
				loadingClasses: false,
				loadedClasses: true,
				errors: null,
				classes: [...action.payload],
			};
		}

		case fromActions.MerchActionTypes.LoadClassesFailure: {
			return {
				...state,
				loadingClasses: false,
				loadedClasses: false,
				errors: action.payload,
				classes: null,
			};
		}

		case fromActions.MerchActionTypes.LoadCombos: {
			return {
				...state,
				errors: null,
				classCombos: {
					...state.classCombos,
					[action.payload]: {
						...state.classCombos[action.payload],
						loading: true,
					},
				},
			};
		}

		case fromActions.MerchActionTypes.LoadCombosSuccess: {
			return {
				...state,
				classCombos: {
					...state.classCombos,
					[action.payload.classId]: {
						combos: action.payload.combos || [],
						loading: false,
					},
				},
			};
		}

		case fromActions.MerchActionTypes.LoadCombosFailure: {
			const { [action.payload.classId]: removed, ...classCombos } = state.classCombos;
			const errors = action.payload.error;

			return {
				...state,
				errors,
				classCombos,
			};
		}

		case fromActions.MerchActionTypes.LoadProductGroups: {
		return {
			...state,
			loadingProductGroups: true,
			loadedProductGroups: false,
			productGroups: null,
		};
		}

		case fromActions.MerchActionTypes.LoadProductGroupsSuccess: {
		return {
			...state,
			loadingProductGroups: false,
			loadedProductGroups: true,
			errors: null,
			productGroups: [...action.payload],
		};
		}

		case fromActions.MerchActionTypes.LoadProductGroupsFailure: {
		return {
			...state,
			loadingProductGroups: false,
			loadedProductGroups: false,
			errors: action.payload,
			productGroups: null,
		};
		}

		case fromActions.MerchActionTypes.LoadProducts: {
		return {
			...state,
			errors: null,
			products: {
				...state.products,
				[action.payload]: {
					...state.products[action.payload],
					loading: true,
				},
			},
		};
		}

		case fromActions.MerchActionTypes.LoadProductsSuccess: {
		return {
			...state,
			products: {
			...state.products,
			[action.payload.productGroupId]: {
				products: action.payload.products || [],
				loading: false,
			},
			},
		};
		}

		case fromActions.MerchActionTypes.LoadProductsFailure: {
			const { [action.payload.productGroupId]: removed, ...products } = state.products;
			const errors = action.payload.error;

			return {
				...state,
				errors,
				products,
			};
		}

		case fromActions.MerchActionTypes.LoadRentalCategoryDetails: {
			return {
                ...state,
				loadingRentalCategoryDetails: true,
				loadedRentalCategoryDetails: false,
				rentalCategoryDetails: null
            };
		}

		case fromActions.MerchActionTypes.LoadRentalCategoryDetailsSuccess: {
			const { rentalCategory } = action.payload;
			return {
				...state,
				loadingRentalCategoryDetails: false,
				loadedRentalCategoryDetails: true,
				errors: null,
				rentalCategoryDetails: rentalCategory
			};
		}

		case fromActions.MerchActionTypes.LoadRentalCategoryDetailsFailure: {
			const { error } = action.payload;
			return {
				...state,
				loadingRentalCategoryDetails: false,
				loadedRentalCategoryDetails: true,
				errors: error
			};
		}

		case fromActions.MerchActionTypes.LoadRentalCategories: {
            return {
                ...state,
				loadingRentalCategories: true,
				loadedRentalCategories: false,
				rentalCategories: null
            };
        }

		case fromActions.MerchActionTypes.LoadRentalCategoriesSuccess: {
			const { rentalCategories } = action.payload;
			return {
				...state,
				loadingRentalCategories: false,
				loadedRentalCategories: true,
				errors: null,
				rentalCategories: rentalCategories
			};
		}

		case fromActions.MerchActionTypes.LoadRentalCategoriesFailure: {
			const { error } = action.payload;
			return {
				...state,
				loadingRentalCategories: false,
				loadedRentalCategories: true,
				errors: error
			};
		}

		case fromActions.MerchActionTypes.SetComboAvailability: {
			return {
				...state,
				updating: true,
				errors: null,
			};
		}

		case fromActions.MerchActionTypes.SetComboAvailabilitySuccess: {
			return {
				...state,
				updating: false,
				classCombos: {
					...state.classCombos,
					[action.payload.classId]: {
						...state.classCombos[action.payload.classId],
						combos: state.classCombos[action.payload.classId].combos.map(c => {
							if (action.payload.ids.includes(c.id)) {
								return {
									...c,
									isAvailable: action.payload.available,
								};
							}

							return c;
						}),
					},
				},
			};
		}

		case fromActions.MerchActionTypes.SetComboAvailabilityFailure: {
			return {
				...state,
				updating: false,
				errors: action.payload.error,
				classCombos: {
					...state.classCombos,
					[action.payload.classId]: {
						...state.classCombos[action.payload.classId],
						combos: state.classCombos[action.payload.classId].combos.filter(c => {
							return {
								...c,
							};
						}),
					},
				},
			};
		}

		case fromActions.MerchActionTypes.DeleteCombo: {
			return {
				...state,
				deleting: true,
				errors: null,
			};
		}

		case fromActions.MerchActionTypes.DeleteComboSuccess: {
			return {
				...state,
				deleting: false,
				classCombos: {
					...state.classCombos,
					[action.payload.classId]: {
						...state.classCombos[action.payload.classId],
						combos: state.classCombos[action.payload.classId].combos.filter(c => c.id != action.payload.id),
					},
				},
			};
		}

		case fromActions.MerchActionTypes.DeleteComboFailure: {
			return {
				...state,
				deleting: false,
				errors: action.payload.error,
			};
		}

		default: {
			if (Object.values(fromActions.MerchActionTypes).includes((action as fromActions.MerchActions).type)) {
				console.log('merch.reducer: unhandled action: ', action);
			}
		}
	}

	return state;
}

