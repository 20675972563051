import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap } from 'rxjs/operators';

import { ProductGroup } from '../../preferences/models';

@Injectable()
export class ProductGroupService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	loadProductGroups(): Observable<ProductGroup[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<ProductGroup[]>(`${c.config.apiServer}api/productgroups`);
			})
		);
	}
}
