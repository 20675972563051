import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, tap, filter, map, take, mergeMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';

@Injectable()
export class EventExistsGuard implements CanActivate {
    constructor(private _store: Store<fromStore.EventsState>) {
		console.log('EventExistsGuard()...');
    }

    private _statusCode = 0;

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		//	get the id of the district we need to load from the route
		const id = +route.paramMap.get('id');
		//	check for a specific redirect route to use if the district isn't found
		let redirectTo = !!route.data.redirectTo ? route.data.redirectTo : '/';

        console.log(`EventExistsGuard: canActivate called for Event id: ${id}...`);

		//	this will attempt to load the event by dispatching the appropriate action then either redirect or allow the activation
		return this.checkStore(id).pipe(
            switchMap(found => {
                if (!found) {
                    switch (this._statusCode) {
                        case 401: {
                            console.log(`EventExistsGuard: unauthorized request.`);
                            redirectTo = '/401';
                            break;
                        }
                        case 500: {
                            console.log(`EventExistsGuard: checkStore returned: Unexpected Error.`);
                            redirectTo = '/500';
                            break;
                        }
                        default: {
                            console.log(`EventExistsGuard: checkStore returned: Not Found.`);
                            break;
                        }
                    }
                }
                else
                    console.log(`EventExistsGuard: checkStore returned: Found.`);

				return this.allowOrRedirect(found, redirectTo);
			}),
			catchError(err => {
				console.log(`DistrictExistsGuard: activation failed for district ${id}.  Error: `, err);

				return this.allowOrRedirect(false, '/500');
			}),
		);
	}

    checkStore(id: number): Observable<boolean> {

        return this._store.select(fromRoot.getUserAuthInfo).pipe(
            filter(x => !!x),
            mergeMap(x => {

                if (!x.policyCodes.has('Event.ViewDetails')) {
                    this._statusCode = 401;
                    return of(false);
                }

                //	start with the district details state...
                return this._store.pipe(
                    select(fromStore.getEventsDetailsState),
                    //	if there aren't district details, or they are for a different district, dispatch an action to load the district
                    tap(state => {
                        if (state.errors && state.errors.status !== 404)
                            this._statusCode = 500;
                        else if ((state.event === null || state.event.id != id || !state.loaded) && !state.loading) {
                            console.log(`DistrictExistsGuard: Loading event ${id}.  Current Event Details state: `, state);
                            this._store.dispatch(new fromStore.LoadEvent(id));
                            this._store.dispatch(new fromStore.LoadRelationshipManagers({ id: id}));
                        }
                    }),
                    //	wait for it to be in the loaded state for the district in question or for any errors
                    filter(state => (state.loaded && state.event.id == id) || state.errors !== null),
                    //	map it to a boolean observable
                    map(state => state.loaded),
                    //	get the first result & complete
                    take(1),
                );
            })
        );


	}

	allowOrRedirect(found: boolean, redirectTo: string): Observable<boolean> {
		if (!found) {
			console.log(`Redirecting to '${redirectTo}'...`);
			this._store.dispatch(new fromRoot.Go({ path: [redirectTo] }));
		}

		return of(found);
	}
}
