import * as fromActions from '../actions/prefs.actions';
import * as fromModels from '../../models';
import { OpusCategory } from '../../../scp-common/models';
import * as fromSchoolModels from '../../../schools/models';
import * as fromDistrictModels from '../../../districts/models';
import { HttpErrorResponse } from '@angular/common/http';

export enum AddSuppliesStep {
	Find,
	Select,
	Configure,
	Confirm,
}

export enum FindType {
	Group,
	Book,
	Keyword,
}

export interface GroupFind {
	productGroupId?: number;
}

export interface BookFind {
	categoryId?: number;
	subCategoryId?: number;
	levelId?: number;
	productName: string;
}

export interface KeywordFind {
	variantId: string;
	brandName: string;
	productName: string;
	optionName: string;
	modelNo: string;
}

export interface FindCriteria {
	findType: FindType;
	groupFind: GroupFind;
	bookFind: BookFind;
	keywordFind: KeywordFind;
}

export interface AddSuppliesState {
	initialized: boolean;
	initializing: boolean;
	errors: any;
	currentStep: AddSuppliesStep;
	find: FindCriteria;
	finding: boolean;
	productGroups: fromModels.ProductGroup[];
	bookCategories: OpusCategory[];
    products: { [id: number]: fromModels.ProductSearchResult };
	supplyItems: { [id: number]: fromModels.SupplyItem };
}

export interface SchoolLookupState {
    schoolId: number;
    pending: boolean;
    errors: any;
    school: fromSchoolModels.SchoolLookup;
}

export interface SchoolByNameLookupState {
    name: string;
    pending: boolean;
    errors: any;
    schools: fromSchoolModels.SchoolLookup[];
}

export interface DistrictLookupState {
    districtId: number;
    pending: boolean;
    errors: any;
    district: fromDistrictModels.DistrictLookup;
}

export interface DistrictByNameLookupState {
    name: string;
    pending: boolean;
    errors: any;
    districts: fromDistrictModels.DistrictLookup[];
}

export const initialSchoolLookupState: SchoolLookupState = {
    schoolId: null,
    pending: false,
    school: null,
    errors: null,
};

export const initialSchoolByNameLookupState: SchoolByNameLookupState = {
    name: null,
    pending: false,
    errors: null,
    schools: null
};

export const initialDistrictLookupState: DistrictLookupState = {
    districtId: null,
    pending: false,
    district: null,
    errors: null,
};

export const initialDistrictByNameLookupState: DistrictByNameLookupState = {
    name: null,
    pending: false,
    errors: null,
    districts: null
};


export interface LandingState {
	loading: boolean;
    loaded: boolean;
    updating: boolean;
    updated: boolean;
	errors: any;
	preferenceSet: fromModels.PreferenceSet;
    addSupplies: AddSuppliesState;
    schoolLookup: SchoolLookupState;
    schoolByNameLookup: SchoolByNameLookupState;
    districtLookup: DistrictLookupState;
	districtByNameLookup: DistrictByNameLookupState;
	ratesRequired: boolean;
	ratesLoading: boolean;
	ratesLoaded: boolean;
    rates: fromModels.InstrumentRate[];
	ratesError: HttpErrorResponse;
    actionType: fromActions.PrefsActionTypes;
}

export const initialFindCriteria: FindCriteria = {
	findType: FindType.Group,
	groupFind: {
		productGroupId: null
	},
	bookFind: {
		categoryId: null,
		subCategoryId: null,
		levelId: null,
		productName: null
	},
	keywordFind: {
		variantId: null,
		brandName: null,
		productName: null,
		optionName: null,
		modelNo: null,
	},
};

export const initialAddSuppliesState: AddSuppliesState = {
	initialized: false,
	initializing: false,
	errors: null,
	currentStep: AddSuppliesStep.Find,
	find: initialFindCriteria,
	finding: false,
	productGroups: null,
	bookCategories: null,
	products: null,
	supplyItems: null,
};

export const initialState: LandingState = {
	loading: false,
	loaded: false,
	updating: false,
	updated: false,
	errors: null,
	preferenceSet: null,
	addSupplies: initialAddSuppliesState,
	schoolLookup: initialSchoolLookupState,
	schoolByNameLookup: initialSchoolByNameLookupState,
	districtLookup: initialDistrictLookupState,
	districtByNameLookup: initialDistrictByNameLookupState,
	ratesRequired: false,
	ratesLoading: false,
	ratesLoaded: false,
    rates: [],
	ratesError: null,
    actionType: null,
};

export function reducer(state = initialState, action: fromActions.PrefsActions): LandingState {
	switch (action.type) {
		case fromActions.PrefsActionTypes.LoadPrefs: {
			return {
				...state,
				loading: true,
				loaded: false,
				errors: null,
			};
		}

		case fromActions.PrefsActionTypes.LoadPrefsSuccess: {
			return {
				...state,
				loaded: true,
				loading: false,
				errors: null,
				preferenceSet: action.payload,
				addSupplies: initialAddSuppliesState,
			};
		}

		case fromActions.PrefsActionTypes.LoadPrefsFailure: {
			return {
				...state,
				loaded: false,
				loading: false,
				errors: action.payload,
			};
        }

        case fromActions.PrefsActionTypes.UpdatePrefsPanel: {
            return {
                ...state,
				updating: true,
				updated: false,
            };
        }

        case fromActions.PrefsActionTypes.UpdatePrefsPanelFailure: {
            return {
                ...state,
				updating: false,
				updated: false,
                errors: action.payload,
            };
        }

        case fromActions.PrefsActionTypes.UpdatePrefsPanelSuccess: {
            return {
                ...state,
                updated: true,
                updating: false,
                errors: null,
                preferenceSet: action.payload.preferenceSet,
            };
        }

        case fromActions.PrefsActionTypes.CreateOverridePrefs: {
            return {
                ...state,
				updating: true,
                updated: false,
                actionType: action.type
            };
        }

        case fromActions.PrefsActionTypes.CreateOverridePrefsFailure: {
            return {
                ...state,
				updating: false,
                updated: false,
                actionType: action.type,
                errors: action.payload,
            };
        }

        case fromActions.PrefsActionTypes.CreateOverridePrefsSuccess: {
            return {
                ...state,
				updated: true,
                updating: false,
                actionType: action.type,
				errors: null,
                preferenceSet: action.payload,
            };
        }

        case fromActions.PrefsActionTypes.CopyPrefs: {
            return {
                ...state,
				updating: true,
				updated: false,
            };
        }

        case fromActions.PrefsActionTypes.CopyPrefsFailure: {
            return {
                ...state,
				updating: false,
				updated: false,
                errors: action.payload,
            };
        }

        case fromActions.PrefsActionTypes.CopyPrefsSuccess: {
            return {
                ...state,
				updated: true,
				updating: false,
				errors: null,
            };
        }

        case fromActions.PrefsActionTypes.DeletePrefs: {
            return {
                ...state,
				updating: true,
                updated: false,
                actionType: action.type
            };
        }

        case fromActions.PrefsActionTypes.DeletePrefsFailure: {
            return {
                ...state,
				updating: false,
                updated: false,
                actionType: action.type,
                errors: action.payload,
            };
        }

        case fromActions.PrefsActionTypes.DeletePrefsSuccess: {
            return {
                ...state,
				updated: true,
				updating: false,
                errors: null,
                actionType: action.type,
                preferenceSet: action.payload,
            };
        }

		case fromActions.PrefsActionTypes.InitializeAddSupplies: {
			return {
				...state,
				addSupplies: {
					...initialAddSuppliesState,
					initialized: false,
					initializing: true,
				},
			};
		}

		case fromActions.PrefsActionTypes.AddSuppliesInitialized: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					initialized: true,
					initializing: false,
				},
			};
		}

		case fromActions.PrefsActionTypes.BookCategoriesLoaded: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					bookCategories: action.payload,
				},
			};
		}

		case fromActions.PrefsActionTypes.ProductGroupsLoaded: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					productGroups: action.payload,
				},
			};
		}

		case fromActions.PrefsActionTypes.FindProducts: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					finding: true,
					find: action.payload,
					products: {},
					supplyItems: {},
				},
			};
		}

		case fromActions.PrefsActionTypes.FindProductsSuccess: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					finding: false,
					currentStep: AddSuppliesStep.Select,
					products: action.payload,
				},
			};
		}

		case fromActions.PrefsActionTypes.FindProductsFailure: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					finding: false,
					currentStep: AddSuppliesStep.Find,
					errors: action.payload,
				},
			};
		}

		case fromActions.PrefsActionTypes.UpdateSupplyItems: {
			const supplies = action.payload.reduce((result, si) => {
				result[si.product.id] = si;

				return result;
			}, {});

			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					supplyItems: {
						...state.addSupplies.supplyItems,
						...supplies,
					},
				},
			};
		}

		case fromActions.PrefsActionTypes.RemoveSupplyItems: {
			const supplyItems = action.payload.reduce((result, id) => {
				const { [id]: removed, ...rest } = result;

				return rest;
			}, state.addSupplies.supplyItems);

			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					supplyItems,
				},
			};
		}

		case fromActions.PrefsActionTypes.SelectAddSuppliesStep: {
			return {
				...state,
				addSupplies: {
					...state.addSupplies,
					currentStep: action.payload,
				}
			};
		}

        case fromActions.PrefsActionTypes.SchoolLookupById: {
            const { schoolId } = action.payload;
            const schoolLookup: SchoolLookupState = {
                schoolId,
                pending: true,
                errors: null,
                school: null,
            };

            return {
                ...state,
                schoolLookup
            };
        }

        case fromActions.PrefsActionTypes.SchoolLookupByIdFailure: {
            const { errors } = action.payload;
            const schoolLookup: SchoolLookupState = {
                ...state.schoolLookup,
                pending: false,
                errors,
                school: null,
            };

            return {
                ...state,
                schoolLookup,
            };
        }

        case fromActions.PrefsActionTypes.SchoolLookupByIdSuccess: {
            const { school } = action.payload;
            const schoolLookup: SchoolLookupState = {
                ...state.schoolLookup,
                pending: false,
                school,
            };

            return {
                ...state,
                schoolLookup,
            };
        }

        case fromActions.PrefsActionTypes.SchoolLookupByName: {
            const { name } = action.payload;
            const schoolByNameLookup: SchoolByNameLookupState = {
                name,
                pending: true,
                errors: null,
                schools: null,
            };

            return {
                ...state,
                schoolByNameLookup,
            };
        }

        case fromActions.PrefsActionTypes.SchoolLookupByNameFailure: {
            const { errors } = action.payload;
            const schoolByNameLookup: SchoolByNameLookupState = {
                ...state.schoolByNameLookup,
                pending: false,
                errors,
                schools: null,
            };

            return {
                ...state,
                schoolByNameLookup,
            };
        }

        case fromActions.PrefsActionTypes.SchoolLookupByNameSuccess: {
            const { schools } = action.payload;
            const schoolByNameLookup: SchoolByNameLookupState = {
                ...state.schoolByNameLookup,
                pending: false,
                schools,
            };

            return {
                ...state,
                schoolByNameLookup,
            };
        }

        //DISTRICT LOOKUP

        case fromActions.PrefsActionTypes.DistrictLookupById: {
            const { districtId } = action.payload;
            const districtLookup: DistrictLookupState = {
                districtId,
                pending: true,
                errors: null,
                district: null,
            };

            return {
                ...state,
                districtLookup
            };
        }

        case fromActions.PrefsActionTypes.DistrictLookupByIdFailure: {
            const { errors } = action.payload;
            const districtLookup: DistrictLookupState = {
                ...state.districtLookup,
                pending: false,
                errors,
                district: null,
            };

            return {
                ...state,
                districtLookup,
            };
        }

        case fromActions.PrefsActionTypes.DistrictLookupByIdSuccess: {
            const { district } = action.payload;
            const districtLookup: DistrictLookupState = {
                ...state.districtLookup,
                pending: false,
                district,
            };

            return {
                ...state,
                districtLookup,
            };
        }

        case fromActions.PrefsActionTypes.DistrictLookupByName: {
            const { name } = action.payload;
            const districtByNameLookup: DistrictByNameLookupState = {
                name,
                pending: true,
                errors: null,
                districts: null,
            };

            return {
                ...state,
                districtByNameLookup,
            };
        }

        case fromActions.PrefsActionTypes.DistrictLookupByNameFailure: {
            const { errors } = action.payload;
            const districtByNameLookup: DistrictByNameLookupState = {
                ...state.districtByNameLookup,
                pending: false,
                errors,
                districts: null,
            };

            return {
                ...state,
                districtByNameLookup,
            };
        }

        case fromActions.PrefsActionTypes.DistrictLookupByNameSuccess: {
            const { districts } = action.payload;
            const districtByNameLookup: DistrictByNameLookupState = {
                ...state.districtByNameLookup,
                pending: false,
                districts,
            };

            return {
                ...state,
                districtByNameLookup,
            };
        }

		case fromActions.PrefsActionTypes.GetRates: {
			return {
				...state,
				ratesRequired: true,
				ratesLoading: true,
				ratesLoaded: false,
				rates: null,
				ratesError: null,
			};
		}

		case fromActions.PrefsActionTypes.GetRatesSuccess: {
			return {
				...state,
				ratesLoading: false,
				ratesLoaded: true,
				rates: action.payload,
			};
		}

		case fromActions.PrefsActionTypes.GetRatesFailure: {
			return {
				...state,
				ratesLoading: false,
				ratesLoaded: false,
				ratesError: action.payload,
			};
		}

		default: {
			if (Object.values(fromActions.PrefsActionTypes).includes(action.type)) {
				console.log('landing.reducer: unhandled action: ', action);
			}
		}
	}

    return state;
}

export const getLandingPreferenceSet = (state: LandingState) => state.preferenceSet;
export const getAddSuppliesState = (state: LandingState) => state.addSupplies;
export const getProductGroups = (state: AddSuppliesState) => state.productGroups;
export const getBookCategories = (state: AddSuppliesState) => state.bookCategories;
export const getStateSchoolLookup = (state: LandingState) => state.schoolLookup;
export const getStateSchoolByNameLookup = (state: LandingState) => state.schoolByNameLookup;
export const getStateDistrictLookup = (state: LandingState) => state.districtLookup;
export const getStateDistrictByNameLookup = (state: LandingState) => state.districtByNameLookup;
