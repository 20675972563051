import { Component, Input } from '@angular/core';

@Component({
	selector: 'opus-history-details',
	templateUrl: './history-details.component.html',
})
export class HistoryDetailsComponent {
	@Input() event;
}

