import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromLanding from './landing.reducer';

export interface PrefsState {
	landingState: fromLanding.LandingState;
}

export const reducers: ActionReducerMap<PrefsState> = {
	landingState: fromLanding.reducer,
};

export const getPrefsState = createFeatureSelector<PrefsState>('preferences');

export { LandingState, AddSuppliesState, FindCriteria, FindType, GroupFind, BookFind, KeywordFind, AddSuppliesStep, SchoolLookupState, SchoolByNameLookupState, DistrictLookupState, DistrictByNameLookupState } from './landing.reducer';
