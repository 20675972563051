import { Injectable } from '@angular/core';

import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as configurationActions from '../actions/configuration.action';
import * as fromServices from '../../services';
import { CoreState } from '..';

@Injectable()
export class ConfigurationEffects {
    constructor(
        private actions$: Actions,
        private configurationService: fromServices.ConfigurationHttpService,
        private store$: Store<CoreState>,
    ) { }

    
    loadConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(configurationActions.ConfigurationActionTypes.LoadConfiguration),
        switchMap((action: configurationActions.LoadConfiguration) => {
            console.log('Load Configuration Effect');
            return this.configurationService.loadConfiguration().pipe(
                tap(d => {
                    console.log('loadConfiguration returned:', d);
                }),
                map(event => {
                    if (event === null) {
                        return new configurationActions.LoadConfigurationFailure('Configuration Not Found');
                    }

                    return new configurationActions.LoadConfigurationSuccess(event);
                }),
                catchError(error => of(new configurationActions.LoadConfigurationFailure(error)))
            );
        })
    ));

}
