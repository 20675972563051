import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const HOME = '[Router] Home';
export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';

export class Go implements Action {
	readonly type = GO;
	constructor(public payload: {
		path: any[];
		query?: object;
		extras?: NavigationExtras;
	}) { }
}

export class Home implements Action {
    readonly type = HOME;
}

export class Back implements Action {
	readonly type = BACK;
}

export class Forward implements Action {
	readonly type = FORWARD;
}

export type Actions = Go | Back | Forward;
