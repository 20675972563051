import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as validators from '../../../core/validators';

import { Event } from '../models';
import * as fromStore from '../store';

@Component({
  selector: 'event-meeting-details-editor',
  templateUrl: './event-meeting-details-editor.component.html',
})
export class EventMeetingDetailsEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  alive = true;
  event$: Observable<Event>;
  event: Event;
  panelState$: Observable<fromStore.PanelState>;
  panelState: fromStore.PanelState;

  rentalMeetingTypes: any[] = [
    {
      id: 1,
      name: 'Schedule or Assembly'
    },
    {
      id: 2,
      name: 'Open or Random'
    }
  ];
  rentalFulfillmentTypes: any[] = [
    { id: 1, name: 'Filled - Pick up at Event' },
    { id: 2, name: 'Unfilled - Filled by Hub' },
    { id: 3, name: 'Unfilled - Filled by Rep' },
  ];

  editor: FormGroup;

  constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
    this.event$.pipe(
      takeWhile(() => this.alive)
    ).subscribe(d => this.event = d);

    this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventMeetingDetailsPanel));
    this.panelState$.pipe(
      takeWhile(() => this.alive),
      tap(s => {
        if (!s.editing && this.panelState && this.panelState.editing) {
          this.resetForm();
        }
      }),
    ).subscribe(s => this.panelState = s);

    this.createForm();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.alive = false;
  }

  createForm() {
    this.editor = new FormGroup({
      eventQuality: new FormControl(this.event.eventQuality),
      deliveryTimePeriod: new FormControl(this.event.deliveryTimePeriod),
      rentalMeetingType: new FormControl(this.event.rentalMeetingTypeId, Validators.required),
      rentalFulfillmentType: new FormControl(this.event.rentalFulfillmentTypeId, Validators.required),
      mouthpieceTesting: new FormControl(this.event.mouthpieceTesting),
      bandProgramAttending: new FormControl(this.event.bandProgramAttending),
      estimatedBandRentals: new FormControl(this.event.estimatedBandRentals, [validators.NumericValidators.min(1)]),
      orchestraProgramAttending: new FormControl(this.event.orchestraProgramAttending),
      estimatedOrchestraRentals: new FormControl(this.event.estimatedOrchestraRentals, [validators.NumericValidators.min(1)])
    });

  }

  resetForm() {

  }

  get canSave(): boolean {
    return this.editor.valid && this.editor.dirty && !this.panelState.updating;
  }

  onSubmit() {
    const eventQuality = this.editor.value.eventQuality;
    const deliveryTimePeriod = this.editor.value.deliveryTimePeriod;
    const rentalMeetingTypeId = this.editor.value.rentalMeetingType;
    const rentalFulfillmentTypeId = this.editor.value.rentalFulfillmentType;
    const mouthpieceTesting = this.editor.value.mouthpieceTesting;
    const bandProgramAttending = this.editor.value.bandProgramAttending;
    const estimatedBandRentals = this.editor.value.estimatedBandRentals;
    const orchestraProgramAttending = this.editor.value.orchestraProgramAttending;
    const estimatedOrchestraRentals = this.editor.value.estimatedOrchestraRentals;

    const event = {
      ...this.event,
      eventQuality,
      deliveryTimePeriod,
      rentalMeetingTypeId,
      rentalFulfillmentTypeId,
      mouthpieceTesting,
      bandProgramAttending,
      estimatedBandRentals,
      orchestraProgramAttending,
      estimatedOrchestraRentals,
    };

    this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventMeetingDetails, event }));
  }

}
