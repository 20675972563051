import { DistrictDetailsComponent } from './district-details.component';
import { DistrictInfoEditorComponent } from './district-info-editor.component';
import { DistrictAddressEditorComponent } from './district-address-editor.component';
import { DistrictContactsEditorComponent } from './district-contacts-editor.component';
import { DistrictCalendarEditorComponent } from './district-calendar-editor.component';
import { DistrictAddlDetailsEditorComponent } from './district-addl-details-editor.component';
import { DistrictSearchResultsComponent } from './district-searchresults.component';
import { DistrictRelationshipEditorComponent } from './district-relationship-editor.component';

export const containers: any[] = [
	DistrictDetailsComponent,
	DistrictInfoEditorComponent,
	DistrictAddressEditorComponent,
	DistrictContactsEditorComponent,
	DistrictCalendarEditorComponent,
	DistrictAddlDetailsEditorComponent,
	DistrictSearchResultsComponent,
	DistrictRelationshipEditorComponent,
];

export * from './district-details.component';
export * from './district-info-editor.component';
export * from './district-address-editor.component';
export * from './district-contacts-editor.component';
export * from './district-calendar-editor.component';
export * from './district-addl-details-editor.component';
export * from './district-searchresults.component';
export * from './district-relationship-editor.component';
