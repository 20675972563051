import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap, map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../../core/store';
import { MessageType } from '../../../core/models';

import { Event, EventPromo } from '../models';
import * as fromStore from '../store';

@Component({
    selector: 'event-promo-editor',
    templateUrl: './event-promo-editor.component.html',
})
export class EventPromoEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    alive = true;
    event$: Observable<Event>;
    approvedPromos$: Observable<EventPromo[]>;
    approvedPromos: EventPromo[];
    event: Event;
    editEvent: Event;
    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;
    editor: FormGroup;

    constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {

    }

    ngOnInit() {
        this.editEvent = null;
        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventPromoPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
        this.event$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(d => {
            this.event = d;
            this.editEvent = d;
        });

        this.createForm();

        this.bindPromos();
    }

    bindPromos() {
        this.event.start;
        this.event.expirationDate;
        this.approvedPromos$ = this.store.pipe(
            select(fromStore.getDetailsStateApprovedPromos),
            takeWhile(() => this.alive),
            map(promos => {
                return promos.filter(ep => {
                    const matchingPromo = this.editEvent.eventPromos.filter(p => ep.promoId == p.promoId);
                    if (matchingPromo && matchingPromo.length === 0) {
                        if (this.event.start >= ep.start && this.event.expirationDate <= ep.end) {
                            return ep;
                        }
                    }
                });
            })
        );
        this.approvedPromos$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(ap => {
            this.approvedPromos = ap.filter(ap => ap != undefined);
            this.ref.detectChanges();
        });
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.alive = false;
    }

    createForm() {
        this.editor = new FormGroup({
            promotionToAdd: new FormControl(null)
        });

    }

    AddSelectedPromo() {
        //determine the selected promo.
        const promoId: string = this.editor.get('promotionToAdd').value;

        if (promoId) {
            const promo: EventPromo = this.approvedPromos.filter(e => e.promoId == promoId)[0];

            //Add the promo to the edit event.
            if (this.editEvent.eventPromos.filter(p => p.promoId == promo.promoId).length > 0) {
                this.store.dispatch(new fromRoot.DisplayMessage({
                    message: 'Promo is already added',
                    messageType: MessageType.alert,
                    toast: true
                }));
            } else {
                this.editEvent = {
                    ...this.editEvent,
                    eventPromos: [
                        ...this.editEvent.eventPromos,
                        promo
                    ]
                };
                this.bindPromos();
                this.editor.controls['promotionToAdd'].setValue(null);
                this.ref.detectChanges();
            }
        }

    }

    removePromotion(promo: EventPromo) {
        if (promo) {
            const eventPromos = this.editEvent.eventPromos.filter(s => s != promo);
            this.editEvent = {
                ...this.editEvent,
                eventPromos: eventPromos
            };
            this.bindPromos();
            this.ref.detectChanges();
        }
    }

    resetForm() {

    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty && !this.panelState.updating;
    }

    onSubmit() {
        const event = {
            ...this.event,
            eventPromos: [
                ...this.editEvent.eventPromos
            ]
        };

        this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventPromo, event }));
    }


}
