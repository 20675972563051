import { Component, Input } from '@angular/core';

import { SchoolDetails, SchoolType, SchoolLevel, InactiveReason } from '../../models';

@Component({
	selector: 'opus-school-info',
	templateUrl: './school-info.component.html',
})
export class SchoolInfoComponent {
	@Input() school: SchoolDetails;

	get schoolType() {
		return SchoolType[this.school.type];
	}

	get level() {
		return SchoolLevel[this.school.level];
	}

	get gradeRange() {
		return `${this.gradeToLabel(this.school.lowGrade)}-${this.gradeToLabel(this.school.highGrade)}`;
    }

    get inactiveReason(): string {
        return InactiveReason[this.school.inactiveReason];
    }

	private gradeToLabel(grade?: number): string {
		let label: string = grade === null ? 'N/A' : grade.toString();

		if (grade == 0) {
			label = 'K';
		} else if (grade >= 13) {
			label = 'College';
		}

		return label;
	}
}
