import { Action } from '@ngrx/store';

import { RolePolicy } from '../../models';

export enum SecurityActionTypes {
    SetUserData = '[Security] Set User Data',
    CheckUserDataLoaded = '[Security] Check User Data Loaded',
    UserDataLoaded = '[Security] User Data Loaded',
    LoadRolePolicies = '[Security] Load Role Policy',
    LoadRolePoliciesSuccess = '[Security] Load Role Policy Success',
    LoadRolePoliciesFailure = '[Security] Load Role Policy Failure'
}

export class SetUserData implements Action {
    readonly type = SecurityActionTypes.SetUserData;
    constructor(public payload: any) { }
}

export class LoadRolePolicies implements Action {
    readonly type = SecurityActionTypes.LoadRolePolicies;
    constructor(public payload: any) { }
}

export class LoadRolePoliciesSuccess implements Action {
    readonly type = SecurityActionTypes.LoadRolePoliciesSuccess;
    constructor(public payload: { rolePolicies: RolePolicy[] }) { }
}

export class LoadRolePoliciesFailure implements Action {
    readonly type = SecurityActionTypes.LoadRolePoliciesFailure;
    constructor(public payload: any) { }
}

export type SecurityActions =
    | SetUserData
    | LoadRolePolicies
    | LoadRolePoliciesSuccess
    | LoadRolePoliciesFailure;
