import { Directive, ElementRef, Renderer2, Input, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import { Subscription, BehaviorSubject } from 'rxjs';
import { map, filter, combineLatest } from 'rxjs/operators';


@Directive({
    selector: '[opusAuthorize]'
})
export class AuthorizeDirective implements AfterViewInit, OnChanges, OnDestroy {
    private subscription?: Subscription;

    private changes$ = new BehaviorSubject(null);

    authorized: boolean;

    @Input('opusAuthorize') policyCode: string;

    @Input() opusAuthorizeAction: string;
    @Input() opusRelationshipManagerEmail: any;
    @Input() opusRelationshipManagerRoles: string[];

    constructor(private element: ElementRef, private renderer: Renderer2, private store: Store<fromStore.CoreState>) { }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    ngOnChanges() {
        this.changes$.next(null);
    }

    ngAfterViewInit(): void {
        if (!this.policyCode) return;

        this.subscription = this.store.pipe(
            select(fromStore.getUserAuthInfo),
            combineLatest(this.changes$),
            map(x => x[0]),
            filter(x => !!x),
            map(x => {
                if (!x.policyCodes.has(this.policyCode)) return false;

                if (
                    !this.opusRelationshipManagerEmail ||
                    !this.opusRelationshipManagerRoles ||
                    !this.opusRelationshipManagerRoles.length ||
                    !this.opusRelationshipManagerRoles.find(y => x.roleNames.has(y))
                ) return true;

                if (Array.isArray(this.opusRelationshipManagerEmail))
                    return !!this.opusRelationshipManagerEmail.find(y => y.toLowerCase() === x.emailLower);

                return this.opusRelationshipManagerEmail && this.opusRelationshipManagerEmail.toLowerCase() === x.emailLower;

            })
        ).subscribe(isAuthorized => {
            this.authorized = isAuthorized;

            switch (this.opusAuthorizeAction || 'remove') {
                case 'show': //If I'm not authorized show, otherwise remove
                    if (isAuthorized) {
                        this.element.nativeElement.remove();
                    }
                    break;
                case 'disable':
                    if (!isAuthorized) {
                        this.renderer.setAttribute(this.element.nativeElement, 'disabled', 'true');
                    }
                    break;
                case 'remove':
                default:
                    if (!isAuthorized) {
                        this.element.nativeElement.remove();
                    }
                    break;
            }
        });
    }
}

