import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProgramSearchResults from '../reducers/searchresults.reducer';

export const getProgramSearchState = createSelector(
    fromFeature.getProgramsState,
    (state: fromFeature.ProgramsState) => state.search
);
export const getProgramSearchResultsState = createSelector(
    getProgramSearchState,
    fromProgramSearchResults.getProgramSearchResults
);
