<div class="card__section">
    <div class="row">
        <div class="columns">
            <h3 class="card__title card__title--large u-m-b-8">Shipping Charges</h3>
        </div>
        <div class="columns shrink">
            <p class="u-m-b-0 typography--body" aria-live="polite">
                Viewing <strong>{{ (shippingCharges$ | async)?.length || 0 }}</strong> Shipping Charges
            </p>
        </div>
    </div>
</div>
<div class="card__section">
    <div class="row align-middle">
        <div class="columns">
            <label for="rentalCategoryFilter">Rental Category</label>
            <select id="rentalCategoryFilter" class="u-m-b-0" [formControl]="rentalCategoryCtrl">
                <option [ngValue]="null">Select</option>
                <option *ngFor="let rc of rentalCategories$ | async" [ngValue]="rc.code">{{ rc.code }}: {{ rc.rentalCategoryAttribute?.displayName || rc.name }}</option>
            </select>
        </div>
        <div class="columns">
            <label for="stateFilter">State</label>
            <select id="stateFilter" class="u-m-b-0" [formControl]="stateCtrl">
                <option [ngValue]="null">Select</option>
                <option *ngFor="let s of states">{{ s }}</option>
            </select>
        </div>
    </div>
</div>
