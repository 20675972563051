import { Component, Input, Output, EventEmitter, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

declare var $: any;

@Component({
  selector: 'opus-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnDestroy, AfterViewInit {
  @ViewChild('opusModal', {static: false})
  private _modal: ElementRef;
  private _visible = false;
  private _displayTertiaryButton = false;
  private _tertiaryLabel = '';
  _displayButtons = true;

  @Input()
  set visible(visible: boolean) {
    if (visible != this._visible) {
      $(this._modal.nativeElement).foundation(visible ? 'open' : 'close');
      
      if (visible) {
        $(this._modal.nativeElement).one('closed.zf.reveal', (e) => {
          this.action.emit(false);
        });
      }
      this._visible = visible;
    }
  }

  @Input()
  set displayButtons(displayButtons: boolean) {
    this._displayButtons = displayButtons;
  }
  @Input() title: string;
  @Input() primaryLabel: string;
  @Input() secondaryLabel: string;
  @Input()
  set tertiaryLabel(tertiaryLabel: string) {
    this._displayTertiaryButton = tertiaryLabel.trim() != '';
    this._tertiaryLabel = tertiaryLabel;
  }
  @Input() showClose = true;
  @Input() primaryEnabled = true;

  @Output() action = new EventEmitter<boolean>();
  @Output() actionTertiary = new EventEmitter<boolean>();

  ngAfterViewInit() {
    $(this._modal.nativeElement).foundation();
  }

  ngOnDestroy() {
    if (this._visible) {
      $(this._modal.nativeElement).foundation('close');
    }
  }

  onSecondary() {
    this.action.emit(false);
  }

  onPrimary() {
    this.action.emit(true);
  }

  onTertiary() {
    this.actionTertiary.emit(true);
  }
}
