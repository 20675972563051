import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';



import {  SecurityState } from '../store';
import * as fromStore from '../store';
import { Observable } from 'rxjs';

@Component({
	selector: 'opus-app',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    constructor(private router: Router, 
        public oidcSecurityService: OidcSecurityService, 
        private _store: Store<SecurityState>, 
        private publicEventsService: PublicEventsService 
    ) {
        this._store.dispatch(new fromStore.LoadRolePolicies({}));
    }

    ngOnInit() {
        this.publicEventsService
        .registerForEvents()
        .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
        .subscribe(() => {
          this._store.dispatch(new fromStore.OidcConfigLoaded())
        });

        this.oidcSecurityService.isAuthenticated$.subscribe((authenticated) => {
            this._store.dispatch(new fromStore.OidcAuthorizationResult(authenticated))
        });
        
        // When navigating to different routes, this code will force a scroll up to the top
        // of the page.
        this.shouldDisplayAdvancedSearch();

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.oidcSecurityService.logoff();
    }

    displayAdvancedSearch = false;

    // TODO: Advanced search not implemented yet, just return "true" for now to show the Quick Search
    shouldDisplayAdvancedSearch(): Observable<boolean> {

        const policyMap = [
            { policyName: 'School.Search', route: 'search/schools' },
            { policyName: 'District.Search', route: 'search/districts' },
            { policyName: 'Teacher.Search', route: 'search/teachers' },
        ];

        return this._store.pipe(
            select(fromStore.getUserAuthInfo),
            filter(x => !!x),
            map(x => policyMap.every(y => x.policyCodes.has(y.policyName)))
        );

    }

}
