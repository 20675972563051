<!-- edit mode -->
<form [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Event location" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <div class="property-block__value property-block__value--no-label">
                <label class="checkbox u-m-b-0">
                    <input type="checkbox" class="checkbox__input" formControlName="eventAtHostSchool" (change)="toggleEventAtSchool()">
                    At Host School
                </label>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__value property-block__value--no-label">
                <label class="checkbox u-m-b-0">
                    <input type="checkbox" class="checkbox__input" formControlName="isVirtualEvent">
                    Virtual Event
                </label>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.isVirtualEvent">
            <label for="virtualEventUrl" class="property-block__label">Virtual Event URL</label>
            <div class="property-block__value">
                <input type="url" id="virtualEventUrl" formControlName="virtualEventUrl" maxlength="1000">
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.eventAtHostSchool">
            <div class="property-block__label" id="addressLine1">Address Line 1</div>
            <div class="property-block__value" role="definition" aria-labelledby="addressLine1">{{ event.hostingSchoolAddress?.line1}}</div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.eventAtHostSchool && event.eventAddress?.line2">
            <div class="property-block__label" id="addressLine2">Address Line 2</div>
            <div class="property-block__value" role="definition" aria-labelledby="addressLine2">{{ event.hostingSchoolAddress?.line2}}</div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.eventAtHostSchool">
            <div class="property-block__label" id="mCityStateZip">City, State Zip</div>
            <div class="property-block__value" role="definition" aria-labelledby="mCityStateZip">{{ event.hostingSchoolAddress?.city}}, {{ event.hostingSchoolAddress?.state}} {{ event.hostingSchoolAddress?.zip}}</div>
        </li>
        <li class="property-block__list-item" *ngIf="!editor.value.eventAtHostSchool">
            <label for="editAddressLine1" class="property-block__label">Address Line 1</label>
            <div class="property-block__value">
                <input type="text" id="editAddressLine1" formControlName="eventAddressLine1" required>
                <div class="error" *ngIf="editor.get('eventAddressLine1').invalid && (editor.get('eventAddressLine1').dirty || editor.get('eventAddressLine1').touched)">
                    <div class="error__message" *ngIf="editor.get('eventAddressLine1').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="!editor.value.eventAtHostSchool">
            <label for="editAddressLine2" class="property-block__label">Address Line 2</label>
            <div class="property-block__value">
                <input type="text" id="editAddressLine2" formControlName="eventAddressLine2">
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="!editor.value.eventAtHostSchool">
            <label for="editCity" id="editCityLabel" class="property-block__label">City</label>
            <div class="property-block__value">
                <input type="text" id="editAddressCity" formControlName="eventAddressCity">
                <div class="error" *ngIf="editor.get('eventAddressCity').invalid && (editor.get('eventAddressCity').dirty || editor.get('eventAddressCity').touched)">
                    <div class="error__message" *ngIf="editor.get('eventAddressCity').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="!editor.value.eventAtHostSchool">
            <div class="property-block__label" id="editState">State</div>
            <div class="property-block__value" role="definition" aria-labelledby="editState">
                <!-- if only one matching city, print it out -->
                <!--<input type="text" id="editAddressState" formControlName="eventAddressState">-->
                <select id="editPState" formControlName="eventAddressState">
                    <option value="">Select</option>
                    <option *ngFor="let s of states" [value]="s">{{ s }}</option>
                </select>
                <div class="error" *ngIf="editor.get('eventAddressState').invalid && (editor.get('eventAddressState').dirty || editor.get('eventAddressState').touched)">
                    <div class="error__message" *ngIf="editor.get('eventAddressState').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="!editor.value.eventAtHostSchool">
            <label for="editZipCode" class="property-block__label" >Zip Code</label>
            <div class="property-block__value">
                <input type="text" id="editZipCode" formControlName="eventAddressZip" required >
                <div class="error" *ngIf="editor.get('eventAddressZip').invalid && (editor.get('eventAddressZip').dirty || editor.get('eventAddressZip').touched)">
                    <div class="error__message" *ngIf="editor.get('eventAddressZip').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="locationInfo" class="property-block__label">Location Info</label>
            <div class="property-block__value">
                <textarea id="locationInfo" rows="3" formControlName="location" maxlength="200"></textarea>
            </div>
        </li>
    </ul>

    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
        </div>
    </div>
</form>

<opus-modal [visible]="showModal" (action)="onModalAction($event)" (actionTertiary)="onModalTertiaryAction($event)" title="Address Correction" [primaryLabel]="suggestedAddress ? 'Accept Suggestion' : 'Save as Entered'" secondaryLabel="Edit" showClose="true"
            [tertiaryLabel]="suggestedAddress ? 'Save as Entered' : ''">
    <ng-container *ngIf="enteredAddress">
        <div class="property-block">
            <div class="property-block__title-wrapper">
                <h2 class="property-block__title">Entered Address:</h2>
            </div>
            <ul class="property-block__list">
                <li class="property-block__list-item">
                    <div class="property-block__label" id="eAddressLine1">Address Line 1</div>
                    <div class="property-block__value" role="definition" aria-labelledby="eAddressLine1">{{ enteredAddress.line1 }}</div>
                </li>
                <li class="property-block__list-item" *ngIf="enteredAddress.line2">
                    <div class="property-block__label" id="eAddressLine2">Address Line 2</div>
                    <div class="property-block__value" role="definition" aria-labelledby="eAddressLine2">{{ enteredAddress.line2 }}</div>
                </li>
                <li class="property-block__list-item">
                    <div class="property-block__label" id="eCityStateZip">City, State Zip</div>
                    <div class="property-block__value" role="definition" aria-labelledby="eCityStateZip">{{ enteredAddress.city }}, {{ enteredAddress.state }} {{ enteredAddress.zip }}</div>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="suggestedAddress">
        <div class="property-block">
            <div class="property-block__title-wrapper">
                <h2 class="property-block__title">Suggested Address:</h2>
            </div>
            <ul class="property-block__list">
                <li class="property-block__list-item">
                    <div class="property-block__label" id="sAddressLine1">Address Line 1</div>
                    <div class="property-block__value" role="definition" aria-labelledby="sAddressLine1">{{ suggestedAddress.line1 }}</div>
                </li>
                <li class="property-block__list-item" *ngIf="suggestedAddress.line2">
                    <div class="property-block__label" id="sAddressLine2">Address Line 2</div>
                    <div class="property-block__value" role="definition" aria-labelledby="sAddressLine2">{{ suggestedAddress.line2 }}</div>
                </li>
                <li class="property-block__list-item">
                    <div class="property-block__label" id="sCityStateZip">City, State Zip</div>
                    <div class="property-block__value" role="definition" aria-labelledby="sCityStateZip">{{ suggestedAddress.city }}, {{ suggestedAddress.state }} {{ suggestedAddress.zip }}</div>
                </li>
            </ul>
        </div>
    </ng-container>
    <ng-container *ngIf="!suggestedAddress">
        <ul class="error">
            <li class="error__message">The United states Postal Service is unable to locate this address.</li>
        </ul>
    </ng-container>
</opus-modal>

