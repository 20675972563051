<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="currentRateCode">Agreement ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="currentRateCode">{{ rateCode }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="currentRateDescription">Description</div>
		<div class="property-block__value" role="definition" aria-labelledby="currentRateDescription">
			<div>{{ rateDescription }}</div>
		</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="currentRateEffectivity">Effectivity</div>
		<div class="property-block__value" role="definition" aria-labelledby="currentRateEffectivity">{{ rateEffectivity }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="nodeLevel">Node Level</div>
		<div class="property-block__value" role="definition" aria-labelledby="nodeLevel">{{ nodeLevelName }}</div>
	</li>
</ul>
