<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="inheritManager">Inherit Manager</div>
		<div class="property-block__value" role="definition" aria-labelledby="inheritManager">{{ school.inheritRelationshipManager | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="managerId">Manager ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="managerId">{{ school.relationshipManager.locationCode }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="managerName">Manager Name</div>
		<div class="property-block__value" role="definition" aria-labelledby="managerName">{{ school.relationshipManager.name }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="inheritRelationshipType">Inherit Relationship Type</div>
		<div class="property-block__value" role="definition" aria-labelledby="inheritRelationshipType">{{ school.inheritRelationshipType | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="relationshipType">Relationship Type</div>
		<div class="property-block__value" role="definition" aria-labelledby="relationshipType">{{ relationshipType }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="routineService">Routine Service</div>
		<div class="property-block__value" role="definition" aria-labelledby="routineService">{{ routineService }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="callDay">Call Day</div>
		<div class="property-block__value" role="definition" aria-labelledby="callDay">{{ callDay }}</div>
	</li>
</ul>
