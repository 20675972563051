import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';

import { Address, AddressVerification } from '../models';

@Injectable()
export class AddressService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) { }

    lookupCityState(zip: string): Observable<{ city: string, state: string }> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<{ city: string, state: string }>(`${c.config.apiServer}api/addresses/citystate/${zip}`);
            })
        );
    }

    verifyAddress(address: Address): Observable<AddressVerification> {
        const params = [];

        Object.keys(address).forEach(key => {
            if (address[key] === null) {
                return;
            }

            params.push(`${key}=${address[key]}`);
        });

        const query = params.join('&');

        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<AddressVerification>(`${c.config.apiServer}api/addresses/verification?${query}`);
            })
        );
    }

    loadCounties(stateCode: string): Observable<string[]> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<string[]>(`${c.config.apiServer}api/addresses/counties/${stateCode}`);
            })
        );
    }
}
