import * as fromCommonModels from '../../../scp-common/models';
import * as fromTeacherDetails from '../actions/teacher-details.action';
import * as fromDistrictModels from '../../../districts/models';
import { TeacherDetails } from '../../models';
import { initialRatePanelState } from '../../../schools/store/reducers/school-details.reducer';

export enum PanelId {
    Info = 'info',
    District = 'district',
    Additional = 'additional',
    Address = 'address',
    Contact = 'contact'
}

export enum Prefix {
    MR = 1,
    MRS,
    MS,
    DR,
    CAPT,
    MAJOR,
    SGT,
    COL,
    FR,
    FATHER,
    BROTHER,
    SISTER,
    PASTOR,
    REV,
    RABBI,
    SR
}

export let PrefixLookup = [];
PrefixLookup[Prefix.MR] = { id: Prefix.MR, name: 'Mr' };
PrefixLookup[Prefix.MRS] = { id: Prefix.MRS, name: 'Mrs' };
PrefixLookup[Prefix.MS] = { id: Prefix.MS, name: 'Ms' };
PrefixLookup[Prefix.DR] = { id: Prefix.DR, name: 'Dr' };
PrefixLookup[Prefix.CAPT] = { id: Prefix.CAPT, name: 'Capt' };
PrefixLookup[Prefix.MAJOR] = { id: Prefix.MAJOR, name: 'Major' };
PrefixLookup[Prefix.SGT] = { id: Prefix.SGT, name: 'Sgt' };
PrefixLookup[Prefix.COL] = { id: Prefix.COL, name: 'Col' };
PrefixLookup[Prefix.FR] = { id: Prefix.FR, name: 'Fr' };
PrefixLookup[Prefix.FATHER] = { id: Prefix.FATHER, name: 'Father' };
PrefixLookup[Prefix.BROTHER] = { id: Prefix.BROTHER, name: 'Brother' };
PrefixLookup[Prefix.SISTER] = { id: Prefix.SISTER, name: 'Sister' };
PrefixLookup[Prefix.PASTOR] = { id: Prefix.PASTOR, name: 'Pastor' };
PrefixLookup[Prefix.REV] = { id: Prefix.REV, name: 'Rev' };
PrefixLookup[Prefix.RABBI] = { id: Prefix.RABBI, name: 'Rabbi' };
PrefixLookup[Prefix.SR] = { id: Prefix.SR, name: 'Sr' };

export enum Suffix {
    PHD = 1,
    ESQ,
    MD,
    JR,
    II,
    III,
    IV,
    DOCTOR,
    DO,
    A,
    W,
    J,
    M,
    MS,
    MS_D,
    MS_Z,
    MS_O,
    MS_COO,
    MIS,
    ENG
}
export let SuffixLookup = [];
SuffixLookup[Suffix.PHD] = { id: Suffix.PHD, name: 'Phd' };
SuffixLookup[Suffix.ESQ] = { id: Suffix.ESQ, name: 'Esq' };
SuffixLookup[Suffix.MD] = { id: Suffix.MD, name: 'Md' };
SuffixLookup[Suffix.JR] = { id: Suffix.JR, name: 'Jr' };
SuffixLookup[Suffix.II] = { id: Suffix.II, name: 'II' };
SuffixLookup[Suffix.III] = { id: Suffix.III, name: 'III' };
SuffixLookup[Suffix.IV] = { id: Suffix.IV, name: 'IV' };
SuffixLookup[Suffix.DOCTOR] = { id: Suffix.DOCTOR, name: 'Doctor' };
SuffixLookup[Suffix.DO] = { id: Suffix.DO, name: 'Do' };
SuffixLookup[Suffix.A] = { id: Suffix.A, name: 'A' };
SuffixLookup[Suffix.W] = { id: Suffix.W, name: 'W' };
SuffixLookup[Suffix.J] = { id: Suffix.J, name: 'J' };
SuffixLookup[Suffix.M] = { id: Suffix.M, name: 'M' };
SuffixLookup[Suffix.MS] = { id: Suffix.MS, name: 'Ms' };
SuffixLookup[Suffix.MS_D] = { id: Suffix.MS_D, name: 'Ms D' };
SuffixLookup[Suffix.MS_Z] = { id: Suffix.MS_Z, name: 'Ms Z' };
SuffixLookup[Suffix.MS_O] = { id: Suffix.MS_O, name: 'Ms O' };
SuffixLookup[Suffix.MS_COO] = { id: Suffix.MS_COO, name: 'Ms Coo' };
SuffixLookup[Suffix.MIS] = { id: Suffix.MIS, name: 'Mis' };
SuffixLookup[Suffix.ENG] = { id: Suffix.ENG, name: 'Eng' };


export interface PanelState {
    editing: boolean;
    updating: boolean;
    validationStatus: ValidationStatus;
    errors: any;	//	TODO: formalize error handling
}

export interface DistrictLookupState {
    districtId: number;
    pending: boolean;
    errors: any;
    district: fromDistrictModels.DistrictLookup;
}

export interface DistrictByNameLookupState {
    name: string;
    pending: boolean;
    errors: any;
    districts: fromDistrictModels.DistrictLookup[];
}
export interface CountyLookupState {
    pending: boolean;
    lookupStateCode: string;
    errors: any;
    counties: { [stateCode: string]: string[] };
}

export interface ZipLookupState {
    zip: string;
    pending: boolean;
    errors: any;
    cityState: { city: string, state: string };
}

export interface AddressPanelState extends PanelState {
    countyLookup: CountyLookupState;
    zipLookup: ZipLookupState;
    verifying: boolean;
    verifiedAddress: fromCommonModels.Address;
    suggestedAddresses: fromCommonModels.Address[];
}

export interface ValidationStatus {
    [id: string]: {
        pending: boolean;
        errors: { [key: string]: any };
    };
}

export interface TeacherDetailsState {
    teacherDetails: TeacherDetails;
	loaded: boolean;
	loading: boolean;
	errors: any;
	//inDistrictLookup: boolean;
	//districtFound: boolean;
    districtLookup: DistrictLookupState;
    districtByNameLookup: DistrictByNameLookupState;
	panels: { [id: string]: PanelState };
}

export const initialPanelState: PanelState = {
	editing: false,
	validationStatus: {},
	updating: false,
	errors: null,
};

export const initialZipLookupState: ZipLookupState = {
    zip: '',
    pending: false,
    cityState: null,
    errors: null,
};

export const initialCountyLookupState: CountyLookupState = {
    pending: false,
    lookupStateCode: null,
    errors: null,
    counties: {},
};

export const initialAddressPanelState: AddressPanelState = {
    ...initialPanelState,
    zipLookup: initialZipLookupState,
    countyLookup: initialCountyLookupState,
    verifying: false,
    verifiedAddress: null,
    suggestedAddresses: null,
};

export const initialDistrictLookupState: DistrictLookupState = {
    districtId: null,
    pending: false,
    district: null,
    errors: null,
};

export const initialDistrictByNameLookupState: DistrictByNameLookupState = {
    name: null,
    pending: false,
    errors: null,
    districts: null
};

export const initialState: TeacherDetailsState = {
	teacherDetails: null,
	loaded: false,
	loading: false,
    errors: null,
    districtLookup: initialDistrictLookupState,
    districtByNameLookup: initialDistrictByNameLookupState,
	//inDistrictLookup: false,
	//districtFound: false,
    panels: {
        [PanelId.Info]: { ...initialPanelState },
        [PanelId.District]: { ...initialPanelState },
        [PanelId.Additional]: { ...initialPanelState },
        [PanelId.Address]: { ...initialAddressPanelState } as PanelState,
        [PanelId.Contact]: { ...initialPanelState }
        /*
		[PanelId.PhysAddr]: { ...initialAddressPanelState } as PanelState,
		[PanelId.MailAddr]: { ...initialAddressPanelState } as PanelState,
		[PanelId.Contacts]: { ...initialPanelState },
		[PanelId.CurrentRate]: { ...initialRatePanelState } as PanelState,
		[PanelId.FutureRate]: { ...initialRatePanelState } as PanelState,
		[PanelId.Calendar]: { ...initialPanelState },
		[PanelId.Relationship]: { ...initialPanelState },
		[PanelId.OnlineRentals]: { ...initialPanelState },
		[PanelId.RentalReturns]: { ...initialPanelState },
		[PanelId.AdditionalDetails]: { ...initialPanelState },
        */
	}
};

export function reducer(state = initialState, action: fromTeacherDetails.TeacherDetailsActions): TeacherDetailsState {
	switch (action.type) {
		case fromTeacherDetails.TeacherDetailsActionTypes.LoadTeacherDetails: {
			return {
				...state,
				loading: true,
				errors: null,
			};
		}

		case fromTeacherDetails.TeacherDetailsActionTypes.LoadTeacherDetailsComplete: {
			const teacherDetails = action.payload;

			return {
				...state,
				loaded: true,
				loading: false,
				errors: null,
				teacherDetails,
			};
		}

		case fromTeacherDetails.TeacherDetailsActionTypes.LoadTeacherDetailsFailure: {
			return {
				...state,
				loaded: false,
				loading: false,
				errors: action.payload,
			};
		}

		case fromTeacherDetails.TeacherDetailsActionTypes.UpdateEditorState: {
			const key = action.payload.key;
			const editing = action.payload.open;

			if (editing == state.panels[key].editing) {
				return state;
			}

			const panel = { ...initialState.panels[key], editing };	//	NOTE: initialPanelState will not have any custom panel initialization...
			const panels = {
				...state.panels,
				[key]: panel,
			};

			return {
				...state,
				panels,
			};
		}

		case fromTeacherDetails.TeacherDetailsActionTypes.UpdateTeacherPanel: {
			const panel = {
				...state.panels[action.payload.panel],
				updating: true,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[action.payload.panel]: panel,
				},
			};
		}

		case fromTeacherDetails.TeacherDetailsActionTypes.UpdateTeacherPanelFailure: {
			const panel = {
				...state.panels[action.payload.panel],
				updating: false,
				errors: action.payload.errors,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[action.payload.panel]: panel,
				},
			};
		}

		case fromTeacherDetails.TeacherDetailsActionTypes.UpdateTeacherPanelSuccess: {
			const teacherDetails = action.payload.teacher;

			return {
				...state,
				teacherDetails,
				panels: {
					...state.panels,
					[action.payload.panel]: initialState.panels[action.payload.panel],
				},
			};
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherAddressLookupCityState: {
            const { zip, panel } = action.payload;
            const zipLookup: ZipLookupState = {
                ...(state.panels[panel] as AddressPanelState).zipLookup,
                zip,
                pending: true,
                errors: null,
                cityState: null,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        zipLookup,
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherAddressLookupCityStateFailure: {
            const { errors, panel } = action.payload;
            const zipLookup: ZipLookupState = {
                ...(state.panels[panel] as AddressPanelState).zipLookup,
                pending: false,
                errors,
                cityState: null,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        zipLookup,
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherAddressLookupCityStateSuccess: {
            const { panel, cityState } = action.payload;
            const zipLookup: ZipLookupState = {
                ...(state.panels[panel] as AddressPanelState).zipLookup,
                pending: false,
                cityState,
            };

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        zipLookup,
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherLoadCounties: {
            const { panel, stateCode } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        countyLookup: {
                            ...(state.panels[panel] as AddressPanelState).countyLookup,
                            pending: true,
                            lookupStateCode: stateCode,
                        },
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherLoadCountiesSuccess: {
            const { panel, counties } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        countyLookup: {
                            ...(state.panels[panel] as AddressPanelState).countyLookup,
                            pending: false,
                            counties: {
                                ...(state.panels[panel] as AddressPanelState).countyLookup.counties,
                                [(state.panels[panel] as AddressPanelState).countyLookup.lookupStateCode]: counties,
                            },
                        },
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherVerifyAddress: {
            const { panel, address } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        verifying: true,
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.TeacherVerifyAddressSuccess: {
            const { panel, verification } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        verifying: false,
                        verifiedAddress: verification.verifiedAddress,
                        suggestedAddresses: verification.picklist,
                    } as AddressPanelState,
                },
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.DistrictLookupById: {
            const { districtId } = action.payload;
            const districtLookup: DistrictLookupState = {
                districtId,
                pending: true,
                errors: null,
                district: null,
            };

            return {
                ...state,
                districtLookup
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.DistrictLookupByIdFailure: {
            const { errors } = action.payload;
            const districtLookup: DistrictLookupState = {
                ...state.districtLookup,
                pending: false,
                errors,
                district: null,
            };

            return {
                ...state,
                districtLookup,
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.DistrictLookupByIdSuccess: {
            const { district } = action.payload;
            const districtLookup: DistrictLookupState = {
                ...state.districtLookup,
                pending: false,
                district,
            };

            return {
                ...state,
                districtLookup,
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.DistrictLookupByName: {
            const { name } = action.payload;
            const districtByNameLookup: DistrictByNameLookupState = {
                name,
                pending: true,
                errors: null,
                districts: null,
            };

            return {
                ...state,
                districtByNameLookup,
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.DistrictLookupByNameFailure: {
            const { errors, panel } = action.payload;
            const districtByNameLookup: DistrictByNameLookupState = {
                ...state.districtByNameLookup,
                pending: false,
                errors,
                districts: null,
            };

            return {
                ...state,
                districtByNameLookup,
            };
        }

        case fromTeacherDetails.TeacherDetailsActionTypes.DistrictLookupByNameSuccess: {
            const { panel, districts } = action.payload;
            const districtByNameLookup: DistrictByNameLookupState = {
                ...state.districtByNameLookup,
                pending: false,
                districts,
            };

            return {
                ...state,
                districtByNameLookup,
            };
        }

		default: {
			if (Object.values(fromTeacherDetails.TeacherDetailsActionTypes).includes((action as fromTeacherDetails.TeacherDetailsActions).type)) {
				console.log('teacher-details.reducer: unhandled action: ', action);
			}
		}
	}

	return state;
}

export const getTeacherDetailsEntity = (state: TeacherDetailsState) => state.teacherDetails;
export const getTeacherDetailsLoading = (state: TeacherDetailsState) => state.loading;
export const getTeacherDetailsLoaded = (state: TeacherDetailsState) => state.loaded;
export const getDetailsStatePanels = (state: TeacherDetailsState) => state.panels;
export const getDetailsStateCountyLookup = (state: AddressPanelState) => state.countyLookup;
export const getDetailsStateDistrictLookup = (state: TeacherDetailsState) => state.districtLookup;
export const getDetailsStateDistrictByNameLookup = (state: TeacherDetailsState) => state.districtByNameLookup;
