<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="mtdID">MTD ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="mtdID">{{ externalSystemId }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="bio">Biography</div>
        <div class="property-block__value u-text--preformatted" role="definition" aria-labelledby="bio">{{ bio }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="education">Education</div>
        <div class="property-block__value u-text--preformatted" role="definition" aria-labelledby="education">{{ education }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="instruments">Instruments</div>
        <div class="property-block__value u-text--preformatted" role="definition" aria-labelledby="instruments">{{ instruments }}</div>
    </li>
</ul>