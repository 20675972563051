<div class="history-snapshot spinner-section" [class.spinner-section--is-active]="(snapshotState$ | async).loading">
	<div class="spinner-section__icon"></div>
	<ng-container *ngIf="!(snapshotState$ | async).loading">
		<ng-container *ngIf="!(snapshotState$ | async).error; else error">
			<div *ngIf="(snapshotState$ | async).data; else noHistory">
				<dl class="history-snapshot__list">
					<dt>Last Modified:</dt>
					<dd>{{ (snapshotState$ | async).data.timestamp | date:'EEE, MMMM d, y h:mm:ss a' }}</dd>
					<dt>User:</dt>
					<dd>{{ (snapshotState$ | async).data.displayName }}</dd>
				</dl>
				<a [routerLink]="['./', 'history']" class="history-snapshot__btn">{{ historyLabel }}</a>
			</div>
		</ng-container>
	</ng-container>

	<ng-template #noHistory>
		<div>
			<p>No recent history.</p>
		</div>
	</ng-template>

	<ng-template #error>
		<div class="error">
			<p>Unable to load history snapshot:</p>
			<div class="error__message">{{ (snapshotState$ | async).error.message }}</div>
		</div>
	</ng-template>
</div>