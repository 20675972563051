import { RelationshipType } from '../../scp-common/models/relationship-type';
import { District } from '../../districts/models/district.model';
import { Address, PreferencesEntity } from '../../scp-common/models';
import { RateAgreement } from './rate-agreement.model';
import { RelationshipManager } from './relationship-manager.model';

export enum SchoolLevel {
	ELEMENTARY = 1,
	MIDDLE,
	HIGH,
	COLLEGE,
	OTHER,
}

export let SchoolLevelLookup = [];
SchoolLevelLookup[SchoolLevel.ELEMENTARY] = { id: SchoolLevel.ELEMENTARY, name: 'Elementary' };
SchoolLevelLookup[SchoolLevel.MIDDLE] = { id: SchoolLevel.MIDDLE, name: 'Middle' };
SchoolLevelLookup[SchoolLevel.HIGH] = { id: SchoolLevel.HIGH, name: 'High' };
SchoolLevelLookup[SchoolLevel.COLLEGE] = { id: SchoolLevel.COLLEGE, name: 'College' };
SchoolLevelLookup[SchoolLevel.OTHER] = { id: SchoolLevel.OTHER, name: 'Other' };

export enum SchoolType {
	PUBLIC = 1,
	PRIVATE,
	COLLEGE,
	OTHER,
	ADULT,
	CHARTER,
	NATIONAL,	//Rapid Rentals
	HOME,
	NOTLISTED,
}

export let SchoolTypeLookup = [];
SchoolTypeLookup[SchoolType.PUBLIC] = { id: SchoolType.PUBLIC, name: 'Public' };
SchoolTypeLookup[SchoolType.PRIVATE] = { id: SchoolType.PRIVATE, name: 'Private' };
SchoolTypeLookup[SchoolType.COLLEGE] = { id: SchoolType.COLLEGE, name: 'College' };
SchoolTypeLookup[SchoolType.OTHER] = { id: SchoolType.OTHER, name: 'Other' };
SchoolTypeLookup[SchoolType.ADULT] = { id: SchoolType.ADULT, name: 'Adult' };
SchoolTypeLookup[SchoolType.CHARTER] = { id: SchoolType.CHARTER, name: 'Charter' };
SchoolTypeLookup[SchoolType.NATIONAL] = { id: SchoolType.NATIONAL, name: 'National' };
SchoolTypeLookup[SchoolType.HOME] = { id: SchoolType.HOME, name: 'Home' };
SchoolTypeLookup[SchoolType.NOTLISTED] = { id: SchoolType.NOTLISTED, name: 'Not Listed' };


export enum SchoolDelivery {
	No,
	Ship,
	Deliver,
}

export enum ServiceLevel {
	Standard = 1,
	RapidRental,
}

export enum RoutineService {
	None,
	Weekly,
	EveryOtherWeek,
	OnDemand,
	Occasionally,
}

export enum CallDay {
	None = 0,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
}

export enum NodeLevel {
	School = 1,
	District,
	SupervisoryDistrict,
	ZipCode,
	County,
	State,
	Country,
}

export enum InactiveReason {
	Closed,
	Duplicate,
	Pending,
}

export const NodeLevelNames = [
	'',
	'School',
	'District',
	'Supervisory District',
	'Zip Code',
	'County',
	'State',
	'Country',
];

export interface SchoolInfo {
	isActive: boolean;
	shortName: string;
	longName: string;
	districtId: number;
	districtName: string;
	type: SchoolType;
	level: SchoolLevel;
	lowGrade?: number;
	highGrade?: number;
}

export interface Contacts {
	phone: string;
	fax: string;
	website: string;
}

export interface OnlineRentals {
	onlineRental: boolean;
	shipToHome: boolean;
	schoolDelivery: SchoolDelivery;
	serviceLevel: ServiceLevel;
	exclusiveServiceAccountId: string;
	exclusiveServiceAccountName: string;
}

export interface SchoolLookup {
	id: number;
	longName: string;
	countyName: string;
	city: string;
	state: string;
	inheritPreferenceSet: boolean;
}

export interface NewSchool {
	longName: string;
	schoolType: SchoolType;
	schoolLevel: SchoolLevel;
	physicalAddress: Address;
  mailingAddress: Address;
  phone: string;
}

export interface SchoolDetails extends PreferencesEntity {
	//	School Info
	id: number;
	isActive: boolean;
	shortName: string;
	longName: string;
	districtId: number;
	districtName: string;
	type: SchoolType;
	level: SchoolLevel;
	lowGrade?: number;
	highGrade?: number;
	inactiveReason: InactiveReason;

	//	Physical Address
	physicalAddressId: number;
	physicalAddress: Address;

	//	 Mailing Address
	mailingAddressId: number;
	mailingAddress: Address;

	//	Contacts;
	phone: string;
	fax: string;
	website: string;

	//	Current Rate
	currentRateAgreementId: number;
	currentRateAgreement: RateAgreement;
	ratePackageNodeLevel?: NodeLevel;

	//	Future Rate
	futureRateAgreementId: number;
	futureRateAgreement: RateAgreement;
	futureRatePackageNodeLevel?: NodeLevel;

	//	School Calendar
	schoolStartFrom: string;
	schoolStartTo: string;
	schoolEndFrom: string;
	schoolEndTo: string;

	//	Relationship Manager
	inheritRelationshipManager: boolean;
	relationshipManager: RelationshipManager;
	relationshipManagerEmail: string;
	inheritedRelationshipManager: RelationshipManager;
	inheritRelationshipType: boolean;
	relationshipType: { id: number, relationshipType: RelationshipType };
	inheritedRelationshipType: { id: number, relationshipType: RelationshipType };
	routineService: RoutineService;
	callDay: CallDay;

	//	OnlineRentals;
	onlineRental: boolean;
	shipToHome: boolean;
	schoolDelivery: SchoolDelivery;
	serviceLevel: ServiceLevel;
	exclusiveServiceAccountId?: number;
	exclusiveServiceAccountCode?: number;
	exclusiveServiceAccountName: string;
	offersBandshare: boolean;

	//	Rental Returns
	returnsAllowed: boolean;
	sessionLocation: string;
	summerLocation: string;
	hasBlackoutPeriod: boolean;
	blackoutStart: string;
	blackoutEnd: string;

	//	Additional Details
	code: string;
	primaryId?: number;
    ncesSchoolId: string;
    ncesIdSource: string;
	summerBillingDate: string;
	fallBillingDate: string;
	summerCustomerInHandDate: string;
	fallCustomerInHandDate: string;
	summerFree: boolean;
	longitude: string;
	latitude: string;
	ianaTimeZoneId: string;

	inheritPreferenceSet: boolean;
	preferenceSetId: number;
}

export interface TeacherLookup {
	id: number;
	name: string;
	countyName: string;
	city: string;
	state: string;
}
