import { Action } from '@ngrx/store';

import { TeacherSearchResultsItem, TeacherSearchCriteria, TeacherPagingSorting } from '../../models';
import { HttpHeaders } from '@angular/common/http';
import { SchoolDetails } from '../../../schools/models';
import { District } from '../../../districts/models';

export enum TeacherSearchResultsActionTypes {
    ProcessQueryString = '[TeacherSearchResults] Process Query String',
	LoadTeacherSearchResults = '[TeacherSearchResults] Load Search Results',
    LoadTeacherSearchResultsSuccess = '[TeacherSearchResults] Load Teacher Success',
    LoadTeacherSearchResultsFailure = '[TeacherSearchResults] Load Teacher Details Failure',
    UpdateEditorState = '[TeacherSearchResults] Update Editor State',
    UpdateTeacherInfo = '[TeacherSearchResults] Update Teacher Info',
    UpdateTeacherInfoSuccess = '[TeacherSearchResults] Update Teacher Info Success',
    UpdateTeacherInfoFailure = '[TeacherSearchResults] Update Teacher Info Failure',
    NextPage = '[TeacherSearchResults] Next Page',
    PrevPage = '[TeacherSearchResults] Prev Page',
    SetPageSize = '[TeacherSearchResults] Set Page Size',
    LastPage = '[TeacherSearchResults] Last Page',
    FirstPage = '[TeacherSearchResults] First Page',
    SortBy = '[TeacherSearchResults] Sort By',
    GetResultsForSchool = '[TeacherSearchResults] Get Results for School',
    GetResultsForDistrict = '[TeacherSearchResults] Get Results for District',
}

export class ProcessQueryString implements Action {
    readonly type = TeacherSearchResultsActionTypes.ProcessQueryString;
    constructor(public payload: string) { }
}

export class LoadTeacherSearchResults implements Action {
    readonly type = TeacherSearchResultsActionTypes.LoadTeacherSearchResults;
    constructor(public payload: TeacherSearchCriteria) { }
}

export class LoadTeacherSearchResultsSuccess implements Action {
    readonly type = TeacherSearchResultsActionTypes.LoadTeacherSearchResultsSuccess;
    constructor(public payload: TeacherSearchResultsItem[], public httpHeaders: HttpHeaders) { }
}

export class LoadTeacherSearchResultsFailure implements Action {
    readonly type = TeacherSearchResultsActionTypes.LoadTeacherSearchResultsFailure;
	constructor(public payload: any) { }
}

export class NextPage implements Action {
    readonly type = TeacherSearchResultsActionTypes.NextPage;
    constructor(public payload: TeacherSearchCriteria, public pagingSorting: TeacherPagingSorting) { }
}

export class PrevPage implements Action {
    readonly type = TeacherSearchResultsActionTypes.PrevPage;
    constructor(public payload: TeacherSearchCriteria, public pagingSorting: TeacherPagingSorting) { }
}

export class SetPageSize implements Action {
    readonly type = TeacherSearchResultsActionTypes.SetPageSize;
    constructor(public payload: number, public searchCriteria: TeacherSearchCriteria, ) { }
}

export class LastPage implements Action {
    readonly type = TeacherSearchResultsActionTypes.LastPage;
    constructor(public payload: TeacherSearchCriteria, public pagingSorting: TeacherPagingSorting) { }
}

export class FirstPage implements Action {
    readonly type = TeacherSearchResultsActionTypes.FirstPage;
    constructor(public payload: TeacherSearchCriteria, public pagingSorting: TeacherPagingSorting) { }
}

export class SortBy implements Action {
    readonly type = TeacherSearchResultsActionTypes.SortBy;
    constructor(public payload: string, public searchCriteria: TeacherSearchCriteria, public pagingSorting: TeacherPagingSorting) { }
}

export class GetResultsForSchool implements Action {
    readonly type = TeacherSearchResultsActionTypes.GetResultsForSchool;
    constructor(public payload: SchoolDetails, public searchCriteria: TeacherSearchCriteria) { }
}

export class GetResultsForDistrict implements Action {
    readonly type = TeacherSearchResultsActionTypes.GetResultsForDistrict;
    constructor(public payload: District, public searchCriteria: TeacherSearchCriteria) { }
}

export type TeacherSearchResultsActions =
    | ProcessQueryString
    | LoadTeacherSearchResults
    | LoadTeacherSearchResultsSuccess
    | LoadTeacherSearchResultsFailure
    | NextPage
    | PrevPage
    | SetPageSize
    | LastPage
    | FirstPage
    | SortBy
    | GetResultsForSchool
    | GetResultsForDistrict;
