import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { Event, EventSchool } from '../../models';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { QrCodeFiledownloadState } from '../../store/reducers/details.reducer';

@Component({
    selector: 'event-schools-attending',
    templateUrl: './event-schools-attending.component.html',
})
export class EventSchoolsAttendingComponent {
    @Input() event: Event;
    @Input() editMode = false;
    @Output() remove: EventEmitter<EventSchool> = new EventEmitter<EventSchool>();

    qrCodeFiledownloadState$: Observable<QrCodeFiledownloadState>;

    constructor(private _store: Store<fromStore.EventsState>) {

    }

    ngOnInit() {
      this.qrCodeFiledownloadState$ = this._store.pipe(select(fromStore.getQrCodeFileDownloadState));
    }

    removeSchool(school: EventSchool) {
        this.remove.emit(school);
    }

    downloadQrCodes(e: Event) {
      this._store.dispatch(new fromStore.DownloadQrCodes({ id: e.id }));
    }
}
