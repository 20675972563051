import { Component, Input } from '@angular/core';

import { District } from '../../models';

@Component({
	selector: 'district-header',
	templateUrl: './district-header.component.html',
})
export class DistrictHeaderComponent {
	@Input() district: District;

	get id(): string {
		return this.district.id.toString();
	}

	get longName(): string {
		return this.district.longName;
	}
}
