import { Action } from '@ngrx/store';
import { ProductGroup } from '../../../scp/preferences/models';
import { HttpErrorResponse } from '@angular/common/http';

import { InstrumentClass, InstrumentCombo, Product, RentalCategory } from '../../models';

export enum MerchActionTypes {
	LoadClasses = '[Merch] Load Classes',
	LoadClassesSuccess = '[Merch] Load Classes Success',
  	LoadClassesFailure = '[Merch] Load Classes Failure',
	LoadCombos = '[Merch] Load Combos',
	LoadCombosSuccess = '[Merch] Load Combos Success',
	LoadCombosFailure = '[Merch] Load Combos Failure',
	LoadProductGroups = '[Merch] Load Product Groups',
	LoadProductGroupsSuccess = '[Merch] Load Product Groups Success',
	LoadProductGroupsFailure = '[Merch] Load Product Groups Failure',
	LoadProducts = '[Merch] Load Products',
	LoadProductsSuccess = '[Merch] Load Products Success',
	LoadProductsFailure = '[Merch] Load Products Failure',
	LoadRentalCategories = '[RentalCategory] Load Rental Category',
    LoadRentalCategoriesSuccess = '[RentalCategory] Load Rental Category Success',
    LoadRentalCategoriesFailure = '[RentalCategory] Load Rental Category Failure',
	LoadRentalCategoryDetails = '[RentalCategory] Load Rental Category Details',
    LoadRentalCategoryDetailsSuccess = '[RentalCategory] Load Rental Category Details Success',
    LoadRentalCategoryDetailsFailure = '[RentalCategory] Load Rental Category Details Failure',
	SetComboAvailability = '[Merch] Set Combo Availability',
	SetComboAvailabilitySuccess = '[Merch] Set Combo Availability Success',
	SetComboAvailabilityFailure = '[Merch] Set Combo Availability Failure',
	DeleteCombo = '[Merch] Delete Combo',
	DeleteComboSuccess = '[Merch] Delete Combo Success',
	DeleteComboFailure = '[Merch] Delete Combo Failure',
}

export class LoadClasses implements Action {
	readonly type = MerchActionTypes.LoadClasses;
}

export class LoadClassesSuccess implements Action {
	readonly type = MerchActionTypes.LoadClassesSuccess;
	constructor(public payload: InstrumentClass[]) { }
}

export class LoadClassesFailure implements Action {
	readonly type = MerchActionTypes.LoadClassesFailure;
	constructor(public payload: any) { }
}

export class LoadCombos implements Action {
	readonly type = MerchActionTypes.LoadCombos;
	constructor(public payload: number) { }
}

export class LoadCombosSuccess implements Action {
	readonly type = MerchActionTypes.LoadCombosSuccess;
	constructor(public payload: { classId: number, combos: InstrumentCombo[] }) { }
}

export class LoadCombosFailure implements Action {
	readonly type = MerchActionTypes.LoadCombosFailure;
	constructor(public payload: { classId: number, error: any }) { }
}

export class LoadProductGroups implements Action {
  readonly type = MerchActionTypes.LoadProductGroups;
}

export class LoadProductGroupsSuccess implements Action {
  readonly type = MerchActionTypes.LoadProductGroupsSuccess;
  constructor(public payload: ProductGroup[]) { }
}

export class LoadProductGroupsFailure implements Action {
  readonly type = MerchActionTypes.LoadProductGroupsFailure;
  constructor(public payload: any) { }
}

export class LoadProducts implements Action {
  readonly type = MerchActionTypes.LoadProducts;
  constructor(public payload: number) { }
}

export class LoadProductsSuccess implements Action {
  readonly type = MerchActionTypes.LoadProductsSuccess;
  constructor(public payload: { productGroupId: number, products: Product[] }) { }
}

export class LoadProductsFailure implements Action {
  readonly type = MerchActionTypes.LoadProductsFailure;
  constructor(public payload: { productGroupId: number, error: any }) { }
}

export class LoadRentalCategories implements Action {
    readonly type = MerchActionTypes.LoadRentalCategories;
    constructor(public payload: string) { }
}

export class LoadRentalCategoriesSuccess implements Action {
    readonly type = MerchActionTypes.LoadRentalCategoriesSuccess;
    constructor(public payload: { rentalCategories: RentalCategory[] }) { }
}

export class LoadRentalCategoriesFailure implements Action {
    readonly type = MerchActionTypes.LoadRentalCategoriesFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadRentalCategoryDetails implements Action {
    readonly type = MerchActionTypes.LoadRentalCategoryDetails;
    constructor(public payload: number) { }
}

export class LoadRentalCategoryDetailsSuccess implements Action {
    readonly type = MerchActionTypes.LoadRentalCategoryDetailsSuccess;
    constructor(public payload: { rentalCategory: RentalCategory }) { }
}

export class LoadRentalCategoryDetailsFailure implements Action {
    readonly type = MerchActionTypes.LoadRentalCategoryDetailsFailure;
    constructor(public payload: { error: any }) { }
}

export class SetComboAvailability implements Action {
	readonly type = MerchActionTypes.SetComboAvailability;
	constructor(public payload: { classId: number, available: boolean, ids: number[] }) { }
}

export class SetComboAvailabilitySuccess implements Action {
	readonly type = MerchActionTypes.SetComboAvailabilitySuccess;
	constructor(public payload: { classId: number, available: boolean, ids: number[] }) { }
}

export class SetComboAvailabilityFailure implements Action {
	readonly type = MerchActionTypes.SetComboAvailabilityFailure;
	constructor(public payload: { classId: number, error: any }) { }
}

export class DeleteCombo implements Action {
	readonly type = MerchActionTypes.DeleteCombo;
	constructor(public payload: { classId: number, id: number }) { }
}

export class DeleteComboSuccess implements Action {
	readonly type = MerchActionTypes.DeleteComboSuccess;
	constructor(public payload: { classId: number, id: number }) { }
}

export class DeleteComboFailure implements Action {
	readonly type = MerchActionTypes.DeleteComboFailure;
	constructor(public payload: { classId: number, id: number, error: any }) { }
}

export type MerchActions =
	| LoadClasses
	| LoadClassesSuccess
	| LoadClassesFailure
	| LoadCombos
	| LoadCombosSuccess
	| LoadCombosFailure
	| LoadProductGroups
	| LoadProductGroupsSuccess
	| LoadProductGroupsFailure
	| LoadProducts
	| LoadProductsSuccess
	| LoadProductsFailure
	| LoadRentalCategories
	| LoadRentalCategoriesSuccess
	| LoadRentalCategoriesFailure
	| LoadRentalCategoryDetails
    | LoadRentalCategoryDetailsSuccess
    | LoadRentalCategoryDetailsFailure
	| SetComboAvailability
	| SetComboAvailabilitySuccess
	| SetComboAvailabilityFailure
	| DeleteCombo
	| DeleteComboSuccess
	| DeleteComboFailure;
