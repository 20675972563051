import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromSchoolModels from '../../schools/models';
import * as validators from '../../../core/validators';

import { Event } from '../models';
import * as fromStore from '../store';
import { TeacherLookupCriteria } from '../../teachers/models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'event-host-editor',
    templateUrl: './event-host-editor.component.html',
})
export class EventHostEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('schoolDropdown', {static: true})
    schoolDropdown: ElementRef;

    @ViewChild('teacherDropdown', {static: true})
    teacherDropdown: ElementRef;

    alive = true;
    event$: Observable<Event>;
    event: Event;

    schoolFoundationDropdown: any;
    teacherFoundationDropdown: any;

    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;

    lookupSchoolId: number;
    schoolLookup$: Observable<fromStore.SchoolLookupState>;
    schoolLookup: fromStore.SchoolLookupState;
    lookupSchoolName: string;
    schoolByNameLookup$: Observable<fromStore.SchoolByNameLookupState>;
    schoolByNameLookup: fromStore.SchoolByNameLookupState;

    teacherLookup$: Observable<fromStore.TeacherLookupState>;
    teacherLookup: fromStore.TeacherLookupState;
    teacherByCriteriaLookup$: Observable<fromStore.TeacherByCriteriaLookupState>;
    teacherByCriteriaLookup: fromStore.TeacherByCriteriaLookupState;

    editor: FormGroup;

    constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('EventInfoEditorComponent()...');
    }

    ngOnInit() {
        console.log('EventInfoEditorComponent.ngOnInit()...');

        this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
        this.event$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(d => this.event = d);

        this.createForm();

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventHostPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.schoolLookup$ = this.store.pipe(select(fromStore.getDetailsStateSchoolLookup));
        this.schoolLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(schoolLookup => {
            this.schoolLookup = schoolLookup;

            if (schoolLookup && schoolLookup.pending && schoolLookup.schoolId == this.lookupSchoolId) {
                this.editor.controls.hostSchoolName.disable();
            }
            else {
                this.editor.controls.hostSchoolName.enable();
            }

            if (schoolLookup && schoolLookup.school) {
                this.editor.controls['hostSchoolName'].patchValue(schoolLookup.school.longName);
            }
        });

        this.schoolByNameLookup$ = this.store.pipe(select(fromStore.getDetailsStateSchoolByNameLookup));
        this.schoolByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(schoolByNameLookup => {
            this.schoolByNameLookup = schoolByNameLookup;

            if (schoolByNameLookup && schoolByNameLookup.schools && schoolByNameLookup.name == this.lookupSchoolName) {
                //Trigger the drop down
                if (!this.schoolFoundationDropdown) {
                    this.schoolFoundationDropdown = new Foundation.Dropdown($(this.schoolDropdown.nativeElement).first());
                }

                const native = $(this.schoolDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });

        this.teacherLookup$ = this.store.pipe(select(fromStore.getDetailsStateTeacherLookup));
        this.teacherLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(teacherLookup => {
            this.teacherLookup = teacherLookup;

            if (teacherLookup && teacherLookup.pending) {
                this.editor.controls.hostTeacherName.disable();
            }
            else {
                this.editor.controls.hostTeacherName.enable();
            }

            if (teacherLookup && teacherLookup.teacher) {
                this.editor.controls['hostTeacherName'].patchValue(teacherLookup.teacher.name);
            }
        });

        this.teacherByCriteriaLookup$ = this.store.pipe(select(fromStore.getDetailsStateTeacherByCriteriaLookup));
        this.teacherByCriteriaLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(teacherByCriteriaLookup => {
            this.teacherByCriteriaLookup = teacherByCriteriaLookup;

            if (teacherByCriteriaLookup && teacherByCriteriaLookup.teachers) {
                //Trigger the drop down
                if (!this.teacherFoundationDropdown) {
                    this.teacherFoundationDropdown = new Foundation.Dropdown($(this.teacherDropdown.nativeElement).first());
                }

                const native = $(this.teacherDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });
    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty && !this.panelState.updating;
    }

    ngAfterViewInit() {
        console.log(this.schoolDropdown.nativeElement);
    }

    ngOnDestroy() {
        console.log('EventInfoEditorComponent.ngOnDestroy()...');
        this.alive = false;
    }

    createForm() {
        const hostSchoolName = new FormControl(this.event.hostingSchoolName);

        this.editor = new FormGroup({
            hostSchoolId: new FormControl(this.event.hostingSchoolId, [Validators.required, validators.NumericValidators.min(1)]),
            hostSchoolName: hostSchoolName,
            hostTeacherId: new FormControl(this.event.hostingTeacherId, [Validators.required, validators.NumericValidators.min(1)]),
            hostTeacherName: new FormControl(this.event.hostingTeacherName),
        });
    }

    lookupSchool() {
        this.lookupSchoolId = Number(this.editor.value.hostSchoolId);

        //Dispatch an action called EventSchoolSearchById
        this.store.dispatch(new fromStore.SchoolLookupById({ panel: fromStore.PanelId.EventHost, schoolId: this.lookupSchoolId }));
    }

    lookupSchoolByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupSchoolName = this.editor.value.hostSchoolName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.schoolDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (this.lookupSchoolName && this.lookupSchoolName.length > 3) {
                //Dispatch an action called EventSchoolSearchById
                this.store.dispatch(new fromStore.SchoolLookupByName({ panel: fromStore.PanelId.EventHost, name: this.lookupSchoolName }));
            }
        }
    }

    selectSchool(school: fromSchoolModels.SchoolLookup) {
        if (school) {
            this.editor.controls['hostSchoolName'].patchValue(school.longName);
            this.editor.controls['hostSchoolId'].patchValue(school.id);
        }
    }

    lookupTeacher() {
        const hostTeacherId = Number(this.editor.value.hostTeacherId);

        //Dispatch an action called EventTeacherSearchById
        this.store.dispatch(new fromStore.TeacherLookupById({ panel: fromStore.PanelId.EventHost, teacherId: hostTeacherId }));
    }

    lookupTeacherByCriteria($event: any) {
        const criteria : TeacherLookupCriteria = {
            name: this.editor.value.hostTeacherName,
            districtId: this.event.hostingSchoolDistrictId,
        };

        if (this.event.eventAddress && this.event.eventAddress.zip)
            criteria.zip = this.event.eventAddress.zip;
        else if (this.event.hostingSchoolAddress && this.event.hostingSchoolAddress.zip)
            criteria.zip = this.event.hostingSchoolAddress.zip.substring(0, 5);

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.teacherDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (criteria.name && criteria.name.length > 3) {
                this.store.dispatch(new fromStore.TeacherLookupByCriteria({ panel: fromStore.PanelId.EventHost, criteria }));
            }
        }
    }

    selectTeacher(teacher: fromSchoolModels.TeacherLookup) {
        if (teacher) {
            this.editor.controls['hostTeacherName'].patchValue(teacher.name);
            this.editor.controls['hostTeacherId'].patchValue(teacher.id);
        }
    }

    resetForm() {

    }

    onSubmit() {
        console.log('onSubmit: ', this.editor.status);

        const hostingSchoolId = this.editor.value.hostSchoolId;
        const hostingSchoolName = this.editor.value.hostSchoolName;
        const hostingTeacherId = this.editor.value.hostTeacherId;
        const hostingTeacherName = this.editor.value.hostTeacherName;

        const event = {
            ...this.event,
            hostingSchoolId,
            hostingSchoolName,
            hostingTeacherId,
            hostingTeacherName
        };

        this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventHost, event }));
    }

}
