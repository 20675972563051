import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { tap, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Operation, compare } from 'fast-json-patch';

import * as detailsActions from '../actions/details.actions';
import * as fromServices from '../../../scp-common/services';
import * as fromSelectors from '../selectors';
import * as fromModels from '../../models';
import { DetailsState } from '..';

import * as fromRoot from '../../../../core/store';
import { MessageType, ProblemDetails } from '../../../../core/models';

@Injectable()
export class EventDetailsEffects {
    constructor(
        private actions$: Actions,
        private eventService: fromServices.EventService,
        private schoolService: fromServices.SchoolService,
        private teacherService: fromServices.TeacherService,
        private addressService: fromServices.AddressService,
        private store$: Store<DetailsState>,
    ) { }

    
    loadDistrict$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.LoadEvent),
        switchMap((action: detailsActions.LoadEvent) => {
            console.log('Load Event Effect');

            return this.eventService.loadEvent(action.payload).pipe(
                tap(d => {
                    console.log('loadEvent returned:', d);
                }),
                map(event => {
                    if (event === null) {
                        return new detailsActions.LoadEventFailure('Event Not Found');
                    }

                    return new detailsActions.LoadEventSuccess(event);
                }),
                catchError(error => of(new detailsActions.LoadEventFailure(error)))
            );
        })
    ));

    
    loadApprovedPromos$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.LoadApprovedPromos),
        switchMap((action: detailsActions.LoadApprovedPromos) => {
            console.log('Load Event Effect');

            return this.eventService.loadApprovedPromos().pipe(
                tap(d => {
                    console.log('loadEvent returned:', d);
                }),
                map(approvedPromos => {
                    if (event === null) {
                        return new detailsActions.LoadApprovedPromosFailure('Unable to load event promotions');
                    }

                    return new detailsActions.LoadApprovedPromosSuccess(approvedPromos);
                }),
                catchError(error => of(new detailsActions.LoadApprovedPromosFailure(error)))
            );
        })
    ));

    
    loadRelationshipManagers$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.LoadRelationshipManagers),
        switchMap((action: detailsActions.LoadRelationshipManagers) => {
            return this.eventService.loadRelationshipManagers(action.payload.id).pipe(
                map(relationshipManagers => {
                    if (event === null) {
                        return new detailsActions.LoadRelationshipManagersFailure({ errors: 'Unable to load event promotions' });
                    }

                    return new detailsActions.LoadRelationshipManagersSuccess({ id: action.payload.id, relationshipManagerEmails: relationshipManagers });
                }),
                catchError(error => of(new detailsActions.LoadRelationshipManagersFailure({ errors: error })))
            );
        })
    ));

    
    verifyAddress$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.EventVerifyAddress),
        tap(a => console.log('Entering verifyAddress$ Effect: ', a)),
        switchMap((a: detailsActions.EventVerifyAddress) => {
            const { panel, address } = a.payload;

            return this.addressService.verifyAddress(address).pipe(
                map(verification => {
                    return new detailsActions.EventVerifyAddressSuccess({ panel, verification });
                }),
                catchError(errors => of(new detailsActions.EventVerifyAddressFailure({ panel, address, errors })))
            );
        }),
        tap(a => console.log('Exiting verifyAddress$ Effect: ', a)),
    ));

    
    verifyAddressFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.EventVerifyAddressFailure),
        //tap(a => console.log('Entering verifyAddress$ Effect: ', a)),
        switchMap((a: detailsActions.EventVerifyAddressFailure) => {
            const { address, panel, errors } = a.payload;
            return of(new fromRoot.DisplayMessage({
                message: 'Address verification service is unavailable at this time.',
                messageType: MessageType.info,
                toast: true
            }));
        }),
        tap(a => console.log('Exiting verifyAddress$ Effect: ', a)),
    ));

    
    schoolLookupById$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.SchoolLookupById),
        switchMap((action: detailsActions.SchoolLookupById) => {
            console.log('Load School Lookup By ID Effect');

            return this.schoolService.lookupSchoolById(action.payload.schoolId).pipe(
                tap(d => {
                    console.log('schoolLookupById$ returned:', d);
                }),
                map(schoolResult => {
                    if (schoolResult === null) {
                        return new detailsActions.SchoolLookupByIdFailure({ panel: action.payload.panel, errors: 'School Not Found' });
                    }

                    return new detailsActions.SchoolLookupByIdSuccess({ panel: action.payload.panel, school: schoolResult });
                }),
                catchError(error => of(new detailsActions.SchoolLookupByIdFailure(error)))
            );
        })
    ));

    
    schoolLookupByName$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.SchoolLookupByName),
        switchMap((action: detailsActions.SchoolLookupByName) => {
            console.log('Load School Lookup By ID Effect');

            return this.schoolService.lookupSchoolByName(action.payload.name).pipe(
                tap(d => {
                    console.log('schoolLookupById$ returned:', d);
                }),
                map(schoolResult => {
                    if (schoolResult === null) {
                        return new detailsActions.SchoolLookupByNameFailure({ panel: action.payload.panel, errors: 'School Not Found' });
                    }

                    return new detailsActions.SchoolLookupByNameSuccess({ panel: action.payload.panel, schools: schoolResult });
                }),
                catchError(error => of(new detailsActions.SchoolLookupByNameFailure(error)))
            );
        })
    ));

    
    teacherLookupById$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.TeacherLookupById),
        switchMap((action: detailsActions.TeacherLookupById) => {
            console.log('Load Teacher Lookup By ID Effect');

            return this.teacherService.lookupTeacherById(action.payload.teacherId).pipe(
                tap(d => {
                    console.log('teacherLookupById$ returned:', d);
                }),
                map(teacherResult => {
                    if (teacherResult === null) {
                        return new detailsActions.TeacherLookupByIdFailure({ panel: action.payload.panel, errors: 'Teacher Not Found' });
                    }

                    return new detailsActions.TeacherLookupByIdSuccess({ panel: action.payload.panel, teacher: teacherResult });
                }),
                catchError(error => of(new detailsActions.TeacherLookupByIdFailure(error)))
            );
        })
    ));

    
    teacherLookupByCriteria$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.TeacherLookupByCriteria),
        switchMap((action: detailsActions.TeacherLookupByCriteria) => {
            console.log('Load Teacher Lookup By Criteria Effect');

            return this.teacherService.lookupTeacherByCriteria(action.payload.criteria).pipe(
                tap(d => {
                    console.log('teacherLookupByCriteria$ returned:', d);
                }),
                map(teacherResult => {
                    if (teacherResult === null) {
                        return new detailsActions.TeacherLookupByCriteriaFailure({ panel: action.payload.panel, errors: 'Teacher Not Found' });
                    }

                    return new detailsActions.TeacherLookupByNameSuccess({ panel: action.payload.panel, teachers: teacherResult });
                }),
                catchError(error => of(new detailsActions.TeacherLookupByCriteriaFailure(error)))
            );
        })
    ));

    //	NOTE: seemed more appropriate to handle the json patch creation here than to send the 2 district objects int the service layer
    
    updateEventPanel$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.UpdateEventPanel),
        tap(a => {
            console.log('updateEventPanel$ Effect');
        }),
        //	get the current value of the event from the store
        withLatestFrom(this.store$.pipe(select(fromSelectors.getDetailsStateEvent))),
        //	create a json patch by comparing the current event with the updated version
        //	typescript note: using array destructuring to pull the withLatestFrom output into individual parameters to map
        map(([action, event]: [detailsActions.UpdateEventPanel, fromModels.Event]) => {
            const patch = compare(event, action.payload.event);

            console.log('new event:', action.payload.event, 'current event:', event, 'patch:', patch);

            //	the event service needs the id & the json patch
            return { panel: action.payload.panel, event, patch };
        }),
        switchMap(({ panel, event, patch }: { panel: string, event: fromModels.Event, patch: Operation[] }) => {
            if (patch.length == 0) {
                console.log('updateEventPanel$ Effect: Skipping empty patch...');

                return of(new detailsActions.UpdateEventPanelSuccess({ panel, event }));
            }

            console.log('updateEventPanel$ Effect: Calling eventService.patchEvent(', event.id, ', ', patch, ')...');

            return this.eventService.patchEvent(event.id, patch).pipe(
                map(event => new detailsActions.UpdateEventPanelSuccess({ panel, event })),
                catchError(errors => {
                    return of(new detailsActions.UpdateEventPanelFailure({ panel, errors }));
                }),
            );
        }),
    ));

    
    updateEventPanelFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.UpdateEventPanelFailure),
        tap(a => {
            console.log(a);
            console.log('updateEventPanel$ Effect');
        }),
        switchMap((a: detailsActions.UpdateEventPanelFailure) => {
            const httpErrorResponse = a.payload.errors;

            const message = this.getErrorMessage(httpErrorResponse) || 'Unhandled exception has occurred';
            return of(new fromRoot.DisplayMessage({
                message,
                messageType: MessageType.alert,
                toast: false
            }));
        })
    ));

    
    createEvent$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.CreateEvent),
        switchMap((action: detailsActions.CreateEvent) => {
            console.log('CreateEvent Effect');

            return this.eventService.createEvent(action.payload.event).pipe(
                tap(d => {
                    console.log('createEvent$ returned:', d);
                }),
                map(createEventResult => {
                    if (createEventResult === null) {
                        return new detailsActions.CreateEventFailure({ errors: 'Error creating event' });
                    }

                    return new detailsActions.CreateEventSuccess({ id: createEventResult });
                }),
                catchError(error => of(new detailsActions.CreateEventFailure({ errors: error })))
            );
        }),
    ));

    
    createEventFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.CreateEventFailure),
        tap(a => console.log('Entering createEventFailure$ Effect: ', a)),
      switchMap((action: detailsActions.CreateEventFailure) => {

        const httpErrorResponse = action.payload.errors;

        const message = this.getErrorMessage(httpErrorResponse) || 'Unhandled exception has occurred';
        return of(new fromRoot.DisplayMessage({
          message,
          messageType: MessageType.alert,
          toast: false
        }));
        }),
        tap(a => console.log('Exiting createEventFailure$ Effect: ', a)),
    ));

    
    copyEvent$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.CopyEvent),
        switchMap((action: detailsActions.CopyEvent) => {
            const { id, name, date } = action.payload;

            return this.eventService.copyEvent(id, name, date).pipe(
                map(event => {
                    if (event) {
                        return new detailsActions.CopyEventSuccess({ event: event });
                    }
                }),
                catchError(errors => {
                    return of(new detailsActions.CopyEventFailure({ errors: errors }));
                })
            );
        })
    ));

    
    copyEventFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.CopyEventFailure),
        switchMap((a: detailsActions.CopyEventFailure) => {
        const httpErrorResponse = a.payload.errors;

        const message = this.getErrorMessage(httpErrorResponse) || 'Unhandled exception has occurred';
        return of(new fromRoot.DisplayMessage({
          message,
          messageType: MessageType.alert,
          toast: false
        }));
        }),
    ));

    
    downloadQrCodes$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.DownloadQrCodes),
      switchMap((action: detailsActions.DownloadQrCodes) => {
        return this.eventService.getQrCodesFile(action.payload.id).pipe(
          map(success => {
            return new detailsActions.DownloadQrCodesSuccess({ id: action.payload.id });
          }),
          catchError(error => {
            return of(new detailsActions.DownloadQrCodesFailure(error));
          }),
        );
      })
    ));

    
    downloadQrCodesFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.EventActionTypes.DownloadQrCodesFailure),
        switchMap((action: detailsActions.DownloadQrCodesFailure) => {
        return of(new fromRoot.DisplayMessage({
                message: this.getErrorMessage(action.payload.error),
                messageType: MessageType.alert,
                toast: false,
            }));
        }),
    ));

    private getErrorMessage(response: HttpErrorResponse): string {
        if (response.status === 400 && response.error) {
            try {
                const problemDetails = response.error as ProblemDetails;
                if (problemDetails) {
                    const firstError = problemDetails.errors ? problemDetails.errors[Object.keys(problemDetails.errors)[0]] : null;
                    if (firstError)
                        return firstError;
                    else if (problemDetails.title)
                        return problemDetails.title;
                }
            }
            catch (ex) {
                console.warn(ex);
            }
        }

        return response.error ? response.error.message : response.message;
    }
}
