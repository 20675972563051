import { Action } from '@ngrx/store';

import { SchoolSearchResultsItem, SchoolSearchCriteria, SchoolPagingSorting } from '../../models';
import { HttpHeaders } from '@angular/common/http';
import { TeacherDetails } from '../../../teachers/models';
import { District } from '../../../districts/models';

export enum SchoolSearchResultsActionTypes {
    ProcessQueryString = '[SchoolSearchResults] Process Query String',
	LoadSchoolSearchResults = '[SchoolSearchResults] Load Search Results',
    LoadSchoolSearchResultsSuccess = '[SchoolSearchResults] Load School Success',
    LoadSchoolSearchResultsFailure = '[SchoolSearchResults] Load School Details Failure',
    UpdateEditorState = '[SchoolSearchResults] Update Editor State',
    UpdateSchoolInfo = '[SchoolSearchResults] Update School Info',
    UpdateSchoolInfoSuccess = '[SchoolSearchResults] Update School Info Success',
    UpdateSchoolInfoFailure = '[SchoolSearchResults] Update School Info Failure',
    NextPage = '[SchoolSearchResults] Next Page',
    PrevPage = '[SchoolSearchResults] Prev Page',
    SetPageSize = '[SchoolSearchResults] Set Page Size',
    LastPage = '[SchoolSearchResults] Last Page',
    FirstPage = '[SchoolSearchResults] First Page',
    SortBy = '[SchoolSearchResults] Sort By',
    GetResultsForTeacher = '[SchoolSearchResults] Get Results for Teacher',
    GetResultsForDistrict = '[SchoolSearchResults] Get Results for District',
}

export class ProcessQueryString implements Action {
    readonly type = SchoolSearchResultsActionTypes.ProcessQueryString;
    constructor(public payload: string) { }
}

export class LoadSchoolSearchResults implements Action {
    readonly type = SchoolSearchResultsActionTypes.LoadSchoolSearchResults;
    constructor(public payload: SchoolSearchCriteria) { }
}

export class LoadSchoolSearchResultsSuccess implements Action {
    readonly type = SchoolSearchResultsActionTypes.LoadSchoolSearchResultsSuccess;
    constructor(public payload: SchoolSearchResultsItem[], public httpHeaders: HttpHeaders) { }
}

export class LoadSchoolSearchResultsFailure implements Action {
    readonly type = SchoolSearchResultsActionTypes.LoadSchoolSearchResultsFailure;
	constructor(public payload: any) { }
}

export class NextPage implements Action {
    readonly type = SchoolSearchResultsActionTypes.NextPage;
    constructor(public payload: SchoolSearchCriteria, public pagingSorting: SchoolPagingSorting) { }
}

export class PrevPage implements Action {
    readonly type = SchoolSearchResultsActionTypes.PrevPage;
    constructor(public payload: SchoolSearchCriteria, public pagingSorting: SchoolPagingSorting) { }
}

export class SetPageSize implements Action {
    readonly type = SchoolSearchResultsActionTypes.SetPageSize;
    constructor(public payload: number, public searchCriteria: SchoolSearchCriteria, ) { }
}

export class LastPage implements Action {
    readonly type = SchoolSearchResultsActionTypes.LastPage;
    constructor(public payload: SchoolSearchCriteria, public pagingSorting: SchoolPagingSorting) { }
}

export class FirstPage implements Action {
    readonly type = SchoolSearchResultsActionTypes.FirstPage;
    constructor(public payload: SchoolSearchCriteria, public pagingSorting: SchoolPagingSorting) { }
}

export class SortBy implements Action {
    readonly type = SchoolSearchResultsActionTypes.SortBy;
    constructor(public payload: string, public searchCriteria: SchoolSearchCriteria, public pagingSorting: SchoolPagingSorting) { }
}

export class GetResultsForTeacher implements Action {
    readonly type = SchoolSearchResultsActionTypes.GetResultsForTeacher;
    constructor(public payload: TeacherDetails, public searchCriteria: SchoolSearchCriteria) { }
}

export class GetResultsForDistrict implements Action {
    readonly type = SchoolSearchResultsActionTypes.GetResultsForDistrict;
    constructor(public payload: District, public searchCriteria: SchoolSearchCriteria) { }
}

export type SchoolSearchResultsActions =
    | ProcessQueryString
    | LoadSchoolSearchResults
    | LoadSchoolSearchResultsSuccess
    | LoadSchoolSearchResultsFailure
    | NextPage
    | PrevPage
    | SetPageSize
    | LastPage
    | FirstPage
    | SortBy
    | GetResultsForTeacher
    | GetResultsForDistrict;
