import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { ValidateCurrentDateOrLater } from '../../event.validators';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'opus-event-copy',
  templateUrl: './event-copy.component.html'
})
export class EventCopyComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue' };
  maxDate = new Date('9999-12-31');

  editor = this.fb.group({
    name: ['', Validators.required],
    date: [new Date(), [Validators.required, ValidateCurrentDateOrLater]]
  });

  @Input() set eventName(name: string) {
    const control = this.editor.get('name');
    control.setValue(name);
  }

  constructor(private fb: FormBuilder) {
    console.log('EventCopyComponent()...');
  }

  ngOnInit() {
    console.log('EventCopyComponent.ngOnInit()...');
  }

  get name() {
    return this.editor.valid ? this.editor.value.name : null;
  }

  get date() {
    return this.editor.valid ? this.editor.value.date : null;
  }
}
