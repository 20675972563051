import { Component, Input } from '@angular/core';

import { StepsComponent } from './steps.component';
import { TabComponent } from './tab.component';

@Component({
	selector: 'opus-step',
	templateUrl: './step.component.html',
})
export class StepComponent extends TabComponent {
	constructor(private steps: StepsComponent) {
		super(steps);
	}
}
