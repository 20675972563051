import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSchoolDetails from '../reducers/school-details.reducer';

export const getSchoolDetailsState = createSelector(
	fromFeature.getSchoolsState,
	(state: fromFeature.SchoolsState) => state.details
);

export const getDistrictLookupsState = createSelector(
	getSchoolDetailsState,
	fromSchoolDetails.getDistrictLookupsState,
);

export const getDistrictByNameLookupState = createSelector(
	getDistrictLookupsState,
	fromSchoolDetails.getDistrictByNameLookupState,
);

export const getSchoolDetailsEntity = createSelector(
	getSchoolDetailsState,
	fromSchoolDetails.getSchoolDetailsEntity,
);

//export const getRateAgreement = createSelector(
//	getSchoolDetailsState,
//	fromSchoolDetails.getRateAgreement,
//);

export const getSchoolDetailsLoaded = createSelector(
	getSchoolDetailsState,
	fromSchoolDetails.getSchoolDetailsLoaded,
);

export const getSchoolDetailsLoading = createSelector(
	getSchoolDetailsState,
	fromSchoolDetails.getSchoolDetailsLoading,
);

export const getDetailsStatePanels = createSelector(
	getSchoolDetailsState,
	fromSchoolDetails.getDetailsStatePanels
);

export const getDetailsStateInfoPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Info],
);

export const getDetailsStatePhysAddrPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.PhysAddr] as fromFeature.AddressPanelState,
);

export const getDetailsStateMailAddrPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.MailAddr] as fromFeature.AddressPanelState,
);

export const getDetailsStateCountyLookup = createSelector(
	getDetailsStatePhysAddrPanel,
	fromSchoolDetails.getDetailsStateCountyLookup,
);

export const getDetailsStatePhysAddrZipLookup = createSelector(
	getDetailsStatePhysAddrPanel,
	fromSchoolDetails.getDetailsStateZipLookup,
);

export const getDetailsStateMailAddrZipLookup = createSelector(
	getDetailsStateMailAddrPanel,
	fromSchoolDetails.getDetailsStateZipLookup,
);

export const getDetailsStateCounties = createSelector(
	getDetailsStateCountyLookup,
	(cl) => cl.lookupStateCode != null ? cl.counties[cl.lookupStateCode] : [],
);

export const getDetailsStateContactsPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Contacts]
);

export const getDetailsStateCalendarPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Calendar]
);

export const getDetailsStateOnlineRentalsPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.OnlineRentals]
);

export const getDetailsStateRentalReturnsPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.RentalReturns]
);

export const getDetailsStateCurrentRatePanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.CurrentRate]
);

export const getDetailsStateFutureRatePanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.FutureRate]
);

export const getDetailsStateRateAgreements = createSelector(
	getSchoolDetailsState,
	fromSchoolDetails.getDetailsStateRateAgreements,
);

export const getDetailsStateRelationshipPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Relationship]
);

export const getDetailsStateAdditionalDetailsPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.AdditionalDetails]
);
