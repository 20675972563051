<!-- edit mode -->
<form [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Event Information" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editHostSchoolId" class="property-block__label">District ID</label>
            <div class="property-block__value">
                <div class="input-group u-m-b-0">
                    <input type="text" name="districtId" class="input-group-field" formControlName="districtId">
                    <div class="input-group-button">
                        <button type="button" (click)="lookupDistrict()">Apply</button>
                    </div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editHostSchoolName" class="property-block__label">District Name</label>
            <div class="property-block__value">
                <span data-toggle="districtTypeAhead">
                    <input type="text" id="editDistrictName" autocomplete="off" (keyup)="lookupDistrictByName($event)" (keydown)="$event.stopPropagation()" formControlName="districtName" role="combobox">
                </span>
                <ul class="dropdown-menu" role="menu" id="districtTypeAhead" #districtDropdown>
                    <li role="menu-item" *ngFor="let district of districtByNameLookup?.districts">
                        <button type="button" class="dropdown-menu__action" role="option" data-close (click)="selectDistrict(district)">
                            <span class="l-block">{{ district.longName }} ({{ district.id }})</span>
                            <span class="l-block">{{ district.county }}</span>
                            <span class="l-block">{{ district.city }}, {{ district.state}}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
        </div>
    </div>
</form>
