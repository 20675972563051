<div class="entity-detail">
    <nav class="u-m-b-16">
        <ul class="breadcrumbs">
            <li><a routerLink="/search/districts">Districts</a></li>
            <li>{{ (districtDetails$ | async).longName }}</li>
        </ul>
    </nav>
    <header class="row u-m-b-40">
        <div class="columns">
            <district-header [district]="districtDetails$ | async"></district-header>
        </div>

        <div class="columns small-4">
            <aside class="card">
				<div class="card__section">
					<opus-history-snapshot contextName="District" [contextId]="(districtDetails$ | async).id"></opus-history-snapshot>
				</div>
            </aside>
        </div>
    </header>
    <nav class="row u-m-b-40">
        <div class="columns">
            <ul class="menu menu--anchors" role="menu">
                <li role="menuitem" opusAuthorize="District.ViewSchools"><a routerLink="." fragment="schools">Schools</a></li>
                <li role="menuitem" opusAuthorize="District.ViewTeachers"><a routerLink="." fragment="teachers">Teachers</a></li>
            </ul>
        </div>
        <div class="columns">
            <ul class="menu menu--external-links" role="menu">
                <li role="menuitem" opusAuthorize="Preference.View"><a [routerLink]="['./', 'preferences']">Preferences</a></li>
            </ul>
        </div>
    </nav>
    <div class="row">
		<div class="columns">
			<opus-property-block label="District Information" [isEditing]="(panels$ | async)['info'].editing" (toggleEditor)="onToggleEditor('info', $event)" policy="District.EditInfo" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(districtDetails$ | async).relationshipManagerEmail">
				<opus-district-info viewer [district]="districtDetails$ | async"></opus-district-info>
				<opus-district-info-editor editor></opus-district-info-editor>
			</opus-property-block>

			<opus-property-block label="Physical Address" [isEditing]="(panels$ | async)['physAddr'].editing" (toggleEditor)="onToggleEditor('physAddr', $event)" policy="District.EditPhysicalAddress">
				<opus-district-address viewer [address]="(districtDetails$ | async).physicalAddress" [isPhysical]="true"></opus-district-address>
				<opus-district-address-editor editor *ngIf="(panels$ | async)['physAddr'].editing" [isPhysical]="true" [district]="districtDetails$ | async"></opus-district-address-editor>
			</opus-property-block>

			<opus-property-block label="Mailing Address" [isEditing]="(panels$ | async)['mailAddr'].editing" (toggleEditor)="onToggleEditor('mailAddr', $event)" policy="District.EditMailingAddress">
				<opus-district-address viewer [address]="(districtDetails$ | async).mailingAddress" [isPhysical]="false"></opus-district-address>
				<opus-district-address-editor editor [isPhysical]="false" [district]="districtDetails$ | async"></opus-district-address-editor>
			</opus-property-block>

			<opus-property-block label="School Calendar" [isEditing]="(panels$ | async)['calendar'].editing" (toggleEditor)="onToggleEditor('calendar', $event)" policy="District.EditSchoolCalendar" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(districtDetails$ | async).relationshipManagerEmail">
				<opus-district-calendar viewer [district]="districtDetails$ | async"></opus-district-calendar>
				<opus-district-calendar-editor editor></opus-district-calendar-editor>
			</opus-property-block>
		</div>

		<div class="columns">
			<opus-property-block label="Relationship Manager" [isEditing]="(panels$ | async)['relationship'].editing" (toggleEditor)="onToggleEditor('relationship', $event)" policy="District.EditRelationshipManagerPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(districtDetails$ | async).relationshipManagerEmail">
				<opus-district-relationship viewer [district]="districtDetails$ | async"></opus-district-relationship>
				<opus-district-relationship-editor editor></opus-district-relationship-editor>
			</opus-property-block>

			<opus-property-block label="Contacts" [isEditing]="(panels$ | async)['contacts'].editing" (toggleEditor)="onToggleEditor('contacts', $event)" policy="District.EditContactPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(districtDetails$ | async).relationshipManagerEmail">
				<opus-district-contacts viewer [district]="districtDetails$ | async"></opus-district-contacts>
				<opus-district-contacts-editor editor></opus-district-contacts-editor>
			</opus-property-block>

			<opus-property-block label="Additional Details" [isEditing]="(panels$ | async)['addl-details'].editing" (toggleEditor)="onToggleEditor('addl-details', $event)" policy="District.EditAdditionalDetailsPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(districtDetails$ | async).relationshipManagerEmail">
				<opus-district-addl-details viewer [district]="districtDetails$ | async"></opus-district-addl-details>
				<opus-district-addl-details-editor editor></opus-district-addl-details-editor>
			</opus-property-block>
		</div>
    </div>
    <opus-school-searchresults-page id="schools" [district]="districtDetails$ | async" opusAuthorize="District.ViewSchools"></opus-school-searchresults-page>
    <br />
    <opus-teacher-searchresults-page id="teachers" [district]="districtDetails$ | async" opusAuthorize="District.ViewTeachers"></opus-teacher-searchresults-page>
    <br />
</div>

