import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../store';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-account-dropdown',
    template: `
		<div class="account-dropdown">
			<p class="account-dropdown__message">
				Signed in as:
				<button class="account-dropdown__trigger link link--on-dark" type="button" data-toggle="adDropdown">
					{{ (userData$ | async)?.preferred_username }}
				</button>
			</p>
			<ul class="dropdown-menu dropdown-menu--tiny" id="adDropdown" role="menu" #accountDropdown >
				<li role="menuitem"><a class="dropdown-menu__action" (click)="logout()">Log out</a></li>
			</ul>
		</div>
	`,
})
export class AccountDropdownComponent implements OnInit {
    @ViewChild('accountDropdown', {static: true})
    accountDropdown: ElementRef;
    accountFoundationDropdown: any;
    userData$: Observable<any>;

    constructor(private store: Store<fromStore.SecurityState>, private oidcSecurityService: OidcSecurityService) {

    }

    ngOnInit(): void {
        this.userData$ = this.store.pipe(select(fromStore.getUserData));

        if (!this.accountFoundationDropdown) {
            this.accountFoundationDropdown = new Foundation.Dropdown($(this.accountDropdown.nativeElement).first());
        }
    }

    logout() {
        this.store.dispatch(new fromStore.SetUserData(null));
        this.oidcSecurityService.logoff();
    }
}
