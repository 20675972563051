import { EventDetailsComponent } from './event-details.component';
import { EventInfoEditorComponent } from './event-info-editor.component';
import { EventHostEditorComponent } from './event-host-editor.component';
import { EventSearchResultsComponent } from './event-searchresults.component';
import { EventDateTimeEditorComponent } from './event-date-time-editor.component';
import { EventLocationEditorComponent } from './event-location-editor.component';
import { EventStaffingEditorComponent } from './event-staffing-editor.component';
import { EventMeetingDetailsEditorComponent } from './event-meeting-details-editor.component';
import { EventSchoolsAttendingEditorComponent } from './event-schools-attending-editor.component';
import { EventPromoEditorComponent } from './event-promo-editor.component';

export const containers: any[] = [
    EventDetailsComponent,
    EventInfoEditorComponent,
    EventHostEditorComponent,
    EventSearchResultsComponent,
    EventDateTimeEditorComponent,
    EventLocationEditorComponent,
    EventStaffingEditorComponent,
    EventMeetingDetailsEditorComponent,
    EventSchoolsAttendingEditorComponent,
    EventPromoEditorComponent
];

export * from './event-details.component';
export * from './event-info-editor.component';
export * from './event-host-editor.component';
export * from './event-searchresults.component';
export * from './event-date-time-editor.component';
export * from './event-location-editor.component';
export * from './event-staffing-editor.component';
export * from './event-meeting-details-editor.component';
export * from './event-schools-attending-editor.component';
export * from './event-promo-editor.component';
