<div class="card__section">
    <p class="typography--body" *ngIf="!criteria?.rentalCategoryCode && !criteria?.state">Select a category, state, or both to view shipping charges.</p>
    <p class="typography--body" *ngIf="(shippingCharges$ | async)?.length === 0 && (criteria?.rentalCategoryCode || criteria?.state)">There are no shipping charges.</p>
    <div class="table" role="table" *ngIf="(shippingCharges$ | async)?.length">
        <span role="rowgroup">
            <span class="table__row table__row--header" role="row">
                <span class="table__cell table__cell--align-left" role="columnheader">Category Name</span>
                <span class="table__cell" role="columnheader">Category Code</span>
                <span class="table__cell" role="columnheader">State</span>
                <span class="table__cell" role="columnheader">Standard Ground</span>
            </span>
        </span>
        <span role="rowgroup">
            <span class="table__row" role="row" *ngFor="let charge of shippingCharges$ | async; odd as isOdd; last as isLast"
                [class.table__row--alt]="isOdd"
                [class.table__row--last]="isLast">
                <span class="table__cell table__cell--align-left" role="cell">{{ charge.rentalCategoryName }}</span>
                <span class="table__cell" role="cell">{{ charge.rentalCategoryCode }}</span>
                <span class="table__cell" role="cell">{{ charge.state }}</span>
                <span class="table__cell" role="cell">{{ charge.ground | currency }}</span>
            </span>
        </span>
    </div>
</div>
