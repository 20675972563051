import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Event } from '../models';
import * as fromStore from '../store';

@Component({
  selector: 'event-info-editor',
  templateUrl: './event-info-editor.component.html',
})
export class EventInfoEditorComponent implements OnInit, OnDestroy {
  alive = true;
  event$: Observable<Event>;
  event: Event;
  panelState$: Observable<fromStore.PanelState>;
  panelState: fromStore.PanelState;
  editor: FormGroup;
  eventTypes: any[] = [
    { id: 1, name: 'Rental Event' },
    { id: 2, name: 'Step Up Event' },
    { id: 3, name: 'Instrument Fitting' },
    { id: 4, name: 'Instrumental Demonstration' }
  ];

  constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder) {
    console.log('EventInfoEditorComponent()...');
  }

  ngOnInit() {
    console.log('EventInfoEditorComponent.ngOnInit()...');

    this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
    this.event$.pipe(
      takeWhile(() => this.alive)
    ).subscribe(d => this.event = d);

    this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventInfoPanel));
    this.panelState$.pipe(
      takeWhile(() => this.alive),
      tap(s => {
        if (!s.editing && this.panelState && this.panelState.editing) {
          console.log('EventInfoEditorComponent.panelState$: clearing form...');
          this.resetForm();
        }
      }),
    ).subscribe(s => this.panelState = s);

    this.createForm();
  }

  get canSave(): boolean {
    return this.editor.valid && this.editor.dirty && !this.panelState.updating;
  }

  ngOnDestroy() {
    console.log('EventInfoEditorComponent.ngOnDestroy()...');
    this.alive = false;
  }

  createForm() {
    this.editor = this.fb.group({
      isActive: [this.isActive],
      name: [this.event.name, Validators.required],
      note: [this.event.note],
      eventType: [this.event.eventTypeId, Validators.required]
    });
  }

  resetForm() {
    this.editor.reset({
      isActive: [this.isActive],
      name: this.event.name,
      note: this.event.note,
      eventType: this.event.eventTypeId,
    });
  }

  get isActive() {
      return this.event.isActive ? 'Yes' : 'No';
  }

  onSubmit() {
    const isActive = this.editor.value.isActive === 'Yes';
    const name = this.editor.value.name;
    const note = this.editor.value.note;
    const eventTypeId = this.editor.value.eventType;

    const event: Event = {
      ...this.event,
      isActive,
      name,
      note,
      eventTypeId
    };

    this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventInfo, event }));

  }
}
