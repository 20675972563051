import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { ProgramDetails } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-program-additional-editor',
    templateUrl: './program-additional-editor.component.html',
})
export class ProgramAdditionalEditorComponent implements OnInit, OnDestroy {
	alive = true;
	programDetails$: Observable<ProgramDetails>;
	panelState$: Observable<fromStore.PanelState>;
	program: ProgramDetails;
	panelState: fromStore.PanelState;
	editor: FormGroup;

	constructor(private store: Store<fromStore.ProgramsState>, private fb: FormBuilder) {
		console.log('ProgramInfoEditorComponent()...');
	}

	ngOnInit() {
		console.log('ProgramInfoEditorComponent.ngOnInit()...');

		this.programDetails$ = this.store.pipe(select(fromStore.getProgramDetailsEntity));
		this.programDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => this.program = s);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateAdditionalDetailsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('ProgramInfoEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('ProgramInfoEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
        this.editor = this.fb.group({
            beginningGrade: [this.beginningGrade],
            numberBeginningGrade: [this.program.numberBeginningGrade, Validators.pattern('^[0-9]*$')],
			numberBeginningMusicians: [this.program.numberBeginningMusicians, Validators.pattern('^[0-9]*$')],
			numberSchoolInstruments: [this.program.numberSchoolInstruments, Validators.pattern('^[0-9]*$')],
		});
	}

	resetForm() {
		this.editor.reset({
            beginningGrade: this.beginningGrade,
            numberBeginningGrade: this.program.numberBeginningGrade,
            numberBeginningMusicians: this.program.numberBeginningMusicians,
            numberSchoolInstruments: this.program.numberSchoolInstruments,
		});
    }

    get beginningGrade() {
        if (this.program.beginningGrade == null) {
            return -1;
        }

        const value = Number.parseInt(this.program.beginningGrade);
        if (value == null || value < 0) {
            return -1;
        }
        else if (value > 12) {
            return 13; // College
        }

        return value;
    }

	get canSave(): boolean {
		return this.editor.valid && this.editor.dirty;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status, this.editor.value);

		const program: ProgramDetails = {
			...this.program,
            beginningGrade: this.editor.value.beginningGrade,
            numberBeginningGrade: this.editor.value.numberBeginningGrade,
            numberBeginningMusicians: this.editor.value.numberBeginningMusicians,
            numberSchoolInstruments: this.editor.value.numberSchoolInstruments,
		};

		console.log('onSubmit: ', program);

        this.store.dispatch(new fromStore.UpdateProgramPanel({ panel: fromStore.PanelId.AdditionalDetails, program }));
	}
}
