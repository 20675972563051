import { ValidatorFn, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class NumericValidators {
  static max(max: number): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      const val: number = control.value;

      if (control.pristine || control.pristine) {
        return null;
      }
      if (val <= max) {
        return null;
      }
      return { max: true };
    };
  }

  static min(min: number): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      const val: number = control.value;

      if (control.pristine || control.pristine) {
        return null;
      }
      if (val >= min) {
        return null;
      }
      return { min: true };
    };
  }

  static minOrNull(min: number): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } | null => {
      const val: number = control.value;

      if (control.pristine || control.pristine) {
        return null;
      }
      if (!val || val >= min) {
        return null;
      }
      return { min: true };
    };
  }

  static quantity(min: number = 1, max: number = 99, required: boolean = true): ValidatorFn {
    return (ctrl: FormControl): { [key: string]: boolean } | null => {
      try {
        const val = ctrl.value;
        const sval = val === null ? '' : (val + '').trim();

        if (!required && sval === '') {
          return null;
        }

        if (val >= min && val <= max && Math.trunc(val) == val) {
          return null;
        }

        return { quantity: true };
      } catch (x) {
        console.log('NumericValidators.quantity(): ', x);

        return null;
      }
    };
  }

  static gradeValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
    try {
      const allGrade = 'All';
      const lowGrade = group.get('lowGrade').value;
      const highGrade = group.get('highGrade').value;

      if (lowGrade === null) group.get('lowGrade').setValue(allGrade);

      if (highGrade === null) group.get('highGrade').setValue(allGrade);

      if (lowGrade === allGrade && highGrade === allGrade) return null;

      if (lowGrade === allGrade && highGrade !== allGrade) return { highGrade: true };

      if (highGrade === allGrade && lowGrade !== allGrade) return { lowGrade: true };

      if (Number(lowGrade) > Number(highGrade)) return { lowGrade: true };

      return null;
    } catch (x) {
      console.log('NumericValidators.gradeValidator(): ', x);

      return null;
    }
  }

  static price(min: number = 0, max?: number, required: boolean = true): ValidatorFn {
    return (ctrl: FormControl): { [key: string]: boolean } | null => {
      try {
        const val = ctrl.value;
        const sval = val === null ? '' : (val + '').trim();
        const ret = { price: true };

        if (!required && sval === '') {
          return null;
        }

        if (val < min) {
          return ret;
        }

        if (max !== null && val > max) {
          return ret;
        }

        if (sval.includes('.') && sval.split('.')[1].length > 2) {
          return ret;
        }

        return null;
      } catch (x) {
        console.log('NumericValidators.price(): ', x);

        return null;
      }
    };
  }
}
