import { Action } from '@ngrx/store';
import { Configuration } from '../../models';

export enum ConfigurationActionTypes {
    LoadConfiguration = '[Configuration] Load Configuration',
    LoadConfigurationSuccess = '[Configuration] Load Configuration Success',
    LoadConfigurationFailure = '[Configuration] Load Configuration Failure',
}

export class LoadConfiguration implements Action {
    readonly type = ConfigurationActionTypes.LoadConfiguration;
    constructor(public payload: any) { }
}

export class LoadConfigurationSuccess implements Action {
    readonly type = ConfigurationActionTypes.LoadConfigurationSuccess;
    constructor(public payload: Configuration) { }
}

export class LoadConfigurationFailure implements Action {
    readonly type = ConfigurationActionTypes.LoadConfigurationFailure;
    constructor(public payload: any) { }
}

export type ConfigurationActions =
    | LoadConfiguration
    | LoadConfigurationSuccess
    | LoadConfigurationFailure;
