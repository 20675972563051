import { Action } from '@ngrx/store';

import { DistrictSearchResultsItem, DistrictSearchCriteria, DistrictPagingSorting } from '../../models';
import { HttpHeaders } from '@angular/common/http';

export enum DistrictSearchResultsActionTypes {
    ProcessQueryString = '[DistrictSearchResults] Process Query String',
	LoadDistrictSearchResults = '[DistrictSearchResults] Load Search Results',
    LoadDistrictSearchResultsSuccess = '[DistrictSearchResults] Load District Success',
    LoadDistrictSearchResultsFailure = '[DistrictSearchResults] Load District Details Failure',
    UpdateEditorState = '[DistrictSearchResults] Update Editor State',
    UpdateDistrictInfo = '[DistrictSearchResults] Update District Info',
    UpdateDistrictInfoSuccess = '[DistrictSearchResults] Update District Info Success',
    UpdateDistrictInfoFailure = '[DistrictSearchResults] Update District Info Failure',
    NextPage = '[DistrictSearchResults] Next Page',
    PrevPage = '[DistrictSearchResults] Prev Page',
    SetPageSize = '[DistrictSearchResults] Set Page Size',
    LastPage = '[DistrictSearchResults] Last Page',
    FirstPage = '[DistrictSearchResults] First Page',
    SortBy = '[DistrictSearchResults] Sort By',
}

export class ProcessQueryString implements Action {
    readonly type = DistrictSearchResultsActionTypes.ProcessQueryString;
    constructor(public payload: string) { }
}

export class LoadDistrictSearchResults implements Action {
    readonly type = DistrictSearchResultsActionTypes.LoadDistrictSearchResults;
    constructor(public payload: DistrictSearchCriteria) { }
}

export class LoadDistrictSearchResultsSuccess implements Action {
    readonly type = DistrictSearchResultsActionTypes.LoadDistrictSearchResultsSuccess;
    constructor(public payload: DistrictSearchResultsItem[], public httpHeaders: HttpHeaders) { }
}

export class LoadDistrictSearchResultsFailure implements Action {
    readonly type = DistrictSearchResultsActionTypes.LoadDistrictSearchResultsFailure;
	constructor(public payload: any) { }
}

export class NextPage implements Action {
    readonly type = DistrictSearchResultsActionTypes.NextPage;
    constructor(public payload: DistrictSearchCriteria, public pagingSorting: DistrictPagingSorting) { }
}

export class PrevPage implements Action {
    readonly type = DistrictSearchResultsActionTypes.PrevPage;
    constructor(public payload: DistrictSearchCriteria, public pagingSorting: DistrictPagingSorting) { }
}

export class SetPageSize implements Action {
    readonly type = DistrictSearchResultsActionTypes.SetPageSize;
    constructor(public payload: number, public searchCriteria: DistrictSearchCriteria, ) { }
}

export class LastPage implements Action {
    readonly type = DistrictSearchResultsActionTypes.LastPage;
    constructor(public payload: DistrictSearchCriteria, public pagingSorting: DistrictPagingSorting) { }
}

export class FirstPage implements Action {
    readonly type = DistrictSearchResultsActionTypes.FirstPage;
    constructor(public payload: DistrictSearchCriteria, public pagingSorting: DistrictPagingSorting) { }
}

export class SortBy implements Action {
    readonly type = DistrictSearchResultsActionTypes.SortBy;
    constructor(public payload: string, public searchCriteria: DistrictSearchCriteria, public pagingSorting: DistrictPagingSorting) { }
}

export type DistrictSearchResultsActions =
    | ProcessQueryString
    | LoadDistrictSearchResults
    | LoadDistrictSearchResultsSuccess
    | LoadDistrictSearchResultsFailure
    | NextPage
    | PrevPage
    | SetPageSize
    | LastPage
    | FirstPage
    | SortBy;
