<form novalidate aria-label="Contacts" [formGroup]="editor" (ngSubmit)="onSubmit()">
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editMusicSupervisor" class="property-block__label">Music Supervisor</label>
			<div class="property-block__value">
				<input type="text" id="editMusicSupervisor" formControlName="musicSupervisor">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editOfficeContact" class="property-block__label">Office Contact</label>
			<div class="property-block__value">
				<input type="text" id="editOfficeContact" formControlName="officeContact">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editPhone" class="property-block__label">Phone</label>
			<div class="property-block__value">
				<input type="text" id="editPhone" formControlName="phone">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editFax" class="property-block__label">Fax</label>
			<div class="property-block__value">
				<input type="text" id="editFax" formControlName="fax">
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editWebsite" class="property-block__label">Website</label>
			<div class="property-block__value">
				<input type="text" id="editWebsite" formControlName="website">
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
