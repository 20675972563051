<opus-history-header *ngIf="context" [context]="context"></opus-history-header>

<div class="card">
    <div class="card__section">
        <div class="row align-middle">
            <div class="columns">
                <h2 class="card__title">Events</h2>
            </div>
            <div class="columns shrink">
                <form name="historyDatesForm" class="history-dates" [formGroup]="historySearchForm" novalidate (ngSubmit)="filterHistoryEvents()">
                    <div class="row align-middle">
                        <label for="fromDate" class="columns columns--tight shrink">From</label>
                        <span class="columns columns--tight shrink">
                            <input id="fromDate" type="text" placeholder="From Date" class="form-control" bsDatepicker [bsConfig]="bsConfig" aria-label="From Date" title="From Date" formControlName="fromDate" required>
                        </span>
                        <label for="toDate" class="columns columns--tight shrink">To</label>
                        <span class="columns columns--tight shrink">
                            <input id="toDate" type="text" placeholder="To Date" class="form-control" bsDatepicker [bsConfig]="bsConfig" aria-label="To Date" title="To Date" formControlName="toDate" required>
                        </span>
                        <span class="columns columns--tight shrink">
                            <button type="submit" class="btn btn--light btn--small" [disabled]="!historySearchForm.valid">Update</button>
                        </span>
                    </div>
                    <div class="row">
                        <div class="columns columns--tight">
                            <div class="error" *ngIf="historySearchForm.invalid" role="alert">
                                <div class="error__message" *ngIf="(fromDate.invalid && fromDate.errors.required) || (toDate.invalid && toDate.errors.required)">Both dates are required</div>
                                <div class="error__message" *ngIf="historySearchForm.errors && historySearchForm.errors.invalidrange">Start date must be prior to end date</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <hr class="card__divider">
    <div class="history">
        <aside class="history__sidebar-wrapper">
            <div class="card__section" *ngIf="(history$ | async) && (history$ | async).events.length == 0">
                <p class="typography--body">There is no recent {{ context.scope.toLowerCase() }} history for {{ context.entityName }}.</p>
            </div>
            <!--<history-sidebar history-items="$ctrl.history.items" on-select-item="$ctrl.onSelectItem(item)" ng-if="$ctrl.history.items"></history-sidebar>-->
            <opus-history-sidebar *ngIf="(history$ | async) && (history$ | async).events.length > 0" [events]="(history$ | async).events" (selectEvent)="onSelectEvent($event)"></opus-history-sidebar>
        </aside>
        <div class="history__divider"></div>
        <section class="history__details-wrapper">
            <opus-history-details [event]="selectedEvent"></opus-history-details>
        </section>
    </div>
</div>
