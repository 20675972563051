import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { State } from '../../../core/store';
import { SchoolDetails, RelationshipManager } from '../models';
import { RelationshipType, LocationManager } from '../../scp-common/models';
import * as fromStore from '../store';
import * as fromScpStore from '../../scp-common/store';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-school-relationship-editor',
    templateUrl: './school-relationship-editor.component.html',
})
export class SchoolRelationshipEditorComponent implements OnInit, OnDestroy {
    @ViewChild('managerDropdown', {static: true})
    managerDropdown: ElementRef;

    managerFoundationDropdown: any;

    alive = true;
    schoolDetails$: Observable<SchoolDetails>;
    panelState$: Observable<fromStore.PanelState>;
    locations$: Observable<fromScpStore.LocationsState>;
    school: SchoolDetails;
    panelState: fromStore.PanelState;
    editor: FormGroup;
    locationId?: number = null;
    locationCode?: number = null;
    inLocationLookup = false;
    locationLookupError: string = null;
    isSubmitting = false;
    managerByNameLookup$: Observable<fromScpStore.ManagerByNameLookupState>;
    managerByNameLookup: fromScpStore.ManagerByNameLookupState;
    lookupManagerName: string;

    constructor(private store: Store<State>, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
        console.log('SchoolRelationshipEditorComponent()...');
    }

    ngOnInit() {
        console.log('SchoolRelationshipEditorComponent.ngOnInit()...');

        this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
        this.schoolDetails$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(s => {
            console.log('schoolDetails$ sub: ', s);

            this.school = s;
            this.locationId = s.relationshipManager.locationId;
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateRelationshipPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    console.log('SchoolRelationshipEditorComponent.panelState$: clearing form...');
                    this.resetForm();
                }
            }),
        ).subscribe(ps => {
            console.log('panelState$ sub: ', ps);

            this.panelState = ps;
        });

        this.locations$ = this.store.pipe(select(fromScpStore.getLocationsState));
        this.locations$.pipe(
            takeWhile(() => this.alive),
        ).subscribe(ls => {
            console.log('locations$ sub: ', ls);

            if (this.locationCode === null) {
                return;
            }

            const lms: fromScpStore.LocationManagerState = ls.locationManagers[this.locationCode];

            if (lms && !lms.pending && this.inLocationLookup) {
                this.inLocationLookup = false;
                this.locationLookupError = lms.error;

                if (!lms.error) {
                    this.locationId = lms.manager == null ? null : lms.manager.id;
                    this.editor.controls.managerName.patchValue(lms.manager == null ? '' : lms.manager.manager);

                    if (this.isSubmitting) {
                        this.update();
                    }
                } else {
                    this.editor.controls.relationshipManagerCode.setErrors({ 'notFound': true });
                }

                this.isSubmitting = false;
                this.cdr.detectChanges();
            }
        });

        this.createForm();

        this.managerByNameLookup$ = this.store.pipe(select(fromScpStore.getLocationsStateLookup));
        this.managerByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(managerByNameLookup => {
            this.managerByNameLookup = managerByNameLookup;

            if (managerByNameLookup && managerByNameLookup.managers && managerByNameLookup.name == this.lookupManagerName) {
                //Trigger the drop down
                if (!this.managerFoundationDropdown) {
                    this.managerFoundationDropdown = new Foundation.Dropdown($(this.managerDropdown.nativeElement).first());
                }

                const native = $(this.managerDropdown.nativeElement).first();

                if (!native.hasClass('is-open')) {
                    native.foundation('open');
                }

                this.cdr.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        console.log('SchoolRelationshipEditorComponent.ngOnDestroy()...');
        this.alive = false;
    }

    createForm() {
        this.editor = this.fb.group({
            inheritRelationshipManager: [this.school.inheritRelationshipManager],
            relationshipManagerCode: [this.school.relationshipManager.locationCode],
            managerName: [this.school.relationshipManager.name],
            inheritRelationshipType: [this.school.inheritRelationshipType],
            relationshipType: [this.school.relationshipType.relationshipType],
            routineService: [this.school.routineService],
            callDay: [this.school.callDay],
        });

        this.editor.controls.relationshipManagerCode.valueChanges.pipe(
            takeWhile(() => this.alive),
        ).subscribe(code => {
            //	when the Manager ID form field is changed, clear out or restore our locationId if it doesn't match the original
            this.locationId = code == this.school.relationshipManager.locationCode ? this.school.relationshipManager.locationId : null;
            this.locationCode = code;
        });
    }

    resetForm() {
        this.editor.reset({
            inheritRelationshipManager: this.school.inheritRelationshipManager,
            relationshipManagerCode: this.school.relationshipManager.locationCode,
            managerName: this.school.relationshipManager.name,
            inheritRelationshipType: this.school.inheritRelationshipType,
            relationshipType: this.school.relationshipType.relationshipType,
            routineService: this.school.routineService,
            callDay: this.school.callDay,
        });
    }

    get inheritedRelationshipType() {
        return RelationshipType[this.school.inheritedRelationshipType.relationshipType];
    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty;
    }

    get saveButtonLabel(): string {
        return this.panelState.updating ? 'Saving...' : 'Save';
    }

    onSubmit() {
        console.log('onSubmit: ', this.editor.status, this.editor.value);

        const code = this.editor.value.relationshipManagerCode;

        //	if we have a locationId, then it either hasn't been changed, or it has been looked up already
        if (this.locationId !== null) {
            console.log('onSubmit(): Skipping location lookup...', this.locationId);

            this.update();

            return;
        }

        this.inLocationLookup = true;
        this.isSubmitting = true;
        this.store.dispatch(new fromScpStore.GetLocation({ code }));
    }

    lookupManagerByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupManagerName = this.editor.value.managerName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.managerDropdown.nativeElement).first();

            if (native.hasClass('is-open')) {
                native.foundation('close');
            }
        } else {
            if (this.lookupManagerName && this.lookupManagerName.length > 3) {
                this.store.dispatch(new fromScpStore.GetManagersByName({ name: this.lookupManagerName }));
            }
        }
    }

    selectManager(manager: LocationManager) {
        if (manager) {
            this.editor.controls.managerName.patchValue(manager.manager);
            this.editor.controls.relationshipManagerCode.patchValue(manager.code);
            this.locationId = manager.id;
        }
    }

    onApplyManagerId() {
        console.log('onApplyManagerId(): ', this.editor.value.relationshipManagerCode);

        const code = this.editor.value.relationshipManagerCode;

        this.inLocationLookup = true;
        this.store.dispatch(new fromScpStore.GetLocation({ code }));
    }

    private update() {
        console.log('update():', this.editor.value);

        const inheritRelationshipManager = this.editor.value.inheritRelationshipManager;
        const relationshipManager = this.getRelationshipManager();
        const inheritRelationshipType = this.editor.value.inheritRelationshipType;
        const relationshipType = this.getRelationshipType();
        const school: SchoolDetails = {
            ...this.school,
            inheritRelationshipManager,
            relationshipManager,
            inheritRelationshipType,
            relationshipType,
            routineService: this.editor.value.routineService,
            callDay: this.editor.value.callDay,
        };

        console.log('update(): ', school);

        this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: fromStore.PanelId.Relationship, school }));
    }

    private getRelationshipManager(): RelationshipManager {
        //	if we're not inheriting, update the rm properties
        if (!this.editor.value.inheritRelationshipManager) {
            return {
                ...this.school.relationshipManager,
                locationId: this.locationId,
            };
        }

        //	otherwise send it back unchanged
        return {
            ...this.school.relationshipManager,
        };
    }

    private getRelationshipType(): { id: number, relationshipType: RelationshipType } {
        if (!this.editor.value.inheritRelationshipType) {
            return {
                ...this.school.relationshipType,
                relationshipType: +this.editor.value.relationshipType,
            };
        }

        return {
            ...this.school.relationshipType,
        };
    }
}
