 <!-- edit mode -->
<form [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Event date and time" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>

    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editEventDate" class="property-block__label">Date</label>
            <div class="property-block__value">
                <input type="text" name="date" placeholder="Start Date" class="form-control" bsDatepicker [bsConfig]="bsConfig" [maxDate]="maxDate" aria-label="Start Date" title="Start Date" formControlName="eventStartDate" required>
                <div class="error" *ngIf="editor.get('eventStartDate').invalid && (editor.get('eventStartDate').dirty || editor.get('eventStartDate').touched)">
                    <div class="error__message" *ngIf="editor.get('eventStartDate').hasError('required')">Required</div>
                    <div class="error__message" *ngIf="editor.get('eventStartDate').hasError('validCurrentDate')">Date must be today or later</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEventArrivalTime" class="property-block__label">Arrival Time</label>
            <div class="property-block__value">
                <input id="editEventArrivalTime" type="time" name="arrivalTime" formControlName="eventArrivalTime" [ngModel]="this.event.arrival | date:'HH:mm'">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEventStartTime" class="property-block__label">Start Time</label>
            <div class="property-block__value">
                <input id="editEventStartTime" type="time" name="startTime" formControlName="eventStartTime" required [ngModel]="this.event.start | date:'HH:mm'">
                <div class="error" *ngIf="editor.get('eventStartTime').invalid && (editor.get('eventStartTime').dirty || editor.get('eventStartTime').touched)">
                    <div class="error__message" *ngIf="editor.get('eventStartTime').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEventEndTime" class="property-block__label">End Time</label>
            <div class="property-block__value">
                <input id="editEventEndTime" type="time" name="endTime" formControlName="eventEndTime" required [ngModel]="this.event.end | date:'HH:mm'">
                <div class="error" *ngIf="editor.get('eventEndTime').invalid && (editor.get('eventEndTime').dirty || editor.get('eventEndTime').touched)">
                    <div class="error__message" *ngIf="editor.get('eventEndTime').hasError('required')">Required</div>
                </div>
                <div class="error">
                    <div class="error__message" *ngIf="editor.hasError('validTimeAfter')">End time must be after the start time</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="event.eventTypeId == 1">
            <div class="property-block__label" id="expirationDate">Quick Code Expiration Date</div>
            <div class="property-block__value" role="definition" aria-labelledby="expirationDate">{{ event.expirationDate | date:'M/d/yyyy' }}</div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="timezone">Time Zone</div>
            <div class="property-block__value" role="definition" aria-labelledby="timezone">{{ event.eventAtHostSchool ? event.hostingSchoolAddress.ianaTimeZoneId : event.eventAddress.ianaTimeZoneId }}</div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
        </div>
    </div>
</form>

