import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromSchoolModels from '../../schools/models';

import { ProgramDetails, ProgramTeacher } from '../models';
import * as fromStore from '../store';
import * as fromRoot from '../../../core/store';
import { MessageType } from '../../../core/models';
import { TeacherLookupCriteria } from '../../teachers/models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-program-program-teachers-editor',
    templateUrl: './program-program-teachers-editor.component.html',
})
export class ProgramProgramTeachersEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('teacherDropdown', {static: true})
    teacherDropdown: ElementRef;

    alive = true;
    program$: Observable<ProgramDetails>;
    program: ProgramDetails;
    editProgram: ProgramDetails;
    teacherFoundationDropdown: any;
    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;

    lookupTeacherId: number;
    lookupTeacherName: string;
    teacherLookup$: Observable<fromStore.TeacherLookupState>;
    teacherLookup: fromStore.TeacherLookupState;
    selectedTeacher: fromSchoolModels.TeacherLookup = null;

    teacherByCriteriaLookup$: Observable<fromStore.TeacherByCriteriaLookupState>;
    teacherByCriteriaLookup: fromStore.TeacherByCriteriaLookupState;

    editor: FormGroup;

    constructor(private store: Store<fromStore.ProgramsState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('ProgramInfoEditorComponent()...');
    }

    ngOnInit() {
        console.log('ProgramInfoEditorComponent.ngOnInit()...');

        this.editProgram = null;

        this.program$ = this.store.pipe(select(fromStore.getProgramDetailsEntity));
        this.program$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(d => {
            this.program = d;
            this.editProgram = d;
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateProgramTeachersPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.createForm();

        this.teacherLookup$ = this.store.pipe(select(fromStore.getDetailsStateTeacherLookup));
        this.teacherLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(teacherLookup => {
            this.teacherLookup = teacherLookup;
            if (teacherLookup && teacherLookup.teacher && teacherLookup.teacherId == this.lookupTeacherId) {
                this.addProgramTeacher({
                    id: null,
                    teacherId: teacherLookup.teacher.id,
                    teacherName: teacherLookup.teacher.name,
                    isPrimary: this.editProgram.programTeachers.find(pt => pt.isPrimary) === undefined,
                });
                this.ref.detectChanges();
            }
        });

        this.teacherByCriteriaLookup$ = this.store.pipe(select(fromStore.getDetailsStateTeacherByCriteriaLookup));
        this.teacherByCriteriaLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(teacherByCriteriaLookup => {
            this.teacherByCriteriaLookup = teacherByCriteriaLookup;

            if (teacherByCriteriaLookup && teacherByCriteriaLookup.teachers && teacherByCriteriaLookup.criteria && teacherByCriteriaLookup.criteria.name === this.lookupTeacherName) {
                //Trigger the drop down
                if (!this.teacherFoundationDropdown) {
                    this.teacherFoundationDropdown = new Foundation.Dropdown($(this.teacherDropdown.nativeElement).first());
                }

                const native = $(this.teacherDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });
    }

    ngAfterViewInit() {
        //console.log(this.teacherDropdown.nativeElement);
    }

    ngOnDestroy() {
        //console.log('ProgramInfoEditorComponent.ngOnDestroy()...');
        this.teacherLookup$ = null;
        this.teacherLookup = null;
        this.alive = false;
    }

    createForm() {
        this.editor = new FormGroup({
            teacherId: new FormControl(''),
            teacherName: new FormControl(''),
            isPrimary: new FormControl(false)
        });
    }

    lookupTeacher() {
        console.log('lookupTeacher called');
        this.lookupTeacherId = Number(this.editor.value.teacherId);

        //Dispatch an action called ProgramTeacherSearchById
        this.store.dispatch(new fromStore.TeacherLookupById({ panel: fromStore.PanelId.ProgramTeachers, teacherId: this.lookupTeacherId }));
    }

    lookupTeacherByCriteria($event: any) {
        console.log('lookupTeacherByCriteria called');
        this.lookupTeacherName = this.editor.value.teacherName;

        const criteria : TeacherLookupCriteria = {
            name: this.editor.value.teacherName,
            districtId: this.program.school.districtId,
        };

        if (this.program.school.physicalAddress)
            criteria.zip = this.program.school.physicalAddress.zip.substring(0, 5);

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.teacherDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (criteria.name && criteria.name.length > 3) {
                //Dispatch an action called ProgramTeacherSearchById
                this.store.dispatch(new fromStore.TeacherLookupByCriteria({ panel: fromStore.PanelId.ProgramTeachers, criteria }));
            }
        }
    }

    addProgramTeacher(teacher: ProgramTeacher) {
        if (this.editProgram.programTeachers.find(s => s.teacherId == teacher.teacherId) !== undefined) {
            this.store.dispatch(new fromRoot.DisplayMessage({
                message: 'Teacher is already added',
                messageType: MessageType.alert,
                toast: true
            }));
        } else {
            this.editProgram = {
                ...this.editProgram,
                programTeachers: [
                    ...this.editProgram.programTeachers,
                    teacher
                ]
            };
            this.editor.controls['teacherId'].setValue('');
        }
    }

    addTeacher() {
        console.log('addTeacher called');
        if (this.selectedTeacher) {
            this.addProgramTeacher({
                id: null,
                teacherId: this.selectedTeacher.id,
                teacherName: this.selectedTeacher.name,
                isPrimary: this.editProgram.programTeachers.find(pt => pt.isPrimary) === undefined,
            });
            this.selectedTeacher = null;
            this.editor.controls['teacherName'].setValue('');
            this.ref.detectChanges();
        }
    }

    selectTeacher(teacher: fromSchoolModels.TeacherLookup) {
        if (teacher) {
            this.selectedTeacher = teacher;
            this.editor.controls['teacherName'].setValue(teacher.name);
        }
    }

    remove(teacher) {
        if (teacher) {
            let programTeachers = this.editProgram.programTeachers.filter(s => s != teacher);

            if (teacher.isPrimary && programTeachers.length > 0) {
                programTeachers = [...programTeachers.map((pt, i) => i == 0 ? { ...pt, isPrimary: true } : pt)];
            }

            this.editProgram = {
                ...this.editProgram,
                programTeachers,
            };

            this.ref.detectChanges();
            // Mark form as dirty so we enable saving (removing items doesn't affect form)
            this.editor.markAsDirty();
        }
    }

    isPrimary(teacher) {
        const programTeachers = this.editProgram.programTeachers.map(pt => pt.id === teacher.id ? { ...teacher, isPrimary: true } : { ...pt, isPrimary: false });

        this.editProgram = {
            ...this.editProgram,
            programTeachers,
        };

        this.ref.detectChanges();
        this.editor.markAsDirty();
    }

    resetForm() {

    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty;
    }

    get saveButtonLabel(): string {
        return this.panelState.updating ? 'Saving...' : 'Save';
    }

    onSubmit() {
        const program = {
            ...this.program,
            programTeachers: [
                ...this.editProgram.programTeachers
            ]
        };

        this.store.dispatch(new fromStore.UpdateProgramPanel({ panel: fromStore.PanelId.ProgramTeachers, program }));
    }
}
