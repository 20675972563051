import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;
declare var Foundation: any;

//	TODO: this component needs to be moved into scp/search
@Component({
    selector: 'opus-quick-search',
    templateUrl: './quick-search.component.html',
})
export class QuickSearchComponent {
    @ViewChild('searchText', {static: true})
    searchText: ElementRef;

    @ViewChild('searchDropdown', {static: true})
    searchDropdown: ElementRef;
    searchFoundationDropdown: any;

    constructor(private router: Router) { }

    ngOnInit() {
        // Created drop-down foundation object from the <div>
        if (!this.searchFoundationDropdown) {
            this.searchFoundationDropdown = new Foundation.Dropdown($(this.searchDropdown.nativeElement).first());
        }
    }

    quickSearchClick(url: string, fieldName: string, isActive: boolean = false) {
        const route = this.getLink(url);
        const queryParams = this.getQueryParams(fieldName, isActive);

        this.router.navigate(route, { queryParams: queryParams });
        const native = $(this.searchDropdown.nativeElement).first();
        if (native.hasClass('is-open')) {
            native.foundation('close');
        }
    }

    getLink(url: string): [string] {
        const input = $(this.searchText.nativeElement).val().trim();
        const inputAsNumber = parseInt(input);
        // If search string is a number, navigate directly to page
        if (!isNaN(inputAsNumber)) {
            return ['/' + url + '/' + input];
        }
        // Else, it's search text
        return ['/' + url + '/searchresults'];
    }

    getQueryParams(fieldName: string, isActive: boolean): any {
        const input = $(this.searchText.nativeElement).val();
        const inputAsNumber = parseInt(input);
        // If search string is a number, not doing search
        if (!isNaN(inputAsNumber)) {
            return {};
        }
        // Else, doing search
        const query = {};
        query[fieldName] = input;
        if (isActive)
            query['isActive'] = true;
        return query;
    }
}
