import { Action } from '@ngrx/store';
import { Message } from '../../models';

export enum MessageActionTypes {
    DisplayMessage = '[Message] Display Message',
    ClearMessage = '[Message] Clear Message'
}

export class DisplayMessage implements Action {
    readonly type = MessageActionTypes.DisplayMessage;
    constructor(public payload: Message) { }
}

export class ClearMessage implements Action {
    readonly type = MessageActionTypes.ClearMessage;
    constructor(public payload: any = null) { }
}

export type MessageActions =
    | DisplayMessage
    | ClearMessage;
