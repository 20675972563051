<opus-modal [visible]="visible" (action)="onModalAction($event)" [title]="title" [primaryLabel]="primaryLabel" [secondaryLabel]="secondaryLabel" showClose="true">
    <ng-container *ngIf="visible && districtFormNotAddressForm">
        <form name="createDistrictForm" #createDistrictForm [formGroup]="editor" novalidate (ngSubmit)="onSubmit()">
            <input type="text" formControlName="districtName" placeholder="District Name" aria-label="District Name" title="District Name">
            <div class="error error--adjust-margin">
                <div class="error__message" *ngIf="editor.get('districtName').invalid && (editor.get('districtName').dirty || submitted)">Required</div>
            </div>

            <fieldset>
                <legend class="sr-only">Relationship Manager</legend>
                <div class="row">
                    <div class="columns">
                        <label for="editManagerId">Relationship Manager ID</label>
                        <div class="input-group u-m-b-0">
                            <input type="text" id="editManagerId" formControlName="relationshipManagerCode" class="input-group-field">
                            <div class="input-group-button">
                                <button type="button" (click)="lookupManager()">Apply</button>
                            </div>
                        </div>
						<div class="error" *ngIf="editor.get('relationshipManagerCode').invalid && (editor.get('relationshipManagerCode').dirty || submitted)">
							<div class="error__message" *ngIf="editor.controls.relationshipManagerCode.errors.required">Required</div>
							<div class="error__message" *ngIf="editor.controls.relationshipManagerCode.errors.server">{{ editor.controls.relationshipManagerCode.errors.server }}</div>
						</div>
                    </div>
                    <div class="columns">
                        <label for="editManagerName">Relationship Manager Name</label>
                        <div>
                            <span data-toggle="managerTypeAhead">
                                <input type="text" id="editManagerName" autocomplete="off" (keyup)="lookupManagerByName($event)" (keydown)="$event.stopPropagation()" formControlName="relationshipManagerName" role="combobox">
                            </span>
                            <ul class="dropdown-menu" role="listbox" id="managerTypeAhead" #managerDropdown data-v-offset="-16">
                                <li *ngFor="let manager of managerByNameLookup?.managers">
                                    <button type="button" class="dropdown-menu__action" role="option" data-close="managerTypeAhead" (click)="selectManager(manager)">
                                        <span class="l-block">{{ manager.manager }} ({{ manager.code }})</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="fieldset">
                <legend>Physical Address</legend>
                <input type="text" placeholder="Address Line 1" aria-label="Address Line 1" formControlName="physicalLine1" title="Address Line 1">
                <div class="error error--adjust-margin">
                    <div class="error__message" *ngIf="editor.get('physicalLine1').invalid && (editor.get('physicalLine1').dirty || submitted)">Required</div>
                </div>
                <input type="text" placeholder="Address Line 2" aria-label="Address Line 2" formControlName="physicalLine2" title="Address Line 2">
                <div class="row">
                    <div class="columns">
                        <div class="input-group u-m-b-0">
                            <input type="text" formControlName="physicalZip" placeholder="Zip Code" aria-label="Zip Code" title="Zip Code" class="input-group-field">
                            <div class="input-group-button">
                                <button type="button" [disabled]="disableZipLookup" (click)="onApplyZip()" data-open="myModal">Apply</button>
                            </div>
                        </div>
                        <ul class="error" *ngIf="hasZipLookupError">
                            <li *ngIf="editor.get('physicalZip').invalid && (editor.get('physicalZip').dirty || submitted)" class="error__message">Required and must be a valid zip code</li>
                            <li class="error__message" *ngIf="this.zipLookup && this.zipLookup.errors">Unable to lookup city &amp; state.</li>
                        </ul>
                    </div>
                    <div class="columns columns--tight">
                        <input type="text" formControlName="physicalCity" placeholder="City" title="City">
                    </div>
                    <div class="columns shrink">
                        <select aria-label="State" title="State" formControlName="physicalState">
                            <option value="">Select State</option>
                            <option *ngFor="let s of states" [value]="s">{{ s }}</option>
                        </select>
                    </div>
                </div>
                <select name="county" title="County" aria-label="County" formControlName="physicalCounty">
                    <option value="">Select County</option>
                    <option *ngFor="let c of counties$ | async" [value]="c.toUpperCase()">{{ c }}</option>
                </select>
            </fieldset>

            <fieldset class="fieldset">
                <legend>Mailing Address</legend>
                <label class="checkbox">
                    <input class="checkbox__input" type="checkbox" (change)="sameAsPhysicalChecked($event)"> Same as physical address
                </label>
                <input type="text" placeholder="Address Line 1" aria-label="Address Line 1" formControlName="mailingLine1" title="Address Line 1">
                <div class="error error--adjust-margin">
                    <div class="error__message" *ngIf="editor.get('mailingLine1').invalid && (editor.get('mailingLine1').dirty || submitted)">Required</div>
                </div>
                <input type="text" placeholder="Address Line 2" aria-label="Address Line 2" formControlName="mailingLine2" title="Address Line 2">
                <div class="row">
                    <div class="columns">
                        <div class="input-group u-m-b-0">
                            <input type="text" formControlName="mailingZip" placeholder="Zip Code" aria-label="Zip Code" title="Zip Code" class="input-group-field">
                            <div class="input-group-button">
                                <button type="button" [disabled]="disableZipLookupMailing" (click)="onApplyZipMailing()" data-open="myModal">Apply</button>
                            </div>
                        </div>
                        <ul class="error" *ngIf="hasZipLookupErrorMailing">
                            <li *ngIf="editor.get('mailingZip').invalid && (editor.get('mailingZip').dirty || submitted)" class="error__message">Required and must be a valid zip code</li>
                            <li class="error__message" *ngIf="this.zipLookupMailing && this.zipLookupMailing.errors">Unable to lookup city &amp; state.</li>
                        </ul>
                    </div>
                    <div class="columns columns--tight">
                        <input type="text" formControlName="mailingCity" placeholder="City" title="City">
                    </div>
                    <div class="columns shrink">
                        <select aria-label="State" title="State" formControlName="mailingState">
                            <option value="">Select State</option>
                            <option *ngFor="let s of states" [value]="s">{{ s }}</option>
                        </select>
                    </div>
                </div>
            </fieldset>
        </form>
    </ng-container>

    <ng-container *ngIf="visible && !districtFormNotAddressForm">
        <ng-container *ngIf="enteredAddress">
            <div class="property-block">
                <div class="property-block__title-wrapper">
                    <h2 class="property-block__title">Entered Address:</h2>
                </div>
                <ul class="property-block__list">
                    <li class="property-block__list-item">
                        <div class="property-block__label" id="eAddressLine1">Address Line 1</div>
                        <div class="property-block__value" role="definition" aria-labelledby="eAddressLine1">{{ enteredAddress.line1 }}</div>
                    </li>
                    <li class="property-block__list-item" *ngIf="enteredAddress.line2">
                        <div class="property-block__label" id="eAddressLine2">Address Line 2</div>
                        <div class="property-block__value" role="definition" aria-labelledby="eAddressLine2">{{ enteredAddress.line2 }}</div>
                    </li>
                    <li class="property-block__list-item">
                        <div class="property-block__label" id="eCityStateZip">City, State Zip</div>
                        <div class="property-block__value" role="definition" aria-labelledby="eCityStateZip">{{ enteredAddress.city }}, {{ enteredAddress.state }} {{ enteredAddress.zip }}</div>
                    </li>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="suggestedAddress">
            <div class="property-block">
                <div class="property-block__title-wrapper">
                    <h2 class="property-block__title">Suggested Address:</h2>
                </div>
                <ul class="property-block__list">
                    <li class="property-block__list-item">
                        <div class="property-block__label" id="sAddressLine1">Address Line 1</div>
                        <div class="property-block__value" role="definition" aria-labelledby="sAddressLine1">{{ suggestedAddress.line1 }}</div>
                    </li>
                    <li class="property-block__list-item" *ngIf="suggestedAddress.line2">
                        <div class="property-block__label" id="sAddressLine2">Address Line 2</div>
                        <div class="property-block__value" role="definition" aria-labelledby="sAddressLine2">{{ suggestedAddress.line2 }}</div>
                    </li>
                    <li class="property-block__list-item">
                        <div class="property-block__label" id="sCityStateZip">City, State Zip</div>
                        <div class="property-block__value" role="definition" aria-labelledby="sCityStateZip">{{ suggestedAddress.city }}, {{ suggestedAddress.state }} {{ suggestedAddress.zip }}</div>
                    </li>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="!suggestedAddress">
            <ul class="error">
                <li class="error__message">The United states Postal Service is unable to locate this address.</li>
            </ul>
        </ng-container>
    </ng-container>
</opus-modal>


<div class="full-page-spinner" [class.full-page-spinner--is-active]="this.hasSubmitted">
    <div class="full-page-spinner__icon"></div>
</div>
