<ul class="property-block__list">
    <li class="property-block__list-item" *ngIf="hasLine1">
        <div class="property-block__label" id="mAddressLine1">Address Line 1</div>
        <div class="property-block__value" role="definition" aria-labelledby="mAddressLine1">{{ line1 }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="hasLine2">
        <div class="property-block__label" id="mAddressLine2">Address Line 2</div>
        <div class="property-block__value" role="definition" aria-labelledby="mAddressLine2">{{ line2 }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="hasCityStateOrZip">
        <div class="property-block__label" id="mCityStateZip">City, State Zip</div>
        <div class="property-block__value" role="definition" aria-labelledby="mCityStateZip">{{ city }}, {{ state }} {{ zip }}</div>
    </li>
    <li class="property-block__list-item ng-scope" *ngIf="hasCounty">
        <div class="property-block__label" id="mCounty">County</div>
        <div class="property-block__value" role="definition" aria-labelledby="mCounty">{{ county }}</div>
    </li>
</ul>