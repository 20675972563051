import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap } from 'rxjs/operators';

import { RentalCategory, OpusCategory } from '../models/taxonomy-model';

@Injectable()
export class TaxonomyService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) { }

    loadRentalCategories(): Observable<RentalCategory[]> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<RentalCategory[]>(`${c.config.apiServer}api/taxonomy/rental/`);
            })
        );
	}

	loadBookCategories(): Observable<OpusCategory[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<OpusCategory[]>(`${c.config.apiServer}api/taxonomy/opus/books`);
			})
		);
	}
}
