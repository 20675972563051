import { Routes } from '@angular/router';
import * as fromCore from './core';
import * as fromMerch from './merch';
import * as fromDistribution from './distribution';
import * as fromDistricts from './scp/districts';
import * as fromEvents from './scp/events';
import * as fromPrograms from './scp/programs';
import * as fromSchools from './scp/schools';
import * as fromCommon from './scp/scp-common';
import * as fromTeachers from './scp/teachers';

export const routes: Routes = [
  {
    path: '',
    //redirectTo: 'search',
    component: fromCore.HomeComponent,
    pathMatch: 'full',
  },	//	TODO: map to app component to redirect based upon user's identity
  {
    path: 'autologin',
    redirectTo: ''
  },
  { path: 'unauthorized', component: fromCore.UnauthorizedComponent },
  { path: 'search', loadChildren: () => import('./scp/search/search.module').then(m => m.SearchModule) },
  { path: 'credit-check', loadChildren: () => import('./credit-check/credit-check.module').then(m => m.CreditCheckModule) },
  {
    path: 'merchandising',
    component: fromMerch.MerchLandingComponent,
  },
  {
    path: 'merchandising/instrument-combinations',
    canActivate: [fromMerch.MerchComboGuard],
    component: fromMerch.CombosComponent,
  },
  {
    path: 'merchandising/product-groups',
    canActivate: [fromMerch.MerchProductGroupsGuard],
    component: fromMerch.ProductGroupsComponent,
  },
  {
    path: 'merchandising/rental-categories',
    canActivate: [fromMerch.MerchRentalCategoryGuard],
    component: fromMerch.RentalCategoriesComponent,
  },
  {
    //	componentless route here in order to set the guard on all routes specific to a district
    //	this is needed for the preferences child route.
    path: 'merchandising/rental-categories/:id',
    canActivate: [fromMerch.MerchRentalCategoryGuard],
    data: { redirectTo: '/404' },
    children: [
      { path: '', component: fromMerch.RentalCategoriesDetailsComponent, canActivate: [fromMerch.MerchRentalCategoryGuard] }
    ],
  },
  {
    path: 'distribution',
    canActivate: [fromDistribution.DistributionGuard],
    component: fromDistribution.DistributionLandingComponent
  },
  { path: 'user-management', loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule) },

  //	specifying these history routes here in order to avoid guard executions when directly navigating
  { path: ':type/:id/history', component: fromCommon.HistoryComponent, canActivate: [fromCommon.HistoryGuard] },
  { path: ':type/:id/preferences/history', component: fromCommon.HistoryComponent, canActivate: [fromCommon.HistoryGuard] },
  { path: ':type/:id/preferences/detail/:code/history', redirectTo: ':type/:id/preferences/history' },

  /*** EVENTS ***/
  {
    path: 'events/searchresults',
    component: fromEvents.EventSearchResultsComponent,
    canActivate: [fromEvents.EventSearchResultsGuard],
    data: {
      redirectTo: '/search/events'
    }
  },
  {
    //	componentless route here in order to set the guard on all routes specific to an event
    //	this is needed for the preferences child route.
    path: 'events/:id',
    canActivate: [fromEvents.EventExistsGuard],
    data: {
      redirectTo: '/404'
    },
    children: [
      { path: '', component: fromEvents.EventDetailsComponent },
    ],
  },
  {
    path: 'events/:id/qrcodes',
    canActivate: [fromEvents.EventExistsGuard],
    data: {
      redirectTo: '/404'
    },
    children: [
      { path: 'qrcodes', component: fromEvents.EventDetailsComponent },
    ],
  },
  /*** DISTRICTS ***/
  {
    path: 'districts/searchresults',
    component: fromDistricts.DistrictSearchResultsComponent,
    canActivate: [fromDistricts.DistrictSearchResultsGuard],
    data: {
      redirectTo: '/search/districts'
    }
  },
  {
    //	componentless route here in order to set the guard on all routes specific to a district
    //	this is needed for the preferences child route.
    path: 'districts/:id',
    canActivate: [fromDistricts.DistrictExistsGuard],
    data: {
      redirectTo: '/404'
    },
    children: [
      { path: '', component: fromDistricts.DistrictDetailsComponent, canActivate: [fromDistricts.DistrictExistsGuard] },
      { path: 'preferences', loadChildren: () => import('./scp/preferences/preferences.module').then(m => m.PreferencesModule) },
    ],
  },
  /*** TEACHERS ***/
  {
    path: 'teachers/searchresults',
    component: fromTeachers.TeacherSearchResultsComponent,
    canActivate: [fromTeachers.TeacherSearchResultsGuard],
    data: {
      redirectTo: '/search/teachers'
    }
  },
  {
    //	componentless route here in order to set the guard on all routes specific to a district
    //	this is needed for the preferences child route.
    path: 'teachers/:id',
    canActivate: [fromTeachers.TeacherDetailsExistsGuard],
    data: { redirectTo: '/404' },
    children: [
      { path: '', component: fromTeachers.TeacherDetailsComponent },
      { path: 'preferences', loadChildren: () => import('./scp/preferences/preferences.module').then(m => m.PreferencesModule) },
    ],
  },
  /*** SCHOOLS ***/
  {
    path: 'schools/searchresults',
    component: fromSchools.SchoolSearchResultsComponent,
    canActivate: [fromSchools.SchoolSearchResultsGuard],
    data: {
      redirectTo: '/search/schools'
    }
  },
  {
    //	componentless route here in order to set the guard on all routes specific to a district
    //	this is needed for the preferences child route.
    path: 'schools/:id',
    canActivate: [fromSchools.SchoolDetailsExistsGuard],
    data: { redirectTo: '/404' },
    children: [
      { path: '', component: fromSchools.SchoolDetailsComponent, canActivate: [fromSchools.SchoolDetailsExistsGuard] },
      { path: 'preferences', loadChildren: () => import('./scp/preferences/preferences.module').then(m => m.PreferencesModule) },
    ],
  },
  /*** PROGRAMS ***/
  {
    //	componentless route here in order to set the guard on all routes specific to a district
    //	this is needed for the preferences child route.
    path: 'programs/:id',
    canActivate: [fromPrograms.ProgramDetailsExistsGuard],
    data: { redirect: '/' },
    children: [
      { path: '', component: fromPrograms.ProgramDetailsComponent },
      { path: 'preferences', loadChildren: () => import('./scp/preferences/preferences.module').then(m => m.PreferencesModule) },
    ],
  },
  { path: '401', component: fromCore.UnauthorizedComponent },
  { path: '500', component: fromCore.UnexpectedErrorComponent },
  { path: '404', component: fromCore.NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];
