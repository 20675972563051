import { Component, Input } from '@angular/core';

import { SchoolDetails } from '../../models';

@Component({
	selector: 'opus-school-contacts',
	templateUrl: './school-contacts.component.html',
})
export class SchoolContactsComponent {
	@Input() school: SchoolDetails;
}

