import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../core/services/ConfigurationService';
import { ConfigurationState } from '../../core/store/reducers';

import { InstrumentClass, InstrumentCombo, ProductGroup, Product, RentalCategory, RentalCategoryAttribute } from '../models';
import { Operation } from 'fast-json-patch';

@Injectable()
export class MerchService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	getInstrumentClasses(): Observable<InstrumentClass[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<InstrumentClass[]>(`${c.config.apiServer}api/taxonomy/rental/classes`);
			})
		);
	}

	getInstrumentCombos(categoryId: number): Observable<InstrumentCombo[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<InstrumentCombo[]>(`${c.config.apiServer}api/instrumentcombinations/category/${categoryId}`);
			})
		);
  	}

	getProductGroups(): Observable<ProductGroup[]> {
		return this.configuration.config$.pipe(
		switchMap((c: ConfigurationState) => {
			return this.http.get<ProductGroup[]>(`${c.config.apiServer}api/productgroups`);
		})
		);
	}

	getProducts(productGroupId: number): Observable<Product[]> {
		return this.configuration.config$.pipe(
		switchMap((c: ConfigurationState) => {
			return this.http.get<Product[]>(`${c.config.apiServer}api/product/search?productGroupId=${productGroupId}`);
		})
		);
	}

	setComboAvailability(available: boolean, ids: number[]): Observable<void> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.post<void>(`${c.config.apiServer}api/instrumentcombinations/${available}`, ids);
			})
		);
	}

	deleteCombo(id: number): Observable<void> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.delete<void>(`${c.config.apiServer}api/instrumentcombinations/${id}`);
			})
		);
	}

	getRentalCategories(code: string): Observable<RentalCategory[]> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<RentalCategory[]>(`${c.config.apiServer}api/Rentals/Category/${code}`);
			})
		)
	}

	getRentalCategoryDetails(id: number): Observable<RentalCategory> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<RentalCategory>(`${c.config.apiServer}api/Rentals/Category/Details/${id}`);
			})
		)
	}

	getRentalCategoryAttributes(id: number): Observable<RentalCategoryAttribute> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<RentalCategoryAttribute>(`${c.config.apiServer}api/Rentals/Category/Details/Attributes/${id}`);
			})
		)
	}

	patchRentalCategoryAttributes(id: number, patch: Operation[]): Observable<RentalCategoryAttribute> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.patch<RentalCategoryAttribute>(`${c.config.apiServer}api/Rentals/Category/Details/Attributes/${id}`, patch);
            })
        );
    }
}
