    <!-- edit mode -->
<form name="eventStaffingForm" [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Event staffing" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
        </li>
        <li class="property-block__list-item">
            <label for="editSupervisor" class="property-block__label">Supervisor</label>
            <div class="property-block__value">
                <input type="text" id="editSupervisor" formControlName="supervisor" maxlength="50">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editTotalStaffNeeded" class="property-block__label">Total Staff Needed</label>
            <div class="property-block__value">
                <input type="number" id="editTotalStaffNeeded" formControlName="staffNeeded" min="1">
            </div>
            <div class="error" *ngIf="editor.get('staffNeeded').invalid && (editor.get('staffNeeded').dirty || editor.get('staffNeeded').touched)">
                <div class="error__message" *ngIf="editor.get('staffNeeded').hasError('min')">Must be greater than 0</div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editScheduledStaffMembers" class="property-block__label">Scheduled Staff Members</label>
            <div class="property-block__value">
                <textarea id="editScheduledStaffMembers" rows="3" formControlName="staffNames" maxlength="500"></textarea>
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
        </div>
    </div>
</form>
