<!-- display mode -->
<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventQuality">Event Quality</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventQuality">{{ eventQuality }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="deliveryTimePeriod">Delivery Time Period</div>
        <div class="property-block__value" role="definition" aria-labelledby="deliveryTimePeriod">{{ deliveryTimePeriod }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="meetingType">Attendance Type</div>
        <div class="property-block__value" role="definition" aria-labelledby="meetingType">{{ event.rentalMeetingTypeName }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="fulfillmentType">Rental Fulfillment Type</div>
        <div class="property-block__value" role="definition" aria-labelledby="fulfillmentType">{{ event.rentalFulfillmentTypeName}}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="mouthpieceTesting">Mouthpiece Testing</div>
        <div class="property-block__value" role="definition" aria-labelledby="mouthpieceTesting">{{ event.mouthpieceTesting | yesNo}}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="bandProgramAttending">Band Program Attending</div>
        <div class="property-block__value" role="definition" aria-labelledby="bandProgramAttending">{{ event.bandProgramAttending | yesNo}}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.bandProgramAttending">
        <div class="property-block__label" id="estimatedBandRentals">Estimated Band Rentals</div>
        <div class="property-block__value" role="definition" aria-labelledby="estimatedBandRentals">{{event.estimatedBandRentals}}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="orchestraProgramAttending">Orchestra Program Attending</div>
        <div class="property-block__value" role="definition" aria-labelledby="orchestraProgramAttending">{{event.orchestraProgramAttending | yesNo}}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.orchestraProgramAttending">
        <div class="property-block__label" id="estimatedOrchestraRentals">Estimated Orchestra Rentals</div>
        <div class="property-block__value" role="definition" aria-labelledby="estimatedOrchestraRentals">{{event.estimatedOrchestraRentals}}</div>
    </li>
</ul>
<!-- /display mode -->
