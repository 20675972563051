import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'app-unexpected-error',
    templateUrl: './unexpected-error.component.html'
})

export class UnexpectedErrorComponent {

  constructor(private security: OidcSecurityService) { }

  reAuthorize() {
    this.security.authorize();
  }
}
