import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromDistrictSearchResults from '../reducers/searchresults.reducer';

export const getDistrictSearchState = createSelector(
    fromFeature.getDistrictsState,
    (state: fromFeature.DistrictsState) => state.search
);
export const getDistrictSearchResultsState = createSelector(
    getDistrictSearchState,
    fromDistrictSearchResults.getDistrictSearchResults
);
/*
export const getDetailsStateDistrict = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateDistrict
);

export const getDetailsStateLoaded = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateLoaded
);

export const getDetailsStateLoading = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateLoading
);

export const getDetailsStateInfoState = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateInfoState
);

export const getDetailsStateErrors = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateErrors
);

export const getDetailsRelationshipManagerState = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateRelationshipManagerState
);
*/
