<div class="search-results">
    <p class="nav-back" *ngIf="!embeddedVersion">
        <a class="nav-back__link" routerLink="/search/events">Back to Search</a>
    </p>
    <div class="card">
        <div class="card__section">
            <div class="row align-bottom">
                <div class="columns">
                    <h1 class="typography--display1" *ngIf="!embeddedVersion">Events</h1>
                    <h1 class="card__title card__title--large" *ngIf="embeddedVersion">Events</h1>
                </div>
                <div class="columns shrink">
                    <button class="btn btn--hollow btn--add" type="button" (click)="createEvent()" opusAuthorize="Event.Create" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="school?.relationshipManagerEmail">Create New Event</button>
                </div>
            </div>
        </div>
        <div class="card__section">
            <div class="display-options">
                <div class="display-options__count">
                    Showing {{(searchResultsState$ | async).pagingSorting.pageSize * ((searchResultsState$ | async).pagingSorting.currentPage - 1) + 1}} -
                    {{(searchResultsState$ | async).pagingSorting.pageSize > (searchResultsState$ | async).pagingSorting.totalCount ? (searchResultsState$ | async).pagingSorting.totalCount : (searchResultsState$ | async).pagingSorting.pageSize * (searchResultsState$ | async).pagingSorting.currentPage}} of
                    {{(searchResultsState$ | async).pagingSorting.totalCount}}
                </div>
                <label class="display-options__per-page">
                    Show
                    <select (change)="setPageSize($event.target.value)">
                        <option value="15" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 15">15</option>
                        <option value="25" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 25">25</option>
                        <option value="50" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 50">50</option>
                        <option value="100" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 100">100</option>
                        <option value="200" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 200">200</option>
                    </select>
                </label>
                <!--<input type="text" class="display-options__filter" placeholder="Type to filter..." aria-label="Type to filter">-->
                <div class="display-options__filter"></div>
                <div class="display-options__pagination">
                    <ul class="pagination" role="navigation" aria-label="Pagination">
                        <li class="pagination__previous" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage <= 1">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage <= 1 ? "Previous" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage > 1" aria-label="Previous page" (click)="prevPage()">Previous<span class="show-for-sr"> page</span></a>
                        </li>
                        <li class="pagination__current">
                            <span class="show-for-sr">You are on page </span>
                            {{(searchResultsState$ | async).pagingSorting.currentPage}}
                        </li>
                        <li aria-hidden="true">of</li>
                        <li><a aria-label="Page 10" (click)="lastPage()">{{(searchResultsState$ | async).pagingSorting.totalPages}}</a></li>
                        <li class="pagination__next" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages ? "Next" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage < (searchResultsState$ | async).pagingSorting.totalPages" aria-label="Next page" (click)="nextPage()">Next<span class="show-for-sr"> page</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="full-page-spinner" [class.full-page-spinner--is-active]="(searchResultsState$ | async).loading">
            <div class="full-page-spinner__icon"></div>
        </div>

        <div class="card__section">
            <div class="table table--align-left" role="table">
                <div role="rowgroup">
                    <div class="table__row table__row--header" role="row">
                        <span class="table__cell table__cell--half" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('Id')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Id' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Id' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                ID
                            </button>
                        </span>
                        <span class="table__cell" role="columnheader">
                            <button class="table__sorter-btn table__sorter-btn--sorted-dsc"
                                    (click)="sortBy('Date')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Date' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Date' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Date
                            </button>
                        </span>
                        <span class="table__cell table__cell--half" role="columnheader">
                            <span class="table__sorter-btn table__sorter-btn--text">
                                Day
                            </span>
                        </span>
                        <span class="table__cell" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('Time')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Time' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'Time' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Time
                            </button>
                        </span>
                        <span class="table__cell" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('MeetingName')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'MeetingName' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'MeetingName' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Meeting Name
                            </button>
                        </span>
                        <span class="table__cell" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('EventType')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'EventType' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'EventType' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Event Type
                            </button>
                        </span>
                        <span class="table__cell table__cell--double" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('HostSchool')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'HostSchool' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'HostSchool' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Host School
                            </button>
                        </span>
                        <span class="table__cell table__cell--one-half" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('HostTeacher')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'HostTeacher' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'HostTeacher' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Host Teacher
                            </button>
                        </span>
                        <span class="table__cell table__cell--half" role="columnheader">
                            <button class="table__sorter-btn"
                                    (click)="sortBy('IsActive')"
                                    [class.table__sorter-btn--sorted-asc]="(searchResultsState$ | async).pagingSorting.sortBy == 'IsActive' && (searchResultsState$ | async).pagingSorting.sortDir == 0"
                                    [class.table__sorter-btn--sorted-dsc]="(searchResultsState$ | async).pagingSorting.sortBy == 'IsActive' && (searchResultsState$ | async).pagingSorting.sortDir == 1">
                                Active
                            </button>
                        </span>
                    </div>
                </div>
                <div role="rowgroup">
                    <a class="table__row ng-scope" role="row" *ngFor="let event of (searchResultsState$ | async).searchResults; odd as isOdd; last as isLast"
                         [class.table__row--alt]="isOdd"
                         [class.table__row--last]="isLast"
                         routerLink="/events/{{ event.id }}" opusAuthorize="Event.ViewDetails">
                        <span class="table__cell table__cell--half" role="cell">{{ event.id }}</span>
                        <span class="table__cell" role="cell">{{ event.date }}</span>
                        <span class="table__cell table__cell--half" role="cell">{{ event.day }}</span>
                        <span class="table__cell" role="cell">{{ event.time }}</span>
                        <span class="table__cell" role="cell">{{ event.meetingName }}</span>
                        <span class="table__cell" role="cell">{{ event.eventType }}</span>
                        <span class="table__cell table__cell--double" role="cell">{{ event.hostSchool }}</span>
                        <span class="table__cell table__cell--one-half" role="cell">{{ event.hostTeacher }}</span>
                        <span class="table__cell table__cell--half" role="cell">{{ event.isActive ? 'Y' : 'N' }}</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="card__section">
            <div class="display-options">
                <div class="display-options__count">
                    Showing {{(searchResultsState$ | async).pagingSorting.pageSize * ((searchResultsState$ | async).pagingSorting.currentPage - 1) + 1}} -
                    {{(searchResultsState$ | async).pagingSorting.pageSize > (searchResultsState$ | async).pagingSorting.totalCount ? (searchResultsState$ | async).pagingSorting.totalCount : (searchResultsState$ | async).pagingSorting.pageSize * (searchResultsState$ | async).pagingSorting.currentPage}} of
                    {{(searchResultsState$ | async).pagingSorting.totalCount}}
                </div>
                <label class="display-options__per-page">
                    Show
                    <select (change)="setPageSize($event.target.value)">
                        <option value="15" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 15">15</option>
                        <option value="25" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 25">25</option>
                        <option value="50" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 50">50</option>
                        <option value="100" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 100">100</option>
                        <option value="200" [selected]="(searchResultsState$ | async).pagingSorting.pageSize == 200">200</option>
                    </select>
                </label>
                <!--<input type="text" class="display-options__filter" placeholder="Type to filter..." aria-label="Type to filter">-->
                <div class="display-options__filter"></div>
                <div class="display-options__pagination">
                    <ul class="pagination" role="navigation" aria-label="Pagination">
                        <!--<li class="pagination__previous disabled">Previous<span class="show-for-sr"> page</span></li>-->
                        <li class="pagination__previous" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage <= 1">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage <= 1 ? "Previous" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage > 1" aria-label="Previous page" (click)="prevPage()">Previous<span class="show-for-sr"> page</span></a>
                        </li>
                        <li class="pagination__current">
                            <span class="show-for-sr">You are on page </span>
                            {{(searchResultsState$ | async).pagingSorting.currentPage}}
                        </li>
                        <li aria-hidden="true">of</li>
                        <li><a aria-label="Page 10" (click)="lastPage()">{{(searchResultsState$ | async).pagingSorting.totalPages}}</a></li>
                        <li class="pagination__next" [class.disabled]="(searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages">
                            {{ (searchResultsState$ | async).pagingSorting.currentPage >= (searchResultsState$ | async).pagingSorting.totalPages ? "Next" : "" }}
                            <a *ngIf="(searchResultsState$ | async).pagingSorting.currentPage < (searchResultsState$ | async).pagingSorting.totalPages" aria-label="Next page" (click)="nextPage()">Next<span class="show-for-sr"> page</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<opus-event-create [visible]="showModal" [school]="school" (action)="createEventClosed($event)"></opus-event-create>
