import { Component, Input } from '@angular/core';

import { ProgramDetails } from '../../models';
import { RelationshipType } from '../../../scp-common/models';

@Component({
	selector: 'opus-program-relationshipmanager',
	templateUrl: './program-relationshipmanager.component.html',
})
export class ProgramRelationshipManagerComponent {
    @Input() program: ProgramDetails;

    get relationshipType() {
        return RelationshipType[this.program.school.relationshipType.relationshipType];
    }
}
