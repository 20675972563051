import { Component, Input, Output, EventEmitter  } from '@angular/core';

import { ProgramDetails, ProgramTeacher } from '../../models';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromRoot from '../../../../core/store';

@Component({
	selector: 'opus-program-program-teachers',
	templateUrl: './program-program-teachers.component.html',
})
export class ProgramProgramTeachersComponent {
    @Input() program: ProgramDetails;
    @Input() editMode = false;
    @Output() remove: EventEmitter<ProgramTeacher> = new EventEmitter<ProgramTeacher>();
    @Output() isPrimary: EventEmitter<ProgramTeacher> = new EventEmitter<ProgramTeacher>();

    constructor(private _store: Store<fromStore.ProgramDetailsState>) {

    }

    removeTeacher(teacher: ProgramTeacher) {
        this.remove.emit(teacher);
    }

    isPrimaryTeacher(teacher: ProgramTeacher) {
        this.isPrimary.emit(teacher);
    }

    //navigateTeacher(schoolId: number) {
    //    this._store.dispatch(new fromRoot.Go({
    //        path: [`schools/${schoolId}`]
    //    }));
    //}
}
