import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, take, map, mergeMap } from 'rxjs/operators';


import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';

@Injectable()
export class TeacherSearchResultsGuard implements CanActivate {
    private _url: string;

    constructor(private _store: Store<fromStore.TeachersState>) {
        console.log('TeacherSearchResultsGuard: constructor called.');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this._url = state.url;
        return this.checkStore();
    }

    checkStore(): Observable<boolean> {
        // True if we've already processed query string in guard
        let processedQueryString = false;

        console.log('teacher-searchresults.guard - checkStore()');

        // TODO: Make forceReload smart enough to be false if the search criteria state matches the query.
        //var forceReload = true;

        // TODO: Need to wait here somehow until process query string calls LoadTeacherSearchResults and returns.
        //return of(true);

        return this._store.pipe(
            select(fromRoot.getUserAuthInfo),
            filter(x => !!x),
            mergeMap(x => {

                if (!x.policyCodes.has('Teacher.Search')) return of(false);

                // TODO: Check for API error (loaded false or err, etc.).  Return false if an err.
                return this._store.pipe(
                    select(fromStore.getTeacherSearchState),
                    tap(() => {
                        //if ((!state.loaded && !state.loading && state.errors === null) || forceReload) {
                        if (!processedQueryString) {
                            // So we only do this dispatch once (TODO: Figure out a way for this "tap" to only happen once)
                            processedQueryString = true;
                            //forceReload = false;
                            //this._store.dispatch(new fromStore.LoadTeacherSearchResults(splitted.length == 2 ? splitted[1] : ""));
                            // Dispatch so that we process the incoming query string.
                            this._store.dispatch(new fromStore.ProcessQueryString(this._url));
                        }
                    }),
                    filter(state => state.loaded),
                    map(state => state.loaded),
                    take(1),
                );

            })
        );

    }
}
