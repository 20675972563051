import { Component, Input } from '@angular/core';

import { ProgramDetails } from '../../models';

@Component({
	selector: 'opus-program-additional',
	templateUrl: './program-additional.component.html',
})
export class ProgramAdditionalComponent {
    @Input() program: ProgramDetails;

    get beginningGrade() {
        if (this.program.beginningGrade == null) {
            return 'Unknown';
        }

        const value = Number.parseInt(this.program.beginningGrade);
        if (value == null || value < 0) {
            return 'Unknown';
        }
        else if (value == 0) {
            return 'K';
        }
        else if (value > 12) {
            return 'College';
        }

        return value.toString();
    }
}
