import { Component, Input } from '@angular/core';

import { Event } from '../../models';

@Component({
    selector: 'event-location',
    templateUrl: './event-location.component.html',
})
export class EventLocationComponent {
    @Input() event: Event;

}
