<div class="row align-center u-m-t-40">
    <div class="columns small-8 large-6">
        <section class="card card--error">
            <div class="card__section">
                <h1 class="card__title card__title--large">500 Unexpected Error</h1>
            </div>
            <hr class="card__divider">
            <div class="card__section typography--body">                
                <p>Your request cannot be processed at this time.<br>If this error continues to occur, please contact support.</p>
                <p>Please note that this has been reported to the technical team.</p>
            </div>
            <div class="card__section">
                <a class="btn btn--small" (click)="reAuthorize()">Go Home</a>
            </div>
        </section>
    </div>
</div>
