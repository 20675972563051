import { Injectable } from '@angular/core';

import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, map, catchError, filter, withLatestFrom, tap, first, mergeMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Operation, compare } from 'fast-json-patch';

import { State } from '../../../../core/store';
import { CountyLookupState, DistrictLookupsState } from '..';
import * as schoolDetailsActions from '../actions/school-details.action';
import * as fromServices from '../../../scp-common/services';
import * as fromSelectors from '../selectors';
import * as fromModels from '../../models';
import * as fromRoot from '../../../../core/store';
import { DistrictLookup } from '../../../districts/models';
import { MessageType, ProblemDetails } from '../../../../core/models';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SchoolDetailsEffects {
    constructor(
        private actions$: Actions,
        private schoolService: fromServices.SchoolService,
        private districtService: fromServices.DistrictService,
        private addressService: fromServices.AddressService,
        private store: Store<State>,
    ) { }

    
    loadSchoolDetails$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.LoadSchoolDetails),
        tap(a => {
            console.log('Entering loadSchoolDetails$ Effect: ', a);
        }),
        switchMap((action: schoolDetailsActions.LoadSchoolDetails) => this.schoolService.loadSchool(action.payload).pipe(
            tap(res => console.log(`schoolService.loadSchool( ${action.payload} ) returned: `, res)),
            map(school => new schoolDetailsActions.LoadSchoolDetailsComplete(school)),
            catchError(error => of(new schoolDetailsActions.LoadSchoolDetailsFailure(error.message))),
        )),
        tap(a => {
            console.log('Exiting loadSchoolDetails$ Effect: ', a);
        }),
    ));

    
    lookupDistrict$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.LookupDistrictById),
        tap(a => console.log('Entering lookupDistrict$ Effect: ', a)),
        withLatestFrom(this.store.pipe(select(fromSelectors.getDistrictLookupsState))),
        map(([action, dls]: [schoolDetailsActions.LookupDistrictById, DistrictLookupsState]) => {
            const { id } = action.payload;
            const dl = dls.idLookups[id] ? dls.idLookups[id].district : null;

            return { id, dl };
        }),
        switchMap(({ id, dl }: { id: number, dl: DistrictLookup }) => {
            if (dl !== null) {
                console.log(`lookupDistrict$: district lookup with id ${id} already loaded.`);

                return of(new schoolDetailsActions.LookupDistrictByIdSuccess({ id, dl }));
            }

            console.log(`lookupDistrict$: Calling service to get district lookup for id ${id}...`);

            return this.districtService.lookupDistrictById(id).pipe(
                tap(res => console.log(`districtService.lookupDistrictById( ${id} ) returned: `, res)),
                map((dl: DistrictLookup) => {
                    if (dl) {
                        return new schoolDetailsActions.LookupDistrictByIdSuccess({ id, dl });
                    }

                    return new schoolDetailsActions.LookupDistrictByIdFailure({ id, error: `There is no district with an ID of '${id}'.` });
                }),
                catchError(err => {
                    const error = `Unable to retrieve the District with ID: ${id}: ${err.message}.`;

                    return of(new schoolDetailsActions.LookupDistrictByIdFailure({ id, error }));
                }),
            );
        }),
        tap(a => console.log('Exiting lookupDistrict$ Effect: ', a)),
    ));

    
    districtLookupByName$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.LookupDistrictByName),
        tap(a => console.log('Entering districtLookupByName$ Effect: ', a)),
        switchMap((action: schoolDetailsActions.LookupDistrictByName) => {
            const { name } = action.payload;

            console.log(`districtLookupByName$: Calling service to get district lookups for name ${name}...`);

            return this.districtService.lookupDistrictByName(name).pipe(
                tap(res => console.log(`districtService.lookupDistrictByName( ${name} ) returned: `, res)),
                map(dls => {
                    if (dls === null) {
                        return new schoolDetailsActions.LookupDistrictByNameFailure({ name, error: 'Districts Not Found' });
                    }

                    return new schoolDetailsActions.LookupDistrictByNameSuccess({ name, dls });
                }),
                catchError(error => of(new schoolDetailsActions.LookupDistrictByNameFailure({ name, error }))),
            );
        }),
        tap(a => console.log('Exiting districtLookupByName$ Effect: ', a)),
    ));

     getRateAgreement$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.GetRateAgreement),
        tap(a => console.log('Entering getRateAgreement$ Effect: ', a)),
        switchMap((action: schoolDetailsActions.GetRateAgreement) => this.schoolService.getRateAgreement(action.payload.rateCode).pipe(
            map(rateAgreement => {
                if (rateAgreement) {
                    return new schoolDetailsActions.GetRateAgreementSuccess({ panel: action.payload.panel, rateCode: action.payload.rateCode, rateAgreement });
                }

                return new schoolDetailsActions.GetRateAgreementFailure({ panel: action.payload.panel, rateCode: action.payload.rateCode, errors: `There is no Rate Agreement with an ID of '${action.payload.rateCode}'.` });
            }),
            catchError(err => {
                const errors = err.status == 404
                    ? `There is no Rate Agreement with an ID of '${action.payload.rateCode}'.`
                    : `Unable to retrieve the Rate Agreement with ID: ${action.payload.rateCode}.`;

                return of(new schoolDetailsActions.GetRateAgreementFailure({ panel: action.payload.panel, rateCode: action.payload.rateCode, errors }));
            }),
        )),
        tap(a => console.log('Exiting getRateAgreement$ Effect: ', a)),
    ));

    
    updateSchoolPanel$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.UpdateSchoolPanel),
        tap(a => {
            console.log('Entering updateSchoolPanel$ Effect: ', a);
        }),
        //	get the current value of the district from the store
        withLatestFrom(this.store.pipe(select(fromSelectors.getSchoolDetailsEntity))),
        //	create a json patch by comparing the current district with the updated version
        //	typescript note: using array destructuring to pull the withLatestFrom output into individual parameters to map
        map(([action, school]: [schoolDetailsActions.UpdateSchoolPanel, fromModels.SchoolDetails]) => {
            const patch = compare(school, action.payload.school);

            console.log('new school:', action.payload.school, 'current school:', school, 'patch:', patch);

            //	the district service needs the id & the json patch
            return { panel: action.payload.panel, school, patch };
        }),
        switchMap(({ panel, school, patch }: { panel: string, school: fromModels.SchoolDetails, patch: Operation[] }) => {
            if (patch.length == 0) {
                console.log('updateSchoolPanel$ Effect: Skipping empty patch...');

                return of(new schoolDetailsActions.UpdateSchoolPanelSuccess({ panel, school }));
            }

            console.log('updateSchoolPanel$ Effect: Calling schoolService.patchSchool(', school.id, ', ', patch, ')...');

            return this.schoolService.patchSchool(school.id, patch).pipe(
                map(school => new schoolDetailsActions.UpdateSchoolPanelSuccess({ panel, school })),
                catchError((error: HttpErrorResponse) => {
                    return of(new schoolDetailsActions.UpdateSchoolPanelFailure({ panel, errors: this.getErrorMessage(error) }));
                }),
            );
        }),
        tap(a => {
            console.log('Exiting updateSchoolPanel$ Effect: ', a);
        }),
    ));

    
    updateSchoolPanelFailure$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.UpdateSchoolPanelFailure),
        tap(a => {
            console.log('Entering updateSchoolPanelFailure$ Effect: ', a);
        }),
        map((a: schoolDetailsActions.UpdateSchoolPanelFailure) => {
            const { errors } = a.payload;

            return new fromRoot.DisplayMessage({
                message: errors || 'Unhandled exception has occurred',
                messageType: MessageType.alert,
                toast: false,
            });
        })
    ));

    
    lookupCityState$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.SchoolAddressLookupCityState),
        tap(a => console.log('Entering lookupCityState$ Effect: ', a)),
        switchMap((a: schoolDetailsActions.SchoolAddressLookupCityState) => {
            const { panel, zip } = a.payload;

            return this.addressService.lookupCityState(zip).pipe(
                tap(res => console.log(`addressService.lookupCityState( ${zip} ) returned: `, res)),
                map(cityState => {
                    return new schoolDetailsActions.SchoolAddressLookupCityStateSuccess({ panel, cityState });
                }),
                catchError(errors => {
                    return of(new schoolDetailsActions.SchoolAddressLookupCityStateFailure({ panel, errors }));
                }),
            );
        }),
        tap(a => console.log('Exiting lookupCityState$ Effect: ', a)),
    ));

    
    loadCounties$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.SchoolLoadCounties),
        tap(a => console.log('Entering loadCounties$ Effect: ', a)),
        //	pull in the county lookup state
        withLatestFrom(this.store.pipe(select(fromSelectors.getDetailsStateCountyLookup))),
        //	grab the county list for the request state code
        map(([action, countyLookup]: [schoolDetailsActions.SchoolLoadCounties, CountyLookupState]) => {
            const counties = countyLookup.counties[action.payload.stateCode];

            return { action, counties };
        }),
        switchMap(({ action, counties }: { action: schoolDetailsActions.SchoolLoadCounties, counties: string[] }) => {
            const { panel, stateCode } = action.payload;

            //	skip service call if we already have a county list for this state
            if (counties) {
                console.log(`loadCounties$: county list for ${action.payload.stateCode} already loaded.`);

                return of(new schoolDetailsActions.SchoolLoadCountiesSuccess({ panel, counties }));
            }

            return this.addressService.loadCounties(stateCode).pipe(
                tap(res => console.log(`addressService.loadCounties( ${stateCode} ) returned: `, res)),
                map((counties: string[]) => {
                    return new schoolDetailsActions.SchoolLoadCountiesSuccess({ panel, counties });
                }),
            );
        }),
        tap(a => console.log('Exiting loadCounties$ Effect: ', a)),
    ));

    
    verifyAddress$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.SchoolVerifyAddress),
        tap(a => console.log('Entering verifyAddress$ Effect: ', a)),
        switchMap((a: schoolDetailsActions.SchoolVerifyAddress) => {
            const { panel, address } = a.payload;

            return this.addressService.verifyAddress(address).pipe(
                tap(res => console.log(`addressService.verifyAddress( ${address} ) returned: `, res)),
                map(verification => {
                    return new schoolDetailsActions.SchoolVerifyAddressSuccess({ panel, verification });
                }),
                catchError(errors => {
                    return of(new schoolDetailsActions.SchoolVerifyAddressFailure({ panel }));
                }),
            );
        }),
        tap(a => console.log('Exiting verifyAddress$ Effect: ', a)),
    ));

    
    createSchool$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.CreateSchool),
        tap(a => console.log('Entering createSchool$ Effect: ', a)),
        switchMap((action: schoolDetailsActions.CreateSchool) => {
            return this.schoolService.createSchool(action.payload.school).pipe(
                tap(id => {
                    console.log('schoolService.createSchool returned:', id);
                }),
                map(id => {
                    if (id === null) {
                        return new schoolDetailsActions.CreateSchoolFailure({ errors: 'Error creating school' });
                    }

                    return new schoolDetailsActions.CreateSchoolSuccess({ id });
                }),
                catchError(error => of(new schoolDetailsActions.CreateSchoolFailure({ errors: 'Error creating school' })))
            );
        }),
        tap(a => console.log('Exiting createSchool$ Effect: ', a)),
    ));

    
    createSchoolFailure$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.CreateSchoolFailure),
        tap(a => console.log('Entering createSchoolFailure$ Effect: ', a)),
        switchMap((action: schoolDetailsActions.CreateSchoolFailure) => {
            return of(new fromRoot.DisplayMessage({
                message: action.payload.errors,
                messageType: MessageType.alert,
                toast: false,
            }));
        }),
        tap(a => console.log('Exiting createSchoolFailure$ Effect: ', a)),
    ));

    
    validateSchoolPrimaryId$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.ValidateSchoolPrimaryId),
        tap(a => {
            console.log('Entering validateSchoolPrimaryId$ Effect: ', a);
        }),
        mergeMap((a: schoolDetailsActions.ValidateSchoolPrimaryId): any[] => {
            const { panel, validator, primaryId, duplicateId, duplicateIsActive } = a.payload;

            //	clearing the primary id is always valid & requires no other checks
            if (primaryId == '') {
                return [new schoolDetailsActions.SchoolPrimaryIdValidated({ panel, validator, errors: null })];
            }

            //	check for a blatantly incorrect primary id
            if (+primaryId < 1 || Number.isNaN(+primaryId)) {
                return [new schoolDetailsActions.SchoolPrimaryIdValidated({ panel, validator, errors: { primaryId: 'Primary ID must refer to a valid school.' } })];
            }

            if (+primaryId == duplicateId) {
                return [new schoolDetailsActions.SchoolPrimaryIdValidated({ panel, validator, errors: { primaryId: 'Primary ID cannot refer to this school.' } })];
            }

            const schoolExistsAction = new schoolDetailsActions.ValidateSchoolExists({ panel, validator: 'schoolExists', id: +primaryId });

            if (duplicateIsActive) {
                return [schoolExistsAction, new schoolDetailsActions.ValidateSchoolCanDeactivate({ panel, validator: 'canDeactivate', id: duplicateId })];
            }

            return [schoolExistsAction];
        }),
        tap(a => {
            console.log('Exiting validateSchoolPrimaryId$ Effect: ', a);
        }),
    ));

    
    getSchoolPrimaryIdValidationResults$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.ValidateSchoolPrimaryId),
        tap(a => console.log('Entering getSchoolPrimaryIdValidationResults$ Effect: ', a)),
        //	Aggregator: gather the completion actions to complete the original, split, action
        switchMap((src: schoolDetailsActions.ValidateSchoolPrimaryId): Observable<schoolDetailsActions.SchoolDetailsActions[]> => {
            const schoolExists$ = this.actions$.pipe(
                ofType(schoolDetailsActions.SchoolDetailsActionTypes.SchoolExistsValidated),
                tap(a => console.log('schoolExists$: ', a)),
                filter((a: any): any => {
                    return src.payload.primaryId == a.payload.id;	//	correlate the actions via the primary id
                }),
                first(),
            );
            const canDeactivate$ = this.actions$.pipe(
                ofType(schoolDetailsActions.SchoolDetailsActionTypes.SchoolCanDeactivateValidated),
                tap(a => console.log('canDeactivate$: ', a)),
                filter((a: any): any => {
                    return src.payload.duplicateId == a.payload.id;	//	correlate the actions via the duplicate id
                }),
                first(),
            );

            return forkJoin([of(src), schoolExists$, canDeactivate$]);
        }),
        //	map the parent & completed dependent actions to a completed parent action
        map(([primaryId, schoolExists, canDeactivate]) => {
            return new schoolDetailsActions.SchoolPrimaryIdValidated({ panel: primaryId.payload.panel, validator: primaryId.payload.validator, errors: { ...schoolExists.payload.errors, ...canDeactivate.payload.errors } });
        }),
        tap(a => console.log('Exiting getSchoolPrimaryIdValidationResults$ Effect: ', a)),
    ));

    
    validateSchoolExists$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.ValidateSchoolExists),
        tap(a => console.log('Entering validateSchoolExists$ Effect: ', a)),
        switchMap((a: schoolDetailsActions.ValidateSchoolExists) => {
            const { panel, validator, id } = a.payload;

            return this.schoolService.exists(id).pipe(
                tap(res => console.log(`schoolService.exists( ${id} ) returned: `, res)),
                map(res => {
                    const errors = res
                        ? null
                        : { schoolExists: `There is no School with ID: ${id}` };

                    return new schoolDetailsActions.SchoolExistsValidated({ panel, validator, id, errors });
                }),
                catchError(err => {
                    return of(new schoolDetailsActions.SchoolExistsValidated({ panel, validator, id, errors: { schoolExists: `Unable to validate whether the school with id: ${id} exists.` } }));
                }),
            );
        }),
        tap(a => console.log('Exiting validateSchoolExists$ Effect: ', a)),
    ));

    
    validateSchoolCanDeactivate$ = createEffect(() => this.actions$.pipe(
        ofType(schoolDetailsActions.SchoolDetailsActionTypes.ValidateSchoolCanDeactivate),
        tap(a => console.log('Entering validateSchoolCanDeactivate$ Effect: ', a)),
        switchMap((a: schoolDetailsActions.ValidateSchoolCanDeactivate) => {
            const { panel, validator, id } = a.payload;

            return this.schoolService.canDeactivate(id).pipe(
                tap(res => console.log(`schoolService.canDeactivate( ${id} ) returned: `, res)),
                map(res => {
                    const errors = res
                        ? null
                        : { canDeactivate: `Associated entities must be disassociated or deactivated prior to deactivating a school: ${id}.` };

                    return new schoolDetailsActions.SchoolCanDeactivateValidated({ panel, validator, id, errors });
                }),
                catchError(err => {
                    return of(new schoolDetailsActions.SchoolCanDeactivateValidated({ panel, validator, id, errors: { canDeactivate: `Unable to validate whether the school with id: ${id} can deactivate.` } }));
                }),
            );
        }),
        tap(a => console.log('Exiting validateSchoolCanDeactivate$ Effect: ', a)),
    ));

    private getErrorMessage(response: HttpErrorResponse): string {
        if (response.status === 400 && response.error) {
            try {
                const problemDetails = response.error as ProblemDetails;
                if (problemDetails) {
                    const firstError = problemDetails.errors ? problemDetails.errors[Object.keys(problemDetails.errors)[0]] : null;
                    if (firstError)
                        return firstError;
                    else if (problemDetails.title)
                        return problemDetails.title;
                }
            }
            catch (ex) {
                console.warn(ex);
            }
        }

        return response.error ? response.error.message : response.message;
    }
}
