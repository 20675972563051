import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromSchoolDetails from './school-details.reducer';
import * as fromSearchResults from './searchresults.reducer';

export interface SchoolsState {
    details: fromSchoolDetails.SchoolDetailsState;
    search: fromSearchResults.SearchResultsState;
}

export const reducers: ActionReducerMap<SchoolsState> = {
    details: fromSchoolDetails.reducer,
    search: fromSearchResults.reducer
};

export const getSchoolsState = createFeatureSelector<SchoolsState>('schools');

export { SchoolDetailsState, PanelId, PanelState, AddressPanelState, ZipLookupState, CountyLookupState, RateLookupState, RateAgreementState, DistrictLookupsState, DistrictByNameLookupState } from './school-details.reducer';
export { SearchResultsState, initialState } from './searchresults.reducer';
