import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { tap, switchMap, map, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as teacherSearchResultsActions from '../actions/teacher-searchresults.action';
import * as fromServices from '../../../scp-common/services';
import * as fromModels from '../../models';
import * as fromReducers from '../reducers';

import { SearchResultsState } from '..';

@Injectable()
export class TeacherSearchResultsEffects {
    constructor(
        private actions$: Actions,
        private teacherService: fromServices.TeacherService,
        private store$: Store<SearchResultsState>,
    ) { }

    
    processQueryString$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.ProcessQueryString),
        switchMap((action: teacherSearchResultsActions.ProcessQueryString) => {
            // Grab an object representation of the query string
            const query = this.parseQueryStringFromUrl(action.payload);
            // Convert query object into our strict-typed object
            let searchCriteria: fromModels.TeacherSearchCriteria;

            // Populate search criteria with the query object params
            searchCriteria = {
                id: query['id'],
                name: query['name'],
                districtName: query['districtName'],
                county: query['county'],
                state: query['state'],
                zip: query['zip'],
                radius: query['radius'],
                phone: query['phone'],
                isActive: query['isActive'],
                PageNumber: query['PageNumber'],
                PageSize: query['PageSize'],
                SortBy: query['SortBy'],
                SortDir: query['SortDir'],
                schoolId: query['schoolId'],
                districtId: query['districtId'],
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(searchCriteria));
        })
    ));

    
    getResultsForSchool$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.GetResultsForSchool),
        switchMap((action: teacherSearchResultsActions.GetResultsForSchool) => {
            console.log(action.payload);

            // Whatever state we have, reset it to initial
            const newCriteria: fromModels.TeacherSearchCriteria = {
                ...fromReducers.initialState.searchCriteria,
                // Use the school's id as our search criteria
                schoolId: action.payload.id
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newCriteria));
        })
    ));

    
    getResultsForDistrict$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.GetResultsForDistrict),
        switchMap((action: teacherSearchResultsActions.GetResultsForDistrict) => {
            console.log(action.payload);

            // Whatever state we have, reset it to initial
            const newCriteria: fromModels.TeacherSearchCriteria = {
                ...fromReducers.initialState.searchCriteria,
                // Use the district's id as our search criteria
                districtId: action.payload.id
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newCriteria));
        })
    ));

    
    nextPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.NextPage),
        switchMap((action: teacherSearchResultsActions.NextPage) => {
            console.log(action.pagingSorting);
            const currentPage: number = action.pagingSorting.currentPage;
            let nextPage: number;

            // If page number is null, maybe we never got first page?
            if (currentPage == null) {
                // Go to the first one
                nextPage = 1;
            }
            else if (currentPage < action.pagingSorting.totalPages) {
                // Increment to next page
                nextPage = currentPage + 1;
            }
            else {
                // Don't change the page
                nextPage = currentPage;
            }

            // Set the next page to pass to our search request
            const newPayload: fromModels.TeacherSearchCriteria = {
                ...action.payload,
                PageNumber: nextPage
            };
            // TODO: Figure out some way to not do any action here if page number didn't change
            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newPayload));
        })
    ));

    
    prevPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.PrevPage),
        switchMap((action: teacherSearchResultsActions.PrevPage) => {
            console.log(action.pagingSorting);
            const currentPage: number = action.pagingSorting.currentPage;
            let prevPage: number;

            // If page number is null, maybe we never got first page?
            if (currentPage == null) {
                // Go to the first one
                prevPage = 1;
            }
            else if (currentPage > 1) {
                // Increment to next page
                prevPage = currentPage - 1;
            }
            else {
                // Don't change the page
                prevPage = currentPage;
            }

            // Set the next page to pass to our search request
            const newPayload: fromModels.TeacherSearchCriteria = {
                ...action.payload,
                PageNumber: prevPage
            };
            // TODO: Figure out some way to not do any action here if page number didn't change
            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newPayload));
        })
    ));

    
    sortByResult$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.SortBy),
        switchMap((action: teacherSearchResultsActions.SortBy) => {
            // Default to ascending sort order
            let sortDir = 0;

            // If user is sorting by same column again
            if (action.payload == action.pagingSorting.sortBy) {
                // Change the direction of the sort since it's the same column
                sortDir = action.pagingSorting.sortDir == 0 ? 1 : 0;
            }
            const lastPage: number = action.pagingSorting.totalPages;

            // Set the next page to pass to our search request
            const newPayload: fromModels.TeacherSearchCriteria = {
                ...action.searchCriteria,
                SortDir: sortDir,
                SortBy: action.payload
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newPayload));
        })
    ));

    
    lastPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.LastPage),
        switchMap((action: teacherSearchResultsActions.LastPage) => {
            const lastPage: number = action.pagingSorting.totalPages;

            // Set the next page to pass to our search request
            const newPayload: fromModels.TeacherSearchCriteria = {
                ...action.payload,
                PageNumber: lastPage
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newPayload));
        })
    ));

    
    firstPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.FirstPage),
        switchMap((action: teacherSearchResultsActions.FirstPage) => {
            const firstPage = 1;

            // Set the next page to pass to our search request
            const newPayload: fromModels.TeacherSearchCriteria = {
                ...action.payload,
                PageNumber: firstPage
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newPayload));
        })
    ));

    
    pageSizeResult$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.SetPageSize),
        switchMap((action: teacherSearchResultsActions.SetPageSize) => {
            const pageSize: number = action.payload;

            // Set the next page to pass to our search request
            const newPayload: fromModels.TeacherSearchCriteria = {
                ...action.searchCriteria,
                PageSize: pageSize
            };

            return of(new teacherSearchResultsActions.LoadTeacherSearchResults(newPayload));
        })
    ));

    
    searchResults$ = createEffect(() => this.actions$.pipe(
        ofType(teacherSearchResultsActions.TeacherSearchResultsActionTypes.LoadTeacherSearchResults),
        switchMap((action: teacherSearchResultsActions.LoadTeacherSearchResults) => {
            console.log('Load Teacher Search Results Effect');
            console.log(action.payload);

            let query = '';

            Object.keys(action.payload).forEach(key => {
                const value = action.payload[key];
                if (value === undefined) {
                    // Any missing value we just ignore
                    return;
                }

                const valueAsString = String(value).trim();

                // If item has a value...
                if (value !== null && value !== '') {
                    // If it's one of these keys and value is "Any"
                    const anyKeys = ['state', 'isSupervisory', 'isActive'];
                    if (anyKeys.some(i => i === key) && value == 'Any') {
                        // Treat "any" like null.
                        return;
                    }
                    // Else, add it to query string
                    else {
                        query += `&${key}=${value}`;
                    }
                }
            });

            return this.teacherService.searchTeachers(query).pipe(
                tap(d => {
                    console.log('searchTeachers returned:', d);
                }),
                map(resp => {
                    if (!resp.ok || resp.body == null) {
                        return new teacherSearchResultsActions.LoadTeacherSearchResultsFailure('Search results not returned');
                    }

                    return new teacherSearchResultsActions.LoadTeacherSearchResultsSuccess(resp.body, resp.headers);
                }),
                catchError(error => of(new teacherSearchResultsActions.LoadTeacherSearchResultsFailure(error)))
            );
        })
    ));

    private parseQueryStringFromUrl(url: string): object {
        const returnObject: object = {
        };

        // Parse query string into chunks
        const splitted = url.split('?', 2);
        // If we have a query string, then process it further
        if (splitted.length == 2) {
            // Split out each key/value pair
            const splittedItems = splitted[1].split('&');
            splittedItems.forEach(item => {
                // Split out the key and value
                const splittedItem = item.split('=');
                const key = splittedItem[0];
                const value = splittedItem.length > 1 ? splittedItem[1] : '';
                // Add is as a key/value pair in our return object.
                returnObject[key] = value;
            });
        }

        return returnObject;
    }
}
