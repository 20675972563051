import { Injectable } from '@angular/core';

import { tap, switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { State } from '../../../../core/store';
import * as scpActions from '../actions';
import * as fromServices from '../../services';
import * as fromSelectors from '../selectors';
import { LocationManager } from '../../models';
import { LocationsState } from '..';

@Injectable()
export class LocationsEffects {
    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private locationService: fromServices.LocationService,
    ) { }

    
    getLocationManager$ = createEffect(() => this.actions$.pipe(
        ofType(scpActions.ScpActionTypes.GetLocation),
        tap(a => console.log('Entering getLocationManager$ Effect: ', a)),
        withLatestFrom(this.store.pipe(select(fromSelectors.getLocationsState))),
        map(([action, locations]: [scpActions.GetLocation, LocationsState]) => {
            const { code } = action.payload;
            const manager = locations.locationManagers[code] ? locations.locationManagers[code].manager : null;

            return { code, manager };
        }),
        switchMap(({ code, manager }: { code: number, manager: LocationManager }) => {
            if (manager !== null) {
                console.log(`getLocationManager$: location manager with code ${code} already loaded.`);

                return of(new scpActions.GetLocationSuccess({ code, manager }));
            }

            console.log(`getLocationManager$: Calling service to get manager for location ${code}...`);

            return this.locationService.getLocationManager(code).pipe(
                tap(res => console.log(`locationService.getLocationManager( ${code} ) returned: `, res)),
                map((manager: LocationManager) => {
                    if (manager) {
                        return new scpActions.GetLocationSuccess({ code, manager });
                    }

                    return new scpActions.GetLocationFailure({ code, error: `There is no Location with an ID of '${code}'.` });
                }),
                catchError(err => {
                    const error = `Unable to retrieve the Location with ID: ${code}: ${err.message}.`;

                    return of(new scpActions.GetLocationFailure({ code, error }));
                }),
            );
        }),
        tap(a => console.log('Exiting getLocationManager$ Effect: ', a)),
    ));

    
    managerLookupByName$ = createEffect(() => this.actions$.pipe(
        ofType(scpActions.ScpActionTypes.GetManagersByName),
        switchMap((action: scpActions.GetManagersByName) => {
            console.log('Load Manager Lookup By ID Effect');

            return this.locationService.lookupManagerByName(action.payload.name).pipe(
                tap(d => {
                    console.log('managerLookupById$ returned:', d);
                }),
                map(managerResult => {
                    if (managerResult === null) {
                        return new scpActions.GetManagersByNameFailure({ errors: 'Manager Not Found' });
                    }

                    return new scpActions.GetManagersByNameSuccess({ managers: managerResult });
                }),
                catchError(error => of(new scpActions.GetManagersByNameFailure(error)))
            );
        })
    ));
}
