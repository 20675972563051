import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError, map, mergeMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';


@Injectable()
export class TeacherDetailsExistsGuard implements CanActivate {
	constructor(private _store: Store<fromStore.TeachersState>) {
		console.log('TeacherDetailsExistsGuard()...');
    }

    private _statusCode = 0;

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		//	get the id of the teacher we need to load from the route
		const id = +route.paramMap.get('id');
		//	check for a specific redirect route to use if the teacher isn't found
		let redirectTo = !!route.data.redirectTo ? route.data.redirectTo : '/';

		console.log(`TeacherDetailsExistsGuard: canActivate called for teacher id: ${id}...`);

		//	this will attempt to load the teacher by dispatching the appropriate action then either redirect or allow the activation
		return this.checkStore(id).pipe(
            switchMap(found => {
                if (!found) {
                    switch (this._statusCode) {
                        case 401: {
                            console.log(`TeacherDetailsExistsGuard: unauthorized request.`);
                            redirectTo = '/401';
                            break;
                        }
                        case 500: {
                            console.log(`TeacherDetailsExistsGuard: checkStore returned: Unexpected Error.`);
                            redirectTo = '/500';
                            break;
                        }
                        default: {
                            console.log(`TeacherDetailsExistsGuard: checkStore returned: Not Found.`);
                            break;
                        }
                    }
                }
                else
                    console.log(`TeacherDetailsExistsGuard: checkStore returned: Found.`);

				return this.allowOrRedirect(found, redirectTo);
			}),
			catchError(err => {
				console.log(`TeacherDetailsExistsGuard: activation failed for teacher ${id}.  Error: `, err);

				return this.allowOrRedirect(false, '/500');
			}),
		);
	}

	checkStore(id: number): Observable<boolean> {

        return this._store.pipe(
            select(fromRoot.getUserAuthInfo),
            filter(x => !!x),
            mergeMap(x => {
                if (!x.policyCodes.has('Teacher.ViewDetails')) {
                    this._statusCode = 401;
                    return of(false);
                }
                //	start with the teacher details state...
                return this._store.select(fromStore.getTeacherDetailsState).pipe(
                    //	if there aren't teacher details, or they are for a different teacher, dispatch an action to load the teacher
                    tap(state => {
                        if (state.errors && !state.errors.includes('404 Not Found'))
                            this._statusCode = 500;
                        else if ((state.teacherDetails === null || state.teacherDetails.id != id || !state.loaded) && !state.loading) {
                            console.log(`TeacherDetailsExistsGuard: Loading teacher ${id}.  Current Teacher Details state: `, state);
                            this._store.dispatch(new fromStore.LoadTeacherDetails(id));
                        }
                    }),
                    //	wait for it to be in the loaded state for the teacher in question or for any errors
                    filter(state => (state.loaded && state.teacherDetails.id == id) || state.errors !== null),
                    //	map it to a boolean observable
                    map(state => state.loaded),
                    //	get the first result & complete
                    take(1),
                );
            })
        );
	}

	allowOrRedirect(found: boolean, redirectTo: string): Observable<boolean> {
		if (!found) {
			console.log(`Redirecting to '${redirectTo}'...`);
			this._store.dispatch(new fromRoot.Go({ path: [redirectTo] }));
		}

		return of(found);
	}
}
