import { SchoolService } from './school.service';
import { DistrictService } from './district.service';
import { PrefsService } from './prefs.service';
import { EventService } from './event.service';
import { AddressService } from './address.service';
import { TeacherService } from './teacher.service';
import { LocationService } from './location.service';
import { ProgramService } from './program.service';
import { TaxonomyService } from './taxonomy.service';
import { ProductGroupService } from './product-group.service';
import { ProductService } from './product.service';
import { RatePackageService } from './rate-package.service';
import { HistoryService } from './history.service';

export const services: any[] = [
	SchoolService,
	DistrictService,
	PrefsService,
	EventService,
	AddressService,
	TeacherService,
	LocationService,
	ProgramService,
	TaxonomyService,
	ProductGroupService,
	ProductService,
    RatePackageService,
	HistoryService,
];

export * from './school.service';
export * from './district.service';
export * from './prefs.service';
export * from './event.service';
export * from './address.service';
export * from './teacher.service';
export * from './location.service';
export * from './program.service';
export * from './taxonomy.service';
export * from './product-group.service';
export * from './product.service';
export * from './rate-package.service';
export * from './history.service';
