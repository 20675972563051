import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromDistrictDetails from '../reducers/details.reducer';
import * as fromModels from '../../models';

export const getDistrictDetailsState = createSelector(
	fromFeature.getDistrictsState,
	(state: fromFeature.DistrictsState) => state.details
);

export const getDetailsStateDistrict = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateDistrict
);

export const getDetailsStateLoaded = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateLoaded
);

export const getDetailsStateLoading = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateLoading
);

export const getDetailsStateErrors = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStateErrors
);

export const getDetailsStatePanels = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDetailsStatePanels
);

export const getDetailsStateInfoPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Info]
);

export const getDetailsStatePhysAddrPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.PhysAddr] as fromFeature.AddressPanelState,
);

export const getDetailsStateMailAddrPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.MailAddr] as fromFeature.AddressPanelState,
);

export const getDetailsStateContactsPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Contacts]
);

export const getDetailsStateCalendarPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Calendar]
);

export const getDetailsStateAddlDetailsPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.AddlDetails]
);

export const getDetailsStateZipLookup = createSelector(
	getDetailsStatePhysAddrPanel,
	fromDistrictDetails.getDetailsStateZipLookup,
);

export const getDetailsStateCountyLookup = createSelector(
	getDetailsStatePhysAddrPanel,
	fromDistrictDetails.getDetailsStateCountyLookup,
);

export const getDetailsStateCounties = createSelector(
	getDetailsStateCountyLookup,
	(cl) => cl.lookupStateCode != null ? cl.counties[cl.lookupStateCode] : [],
);

export const getDetailsStateRelationshipPanel = createSelector(
	getDetailsStatePanels,
	(panels) => panels[fromFeature.PanelId.Relationship]
);

export const getDistrictLookupsState = createSelector(
	getDistrictDetailsState,
	fromDistrictDetails.getDistrictLookupsState,
);

export const getDistrictByNameLookupState = createSelector(
	getDistrictLookupsState,
	fromDistrictDetails.getDistrictByNameLookupState,
);
