import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import { InstrumentCombo } from '../models';

@Component({
	selector: 'opus-combos',
	templateUrl: './combos.component.html',
})
export class CombosComponent implements OnInit, OnDestroy {
	alive = true;
	vm$: Observable<fromStore.CombosState>;
	vm: fromStore.CombosState;
	classCtrl = new FormControl('');
	selectedClassId: number = null;
	filters: FormGroup;
	sort = { by: 'catCode', reverse: false };
	selectedCombos: number[] = [];
	updating = false;
	showDeleteModal = false;
	deletingCombo: InstrumentCombo = null;
	deleting = false;

	constructor(private store: Store<fromStore.CombosState>, private cdr: ChangeDetectorRef, private fb: FormBuilder) {
		console.log('CombosComponent()...');
	}

	ngOnInit() {
		console.log('CombosComponent.ngOnInit()...');

		this.vm$ = this.store.pipe(select(fromStore.getCombosState));
		this.vm$.pipe(
			takeWhile(() => this.alive)
		).subscribe(vm => {
			console.log('CombosComponent.vm$: ', vm);

			//	template will be bound to the vm instance instead of the observable so we don't have to use async pipe subscriptions all over the place in it
			//	so we have to manage our unsubscribe & manually tell angular to detect changes on updates
			this.vm = vm;

			if (!vm.loadedClasses && !vm.loadingClasses && !vm.errors) {
				this.store.dispatch(new fromStore.LoadClasses());
			}

			if (this.updating && !vm.updating) {
				this.updating = false;
				this.classCtrl.enable();

				if (!vm.errors) {
					this.toggleSelectAll(false);
				}
			}

			if (this.deleting && !vm.deleting) {
				this.deleting = false;
				this.classCtrl.enable();

				if (!vm.errors) {
					const i = this.selectedCombos.findIndex(c => c == this.deletingCombo.id);

					if (i !== -1) {
						this.selectedCombos.splice(i, 1);
					}
				}

				this.deletingCombo = null;
			}

			this.cdr.detectChanges();
		});

		this.classCtrl.valueChanges.pipe(
			takeWhile(() => this.alive)
		).subscribe(val => {
			console.log('classCtrl.valueChanges(): ', val);

			if (val !== null && val != this.selectedClassId) {
				this.selectedClassId = val;
				this.store.dispatch(new fromStore.LoadCombos(val));
			}
		});

		this.filters = this.fb.group({
			catName: [''],
			catCode: [''],
			brand: [''],
			size: [''],
			color: [''],
			available: ['any'],
		});
	}

	ngOnDestroy() {
		console.log('CombosComponent.ngOnDestroy()...');
		this.alive = false;
	}

	get isLoading(): boolean {
		//console.log('isLoading(): ', this.selectedClassId, this.vm.combos);

		return this.selectedClassId !== null && this.vm.classCombos[this.selectedClassId] && this.vm.classCombos[this.selectedClassId].loading;
	}

	get combos(): InstrumentCombo[] {
		return this.vm.classCombos[this.selectedClassId] ? this.vm.classCombos[this.selectedClassId].combos || [] : [];
	}

	get filteredCombos(): InstrumentCombo[] {
		return this.combos.filter(ic => {
			let allow = true;

			Object.keys(this.filters.value).forEach(k => {
				if (!allow) {
					return;
				}

				const fv = this.filters.value[k].toLowerCase();

				if (!fv) {
					return;
				}

				switch (k) {
					case 'catName': allow = ic.rentalCategory.name.toLowerCase().includes(fv); break;
					case 'catCode': allow = ic.rentalCategory.code.toLowerCase().includes(fv); break;
					case 'brand': allow = ic.brand.name.toLowerCase().includes(fv); break;
					case 'size': allow = ic.size ? ic.size.toLowerCase().includes(fv) : fv == 'any'; break;
					case 'color': allow = ic.color ? ic.color.toLowerCase().includes(fv) : fv == 'any'; break;
					case 'available': allow = fv == 'any' ? true : (fv == 'yes' ? ic.isAvailable : !ic.isAvailable); break;
				}
			});

			//console.log(allow, ic);

			return allow;
		}).sort((a, b) => {
			let ret = 0;

			switch (this.sort.by) {
				case 'catName': ret = a.rentalCategory.name < b.rentalCategory.name ? -1 : 1; break;
				case 'catCode': ret = a.rentalCategory.code < b.rentalCategory.code ? -1 : 1; break;
				case 'brand': ret = a.brand.name < b.brand.name ? -1 : 1; break;
				case 'size': ret = (a.size || 'Any') < (b.size || 'Any') ? -1 : 1; break;
				case 'color': ret = (a.color || 'Any') < (b.color || 'Any') ? -1 : 1; break;
				case 'available': ret = a.isAvailable < b.isAvailable ? -1 : 1; break;
			}

			return this.sort.reverse ? -ret : ret;
		});
	}

	sortBy(by: string) {
		if (by == this.sort.by) {
			this.sort.reverse = !this.sort.reverse;
		} else {
			this.sort.by = by;
			this.sort.reverse = false;
		}
	}

	toggleSelectAll(select: boolean = null) {
		this.selectedCombos = (select === null && this.selectedCombos.length === this.filteredCombos.length) || select === false
			? []
			: this.filteredCombos.map(c => c.id);

		this.updateFilterStatus();
	}

	isSelected(id: number): boolean {
		return this.selectedCombos.includes(id);
	}

	onSelectCombo(id: number) {
		const i = this.selectedCombos.findIndex(c => c == id);

		if (i === -1) {
			this.selectedCombos.push(id);
		} else {
			this.selectedCombos.splice(i, 1);
		}

		this.updateFilterStatus();
	}

	private updateFilterStatus() {
		if (this.selectedCombos.length) {
			this.filters.disable();
		} else {
			this.filters.enable();
		}
	}

	updateSelected(available: boolean) {
		const ids = this.selectedCombos.filter(id => this.filteredCombos.find(c => c.id == id).isAvailable != available);

		if (!ids.length) {
			console.log('updateSelected(): No update required.');
			return;
		}

		this.store.dispatch(new fromStore.SetComboAvailability({
			classId: this.selectedClassId,
			available,
			ids,
		}));

		this.updating = true;
		this.classCtrl.disable();
	}

	onToggleAvailable(c: InstrumentCombo) {
		this.store.dispatch(new fromStore.SetComboAvailability({
			classId: this.selectedClassId,
			available: !c.isAvailable,
			ids: [c.id]
		}));
	}

	get hasUnavailableSelections(): boolean {
		return this.filteredCombos.find(c => this.selectedCombos.includes(c.id) && !c.isAvailable) !== undefined;
	}

	get hasAvailableSelections(): boolean {
		return this.filteredCombos.find(c => this.selectedCombos.includes(c.id) && c.isAvailable) !== undefined;
	}

	onConfirmDelete(c: InstrumentCombo) {
		this.deletingCombo = c;
		this.showDeleteModal = true;
	}

	onDeleteModalAction(accept) {
		if (this.deleting) {
			return;
		}

		this.showDeleteModal = false;

		if (accept) {
			this.deleting = true;
			this.classCtrl.disable();
			this.store.dispatch(new fromStore.DeleteCombo({ classId: this.selectedClassId, id: this.deletingCombo.id }));

			return;
		}

		this.deletingCombo = null;
	}
}
