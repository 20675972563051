import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap, map, filter, first } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Event, Address } from '../models';
import * as fromStore from '../store';
import { states } from '../../scp-common/models/states';

@Component({
    selector: 'event-location-editor',
    templateUrl: './event-location-editor.component.html',
})
export class EventLocationEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    alive = true;
    event$: Observable<Event>;
    event: Event;
    editor: FormGroup;
    enteredAddress: Address = null;
    suggestedAddress: Address = null;
    panelState$: Observable<fromStore.AddressPanelState>;
    panelState: fromStore.PanelState;

    showModal = false;
    states: string[] = states;

    constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder) {

    }

    ngOnInit() {
        this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
        this.event$.pipe(
            takeWhile(() => this.alive),
            map(event => {
                if (event.eventAtHostSchool && !event.eventAddress && event.hostingSchoolAddress) {
                    return { ...event, eventAddress: { ...event.hostingSchoolAddress } };
                }

                return event;
            })
        ).subscribe(d => this.event = d);

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventAddrPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.createForm();
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.alive = false;
    }

    createForm() {
        this.editor = new FormGroup({
            eventAtHostSchool: new FormControl(this.event.eventAtHostSchool),
            isVirtualEvent: new FormControl(this.event.isVirtualEvent),
            virtualEventUrl: new FormControl(this.event.virtualEventUrl),
            eventAddressLine1: new FormControl(this.event.eventAddress ? this.event.eventAddress.line1 : '', Validators.required),
            eventAddressLine2: new FormControl(this.event.eventAddress ? this.event.eventAddress.line2 : ''),
            eventAddressCity: new FormControl(this.event.eventAddress ? this.event.eventAddress.city : '', Validators.required),
            eventAddressState: new FormControl(this.event.eventAddress ? this.event.eventAddress.state : '', Validators.required),
            eventAddressZip: new FormControl(this.event.eventAddress ? this.event.eventAddress.zip : '', Validators.required),
            location: new FormControl(this.event.location)
        });

    }

    toggleEventAtSchool() {
        const eventAtHostSchool = this.editor.value.eventAtHostSchool;

        if (eventAtHostSchool) {
            this.editor.controls['eventAddressLine1'].setValue(this.event.hostingSchoolAddress.line1);
            this.editor.controls['eventAddressLine2'].setValue(this.event.hostingSchoolAddress.line2);
            this.editor.controls['eventAddressCity'].setValue(this.event.hostingSchoolAddress.city);
            this.editor.controls['eventAddressState'].setValue(this.event.hostingSchoolAddress.state);
            this.editor.controls['eventAddressZip'].setValue(this.event.hostingSchoolAddress.zip);
        }
        console.log(eventAtHostSchool);
    }

    resetForm() {

    }

    update() {
        const eventAtHostSchool = this.editor.value.eventAtHostSchool;
        const isVirtualEvent = this.editor.value.isVirtualEvent;
        const virtualEventUrl = isVirtualEvent ? this.editor.value.virtualEventUrl : null;
        const location = this.editor.value.location;
        let eventAddress: Address = null;

        if (eventAtHostSchool) {
            eventAddress = {
                line1: this.event.hostingSchoolAddress.line1,
                line2: this.event.hostingSchoolAddress.line2,
                city: this.event.hostingSchoolAddress.city,
                state: this.event.hostingSchoolAddress.state,
                zip: this.event.hostingSchoolAddress.zip,
                county: this.event.hostingSchoolAddress.county
            };
        }
        else {
            eventAddress = this.suggestedAddress || this.enteredAddress;
        }

        const event: Event = {
            ...this.event,
            eventAtHostSchool,
            isVirtualEvent,
            virtualEventUrl,
            location,
            eventAddress,
        };

        this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventLocation, event }));
    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty && !this.panelState.updating;
    }

    onSubmit() {
        const address: Address = {
            line1: this.editor.value.eventAddressLine1,
            line2: this.editor.value.eventAddressLine2,
            city: this.editor.value.eventAddressCity,
            state: this.editor.value.eventAddressState,
            zip: this.editor.value.eventAddressZip,
            county: ''
        };

        this.enteredAddress = address;

        this.store.dispatch(new fromStore.EventVerifyAddress({ panel: fromStore.PanelId.EventLocation, address }));

        //	observe results in store
        this.panelState$.pipe(
            tap(ps => console.log('onSubmit: ', ps)),
            filter(ps => !(ps as fromStore.AddressPanelState).verifying),
            first(),
            tap(ps => console.log('onSubmit: ', ps)),
        ).subscribe(ps => {
            console.log('subscribe: ', ps);
            const verifiedAddress = (ps as fromStore.AddressPanelState).verifiedAddress;

            // If verified address didn't come back, make sure suggested stays null (so we don't use it later)
            this.suggestedAddress = verifiedAddress;
            if (this.suggestedAddress != null) {
                this.suggestedAddress = {
                    ...verifiedAddress,
                    county: this.editor.value.addressCounty,
                };
            }

            //	if verified address is not identical to entered, or we got a picklist instead, show modal
            if (verifiedAddress !== null && this.compareAddresses(address, verifiedAddress)) {
                this.update();

                return;
            }

            this.showModal = true;
        });

    }

    onModalAction(accept) {
        console.log(accept);

        this.showModal = false;

        if (accept) {
            this.update();
        }
    }

    onModalTertiaryAction(accept) {
        console.log(accept);

        this.showModal = false;

        if (accept) {
            // Clear out the suggested address so that update() will use the entered one
            this.suggestedAddress = null;
            this.update();
        }
    }

    private compareAddresses(first: Address, second: Address): boolean {
        return first.line1 == second.line1
            && first.line2 == second.line2
            && first.city == second.city
            && first.state == second.state
            && first.zip == second.zip;
    }

}
