import { PropertyBlockComponent } from './property-block.component';
import { HistoryHeaderComponent } from './history-header.component';
import { HistorySidebarComponent } from './history-sidebar.component';
import { HistoryDetailsComponent } from './history-details.component';

export const components: any[] = [
	PropertyBlockComponent,
	HistoryHeaderComponent,
	HistorySidebarComponent,
	HistoryDetailsComponent,
];

export * from './property-block.component';
export * from './history-header.component';
export * from './history-sidebar.component';
export * from './history-details.component';
