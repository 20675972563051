    <!-- edit mode -->
<form name="rentalMeetingDetailsForm" [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Manage rental event details" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editEventQuality" class="property-block__label">Event Quality</label>
            <div class="property-block__value">
                <select id="editEventQuality" required formControlName="eventQuality">
                    <option value="0">Exclusive</option>
                    <option value="1">Competitive</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editDeliveryTimePeriod" class="property-block__label">Delivery Time Period</label>
            <div class="property-block__value">
                <select id="editDeliveryTimePeriod" formControlName="deliveryTimePeriod">
                    <option value="0">Immediate</option>
                    <option value="1">Summer</option>
                    <option value="2">Fall</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editMeetingType" class="property-block__label">Attendance Type</label>
            <div class="property-block__value">
                <select name="type" id="editEventType" required formControlName="rentalMeetingType">
                    <option value="">Select</option>
                    <option *ngFor="let et of rentalMeetingTypes;" [ngValue]="et.id">{{et.name}}</option>
                </select>
                <div class="error" *ngIf="editor.get('rentalMeetingType').invalid && (editor.get('rentalMeetingType').dirty || editor.get('rentalMeetingType').touched)">
                    <div class="error__message" *ngIf="editor.get('rentalMeetingType').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editFulfillmentType" class="property-block__label">Rental Fulfillment Type</label>
            <div class="property-block__value">
                <select name="type" id="editEventType" required formControlName="rentalFulfillmentType">
                    <option value="">Select</option>
                    <option *ngFor="let et of rentalFulfillmentTypes;" [ngValue]="et.id">{{et.name}}</option>
                </select>
                <div class="error" *ngIf="editor.get('rentalFulfillmentType').invalid && (editor.get('rentalFulfillmentType').dirty || editor.get('rentalFulfillmentType').touched)">
                    <div class="error__message" *ngIf="editor.get('rentalFulfillmentType').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__value property-block__value--no-label">
                <label class="checkbox u-m-b-0">
                    <input type="checkbox" class="checkbox__input" formControlName="mouthpieceTesting">
                    Mouthpiece Testing
                </label>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__value property-block__value--no-label">
                <label class="checkbox u-m-b-0">
                    <input type="checkbox" class="checkbox__input" formControlName="bandProgramAttending">
                    Band Program Attending
                </label>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="this.editor.value.bandProgramAttending">
            <label for="editEstimatedBandRentals" class="property-block__label">Estimated Band Rentals</label>
            <div class="property-block__value">
                <input type="number" id="editEstimatedBandRentals" min="0" formControlName="estimatedBandRentals">
                <div class="error" *ngIf="editor.get('estimatedBandRentals').invalid && (editor.get('estimatedBandRentals').dirty || editor.get('estimatedBandRentals').touched)">
                    <div class="error__message" *ngIf="editor.get('estimatedBandRentals').hasError('min')">Must be greater than 0</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__value property-block__value--no-label">
                <label class="checkbox u-m-b-0">
                    <input type="checkbox" class="checkbox__input" formControlName="orchestraProgramAttending">
                    Orchestra Program Attending
                </label>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="this.editor.value.orchestraProgramAttending">
            <label for="editEstimatedOrchestraRentals" class="property-block__label">Estimated Orchestra Rentals</label>
            <div class="property-block__value">
                <input type="number" id="editEstimatedOrchestraRentals" min="0" formControlName="estimatedOrchestraRentals">
                <div class="error" *ngIf="editor.get('estimatedOrchestraRentals').invalid && (editor.get('estimatedOrchestraRentals').dirty || editor.get('estimatedOrchestraRentals').touched)">
                    <div class="error__message" *ngIf="editor.get('estimatedOrchestraRentals').hasError('min')">Must be greater than 0</div>
                </div>
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
        </div>
    </div>
</form>
