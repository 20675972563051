<nav class="u-m-b-16">
	<ul class="breadcrumbs">
		<li><a routerLink="/search/teachers">Teachers</a></li>
		<li>{{ (teacherDetails$ | async).name }}</li>
	</ul>
</nav>

<div class="entity-detail">
	<header class="row u-m-b-40">
		<div class="columns">
			<opus-teacher-header [teacherDetails]="teacherDetails$ | async"></opus-teacher-header>
		</div>

		<div class="columns small-4">
			<aside class="card">
				<div class="card__section">
					<opus-history-snapshot contextName="Teacher" [contextId]="(teacherDetails$ | async).id"></opus-history-snapshot>
				</div>
			</aside>
		</div>
	</header>

	<div class="row u-m-b-40">
		<div class="columns">
			<opus-property-block label="Teacher Information" [isEditing]="(panels$ | async)['info'].editing" (toggleEditor)="onToggleEditor('info', $event)" policy="Teacher.Edit">
				<opus-teacher-info viewer [teacher]="teacherDetails$ | async"></opus-teacher-info>
				<opus-teacher-info-editor editor [teacher]="teacherDetails$ | async" *ngIf="(panels$ | async)['info'].editing"></opus-teacher-info-editor>
			</opus-property-block>
			<opus-property-block label="District" [isEditing]="(panels$ | async)['district'].editing" (toggleEditor)="onToggleEditor('district', $event)" policy="Teacher.Edit">
				<opus-teacher-district viewer [teacher]="teacherDetails$ | async"></opus-teacher-district>
				<opus-teacher-district-editor editor [teacher]="teacherDetails$ | async" *ngIf="(panels$ | async)['district'].editing"></opus-teacher-district-editor>
			</opus-property-block>
			<opus-property-block label="Additional Details" [isEditing]="(panels$ | async)['additional'].editing" (toggleEditor)="onToggleEditor('additional', $event)" policy="Teacher.Edit">
				<opus-teacher-additional viewer [teacher]="teacherDetails$ | async"></opus-teacher-additional>
				<opus-teacher-additional-editor editor [teacher]="teacherDetails$ | async" *ngIf="(panels$ | async)['additional'].editing"></opus-teacher-additional-editor>
			</opus-property-block>
		</div>
		<div class="columns">
			<opus-property-block label="Mailing Address" [isEditing]="(panels$ | async)['address'].editing" (toggleEditor)="onToggleEditor('address', $event)" policy="Teacher.Edit">
				<opus-teacher-address viewer [teacher]="teacherDetails$ | async"></opus-teacher-address>
				<opus-teacher-address-editor editor [teacher]="teacherDetails$ | async" *ngIf="(panels$ | async)['address'].editing"></opus-teacher-address-editor>
			</opus-property-block>
			<opus-property-block label="Contact" [isEditing]="(panels$ | async)['contact'].editing" (toggleEditor)="onToggleEditor('contact', $event)" policy="Teacher.Edit">
				<opus-teacher-contact viewer [teacher]="teacherDetails$ | async"></opus-teacher-contact>
				<opus-teacher-contact-editor editor [teacher]="teacherDetails$ | async" *ngIf="(panels$ | async)['contact'].editing"></opus-teacher-contact-editor>
			</opus-property-block>
		</div>
	</div>

	<opus-school-searchresults-page id="schools" [teacher]="teacherDetails$ | async"></opus-school-searchresults-page>
	<br />
	<opus-program-searchresults-page id="programs" [teacher]="teacherDetails$ | async" opusAuthorize="Teacher.ViewAssociatedPrograms"></opus-program-searchresults-page>
</div>