<opus-modal [visible]="visible" (action)="onModalAction($event)" title="Add Program" primaryLabel="Add Program" secondaryLabel="Cancel" showClose="true" [primaryEnabled]="isValid">
	<form name="addProgramForm" [formGroup]="form" novalidate *ngIf="visible">
		<label for="apType">Program Type</label>
		<select name="type" id="apType" formControlName="programType">
			<option value="">Select</option>
			<option *ngFor="let pt of programTypes" value="{{ pt.id }}">{{ pt.name }}</option>
		</select>
		<div class="error error--adjust-margin" *ngIf="!form.controls.programType.valid && form.controls.programType.touched">
			<div class="error__message">Required</div>
		</div>
		<fieldset>
			<legend class="sr-only">School</legend>
			<div class="row">
				<div class="columns">
					<label for="apShoolId">School ID</label>
					<div class="input-group">
						<input type="text" id="apShoolId" placeholder="Enter School ID" name="schoolId" class="input-group-field" formControlName="schoolId">
						<div class="input-group-button">
							<button type="button" [disabled]="school || form.controls.schoolId.invalid || form.controls.schoolId.value.trim() === '' || state.schoolLookup.pending" (click)="lookupSchool()">Apply</button>
						</div>
					</div>
					<div class="error" *ngIf="form.controls.schoolId.invalid && form.controls.schoolId.value.trim() !== ''">
						<!--<div class="error__message">A school is required</div>-->
						<div class="error__message" *ngIf="form.controls.schoolId.errors.id">Invalid School ID</div>
					</div>
				</div>
				<div class="columns">
					<label for="apSchoolName">School Name</label>
					<span data-toggle="schoolTypeAhead"><input type="text" id="apSchoolName" autocomplete="off" (keyup)="lookupSchoolByName($event)" (keydown)="$event.stopPropagation()" formControlName="schoolName" role="combobox" aria-describedby="editSchoolNameDescription" name="schoolName"></span>
					<ul class="dropdown-menu" role="listbox" id="schoolTypeAhead" data-close-on-click="true" #schoolDropdown>
						<li *ngFor="let school of state.schoolByNameLookup.schools">
							<button type="button" class="dropdown-menu__action" data-close="schoolTypeAhead" (click)="selectSchool(school)" role="option">
								<span class="l-block">{{ school.longName }} ({{ school.id }})</span>
								<span class="l-block" *ngIf="school.county">{{ school.county }}</span><!-- only print this line if county has been assigned -->
								<span class="l-block">{{ school.city }}, {{ school.state}}</span>
							</button>
						</li>
					</ul>
					<p class="typography--caption u-m-b-0" id="editSchoolNameDescription" *ngIf="!school">
						Start typing the name of the school or
						<a routerLink="/search/schools" class="typography--caption" target="_blank">search for the ID</a>.
					</p>
				</div>
			</div>
		</fieldset>
	</form>
</opus-modal>
<div class="full-page-spinner" [class.full-page-spinner--is-active]="this.submitting">
	<div class="full-page-spinner__icon"></div>
</div>
