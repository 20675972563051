import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HistoryGuard implements CanActivate {
	constructor(private _store: Store<fromStore.DetailsState>) {
		console.log('HistoryGuard()...');
	}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		console.log('HistoryGuard: canActivate(): ', route);

		//	gather route parameters...
		const contextName = route.paramMap.get('type');
		const contextId = +route.paramMap.get('id');
		const scope = route.routeConfig.path.includes('preferences') ? 'preferences' : 'details';

		console.log(`HistoryGuard: Calling checkStore( '${contextName}', ${contextId}, '${scope}' )...`);

		//	always load history from the server - don't use the store as a cache
        const today = new Date(new Date().toDateString());
        const fromDate = new Date(today);
        fromDate.setDate(today.getDate() - 30);
        today.setDate(today.getDate() + 1);
		this._store.dispatch(new fromStore.LoadHistory({ contextName, contextId, scope, fromDate: fromDate.toISOString(), toDate: today.toISOString() }));

		return this.checkStore(contextName, contextId, scope).pipe(
			switchMap(found => {
				if (!found) {
					console.log('HistoryGuard: Not Found.');

					this._store.dispatch(new fromRoot.Go({ path: ['/404'] }));

					return of(false);
				}

				console.log('HistoryGuard: Found.');

				return of(true);
			}),
			catchError((error: HttpErrorResponse) => {
				console.log('HistoryGuard: Activation failed... Error: ', error);

				this._store.dispatch(new fromRoot.Go({ path: [error.status == 401 ? '/401' : '/500'] }));

				return of(false);
			}),
		);
	}

	private checkStore(contextName: string, contextId: number, scope: string): Observable<boolean> {
		return this._store.pipe(
			select(fromStore.getHistoryDetailsState),
			//	wait for it to be in the loaded state or for any errors
			filter(state => {
				return this.isHistoryLoaded(state, contextName, contextId, scope) || (this.hasHistoryError(state) && !state.loading);
			}),
			tap(state => {
				console.log('HistoryGuard: After filter... state: ', state);

				if (state.error && state.error.status != 404) {
					throw state.error;
				}
			}),
			//	map it to a boolean observable
			map(state => state.loaded),
			tap(state => {
				console.log('HistoryGuard: After map... state: ', state);
			}),
			//	get the first result & complete
			take(1),
		);
	}

	private isHistoryLoaded(state: fromStore.DetailsState, contextName: string, contextId: number, scope: string): boolean {
		return state.loaded && !!state.data && !!state.data.context && state.data.context.entityTypeName.toLowerCase() + 's' == contextName && state.data.context.entityId == contextId && state.data.context.scope.toLowerCase() == scope;
	}

	private hasHistoryError(state: fromStore.DetailsState): boolean {
		return !!state.error || (state.loaded && state.data === null);
	}
}
