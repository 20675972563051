import { Component, Input } from '@angular/core';

import { TeacherDetails } from '../../models';
import { SchoolType } from '../../../schools/models';

@Component({
	selector: 'opus-teacher-address',
	templateUrl: './teacher-address.component.html',
})
export class TeacherAddressComponent {
	@Input() teacher: TeacherDetails;

    get hasLine1() {
        return this.teacher.mailingAddress != null && this.teacher.mailingAddress.line1;
    }

    get hasLine2() {
        return this.teacher.mailingAddress != null && this.teacher.mailingAddress.line2;
    }

    get hasCityStateOrZip() {
        return this.teacher.mailingAddress != null &&
            (this.teacher.mailingAddress.city != null ||
                this.teacher.mailingAddress.state != null ||
                this.teacher.mailingAddress.zip != null);
    }

    get hasCounty() {
        return this.teacher.mailingAddress != null && this.teacher.mailingAddress.county != null;
    }

    get line1() {
        if (this.teacher.mailingAddress == null) {
            return null;
        }

        return this.teacher.mailingAddress.line1;
    }

    get line2() {
        if (this.teacher.mailingAddress == null) {
            return null;
        }

        return this.teacher.mailingAddress.line2;
    }

    get city() {
        if (this.teacher.mailingAddress == null) {
            return null;
        }

        return this.teacher.mailingAddress.city;
    }

    get state() {
        if (this.teacher.mailingAddress == null) {
            return null;
        }

        return this.teacher.mailingAddress.state;
    }

    get zip() {
        if (this.teacher.mailingAddress == null) {
            return null;
        }

        return this.teacher.mailingAddress.zip;
    }

    get county() {
        if (this.teacher.mailingAddress == null) {
            return null;
        }

        return this.teacher.mailingAddress.county;
    }
}
