<form name="schoolInformationForm" aria-label="Program Teachers" [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>

    <div class="property-block__content-wrapper u-m-b-16">
        <div class="property-block__content">
            <div class="table">
                <div class="table__row table__row--last table__row--align-middle">
                    <div class="table__cell">
                        <div class="input-group input-group--small u-m-b-0">
                            <input type="text" class="input-group-field" placeholder="Enter teacher ID" aria-label="Teacher ID" formControlName="teacherId">
                        </div>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        <button type="button"
                                class="btn btn--action btn--add"
                                aria-label="Add teacher  id to list of attendees"
                                title="Add"
                                (click)="lookupTeacher()"></button>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        or
                    </div>
                    <div class="table__cell">
                        <span data-toggle="teacherName">
                            <input type="text" placeholder="Enter teacher name" aria-describedby="attendingSchoolNameDescription" auto-complete="off" aria-label="Teacher name" (keyup)="lookupTeacherByCriteria($event)" (keydown)="$event.stopPropagation()" formControlName="teacherName">
                        </span>
                        <ul class="dropdown-menu" role="menu" id="teacherName" #teacherDropdown data-close-on-click="true">
                            <li role="menu-item" *ngFor="let teacher of teacherByCriteriaLookup?.teachers">
                                <button type="button" class="dropdown-menu__action" data-close (click)="selectTeacher(teacher)">
                                    <span class="l-block">{{ teacher.name }}</span>
                                    <span class="l-block">{{ teacher.county }}</span> <!-- only print this line if county has been assigned -->
                                    <span class="l-block">{{ teacher.city }}, {{ teacher.state}}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        <button type="button"
                                class="btn btn--action btn--add"
                                aria-label="Add teacher"
                                title="Add"
                                (click)="addTeacher()"></button>
                    </div>
                </div>
                <div class="table__row table__row--last">
                    <div class="table__cell table__cell--align-right">
                        <p class="typography--caption u-m-b-0" id="teacherNameDescription">
                            Start typing the name of the teacher or search for the ID.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /only edit mode -->
    <!-- always display -->
    <opus-program-program-teachers viewer [program]="editProgram" (remove)="remove($event)" (isPrimary)="isPrimary($event)" editMode="true"></opus-program-program-teachers>
    <!-- /always display -->

    <ul class="error" *ngIf="false">
        <li class="error__message" *ngIf="false">Server error</li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
        </div>
    </div>
</form>
