import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import { District, DistrictRelMgrVM } from '../models';

import * as fromStore from '../store';

@Component({
	selector: 'opus-district-page',
	templateUrl: './district-details.component.html',
})
export class DistrictDetailsComponent implements OnInit {
	districtDetails$: Observable<District>;
	relationshipManagerVM$: Observable<DistrictRelMgrVM>;
	panels$: Observable<{ [id: string]: fromStore.PanelState }>;
	scrollTo: Element = null;

	private _district: District;

	constructor(private store: Store<fromStore.DistrictsState>, router: Router) {
		console.log('DistrictDetailsComponent: constructor().');

		router.events.subscribe(s => {
			if (s instanceof NavigationEnd) {
				const tree = router.parseUrl(router.url);

				if (tree.fragment) {
					const element = document.querySelector('#' + tree.fragment);

					if (element) {
						this.scrollTo = element;
						element.scrollIntoView();
					}
				}
			}
		});
	}

	get districtId(): number {
		return this._district.id;
	}

	ngOnInit() {
		console.log('DistrictDetailsComponent: ngOnInit().');

		this.districtDetails$ = this.store.pipe(select(fromStore.getDetailsStateDistrict));

		this.panels$ = this.store.pipe(select(fromStore.getDetailsStatePanels));
	}

	ngAfterViewInit() {
		if (this.scrollTo) {
			console.log('scrollIntoView: ', this.scrollTo);

			this.scrollTo.scrollIntoView();
			this.scrollTo = null;
		}
	}

	onToggleEditor(key: string, open: boolean) {
		this.store.dispatch(new fromStore.UpdateEditorState({ key, open }));
	}
}
