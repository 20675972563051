import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDetails from './details.reducer';
import * as fromSearchResults from './searchresults.reducer';

export interface DistrictsState {
    details: fromDetails.DetailsState;
    search: fromSearchResults.SearchResultsState;
}

export const reducers: ActionReducerMap<DistrictsState> = {
    details: fromDetails.reducer,
    search: fromSearchResults.reducer
};

export const getDistrictsState = createFeatureSelector<DistrictsState>('districts');

export { DetailsState, PanelState, PanelId, ValidationStatus, ZipLookupState, CountyLookupState, AddressPanelState, DistrictLookupsState, DistrictByNameLookupState, /*RelationshipManagerState*/ } from './details.reducer';
export { SearchResultsState } from './searchresults.reducer';
