import * as fromActions from '../actions';
import * as fromModels from '../../models';

export interface DistributionDetailsState {
    loadingCharges: boolean;
    loadedCharges: boolean;
    errors: any[];
    shippingCharges: fromModels.ShippingCharge[];
}

export const initialState: DistributionDetailsState = {
    loadingCharges: false,
    loadedCharges: false,
    errors: null,
    shippingCharges: null,
};

export function reducer(state = initialState, action: fromActions.DistributionActions): DistributionDetailsState {
	switch (action.type) {
		case fromActions.DistributionActionTypes.LoadShippingCharges: {
			return {
				...state,
				loadingCharges: true,
				loadedCharges: false,
				shippingCharges: null,
			};
		}

		case fromActions.DistributionActionTypes.LoadShippingChargesSuccess: {
			return {
				...state,
				loadingCharges: false,
				loadedCharges: true,
				errors: null,
				shippingCharges: [...action.payload],
			};
		}

		case fromActions.DistributionActionTypes.LoadShippingChargesFailure: {
			return {
				...state,
				loadingCharges: false,
				loadedCharges: false,
				errors: action.payload,
				shippingCharges: null,
			};
		}

		default: {
			if (Object.values(fromActions.DistributionActionTypes).includes((action as fromActions.DistributionActions).type)) {
				console.log('distribution.reducer: unhandled action: ', action);
			}
		}
	}

	return state;
}

