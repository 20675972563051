import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Product } from '../models';
import * as fromStore from '../store';

@Component({
  selector: 'opus-product-groups',
  templateUrl: './product-groups.component.html',
})
export class ProductGroupsComponent implements OnInit, OnDestroy {
  alive = true;
  vm$: Observable<fromStore.CombosState>;
  vm: fromStore.CombosState;
  productGroupControl = new FormControl('');
  selectedProductGroupId: number = null;
  sort = { by: 'itemId', reverse: false };

  constructor(private store: Store<fromStore.CombosState>, private cdr: ChangeDetectorRef, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.vm$ = this.store.pipe(select(fromStore.getCombosState));
    this.vm$.pipe(
      takeWhile(() => this.alive)
    ).subscribe(vm => {
      this.vm = vm;

      if (!vm.loadedProductGroups && !vm.loadingProductGroups && !vm.errors) {
        this.store.dispatch(new fromStore.LoadProductGroups());
      }
      
      this.cdr.detectChanges();
    });

    this.productGroupControl.valueChanges.pipe(
      takeWhile(() => this.alive)
    ).subscribe(selectedProductGroupId => {

      if (selectedProductGroupId !== null && selectedProductGroupId != this.selectedProductGroupId) {
        this.selectedProductGroupId = selectedProductGroupId;
        this.store.dispatch(new fromStore.LoadProducts(selectedProductGroupId));
      }
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  get isLoading(): boolean {
    return this.selectedProductGroupId !== null && this.vm.products[this.selectedProductGroupId] && this.vm.products[this.selectedProductGroupId].loading;
  }

  get products(): Product[] {
    return this.vm.products[this.selectedProductGroupId] ? this.vm.products[this.selectedProductGroupId].products || [] : [];
  }

  get filteredProducts(): Product[] {
    let filteredProducts = [...this.products];

    filteredProducts.sort((a, b) => {
      let ret = 0;

      switch (this.sort.by) {
        case 'itemId':
          ret = a.productCode < b.productCode ? -1 : 1;
          break;
        case 'variantId':
          ret = a.variantId < b.variantId ? -1 : 1;
          break;
        case 'active':
          ret = a.active < b.active ? -1 : 1;
          break;
        case 'brand':
          ret = a.brandName < b.brandName ? -1 : 1;
          break;
        case 'itemName':
          ret = a.productName < b.productName ? -1 : 1;
          break;
        case 'variantName':
          ret = a.optionName < b.optionName ? -1 : 1;
          break;
        case 'rentalInstruments':
          ret = a.rentalInstruments < b.rentalInstruments ? -1 : 1;
          break;
      }

      return this.sort.reverse ? -ret : ret;
    });

    return filteredProducts;
  }

  sortBy(by: string) {
    if (by == this.sort.by) {
      this.sort.reverse = !this.sort.reverse;
    } else {
      this.sort.by = by;
      this.sort.reverse = false;
    }
  }
}
