import { Component, Input } from '@angular/core';

import { TabsComponent } from './tabs.component';

@Component({
	selector: 'opus-tab',
	templateUrl: './tab.component.html',
})
export class TabComponent {
	@Input() name;
	active = false;

	constructor(private tabs: TabsComponent) {
		tabs.addTab(this);
	}
}
