<nav class="u-m-b-16">
	<ul class="breadcrumbs">
		<li><a routerLink="../">Merchandising</a></li>
		<li>Instrument Combinations</li>
	</ul>
</nav>

<section class="card">
	<div class="card__section">
		<div class="row align-middle">
			<div class="columns">
				<h2 class="card__title card__title--large">Instrument Combinations</h2>
			</div>
			<div class="columns shrink">
				<p class="u-m-b-0 typography--body" aria-live="polite" *ngIf="selectedClassId && !isLoading">
					Viewing <strong>{{ filteredCombos.length || 0 }}</strong> Combinations
				</p>
			</div>
		</div>
	</div>

	<div class="card__section spinner-section" [class.spinner-section--is-active]="vm.loadingClasses">
		<div class="spinner-section__icon"></div>
		<div class="row align-middle">
			<div class="columns shrink">
				<label for="instrumentClass">Instrument Class</label>
			</div>
			<div class="columns small-5">
				<select id="instrumentClass" class="u-m-b-0" [formControl]="classCtrl">
					<option value="">Select</option>
					<option *ngFor="let c of vm.classes" value="{{ c.id }}">{{ c.name }}</option>
				</select>
			</div>
		</div>
	</div>

	<div class="card__section u-background--muted" *ngIf="selectedCombos.length && !updating">
		<div class="row align-middle typography--body">
			<div class="columns shrink">
				<p class="u-m-b-0">Selected {{ selectedCombos.length }} combination{{ selectedCombos.length > 1 ? 's' : '' }}</p>
			</div>
			<div class="columns">
				<button class="link u-text--uppercase" type="button" (click)="toggleSelectAll(false)">Cancel</button>
			</div>
			<div class="columns shrink">
				<div class="btn-group">
					<button class="btn-group__btn btn btn--tiny btn--light btn--elevated" type="button" (click)="updateSelected(true)" [disabled]="!hasUnavailableSelections">Make Available</button>
					<button class="btn-group__btn btn btn--tiny btn--light btn--elevated" type="button" (click)="updateSelected(false)" [disabled]="!hasAvailableSelections">Make Unavailable</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card__section spinner-section" [class.spinner-section--is-active]="isLoading || updating || deleting">
		<div class="spinner-section__icon"></div>
		<p *ngIf="!combos.length && selectedClassId && !isLoading">The selected category has no instrument combinations to manage.</p>
		<div class="table table--align-left" role="table" *ngIf="!isLoading && combos.length > 0">
			<div role="rowgroup">

				<div class="table__row table__row--header" role="rowheader">
					<span class="table__cell table__cell--shrink" role="columnheader">
						<button type="button" class="checkbox checkbox--no-label u-m-b-0"
								[ngClass]="{
                                'checkbox--active': selectedCombos.length && filteredCombos.length === selectedCombos.length,
                                'checkbox--indeterminate': selectedCombos.length && filteredCombos.length !== selectedCombos.length
                            }"
								(click)="toggleSelectAll()"
								title="Select/Unselect all visible combinations"
                                opusAuthorize="InstrumentCombinations.Edit"
                                opusAuthorizeAction="disable"
                                >
							<span class="checkbox__input" role="presentation"></span>
							<span class="sr-only">Select or unselect all visible combinations</span>
						</button>
					</span>
					<span class="table__cell" role="columnheader">
						<button type="button" (click)="sortBy('catName')" class="table__sorter-btn"
								[ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'catName' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'catName' && !sort.reverse
                            }">
							Category
						</button>
					</span>
					<span class="table__cell" role="columnheader">
						<button type="button" (click)="sortBy('catCode')" class="table__sorter-btn"
								[ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'catCode' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'catCode' && !sort.reverse
                            }">
							Category Code
						</button>
					</span>
					<span class="table__cell" role="columnheader">
						<button type="button" (click)="sortBy('brand')" class="table__sorter-btn"
								[ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'brand' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'brand' && !sort.reverse
                            }">
							Brand
						</button>
					</span>
					<span class="table__cell table__cell--double" role="columnheader">
						<button type="button" (click)="sortBy('size')" class="table__sorter-btn"
								[ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'size' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'size' && !sort.reverse
                            }">
							Size
						</button>
					</span>
					<span class="table__cell table__cell--double" role="columnheader">
						<button type="button" (click)="sortBy('color')" class="table__sorter-btn"
								[ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'color' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'color' && !sort.reverse
                            }">
							Color
						</button>
					</span>
					<span class="table__cell table__cell--align-center" role="columnheader" id="activeHeader">
						<button type="button" (click)="sortBy('available')" class="table__sorter-btn"
								[ngClass]="{
                                'table__sorter-btn--sorted-asc': sort.by === 'available' && sort.reverse,
                                'table__sorter-btn--sorted-dsc': sort.by === 'available' && !sort.reverse
                            }">
							Available
						</button>
					</span>
					<span class="table__cell table__cell--half table__cell--align-center table__cell--align-middle" role="columnheader">
						Delete
					</span>
				</div>

				<div class="table__row" [formGroup]="filters">
					<div class="table__cell table__cell--shrink"></div>
					<div class="table__cell">
						<input type="text" placeholder="Category filter" title="Category filter" aria-label="Category filter" formControlName="catName">
					</div>
					<div class="table__cell">
						<input type="text" placeholder="Category code filter" title="Category code filter" aria-label="Category code filter" formControlName="catCode">
					</div>
					<div class="table__cell">
						<input type="text" placeholder="Brand filter" title="Brand filter" aria-label="Brand filter" formControlName="brand">
					</div>
					<div class="table__cell table__cell--double">
						<input type="text" placeholder="Size filter" title="Size filter" aria-label="Size filter" formControlName="size">
					</div>
					<div class="table__cell table__cell--double">
						<input type="text" placeholder="Color filter" title="Color filter" aria-label="Color filter" formControlName="color">
					</div>
					<div class="table__cell">
						<select title="Available filter" aria-label="Available filter" formControlName="available">
							<option value="any">Any</option>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</div>
					<span class="table__cell table__cell--half"></span>
				</div>
			</div>

			<div role="rowgroup">
				<div class="table__row table__row--align-middle" role="row" [ngClass]="{'table__row--last': isLast, 'table__row--alt': isOdd}" *ngFor="let c of filteredCombos; let isOdd = odd; let isLast = last;">
					<span class="table__cell table__cell--shrink" role="cell">
						<div class="checkbox checkbox--no-label u-m-b-0">
							<input type="checkbox" class="checkbox__input" aria-label="Select this combination" [checked]="isSelected(c.id)" (change)="onSelectCombo(c.id)"                                 
                                    opusAuthorize="InstrumentCombinations.Edit"
                                    opusAuthorizeAction="disable">
						</div>
					</span>
					<span class="table__cell" role="cell">{{ c.rentalCategory.name }}</span>
					<span class="table__cell" role="cell">{{ c.rentalCategory.code }}</span>
					<span class="table__cell" role="cell">{{ c.brand.name }}</span>
					<span class="table__cell table__cell--double" role="cell">{{ c.size || 'Any'}}</span>
					<span class="table__cell table__cell--double" role="cell">{{ c.color || 'Any' }}</span>
					<span class="table__cell table__cell--align-center" role="cell">
						<div class="switch switch--tiny input--inline u-m-b-0">
							<input type="checkbox" class="switch__input" id="cActive{{ c.id }}" [checked]="c.isAvailable" (change)="onToggleAvailable(c)"                                 
                                    opusAuthorize="InstrumentCombinations.Edit"
                                    opusAuthorizeAction="disable">

							<label for="cActive{{ c.id }}" class="switch__paddle">
								<span class="show-for-sr">Is available?</span>
								<span class="switch__text switch__text--active" aria-hidden="true">Yes</span>
								<span class="switch__text switch__text--inactive" aria-hidden="true">No</span>
							</label>
						</div>
					</span>
					<span class="table__cell table__cell--half table__cell--align-center table__cell--align-middle" role="cell">
						<button class="btn--delete btn--no-padding" (click)="onConfirmDelete(c)" title="Delete this combination" 
                                opusAuthorize="InstrumentCombinations.Edit"
                                opusAuthorizeAction="disable">
							<div class="sr-only">Delete this combination</div>
						</button>
					</span>
				</div>
			</div>
		</div>
	</div>

</section>

<opus-modal [visible]="showDeleteModal" (action)="onDeleteModalAction($event)" title="Delete Instrument Combination" primaryLabel="Delete Instrument Combination" secondaryLabel="Cancel" showClose="true">
	<ng-container *ngIf="showDeleteModal">
		<p>Delete this combination? This cannot be undone.</p>
		<div class="table table--align-left u-m-b-16" role="table">
			<div role="rowgroup">
				<div class="table__row table__row--header" role="rowheader">
					<span class="table__cell" role="columnheader">Category</span>
					<span class="table__cell" role="columnheader">Brand</span>
					<span class="table__cell" role="columnheader">Size</span>
					<span class="table__cell" role="columnheader">Color</span>
				</div>
			</div>
			<div role="rowgroup">
				<div class="table__row table__row--last" role="row">
					<span class="table__cell" role="cell">{{ deletingCombo.rentalCategory.name }}</span>
					<span class="table__cell" role="cell">{{ deletingCombo.brand.name }}</span>
					<span class="table__cell" role="cell">{{ deletingCombo.size || 'Any' }}</span>
					<span class="table__cell" role="cell">{{ deletingCombo.color || 'Any' }}</span>
				</div>
			</div>
		</div>
	</ng-container>
</opus-modal>
