<section class="property-block">
    <div class="property-block__title-wrapper">
        <h2 class="property-block__title">Relationship Manager</h2>
    </div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <div class="property-block__label" id="active">Inherit Manager</div>
            <div class="property-block__value" role="definition" aria-labelledby="active">{{ program.school.inheritRelationshipManager | yesNo }}</div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="schoolId">Manager ID</div>
            <div class="property-block__value" role="definition" aria-labelledby="schoolId">{{ program.school.relationshipManager.locationCode }}</div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="shortName">Manager Name</div>
            <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ program.school.relationshipManager.name }}</div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="shortName">Inherit Relationship Type</div>
            <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ program.school.inheritRelationshipType | yesNo }}</div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="shortName">Relationship Type</div>
            <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ relationshipType }}</div>
        </li>
    </ul>
</section>