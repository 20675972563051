<form [formGroup]="editor" name="eventPromotionsForm" aria-label="Manage promotions for event" novalidate (ngSubmit)="onSubmit()" class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <!-- edit mode only -->
    <div class="property-block__content-wrapper u-m-b-16">
        <div class="property-block__content" opusAuthorize="Event.ApprovePromo">
            <p class="typography--body" *ngIf="(approvedPromos$ | async)?.length == 0">There are no approved promotions for this event.</p>
            <div class="table" *ngIf="(approvedPromos$ | async)?.length > 0">
                <div class="table__row table__row--last table__row--align-middle">
                    <div class="table__cell table__cell--shrink">
                        <label for="promotionToAdd">Available Promotions</label>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        <select id="promotionToAdd"
                                class="u-m-b-0"
                                formControlName="promotionToAdd">
                                <!--ng-model="EventPromotionsCtrl.promotionToAdd"
                                ng-options="promo as (promo.name + ' - ' + promo.description) for promo in EventPromotionsCtrl.activePromotions"-->
                            <option value="null">Select a promotion</option>
                            <option *ngFor="let promo of approvedPromos$ | async"  [value]="promo?.promoId">{{promo?.name}} - {{promo?.description}}  </option>
                        </select>
                    </div>
                    <div class="table__cell table__cell--shrink" >
                        <button type="button" class="btn btn--action btn--add" aria-label="Add" title="Add" (click)="AddSelectedPromo()" ></button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /edit mode only -->
    <!-- always display -->
    <event-promo viewer [event]="editEvent" editMode="true" (remove)="removePromotion($event)" ></event-promo>
    <!-- /always display -->
    <!-- edit mode only -->
    <div class="u-m-t-16">
        <button type="submit" class="property-block__action-btn">Save</button>
    </div>
    <!-- /edit mode only -->
</form>
