import { Component, Input } from '@angular/core';

import { ProgramDetails } from '../../models/program-details.model';
import { ProgramTypeLookup } from '../../../scp-common/models';

@Component({
	selector: 'opus-program-header',
	templateUrl: './program-header.html',
})
export class ProgramHeaderComponent {
	@Input() program: ProgramDetails;

	get id() {
        return this.program.id;
	}

    get programType() {
        return ProgramTypeLookup[this.program.programTypeId].name;
    }

    get schoolName() {
        return this.program.school.longName;
	}
}
