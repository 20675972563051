import { SchoolHeaderComponent } from './school-header';
import { SchoolInfoComponent } from './school-info.component';
import { SchoolAddressComponent } from './school-address.component';
import { SchoolContactsComponent } from './school-contacts.component';
import { SchoolRateComponent } from './school-rate.component';
import { SchoolCalendarComponent } from './school-calendar.component';
import { SchoolRelationshipComponent } from './school-relationship.component';
import { SchoolOnlineRentalsComponent } from './school-online-rentals.component';
import { SchoolRentalReturnsComponent } from './school-rental-returns.component';
import { SchoolAdditionalDetailsComponent } from './school-additional-details.component';

export const detailsComponents: any[] = [
	SchoolHeaderComponent,
	SchoolInfoComponent,
	SchoolAddressComponent,
	SchoolContactsComponent,
	SchoolRateComponent,
	SchoolCalendarComponent,
	SchoolRelationshipComponent,
	SchoolOnlineRentalsComponent,
	SchoolRentalReturnsComponent,
	SchoolAdditionalDetailsComponent,
];

export * from './school-header';
export * from './school-info.component';
export * from './school-address.component';
export * from './school-contacts.component';
export * from './school-rate.component';
export * from './school-calendar.component';
export * from './school-relationship.component';
export * from './school-online-rentals.component';
export * from './school-rental-returns.component';
export * from './school-additional-details.component';
