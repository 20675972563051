import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { SchoolDetails } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-school-rental-returns-editor',
	templateUrl: './school-rental-returns-editor.component.html',
})
export class SchoolRentalReturnsEditorComponent {
	alive = true;
	schoolDetails$: Observable<SchoolDetails>;
	panelState$: Observable<fromStore.PanelState>;
	school: SchoolDetails;
	panelState: fromStore.PanelState;
	editor: FormGroup;
	months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	days: { [id: string]: string[] } = {};
	blackoutStart: Date;
	blackoutEnd: Date;

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder) {
		console.log('SchoolRentalReturnsEditorComponent()...');
	}

	ngOnInit() {
		console.log('SchoolRentalReturnsEditorComponent.ngOnInit()...');

		this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
		this.schoolDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => this.school = s);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateRentalReturnsPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('SchoolRentalReturnsEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('SchoolRentalReturnsEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		this.blackoutStart = this.school.blackoutStart
			? new Date(Date.parse(this.school.blackoutStart))
			: new Date(9999, 0, 1);
		this.blackoutEnd = this.school.blackoutEnd
			? new Date(Date.parse(this.school.blackoutEnd))
			: new Date(9999, 0, 1);

		this.editor = this.fb.group({
			returnsAllowed: [this.returnsAllowed],
			sessionLocation: [this.school.sessionLocation],
			summerLocation: [this.school.summerLocation],
			hasBlackoutPeriod: [this.hasBlackoutPeriod],
			blackoutStartMonth: [this.blackoutStart.getMonth()],
			blackoutStartDay: [this.blackoutStart.getDate()],
			blackoutEndMonth: [this.blackoutEnd.getMonth()],
			blackoutEndDay: [this.blackoutEnd.getDate()],
		});

		this.initMonthChanges();
	}

	resetForm() {
		this.editor.reset({
			returnsAllowed: this.returnsAllowed,
			sessionLocation: this.school.sessionLocation,
			summerLocation: this.school.summerLocation,
			hasBlackoutPeriod: this.hasBlackoutPeriod,
			blackoutStartMonth: this.blackoutStart.getMonth(),
			blackoutStartDay: this.blackoutStart.getDate(),
			blackoutEndMonth: this.blackoutEnd.getMonth(),
			blackoutEndDay: this.blackoutEnd.getDate(),
		});
	}

	initMonthChanges() {
		const keys: string[] = ['blackoutStart', 'blackoutEnd'];

		for (const key of keys) {
			this.days[this[key].getMonth()] = this.getDaysInMonth(this[key].getMonth());

			this.editor.get(key + 'Month').valueChanges.pipe(
				takeWhile(() => this.alive),
			).subscribe(val => {
				console.log(key + 'Month.valueChanges: ', val);

				if (!this.days[val]) {
					this.days[val] = this.getDaysInMonth(val);
				}

				const ctrl = this.editor.get(key + 'Day');
				const lastDay = this.days[val][this.days[val].length - 1];

				if (+ctrl.value > +lastDay) {
					ctrl.setValue(lastDay, { emitEvent: false });
				}
			});
		}
	}

	getDaysInMonth(month) {
		const days: string[] = [];

		for (let i = 1; i <= new Date(9999, +month + 1, 0).getDate(); i++) {
			days.push(i.toString());
		}

		return days;
	}

	get canSave(): boolean {
		return this.editor.valid && this.editor.dirty;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	get returnsAllowed() {
		return this.school.returnsAllowed ? 'Yes' : 'No';
	}

	get hasBlackoutPeriod() {
		return this.school.hasBlackoutPeriod ? 'Yes' : 'No';
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status, this.editor.value);

		const hasBlackOutPeriod = this.editor.value.hasBlackoutPeriod == 'Yes';
		const blackoutStart = hasBlackOutPeriod ? `${+this.editor.value.blackoutStartMonth + 1}/${this.editor.value.blackoutStartDay}/9999` : this.school.blackoutStart;
		const blackoutEnd = hasBlackOutPeriod ? `${+this.editor.value.blackoutEndMonth + 1}/${this.editor.value.blackoutEndDay}/9999` : this.school.blackoutEnd;

		const school = {
			...this.school,
			returnsAllowed: this.editor.value.returnsAllowed == 'Yes',
			sessionLocation: this.editor.value.sessionLocation,
			summerLocation: this.editor.value.summerLocation,
			hasBlackoutPeriod: this.editor.value.hasBlackoutPeriod == 'Yes',
			blackoutStart,
			blackoutEnd,
		};

		this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: fromStore.PanelId.RentalReturns, school }));
	}
}
