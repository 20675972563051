<form [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editCurrentRateCode" class="property-block__label">Agreement ID</label>
			<div class="property-block__value">
				<div class="input-group u-m-b-0">
					<input type="text" name="agreementId" class="input-group-field" formControlName="rateCode">
					<div class="input-group-button">
						<button type="button" (click)="onApplyRateCode()">Apply</button>
					</div>
				</div>
				<ul class="error" *ngIf="hasError">
					<li class="error__message" *ngIf="editor.controls.rateCode.hasError('notFound')">There is no Rate Agreement with an ID of '{{editor.value.rateCode}}'.</li>
				</ul>
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="rateDescription">Description</div>
			<div class="property-block__value" role="definition" aria-labelledby="rateDescription">
				<div>{{ rateDescription }}</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="rateEffectivity">Effectivity</div>
			<div class="property-block__value" role="definition" aria-labelledby="rateEffectivity">{{ rateEffectivity }}</div>
		</li>
		<li class="property-block__list-item">
			<label for="editNodeLevel" class="property-block__label">Node Level</label>
			<div class="property-block__value">
				<select id="editNodeLevel" formControlName="nodeLevel">
					<option value="">Select Level</option>
					<option *ngFor="let nl of nodeLevels" [value]="nl">{{ nodeLevelNames[nl] }}</option>
				</select>
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
