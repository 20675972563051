<form [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate
	  class="spinner-section"
	  [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<label for="editPrimary" class="property-block__label">Primary ID</label>
			<div class="property-block__value">
				<input type="text" id="editPrimary" formControlName="primaryId"
					   opusAuthorize="School.EditPrimary"
					   opusAuthorizeAction="disable"
					   [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
					   [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
				<ul class="error" *ngIf="hasPrimaryIdErrors">
					<li class="error__message"
						*ngIf="primaryIdErrors.primaryId">
						{{ primaryIdErrors.primaryId }}
					</li>
					<li class="error__message"
						*ngIf="primaryIdErrors.canDeactivate">
						{{ primaryIdErrors.canDeactivate }}
					</li>
					<li class="error__message"
						*ngIf="primaryIdErrors.schoolExists">
						{{ primaryIdErrors.schoolExists }}
					</li>
				</ul>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="ncesSchoolId" class="property-block__label">NCES School ID</label>
			<div class="property-block__value">
				<input type="text" id="editNcesSchoolId" formControlName="ncesSchoolId" maxlength="15"
					   opusAuthorize="School.EditNcesSchoolId"
					   opusAuthorizeAction="disable"
					   [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
					   [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
			</div>
		</li>
		<!--<li class="property-block__list-item">
			<label for="editNcesIdSource" class="property-block__label">NCES ID Source</label>
			<div class="property-block__value">
				<select id="editNcesIdSource" formControlName="ncesIdSource"
					   opusAuthorize="School.EditNcesSchoolId"
					   opusAuthorizeAction="disable"
					   [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
					   [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
					<option value="">Select ID Source</option>
					<option *ngFor="let s of schoolNcesIdSources" [value]="s">{{ s }}</option>
				</select>
			</div>
		</li>-->
		<li class="property-block__list-item">
			<label for="editSummerBilling" class="property-block__label">
				Summer
				Billing
			</label>
			<div class="property-block__value">
				<div class="md-selector" role="group"
					 aria-labelledby="editSummerBilling">
					<div class="md-selector__month">
						<label>
							Month
							<select formControlName="summerBillingMonth"
									opusAuthorize="School.EditSummerBilling"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let m of months; let i = index"
										[value]="i">
									{{ m }}
								</option>
							</select>
						</label>
					</div>
					<div class="md-selector__day">
						<label>
							Day
							<select formControlName="summerBillingDay"
									opusAuthorize="School.EditSummerBilling"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let d of days[editor.value.summerBillingMonth]"
										[value]="d">
									{{ d }}
								</option>
							</select>
						</label>
					</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editSummerCustomerInHand"
				   class="property-block__label">Summer Customer in-hand</label>
			<div class="property-block__value">
				<div class="md-selector" role="group"
					 aria-labelledby="editSummerCustomerInHand">
					<div class="md-selector__month">
						<label>
							Month
							<select formControlName="summerCustomerInHandDateMonth"
									opusAuthorize="School.EditSummerCustomerInHandDate"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let m of customerInHandMonths; let i = index"
										[value]="i">
									{{ m }}
								</option>
							</select>
						</label>
					</div>
					<div class="md-selector__day">
						<label>
							Day
							<select formControlName="summerCustomerInHandDateDay"
									opusAuthorize="School.EditSummerCustomerInHandDate"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let d of days[editor.value.summerCustomerInHandDateMonth]"
										[value]="d">
									{{ d }}
								</option>
							</select>
						</label>
					</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editFallBilling" class="property-block__label">
				Fall
				Billing
			</label>
			<div class="property-block__value">
				<div class="md-selector" role="group"
					 aria-labelledby="editFallBilling">
					<div class="md-selector__month">
						<label>
							Month
							<select formControlName="fallBillingMonth"
									opusAuthorize="School.EditFallBilling"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let m of months; let i = index"
										[value]="i">
									{{ m }}
								</option>
							</select>
						</label>
					</div>
					<div class="md-selector__day">
						<label>
							Day
							<select formControlName="fallBillingDay"
									opusAuthorize="School.EditFallBilling"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let d of days[editor.value.fallBillingMonth]"
										[value]="d">
									{{ d }}
								</option>
							</select>
						</label>
					</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editFallCustomerInHand"
				   class="property-block__label">Fall Customer in-hand</label>
			<div class="property-block__value">
				<div class="md-selector" role="group"
					 aria-labelledby="editFallCustomerInHand">
					<div class="md-selector__month">
						<label>
							Month
							<select formControlName="fallCustomerInHandDateMonth"
									opusAuthorize="School.EditFallCustomerInHandDate"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let m of customerInHandMonths; let i = index"
										[value]="i">
									{{ m }}
								</option>
							</select>
						</label>
					</div>
					<div class="md-selector__day">
						<label>
							Day
							<select formControlName="fallCustomerInHandDateDay"
									opusAuthorize="School.EditFallCustomerInHandDate"
									opusAuthorizeAction="disable"
									[opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']"
									[opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
								<option *ngFor="let d of days[editor.value.fallCustomerInHandDateMonth]"
										[value]="d">
									{{ d }}
								</option>
							</select>
						</label>
					</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<label for="editSummerFree" class="property-block__label">
				Summer
				Free
			</label>
			<div class="property-block__value">
				<select id="editSummerFree" formControlName="summerFree"
						opusAuthorize="School.EditSummerFree"
						opusAuthorizeAction="disable">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
			</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="latitude">Latitude</div>
			<div class="property-block__value" role="definition"
				 aria-labelledby="latitude">{{ school.latitude }}</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="longitude">Longitude</div>
			<div class="property-block__value" role="definition"
				 aria-labelledby="longitude">{{ school.longitude }}</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label" id="timezone">Time Zone</div>
			<div class="property-block__value" role="definition"
				 aria-labelledby="timezone">{{ school.ianaTimeZoneId }}</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn"
					[disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
