import { Component, Input } from '@angular/core';

import { Event } from '../../models';

@Component({
    selector: 'event-info',
    templateUrl: './event-info.component.html',
})
export class EventInfoComponent {
    @Input() event: Event;

}
