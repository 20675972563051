import * as fromProgramCreate from '../actions/program-create.actions';
import { SchoolLookup } from '../../../schools/models';

export interface SchoolLookupState {
	id: number;
	pending: boolean;
	errors: any;
	school: SchoolLookup;
}

export interface SchoolByNameLookupState {
	name: string;
	pending: boolean;
	errors: any;
	schools: SchoolLookup[];
}

export interface ProgramCreateState {
	creating: boolean;
	created: boolean;
	errors: any;
	schoolLookup: SchoolLookupState;
	schoolByNameLookup: SchoolByNameLookupState;
}

export const initialSchoolLookupState: SchoolLookupState = {
	id: null,
	pending: false,
	errors: null,
	school: null,
};

export const initialSchoolByNameLookupState: SchoolByNameLookupState = {
	name: null,
	pending: false,
	errors: null,
	schools: null,
};

export const initialCreateState: ProgramCreateState = {
	creating: false,
	created: false,
	errors: null,
	schoolLookup: initialSchoolLookupState,
	schoolByNameLookup: initialSchoolByNameLookupState,
};

export function reducer(state = initialCreateState, action: fromProgramCreate.ProgramCreateActions): ProgramCreateState {
	switch (action.type) {
		case fromProgramCreate.ProgramCreateActionTypes.CreateProgram: {
			console.log('reducer:', action);

			return {
				...state,
				created: false,
				creating: true,
				errors: null,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.CreateProgramSuccess: {
			console.log('reducer:', action);

			return {
				...state,
				created: true,
				creating: false,
				errors: null,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.CreateProgramFailure: {
			console.log('reducer:', action);

			return {
				...state,
				created: false,
				creating: false,
				errors: action.payload,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.SchoolLookupById: {
			const { id } = action.payload;
			const schoolLookup: SchoolLookupState = {
				...state.schoolLookup,
				id,
				pending: true,
				errors: null,
				school: null,
			};

			return {
				...state,
				schoolLookup,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.SchoolLookupByIdSuccess: {
			const { school } = action.payload;
			const schoolLookup: SchoolLookupState = {
				...state.schoolLookup,
				pending: false,
				school,
			};

			return {
				...state,
				schoolLookup,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.SchoolLookupByIdFailure: {
			const { errors } = action.payload;
			const schoolLookup: SchoolLookupState = {
				...state.schoolLookup,
				pending: false,
				errors,
				school: null,
			};

			return {
				...state,
				schoolLookup,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.SchoolLookupByName: {
			const { name } = action.payload;
			const schoolByNameLookup: SchoolByNameLookupState = {
				name,
				pending: true,
				errors: null,
				schools: null,
			};

			return {
				...state,
				schoolByNameLookup,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.SchoolLookupByNameFailure: {
			const { errors } = action.payload;
			const schoolByNameLookup: SchoolByNameLookupState = {
				...state.schoolByNameLookup,
				pending: false,
				errors,
				schools: null,
			};

			return {
				...state,
				schoolByNameLookup,
			};
		}

		case fromProgramCreate.ProgramCreateActionTypes.SchoolLookupByNameSuccess: {
			const { schools } = action.payload;
			const schoolByNameLookup: SchoolByNameLookupState = {
				...state.schoolByNameLookup,
				pending: false,
				schools,
				errors: null,
			};

			return {
				...state,
				schoolByNameLookup,
			};
		}
	}

	return state;
}
