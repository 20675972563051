<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="schoolReturns">School Returns Allowed</div>
		<div class="property-block__value" role="definition" aria-labelledby="schoolReturns">{{ school.returnsAllowed | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="intRtnLocInSession">Location in Session</div>
		<div class="property-block__value" role="definition" aria-labelledby="intRtnLocInSession">{{ school.sessionLocation }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="intRtnLocInSummer">Location in Summer</div>
		<div class="property-block__value" role="definition" aria-labelledby="intRtnLocInSummer">{{ school.summerLocation }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="returnsBlackoutPeriod">Returns Blackout Period</div>
		<div class="property-block__value" role="definition" aria-labelledby="returnsBlackoutPeriod">{{ school.hasBlackoutPeriod | yesNo }}</div>
	</li>
	<ng-container *ngIf="school.hasBlackoutPeriod">
		<li class="property-block__list-item">
			<div class="property-block__label" id="blackoutPeriodStart">Blackout Period</div>
			<div class="property-block__value" role="definition" aria-labelledby="blackoutPeriodStart">{{ blackoutPeriod }}</div>
		</li>
	</ng-container>
</ul>
