import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Event, AddressVerification, Address, EventCreate, EventPromo } from '../../models';
import { SchoolLookup, TeacherLookup } from '../../../schools/models';
import { TeacherLookupCriteria } from '../../../teachers/models';

export enum EventActionTypes {
    LoadEvent = '[Event] Load Event',
    LoadEventSuccess = '[Event] Load Event Success',
    LoadEventFailure = '[Event] Load Event Details Failure',
    UpdateEditorState = '[Event] Update Editor State',
    UpdateEventPanel = '[Event] Update Event Panel',
    UpdateEventPanelSuccess = '[Event] Update Event Panel Success',
    UpdateEventPanelFailure = '[Event] Update Event Panel Failure',
    SchoolLookupById = '[Event] Lookup School By Id',
    SchoolLookupByIdSuccess = '[Event] Lookup School By Id Success',
    SchoolLookupByIdFailure = '[Event] Lookup School By Id Failure',
    SchoolLookupByName = '[Event] Lookup School By Name',
    SchoolLookupByNameSuccess = '[Event] Lookup School By Name Success',
    SchoolLookupByNameFailure = '[Event] Lookup School By Name Failure',
    TeacherLookupById = '[Event] Lookup Teacher By Id',
    TeacherLookupByIdSuccess = '[Event] Lookup Teacher By Id Success',
    TeacherLookupByIdFailure = '[Event] Lookup Teacher By Id Failure',
    TeacherLookupByCriteria = '[Event] Lookup Teacher By Criteria',
    TeacherLookupByCriteriaSuccess = '[Event] Lookup Teacher By Criteria Success',
    TeacherLookupByCriteriaFailure = '[Event] Lookup Teacher By Criteria Failure',
    EventVerifyAddress = '[Event] Event Verify Address',
    EventVerifyAddressSuccess = '[Event] Event Verify Address Success',
    EventVerifyAddressFailure = '[Event] Event Verify Address Failure',
    CreateEvent = '[Event] Create Event',
    CreateEventSuccess = '[Event] Create Event Success',
    CreateEventFailure = '[Event] Create Event Failed',
    LoadApprovedPromos = '[Event] Load Approved Promos',
    LoadApprovedPromosSuccess = '[Event] Load Approved Promos Success',
    LoadApprovedPromosFailure = '[Event] Load Approved Promos Failure',
    CopyEvent = '[Event] Copy Event',
    CopyEventSuccess = '[Event] Copy Event Success',
    CopyEventFailure = '[Event] Copy Event Failed',
    LoadRelationshipManagers = '[Event] Load Relationship Managers',
    LoadRelationshipManagersSuccess = '[Event] Load Relationship Managers Success',
    LoadRelationshipManagersFailure = '[Event] Load Relationship Managers Failure',
    DownloadQrCodes = '[Report] Download Event QR Codes File',
    DownloadQrCodesSuccess = '[Report] Download QR Codes File Success',
    DownloadQrCodesFailure = '[Report] Download QR Codes File Failure',
}

export class LoadEvent implements Action {
    readonly type = EventActionTypes.LoadEvent;
    constructor(public payload: number) { }
}

export class LoadEventSuccess implements Action {
    readonly type = EventActionTypes.LoadEventSuccess;
    constructor(public payload: Event) { }
}

export class LoadEventFailure implements Action {
    readonly type = EventActionTypes.LoadEventFailure;
    constructor(public payload: any) { }
}

export class LoadApprovedPromos implements Action {
    readonly type = EventActionTypes.LoadApprovedPromos;
    constructor(public payload: any) { }
}

export class LoadApprovedPromosSuccess implements Action {
    readonly type = EventActionTypes.LoadApprovedPromosSuccess;
    constructor(public payload: EventPromo[]) { }
}

export class LoadApprovedPromosFailure implements Action {
    readonly type = EventActionTypes.LoadApprovedPromosFailure;
    constructor(public payload: any) { }
}

export class UpdateEditorState implements Action {
    readonly type = EventActionTypes.UpdateEditorState;
    constructor(public payload: { key: string, open: boolean }) { }
}

export class UpdateEventPanel implements Action {
    readonly type = EventActionTypes.UpdateEventPanel;
    constructor(public payload: { panel: string, event: Event }) { }
}

export class UpdateEventPanelSuccess implements Action {
    readonly type = EventActionTypes.UpdateEventPanelSuccess;
    constructor(public payload: { panel: string, event: Event }) { }
}

export class UpdateEventPanelFailure implements Action {
    readonly type = EventActionTypes.UpdateEventPanelFailure;
    constructor(public payload: { panel: string, errors: HttpErrorResponse }) { }
}

export class SchoolLookupById implements Action {
    readonly type = EventActionTypes.SchoolLookupById;
    constructor(public payload: { panel: string, schoolId: number }) { }
}

export class SchoolLookupByIdSuccess implements Action {
    readonly type = EventActionTypes.SchoolLookupByIdSuccess;
    constructor(public payload: { panel: string, school: SchoolLookup }) { }
}

export class SchoolLookupByIdFailure implements Action {
    readonly type = EventActionTypes.SchoolLookupByIdFailure;
    constructor(public payload: { panel: string, errors: any }) { }
}

export class SchoolLookupByName implements Action {
    readonly type = EventActionTypes.SchoolLookupByName;
    constructor(public payload: { panel: string, name: string }) { }
}

export class SchoolLookupByNameSuccess implements Action {
    readonly type = EventActionTypes.SchoolLookupByNameSuccess;
    constructor(public payload: { panel: string, schools: SchoolLookup[] }) { }
}

export class SchoolLookupByNameFailure implements Action {
    readonly type = EventActionTypes.SchoolLookupByNameFailure;
    constructor(public payload: { panel: string, errors: any }) { }
}

export class TeacherLookupById implements Action {
    readonly type = EventActionTypes.TeacherLookupById;
    constructor(public payload: { panel: string, teacherId: number }) { }
}

export class TeacherLookupByIdSuccess implements Action {
    readonly type = EventActionTypes.TeacherLookupByIdSuccess;
    constructor(public payload: { panel: string, teacher: TeacherLookup }) { }
}

export class TeacherLookupByIdFailure implements Action {
    readonly type = EventActionTypes.TeacherLookupByIdFailure;
    constructor(public payload: { panel: string, errors: any }) { }
}

export class TeacherLookupByCriteria implements Action {
    readonly type = EventActionTypes.TeacherLookupByCriteria;
    constructor(public payload: { panel: string, criteria: TeacherLookupCriteria }) { }
}

export class TeacherLookupByNameSuccess implements Action {
    readonly type = EventActionTypes.TeacherLookupByCriteriaSuccess;
    constructor(public payload: { panel: string, teachers: TeacherLookup[] }) { }
}

export class TeacherLookupByCriteriaFailure implements Action {
    readonly type = EventActionTypes.TeacherLookupByCriteriaFailure;
    constructor(public payload: { panel: string, errors: any }) { }
}

export class EventVerifyAddress implements Action {
    readonly type = EventActionTypes.EventVerifyAddress;
    constructor(public payload: { panel: string, address: Address }) { }
}

export class EventVerifyAddressSuccess implements Action {
    readonly type = EventActionTypes.EventVerifyAddressSuccess;
    constructor(public payload: { panel: string, verification: AddressVerification }) { }
}

export class EventVerifyAddressFailure implements Action {
    readonly type = EventActionTypes.EventVerifyAddressFailure;
    constructor(public payload: { panel: string, address: Address, errors: any }) { }
}

export class CreateEvent implements Action {
    readonly type = EventActionTypes.CreateEvent;
    constructor(public payload: { event: EventCreate }) { }
}

export class CreateEventSuccess implements Action {
    readonly type = EventActionTypes.CreateEventSuccess;
    constructor(public payload: { id: number }) { }
}

export class CreateEventFailure implements Action {
    readonly type = EventActionTypes.CreateEventFailure;
    constructor(public payload: { errors: any }) { }
}

export class CopyEvent implements Action {
    readonly type = EventActionTypes.CopyEvent;
    constructor(public payload: { id: number, name: string, date: Date }) { }
}

export class CopyEventSuccess implements Action {
    readonly type = EventActionTypes.CopyEventSuccess;
    constructor(public payload: { event: Event }) { }
}

export class CopyEventFailure implements Action {
    readonly type = EventActionTypes.CopyEventFailure;
	constructor(public payload: { errors: HttpErrorResponse }) { }
}

export class LoadRelationshipManagers implements Action {
    readonly type = EventActionTypes.LoadRelationshipManagers;
    constructor(public payload: { id: number }) { }
}

export class LoadRelationshipManagersSuccess implements Action {
    readonly type = EventActionTypes.LoadRelationshipManagersSuccess;
    constructor(public payload: { id: number, relationshipManagerEmails: string[] }) { }
}

export class LoadRelationshipManagersFailure implements Action {
    readonly type = EventActionTypes.LoadRelationshipManagersFailure;
    constructor(public payload: { errors: any }) { }
}

export class DownloadQrCodes implements Action {
  readonly type = EventActionTypes.DownloadQrCodes;
  constructor(public payload: { id: number }) { }
}

export class DownloadQrCodesSuccess implements Action {
  readonly type = EventActionTypes.DownloadQrCodesSuccess;
  constructor(public payload: { id: number }) { }
}

export class DownloadQrCodesFailure implements Action {
  readonly type = EventActionTypes.DownloadQrCodesFailure;
  constructor(public payload: { id: number, error: any }) { }
}

export type EventActions =
    | LoadEvent
    | LoadEventSuccess
    | LoadEventFailure
    | UpdateEditorState
    | UpdateEventPanel
    | UpdateEventPanelFailure
    | UpdateEventPanelSuccess
    | SchoolLookupById
    | SchoolLookupByIdSuccess
    | SchoolLookupByIdFailure
    | SchoolLookupByName
    | SchoolLookupByNameSuccess
    | SchoolLookupByNameFailure
    | TeacherLookupById
    | TeacherLookupByIdSuccess
    | TeacherLookupByIdFailure
    | TeacherLookupByCriteria
    | TeacherLookupByNameSuccess
    | TeacherLookupByCriteriaFailure
    | EventVerifyAddress
    | EventVerifyAddressSuccess
    | EventVerifyAddressFailure
    | CreateEvent
    | CreateEventSuccess
    | CreateEventFailure
    | LoadApprovedPromos
    | LoadApprovedPromosSuccess
    | LoadApprovedPromosFailure
    | CopyEvent
    | CopyEventSuccess
    | CopyEventFailure
    | LoadRelationshipManagers
    | LoadRelationshipManagersSuccess
    | LoadRelationshipManagersFailure
    | DownloadQrCodes
    | DownloadQrCodesSuccess
    | DownloadQrCodesFailure;
