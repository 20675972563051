import { HttpErrorResponse } from '@angular/common/http';

import { Action } from '@ngrx/store';

import { HistorySnapshot, HistoryDetails } from '../../models/history.model';

export enum HistoryActionTypes {
	LoadSnapshot = '[History] Load Snapshot',
	LoadSnapshotSuccess = '[History] Load Snapshot Success',
	LoadSnapshotFailure = '[History] Load Snapshot Failure',
	LoadHistory = '[History] Load History',
	LoadHistorySuccess = '[History] Load History Success',
	LoadHistoryFailure = '[History] Load History Failure',
}

export class LoadSnapshot implements Action {
	readonly type = HistoryActionTypes.LoadSnapshot;
	constructor(public payload: { contextName: string, contextId: number, scope: string }) { }
}

export class LoadSnapshotSuccess implements Action {
	readonly type = HistoryActionTypes.LoadSnapshotSuccess;
	constructor(public payload: HistorySnapshot) { }
}

export class LoadSnapshotFailure implements Action {
	readonly type = HistoryActionTypes.LoadSnapshotFailure;
	constructor(public payload: any) { }
}

export class LoadHistory implements Action {
	readonly type = HistoryActionTypes.LoadHistory;
	constructor(public payload: { contextName: string, contextId: number, scope: string, fromDate: string, toDate: string }) { }
}

export class LoadHistorySuccess implements Action {
	readonly type = HistoryActionTypes.LoadHistorySuccess;
	constructor(public payload: HistoryDetails) { }
}

export class LoadHistoryFailure implements Action {
	readonly type = HistoryActionTypes.LoadHistoryFailure;
	constructor(public payload: HttpErrorResponse) { }
}

export type HistoryActions =
	| LoadSnapshot
	| LoadSnapshotSuccess
	| LoadSnapshotFailure
	| LoadHistory
	| LoadHistorySuccess
	| LoadHistoryFailure;
