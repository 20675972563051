import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromSchoolModels from '../../schools/models';

import { Event, EventSchool } from '../models';
import * as fromStore from '../store';
import * as fromRoot from '../../../core/store';
import { MessageType } from '../../../core/models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'event-schools-attending-editor',
    templateUrl: './event-schools-attending-editor.component.html',
})
export class EventSchoolsAttendingEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('attendingSchoolDropdown', {static: true})
    schoolDropdown: ElementRef;

    alive = true;
    event$: Observable<Event>;
    event: Event;
    editEvent: Event;
    schoolFoundationDropdown: any;
    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;

    lookupSchoolId: number;
    lookupSchoolName: string;
    schoolLookup$: Observable<fromStore.SchoolLookupState>;
    schoolLookup: fromStore.SchoolLookupState;
    eventSchools: EventSchool[];
    selectedSchool: fromSchoolModels.SchoolLookup = null;

    schoolByNameLookup$: Observable<fromStore.SchoolByNameLookupState>;
    schoolByNameLookup: fromStore.SchoolByNameLookupState;

    editor: FormGroup;

    constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('EventInfoEditorComponent()...');
    }

    ngOnInit() {
        console.log('EventInfoEditorComponent.ngOnInit()...');

        this.editEvent = null;

        this.event$ = this.store.pipe(select(fromStore.getDetailsStateEvent));
        this.event$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(d => {
            this.event = d;
            this.editEvent = d;
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateEventSchoolsAttendingPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.createForm();

        this.schoolLookup$ = this.store.pipe(select(fromStore.getDetailsStateSchoolLookup));
        this.schoolLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(schoolLookup => {
            this.schoolLookup = schoolLookup;
            if (schoolLookup && schoolLookup.school && schoolLookup.schoolId == this.lookupSchoolId) {
                this.addAttendingSchool({
                    id: null,
                    schoolId: schoolLookup.school.id,
                    schoolName: schoolLookup.school.longName
                });
                this.ref.detectChanges();
            }
        });

        this.schoolByNameLookup$ = this.store.pipe(select(fromStore.getDetailsStateSchoolByNameLookup));
        this.schoolByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(schoolByNameLookup => {
            this.schoolByNameLookup = schoolByNameLookup;

            if (schoolByNameLookup && schoolByNameLookup.schools && schoolByNameLookup.name == this.lookupSchoolName) {
                //Trigger the drop down
                if (!this.schoolFoundationDropdown) {
                    this.schoolFoundationDropdown = new Foundation.Dropdown($(this.schoolDropdown.nativeElement).first());
                }

                const native = $(this.schoolDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });
    }

    ngAfterViewInit() {
        //console.log(this.schoolDropdown.nativeElement);
    }

    ngOnDestroy() {
        //console.log('EventInfoEditorComponent.ngOnDestroy()...');
        this.schoolLookup$ = null;
        this.schoolLookup = null;
        this.alive = false;
    }

    createForm() {
        this.editor = new FormGroup({
            schoolId: new FormControl(''),
            schoolName: new FormControl(''),
        });

    }

    lookupSchool() {
        this.lookupSchoolId = Number(this.editor.value.schoolId);

        //Dispatch an action called EventSchoolSearchById
        this.store.dispatch(new fromStore.SchoolLookupById({ panel: fromStore.PanelId.EventSchoolsAttending, schoolId: this.lookupSchoolId }));
    }

    lookupSchoolByName($event: any) {
        this.lookupSchoolName = this.editor.value.schoolName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.schoolDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (this.lookupSchoolName && this.lookupSchoolName.length > 3) {
                //Dispatch an action called EventSchoolSearchById
                this.store.dispatch(new fromStore.SchoolLookupByName({ panel: fromStore.PanelId.EventHost, name: this.lookupSchoolName }));
            }
        }
    }

    addAttendingSchool(school: EventSchool) {
        if (this.editEvent.eventSchools.filter(s => s.schoolId == school.schoolId).length > 0 || this.editEvent.hostingSchoolId == school.schoolId) {
            this.store.dispatch(new fromRoot.DisplayMessage({
                message: 'School is already added',
                messageType: MessageType.alert,
                toast: true
            }));
        } else {
            this.editEvent = {
                ...this.editEvent,
                eventSchools: [
                    ...this.editEvent.eventSchools,
                    school
                ]
            };
            this.editor.controls['schoolId'].setValue('');
        }
    }

    addSchool() {
        if (this.selectedSchool) {
            this.addAttendingSchool({
                id: null,
                schoolId: this.selectedSchool.id,
                schoolName: this.selectedSchool.longName
            });
            this.selectedSchool = null;
            this.editor.controls['schoolName'].setValue('');
            this.ref.detectChanges();
        }
    }

    selectSchool(school: fromSchoolModels.SchoolLookup) {
        if (school) {
            this.selectedSchool = school;
            this.editor.controls['schoolName'].setValue(school.longName);
        }
    }

    remove(school) {
        if (school) {
            const eventSchools = this.editEvent.eventSchools.filter(s => s != school);
            this.editEvent = {
                ...this.editEvent,
                eventSchools: eventSchools
            };
            this.ref.detectChanges();
        }
    }

    resetForm() {

    }

    onSubmit() {
        const event = {
            ...this.event,
            eventSchools: [
                ...this.editEvent.eventSchools
            ]
        };

        this.store.dispatch(new fromStore.UpdateEventPanel({ panel: fromStore.PanelId.EventSchoolsAttending, event }));
    }

}
