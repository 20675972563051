import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { tap, map, filter, take, first } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Event, EventPromo } from '../models';
import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';
import { CopyEvent, LoadRelationshipManagers } from '../store';
import { EventCopyComponent } from '../components';

@Component({
  selector: 'opus-event-page',
  templateUrl: './event-details.component.html'
})
export class EventDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(EventCopyComponent, {static: false}) eventCopy;
  eventDetails$: Observable<Event>;
  panels$: Observable<{ [id: string]: fromStore.PanelState }>;
  copyEventState$: Observable<fromStore.CopyEventState>;
  relationshipManagerState$: Observable<fromStore.RelationshipManagerState>;
  showCopyModal = false;
  copying = false;

  private _event: Event;
  private _subscription: Subscription;

  constructor(private _store: Store<fromStore.EventsState>) {
    console.log('EventDetailsComponent: constructor().');
  }

  get canEdit(): boolean {
    if (!this._event || !this._event.start) {
      return false;
    }

    const start = typeof this._event.start.getTime === 'function' ? this._event.start : new Date(Date.parse(<any>this._event.start));
    const diff = Math.floor(new Date().getTime() - start.getTime());
    const msPerDay = 1000 * 60 * 60 * 24;
    const days = Math.floor(diff / msPerDay);

    //console.log('EventDetailsComponent.canEdit(): ', this._event.start, diff, days);

    return days <= 45;
  }

  get eventId(): number {
    return this._event.id;
  }

  get name(): string {
    return this._event.name;
  }

  get hostingSchoolName(): string {
    return this._event.hostingSchoolName;
  }

  get hostingSchoolId(): number {
    return this._event.hostingSchoolId;
  }

  ngOnInit() {
    console.log('EventDetailsComponent: ngOnInit().');

    //		this.districtInfoState$ = this._store.select(fromStore.getDetailsStateInfoState);
    //		this.relationshipManagerVM$ = this._store.select(fromStore.getDetailsStateRelMgrVM);
    this.eventDetails$ = this._store.pipe(select(fromStore.getDetailsStateEvent));

    this.panels$ = this._store.pipe(select(fromStore.getDetailsStatePanels));

    this._subscription = this.eventDetails$.subscribe(e => (this._event = e)); //	TODO: unsubscribe
    this._store.dispatch(new LoadRelationshipManagers({ id: this.eventId }));

    this.relationshipManagerState$ = this._store.pipe(select(fromStore.getDetailsRelationshipManagerState));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ngAfterViewInit() {
    console.log('EventDetailsComponent.ngAfterViewInit()...');
  }

  onToggleEditor(key: string, open: boolean) {
    this._store.dispatch(new fromStore.UpdateEditorState({ key, open }));
  }

  onToggleEventPromoEditor(key: string, open: boolean) {
    //Check the store for the approvedPromos
    //If it does not exist,
    //Trigger new action to retrieve Approved promos
    //When it's populated then dispatch the action to Update the EditorState
    this.checkStoreForApprovedPromotions().subscribe(x => {
      this._store.dispatch(new fromStore.UpdateEditorState({ key, open }));
    });
  }

  checkStoreForApprovedPromotions(): Observable<EventPromo[]> {
    //	start with the district details state...
    return this._store.select(fromStore.getEventsDetailsState).pipe(
      //	if there aren't approvedPromos dispatch an action to load the approvedPromos
      tap(state => {
        if (state.approvedPromos === null && !state.loading && state.errors === null) {
          this._store.dispatch(new fromStore.LoadApprovedPromos({}));
        }
      }),
      //	wait for it to be in the loaded state and approved promos to be returned or an error.
      filter(state => (state.loaded && state.approvedPromos !== null) || state.errors !== null),
      //	map it to a boolean observable
      map(state => state.approvedPromos),
      //	get the first result & complete
      take(1)
    );
  }

  onCopyEvent() {
    this.showCopyModal = true;
  }

  onCopyModalAction(accept) {
    console.log('onCopyModalAction(): ', accept);

    if (accept) {
      //	get the event name from the modal
      console.log('EventDetailsComponent.onCopyModalAction(): ', this.eventCopy.name, this.eventCopy.date);

      if (this.eventCopy.name != null && this.eventCopy.date != null) {
        this.copyEvent(this.eventCopy.name, this.eventCopy.date);
      }

      return;
    }

    //this.copying = false;
    this.showCopyModal = false;
  }

  copyEvent(name: string, date: Date) {
    this.copying = true;
    //Dispatch an action to copy the event
    this._store.dispatch(new CopyEvent({ id: this.eventId, name, date }));

    //listen on the selector for the result
    this.copyEventState$ = this._store.pipe(select(fromStore.getDetailsStateCopyEventState));

    this.copyEventState$
      .pipe(
        filter(es => {
          return es && (es.loaded || es.errors);
        }),
        map(es => {
          this.copying = false;

          if (es.errors) {
            return;
          }

          this.showCopyModal = false;
          this._store.dispatch(
            new fromRoot.Go({
              path: [`events/${es.event.id}`]
            })
          );
        }),
        first()
      )
      .subscribe();
  }
}
