import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Operation } from 'fast-json-patch';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap} from 'rxjs/operators';

import { District, DistrictSummary, DistrictSearchResultsItem, DistrictLookup, DistrictCreate } from '../../districts/models';

@Injectable()
export class DistrictService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) {
	}

	loadDistrict(districtId: number): Observable<District> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<District>(`${c.config.apiServer}api/districts/details/${districtId}`);
            })
        );
	}

	patchDistrict(id: number, patch: Operation[]): Observable<District> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.patch<District>(`${c.config.apiServer}api/districts/${id}`, patch);
            })
        );
	}

	canDeactivate(id: number, isSupervisory: boolean): Observable<boolean> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<boolean>(`${c.config.apiServer}api/districts/${id}/candeactivate?issupervisory=${isSupervisory}`);
            })
        );
	}

	hasChildren(id: number, isSupervisory: boolean): Observable<boolean> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<boolean>(`${c.config.apiServer}api/districts/${id}/haschildren?issupervisory=${isSupervisory}`);
            })
        );
	}

	exists(id: number): Observable<boolean> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<boolean>(`${c.config.apiServer}api/districts/${id}/exists`);
            })
        );
	}

    /* TODO: GRead 4/18/2018 - I think this is old and not really used???
     * Looks like it's in school-details.effect.ts, but this route doesn't exist
     * as far as I can tell. */
	lookupDistrictById_SUMMARY(id: number): Observable<DistrictSummary> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<DistrictSummary>(`${c.config.apiServer}api/districts/summary/${id}`);
            })
        );
    }

    lookupDistrictById(districtId: number): Observable<DistrictLookup> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<DistrictLookup>(`${c.config.apiServer}api/districts/lookup/${districtId}`);
            })
        );
    }

    lookupDistrictByName(districtName: string, isActive?: boolean, isSupervisory?: boolean): Observable<DistrictLookup[]> {
        return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				let query = '';

				if (isActive !== undefined) {
					query += 'isActive=' + isActive.toString();
				}

				if (isSupervisory !== undefined) {
					query += (query !== '' ? '&' : '') + 'isSupervisory=' + isSupervisory.toString();
				}

				if (query !== '') {
					query = '?' + query;
				}

                return this.http.get<DistrictLookup[]>(`${c.config.apiServer}api/districts/search/lookup/${districtName}${query}`);
            })
        );
    }


    searchDistricts(query: string): Observable<HttpResponse<DistrictSearchResultsItem[]>> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<DistrictSearchResultsItem[]>(`${c.config.apiServer}api/districts?${query}`, { observe: 'response' });
            })
        );
    }

    createDistrict(event: DistrictCreate): Observable<number> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.post<number>(`${c.config.apiServer}api/districts`, event);
            })
        );

    }
}
