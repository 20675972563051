import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { SchoolDetails, RateAgreement, NodeLevel, NodeLevelNames } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-school-rate-editor',
	templateUrl: './school-rate-editor.component.html',
})
export class SchoolRateEditorComponent implements OnInit {
	@Input() panelId: string;

	alive = true;
	editor: FormGroup;
	submitting = false;
	inLookup = false;

	schoolDetails$: Observable<SchoolDetails>;
	school: SchoolDetails;
	panelState$: Observable<fromStore.PanelState>;
	panelState: fromStore.PanelState;
	rateAgreements$: Observable<{ [rateCode: string]: fromStore.RateAgreementState }>;
	lookupRateAgreement: RateAgreement;

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
		console.log('SchoolRateEditorComponent()...');
	}

	ngOnInit() {
		console.log('SchoolRateEditorComponent.ngOnInit()...');

		this.schoolDetails$ = this.store.pipe(select(fromStore.getSchoolDetailsEntity));
		this.schoolDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => {
			console.log('schoolDetails$ [', this.panelId, ']: ', s);

			if (this.rateAgreementChanged(s)) {
				this.school = s;
				this.lookupRateAgreement = this.schoolRateAgreement;
				this.resetForm();
			} else {
				this.school = s;
			}
		});

		this.panelState$ = this.store.pipe(select(this.panelSelector));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				//	clear the form when the editor is closed
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('SchoolRateEditorComponent.panelState$: clearing form...');

					this.resetForm();
				}
			}),
		).subscribe(ps => {
			console.log('panelState$: ', ps);

			this.panelState = ps;
		});

		this.rateAgreements$ = this.store.pipe(select(fromStore.getDetailsStateRateAgreements));
		this.rateAgreements$.pipe(
			takeWhile(() => this.alive),
		).subscribe(states => {
			console.log('rateAgreements$: ', states);

			if (!this.lookupRateAgreement || !this.lookupRateAgreement.code) {
				return;
			}

			const ras = states[this.lookupRateAgreement.code];

			if (!ras || ras.pending || !this.inLookup) {
				return;
			}

			this.inLookup = false;
			this.lookupRateAgreement = ras.rateAgreement || {
				id: null,
				code: this.editor.value.rateCode,
				startDate: '',
				endDate: '',
				description: '',
			};

			if (!ras.rateAgreement) {
				this.editor.controls.rateCode.setErrors({ 'notFound': true });
			} else if (this.submitting) {
				this.update();
			}

			this.submitting = false;
			this.cdr.detectChanges();
		});

		this.createForm();
	}

	ngOnDestroy() {
		console.log('SchoolRateEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	createForm() {
		this.editor = this.fb.group({
			rateCode: [this.schoolRateCode],
			nodeLevel: [this.nodeLevel || ''],
		});

		this.editor.controls.rateCode.valueChanges.pipe(
			takeWhile(() => this.alive),
		).subscribe(code => {
			this.lookupRateAgreement = code == this.schoolRateCode
				? this.schoolRateAgreement
				: {
					id: null,
					code,
					startDate: '',
					endDate: '',
					description: '',
				};
		});
	}

	resetForm() {
		if (!this.editor) {
			return;
		}

		this.editor.reset({
			rateCode: this.schoolRateCode,
			nodeLevel: this.nodeLevel || '',
		});
	}

	rateAgreementChanged(s: SchoolDetails): boolean {
		if (!this.school) {
			return true;
		}

		return this.schoolRateCode != (this.panelId == fromStore.PanelId.CurrentRate
			? (s.currentRateAgreement ? s.currentRateAgreement.code : '')
			: (s.futureRateAgreement ? s.futureRateAgreement.code : ''));
	}

	get schoolRateCode() {
		return this.schoolRateAgreement ? this.schoolRateAgreement.code : '';
	}

	get panelSelector() {
		return this.panelId == fromStore.PanelId.CurrentRate ? fromStore.getDetailsStateCurrentRatePanel : fromStore.getDetailsStateFutureRatePanel;
	}

	get schoolRateAgreement() {
		return this.panelId == fromStore.PanelId.CurrentRate ? this.school.currentRateAgreement : this.school.futureRateAgreement;
	}

	get rateField() {
		return this.panelId == fromStore.PanelId.CurrentRate ? 'currentRateAgreementId' : 'futureRateAgreementId';
	}

	get nodeLevelField() {
		return this.panelId == fromStore.PanelId.CurrentRate ? 'ratePackageNodeLevel' : 'futureRatePackageNodeLevel';
	}

	get nodeLevel() {
		return (this.panelId == fromStore.PanelId.CurrentRate ? this.school.ratePackageNodeLevel : this.school.futureRatePackageNodeLevel);
	}

	get nodeLevels(): number[] {
		const ret = Object.keys(NodeLevel).filter(nl => !isNaN(Number(nl))).map(nl => Number(nl));

		return ret;
	}

	get nodeLevelNames() {
		return NodeLevelNames;
	}

	get canSave(): boolean {
		const canSave = !this.submitting
			&& this.editor.valid
			&& this.editor.dirty
			&& (this.editor.value.rateCode != (this.schoolRateAgreement ? this.schoolRateAgreement.code : '') || this.editor.value.nodeLevel != this.nodeLevel);

		console.log('canSave(): ', canSave);

		return canSave;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	get hasError() {
		console.log('hasError(): ', this.editor.controls.rateCode.invalid);

		return this.editor.controls.rateCode.invalid;
	}

	get rateDescription() {
		return this.lookupRateAgreement ? this.lookupRateAgreement.description : '';
	}

	get rateEffectivity() {
		return this.lookupRateAgreement
			? this.getDateRangeText(this.lookupRateAgreement.startDate, this.lookupRateAgreement.endDate)
			: '';
	}

	update() {
		console.log('update(): ', this.lookupRateAgreement);

		const school = {
			...this.school,
			[this.rateField]: this.lookupRateAgreement.id,
			[this.nodeLevelField]: this.editor.value.nodeLevel == '' ? null : this.editor.value.nodeLevel,
		};

		this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: this.panelId, school }));
	}

	onApplyRateCode() {
		const rateCode = this.editor.value.rateCode.trim();

		console.log('onApplyRateCode(): ', rateCode);

		if (rateCode == this.schoolRateCode || rateCode == '') {
			return;
		}

		this.inLookup = true;
		this.store.dispatch(new fromStore.GetRateAgreement({ panel: this.panelId, rateCode }));
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status, this.editor.value);

		this.submitting = true;
		this.inLookup = true;
		this.store.dispatch(new fromStore.GetRateAgreement({ panel: this.panelId, rateCode: this.editor.value.rateCode.trim() }));
	}

	private getDateRangeText(from: string, to: string): string {
		const fromDate = this.getDate(from);
		const toDate = this.getDate(to);

		if (fromDate === null && toDate === null) {
			return '';
		}

		return `${this.getDateText(fromDate)} - ${this.getDateText(toDate)}`;
	}

	private getDate(s: string): Date {
		const ms: number = Date.parse(s);

		if (isNaN(ms)) {
			return null;
		}

		return new Date(ms);
	}

	private getDateText(d: Date): string {
		return d === null ? 'Unspecified' : `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
	}
}
