import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import * as fromStore from '../store';
import { takeWhile } from 'rxjs/operators';
import * as fromDistrictModels from '../../districts/models';
import { select, Store } from '@ngrx/store';
import { TeacherCreate } from '../models';
import { SchoolDetails } from '../../schools/models';
import { District } from '../../districts/models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-teacher-create',
    templateUrl: './teacher-create.html',
})
export class TeacherCreateComponent {
    @Input() school: SchoolDetails;
    @Input() district: District;

    @Input() visible: Boolean = false;
    @Output() action = new EventEmitter<boolean>();

    @ViewChild('districtDropdown', {static: false})
    districtDropdown: ElementRef;

    districtFoundationDropdown: any;

    lookupDistrictId: number;
    districtLookup$: Observable<fromStore.DistrictLookupState>;
    districtLookup: fromStore.DistrictLookupState;
    lookupDistrictName: string;
    districtByNameLookup$: Observable<fromStore.DistrictByNameLookupState>;
    districtByNameLookup: fromStore.DistrictByNameLookupState;

    editor: FormGroup;
    alive = true;
    submitted = false;
    hasSubmitted = false;
    pendingSubmit = false;
    districtApplied = false;

    constructor(private store: Store<fromStore.TeachersState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('TeacherCreateComponent()...');
    }

    ngOnInit() {
        console.log('TeacherCreateComponent.ngOnInit()...');

        this.createForm();

        this.districtLookup$ = this.store.pipe(select(fromStore.getDetailsStateDistrictLookup));
        this.districtLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(districtLookup => {
            this.districtLookup = districtLookup;

            if (districtLookup && districtLookup.pending && districtLookup.districtId == this.lookupDistrictId) {
                this.editor.controls.districtName.disable();
            }
            else {
                this.editor.controls.districtName.enable();
            }

            if (districtLookup && !districtLookup.pending) {
                if (districtLookup.district) {
                    this.districtApplied = true;
                    this.editor.controls['districtName'].patchValue(districtLookup.district.longName);
                    // Got affirmative from "Apply" for district, resubmit if apply was triggered from a submit
                    if (this.pendingSubmit) {
                        this.onModalAction(true);
                    }
                }
                else {
                    // District wasn't applied and there was some sort of error (show to user)
                    this.districtApplied = false;
                    this.editor.get('districtId').setErrors({ 'invalid': true });
                    this.ref.detectChanges();
                }

                // Set this flag to false once we got a response back from "Apply"
                this.pendingSubmit = false;
            }
        });

        this.districtByNameLookup$ = this.store.pipe(select(fromStore.getDetailsStateDistrictByNameLookup));
        this.districtByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(districtByNameLookup => {
            this.districtByNameLookup = districtByNameLookup;

            if (districtByNameLookup && districtByNameLookup.districts && districtByNameLookup.name == this.lookupDistrictName) {
                //Trigger the drop down
                if (!this.districtFoundationDropdown) {
                    this.districtFoundationDropdown = new Foundation.Dropdown($(this.districtDropdown.nativeElement).first());
                }

                const native = $(this.districtDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        console.log('TeacherInfoEditorComponent.ngOnDestroy()...');
        this.alive = false;

        if (this.districtFoundationDropdown != null) {
            this.districtFoundationDropdown.destroy();
        }
        this.districtFoundationDropdown = null;
    }

    createForm() {
        let districtName = '';
        let districtId = '';
        let schoolType = 'notset';
        let zip = '';

        // If we are creating a new teacher for the current school
        if (this.school != null) {
            schoolType = this.school.type.toString();
            if (this.school.districtId != null) {
                districtId = this.school.districtId.toString();
            }
            if (this.school.districtName != null) {
                districtName = this.school.districtName;
            }
            if (this.school.physicalAddress != null && this.school.physicalAddress.zip != null) {
                zip = this.school.physicalAddress.zip;
            }
        }
        else if (this.district != null) {
            districtId = this.district.id.toString();
            districtName = this.district.longName;
            if (this.district.physicalAddress != null && this.district.physicalAddress.zip != null) {
                zip = this.district.physicalAddress.zip;
            }
        }

        const emailValidator = Validators.pattern(/^.+?@.+?\..+$/);

        this.editor = new FormGroup({
            prefix: new FormControl('notset'),
            firstName: new FormControl(''),
            lastName: new FormControl('', [Validators.required]),
            suffix: new FormControl('notset'),
            districtId: new FormControl(districtId),
            // TODO: Coudln't figure out how to use RequiredValidator with a select.  Just check for non-empty value instead
            schoolType: new FormControl(schoolType, [Validators.pattern(/^(?!.*notset).*$/)]),
            zip: new FormControl(zip, [Validators.required, Validators.pattern(/^\d{5}(?:-\d{4})?$/)]),
            email: new FormControl('', [emailValidator]),
            districtName: new FormControl(districtName)
        });
    }

    lookupDistrict() {
        this.lookupDistrictId = Number(this.editor.value.districtId);

        //Dispatch an action called TeacherSchoolSearchById
        this.store.dispatch(new fromStore.DistrictLookupById({ panel: fromStore.PanelId.Info, districtId: this.lookupDistrictId }));
    }

    lookupDistrictByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupDistrictName = this.editor.value.districtName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.districtDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (this.lookupDistrictName && this.lookupDistrictName.length > 3) {
                //Dispatch an action called TeacherDistrictSearchById
                this.store.dispatch(new fromStore.DistrictLookupByName({ panel: fromStore.PanelId.Info, name: this.lookupDistrictName }));
            }
        }

    }

    selectDistrict(district: fromDistrictModels.DistrictLookup) {
        if (district) {
            this.editor.controls['districtName'].patchValue(district.longName);
            this.editor.controls['districtId'].patchValue(district.id);
            this.districtApplied = true;
        }
    }

    resetForm() {
        const native = $(this.districtDropdown.nativeElement).first();
        if (native.hasClass('is-open'))
            native.foundation('close');

        let districtName = '';
        let districtId = '';
        let schoolType = 'notset';
        let zip = '';

        // If we are creating a new teacher for the current school
        if (this.school != null) {
            schoolType = this.school.type.toString();
            if (this.school.districtId != null) {
                districtId = this.school.districtId.toString();
            }
            if (this.school.districtName != null) {
                districtName = this.school.districtName;
            }
            if (this.school.physicalAddress != null && this.school.physicalAddress.zip != null) {
                zip = this.school.physicalAddress.zip;
            }
        }
        else if (this.district != null) {
            districtId = this.district.id.toString();
            districtName = this.district.longName;
            if (this.district.physicalAddress != null && this.district.physicalAddress.zip != null) {
                zip = this.district.physicalAddress.zip;
            }
        }

        this.editor.reset({
            prefix: 'notset',
            firstName: '',
            lastName: '',
            suffix: 'notset',
            districtId: districtId,
            schoolType: schoolType,
            zip: zip,
            districtName: districtName
        });

        this.submitted = false;
        this.districtApplied = false;
    }

    selectTeacherType() {
        // Enable or disable validation for these form values based on teacher type
        if (this.editor.get('teacherType').value == 1) {
            this.editor.controls.rentalMeetingType.enable();
            this.editor.controls.rentalFulfillmentType.enable();
        }
        else {
            this.editor.controls.rentalMeetingType.disable();
            this.editor.controls.rentalFulfillmentType.disable();
        }
    }

    changeDistrictId() {
        // Reset "invalid" error if district id was changed by user (it'll get validated again when "apply" is pressed)
        this.editor.get('districtId').setErrors(null);
        this.districtApplied = false;
    }

    onModalAction(accept: boolean) {
        console.log(accept);
        //this.visible = false;

        // TODO: Do actual teacher creation here.
        if (accept) {
            this.submitted = true;

            if (!this.editor.valid) {
                // Form is not legit, don't submit!
                // Don't emit teacher to close the modal form
                return;
            }

            // If the districtId hasn't been applied and is not blank
            if (!this.districtApplied && this.editor.value.districtId.trim() != '') {
                // Set flag to perform resubmit
                this.pendingSubmit = true;
                // Automatically apply the district Id before we submit (in case it's invalid)
                this.lookupDistrict();
                // Response from lookupDistrict() will retrigger submit if district Id was applied.
                return;
            }

            const teacher: TeacherCreate = {
                prefix: this.editor.value.prefix == 'notset' ? null : this.editor.value.prefix,
                firstName: this.editor.value.firstName,
                lastName: this.editor.value.lastName,
                suffix: this.editor.value.suffix == 'notset' ? null : this.editor.value.suffix,
                districtId: this.editor.value.districtId,
                schoolType: this.editor.value.schoolType,
                zip: this.editor.value.zip,
                email: this.editor.value.email
            };

            this.store.dispatch(new fromStore.CreateTeacher({ teacher }));
            this.hasSubmitted = true;
        }

        // Reset the form in case we show it again.
        this.resetForm();

        // Tell our container we've finished with our teacher create modal.
        // TODO: We should pass true/false to emit below based on whether teacher was created or not.
        this.action.emit(accept);
    }
}
