<!-- display mode -->
<ul class="property-block__list" >
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventDate">Date</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventDate">{{ event.start | date:'M/d/yyyy' }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventDayOfWeek">Day of Week</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventDayOfWeek">{{ event.start | date:'EEEE' }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventArrivalTime">Arrival Time</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventArrivalTime">{{ event.arrival | date:'shortTime' }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventStartTime">Start Time</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventStartTime">{{ event.start | date:'shortTime' }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="eventEndTime">End Time</div>
        <div class="property-block__value" role="definition" aria-labelledby="eventEndTime">{{ event.end | date:'shortTime' }}</div>
    </li>
    <li class="property-block__list-item" *ngIf="event.eventTypeId == 1">
        <div class="property-block__label" id="expirationDate">Quick Code Expiration Date</div>
        <div class="property-block__value" role="definition" aria-labelledby="expirationDate">{{ event.expirationDate | date:'M/d/yyyy' }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="timezone">Time Zone</div>
        <div class="property-block__value" role="definition" aria-labelledby="timezone">{{ event.eventAtHostSchool ? event.hostingSchoolAddress.ianaTimeZoneId : event.eventAddress.ianaTimeZoneId }}</div>
    </li>
</ul>
<!-- /display mode -->