import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap } from 'rxjs/operators';
import { Operation } from 'fast-json-patch';

import { TeacherLookup } from '../../schools/models';
import { TeacherSearchResultsItem, TeacherDetails, TeacherCreate, TeacherLookupCriteria } from '../../teachers/models';

@Injectable()
export class TeacherService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) { }

    loadTeacher(teacherId: number): Observable<TeacherDetails> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<TeacherDetails>(`${c.config.apiServer}api/teacher/${teacherId}`);
            })
        );
    }

    lookupTeacherById(teacherId: number): Observable<TeacherLookup> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<TeacherLookup>(`${c.config.apiServer}api/teacher/lookup/${teacherId}?active=true`);
            })
        );
    }

    lookupTeacherByCriteria(criteria: TeacherLookupCriteria): Observable<TeacherLookup[]> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                let query = '';

                Object.keys(criteria).forEach(key => {
                    const value = criteria[key];

                    if (value === null || value === undefined || value === '')
                        return;

                    if (query.length > 0)
                        query += '&';
                    query += `${key}=${value}`;
                });
                
                return this.http.get<TeacherLookup[]>(`${c.config.apiServer}api/teacher/search/lookup?${query}`);
            })
        );
    }

    searchTeachers(query: string): Observable<HttpResponse<TeacherSearchResultsItem[]>> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<TeacherSearchResultsItem[]>(`${c.config.apiServer}api/teacher?${query}`, { observe: 'response' });
            })
        );
    }

    patchTeacher(id: number, patch: Operation[]): Observable<TeacherDetails> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.patch<TeacherDetails>(`${c.config.apiServer}api/teacher/${id}`, patch);
            })
        );
    }

    createTeacher(teacher: TeacherCreate): Observable<number> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.post<number>(`${c.config.apiServer}api/teacher`, teacher);
            })
        );
    }
}
