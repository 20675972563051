import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromRoot from '../../../../core/store';
import { Event } from '../../models';


@Component({
    selector: 'event-host',
    templateUrl: './event-host.component.html',
})
export class EventHostComponent {
    @Input() event: Event;

    constructor(private store: Store<fromStore.EventsState>) { }
}
