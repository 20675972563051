import { LayoutComponent } from './layout.component';
import { QuickSearchComponent } from './quick-search.component';
import { AccountDropdownComponent } from './account-dropdown.component';
import { GlobalNavComponent } from './global-nav.component';
import { ModalComponent } from './modal.component';
import { MessageComponent } from './message.component';
import { StepsComponent } from './steps.component';
import { StepComponent } from './step.component';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab.component';
import { UnauthorizedComponent } from './unauthorized.component';
import { NotFoundComponent } from './not-found.component';
import { UnexpectedErrorComponent } from './unexpected-error.component';
import { HomeComponent } from './home.component';

export const components: any[] = [
	LayoutComponent,
	QuickSearchComponent,
	AccountDropdownComponent,
	GlobalNavComponent,
    ModalComponent,
	MessageComponent,
	StepsComponent,
	StepComponent,
	TabsComponent,
    TabComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    UnexpectedErrorComponent,
    HomeComponent
];

export * from './layout.component';
export * from './quick-search.component';
export * from './account-dropdown.component';
export * from './global-nav.component';
export * from './modal.component';
export * from './message.component';
export * from './steps.component';
export * from './step.component';
export * from './tabs.component';
export * from './tab.component';
export * from './unauthorized.component';
export * from './not-found.component';
export * from './unexpected-error.component';
export * from './home.component';
