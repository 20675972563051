import * as fromScp from '../actions';
import * as fromModels from '../../models';

export interface TaxonomyState {
    loading: boolean;
    loaded: boolean;
    rentalCategories: fromModels.RentalCategory[];
    error: any;
}

export const initialState: TaxonomyState = {
    loading: false,
    loaded: false,
    rentalCategories: null,
    error: null
};

export function reducer(state = initialState, action: fromScp.ScpActions): TaxonomyState {
    switch (action.type) {
        case fromScp.ScpActionTypes.GetRentalCategories: {
            return {
                ...state,
                loading: true,
                loaded: false
            };
        }
        case fromScp.ScpActionTypes.GetRentalCategoriesFailure: {
            const { error } = action.payload;
            return {
                ...state,
                loaded: true,
                loading: false,
                error: error
            };
        }
        case fromScp.ScpActionTypes.GetRentalCategoriesSuccess: {
            const { rentalCategories } = action.payload;
            return {
                ...state,
                loaded: true,
                loading: false,
                error: null,
                rentalCategories: rentalCategories
            };
        }

    }

    return state;
}
