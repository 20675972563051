import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap, filter, first } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Address } from '../../scp-common/models';
import { SchoolDetails } from '../models';
import * as fromStore from '../store';

import { states } from '../../scp-common/models/states';

@Component({
	selector: 'opus-school-address-editor',
	templateUrl: './school-address-editor.component.html',
})
export class SchoolAddressEditorComponent implements OnInit, OnDestroy, OnChanges {
	@Input() isPhysical: boolean;
	@Input() school: SchoolDetails;

    states: string[] = states;
	alive = true;
	panelState$: Observable<fromStore.AddressPanelState>;
	panelState: fromStore.AddressPanelState;
	zipLookup: fromStore.ZipLookupState;
	editor: FormGroup;
	showModal = false;
	enteredAddress: Address = null;
	suggestedAddress: Address = null;
	counties$: Observable<string[]>;

	constructor(private store: Store<fromStore.SchoolsState>, private fb: FormBuilder) {
		console.log('SchoolAddressEditorComponent()...');

		this.createForm();
	}

	ngOnInit() {
		console.log('SchoolAddressEditorComponent.ngOnInit(): isPhysical: ', this.isPhysical);

		//	TODO: should this block really be in resetForm()?
		if (this.isPhysical) {
			//	subscribe to state code changes
			this.initCounties();

			//	load up the counties for the current state
			const stateCode = this.address.state;
			if (stateCode != '') {
				this.store.dispatch(new fromStore.SchoolLoadCounties({ panel: this.panelId, stateCode }));
			}
		}

		this.counties$ = this.store.pipe(select(fromStore.getDetailsStateCounties));

		this.panelState$ = this.store.pipe(
			select(this.isPhysical ? fromStore.getDetailsStatePhysAddrPanel : fromStore.getDetailsStateMailAddrPanel)
		);
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('SchoolAddressEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(ps => {
			console.log('panelState$ sub: ', ps);
			this.panelState = ps;
			this.zipLookup = ps.zipLookup;

			const zl = this.zipLookup;
			if (zl && zl.pending) {
				this.editor.controls.addressCity.disable();
				this.editor.controls.addressCounty.disable();
				this.editor.controls.addressState.disable();
				this.editor.controls.addressZipcode.disable();
			} else {
				this.editor.controls.addressCity.enable();
				this.editor.controls.addressCounty.enable();
				this.editor.controls.addressState.enable();
				this.editor.controls.addressZipcode.enable();
			}

			if (zl && zl.cityState != null) {
				this.editor.controls['addressCity'].patchValue(zl.cityState.city);
				this.editor.controls['addressState'].patchValue(zl.cityState.state);

				if (this.editor.controls.addressCity.value != this.address.city) {
					this.editor.controls.addressCity.markAsDirty();
				}

				if (this.editor.controls.addressState.value != this.address.state) {
					this.editor.controls.addressState.markAsDirty();
				}
			}
		});
	}

	ngOnDestroy() {
		console.log('SchoolAddressEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	ngOnChanges() {
		this.resetForm();
	}

	createForm() {
		//	NOTE: FormBuilder.group will ignore the updateOn parameter when constructing the FormGroup
		this.editor = this.fb.group({
			addressLine1: ['', Validators.required],
			addressLine2: [''],
			addressCity: ['', Validators.required],
			addressState: ['', Validators.required],
			addressZipcode: ['', Validators.pattern(/^\d{5}(?:-\d{4})?$/)],
			addressCounty: [''],
			updateMailing: [false],
			sameAsPhysical: [false],
		}/* NOTE: originally tried to do address verification as an async validator, but angular was ignoring the supplied updateOn='submit' even when using FormGroup instead of FormBuilder */);
	}

	initCounties() {
		console.log('initCounties()...');

		this.editor.get('addressState').valueChanges.pipe(
			takeWhile(() => this.alive),
		).subscribe(stateCode => {
			console.log(`addressState.valueChanges: '${this.editor.value.addressState}' -> '${stateCode}'`);

			if (stateCode != '' && stateCode != this.editor.value.addressState) {
				//	if the state is changed, we need to clear out the county so that the county dropdown will show the select option
				//	if the state is changed back to the original state, however, set the county back to that of the original model
				this.editor.controls['addressCounty'].patchValue(stateCode == this.address.state ? this.address.county : '');

				this.store.dispatch(new fromStore.SchoolLoadCounties({ panel: this.panelId, stateCode }));
			}
		});
	}

	resetForm() {
		console.log('resetForm()...');

		const address: Address = this.address;
		this.editor.reset({
			addressLine1: address.line1,
			addressLine2: address.line2,
			addressCity: address.city,
			addressState: address.state,
			addressZipcode: address.zip,
			addressCounty: address.county || '',
			updateMailing: false,
			sameAsPhysical: false,
		});
	}

	get isMailing(): boolean {
		return !this.isPhysical;
	}

	get panelId(): string {
		return this.isPhysical ? fromStore.PanelId.PhysAddr : fromStore.PanelId.MailAddr;
	}

	get address(): Address {
		return this.isPhysical ? this.school.physicalAddress : this.school.mailingAddress;
	}

	get canSave(): boolean {
		//	this is a bit hacky, but easiest way i could see to allow the mailing address to be saved when the existing data is invalid, but same as physical has been checked
		return this.editor.dirty && (this.editor.valid || this.isMailing && this.editor.value.sameAsPhysical);
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	get inZipLookup(): boolean {
		return this.zipLookup != null && this.zipLookup.pending;
	}

	get hasZipLookupError(): boolean {
		const hasError = (this.editor.controls.addressZipcode.enabled && !this.editor.controls.addressZipcode.valid) || (this.zipLookup != null && this.zipLookup.errors != null);

		return hasError;
	}

	get disableZipLookup(): boolean {
		const zipCtrl = this.editor.controls.addressZipcode;

		return zipCtrl.disabled || !zipCtrl.valid || this.inZipLookup;
	}

	onApplyZip(elem: any) {
		const zip = this.editor.value.addressZipcode;

		console.log('SchoolAddressEditorComponent.onApplyZipcode(): ', zip);
		this.store.dispatch(new fromStore.SchoolAddressLookupCityState({ panel: this.panelId, zip }));
	}

	onModalAction(accept: boolean) {
		console.log(accept);

		this.showModal = false;

		if (accept) {
			this.update();
		}
    }

    onModalTertiaryAction(accept: boolean) {
        console.log(accept);

        this.showModal = false;

        if (accept) {
            // Clear out the suggested address so that update() will use the entered one
            this.suggestedAddress = null;
            this.update();
        }
    }

	update() {
		console.log('updating...');

		//	suggested address will normally be the address to use unless qas was unable to find a match.  in this case, accepting the modal will use the entered address as is.
		const address = this.suggestedAddress || this.enteredAddress;

		//	the address(es) we update will depend upon which we're editing & the state of some form controls.
		//	we need to replace the address we're editing
		//	if editing the physical address & update mailing is checked, we need to update it as well
		//	if editing the mailing address and same as physical is checked, we need to set the mailing address to that of the physical address
		const physicalAddress = {
			...this.isPhysical ? address : this.school.physicalAddress,
		};

		const mailingAddress = {
			...this.isMailing ? (this.editor.value.sameAsPhysical ? this.school.physicalAddress : address) : (this.editor.value.updateMailing ? address : this.school.mailingAddress),
		};

		const school = {
			...this.school,
			physicalAddress,
			mailingAddress,
		};

		console.log('update():', school);
		this.store.dispatch(new fromStore.UpdateSchoolPanel({ panel: this.panelId, school }));
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status);

		//	Apparently we're going to have to explicitly invoke the address validator since angular refuses to honor updateOn: 'submit' at the form gropup level
		//	dispatch action to verify address
		const address: Address = {
			line1: this.editor.value.addressLine1,
			line2: this.editor.value.addressLine2,
			city: this.editor.value.addressCity,
			state: this.editor.value.addressState,
			zip: this.editor.value.addressZipcode,
			county: this.editor.value.addressCounty,
		};

		this.enteredAddress = address;

		if (this.isMailing && this.editor.value.sameAsPhysical) {
			this.update();

			return;
		}

		this.store.dispatch(new fromStore.SchoolVerifyAddress({ panel: this.panelId, address }));

		//	observe results in store
		this.panelState$.pipe(
			tap(ps => console.log('onSubmit: ', ps)),
			filter(ps => !(ps as fromStore.AddressPanelState).verifying),
			first(),
			tap(ps => console.log('onSubmit: ', ps)),
		).subscribe(ps => {
			console.log('subscribe: ', ps);
			const verifiedAddress = (ps as fromStore.AddressPanelState).verifiedAddress;

            // If verified address didn't come back, make sure suggested stays null (so we don't use it later)
            this.suggestedAddress = verifiedAddress;
            if (this.suggestedAddress != null) {
                this.suggestedAddress = {
                    ...verifiedAddress,
                    county: this.editor.value.addressCounty,
                };
            }

			//	if verified address is not identical to entered, or we got a picklist instead, show modal
			if (verifiedAddress !== null && this.compareAddresses(address, verifiedAddress)) {
				this.update();

				return;
			}

			this.showModal = true;
		});
	}

	private compareAddresses(first: Address, second: Address): boolean {
		return first.line1 == second.line1
			&& first.line2 == second.line2
			&& first.city == second.city
			&& first.state == second.state
			&& first.zip == second.zip;
	}
}
