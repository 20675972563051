import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromStore from '../store';
import * as fromSchoolModels from '../../schools/models';
import * as validators from '../../../core/validators';

import { SchoolDetails } from '../../schools/models';
import { ValidateCurrentDateOrLater } from '../event.validators';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { EventCreate } from '../models';
import { TeacherLookupCriteria } from '../../teachers/models';

declare var $: any;
declare var Foundation: any;

@Component({
	selector: 'opus-event-create',
	templateUrl: './event-create.html',
})
export class EventCreateComponent {
    @Input() school: SchoolDetails;
    @Input() visible: Boolean = false;
    @Output() action = new EventEmitter<boolean>();

    @ViewChild('schoolDropdown', {static: false})
    schoolDropdown: ElementRef;

    @ViewChild('teacherDropdown', {static: false})
    teacherDropdown: ElementRef;

    @ViewChild('SubmitButton', {static: false})
    SubmitButton;

    schoolFoundationDropdown: any;
    teacherFoundationDropdown: any;

	details$: Observable<fromStore.DetailsState>;

	lookupSchoolId: number;
    schoolLookup$: Observable<fromStore.SchoolLookupState>;
    schoolLookup: fromStore.SchoolLookupState;
    lookupSchoolName: string;
    schoolByNameLookup$: Observable<fromStore.SchoolByNameLookupState>;
    schoolByNameLookup: fromStore.SchoolByNameLookupState;

    teacherLookup$: Observable<fromStore.TeacherLookupState>;
    teacherLookup: fromStore.TeacherLookupState;
    teacherByCriteriaLookup$: Observable<fromStore.TeacherByCriteriaLookupState>;
    teacherByCriteriaLookup: fromStore.TeacherByCriteriaLookupState;

    editor: FormGroup;
	alive = true;
    submitting = false;
    submitted = false;
    hasDoneSchoolLookup = false;
    hasDoneTeacherLookup = false;

    rentalMeetingTypes: any[] = [
        {
            id: 1,
            name: 'Schedule or Assembly'
        },
        {
            id: 2,
            name: 'Open or Random'
        }
    ];
    rentalFulfillmentTypes: any[] = [
        { id: 1, name: 'Filled - Pick up at Event' },
        { id: 2, name: 'Unfilled - Filled by Hub' },
        { id: 3, name: 'Unfilled - Filled by Rep' },
    ];

    bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue' };
    maxDate = new Date('9999-12-31');

    constructor(private store: Store<fromStore.EventsState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('EventCreateComponent()...');
    }

    ngOnInit() {
        console.log('EventCreateComponent.ngOnInit()...');

        this.createForm();

        this.schoolLookup$ = this.store.pipe(select(fromStore.getDetailsStateSchoolLookup));
        this.schoolLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(schoolLookup => {
            this.schoolLookup = schoolLookup;

            if (schoolLookup && schoolLookup.pending && schoolLookup.schoolId == this.lookupSchoolId) {
                this.editor.controls.hostSchoolName.disable();
            }
            else {
                this.editor.controls.hostSchoolName.enable();
            }

            if (schoolLookup && schoolLookup.school) {
                this.editor.controls['hostSchoolName'].patchValue(schoolLookup.school.longName);
            }
        });

        this.schoolByNameLookup$ = this.store.pipe(select(fromStore.getDetailsStateSchoolByNameLookup));
        this.schoolByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(schoolByNameLookup => {
            this.schoolByNameLookup = schoolByNameLookup;

            if (this.hasDoneSchoolLookup && schoolByNameLookup && schoolByNameLookup.schools && schoolByNameLookup.name == this.lookupSchoolName) {
                //Trigger the drop down
                if (!this.schoolFoundationDropdown) {
                    this.schoolFoundationDropdown = new Foundation.Dropdown($(this.schoolDropdown.nativeElement).first());
                }

                const native = $(this.schoolDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });

        this.teacherLookup$ = this.store.pipe(select(fromStore.getDetailsStateTeacherLookup));
        this.teacherLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(teacherLookup => {
            this.teacherLookup = teacherLookup;

            if (teacherLookup && teacherLookup.pending) {
                this.editor.controls.hostTeacherName.disable();
            }
            else {
                this.editor.controls.hostTeacherName.enable();
            }

            if (teacherLookup && teacherLookup.teacher) {
                this.editor.controls['hostTeacherName'].patchValue(teacherLookup.teacher.name);
            }
        });

        this.teacherByCriteriaLookup$ = this.store.pipe(select(fromStore.getDetailsStateTeacherByCriteriaLookup));
        this.teacherByCriteriaLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(teacherByCriteriaLookup => {
            this.teacherByCriteriaLookup = teacherByCriteriaLookup;

            if (this.hasDoneTeacherLookup && teacherByCriteriaLookup && teacherByCriteriaLookup.teachers) {
                //Trigger the drop down
                if (!this.teacherFoundationDropdown) {
                    this.teacherFoundationDropdown = new Foundation.Dropdown($(this.teacherDropdown.nativeElement).first());
                }

                const native = $(this.teacherDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
			});

		this.details$ = this.store.pipe(select(fromStore.getEventsDetailsState));
		this.details$.pipe(
			takeWhile(() => this.alive),
		).subscribe(ds => {
			console.log('detail$: ', ds);

			if (ds.creating) {
				this.submitting = true;
			}

			if (this.submitting && !ds.creating) {
				this.submitting = false;
				this.ref.detectChanges();

				if (ds.createdId !== null) {
					this.resetForm();
					this.action.emit(true);
					this.store.dispatch(new fromRoot.Go({ path: [`/events/${ds.createdId}`] }));

					return;
				}
			}
		});
    }

    ngOnDestroy() {
        console.log('EventInfoEditorComponent.ngOnDestroy()...');
        this.alive = false;

        if (this.schoolFoundationDropdown != null) {
            this.schoolFoundationDropdown.destroy();
        }
        if (this.teacherFoundationDropdown != null) {
            this.teacherFoundationDropdown.destroy();
        }

        this.teacherFoundationDropdown = null;
        this.schoolFoundationDropdown = null;

        this.hasDoneSchoolLookup = false;
        this.hasDoneTeacherLookup = false;
    }

    createForm() {
        let schoolName = '';
        let schoolId = '';

        // If we are creating a new event for the current school
        if (this.school != null) {
            // Make sure that school is populated by default
            schoolName = this.school.longName;
            schoolId = this.school.id.toString();
        }

        this.editor = new FormGroup({
            eventName: new FormControl('', [Validators.required]),
            eventType: new FormControl('', [Validators.required]),
            eventDate: new FormControl(new Date(), [Validators.required, ValidateCurrentDateOrLater]),
            eventQuality: new FormControl('1', [Validators.required]),
            rentalMeetingType: new FormControl('', [Validators.required]),
            rentalFulfillmentType: new FormControl('', [Validators.required]),
            hostSchoolId: new FormControl(schoolId, [Validators.required, validators.NumericValidators.min(1)]),
            hostSchoolName: new FormControl(schoolName),
            hostTeacherId: new FormControl('', [Validators.required, validators.NumericValidators.min(1)]),
            hostTeacherName: new FormControl(''),
        });
    }

    lookupSchool() {
        this.lookupSchoolId = Number(this.editor.value.hostSchoolId);

        //Dispatch an action called EventSchoolSearchById
        this.store.dispatch(new fromStore.SchoolLookupById({ panel: fromStore.PanelId.EventHost, schoolId: this.lookupSchoolId }));
    }

    lookupSchoolByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupSchoolName = this.editor.value.hostSchoolName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.schoolDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (this.lookupSchoolName && this.lookupSchoolName.length > 3) {
                //Dispatch an action called EventSchoolSearchById
                this.store.dispatch(new fromStore.SchoolLookupByName({ panel: fromStore.PanelId.EventHost, name: this.lookupSchoolName }));

                this.hasDoneSchoolLookup = true;
            }
        }

    }

    selectSchool(school: fromSchoolModels.SchoolLookup) {
        if (school) {
            this.editor.controls['hostSchoolName'].patchValue(school.longName);
            this.editor.controls['hostSchoolId'].patchValue(school.id);
        }
    }

    lookupTeacher() {
        const hostTeacherId = Number(this.editor.value.hostTeacherId);

        //Dispatch an action called EventTeacherSearchById
        this.store.dispatch(new fromStore.TeacherLookupById({ panel: fromStore.PanelId.EventHost, teacherId: hostTeacherId }));
    }

    lookupTeacherByCriteria($event: any) {
        const criteria : TeacherLookupCriteria = {
            name: this.editor.value.hostTeacherName,
        };

        if (this.school) {
            criteria.districtId = this.school.districtId;
            if (this.school.physicalAddress && this.school.physicalAddress.zip)
                criteria.zip = this.school.physicalAddress.zip.substring(0, 5);
        }

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.teacherDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (criteria.name && criteria.name.length > 3) {
                //Dispatch an action called EventTeacherSearchById
                this.store.dispatch(new fromStore.TeacherLookupByCriteria({ panel: fromStore.PanelId.EventHost, criteria }));

                this.hasDoneTeacherLookup = true;
            }
        }
    }

    selectTeacher(teacher: fromSchoolModels.TeacherLookup) {
        if (teacher) {
            this.editor.controls['hostTeacherName'].patchValue(teacher.name);
            this.editor.controls['hostTeacherId'].patchValue(teacher.id);
        }
    }

    resetForm() {
        if (this.schoolFoundationDropdown != null) {
            this.schoolFoundationDropdown.destroy();
        }
        if (this.teacherFoundationDropdown != null) {
            this.teacherFoundationDropdown.destroy();
        }

        this.teacherFoundationDropdown = null;
        this.schoolFoundationDropdown = null;

        let schoolName = '';
        let schoolId = '';

        // If we are creating a new event for the current school
        if (this.school != null) {
            // Make sure that school is populated by default
            schoolName = this.school.longName;
            schoolId = this.school.id.toString();
        }

        this.editor.reset({
            eventDate: new Date(),
            eventName: '',
            eventType: '',
            eventQuality: '1',
            rentalMeetingType: '',
            rentalFulfillmentType: '',
            hostSchoolId: schoolId,
            hostSchoolName: schoolName,
            hostTeacherId: '',
            hostTeacherName: '',
        });

        this.submitting = false;
        this.submitted = false;
    }

    selectEventType() {
        // Enable or disable validation for these form values based on event type
        if (this.editor.get('eventType').value == 1) {
            this.editor.controls.eventQuality.enable();
            this.editor.controls.rentalMeetingType.enable();
            this.editor.controls.rentalFulfillmentType.enable();
        }
        else {
            this.editor.controls.eventQuality.disable();
            this.editor.controls.rentalMeetingType.disable();
            this.editor.controls.rentalFulfillmentType.disable();
        }
    }

	onModalAction(accept) {
		console.log(accept);

		if (!accept) {
			this.resetForm();
			this.action.emit(accept);

			return;
		}

        this.submitted = true;
		if (!this.editor.valid) {
			// Form is not legit, don't submit!
			// Don't emit event to close the modal form
			return;
		}

		const event: EventCreate = {
			name: this.editor.value.eventName,
            eventTypeId: this.editor.value.eventType,
            eventDate: this.editor.value.eventDate,
            eventQuality: this.editor.value.eventQuality,
			rentalMeetingTypeId: this.editor.value.rentalMeetingType,
			rentalFulfillmentTypeId: this.editor.value.rentalFulfillmentType,
			hostSchoolId: this.editor.value.hostSchoolId,
			hostTeacherId: this.editor.value.hostTeacherId,
		};

		this.store.dispatch(new fromStore.CreateEvent({ event }));
	}
}
