<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="pAddressLine1">Address Line 1</div>
		<div class="property-block__value" role="definition" aria-labelledby="pAddressLine1">{{ address.line1 }}</div>
	</li>
	<li class="property-block__list-item" *ngIf="address.line2">
		<div class="property-block__label" id="pAddressLine2">Address Line 2</div>
		<div class="property-block__value" role="definition" aria-labelledby="pAddressLine2">{{ address.line2 }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="pCityStateZip">City, State Zip</div>
		<div class="property-block__value" role="definition" aria-labelledby="pCityStateZip">{{ address.city }}, {{ address.state }} {{ address.zip }}</div>
	</li>
	<li class="property-block__list-item" *ngIf="this.isPhysical">
		<div class="property-block__label" id="pCounty">County</div>
		<div class="property-block__value" role="definition" aria-labelledby="pCounty">{{ address.county }}</div>
	</li>
</ul>
