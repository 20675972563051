import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap, filter, first } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../core/store';
import * as fromScpStore from '../../scp-common/store';
import * as fromStore from '../store';
import { DistrictCreate } from '../models';
import { Address } from '../../events/models/index';
import { states } from '../../scp-common/models/states';
import * as fromScpModels from '../../scp-common/models';
import { MessageType } from '../../../core/models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-district-create',
    templateUrl: './district-create.html',
})
export class DistrictCreateComponent {
    @Input() visible: Boolean = false;
    @Output() action = new EventEmitter<boolean>();

    @ViewChild('managerDropdown', {static: false})
    managerDropdown: ElementRef;
    managerFoundationDropdown: any;

    states: string[] = states;
    editor: FormGroup;
    alive = true;
    submitted = false;
    hasSubmitted = false;
    showVerifyAddress = false;
    districtFormNotAddressForm = true;

    counties$: Observable<string[]>;

    panelState$: Observable<fromStore.AddressPanelState>;
    panelState: fromStore.AddressPanelState;
    zipLookup: fromStore.ZipLookupState;
    zipLookupMailing: fromStore.ZipLookupState;

    lookupManagerCode: number;
    managerLookup$: Observable<fromScpStore.LocationsState>;
    managerLookup: fromScpStore.LocationsState;
    lookupManagerName: string;
    managerByNameLookup$: Observable<fromScpStore.ManagerByNameLookupState>;
    managerByNameLookup: fromScpStore.ManagerByNameLookupState;

    hasDoneManagerLookup = false;
    hasDoneManagerLookupByName = false;
    isSameAsPhysical = false;

    title = 'Create New District';
    primaryLabel = 'Create District';
    secondaryLabel = 'Cancel';

    physicalAddress: Address;
    mailingAddress: Address;
    validatingPhysicalNotMailing: boolean;

    enteredAddress: Address = {
        line1: '123 Some Rd.',
        line2: '',
        city: 'Quakertown',
        state: 'PA',
        zip: '18950',
        county: 'Bucks'
    };

    suggestedAddress: Address = {
        line1: '123 Some St.',
        line2: '',
        city: 'Quakertown',
        state: 'PA',
        zip: '18951',
        county: 'Bucks'
    };

    details$: Observable<fromStore.DetailsState>;

    constructor(private store: Store<fromStore.DistrictsState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('DistrictCreateComponent()...');
    }

    ngOnInit() {
        console.log('DistrictCreateComponent.ngOnInit()...');

        this.createForm();

        this.managerByNameLookup$ = this.store.pipe(select(fromScpStore.getLocationsStateLookup));
        this.managerByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(managerByNameLookup => {
            this.managerByNameLookup = managerByNameLookup;

            if (this.hasDoneManagerLookupByName && managerByNameLookup && managerByNameLookup.managers && managerByNameLookup.name == this.lookupManagerName) {
                this.hasDoneManagerLookupByName = false;

                //Trigger the drop down
                if (!this.managerFoundationDropdown) {
                    this.managerFoundationDropdown = new Foundation.Dropdown($(this.managerDropdown.nativeElement).first());
                }

                const native = $(this.managerDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });

        this.managerLookup$ = this.store.pipe(select(fromScpStore.getLocationsState));
        this.managerLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(managerLookup => {
            this.managerLookup = managerLookup;

            if (this.hasDoneManagerLookup && managerLookup
                && managerLookup.locationManagers[this.lookupManagerCode]) {
                if (managerLookup.locationManagers[this.lookupManagerCode].pending) {
                    return;
                }

                this.hasDoneManagerLookup = false;

                if (managerLookup.locationManagers[this.lookupManagerCode].manager) {
                    this.editor.controls.relationshipManagerName.patchValue(managerLookup.locationManagers[this.lookupManagerCode].manager.manager);

                    return;
                }

                if (managerLookup.locationManagers[this.lookupManagerCode].error) {
                    this.editor.controls.relationshipManagerCode.setErrors({ 'server': managerLookup.locationManagers[this.lookupManagerCode].error });
                    this.store.dispatch(new fromRoot.DisplayMessage({
                        message: managerLookup.locationManagers[this.lookupManagerCode].error,
                        messageType: MessageType.alert,
                        toast: false,
                    }));
                }
            }
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStatePhysAddrPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
        ).subscribe(ps => {
            console.log('panelState$ sub: ', ps);
            this.panelState = ps;
            this.zipLookup = ps.zipLookup;

            const zl = this.zipLookup;
            if (zl && zl.pending) {
                this.editor.controls.physicalCity.disable();
                this.editor.controls.physicalCounty.disable();
                this.editor.controls.physicalState.disable();
                this.editor.controls.physicalZip.disable();
            } else {
                this.editor.controls.physicalCity.enable();
                this.editor.controls.physicalCounty.enable();
                this.editor.controls.physicalState.enable();
                this.editor.controls.physicalZip.enable();
            }

            if (zl && zl.cityState != null) {
                this.editor.controls['physicalCity'].patchValue(zl.cityState.city);
                this.editor.controls['physicalState'].patchValue(zl.cityState.state);
            }
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateMailAddrPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
        ).subscribe(ps => {
            console.log('panelState$ sub: ', ps);
            this.panelState = ps;
            this.zipLookupMailing = ps.zipLookup;

            const zl = this.zipLookupMailing;
            if (zl && zl.pending) {
                this.editor.controls.mailingCity.disable();
                this.editor.controls.mailingCounty.disable();
                this.editor.controls.mailingState.disable();
                this.editor.controls.mailingZip.disable();
            } else {
                this.editor.controls.mailingCity.enable();
                this.editor.controls.mailingCounty.enable();
                this.editor.controls.mailingState.enable();
                this.editor.controls.mailingZip.enable();
            }

            if (zl && zl.cityState != null) {
                this.editor.controls['mailingCity'].patchValue(zl.cityState.city);
                this.editor.controls['mailingState'].patchValue(zl.cityState.state);
            }
        });

        this.counties$ = this.store.pipe(select(fromStore.getDetailsStateCounties));
        this.initCounties();

        this.details$ = this.store.pipe(select(fromStore.getDistrictDetailsState));
        this.details$.pipe(
            takeWhile(() => this.alive),
        ).subscribe(s => {
            console.log('detail$: ', s);

            if (s.creating) {
                this.hasSubmitted = true;
            }

            if (this.hasSubmitted && !s.creating) {
                this.hasSubmitted = false;
                this.ref.detectChanges();

                if (s.createdId !== null) {
                    this.resetForm();
                    this.action.emit(true);
                    this.store.dispatch(new fromRoot.Go({ path: [`/districts/${s.createdId}`] }));
                }
            }
        });
    }

    ngOnDestroy() {
        console.log('DistrictCreateComponent.ngOnDestroy()...');
        this.alive = false;
    }

    initCounties() {
        console.log('initCounties()...');

        this.editor.get('physicalState').valueChanges.pipe(
            takeWhile(() => this.alive),
        ).subscribe(stateCode => {
            console.log(`physicalState.valueChanges: '${this.editor.value.physicalState}' -> '${stateCode}'`);

            if (stateCode != '' && stateCode != this.editor.value.physicalState) {
                //	if the state is changed, we need to clear out the county so that the county dropdown will show the select option
                //	if the state is changed back to the original state, however, set the county back to that of the original model
                this.editor.controls['physicalCounty'].patchValue(stateCode == this.editor.value.physicalState ? this.editor.value.physicalCounty : '');

                this.store.dispatch(new fromStore.DistrictLoadCounties({ panel: fromStore.PanelId.PhysAddr, stateCode }));
            }
        });
    }
    createForm() {
        this.editor = new FormGroup({
            districtName: new FormControl('', [Validators.required]),
            relationshipManagerCode: new FormControl('', [Validators.required]),
            relationshipManagerName: new FormControl(''),
            physicalLine1: new FormControl('', [Validators.required]),
            physicalLine2: new FormControl(''),
            physicalCity: new FormControl(''),
            physicalState: new FormControl(''),
            physicalZip: new FormControl('', [Validators.required, Validators.pattern(/^\d{5}(?:-\d{4})?$/)]),
            physicalCounty: new FormControl(''),
            mailingLine1: new FormControl('', [Validators.required]),
            mailingLine2: new FormControl(''),
            mailingCity: new FormControl(''),
            mailingState: new FormControl(''),
            mailingZip: new FormControl('', [Validators.required, Validators.pattern(/^\d{5}(?:-\d{4})?$/)]),
            mailingCounty: new FormControl(''),
        });
    }

    resetForm() {
        if (this.managerFoundationDropdown != null) {
            this.managerFoundationDropdown.destroy();
        }
        this.managerFoundationDropdown = null;

        this.editor.reset({
            districtName: '',
            relationshipManagerCode: '',
            relationshipManagerName: '',
            physicalLine1: '',
            physicalLine2: '',
            physicalCity: '',
            physicalState: '',
            physicalZip: '',
            physicalCounty: '',
            mailingLine1: '',
            mailingLine2: '',
            mailingCity: '',
            mailingState: '',
            mailingZip: '',
            mailingCounty: '',
        });

        this.submitted = false;
    }

    get inZipLookup(): boolean {
        return this.zipLookup != null && this.zipLookup.pending;
    }

    get hasZipLookupError(): boolean {
        const hasError = (this.editor.controls.physicalZip.enabled && !this.editor.controls.physicalZip.valid) || (this.zipLookup != null && this.zipLookup.errors != null);

        return hasError;
    }

    get disableZipLookup(): boolean {
        const zipCtrl = this.editor.controls.physicalZip;

        return zipCtrl.disabled || !zipCtrl.valid || this.inZipLookup;
    }

    onApplyZip(elem: any) {
        const zip = this.editor.value.physicalZip;

        console.log('DistrictAddressEditorComponent.onApplyZipcode(): ', zip);
        this.store.dispatch(new fromStore.DistrictAddressLookupCityState({ panel: fromStore.PanelId.PhysAddr, zip }));
    }

    get inZipLookupMailing(): boolean {
        return this.zipLookupMailing != null && this.zipLookupMailing.pending;
    }

    get hasZipLookupErrorMailing(): boolean {
        const hasError = (this.editor.controls.physicalZip.enabled && !this.editor.controls.physicalZip.valid) || (this.zipLookupMailing != null && this.zipLookupMailing.errors != null);

        return hasError;
    }

    get disableZipLookupMailing(): boolean {
        const zipCtrl = this.editor.controls.physicalZip;

        return zipCtrl.disabled || !zipCtrl.valid || this.inZipLookupMailing || this.isSameAsPhysical;
    }

    onApplyZipMailing(elem: any) {
        const zip = this.editor.value.mailingZip;

        console.log('DistrictAddressEditorComponent.onApplyZipcode(): ', zip);
        this.store.dispatch(new fromStore.DistrictAddressLookupCityState({ panel: fromStore.PanelId.MailAddr, zip }));
    }

    sameAsPhysicalChecked($event: any) {
        this.isSameAsPhysical = $event.currentTarget.checked;
        if (this.isSameAsPhysical) {
            this.editor.controls.mailingLine1.disable();
            this.editor.controls.mailingLine2.disable();
            this.editor.controls.mailingCity.disable();
            this.editor.controls.mailingState.disable();
            this.editor.controls.mailingZip.disable();
            this.editor.controls.mailingCounty.disable();

            this.editor.controls.mailingLine1.patchValue(this.editor.value.physicalLine1);
            this.editor.controls.mailingLine2.patchValue(this.editor.value.physicalLine2);
            this.editor.controls.mailingCity.patchValue(this.editor.value.physicalCity);
            this.editor.controls.mailingState.patchValue(this.editor.value.physicalState);
            this.editor.controls.mailingZip.patchValue(this.editor.value.physicalZip);
            this.editor.controls.mailingCounty.patchValue(this.editor.value.physicalCounty);
        }
        else {
            this.editor.controls.mailingLine1.enable();
            this.editor.controls.mailingLine2.enable();
            this.editor.controls.mailingCity.enable();
            this.editor.controls.mailingState.enable();
            this.editor.controls.mailingZip.enable();
            this.editor.controls.mailingCounty.enable();

            this.editor.controls.mailingLine1.patchValue('');
            this.editor.controls.mailingLine2.patchValue('');
            this.editor.controls.mailingCity.patchValue('');
            this.editor.controls.mailingState.patchValue('');
            this.editor.controls.mailingZip.patchValue('');
            this.editor.controls.mailingCounty.patchValue('');
        }

        this.ref.detectChanges();
    }

    lookupManager() {
        this.lookupManagerCode = Number(this.editor.value.relationshipManagerCode);

        this.hasDoneManagerLookup = true;
        this.store.dispatch(new fromScpStore.GetLocation({ code: this.lookupManagerCode }));
    }

    lookupManagerByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupManagerName = this.editor.value.relationshipManagerName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.managerDropdown.nativeElement).first();
            if (native.hasClass('is-open'))
                native.foundation('close');
        } else {
            if (this.lookupManagerName && this.lookupManagerName.length > 3) {
                this.hasDoneManagerLookupByName = true;
                //Dispatch an action called EventSchoolSearchById
                this.store.dispatch(new fromScpStore.GetManagersByName({ name: this.lookupManagerName }));
            }
        }

    }

    selectManager(manager: fromScpModels.LocationManager) {
        if (manager) {
            this.editor.controls['relationshipManagerName'].patchValue(manager.manager);
            this.editor.controls['relationshipManagerCode'].patchValue(manager.code);
        }
    }

    onVerifyAddressModalAction(accept) {
        this.showVerifyAddress = false;
    }

    onModalAction(accept) {
        console.log('DistrictCreateComponenet.onModalAction(): ', accept);

        // If showing district create form
        if (this.districtFormNotAddressForm) {
            if (accept) {
                this.submitted = true;

                if (!this.editor.valid) {
                    // Form is not legit, don't submit!
                    // Don't emit event to close the modal form
                    return;
                }

                // Form is good, move on to validating physical address first
                this.validatePhysicalAddress();
            }
            else {
                // Reset the form in case we show it again.
                this.resetForm();
                // Tell our container we've finished with our event create modal.
                this.action.emit(false);
            }
        }
        // If showing address validation form
        else {
            // If they accepted the results of the validation form
            if (accept) {
                // They accepted physical mailing address
                if (this.validatingPhysicalNotMailing) {
                    // Use the suggested address as our physical one
                    this.physicalAddress = this.suggestedAddress;
                    // Move on to mailing address validation
                    this.validateMailingAddress();
                }
                // Accepted mailing address
                else {
                    // Use the suggested address as our mailing one
                    this.mailingAddress = this.suggestedAddress;
                    // Go ahead and create the district now
                    this.createDistrict();
                }
            }
            // Else, they clicked "EDIT" to go back and make changes
            else {
                // Go back to district create form
                this.toggleForm(true, false);
            }
        }
    }

    createDistrict() {
        const district: DistrictCreate = {
            longName: this.editor.value.districtName,
            relationshipManagerCode: this.editor.value.relationshipManagerCode,
            physicalAddress: this.physicalAddress,
            mailingAddress: this.mailingAddress
        };

        this.store.dispatch(new fromStore.CreateDistrict({ district }));

        this.toggleForm(true, false);
        this.ref.detectChanges();
    }

    validatePhysicalAddress() {
        this.physicalAddress = {
            line1: this.editor.value.physicalLine1,
            line2: this.editor.value.physicalLine2,
            city: this.editor.value.physicalCity,
            state: this.editor.value.physicalState,
            zip: this.editor.value.physicalZip,
            county: this.editor.value.physicalCounty
        };

        this.store.dispatch(new fromStore.DistrictVerifyAddress({ panel: fromStore.PanelId.PhysAddr, address: this.physicalAddress }));

        //	observe results in store
        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStatePhysAddrPanel));
        this.panelState$.pipe(
            tap(ps => console.log('onSubmit: ', ps)),
            filter(ps => !(ps as fromStore.AddressPanelState).verifying),
            first(),
            tap(ps => console.log('onSubmit: ', ps)),
        ).subscribe(ps => {
            this.hasSubmitted = false;

            console.log('subscribe: ', ps);
            const verifiedAddress = (ps as fromStore.AddressPanelState).verifiedAddress;

            // If we didn't get back a verified address or the addresses are a perfect match
            if (verifiedAddress == null || this.compareAddresses(this.physicalAddress, verifiedAddress)) {
                // Move onto mailingAddressValidation
                this.validateMailingAddress();
            }
            // Else, verified address is different.  Show the verification form with the suggested address
            else {
                this.suggestedAddress = {
                    ...verifiedAddress,
                    county: this.editor.value.physicalCounty,
                };
                this.enteredAddress = this.physicalAddress;
                // Prompt to confirm physical mailing address validated address
                this.toggleForm(false, true);
                this.ref.detectChanges();
            }
        });

    }

    validateMailingAddress() {
        // Grab physical address for mailing if it's marked to be the same
        if (this.isSameAsPhysical) {
            this.mailingAddress = {
                ...this.physicalAddress,
                county: null
            };
        }
        else {
            // Otherwise, grab what they specified
            this.mailingAddress = {
                line1: this.editor.value.mailingLine1,
                line2: this.editor.value.mailingLine2,
                city: this.editor.value.mailingCity,
                state: this.editor.value.mailingState,
                zip: this.editor.value.mailingZip,
                county: null
            };
        }

        this.store.dispatch(new fromStore.DistrictVerifyAddress({ panel: fromStore.PanelId.PhysAddr, address: this.mailingAddress }));

        //	observe results in store
        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStatePhysAddrPanel));
        this.panelState$.pipe(
            tap(ps => console.log('onSubmit: ', ps)),
            filter(ps => !(ps as fromStore.AddressPanelState).verifying),
            first(),
            tap(ps => console.log('onSubmit: ', ps)),
        ).subscribe(ps => {
            this.hasSubmitted = false;

            console.log('subscribe: ', ps);
            const verifiedAddress = (ps as fromStore.AddressPanelState).verifiedAddress;

            // If we didn't get back a verified address or the addresses are a perfect match
            if (verifiedAddress == null || this.compareAddresses(this.mailingAddress, verifiedAddress)) {
                // Move onto creating the district
                this.createDistrict();
            }
            // Else, verified address is different.  Show the verification form with the suggested address
            else {
                this.suggestedAddress = {
                    ...verifiedAddress,
                };
                this.enteredAddress = this.mailingAddress;
                // Prompt to confirm mailing mailing address validated address
                this.toggleForm(false, false);
                this.ref.detectChanges();
            }
        });
    }

    toggleForm(districtFormNotAddressForm: boolean, validatingPhysicalNotMailing: boolean) {
        this.districtFormNotAddressForm = districtFormNotAddressForm;

        if (this.districtFormNotAddressForm) {
            this.title = 'Create New District';
            this.primaryLabel = 'Create District';
            this.secondaryLabel = 'Cancel';
        }
        else {
            this.title = 'Address Correction';
            this.primaryLabel = 'Accept';
            this.secondaryLabel = 'Edit';

            this.validatingPhysicalNotMailing = validatingPhysicalNotMailing;
        }

        return;
    }

    private compareAddresses(first: Address, second: Address): boolean {
        return first.line1 == second.line1
            && first.line2 == second.line2
            && first.city == second.city
            && first.state == second.state
            && first.zip == second.zip;
    }
}
