import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import { ProgramDetails } from '../models';
import { ProgramType } from '../../scp-common/models';

@Component({
	selector: 'opus-program-page',
	templateUrl: './program-details.component.html',
})
export class ProgramDetailsComponent implements OnInit {
	programDetails$: Observable<ProgramDetails>;
	panels$: Observable<{ [id: string]: fromStore.PanelState }>;

	constructor(private store: Store<fromStore.ProgramsState>) { }

	ngOnInit() {
		this.programDetails$ = this.store.pipe(select(fromStore.getProgramDetailsEntity));
		this.panels$ = this.store.pipe(select(fromStore.getDetailsStatePanels));
	}

	onToggleEditor(key: string, open: boolean) {
		this.store.dispatch(new fromStore.UpdateEditorState({ key, open }));
	}

	getProgramType(id: number): string {
		return ProgramType[id];
	}
}
