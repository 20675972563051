import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import * as fromModels from '../models';
import { ConfigurationService } from '../../../core/services/ConfigurationService';

@Component({
	selector: 'opus-district-searchresults-page',
	templateUrl: './district-searchresults.component.html',
})
export class DistrictSearchResultsComponent implements OnInit, OnDestroy {
    searchResultsState$: Observable<fromStore.SearchResultsState>;
    apiUrl: string;

    private _subscription: Subscription;
    private _searchCriteria: fromModels.DistrictSearchCriteria;
    private _pagingSorting: fromModels.DistrictPagingSorting;

    showModal = false;
    alive = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _store: Store<fromStore.DistrictsState>,
        private configuration: ConfigurationService
    ) {
        console.log('DistrictSearchResultsComponent: constructor().');
	}

	ngOnInit() {
        console.log('DistrictSearchResultsComponent: ngOnInit().');

        this.configuration.config$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(c => {
            this.apiUrl = c.config.apiServer;
        });

        // Get results of search (so our html can populate)
        this.searchResultsState$ = this._store.pipe(
            select(fromStore.getDistrictSearchState)
        );
        // Grab the current search criteria stored in the state
        console.log(this.searchResultsState$);
        this._subscription = this.searchResultsState$.subscribe(s => {
            this._searchCriteria = s.searchCriteria;
            this._pagingSorting = s.pagingSorting;
        });

        // If query params are changed, process them
        this.route.queryParams.subscribe(val => {
            this._store.dispatch(new fromStore.ProcessQueryString(this.router.url));
        });
    }

    ngOnDestroy() {
        this.alive = false;
    }

    nextPage() {
        console.log('DistrictSearchResultsComponent: nextPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.NextPage(this._searchCriteria, this._pagingSorting));
    }

    prevPage() {
        console.log('DistrictSearchResultsComponent: prevPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.PrevPage(this._searchCriteria, this._pagingSorting));
    }

    setPageSize(pageSize: number) {
        console.log('DistrictSearchResultsComponent: setPageSize().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SetPageSize(pageSize, this._searchCriteria));
    }

    lastPage() {
        console.log('DistrictSearchResultsComponent: lastPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.LastPage(this._searchCriteria, this._pagingSorting));
    }

    firstPage() {
        console.log('DistrictSearchResultsComponent: firstPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.FirstPage(this._searchCriteria, this._pagingSorting));
    }

    sortBy(column: string) {
        console.log('DistrictSearchResultsComponent: sortBy().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SortBy(column, this._searchCriteria, this._pagingSorting));
    }

    createDistrict() {
        console.log('createDistrict');
        this.showModal = true;
    }

    createDistrictClosed(created: boolean) {
        if (created) {
            console.log('District created');
        }
        else {
            console.log('District create was canceled');
        }

        // Reset our flag so we can show the modal again later if we want to.
        this.showModal = false;
    }
}
