
const schoolNcesIdSourceVariationsPublic: string[] = [
  '010000500870', '010000500871'
];

export const schoolNcesIdSourcesPublic: string[] = schoolNcesIdSourceVariationsPublic;

const schoolNcesIdSourceVariationsPrivate: string[] = [
  '00000033', '00000044'
];

export const schoolNcesIdSourcesPrivate: string[] = schoolNcesIdSourceVariationsPrivate;

const districtNcesIdSourceVariations: string[] = [
  '0100002', '0100005'
];

export const districtNcesIdSources: string[] = districtNcesIdSourceVariations;
