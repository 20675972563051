import { Component, Input } from '@angular/core';

import { Address } from '../../../scp-common/models';

@Component({
	selector: 'opus-district-address',
	templateUrl: './district-address.component.html',
})
export class DistrictAddressComponent {
    @Input() address: Address;
    @Input() isPhysical: boolean;
}

