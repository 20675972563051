import { Component, Input } from '@angular/core';

import { SchoolDetails } from '../../models';

@Component({
	selector: 'opus-school-additional-details',
	templateUrl: './school-additional-details.component.html',
})
export class SchoolAdditionalDetailsComponent {
	@Input() school: SchoolDetails;
	monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	get summerBilling() {
		const summerBilling = this.school.summerBillingDate == null
			? this.createBillingDate(1, 5)
			: new Date(Date.parse(this.school.summerBillingDate));

		return `${this.monthNames[summerBilling.getMonth()]} ${summerBilling.getDate().toString()}`;
	}

	get fallBilling() {
		const fallBilling = this.school.fallBillingDate == null
			? this.createBillingDate(1, 8)
			: new Date(Date.parse(this.school.fallBillingDate));

		return `${this.monthNames[fallBilling.getMonth()]} ${fallBilling.getDate().toString()}`;
	}

	get summerCustomerInHand() {
		if (!this.school.summerCustomerInHandDate) return '';

		const summerCustomerInHand = new Date(Date.parse(this.school.summerCustomerInHandDate));

		return `${this.monthNames[summerCustomerInHand.getMonth()]} ${summerCustomerInHand.getDate().toString()}`;
	}

	get fallCustomerInHand() {
		if (!this.school.fallCustomerInHandDate) return '';

		const fallCustomerInHand = new Date(Date.parse(this.school.fallCustomerInHandDate));

		return `${this.monthNames[fallCustomerInHand.getMonth()]} ${fallCustomerInHand.getDate().toString()}`;
	}

	private createBillingDate(billingDay: number, billingMonth: number) {
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth();
		const currentDay = new Date().getDate();

		if (currentMonth < billingMonth || (currentMonth == billingMonth && currentDay <= billingDay))
			return new Date(currentYear, billingMonth, billingDay);
		else
			return new Date((currentYear + 1), billingMonth, billingDay);
	}
}
