<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="active">Beginning Grade</div>
        <div class="property-block__value" role="definition" aria-labelledby="active">{{ beginningGrade }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="schoolId">Beginning Grade Student Count</div>
        <div class="property-block__value" role="definition" aria-labelledby="schoolId">{{ program.numberBeginningGrade }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">Beginning Grade Musician Count</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ program.numberBeginningMusicians }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="shortName">School Instrument Count</div>
        <div class="property-block__value" role="definition" aria-labelledby="shortName">{{ program.numberSchoolInstruments }}</div>
    </li>
</ul>
