import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromDistrictModels from '../../districts/models';

import { TeacherDetails } from '../models';
import * as fromStore from '../store';
declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-teacher-district-editor',
    templateUrl: './teacher-district-editor.component.html',
})
export class TeacherDistrictEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() teacher: TeacherDetails;

    @ViewChild('districtDropdown', {static: true})
    districtDropdown: ElementRef;

    alive = true;

    districtFoundationDropdown: any;

    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;

    lookupDistrictId: number;
    districtLookup$: Observable<fromStore.DistrictLookupState>;
    districtLookup: fromStore.DistrictLookupState;
    lookupDistrictName: string;
    districtByNameLookup$: Observable<fromStore.DistrictByNameLookupState>;
    districtByNameLookup: fromStore.DistrictByNameLookupState;

    editor: FormGroup;

    displayedLookup = false;

    constructor(private store: Store<fromStore.TeachersState>, private fb: FormBuilder, private ref: ChangeDetectorRef) {
        console.log('TeacherDistrictEditorComponent()...');
    }

    ngOnInit() {
        console.log('TeacherDistrictEditorComponent.ngOnInit()...');
        this.displayedLookup = false;

        this.createForm();

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateDistrictPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(s => {
                if (!s.editing && this.panelState && this.panelState.editing) {
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.districtLookup$ = this.store.pipe(select(fromStore.getDetailsStateDistrictLookup));
        this.districtLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(districtLookup => {
            this.districtLookup = districtLookup;

            if (districtLookup && districtLookup.pending && districtLookup.districtId == this.lookupDistrictId) {
                this.editor.controls.districtName.disable();
            }
            else {
                this.editor.controls.districtName.enable();
            }

            if (districtLookup && districtLookup.district && this.displayedLookup) {
                this.editor.controls['districtName'].patchValue(districtLookup.district.longName);
            }

            this.ref.detectChanges();
        });

        this.districtByNameLookup$ = this.store.pipe(select(fromStore.getDetailsStateDistrictByNameLookup));
        this.districtByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(districtByNameLookup => {
            this.districtByNameLookup = districtByNameLookup;

            if (districtByNameLookup && districtByNameLookup.districts && districtByNameLookup.name == this.lookupDistrictName) {
                //Trigger the drop down
                if (!this.districtFoundationDropdown) {
                    this.districtFoundationDropdown = new Foundation.Dropdown($(this.districtDropdown.nativeElement).first());
                }

                const native = $(this.districtDropdown.nativeElement).first();
                if (!native.hasClass('is-open'))
                    native.foundation('open');
                this.ref.detectChanges();
            }
        });
    }

    get canSave(): boolean {
        return this.editor.valid && this.editor.dirty && !this.panelState.updating;
    }

    ngAfterViewInit() {
        console.log(this.districtDropdown.nativeElement);
    }

    ngOnDestroy() {
        console.log('EventInfoEditorComponent.ngOnDestroy()...');
        this.alive = false;
    }

    createForm() {
        this.editor = new FormGroup({
            districtId: new FormControl(this.teacher.districtId),
            districtName: new FormControl(this.teacher.districtName),
        });
    }

    lookupDistrict() {
        this.lookupDistrictId = Number(this.editor.value.districtId);

        this.displayedLookup = true;
        //Dispatch an action called EventDistrictSearchById
        this.store.dispatch(new fromStore.DistrictLookupById({ panel: fromStore.PanelId.District, districtId: this.lookupDistrictId }));
    }

    lookupDistrictByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupDistrictName = this.editor.value.districtName;

        if (this.lookupDistrictName && this.lookupDistrictName.length > 3) {
            this.displayedLookup = true;

            //Dispatch an action called EventDistrictSearchById
            this.store.dispatch(new fromStore.DistrictLookupByName({ panel: fromStore.PanelId.District, name: this.lookupDistrictName }));
        }
    }

    selectDistrict(district: fromDistrictModels.DistrictLookup) {
        if (district) {
            this.editor.controls['districtName'].patchValue(district.longName);
            this.editor.controls['districtId'].patchValue(district.id);
        }
    }

    resetForm() {
        this.editor.reset({
            districtId: this.teacher.districtId,
            districtName: this.teacher.districtName
        });
    }

    onSubmit() {
        console.log('onSubmit: ', this.editor.status);

        const districtId = this.editor.value.districtId;
        const districtName = this.editor.value.districtName;

        const teacher = {
            ...this.teacher,
            districtId,
            districtName
        };

        this.store.dispatch(new fromStore.UpdateTeacherPanel({ panel: fromStore.PanelId.District, teacher }));
    }
}
