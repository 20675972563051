<div>
    <h1 class="typography--display1">Program Detail</h1>
    <h2 class="typography--headline u-text--uppercase">{{ program.school.longName }} {{programType }}</h2>
    <dl class="definitions--inline u-m-b-40">
        <dt>Program ID</dt>
        <dd>{{ id }}</dd>
    </dl>
    <ul class="menu menu--external-links" role="menu">
        <li role="menuitem" opusAuthorize="Preference.View"><a [routerLink]="['./', 'preferences']">Preferences</a></li>
    </ul>
</div>
