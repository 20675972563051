<!-- edit mode -->
<form [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Event Information" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editActive" class="property-block__label">Active</label>
            <div class="property-block__value">
                <select id="editActive" formControlName="isActive">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="eventId">Event ID</div>
            <div class="property-block__value" role="definition" aria-labelledby="eventId">{{ event.id }}</div>
        </li>
        <li class="property-block__list-item">
            <label for="editEventName" class="property-block__label">Event Name</label>
            <div class="property-block__value">
                <input type="text" id="editEventName" required name="eventName" formControlName="name" maxlength="100">
                <div class="error" *ngIf="editor.get('name').invalid && (editor.get('name').dirty || editor.get('name').touched)">
                    <div class="error__message" *ngIf="editor.get('name').hasError('required')">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEventType" class="property-block__label">Event Type</label>
            <div class="property-block__value">
                <select name="type" id="editEventType" required formControlName="eventType">
                    <option value="">Select</option>
                    <option *ngFor="let et of eventTypes;" [ngValue]="et.id" >{{et.name}}</option>
                </select>
                {{ this.editor.value.eventTypeId }}
                <div class="error" *ngIf="editor.get('eventType').invalid && (editor.get('eventType').dirty || editor.get('eventType').touched)">
                    <div class="error__message" *ngIf="!editor.get('eventType').invalid">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEventNotes" class="property-block__label">Notes</label>
            <div class="property-block__value">
                <textarea name="notes" id="editEventNotes" rows="3" formControlName="note" maxlength="500"></textarea>
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn"[disabled]="!canSave">Save</button>
        </div>
    </div>
</form>
