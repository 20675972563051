import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';

import { HistorySnapshot, HistoryDetails } from '../models/history.model';

@Injectable()
export class HistoryService {
	constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	loadSnapshot(contextName: string, contextId: number, scope: string = 'Details'): Observable<HistorySnapshot> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<HistorySnapshot>(`${c.config.apiServer}api/history/snapshot/${contextName}/${contextId}/${scope}`);
			}),
		);
	}

	loadHistory(contextName: string, contextId: number, scope: string, fromDate: string, toDate: string): Observable<HistoryDetails> {
		if (contextName.endsWith('s')) {
			contextName = contextName.substring(0, contextName.length - 1);
		}

		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<HistoryDetails>(`${c.config.apiServer}api/history/${contextName}/${contextId}/${scope}?fromDate=${encodeURI(fromDate)}&toDate=${encodeURI(toDate)}`);
			}),
		);
	}
}
