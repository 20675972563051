import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getLocationsState = createSelector(
	fromFeature.getScpState,
	(state: fromFeature.ScpState) => state.locations
);

export const getLocationsStateLookup = createSelector(
    fromFeature.getScpState,
    (state: fromFeature.ScpState) => state.locations.managerByNameLookup
);


export const getTaxonomyState = createSelector(
    fromFeature.getScpState,
    (state: fromFeature.ScpState) => state.taxonomy
);

export const getRentalCategoryState = createSelector(
    getTaxonomyState,
    (state: fromFeature.TaxonomyState) => state.rentalCategories
);
