<form name="eventSchoolsAttendingForm" [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Manage schools attending the event" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <!-- only edit mode -->
    <div class="property-block__content-wrapper u-m-b-16" > <!--ng-show="EventSchoolsAttendingCtrl.isEditing"-->
        <div class="property-block__content">
            <div class="table">
                <div class="table__row table__row--last table__row--align-middle">
                    <div class="table__cell">
                        <div class="input-group input-group--small u-m-b-0">
                            <input type="text" class="input-group-field" placeholder="Enter school ID" aria-label="School ID" formControlName="schoolId">
                        </div>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        <button type="button"
                                class="btn btn--action btn--add"
                                aria-label="Add school  id to list of attendees"
                                title="Add"
                                (click)="lookupSchool()"></button>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        or
                    </div>
                    <div class="table__cell">
                        <span data-toggle="attendingSchoolName"><input type="text" placeholder="Enter school name" aria-describedby="attendingSchoolNameDescription" auto-complete="off" aria-label="School name" (keyup)="lookupSchoolByName($event)" (keydown)="$event.stopPropagation()" formControlName="schoolName" ></span>
                        <ul class="dropdown-menu" role="menu" id="attendingSchoolName" #attendingSchoolDropdown data-close-on-click="true">
                            <li role="menu-item" *ngFor="let school of schoolByNameLookup?.schools">
                                <button type="button" class="dropdown-menu__action" data-close (click)="selectSchool(school)" >
                                    <span class="l-block">{{ school.longName }}</span>
                                    <span class="l-block">{{ school.county }}</span> <!-- only print this line if county has been assigned -->
                                    <span class="l-block">{{ school.city }}, {{ school.state}}</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="table__cell table__cell--shrink">
                        <button type="button"
                                class="btn btn--action btn--add"
                                aria-label="Add school to list of attendees"
                                title="Add"
                                (click)="addSchool()"></button> 
                    </div>
                </div>
                <div class="table__row table__row--last">
                    <div class="table__cell table__cell--align-right">
                        <p class="typography--caption u-m-b-0" id="attendingSchoolNameDescription">
                            Start typing the name of the school or search for the ID.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /only edit mode -->
    <!-- always display -->
    <event-schools-attending viewer [event]="editEvent" (remove)="remove($event)" editMode="true"></event-schools-attending>
    <!-- /always display -->
    <!-- only edit mode -->
    <div class="u-m-t-16">
        <button type="submit" class="property-block__action-btn" >Save</button>
    </div>
    <!-- /only edit mode -->
</form>