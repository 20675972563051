import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { Store } from '@ngrx/store';

import * as fromStore from '../store';
import * as fromRoot from '../../../core/store';
import * as fromModels from '../models';
import { TeacherDetails } from '../../teachers/models';
import { SchoolDetails } from '../../schools/models';
import { MessageType } from '../../../core/models';

@Component({
	selector: 'opus-program-searchresults-page',
	templateUrl: './program-searchresults.component.html',
})
export class ProgramSearchResultsComponent implements OnInit, OnChanges {
    @Input() teacher: TeacherDetails;
    @Input() school: SchoolDetails;

	showModal = false;
	searchResultsState$: Observable<fromStore.SearchResultsState>;

    private _subscription: Subscription;
    private _searchCriteria: fromModels.ProgramSearchCriteria;
    private _pagingSorting: fromModels.ProgramPagingSorting;
	embeddedVersion = false;
	activeProgramTypes: string[] = null;

	constructor(private _store: Store<fromStore.ProgramsState>) {
		console.log('ProgramSearchResultsComponent: constructor().');
	}

    ngOnInit() {
        console.log('ProgramSearchResultsComponent: ngOnInit().');

        // Get results of search (so our html can populate)
        this.searchResultsState$ = this._store.select(fromStore.getProgramSearchState);
        // Grab the current search criteria stored in the state
        console.log(this.searchResultsState$);
        this._subscription = this.searchResultsState$.subscribe(s => {
            this._searchCriteria = s.searchCriteria;
			this._pagingSorting = s.pagingSorting;

			if (this.school && s.searchResults) {
				this.activeProgramTypes = s.searchResults.filter(sr => (sr.active as any) === 'Y').map(sr => sr.program).filter((p, i, self) => self.indexOf(p) === i);
			}
        });
    }

    ngOnChanges() {
      // If a teacher or school is specified, we want to treat the results
      // as an embedded version (in teacher details specifically)
      if (this.teacher != null) {
        this.embeddedVersion = true;
        // Load results based on specified teacher
        this._store.dispatch(new fromStore.GetResultsForTeacher(this.teacher, this._searchCriteria));
      }
      else if (this.school != null) {
          this.embeddedVersion = true;
          // Load results based on specified teacher
          this._store.dispatch(new fromStore.GetResultsForSchool(this.school, this._searchCriteria));
      }
    }

    nextPage() {
        console.log('ProgramSearchResultsComponent: nextPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.NextPage(this._searchCriteria, this._pagingSorting));
    }

    prevPage() {
        console.log('ProgramSearchResultsComponent: prevPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.PrevPage(this._searchCriteria, this._pagingSorting));
    }

    setPageSize(pageSize: number) {
        console.log('ProgramSearchResultsComponent: setPageSize().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SetPageSize(pageSize, this._searchCriteria));
    }

    lastPage() {
        console.log('ProgramSearchResultsComponent: lastPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.LastPage(this._searchCriteria, this._pagingSorting));
    }

    firstPage() {
        console.log('ProgramSearchResultsComponent: firstPage().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.FirstPage(this._searchCriteria, this._pagingSorting));
    }

    sortBy(column: string) {
        console.log('ProgramSearchResultsComponent: sortBy().');

        // Run the search again with the new parameters
        this._store.dispatch(new fromStore.SortBy(column, this._searchCriteria, this._pagingSorting));
    }

    selectProgram(program: Number) {
        console.log(`selectProgram ${program}`);
        this._store.dispatch(new fromRoot.Go({
            path: [`programs/${program}`]
        }));
    }

    selectPreferenceSet(schoolId: Number) {
        // TODO: How do I navigate to a preference set?
        console.log(`selectPreferenceSet for ${schoolId}`);
        console.log('TODO: Need to implement preferences route.');
        //this._store.dispatch(new fromRoot.Go({
        //    path: [`schools/${schoolId}/preferences`]
        //}));
	}

	createProgram() {
		console.log('ProgramSearchResultsComponent.createProgram()...');

		//	If the user is on the Teacher detail page, it would be preferable to deliver an error message on click of this button if the Teacher is inactive.
		if (this.teacher && !this.teacher.active) {
			this._store.dispatch(new fromRoot.DisplayMessage({
				message: 'Only active Teachers may be associated with Programs.',
				messageType: MessageType.alert,
				toast: false,
			}));

			return;
		}

		//	If the user is on the School detail page, it would be preferable to deliver an error message on click of this button if the School already has a Program for each Program Type.
		if (this.school && this.activeProgramTypes && this.activeProgramTypes.length >= 4) {
			this._store.dispatch(new fromRoot.DisplayMessage({
				message: 'The School already has a Program for each Program Type.',
				messageType: MessageType.alert,
				toast: false,
			}));

			return;
		}

		this.showModal = true;
	}

	createProgramClosed(created) {
		console.log('ProgramSearchResultsComponent.creatProgramClosed(): ', created);
		this.showModal = false;
	}
}
