<!-- display mode -->
<ul class="property-block__list">
    <li class="property-block__list-item">
        <div class="property-block__label" id="hostingSchoolRelationshipManagerName">Host School Relationship Manager</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostingSchoolRelationshipManagerName">{{ event.hostingSchoolRelationshipManagerName }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="supervisor">Supervisor</div>
        <div class="property-block__value" role="definition" aria-labelledby="supervisor">{{ event.supervisor }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="totalStaffNeeded">Total Staff Needed</div>
        <div class="property-block__value" role="definition" aria-labelledby="totalStaffNeeded">{{ event.staffNeeded }}</div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="scheduledStaffMembers">Scheduled Staff Members</div>
        <div class="property-block__value u-text--preformatted" role="definition" aria-labelledby="scheduledStaffMembers">{{ event.staffNames }}</div>
    </li>
</ul>
<!-- /display mode -->
