import { Action } from '@ngrx/store';

import { ProgramSearchResultsItem, ProgramSearchCriteria, ProgramPagingSorting } from '../../models';
import { HttpHeaders } from '@angular/common/http';
import { TeacherDetails } from '../../../teachers/models';
import { SchoolDetails } from '../../../schools/models';

export enum ProgramSearchResultsActionTypes {
    ProcessQueryString = '[ProgramSearchResults] Process Query String',
	LoadProgramSearchResults = '[ProgramSearchResults] Load Search Results',
    LoadProgramSearchResultsSuccess = '[ProgramSearchResults] Load Program Success',
    LoadProgramSearchResultsFailure = '[ProgramSearchResults] Load Program Details Failure',
    UpdateEditorState = '[ProgramSearchResults] Update Editor State',
    NextPage = '[ProgramSearchResults] Next Page',
    PrevPage = '[ProgramSearchResults] Prev Page',
    SetPageSize = '[ProgramSearchResults] Set Page Size',
    LastPage = '[ProgramSearchResults] Last Page',
    FirstPage = '[ProgramSearchResults] First Page',
    SortBy = '[ProgramSearchResults] Sort By',
    GetResultsForTeacher = '[ProgramSearchResults] Get Results for Teacher',
    GetResultsForSchool = '[ProgramSearchResults] Get Results for School',
}

export class ProcessQueryString implements Action {
    readonly type = ProgramSearchResultsActionTypes.ProcessQueryString;
    constructor(public payload: string) { }
}

export class LoadProgramSearchResults implements Action {
    readonly type = ProgramSearchResultsActionTypes.LoadProgramSearchResults;
    constructor(public payload: ProgramSearchCriteria) { }
}

export class LoadProgramSearchResultsSuccess implements Action {
    readonly type = ProgramSearchResultsActionTypes.LoadProgramSearchResultsSuccess;
    constructor(public payload: ProgramSearchResultsItem[], public httpHeaders: HttpHeaders) { }
}

export class LoadProgramSearchResultsFailure implements Action {
    readonly type = ProgramSearchResultsActionTypes.LoadProgramSearchResultsFailure;
	constructor(public payload: any) { }
}

export class NextPage implements Action {
    readonly type = ProgramSearchResultsActionTypes.NextPage;
    constructor(public payload: ProgramSearchCriteria, public pagingSorting: ProgramPagingSorting) { }
}

export class PrevPage implements Action {
    readonly type = ProgramSearchResultsActionTypes.PrevPage;
    constructor(public payload: ProgramSearchCriteria, public pagingSorting: ProgramPagingSorting) { }
}

export class SetPageSize implements Action {
    readonly type = ProgramSearchResultsActionTypes.SetPageSize;
    constructor(public payload: number, public searchCriteria: ProgramSearchCriteria, ) { }
}

export class LastPage implements Action {
    readonly type = ProgramSearchResultsActionTypes.LastPage;
    constructor(public payload: ProgramSearchCriteria, public pagingSorting: ProgramPagingSorting) { }
}

export class FirstPage implements Action {
    readonly type = ProgramSearchResultsActionTypes.FirstPage;
    constructor(public payload: ProgramSearchCriteria, public pagingSorting: ProgramPagingSorting) { }
}

export class SortBy implements Action {
    readonly type = ProgramSearchResultsActionTypes.SortBy;
    constructor(public payload: string, public searchCriteria: ProgramSearchCriteria, public pagingSorting: ProgramPagingSorting) { }
}

export class GetResultsForTeacher implements Action {
    readonly type = ProgramSearchResultsActionTypes.GetResultsForTeacher;
    constructor(public payload: TeacherDetails, public searchCriteria: ProgramSearchCriteria) { }
}

export class GetResultsForSchool implements Action {
    readonly type = ProgramSearchResultsActionTypes.GetResultsForSchool;
    constructor(public payload: SchoolDetails, public searchCriteria: ProgramSearchCriteria) { }
}

export type ProgramSearchResultsActions =
    | ProcessQueryString
    | LoadProgramSearchResults
    | LoadProgramSearchResultsSuccess
    | LoadProgramSearchResultsFailure
    | NextPage
    | PrevPage
    | SetPageSize
    | LastPage
    | FirstPage
    | SortBy
    | GetResultsForTeacher
    | GetResultsForSchool;
