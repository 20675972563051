import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { TeacherDetails } from '../models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-teacher-contact-editor',
	templateUrl: './teacher-contact-editor.component.html',
})
export class TeacherContactEditorComponent implements OnInit, OnDestroy, OnChanges {
	@Input() teacher: TeacherDetails;

	alive = true;
	panelState$: Observable<fromStore.PanelState>;
	panelState: fromStore.PanelState;
	editor: FormGroup;

	constructor(private store: Store<fromStore.TeachersState>, private fb: FormBuilder) {
		console.log('TeacherContactEditorComponent()...');

		this.createForm();
	}

  ngOnInit() {
    console.log('TeacherContactEditorComponent.ngOnInit()');

    this.panelState$ = this.store.select(fromStore.getDetailsStateContactPanel);
    this.panelState$.pipe(
      takeWhile(() => this.alive),
      tap(s => {
        if (!s.editing && this.panelState && this.panelState.editing) {
          console.log('TeacherContactEditorComponent.panelState$: clearing form...');
          this.resetForm();
        }
      }),
    ).subscribe(ps => {
      console.log('panelState$ sub: ', ps);
      this.panelState = ps;
    });
  }

	ngOnDestroy() {
		console.log('TeacherContactEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	ngOnChanges() {
		this.resetForm();
	}

    createForm() {
        const phoneValidator = Validators.pattern(/^([0-9]{10})?$/);
        // Stolen from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
        const emailValidator = Validators.pattern(/^.+?@.+?\..+$/);

		//	NOTE: FormBuilder.group will ignore the updateOn parameter when constructing the FormGroup
		this.editor = this.fb.group({
			phone: ['', phoneValidator],
			fax: ['', phoneValidator],
			email: ['', emailValidator],
		}/* NOTE: originally tried to do address verification as an async validator, but angular was ignoring the supplied updateOn='submit' even when using FormGroup instead of FormBuilder */);
	}

	resetForm() {
		console.log('resetForm()...');

		this.editor.reset({
			phone: this.teacher.phone,
			fax: this.teacher.fax,
			email: this.teacher.email,
		});
	}

    get panelId(): string {
        return fromStore.PanelId.Contact;
	}

	get canSave(): boolean {
		return this.editor.dirty && this.editor.valid;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

    onSubmit() {
        const teacher = { ...this.teacher };
        Object.keys(this.editor.value).forEach(key => {
            teacher[key] = this.editor.value[key];
        });

        this.store.dispatch(new fromStore.UpdateTeacherPanel({ panel: fromStore.PanelId.Contact, teacher }));
    }
}
