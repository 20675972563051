<form [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
	<ul class="property-block__list">
		<li class="property-block__list-item">
			<div id="editSchoolStart" class="property-block__label">School Start</div>
			<div class="property-block__value" role="group" aria-labelledby="editSchoolStart">
				<div class="school-calendar">
					<div class="school-calendar__group">
						<div class="school-calendar__month">
							<select aria-label="Month" formControlName="schoolStartFromMonth">
								<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
							</select>
						</div>
						<div class="school-calendar__day">
							<select aria-label="Day" formControlName="schoolStartFromDay">
								<option *ngFor="let d of days[editor.value.schoolStartFromMonth]" [value]="d">{{ d }}</option>
							</select>
						</div>
					</div>
					<div class="school-calendar__separator">through</div>
					<div class="school-calendar__group">
						<div class="school-calendar__month">
							<select aria-label="Month" formControlName="schoolStartToMonth">
								<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
							</select>
						</div>
						<div class="school-calendar__day">
							<select aria-label="Day" formControlName="schoolStartToDay">
								<option *ngFor="let d of days[editor.value.schoolStartToMonth]" [value]="d">{{ d }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</li>
		<li class="property-block__list-item">
			<div id="editSchoolStart" class="property-block__label">School End</div>
			<div class="property-block__value" role="group" aria-labelledby="editSchoolEnd">
				<div class="school-calendar">
					<div class="school-calendar__group">
						<div class="school-calendar__month">
							<select aria-label="Month" formControlName="schoolEndFromMonth">
								<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
							</select>
						</div>
						<div class="school-calendar__day">
							<select aria-label="Day" formControlName="schoolEndFromDay">
								<option *ngFor="let d of days[editor.value.schoolEndFromMonth]" [value]="d">{{ d }}</option>
							</select>
						</div>
					</div>
					<div class="school-calendar__separator">through</div>
					<div class="school-calendar__group">
						<div class="school-calendar__month">
							<select aria-label="Month" formControlName="schoolEndToMonth">
								<option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
							</select>
						</div>
						<div class="school-calendar__day">
							<select aria-label="Day" formControlName="schoolEndToDay">
								<option *ngFor="let d of days[editor.value.schoolEndToMonth]" [value]="d">{{ d }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</li>
	</ul>
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
