import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromRoot from '../../../../core/store';
import * as fromServices from '../../../scp-common/services';
import * as fromActions from '../actions';
import { SchoolLookup } from '../../../schools/models';

import { MessageType, ProblemDetails } from '../../../../core/models';

@Injectable()
export class ProgramCreateEffects {
  constructor(
    private actions$: Actions,
    private programService: fromServices.ProgramService,
    private schoolService: fromServices.SchoolService,
  ) { }

  
  createProgram$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ProgramCreateActionTypes.CreateProgram),
    tap(a => console.log('Entering createProgram$ Effect: ', a)),
    switchMap((a: fromActions.CreateProgram) => {
      const { program } = a.payload;

      return this.programService.createProgram(program).pipe(
        tap(res => console.log(`ProgramService.createProgram( ${program} ) returned: `, res)),
        map((id: number) => {
          return new fromActions.CreateProgramSuccess({ id, teacherId: program.teacherId });
        }),
        catchError(error => of(new fromActions.CreateProgramFailure(error))),
      );
    }),
    tap(a => console.log('Exiting createProgram$ Effect: ', a)),
  ));

  
  createProgramFailure$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ProgramCreateActionTypes.CreateProgramFailure),
    tap(a => console.log('Entering createProgramFailure$ Effect: ', a)),
    switchMap((action: fromActions.CreateProgramFailure) => {
      return of(new fromRoot.DisplayMessage({
        message: this.getErrorMessage(action.payload.errors),
        messageType: MessageType.alert,
        toast: false,
      }));
    }),
    tap(a => console.log('Exiting createProgramFailure$ Effect: ', a)),
  ));

  
  createProgramSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ProgramCreateActionTypes.CreateProgramSuccess),
    tap(a => console.log('Entering createProgramSuccess$ Effect: ', a)),
    map((action: fromActions.CreateProgramSuccess) => {
      return new fromRoot.Go({
        path: [`programs/${action.payload.id}`]
      });
    }),
    tap(a => console.log('Exiting createProgramSuccess$ Effect: ', a)),
  ));

  
  schoolLookupById$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ProgramCreateActionTypes.SchoolLookupById),
    tap(a => console.log('Entering createProgramSuccess$ Effect: ', a)),
    switchMap((action: fromActions.SchoolLookupById) => {
      return this.schoolService.lookupSchoolById(action.payload.id).pipe(
        tap(s => {
          console.log('lookupSchoolById returned:', s);
        }),
        map(res => {
          if (res === null) {
            return new fromActions.SchoolLookupByIdFailure({ errors: 'School Not Found' });
          }

          return new fromActions.SchoolLookupByIdSuccess({ school: res });
        }),
        catchError(error => of(new fromActions.SchoolLookupByIdFailure({ errors: error }))),
      );
    }),
    tap(a => console.log('Exiting createProgramSuccess$ Effect: ', a)),
  ));

  
  schoolLookupByName$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ProgramCreateActionTypes.SchoolLookupByName),
    tap(a => console.log('Entering schoolLookupByName$ Effect: ', a)),
    switchMap((action: fromActions.SchoolLookupByName) => {
      return this.schoolService.lookupSchoolByName(action.payload.name).pipe(
        tap(s => {
          console.log('schoolLookupByName$ returned: ', s);
        }),
        map(res => {
          if (res === null) {
            return new fromActions.SchoolLookupByNameFailure({ errors: 'School Not Found' });
          }

          return new fromActions.SchoolLookupByNameSuccess({ schools: res });
        }),
        catchError(error => of(new fromActions.SchoolLookupByNameFailure({ errors: error }))),
      );
    }),
    tap(a => console.log('Exiting schoolLookupByName$ Effect: ', a)),
  ));

  
  schoolLookupReset$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.ProgramCreateActionTypes.SchoolLookupReset),
    tap(a => console.log('Entering schoolLookupReset$ Effect: ', a)),
    switchMap((action: fromActions.SchoolLookupByIdSuccess) => {
      const empty: SchoolLookup = null;
      return of(new fromActions.SchoolLookupByIdSuccess({ school: empty }));
    }),
    tap(a => console.log('Exiting schoolLookupReset$ Effect: ', a)),
  ));

  private getErrorMessage(response: HttpErrorResponse): string {
    if (response.status === 400 && response.error) {
      try {
        const problemDetails = response.error as ProblemDetails;
        if (problemDetails) {
          const firstError = problemDetails.errors ? problemDetails.errors[Object.keys(problemDetails.errors)[0]] : null;
          if (firstError)
            return firstError;
          else if (problemDetails.title)
            return problemDetails.title;
        }
      }
      catch (ex) {
        console.warn(ex);
      }
    }

    return response.error ? response.error.message : response.message;
  }
}
