import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';
import { HistorySnapshot } from '../models';

@Component({
	selector: 'opus-history-snapshot',
	templateUrl: './history-snapshot.component.html',
})
export class HistorySnapshotComponent implements OnInit, OnChanges {
	@Input() contextName: string;
	@Input() contextId: number;
	@Input() scope = 'Details';
	snapshotState$: Observable<fromStore.SnapshotState>;

	constructor(private store: Store<fromStore.SnapshotState>) {
		console.log('HistorySnapshotComponent()...');
	}

	ngOnInit() {
		console.log('HistorySnapshotComponent.ngOnInit()...');

		this.snapshotState$ = this.store.pipe(select(fromStore.getHistorySnapshotState));
		this.store.dispatch(new fromStore.LoadSnapshot({ contextName: this.contextName, contextId: this.contextId, scope: this.scope }));
	}

	ngOnChanges(changes: SimpleChanges) {
		console.log('HistorySnapshotComponent.ngOnChanges(): ', changes);

		if (changes.contextId && !changes.contextId.firstChange) {
			this.store.dispatch(new fromStore.LoadSnapshot({ contextName: this.contextName, contextId: this.contextId, scope: this.scope }));
		}
	}

	get historyLabel() {
		//const scope = this.contextName == 'preferencesets' ? 'Preferences' : 'Details';

		return `View ${this.scope} History`;
	}
}
