<!-- display mode -->
<ul class="property-block__list">
    <li class="property-block__list-item" *ngIf="event.hostingSchoolDistrictId > 0">
        <div class="property-block__label" id="hostSchoolDistrictId">Host School District ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostSchoolDistrictId"><a routerLink="/districts/{{ event.hostingSchoolDistrictId }}">{{ event.hostingSchoolDistrictId }}</a></div>
    </li>
    <li class="property-block__list-item" *ngIf="event.hostingSchoolDistrictId > 0 && event.hostingSchoolDistrictName">
        <div class="property-block__label" id="hostSchoolDistrictName">Host School District Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostSchoolDistrictName"><a routerLink="/districts/{{ event.hostingSchoolDistrictId }}">{{ event.hostingSchoolDistrictName }}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="hostSchoolId">Host School ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostSchoolId"><a routerLink="/schools/{{ event.hostingSchoolId }}">{{ event.hostingSchoolId }}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="hostSchoolName">Host School Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostSchoolName"><a routerLink="/schools/{{ event.hostingSchoolId }}">{{ event.hostingSchoolName }}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="hostTeacherId">Host Teacher ID</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostTeacherId"><a routerLink="/teachers/{{ event.hostingTeacherId }}">{{event.hostingTeacherId}}</a></div>
    </li>
    <li class="property-block__list-item">
        <div class="property-block__label" id="hostTeacherName">Host Teacher Name</div>
        <div class="property-block__value" role="definition" aria-labelledby="hostTeacherName"><a routerLink="/teachers/{{ event.hostingTeacherId }}">{{event.hostingTeacherName}}</a></div>
    </li>
</ul>
<!-- /display mode -->
