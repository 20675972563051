<form name="teacherInfoForm" aria-label="Teacher Information" [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editActive" class="property-block__label">Active</label>
            <div class="property-block__value">
                <select id="editActive" formControlName="active">
                    <option [ngValue]="true">Yes</option>
                    <option [ngValue]="false">No</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="!editor.value.active">
            <label for="inactiveReason" class="property-block__label">Inactive Reason</label>
            <div class="property-block__value">
                <select id="inactiveReason" formControlName="inactiveReason">
                    <option [ngValue]="0">Inactive</option>
                    <option [ngValue]="1">Duplicate</option>
                    <option [ngValue]="2">Pending</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item ng-scope" *ngIf="!editor.value.active && editor.value.inactiveReason == 1">
            <label for="editPrimaryId" class="property-block__label">Primary ID</label>
            <div class="property-block__value">
                <input type="text" id="editPrimaryId" formControlName="duplicateTeacherId">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="inactiveReason" class="property-block__label">Teacher ID</label>
            <div class="property-block__value" role="definition" aria-labelledby="teacherId">{{ teacherId }}</div>
        </li>
        <li class="property-block__list-item">
            <label for="editPrefix" class="property-block__label">Prefix</label>
            <div class="property-block__value">
                <select id="editPrefix" formControlName="prefix">
                    <option value="notset">Select</option>
                    <option value="1" label="Mr">Mr</option>
                    <option value="2" label="Mrs">Mrs</option>
                    <option value="3" label="Ms" selected="selected">Ms</option>
                    <option value="4" label="Dr">Dr</option>
                    <option value="5" label="Capt">Capt</option>
                    <option value="6" label="Major">Major</option>
                    <option value="7" label="Sgt">Sgt</option>
                    <option value="8" label="Col">Col</option>
                    <option value="9" label="Fr">Fr</option>
                    <option value="10" label="Father">Father</option>
                    <option value="11" label="Brother">Brother</option>
                    <option value="12" label="Sister">Sister</option>
                    <option value="13" label="Pastor">Pastor</option>
                    <option value="14" label="Rev">Rev</option>
                    <option value="15" label="Rabbi">Rabbi</option>
                    <option value="16" label="Sr">Sr</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editFirstName" class="property-block__label">First Name</label>
            <div class="property-block__value">
                <input type="text" id="editFirstName" formControlName="firstName">
            </div>  
        </li>
        <li class="property-block__list-item">
            <label for="editLastName" class="property-block__label">Last Name</label>
            <div class="property-block__value">
                <input type="text" id="editLastName" formControlName="lastName" required>
                <div class="error" *ngIf="editor.get('lastName').invalid">
                    <div class="error__message">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editSuffix" class="property-block__label">Suffix</label>
            <div class="property-block__value">
                <select id="editSuffix" formControlName="suffix">
                    <option value="notset" selected>Select</option>
                    <option label="Phd" value="1">Phd</option>
                    <option label="Esq" value="2">Esq</option>
                    <option label="Md" value="3">Md</option>
                    <option label="Jr" value="4">Jr</option>
                    <option label="II" value="5">II</option>
                    <option label="III" value="6">III</option>
                    <option label="IV" value="7">IV</option>
                    <option label="Doctor" value="8">Doctor</option>
                    <option label="Do" value="9">Do</option>
                    <option label="A" value="10">A</option>
                    <option label="W" value="11">W</option>
                    <option label="J" value="12">J</option>
                    <option label="M" value="13">M</option>
                    <option label="Ms" value="14">Ms</option>
                    <option label="Ms D" value="15">Ms D</option>
                    <option label="Ms Z" value="16">Ms Z</option>
                    <option label="Ms O" value="17">Ms O</option>
                    <option label="Ms Coo" value="18">Ms Coo</option>
                    <option label="Mis" value="19">Mis</option>
                    <option label="Eng" value="20">Eng</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editGender" class="property-block__label" required>Gender</label>
            <div class="property-block__value">
                <select id="editGender" formControlName="gender">
                    <option value="M">Male</option>
                    <option value="F" selected="selected">Female</option>
                    <option value="N">N/A</option>
                </select>
                <div class="error" *ngIf="editor.get('gender').hasError('required')">
                    <div class="error__message">Required</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editType" class="property-block__label">Type</label>
            <div class="property-block__value">
                <select formControlName="schoolType">
                    <option value="notset" selected>Select</option>
                    <option value="1" label="Public">Public</option>
                    <option value="2" label="Private">Private</option>
                    <option value="3" label="College">College</option>
                    <option value="4" label="Other">Other</option>
                    <option value="5" label="Adult">Adult</option>
                    <option value="6" label="Charter">Charter</option>
                    <option value="7" label="National">National</option>
                    <option value="8" label="Home">Home</option>
                    <option value="9" label="Not Listed">Not Listed</option>
                </select>
                <div class="error" *ngIf="editor.get('schoolType').invalid">
                    <div class="error__message">Required</div>
                </div>
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
        </div>
    </div>
</form>