<form novalidate name="districtInformationForm" aria-label="District Information" [formGroup]="editor" (ngSubmit)="onSubmit()" class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
	<div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editIsActive" class="property-block__label">Active</label>
            <div class="property-block__value">
                <select id="editIsActive" formControlName="isActive">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                <ul class="error" *ngIf="editor.get('isActive').invalid">
                    <li class="error__message" *ngIf="editor.controls.isActive.hasError('canDeactivate')">Associated entities must be disassociated or deactivated prior to deactivating a district.</li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.isActive == 'No' && editor.controls.isActive.valid">
            <label for="inactiveReason" class="property-block__label">Inactive Reason</label>
            <div class="property-block__value">
                <select id="inactiveReason" formControlName="inactiveReason">
                    <option value="0">Closed</option>
                    <option value="1">Duplicate</option>
                    <option value="2">Pending</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="allowEditPrimaryId">
            <label for="editPrimary" class="property-block__label">Primary ID</label>
            <div class="property-block__value">
                <input type="text" id="editPrimary" formControlName="primaryId">
                <ul class="error" *ngIf="hasPrimaryIdErrors">
                    <li class="error__message" *ngIf="primaryIdErrors.primaryId">{{ primaryIdErrors.primaryId }}</li>
                    <li class="error__message" *ngIf="primaryIdErrors.canDeactivate">{{ primaryIdErrors.canDeactivate }}</li>
                    <li class="error__message" *ngIf="primaryIdErrors.districtExists">{{ primaryIdErrors.districtExists }}</li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="id">District ID</div>
            <div class="property-block__value" role="definition" aria-labelledby="id">{{ district.id }}</div>
        </li>
        <li class="property-block__list-item">
            <label for="editLongName" class="property-block__label">Long Name</label>
            <div class="property-block__value">
                <input type="text" id="editLongName" formControlName="longName">
                <ul class="error" *ngIf="editor.controls.longName.invalid">
                    <li class="error__message" *ngIf="editor.controls.longName.hasError('required')">Required</li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editShortName" class="property-block__label">Short Name</label>
            <div class="property-block__value">
                <input type="text" id="editShortName" formControlName="shortName">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editIsSupervisoryId" class="property-block__label">Supervisory District</label>
            <div class="property-block__value">
                <select id="editIsSupervisoryId" formControlName="isSupervisory">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
                <ul class="error" *ngIf="editor.invalid">
                    <li class="error__message" *ngIf="editor.hasError('hasSupervisoryDistrict')">Supervisory districts may not have a supervisory district.</li>
                    <li class="error__message" *ngIf="editor.controls.isSupervisory.hasError('hasSchools')">Associated schools must be disassociated prior to setting a district as supervisory.</li>
                    <li class="error__message" *ngIf="editor.controls.isSupervisory.hasError('hasDistricts')">Associated districts must be disassociated prior to removing the supervisory status.</li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editSupervisoryDistrictId" class="property-block__label">Supervisory District ID</label>
            <div class="property-block__value">
                <div class="input-group u-m-b-0">
                    <input type="text" id="editSupervisoryDistrictId" class="input-group-field" formControlName="supervisoryDistrictId">
                    <div class="input-group-button">
                        <button type="button" (click)="onApplySupervisoryDistrictId()">Apply</button>
                    </div>
                </div>
                <ul class="error" *ngIf="editor.controls.supervisoryDistrictId.invalid">
                    <li class="error__message" *ngIf="editor.controls.supervisoryDistrictId.hasError('supervisoryInactive')">The Supervisory District must be active if the District is active.</li>
                    <li class="error__message" *ngIf="editor.controls.supervisoryDistrictId.hasError('notFound')">District not found.</li>
                    <li class="error__message" *ngIf="editor.controls.supervisoryDistrictId.hasError('invalidId')">ID Must be a positive number.</li>
                    <li class="error__message" *ngIf="editor.controls.supervisoryDistrictId.hasError('districtId')">A District cannot be its own Supervisory District.</li>
                    <li class="error__message" *ngIf="editor.controls.supervisoryDistrictId.hasError('notSupervisory')">District is not a Supervisory District.</li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editSupervisoryDistrictName" class="property-block__label">Supervisory District Name</label>
            <div class="property-block__value">
                <span data-toggle="districtNameTypeahead">
                    <input type="text" class="input-group-field" id="editSupervisoryDistrictName" autocomplete="off" formControlName="supervisoryDistrictName" (keyup)="lookupDistrictByName($event)" (keydown)="$event.stopPropagation()">
                </span>
                <ul class="dropdown-menu" role="menu" id="districtNameTypeahead" #districtDropdown data-close-on-click="true">
                    <li role="menu-item" *ngFor="let sd of districtByNameLookup?.districts">
                        <button type="button" class="dropdown-menu__action" (click)="selectDistrict(sd)" data-close>
                            <span class="l-block">{{ sd.longName }} ({{ sd.id }})</span>
                            <span class="l-block" *ngIf="sd.county">{{ sd.county }}</span>
                            <span class="l-block">{{ (sd.city ? sd.city + ', ' : '') + sd.state }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
	<!--<ul class="property-block__list">
		<li class="property-block__list-item">
			<div class="property-block__label">Form Value</div>
			<div class="property-block__value">{{ editor.value | json }}</div>
		</li>
		<li class="property-block__list-item">
			<div class="property-block__label">Form Status</div>
			<div class="property-block__value">{{ editor.status | json }}</div>
		</li>
	</ul>-->
	<div class="property-block__action-wrapper">
		<div class="property-block__action">
			<button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
		</div>
	</div>
</form>
