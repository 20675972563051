import * as fromDistrictDetails from '../actions/details.actions';
import * as fromCommonModels from '../../../scp-common/models';
import * as fromDistrictModels from '../../models';
import * as fromPrefs from '../../../preferences/store/actions';

export enum PanelId {
	Info = 'info',
	Relationship = 'relationship',
	PhysAddr = 'physAddr',
	MailAddr = 'mailAddr',
	Contacts = 'contacts',
	Calendar = 'calendar',
	AddlDetails = 'addl-details',
}

export interface ValidationStatus {
	[id: string]: {
		pending: boolean;
		errors: { [key: string]: any };
	};
}

export interface PanelState {
	editing: boolean;
	updating: boolean;
	validationStatus: ValidationStatus;
	errors: any;	//	TODO: formalize error handling
}

export interface ZipLookupState {
	zip: string;
	pending: boolean;
	errors: any;
	cityState: { city: string, state: string };
}

export interface CountyLookupState {
	pending: boolean;
	lookupStateCode: string;
	errors: any;
	counties: { [stateCode: string]: string[] };
}

export interface AddressPanelState extends PanelState {
	countyLookup: CountyLookupState;
	zipLookup: ZipLookupState;
	verifying: boolean;
	verifiedAddress: fromCommonModels.Address;
	suggestedAddresses: fromCommonModels.Address[];
}

export interface DistrictIdLookupState {
	pending: boolean;
	errors: any;
	district: fromDistrictModels.DistrictLookup;
}

export interface DistrictByNameLookupState {
	name: string;
	pending: boolean;
	errors: any;
	districts: fromDistrictModels.DistrictLookup[];
}

export interface DistrictLookupsState {
	idLookups: { [id: number]: DistrictIdLookupState };
	nameLookup: DistrictByNameLookupState;
}

export interface DetailsState {
	loading: boolean;
	loaded: boolean;
	creating: boolean;
	createdId?: number;
	district: fromDistrictModels.District;
	errors: any;
	panels: { [id: string]: PanelState };
	districtLookups: DistrictLookupsState;
}

export const initialPanelState: PanelState = {
	editing: false,
	validationStatus: {},
	updating: false,
	errors: null,
};

export const initialZipLookupState: ZipLookupState = {
	zip: '',
	pending: false,
	cityState: null,
	errors: null,
};

export const initialCountyLookupState: CountyLookupState = {
	pending: false,
	lookupStateCode: null,
	errors: null,
	counties: {},
};

export const initialAddressPanelState: AddressPanelState = {
	...initialPanelState,
	zipLookup: initialZipLookupState,
	countyLookup: initialCountyLookupState,
	verifying: false,
	verifiedAddress: null,
	suggestedAddresses: null,
};

export const initialDistrictIdLookupState: DistrictIdLookupState = {
	district: null,
	errors: null,
	pending: false,
};

export const initialDistrictNameLookupState: DistrictByNameLookupState = {
	name: null,
	districts: null,
	errors: null,
	pending: false,
};

export const initialDistrictLookupsState: DistrictLookupsState = {
	idLookups: {},
	nameLookup: initialDistrictNameLookupState,
};

export const initialState: DetailsState = {
	loading: false,
	loaded: false,
	creating: false,
	createdId: null,
	district: null,
	errors: null,
	panels: {
		[PanelId.Info]: { ...initialPanelState },
		[PanelId.Relationship]: { ...initialPanelState },
		[PanelId.PhysAddr]: { ...initialAddressPanelState } as PanelState,
		[PanelId.MailAddr]: { ...initialAddressPanelState } as PanelState,
		[PanelId.Contacts]: { ...initialPanelState },
		[PanelId.Calendar]: { ...initialPanelState },
		[PanelId.AddlDetails]: { ...initialPanelState },
	},
	districtLookups: initialDistrictLookupsState,
};

export function reducer(state = initialState, action: fromDistrictDetails.DistrictActions | fromPrefs.PrefsActions): DetailsState {
	switch (action.type) {
		case fromDistrictDetails.DistrictActionTypes.LoadDistrict: {
			return {
				...state,
				loading: true,
				errors: null,
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LoadDistrictSuccess: {
			const district = action.payload;

			return {
				...state,
				loaded: true,
				loading: false,
				errors: null,
				district,
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LoadDistrictFailure: {
			console.log('reducer:', action);

			return {
				...state,
				loaded: false,
				loading: false,
				errors: action.payload,
			};
    }

    case fromPrefs.PrefsActionTypes.CreateOverridePrefsSuccess: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: null
      };
    }

    case fromPrefs.PrefsActionTypes.CopyPrefsSuccess: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: null
      };
    }

    case fromPrefs.PrefsActionTypes.DeletePrefsSuccess: {
      return {
        ...state,
        loaded: false,
        loading: false,
        errors: null
      };
    }

		case fromDistrictDetails.DistrictActionTypes.UpdateEditorState: {
			const key = action.payload.key;
			const editing = action.payload.open;

			if (editing == state.panels[key].editing) {
				return state;
			}

			const panel = { ...initialState.panels[key], editing };	//	NOTE: initialPanelState will not have any custom panel initialization...
			const panels = {
				...state.panels,
				[key]: panel,
			};

			return {
				...state,
				panels,
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LookupDistrictById: {
			const { id } = action.payload;
			const lookupState: DistrictIdLookupState = state.districtLookups.idLookups[id] || initialDistrictIdLookupState;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					idLookups: {
						...state.districtLookups.idLookups,
						[id]: {
							...lookupState,
							pending: true,
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LookupDistrictByIdSuccess: {
			const { id, dl } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					idLookups: {
						...state.districtLookups.idLookups,
						[id]: {
							pending: false,
							errors: null,
							district: dl,
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LookupDistrictByIdFailure: {
			const { id, error } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					idLookups: {
						...state.districtLookups.idLookups,
						[id]: {
							pending: false,
							errors: error,
							district: null,
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LookupDistrictByName: {
			const { name } = action.payload;
			const nameLookup: DistrictByNameLookupState = {
				name,
				pending: true,
				errors: null,
				districts: null,
			};

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					nameLookup,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LookupDistrictByNameFailure: {
			const { error, name } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					nameLookup: {
						name,
						pending: false,
						errors: error,
						districts: null,
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.LookupDistrictByNameSuccess: {
			const { name, dls } = action.payload;

			return {
				...state,
				districtLookups: {
					...state.districtLookups,
					nameLookup: {
						name,
						pending: false,
						errors: null,
						districts: dls,
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.UpdateDistrictPanel: {
			const panel = {
				...state.panels[action.payload.panel],
				updating: true,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[action.payload.panel]: panel,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.UpdateDistrictPanelFailure: {
			const panel = {
				...state.panels[action.payload.panel],
				updating: false,
				errors: action.payload.errors,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[action.payload.panel]: panel,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.UpdateDistrictPanelSuccess: {
			const district = action.payload.district;

			return {
				...state,
				district,
				panels: {
					...state.panels,
					[action.payload.panel]: initialState.panels[action.payload.panel],
				},
			};
		}

		//	Primary Id validation is a special case in that it has dependent validations that must be managed
		//	Both can deactivate & district exists validations may be triggered, but must always be cleared
		case fromDistrictDetails.DistrictActionTypes.ValidateDistrictPrimaryId: {
			const { panel, validator } = action.payload;
			const validationStatus = { ...state.panels[panel].validationStatus, };
			const { canDeactivate, districtExists, primaryId, ...rest } = validationStatus;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...rest,
							[validator]: {
								pending: true,
								errors: null,
							},
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictPrimaryIdValidated: {
			const { panel, validator } = action.payload;
			const validationStatus = { ...state.panels[panel].validationStatus, };
			const { canDeactivate, districtExists, primaryId, ...rest } = validationStatus;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...rest,
							[validator]: {
								pending: false,
								errors: action.payload.errors,
							},
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.ValidateDistrictIsSupervisory:
		case fromDistrictDetails.DistrictActionTypes.ValidateDistrictExists:
		case fromDistrictDetails.DistrictActionTypes.ValidateDistrictCanDeactivate: {
			const { panel, validator } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...state.panels[panel].validationStatus,
							[validator]: {
								pending: true,
								errors: null,
							},
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictIsSupervisoryValidated:
		case fromDistrictDetails.DistrictActionTypes.DistrictExistsValidated:
		case fromDistrictDetails.DistrictActionTypes.DistrictCanDeactivateValidated: {
			const { panel, validator } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						validationStatus: {
							...state.panels[panel].validationStatus,
							[validator]: {
								pending: false,
								errors: action.payload.errors,
							},
						},
					},
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictAddressLookupCityState: {
			const { zip, panel } = action.payload;
			const zipLookup: ZipLookupState = {
				...(state.panels[panel] as AddressPanelState).zipLookup,
				zip,
				pending: true,
				errors: null,
				cityState: null,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						zipLookup,
					} as AddressPanelState,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictAddressLookupCityStateFailure: {
			const { errors, panel } = action.payload;
			const zipLookup: ZipLookupState = {
				...(state.panels[panel] as AddressPanelState).zipLookup,
				pending: false,
				errors,
				cityState: null,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						zipLookup,
					} as AddressPanelState,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictAddressLookupCityStateSuccess: {
			const { panel, cityState } = action.payload;
			const zipLookup: ZipLookupState = {
				...(state.panels[panel] as AddressPanelState).zipLookup,
				pending: false,
				cityState,
			};

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						zipLookup,
					} as AddressPanelState,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictVerifyAddress: {
			const { panel, address } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						verifying: true,
					} as AddressPanelState,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictVerifyAddressSuccess: {
			const { panel, verification } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						verifying: false,
						verifiedAddress: verification.verifiedAddress,
						suggestedAddresses: verification.picklist,
					} as AddressPanelState,
				},
			};
		}

        case fromDistrictDetails.DistrictActionTypes.DistrictVerifyAddressFailure: {
            const { panel } = action.payload;

            return {
                ...state,
                panels: {
                    ...state.panels,
                    [panel]: {
                        ...state.panels[panel],
                        verifying: false,
                        verifiedAddress: null,
                        suggestedAddresses: null,
                    } as AddressPanelState,
                },
            };
        }

		case fromDistrictDetails.DistrictActionTypes.DistrictLoadCounties: {
			const { panel, stateCode } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						countyLookup: {
							...(state.panels[panel] as AddressPanelState).countyLookup,
							pending: true,
							lookupStateCode: stateCode,
						},
					} as AddressPanelState,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.DistrictLoadCountiesSuccess: {
			const { panel, counties } = action.payload;

			return {
				...state,
				panels: {
					...state.panels,
					[panel]: {
						...state.panels[panel],
						countyLookup: {
							...(state.panels[panel] as AddressPanelState).countyLookup,
							pending: false,
							counties: {
								...(state.panels[panel] as AddressPanelState).countyLookup.counties,
								[(state.panels[panel] as AddressPanelState).countyLookup.lookupStateCode]: counties,
							},
						},
					}as AddressPanelState,
				},
			};
		}

		case fromDistrictDetails.DistrictActionTypes.CreateDistrict: {
			return {
				...state,
				creating: true,
				createdId: null,
			};
		}

		case fromDistrictDetails.DistrictActionTypes.CreateDistrictSuccess: {
			return {
				...state,
				creating: false,
				createdId: action.payload.id,
			};
		}

		case fromDistrictDetails.DistrictActionTypes.CreateDistrictFailure: {
			return {
				...state,
				creating: false,
				createdId: null,
				errors: action.payload.errors,
			};
		}
	}

	return state;
}

export const getDetailsStateDistrict = (state: DetailsState) => state.district;
export const getDetailsStateLoading = (state: DetailsState) => state.loading;
export const getDetailsStateLoaded = (state: DetailsState) => state.loaded;
export const getDetailsStateErrors = (state: DetailsState) => state.errors;
export const getDetailsStatePanels = (state: DetailsState) => state.panels;
export const getDetailsStateContactsPanel = (state: PanelState) => state[PanelId.Contacts];
export const getDetailsStateZipLookup = (state: AddressPanelState) => state.zipLookup;
export const getDetailsStateCountyLookup = (state: AddressPanelState) => state.countyLookup;
export const getDistrictLookupsState = (state: DetailsState) => state.districtLookups;
export const getDistrictByNameLookupState = (state: DistrictLookupsState) => state.nameLookup;
