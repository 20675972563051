<div class="entity-detail">
    <nav class="u-m-b-16">
        <ul class="breadcrumbs">
            <li><a routerLink="/search/schools">Schools</a></li>
            <li>{{ (schoolDetails$ | async).longName }}</li>
        </ul>
    </nav>
    <header class="row u-m-b-40">
        <div class="columns">
            <opus-school-header [schoolDetails]="schoolDetails$ | async"></opus-school-header>
        </div>

        <div class="columns small-4">
			<aside class="card">
				<div class="card__section">
					<opus-history-snapshot contextName="School" [contextId]="(schoolDetails$ | async).id"></opus-history-snapshot>
                </div>
            </aside>
        </div>
    </header>
    <nav class="row u-m-b-40">
        <div class="columns">
            <ul class="menu menu--anchors" role="menu">
                <li role="menuitem"><a routerLink="." fragment="programs">Programs</a></li>
                <li role="menuitem"><a routerLink="." fragment="teachers">Teachers</a></li>
                <li role="menuitem"><a routerLink="." fragment="events">Events</a></li>
            </ul>
        </div>
        <div class="columns">
            <ul class="menu menu--external-links" role="menu">
                <li role="menuitem" opusAuthorize="Preference.View"><a (click)="navigateToPreferences()">Preferences</a></li>
            </ul>
        </div>
    </nav>

    <div class="row">
        <div class="columns">
            <opus-property-block label="School Information" [isEditing]="(panels$ | async)['info'].editing" (toggleEditor)="onToggleEditor('info', $event)" policy="School.EnterEditSchoolInfo" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-info viewer [school]="schoolDetails$ | async"></opus-school-info>
                <opus-school-info-editor editor *ngIf="(panels$ | async)['info'].editing"></opus-school-info-editor>
            </opus-property-block>

            <opus-property-block label="Physical Address" [isEditing]="(panels$ | async)['physAddr'].editing" (toggleEditor)="onToggleEditor('physAddr', $event)" policy="School.EditPhysicalAddress">
                <opus-school-address viewer [address]="(schoolDetails$ | async).physicalAddress" isPhysical="true"></opus-school-address>
                <opus-school-address-editor editor *ngIf="(panels$ | async)['physAddr'].editing" [isPhysical]="true" [school]="schoolDetails$ | async"></opus-school-address-editor>
            </opus-property-block>

            <opus-property-block label="Mailing Address" [isEditing]="(panels$ | async)['mailAddr'].editing" (toggleEditor)="onToggleEditor('mailAddr', $event)" policy="School.EditMailingAddress">
                <opus-school-address viewer [address]="(schoolDetails$ | async).mailingAddress"></opus-school-address>
                <opus-school-address-editor editor *ngIf="(panels$ | async)['mailAddr'].editing" [isPhysical]="false" [school]="schoolDetails$ | async"></opus-school-address-editor>
            </opus-property-block>

            <opus-property-block label="Contacts" [isEditing]="(panels$ | async)['contacts'].editing" (toggleEditor)="onToggleEditor('contacts', $event)" policy="School.EditContactsPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-contacts viewer [school]="schoolDetails$ | async"></opus-school-contacts>
                <opus-school-contacts-editor editor *ngIf="(panels$ | async)['contacts'].editing"></opus-school-contacts-editor>
            </opus-property-block>
        </div>
        <div class="columns">
            <opus-property-block label="Current Rate" [isEditing]="(panels$ | async)['currentRate'].editing" (toggleEditor)="onToggleEditor('currentRate', $event)" policy="School.EditCurrentRatePanel">
                <opus-school-rate viewer [rateAgreement]="(schoolDetails$ | async).currentRateAgreement" [nodeLevel]="(schoolDetails$ | async).ratePackageNodeLevel"></opus-school-rate>
                <opus-school-rate-editor editor *ngIf="(panels$ | async)['currentRate'].editing" panelId="currentRate"></opus-school-rate-editor>
            </opus-property-block>

            <opus-property-block label="Future Rate" [isEditing]="(panels$ | async)['futureRate'].editing" (toggleEditor)="onToggleEditor('futureRate', $event)" policy="School.EditFutureRatePanel">
                <opus-school-rate viewer [rateAgreement]="(schoolDetails$ | async).futureRateAgreement" [nodeLevel]="(schoolDetails$ | async).futureRatePackageNodeLevel"></opus-school-rate>
                <opus-school-rate-editor editor *ngIf="(panels$ | async)['futureRate'].editing" panelId="futureRate"></opus-school-rate-editor>
            </opus-property-block>

            <opus-property-block label="School Calendar" [isEditing]="(panels$ | async)['calendar'].editing" (toggleEditor)="onToggleEditor('calendar', $event)" policy="School.EditSchoolCalendar" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-calendar viewer [school]="schoolDetails$ | async"></opus-school-calendar>
                <opus-school-calendar-editor editor *ngIf="(panels$ | async)['calendar'].editing"></opus-school-calendar-editor>
            </opus-property-block>

            <opus-property-block label="Relationship Manager" [isEditing]="(panels$ | async)['relationship'].editing" (toggleEditor)="onToggleEditor('relationship', $event)" policy="School.EnterEditRelationshipManager" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-relationship viewer [school]="schoolDetails$ | async"></opus-school-relationship>
                <opus-school-relationship-editor editor *ngIf="(panels$ | async)['relationship'].editing"></opus-school-relationship-editor>
            </opus-property-block>
        </div>
    </div>
    <div class="row">
        <div class="columns">
            <opus-property-block label="Online Rentals" [isEditing]="(panels$ | async)['onlineRentals'].editing" (toggleEditor)="onToggleEditor('onlineRentals', $event)" policy="School.EnterEditOLRPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-online-rentals viewer [school]="schoolDetails$ | async"></opus-school-online-rentals>
                <opus-school-online-rentals-editor editor *ngIf="(panels$ | async)['onlineRentals'].editing"></opus-school-online-rentals-editor>
            </opus-property-block>
        </div>
        <div class="columns">
            <opus-property-block label="Rental Returns" [isEditing]="(panels$ | async)['rentalReturns'].editing" (toggleEditor)="onToggleEditor('rentalReturns', $event)" policy="School.EditRentalReturnPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-rental-returns viewer [school]="schoolDetails$ | async"></opus-school-rental-returns>
                <opus-school-rental-returns-editor editor *ngIf="(panels$ | async)['rentalReturns'].editing"></opus-school-rental-returns-editor>
            </opus-property-block>
        </div>
        <div class="columns">
            <opus-property-block label="Additional Details" [isEditing]="(panels$ | async)['additionalDetails'].editing" (toggleEditor)="onToggleEditor('additionalDetails', $event)" policy="School.EnterEditAdditionalDetailsPanel" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(schoolDetails$ | async).relationshipManagerEmail">
                <opus-school-additional-details viewer [school]="schoolDetails$ | async"></opus-school-additional-details>
                <opus-school-additional-details-editor editor *ngIf="(panels$ | async)['additionalDetails'].editing"></opus-school-additional-details-editor>
            </opus-property-block>
        </div>
    </div>
    <opus-program-searchresults-page id="programs" [school]="schoolDetails$ | async" opusAuthorize="School.ViewAssociatedPrograms"></opus-program-searchresults-page>
    <br />
    <opus-teacher-searchresults-page id="teachers" [school]="schoolDetails$ | async" opusAuthorize="School.ViewAssociatedTeachers"></opus-teacher-searchresults-page>
    <br />
    <opus-event-searchresults-page id="events" [school]="schoolDetails$ | async" opusAuthorize="School.ViewAssociatedEvents"></opus-event-searchresults-page>
    <div class="full-page-spinner" [class.full-page-spinner--is-active]="this.navigatingToPreferences">
        <div class="full-page-spinner__icon"></div>
    </div>
</div>