import { createSelector } from '@ngrx/store';

import { IUserAuthInfo } from '../../models';
import * as fromFeature from '../reducers';
import * as fromSecurity from '../reducers/security.reducer';

export const getSecurityState = createSelector(
    fromFeature.getCoreState,
    (state: fromFeature.CoreState) => state.security
);

export const getRolePolicies = createSelector(
    getSecurityState,
    fromSecurity.getRolePolicies,
);

export const getUserData = createSelector(
    getSecurityState,
    fromSecurity.getUserData,
);

export const getUserAuthInfo = createSelector(
    getUserData,
    getRolePolicies,
    (userData, rolePolicies): IUserAuthInfo => {
        if (!userData) return null;
        if (!rolePolicies) return null;

        const roleNames = new Set<string>(Array.isArray(userData.role) ? userData.role : [userData.role]);

        return {
            email: userData.email,
            emailLower: userData.email.toLowerCase(),
            roleNames,
            policyCodes: rolePolicies.reduce((set, x) => {
                if (roleNames.has(x.roleName)) set.add(x.policyCode);
                return set;
            }, new Set<string>())
        };

    }
);
