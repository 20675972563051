import { Component, Input } from '@angular/core';

import { Event, EventQuality } from '../../models';
import { DeliveryTimePeriodType } from '../../../scp-common/models';

@Component({
    selector: 'event-meeting-details',
    templateUrl: './event-meeting-details.component.html',
})
export class EventMeetingDetailsComponent {
    @Input() event: Event;

    get deliveryTimePeriod() {
        if (this.event.deliveryTimePeriod >= 0)
            return DeliveryTimePeriodType[this.event.deliveryTimePeriod];
        return 'None';
    }

    get eventQuality() {
        return EventQuality[this.event.eventQuality];
    }
}
