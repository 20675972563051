import { LocationsEffects } from './locations.effects';
import { TaxonomyEffects } from './taxonomy.effects';
import { HistoryEffects } from './history.effects';

export const effects: any[] = [
	LocationsEffects,
	TaxonomyEffects,
	HistoryEffects,
];

export * from './locations.effects';
export * from './taxonomy.effects';
export * from './history.effects';
