import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CoreModule } from '../../core/core.module';

import { reducers, effects } from './store';

import { YesNoPipe } from './pipes/yes-no.pipe';
import * as fromServices from './services';
import * as fromGuards from './guards';
import * as fromComponents from './components';
import * as fromContainers from './containers';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        RouterModule,
        /**
         * StoreModule.forFeature is used for composing state
         * from feature modules. These modules can be loaded
         * eagerly or lazily and will be dynamically added to
         * the existing state.
         */
        StoreModule.forFeature('scp', reducers),

        /**
         * Effects.forFeature is used to register effects
         * from feature modules. Effects can be loaded
         * eagerly or lazily and will be started immediately.
         *
         * All Effects will only be instantiated once regardless of
         * whether they are registered once or multiple times.
         */
        EffectsModule.forFeature(effects)
    ],
    declarations: [...fromComponents.components, ...fromContainers.containers, YesNoPipe],
    exports: [...fromComponents.components, ...fromContainers.containers, YesNoPipe],
    providers: [...fromServices.services, ...fromGuards.guards]
})
export class ScpCommonModule { }
