import { detailsComponents } from './event-details';
import { EventCreateComponent } from './event-create';

export const components: any[] = [
    ...detailsComponents,
    EventCreateComponent
];

export * from './event-details';
export * from './event-create';
