import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AsyncValidatorFn, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { takeWhile, tap, map, filter, take } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromStore from '../store';

import { District, InactiveReason, DistrictLookup } from '../models';

declare var $: any;
declare var Foundation: any;

@Component({
    selector: 'opus-district-info-editor',
    templateUrl: './district-info-editor.component.html',
})
export class DistrictInfoEditorComponent implements OnInit, OnDestroy/*, AfterViewInit*/ {
    @ViewChild('districtDropdown', {static: true})
    districtDropdown: ElementRef;
    districtFoundationDropdown: any;

    alive = true;
    editor: FormGroup;
    district$: Observable<District>;
    district: District;
    panelId = fromStore.PanelId.Info;
    panelState$: Observable<fromStore.PanelState>;
    panelState: fromStore.PanelState;
    districtLookups$: Observable<fromStore.DistrictLookupsState>;
    lookupId: number;
    districtByNameLookup$: Observable<fromStore.DistrictByNameLookupState>;
    districtByNameLookup: fromStore.DistrictByNameLookupState;
    lookupDistrictName: string;

    constructor(private store: Store<fromStore.DistrictsState>, private fb: FormBuilder, private cdr: ChangeDetectorRef) {
        console.log('DistrictInfoEditorComponent()...');
    }

    ngOnInit() {
        console.log('DistrictInfoEditorComponent.ngOnInit()...');

        this.district$ = this.store.pipe(select(fromStore.getDetailsStateDistrict));
        this.district$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(d => {
            this.district = d;
            this.lookupId = d.supervisoryDistrictId;
            //this.districtName = s.districtName;
        });

        this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateInfoPanel));
        this.panelState$.pipe(
            takeWhile(() => this.alive),
            tap(ps => {
                if (!ps.editing && this.panelState && this.panelState.editing) {
                    console.log('DistrictInfoEditorComponent.panelState$: clearing form...');
                    this.resetForm();
                }
            }),
        ).subscribe(s => this.panelState = s);

        this.createForm();

        this.districtLookups$ = this.store.pipe(select(fromStore.getDistrictLookupsState));

        this.districtByNameLookup$ = this.store.pipe(select(fromStore.getDistrictByNameLookupState));
        this.districtByNameLookup$.pipe(
            takeWhile(() => this.alive)
        ).subscribe(districtByNameLookup => {
            this.districtByNameLookup = districtByNameLookup;

            if (districtByNameLookup && districtByNameLookup.districts && districtByNameLookup.name == this.lookupDistrictName) {
                //Trigger the drop down
                if (!this.districtFoundationDropdown) {
                    this.districtFoundationDropdown = new Foundation.Dropdown($(this.districtDropdown.nativeElement).first());
                }

                const native = $(this.districtDropdown.nativeElement).first();

                if (!native.hasClass('is-open')) {
                    native.foundation('open');
                }

                this.cdr.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        console.log('DistrictInfoEditorComponent.ngOnDestroy()...');
        this.alive = false;
    }

    createForm() {
        this.editor = this.fb.group({
            isActive: [this.isActive ? 'Yes' : 'No', null, [this.isActiveAsyncValidator()]],
            inactiveReason: [this.district.inactiveReason == null ? 0 : this.district.inactiveReason],
            longName: [this.longName, Validators.required],
            shortName: [this.shortName],
            isSupervisory: [this.isSupervisory ? 'Yes' : 'No', { asyncValidators: [this.isSupervisoryAsyncValidator()] }],
            supervisoryDistrictId: [this.supervisoryDistrictId, { asyncValidators: [this.supervisoryIdAsyncValidator()], updateOn: 'blur' }],
            supervisoryDistrictName: [this.supervisoryDistrictName],
            primaryId: [this.primaryId, { updateOn: 'blur', asyncValidators: [this.primaryIdValidator()] }]
        }, { validator: [this.formValidator()] });
    }

    resetForm() {
        this.editor.reset({
            isActive: this.district.isActive ? 'Yes' : 'No',
            inactiveReason: this.district.inactiveReason == null ? 0 : this.district.inactiveReason,
            longName: this.district.longName,
            shortName: this.district.shortName,
            isSupervisory: this.district.isSupervisory ? 'Yes' : 'No',
            supervisoryDistrictId: this.district.supervisoryDistrictId,
            supervisoryDistrictName: this.district.supervisoryDistrictName,
            primaryId: this.district.primaryId
        });
    }

    get id(): string {
        return this.district.id.toString();
    }

    get isActive(): boolean {
        return this.district.isActive;
    }

    get inactiveReason(): string {
        return InactiveReason[this.district.inactiveReason];
    }

    get longName(): string {
        return this.district.longName;
    }

    get shortName(): string {
        return this.district.shortName;
    }

    get isSupervisory(): boolean {
        return this.district.isSupervisory;
    }

    get supervisoryDistrictId(): string {
        return this.district.supervisoryDistrictId ? this.district.supervisoryDistrictId.toString() : '';
    }

    get supervisoryDistrictName(): string {
        return this.district.supervisoryDistrictName;
    }

    get primaryId(): number {
        return this.district.primaryId;
    }

    get canSave(): boolean {
        const ret = this.editor.valid && !this.primaryIdStatus.pending && !this.hasPrimaryIdErrors;
        console.log(`canSave(): ${this.editor.valid} && ${this.editor.dirty} && !${this.primaryIdStatus.pending} && !${this.hasPrimaryIdErrors}`, this.editor);

        return ret;
    }

    get saveButtonLabel(): string {
        return this.panelState.updating ? 'Saving...' : 'Save';
    }

    get primaryIdStatus() {
        return this.panelState.validationStatus['primaryId'] || {
            pending: false,
            errors: null,
        };
    }

    get primaryIdErrors(): ValidationErrors {
        return this.primaryIdStatus.errors;
    }

    get primaryIdPending(): boolean {
        return this.primaryIdStatus.pending;
    }

    get hasPrimaryIdErrors(): boolean {
        return this.primaryIdErrors != null;
    }

    get allowEditPrimaryId(): boolean {
        //if (!this.isActive && this.district.inactiveReason === InactiveReason.Duplicate) {
        //    return true;
        //}

        if (this.editor.value && this.editor.value.isActive && this.editor.value.inactiveReason) {
            return this.editor.value.isActive === 'No' && parseInt(this.editor.value.inactiveReason) === InactiveReason.Duplicate;
        }

        return false;
    }

    lookupDistrictByName($event: any) {
        $event.stopPropagation();
        $event.preventDefault();

        this.lookupDistrictName = this.editor.value.supervisoryDistrictName;

        if ($event.code == 'Escape' || $event.code == 'Tab') {
            const native = $(this.districtDropdown.nativeElement).first();

            if (native.hasClass('is-open')) {
                native.foundation('close');
            }
        } else {
            if (this.lookupDistrictName && this.lookupDistrictName.length > 3) {
                this.store.dispatch(new fromStore.LookupDistrictByName({ name: this.lookupDistrictName, isActive: this.editor.value.isActive == 'Yes', isSupervisory: true }));
            }
        }
    }

    selectDistrict(district: DistrictLookup) {
        if (district) {
            this.editor.controls.supervisoryDistrictName.patchValue(district.longName);
            this.editor.controls.supervisoryDistrictId.patchValue(district.id);
        }
    }

    onSubmit() {
        console.log('onSubmit: ', this.editor.status, this.editor.value);

        const isActive = this.editor.value.isActive == 'Yes';
        const primaryId = this.editor.value.primaryId;

        const district: District = {
            ...this.district,
            isActive: isActive,
            inactiveReason: isActive ? null : this.editor.value.inactiveReason,
            shortName: this.editor.value.shortName,
            longName: this.editor.value.longName,
            isSupervisory: this.editor.value.isSupervisory == 'Yes',
            supervisoryDistrictId: this.editor.value.supervisoryDistrictId,
            primaryId
        };

        this.store.dispatch(new fromStore.UpdateDistrictPanel({ panel: this.panelId, district }));
    }

    isActiveAsyncValidator(): AsyncValidatorFn {
        const validator = 'canDeactivate';

        return (control: AbstractControl): Observable<ValidationErrors> | null => {
            console.log('isActiveAsyncValidator(): ', control);

            if (!control.dirty || control.value == 'Yes') {
                return of(null);
            }

            //	if the control value changed at all, we always need to dispatch an action in order to at least clear previous validation errors
            this.store.dispatch(new fromStore.ValidateDistrictCanDeactivate({ panel: this.panelId, validator, id: this.district.id, isSupervisory: this.isSupervisory }));

            //	the result of the validation is an observable of the validation status in the state of our panel
            return this.panelState$.pipe(
                //	wait for the status to complete
                filter(ps => {
                    const status = ps.validationStatus[validator];

                    return status != null && !status.pending;
                }),
                tap(ps => {
                    console.log('isActiveAsyncValidator(): panelState: ', ps);
                }),
                //	once that validation has completed, the errors property will either be a ValidationErrors object or null - exactly what we need to return
                map(ps => {
                    console.log('isActiveAsyncValidator(): ', ps.validationStatus[validator].errors);

                    return ps.validationStatus[validator].errors;
                }),
                take(1),
            );
        };
    }

    primaryIdValidator(): AsyncValidatorFn {
        const validator = 'primaryId';

        return (control: AbstractControl): Observable<ValidationErrors> | null => {
            //	NOTE: this is getting called several times during page load... i see no way to mitigate this except to not take action unless actually needed
            console.log('primaryIdValidator(): ', control);

            if (!control.dirty) {
                return of(null);
            }

            //	if the control value changed at all, we always need to dispatch an action in order to at least clear previous validation errors
            this.store.dispatch(new fromStore.ValidateDistrictPrimaryId({ panel: this.panelId, validator, primaryId: control.value, duplicateId: this.district.id, duplicateIsActive: this.district.isActive, duplicateIsSupervisory: this.district.isSupervisory }));

            //	the result of the validation is an observable of the validation status in the state of our panel
            return this.panelState$.pipe(
                //	wait for the status to complete
                filter(ps => {
                    const status = ps.validationStatus[validator];

                    return status != null && !status.pending;
                }),
                tap(ps => {
                    console.log('primaryIdValidator(): panelState: ', ps);
                }),
                //	once that validation has completed, the errors property will either be a ValidationErrors object or null - exactly what we need to return
                map(ps => {
                    return ps.validationStatus[validator].errors;
                }),
                take(1)
            );
        };
    }

    formValidator(): ValidatorFn {
        return (fg: FormGroup): ValidationErrors | null => {
            //console.log('formValidator(): ', fg);

            if (fg.value.isSupervisory == 'Yes' && fg.value.supervisoryDistrictId) {
                console.log('formValidator(): ', fg.value.supervisoryDistrictId);

                return { hasSupervisoryDistrict: true };
            }

            return null;
        };
    }

    isSupervisoryAsyncValidator(): AsyncValidatorFn {
        const validator = 'isSupervisory';

        return (control: AbstractControl): Observable<ValidationErrors> | null => {
            console.log('isSupervisoryAsyncValidator(): ', control);

            //	if the control value changed at all, we always need to dispatch an action in order to at least clear previous validation errors
            if (!control.dirty || (control.value == 'Yes') == this.district.isSupervisory) {
                return of(null);
            }

            this.store.dispatch(new fromStore.ValidateDistrictIsSupervisory({ panel: this.panelId, validator, id: this.district.id, isSupervisory: this.district.isSupervisory }));

            //	the result of the validation is an observable of the validation status in the state of our panel
            return this.panelState$.pipe(
                //	wait for the status to complete
                filter(ps => {
                    const status = ps.validationStatus[validator];

                    return status != null && !status.pending;
                }),
                tap(ps => {
                    console.log('isSupervisoryAsyncValidator(): panelState: ', ps);
                }),
                //	once that validation has completed, the errors property will either be a ValidationErrors object or null - exactly what we need to return
                map(ps => {
                    console.log('isSupervisoryAsyncValidator(): ', ps.validationStatus[validator].errors);

                    return ps.validationStatus[validator].errors;
                }),
                take(1),
            );
        };
    }

    supervisoryIdAsyncValidator(): AsyncValidatorFn {
        const validator = 'supervisoryId';

        return (control: AbstractControl): Observable<ValidationErrors> | null => {
            //console.log('supervisoryIdAsyncValidator(): ', control);

            if (!control.dirty || +control.value == this.lookupId) {
                console.log('supervisoryIdAsyncValidator(): Value unchanged...');

                return of(null);
            }

            if (isNaN(+control.value) || +control.value < 0) {
                console.log('supervisoryIdAsyncValidator(): Value not a number or not positive...');

                return of({ invalidId: true });
            }

            this.lookupId = +control.value;

            if (!this.lookupId) {
                console.log('supervisoryIdAsyncValidator(): Value cleared...');

                this.lookupDistrictName = null;
                this.editor.controls.supervisoryDistrictName.patchValue('');

                return of(null);
            }

            if (this.lookupId == this.district.supervisoryDistrictId) {
                console.log('supervisoryIdAsyncValidator(): Value equal to model value...');

                this.lookupDistrictName = this.district.supervisoryDistrictName;
                this.editor.controls.supervisoryDistrictName.patchValue(this.district.supervisoryDistrictName);

                return of(null);
            }

            if (this.lookupId == this.district.id) {
                console.log('supervisoryIdAsyncValidator(): Supervisory District Id equals current District Id...');

                return of({ districtId: true });
            }

            console.log(`supervisoryIdAsyncValidator(): Validating value of ${this.lookupId}...`);
            this.store.dispatch(new fromStore.LookupDistrictById({ id: this.lookupId }));

            return this.districtLookups$.pipe(
                tap(ls => {
                    if (ls.idLookups[this.lookupId].pending) {
                        this.lookupDistrictName = null;
                    }
                }),
                filter(ls => {
                    console.log('filter: ', this.lookupId, ls);

                    return !ls.idLookups[this.lookupId].pending;
                }),
                map(ls => {
                    const lookup = ls.idLookups[this.lookupId];
                    const error = lookup.errors;
                    const district = lookup.district;

                    console.log('map: ', this.lookupId, error);

                    this.cdr.markForCheck();

                    if (error || !district) {
                        return { notFound: true };
                    }

                    if (!district.isSupervisory) {
                        return { notSupervisory: true };
                    }

                    if (this.editor.value.isActive == 'Yes' && !district.isActive) {
                        return { supervisoryInactive: true };
                    }

                    this.lookupDistrictName = lookup.district.longName;
                    this.editor.controls.supervisoryDistrictName.patchValue(lookup.district.longName);

                    return null;
                }),
                take(1),
            );
        };
    }

    onApplySupervisoryDistrictId() {
        console.log('onApplySupervisoryDistrictId(): ', this.editor.value.supervisoryDistrictId);

        //this.editor.controls.supervisoryDistrictId.updateValueAndValidity();
    }
}
