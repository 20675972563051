import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ConfigurationService } from '../../../core/services/ConfigurationService';
import { ConfigurationState } from '../../../core/store/reducers';
import { switchMap } from 'rxjs/operators';
import { Operation } from 'fast-json-patch';

import { SchoolDetails, RateAgreement, SchoolSearchResultsItem, SchoolLookup, NewSchool } from '../../schools/models';

@Injectable()
export class SchoolService {
    constructor(private http: HttpClient, private configuration: ConfigurationService) { }

	loadSchool(schoolId: number): Observable<SchoolDetails> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<SchoolDetails>(`${c.config.apiServer}api/schools/${schoolId}`);
            })
        );
	}

    lookupSchoolById(schoolId: number): Observable<SchoolLookup> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<SchoolLookup>(`${c.config.apiServer}api/schools/lookup/${schoolId}`);
            })
        );
    }

    lookupSchoolByName(schoolName: string): Observable<SchoolLookup[]> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<SchoolLookup[]>(`${c.config.apiServer}api/schools/search/lookup/${schoolName}`);
            })
        );
    }

	patchSchool(id: number, patch: Operation[]): Observable<SchoolDetails> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.patch<SchoolDetails>(`${c.config.apiServer}api/schools/${id}`, patch);
			})
		);
	}

	updateSchool(id: number, diff: Operation[]): Observable<SchoolDetails> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.patch<SchoolDetails>(`${c.config.apiServer}api/schools/${id}`, diff);
            })
        );
	}

	getRateAgreement(code: string): Observable<RateAgreement> {
        return this.configuration.config$.pipe(
            switchMap((c: ConfigurationState) => {
                return this.http.get<RateAgreement>(`${c.config.apiServer}api/rateagreement/code/${code}`);
            })
        );
    }

	searchSchools(query: string): Observable<HttpResponse<SchoolSearchResultsItem[]>> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<SchoolSearchResultsItem[]>(`${c.config.apiServer}api/schools?${query}`, { observe: 'response' });
			})
		);
	}

	createSchool(school: NewSchool): Observable<number> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.post<number>(`${c.config.apiServer}api/schools`, school);
			})
		);
	}

	exists(id: number): Observable<boolean> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<boolean>(`${c.config.apiServer}api/schools/${id}/exists`);
			})
		);
	}

	canDeactivate(id: number): Observable<boolean> {
		return this.configuration.config$.pipe(
			switchMap((c: ConfigurationState) => {
				return this.http.get<boolean>(`${c.config.apiServer}api/schools/${id}/candeactivate`);
			})
		);
	}
}
