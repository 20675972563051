import { ProgramDetailsEffects } from './program-details.effect';
import { ProgramSearchResultsEffects } from './program-searchresults.effect';
import { ProgramCreateEffects } from './program-create.effects';

export const effects: any[] = [
	ProgramSearchResultsEffects,
	ProgramDetailsEffects,
	ProgramCreateEffects,
];

export * from './program-details.effect';
export * from './program-searchresults.effect';
export * from './program-create.effects';
