<ol class="history__sidebar__list">
	<li *ngFor="let event of events; let isOdd = odd"
		class="history__sidebar__list-item history__sidebar__list-item--{{ event.eventType }}"
		[ngClass]="{
			'history__sidebar__list-item--selected': selectedEvent && selectedEvent.id == event.id,
			'history__sidebar__list-item--alt': isOdd
		}">
		<button class="history__sidebar__btn" type="button" (click)="onSelectEvent(event)">
			<div class="history__sidebar__summary">{{ event.displayName }} {{ event.summary }}</div>
			<div class="history__sidebar__tag">{{ event.tag }}</div>
			<div class="history__sidebar__date">{{ event.timestamp | date:'shortDate' }}</div>
		</button>
	</li>
</ol>
<!--
<ol class="history__sidebar__list">
	<li class="history__sidebar__list-item history__sidebar__list-item--{{ item.type }}"
		ng-class="{
			'history__sidebar__list-item--selected': $ctrl.selectedItem.id == item.id,
			'history__sidebar__list-item--alt': $odd
		}"
		ng-repeat="item in $ctrl.historyItems | orderBy:'timestamp':true">
		<button class="history__sidebar__btn" type="button" ng-click="$ctrl.selectItem(item)">
			<div class="history__sidebar__summary">{{ item.user.displayName }} {{ item.summary }}</div>
			<div class="history__sidebar__tag">{{ item.tag }}</div>
			<div class="history__sidebar__date">{{ item.timestamp | date:'shortDate' }}</div>
		</button>
	</li>
</ol>
-->
