<!-- edit mode -->
<form [formGroup]="editor" (ngSubmit)="onSubmit()" aria-label="Event Information" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editHostSchoolId" class="property-block__label">Host School ID</label>
            <div class="property-block__value">
                <div class="input-group u-m-b-0">
                    <input type="text" name="hostSchoolId" id="editHostSchoolId" class="input-group-field" formControlName="hostSchoolId">
                    <div class="input-group-button">
                        <button type="button" (click)="lookupSchool()" [disabled]="editor.get('hostSchoolId').invalid">Apply</button>
                    </div>
                </div>
                <div class="error" *ngIf="editor.get('hostSchoolId').invalid && (editor.get('hostSchoolId').dirty || editor.get('hostSchoolId').touched)">
                    <div class="error__message" *ngIf="editor.get('hostSchoolId').hasError('required')">Required</div>
                    <div class="error__message" *ngIf="editor.get('hostSchoolId').hasError('min')">ID Must be a number</div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editHostSchoolName" class="property-block__label">Host School Name</label>
            <div class="property-block__value">
                <span data-toggle="hostSchoolTypeAhead" ><input type="text" id="editHostSchoolName" autocomplete="off" (keyup)="lookupSchoolByName($event)" (keydown)="$event.stopPropagation()" formControlName="hostSchoolName" role="combobox"></span>
                <ul class="dropdown-menu" role="listbox" id="hostSchoolTypeAhead" #schoolDropdown data-close-on-click="true">
                    <li *ngFor="let school of schoolByNameLookup?.schools">
                        <button type="button" class="dropdown-menu__action" role="option" data-close (click)="selectSchool(school)">
                            <span class="l-block">{{ school.longName }}</span>
                            <span class="l-block">{{ school.county }}</span> 
                            <span class="l-block">{{ school.city }}, {{ school.state}}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editHostTeacherId" class="property-block__label">Host Teacher ID</label>
            <div class="property-block__value">
                <div class="input-group u-m-b-0">
                    <input type="text" name="hostTeacherId" id="editHostTeacherId" class="input-group-field" formControlName="hostTeacherId">
                    <div class="input-group-button">
                        <button type="button" (click)="lookupTeacher()" [disabled]="editor.get('hostTeacherId').invalid">Apply</button>
                    </div>
                </div>
                <div class="error" *ngIf="editor.get('hostTeacherId').invalid && (editor.get('hostTeacherId').dirty || editor.get('hostTeacherId').touched)">
                    <div class="error__message" *ngIf="editor.get('hostTeacherId').hasError('required')">Required</div>
                    <div class="error__message" *ngIf="editor.get('hostTeacherId').hasError('min')">ID Must be a number</div>

                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editHostTeacherName" class="property-block__label">Host Teacher Name</label>
            <div class="property-block__value">
                <span data-toggle="hostTeacherTypeAhead"><input type="text" id="editHostTeacherName" autocomplete="off" (keyup)="lookupTeacherByCriteria($event)" (keydown)="$event.stopPropagation()" formControlName="hostTeacherName" role="combobox"></span>
                <ul class="dropdown-menu" role="menu" id="hostTeacherTypeAhead" #teacherDropdown data-close-on-click="true">
                    <li role="menu-item" *ngFor="let teacher of teacherByCriteriaLookup?.teachers">
                        <button type="button" class="dropdown-menu__action" data-close (click)="selectTeacher(teacher)">
                            <span class="l-block">{{ teacher.name }}</span>
                            <span class="l-block">{{ teacher.districtName }}</span> 
                            <span class="l-block">{{ teacher.county }}</span> 
                            <span class="l-block">{{ teacher.city}}, {{ teacher.state}}</span> 
                        </button>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">Save</button>
        </div>
    </div>
</form>
