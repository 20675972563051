<form name="teacherContactForm" aria-label="Additional Teacher Details" [formGroup]="editor" (ngSubmit)="onSubmit()" class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editPhone" class="property-block__label">MTD ID</label>
            <div class="property-block__value">
                <input type="text" id="mtdId" formControlName="externalSystemId">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editBio" class="property-block__label">Biography</label>
            <div class="property-block__value">
                <textarea id="editBio" rows="3" formControlName="bio"></textarea>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEducation" class="property-block__label">Education</label>
            <div class="property-block__value">
                <textarea id="editEducation" rows="3" formControlName="education"></textarea>
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editInstruments" class="property-block__label">Instruments</label>
            <div class="property-block__value">
                <textarea id="editInstruments" rows="3" formControlName="instruments"></textarea>
            </div> 
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
        </div>
    </div>
</form>