<opus-modal [visible]="visible" (action)="onModalAction($event)" title="Create New Event" primaryLabel="Create Event" secondaryLabel="Cancel" showClose="true">
    <div *ngIf="visible">
        <form name="createEventForm" #createEventForm [formGroup]="editor" novalidate (ngSubmit)="onSubmit()">
            <label for="ceEventName">Event Name</label>
            <input id="ceEventName" type="text" formControlName="eventName" placeholder="Event Name" aria-label="Event Name" required maxlength="100">
            <div class="error error--adjust-margin ng-hide" ng-show="createEventForm.name.$invalid &amp;&amp; (createEventForm.name.$touched || createEventForm.$submitted)">
                <div class="error__message" *ngIf="editor.get('eventName').invalid && (editor.get('eventName').dirty || submitted)">Required</div>
            </div>

            <div class="row">
                <div class="columns">
                    <label for="ceEventType">Event Type</label>
                    <select name="type" formControlName="eventType" (change)="selectEventType()">
                        <option value="" selected="selected">Select</option>
                        <option label="Rental Event" value="1">Rental Event</option>
                        <option label="Step Up Event" value="2">Step Up Event</option>
                        <option label="Instrument Fitting" value="3">Instrument Fitting</option>
                        <option label="Instrumental Demonstration" value="4">Instrumental Demonstration</option>
                    </select>
                    <div class="error error--adjust-margin">
                        <div class="error__message" *ngIf="editor.get('eventType').invalid && (editor.get('eventType').dirty || submitted)">Required</div>
                    </div>
                </div>
                <div class="columns">
                    <label for="eventDate">Event Date</label>
                    <input id="eventDate" type="text" placeholder="Event Date" class="form-control" bsDatepicker [bsConfig]="bsConfig" [maxDate]="maxDate" aria-label="Event Date" title="Event Date" formControlName="eventDate">
                    <div class="error error--adjust-margin" *ngIf="editor.get('eventDate').invalid && (editor.get('eventDate').dirty || editor.get('eventDate').touched)">
                        <div class="error__message" *ngIf="editor.get('eventDate').hasError('validCurrentDate')">Date must be today or later</div>
                    </div>
                </div>
            </div>

            <div *ngIf="this.editor.value.eventType == 1">
                <div class="row">
                    <div class="columns small-6">
                        <label for="eventQuality">Event Quality</label>
                        <select id="eventQuality" required formControlName="eventQuality">
                            <option value="0">Exclusive</option>
                            <option value="1">Competitive</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="columns">
                        <div class="error error--adjust-margin">
                            <div class="error__message" *ngIf="editor.get('eventQuality').invalid && (editor.get('eventQuality').dirty || submitted)">Required</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="columns">
                        <label for="ceMeetingType">Attendance Type</label>
                        <select id="ceMeetingType" required formControlName="rentalMeetingType">
                            <option value="">Select</option>
                            <option *ngFor="let et of rentalMeetingTypes;" [ngValue]="et.id">{{et.name}}</option>
                        </select>
                        <div class="error error--adjust-margin">
                            <div class="error__message" *ngIf="editor.get('rentalMeetingType').invalid && (editor.get('rentalMeetingType').dirty || submitted)">Required</div>
                        </div>
                    </div>
                    <div class="columns">
                        <label for="ceFulfillmentType">Rental Fulfillment Type</label>
                        <select id="ceFulfillmentType" required formControlName="rentalFulfillmentType">
                            <option value="">Select</option>
                            <option *ngFor="let et of rentalFulfillmentTypes;" [ngValue]="et.id">{{et.name}}</option>
                        </select>
                        <div class="error error--adjust-margin">
                            <div class="error__message" *ngIf="editor.get('rentalFulfillmentType').invalid && (editor.get('rentalFulfillmentType').dirty || submitted)">Required</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ngIf: $ctrl.event.type == 1 -->
            <fieldset>
                <legend class="sr-only">Host School &amp; Teacher</legend>
                <div class="row">
                    <div class="columns">
                        <label for="ceShoolId">Host School ID</label>
                        <div class="input-group">
                            <input type="text" id="ceShoolId" placeholder="Enter School ID" formControlName="hostSchoolId" class="input-group-field" value="34178" required="">
                            <div class="input-group-button">
                                <button type="button" (click)="lookupSchool()">Apply</button>
                            </div>
                        </div>
                        <div class="error ng-hide" *ngIf="editor.get('hostSchoolId').invalid && (editor.get('hostSchoolId').dirty || submitted)">
                            <div class="error__message">A host school is required</div>
                        </div>
                    </div>
                    <div class="columns">
                        <label for="ceSchoolName">Host School Name</label>

                        <div>
                            <span data-toggle="hostSchoolTypeAhead">
                                <input type="text" id="editHostSchoolName" autocomplete="off" (keyup)="lookupSchoolByName($event)" (keydown)="$event.stopPropagation()" formControlName="hostSchoolName" role="combobox">
                            </span>
                            <ul class="dropdown-menu" role="listbox" id="hostSchoolTypeAhead" #schoolDropdown data-v-offset="-16">
                                <li *ngFor="let school of schoolByNameLookup?.schools">
                                    <button type="button" class="dropdown-menu__action" role="option" data-close="hostSchoolTypeAhead" (click)="selectSchool(school)">
                                        <span class="l-block">{{ school.longName }}</span>
                                        <span class="l-block">{{ school.county }}</span>
                                        <span class="l-block">{{ school.city }}, {{ school.state}}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <!--<input type="text" id="ceSchoolName" aria-describedby="editHostSchoolNameDescription" autocomplete="off" data-toggle="ceHostSchoolTypeAhead" value="Ballenger Creek Elementary School" required="" aria-controls="ceHostSchoolTypeAhead" data-is-focus="false" data-yeti-box="ceHostSchoolTypeAhead" aria-haspopup="true" aria-expanded="false">-->
                        <p class="typography--caption u-m-b-0" id="editHostSchoolNameDescription">
                            Start typing the name of the school or search for the ID.
                        </p>
                    </div>
                </div>
                <div class="row u-p-t-8">
                    <div class="columns">
                        <label for="ceTeacherId">Host Teacher ID</label>
                        <div class="input-group">
                            <input type="text" id="ceTeacherId" formControlName="hostTeacherId" class="input-group-field" placeholder="Enter Teacher ID" aria-label="Host teacher ID" required="">
                            <div class="input-group-button">
                                <button type="button" (click)="lookupTeacher()">Apply</button>
                            </div>
                        </div>
                        <div class="error ng-hide" *ngIf="editor.get('hostTeacherId').invalid && (editor.get('hostTeacherId').dirty || submitted)">
                            <div class="error__message">A host teacher is required</div>
                        </div>
                    </div>
                    <div class="columns">
                        <label for="editHostTeacherName">Host Teacher Name</label>

                        <div>
                            <span data-toggle="hostTeacherTypeAhead">
                                <input type="text" id="editHostTeacherName" autocomplete="off" (keyup)="lookupTeacherByCriteria($event)" (keydown)="$event.stopPropagation()" formControlName="hostTeacherName" role="combobox" />
                            </span>
                            <ul class="dropdown-menu" role="menu" id="hostTeacherTypeAhead" #teacherDropdown data-v-offset="-16">
                                <li role="menu-item" *ngFor="let teacher of teacherByCriteriaLookup?.teachers">
                                    <button type="button" class="dropdown-menu__action" data-close="hostTeacherTypeAhead" (click)="selectTeacher(teacher)">
                                        <span class="l-block">{{ teacher.name }}</span>
                                        <span class="l-block">{{ teacher.districtName }}</span>
                                        <span class="l-block">{{ teacher.county }}</span>
                                        <span class="l-block">{{ teacher.city}}, {{ teacher.state}}</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <!--<input type="text" id="editHostTeacherName" aria-describedby="editHostTeacherNameDescription" autocomplete="off" data-toggle="ceHostTeacherTypeAhead" required="" aria-controls="ceHostTeacherTypeAhead" data-is-focus="false" data-yeti-box="ceHostTeacherTypeAhead" aria-haspopup="true" aria-expanded="false" />-->

                        <p class="typography--caption u-m-b-0" id="editHostTeacherNameDescription">
                            Start typing the name of the teacher or search for the ID.
                        </p>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</opus-modal>
<div class="full-page-spinner" [class.full-page-spinner--is-active]="this.submitting">
    <div class="full-page-spinner__icon"></div>
</div>
