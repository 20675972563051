<div class="entity-detail">
    <nav class="u-m-b-16">
        <ul class="breadcrumbs">
            <li><a routerLink="/search/schools">Schools</a></li>
            <li><a routerLink="/schools/{{ (programDetails$ | async).school.id }}">{{ (programDetails$ | async).school.longName }}</a></li>
            <li><a routerLink="/schools/{{ (programDetails$ | async).school.id }}" fragment="programs">Programs</a></li>
            <li>{{ getProgramType((programDetails$ | async).programTypeId) }}</li>
        </ul>
    </nav>
    <header class="row u-m-b-40">
        <div class="columns">
            <opus-program-header [program]="programDetails$ | async"></opus-program-header>
        </div>

        <div class="columns small-4">
            <aside class="card">
                <div class="card__section">
                    <opus-history-snapshot contextName="Program" [contextId]="(programDetails$ | async).id"></opus-history-snapshot>
                </div>
            </aside>
        </div>
    </header>

    <div class="row u-m-b-40">
        <div class="columns">
            <opus-property-block label="Program Information" [isEditing]="(panels$ | async)['info'].editing" (toggleEditor)="onToggleEditor('info', $event)" policy="Program.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(programDetails$ | async).school.relationshipManagerEmail">
                <opus-program-info viewer [program]="programDetails$ | async"></opus-program-info>
                <opus-program-info-editor editor *ngIf="(panels$ | async)['info'].editing"></opus-program-info-editor>
            </opus-property-block>
            <opus-property-block label="Additional Details" [isEditing]="(panels$ | async)['additionalDetails'].editing" (toggleEditor)="onToggleEditor('additionalDetails', $event)" policy="Program.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(programDetails$ | async).school.relationshipManagerEmail">
                <opus-program-additional viewer [program]="programDetails$ | async"></opus-program-additional>
                <opus-program-additional-editor editor *ngIf="(panels$ | async)['additionalDetails'].editing"></opus-program-additional-editor>
            </opus-property-block>
        </div>
        <div class="columns">
            <opus-program-relationshipmanager viewer [program]="programDetails$ | async"></opus-program-relationshipmanager>

            <opus-property-block label="Program Teachers" [isEditing]="(panels$ | async)['programTeachers'].editing" (toggleEditor)="onToggleEditor('programTeachers', $event)" policy="Program.Edit" [opusRelationshipManagerRoles]="['aa_OPUS-MAA_LimitedEntityAdmin']" [opusRelationshipManagerEmail]="(programDetails$ | async).school.relationshipManagerEmail">
                <opus-program-program-teachers viewer [program]="programDetails$ | async"></opus-program-program-teachers>
                <opus-program-program-teachers-editor editor *ngIf="(panels$ | async)['programTeachers'].editing"></opus-program-program-teachers-editor>
            </opus-property-block>
        </div>
    </div>
</div>
