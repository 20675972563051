import { Component, Input } from '@angular/core';

import { District } from '../../models';
import { RelationshipType } from '../../../scp-common/models/relationship-type';

@Component({
	selector: 'opus-district-relationship',
	templateUrl: './district-relationship.component.html',
})
export class DistrictRelationshipComponent {
	@Input() district: District;

	get relationshipType() {
		return RelationshipType[this.district.relationshipType.relationshipType];
  }

  get inheritedRelationshipType() {
    return RelationshipType[this.district.inheritedRelationshipType.relationshipType];
  }
}
