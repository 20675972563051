import { SchoolType } from '../../schools/models';
import { Address } from '../../events/models/index';

export enum InactiveReason {
    Inactive = 0,
    Duplicate,
    Pending
}

export interface TeacherCreate {
    prefix: number;
    firstName: string;
    lastName: string;
    suffix: number;
    districtId: number;
    schoolType: number;
    zip: string;
    email: string;
}

export interface TeacherDetails {
    id: number;
    name: string;
    active: boolean;
    gender: string;
    schoolType: SchoolType;
    inactiveReason: InactiveReason;
    duplicateTeacherId: number;

    prefix: number;
    firstName: string;
    lastName: string;
    suffix: number;

    districtId: number;
    districtName: string;

    mailingAddressId: number;
    mailingAddress: Address;

    phone: string;
    fax: string;
    email: string;

    externalSystemId: string;
    bio: string;
    education: string;
    instruments: string;
}
