import { Component, Input } from '@angular/core';

import { TeacherDetails, InactiveReason } from '../../models';
import { SchoolType, SchoolTypeLookup } from '../../../schools/models';

@Component({
	selector: 'opus-teacher-info',
	templateUrl: './teacher-info.component.html',
})
export class TeacherInfoComponent {
	@Input() teacher: TeacherDetails;

    get active() {
        return this.teacher.active ? 'Yes' : 'No';
    }

    get activeBit() {
        return this.teacher.active;
    }

    get inactiveReasonIsDuplicate() {
        return this.teacher.inactiveReason == InactiveReason.Duplicate;
    }

    get id() {
        return this.teacher.id;
    }

    get name() {
        return this.teacher.name;
    }

    get gender() {
        const value = this.teacher.gender.toLowerCase();
        if (value == 'm') {
            return 'Male';
        }
        else if (value == 'f') {
            return 'Female';
        }
        else {
            return 'N/A';
        }
    }

	get schoolType() {
		return this.teacher.schoolType ? SchoolTypeLookup[this.teacher.schoolType].name : '';
    }

    get duplicateTeacherId() {
        return this.teacher.duplicateTeacherId;
    }

    get inactiveReason() {
        if (this.teacher.inactiveReason == null) {
            return null;
        }

        return InactiveReason[this.teacher.inactiveReason];
    }

    get inactiveReasonId() {
        return this.teacher.inactiveReason;
    }
}
