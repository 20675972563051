import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { tap, switchMap, map, catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as SchoolSearchResultsActions from '../actions/school-searchresults.action';
import * as fromServices from '../../../scp-common/services';
import * as fromModels from '../../models';
import * as fromReducers from '../reducers';
import { SearchResultsState } from '..';

@Injectable()
export class SchoolSearchResultsEffects {
    constructor(
        private actions$: Actions,
        private SchoolService: fromServices.SchoolService,
        private store$: Store<SearchResultsState>,
    ) { }

    
    processQueryString$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.ProcessQueryString),
        switchMap((action: SchoolSearchResultsActions.ProcessQueryString) => {
            // Grab an object representation of the query string
            const query = this.parseQueryStringFromUrl(action.payload);
            // Convert query object into our strict-typed object
            let searchCriteria: fromModels.SchoolSearchCriteria;

            // Populate search criteria with the query object params
            searchCriteria = {
                callDay: query['callDay'],
                code: query['code'],
                county: query['county'],
                districtId: query['DistrictId'],
                districtName: query['districtName'],
                gradeLevel: query['gradeLevel'],
                id: query['id'],
                isActive: query['isActive'],
                longName: query['longName'],
                offersBandshare: query['offersBandshare'],
                PageNumber: query['PageNumber'],
                PageSize: query['PageSize'],
                phone: query['phone'],
                primaryFulfillmentSite: query['primaryFulfillmentSite'],
                radius: query['radius'],
                schoolType: query['schoolType'],
                SortBy: query['SortBy'],
                SortDir: query['SortDir'],
                state: query['state'],
                teacherId: query['TeacherId'],
                zip: query['zip'],
            };
            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(searchCriteria));
        })
    ));

    
    getResultsForTeacher$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.GetResultsForTeacher),
        switchMap((action: SchoolSearchResultsActions.GetResultsForTeacher) => {
            console.log(action.payload);

            // Whatever state we have, reset it to initial
            const newCriteria: fromModels.SchoolSearchCriteria = {
                ...fromReducers.initialState.searchCriteria,
                // Use the teacher as our criteria
                teacherId: action.payload.id
            };

            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newCriteria));
        })
    ));

    
    getResultsForDistrict$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.GetResultsForDistrict),
        switchMap((action: SchoolSearchResultsActions.GetResultsForDistrict) => {
            console.log(action.payload);

            // Whatever state we have, reset it to initial
            const newCriteria: fromModels.SchoolSearchCriteria = {
                ...fromReducers.initialState.searchCriteria,
                // Use the district as our criteria
                districtId: action.payload.id
            };

            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newCriteria));
        })
    ));

    
    nextPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.NextPage),
        switchMap((action: SchoolSearchResultsActions.NextPage) => {
            console.log(action.pagingSorting);
            const currentPage: number = action.pagingSorting.currentPage;
            let nextPage: number;

            // If page number is null, maybe we never got first page?
            if (currentPage == null) {
                // Go to the first one
                nextPage = 1;
            }
            else if (currentPage < action.pagingSorting.totalPages) {
                // Increment to next page
                nextPage = currentPage + 1;
            }
            else {
                // Don't change the page
                nextPage = currentPage;
            }

            // Set the next page to pass to our search request
            const newPayload: fromModels.SchoolSearchCriteria = {
                ...action.payload,
                PageNumber: nextPage
            };
            // TODO: Figure out some way to not do any action here if page number didn't change
            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newPayload));
        })
    ));

    
    prevPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.PrevPage),
        switchMap((action: SchoolSearchResultsActions.PrevPage) => {
            console.log(action.pagingSorting);
            const currentPage: number = action.pagingSorting.currentPage;
            let prevPage: number;

            // If page number is null, maybe we never got first page?
            if (currentPage == null) {
                // Go to the first one
                prevPage = 1;
            }
            else if (currentPage > 1) {
                // Increment to next page
                prevPage = currentPage - 1;
            }
            else {
                // Don't change the page
                prevPage = currentPage;
            }

            // Set the next page to pass to our search request
            const newPayload: fromModels.SchoolSearchCriteria = {
                ...action.payload,
                PageNumber: prevPage
            };
            // TODO: Figure out some way to not do any action here if page number didn't change
            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newPayload));
        })
    ));

    
    sortByResult$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.SortBy),
        switchMap((action: SchoolSearchResultsActions.SortBy) => {
            // Default to ascending sort order
            let sortDir = 0;

            // If user is sorting by same column again
            if (action.payload == action.pagingSorting.sortBy) {
                // Change the direction of the sort since it's the same column
                sortDir = action.pagingSorting.sortDir == 0 ? 1 : 0;
            }
            const lastPage: number = action.pagingSorting.totalPages;

            // Set the next page to pass to our search request
            const newPayload: fromModels.SchoolSearchCriteria = {
                ...action.searchCriteria,
                SortDir: sortDir,
                SortBy: action.payload
            };

            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newPayload));
        })
    ));

    
    lastPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.LastPage),
        switchMap((action: SchoolSearchResultsActions.LastPage) => {
            const lastPage: number = action.pagingSorting.totalPages;

            // Set the next page to pass to our search request
            const newPayload: fromModels.SchoolSearchCriteria = {
                ...action.payload,
                PageNumber: lastPage
            };

            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newPayload));
        })
    ));

    
    firstPageResult$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.FirstPage),
        switchMap((action: SchoolSearchResultsActions.FirstPage) => {
            const firstPage = 1;

            // Set the next page to pass to our search request
            const newPayload: fromModels.SchoolSearchCriteria = {
                ...action.payload,
                PageNumber: firstPage
            };

            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newPayload));
        })
    ));

    
    pageSizeResult$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.SetPageSize),
        switchMap((action: SchoolSearchResultsActions.SetPageSize) => {
            const pageSize: number = action.payload;

            // Set the next page to pass to our search request
            const newPayload: fromModels.SchoolSearchCriteria = {
                ...action.searchCriteria,
                PageSize: pageSize
            };

            return of(new SchoolSearchResultsActions.LoadSchoolSearchResults(newPayload));
        })
    ));

    
    searchResults$ = createEffect(() => this.actions$.pipe(
        ofType(SchoolSearchResultsActions.SchoolSearchResultsActionTypes.LoadSchoolSearchResults),
        switchMap((action: SchoolSearchResultsActions.LoadSchoolSearchResults) => {
            console.log('Load School Search Results Effect');
            console.log(action.payload);

            let query = '';

            Object.keys(action.payload).forEach(key => {
                const value = action.payload[key];
                if (value === undefined) {
                    // Any missing value we just ignore
                    return;
                }

                const valueAsString = String(value).trim();

                // If item has a value...
                if (value !== null && value !== '') {
                    // If it's one of these keys and value is "Any"
                    const anyKeys = ['state', 'isSupervisory', 'isActive', 'offersBandshare'];
                    if (anyKeys.some(i => i === key) && value == 'Any') {
                        // Treat "any" like null.
                        return;
                    }
                    // Else, add it to query string
                    else {
                        query += `&${key}=${value}`;
                    }
                }
            });

            return this.SchoolService.searchSchools(query).pipe(
                tap(d => {
                    console.log('searchSchools returned:', d);
                }),
                map(resp => {
                    if (!resp.ok || resp.body == null) {
                        return new SchoolSearchResultsActions.LoadSchoolSearchResultsFailure('Search results not returned');
                    }

                    return new SchoolSearchResultsActions.LoadSchoolSearchResultsSuccess(resp.body, resp.headers);
                }),
                catchError(error => of(new SchoolSearchResultsActions.LoadSchoolSearchResultsFailure(error)))
            );
        })
    ));

    private parseQueryStringFromUrl(url: string): object {
        const returnObject: object = {
        };

        // Parse query string into chunks
        const splitted = url.split('?', 2);
        // If we have a query string, then process it further
        if (splitted.length == 2) {
            // Split out each key/value pair
            const splittedItems = splitted[1].split('&');
            splittedItems.forEach(item => {
                // Split out the key and value
                const splittedItem = item.split('=');
                const key = splittedItem[0];
                const value = splittedItem.length > 1 ? splittedItem[1] : '';
                // Add is as a key/value pair in our return object.
                returnObject[key] = value;
            });
        }

        return returnObject;
    }
}
