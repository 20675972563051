export enum ProgramType {
    Choir = 1,
    Band,
	NoMTDSubscription,
    GeneralMusic,
    Orchestra
}

export const ProgramTypeLookup: { id: ProgramType, name: string }[] = [];

ProgramTypeLookup[ProgramType.Choir] = { id: ProgramType.Choir, name: 'Choir' };
ProgramTypeLookup[ProgramType.Band] = { id: ProgramType.Band, name: 'Band' };
ProgramTypeLookup[ProgramType.NoMTDSubscription] = { id: ProgramType.NoMTDSubscription, name: 'No MTD Subscription' };
ProgramTypeLookup[ProgramType.GeneralMusic] = { id: ProgramType.GeneralMusic, name: 'General Music' };
ProgramTypeLookup[ProgramType.Orchestra] = { id: ProgramType.Orchestra, name: 'Orchestra' };
