<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="onlineRental">Online Rental</div>
		<div class="property-block__value" role="definition" aria-labelledby="onlineRental">{{ school.onlineRental | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="shipToHome">Ship to Home</div>
		<div class="property-block__value" role="definition" aria-labelledby="shipToHome">{{ school.shipToHome | yesNo }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="schoolDelivery">School Delivery</div>
		<div class="property-block__value" role="definition" aria-labelledby="schoolDelivery">{{ schoolDelivery }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="exclusiveServiceAccountID">Exclusive Service Account ID</div>
		<div class="property-block__value" role="definition" aria-labelledby="exclusiveServiceAccountID">{{ school.exclusiveServiceAccountCode }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="exclusiveServiceAccountName">Exclusive Service Account Name</div>
		<div class="property-block__value" role="definition" aria-labelledby="exclusiveServiceAccountName">{{ school.exclusiveServiceAccountName }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="offersBandshare">Offers Bandshare</div>
		<div class="property-block__value" role="definition" aria-labelledby="offersBandshare">{{ school.offersBandshare | yesNo }}</div>
	</li>
</ul>
