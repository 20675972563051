import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromProgramDetails from './program-details.reducer';
import * as fromSearchResults from './searchresults.reducer';
import * as fromProgramCreate from './program-create.reducer';

export interface ProgramsState {
    programDetails: fromProgramDetails.ProgramDetailsState;
	search: fromSearchResults.SearchResultsState;
	create: fromProgramCreate.ProgramCreateState;
}

export const reducers: ActionReducerMap<ProgramsState> = {
    programDetails: fromProgramDetails.reducer,
	search: fromSearchResults.reducer,
	create: fromProgramCreate.reducer,
};

export const getProgramsState = createFeatureSelector<ProgramsState>('programs');

export { ProgramDetailsState, PanelId, PanelState, TeacherLookupState, TeacherByCriteriaLookupState} from './program-details.reducer';
export { SearchResultsState, initialState } from './searchresults.reducer';
export { ProgramCreateState } from './program-create.reducer';
