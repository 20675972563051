import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of, forkJoin } from 'rxjs';
import { tap, switchMap, map, catchError, filter, withLatestFrom, first, mergeMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Operation, compare } from 'fast-json-patch';

import * as fromServices from '../../../scp-common/services';
import * as detailsActions from '../actions/details.actions';
import * as fromSelectors from '../selectors';
import * as fromModels from '../../models';
import * as fromRoot from '../../../../core/store';
import { DetailsState, CountyLookupState, DistrictLookupsState } from '..';
import { MessageType, ProblemDetails } from '../../../../core/models';

@Injectable()
export class DistrictDetailsEffects {
    constructor(
        private actions$: Actions,
        private districtService: fromServices.DistrictService,
        private addressService: fromServices.AddressService,
        private store$: Store<DetailsState>,
    ) { }

    
    loadDistrict$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.LoadDistrict),
        switchMap((action: detailsActions.LoadDistrict) => {
            console.log('Load District Effect');

            return this.districtService.loadDistrict(action.payload).pipe(
                tap(d => {
                    console.log('loadDistrict returned:', d);
                }),
                map(district => {
                    if (district === null) {
                        return new detailsActions.LoadDistrictFailure('District Not Found');
                    }

                    return new detailsActions.LoadDistrictSuccess(district);
                }),
                catchError(error => of(new detailsActions.LoadDistrictFailure(error)))
            );
        })
    ));

    
    lookupDistrict$ = createEffect(() => this.actions$.pipe(
      ofType(detailsActions.DistrictActionTypes.LookupDistrictById),
        tap(a => console.log('Entering lookupDistrict$ Effect: ', a)),
        withLatestFrom(this.store$.pipe(select(fromSelectors.getDistrictLookupsState))),
        map(([action, dls]: [detailsActions.LookupDistrictById, DistrictLookupsState]) => {
            const { id } = action.payload;
            const dl = dls.idLookups[id] ? dls.idLookups[id].district : null;

            return { id, dl };
        }),
        switchMap(({ id, dl }: { id: number, dl: fromModels.DistrictLookup }) => {
            if (dl !== null) {
                console.log(`lookupDistrict$: district lookup with id ${id} already loaded.`);

                return of(new detailsActions.LookupDistrictByIdSuccess({ id, dl }));
            }

            console.log(`lookupDistrict$: Calling service to get district lookup for id ${id}...`);

            return this.districtService.lookupDistrictById(id).pipe(
                tap(res => console.log(`districtService.lookupDistrictById( ${id} ) returned: `, res)),
                map((dl: fromModels.DistrictLookup) => {
                    if (dl) {
                        return new detailsActions.LookupDistrictByIdSuccess({ id, dl });
                    }

                    return new detailsActions.LookupDistrictByIdFailure({ id, error: `There is no district with an ID of '${id}'.` });
                }),
                catchError(err => {
                    const error = `Unable to retrieve the District with ID: ${id}: ${err.message}.`;

                    return of(new detailsActions.LookupDistrictByIdFailure({ id, error }));
                }),
            );
        }),
        tap(a => console.log('Exiting lookupDistrict$ Effect: ', a)),
    ));

    
    districtLookupByName$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.LookupDistrictByName),
        tap(a => console.log('Entering districtLookupByName$ Effect: ', a)),
        switchMap((action: detailsActions.LookupDistrictByName) => {
            const { name, isActive, isSupervisory } = action.payload;

            console.log(`districtLookupByName$: Calling service to get district lookups for name ${name}...`);

            return this.districtService.lookupDistrictByName(name, isActive, isSupervisory).pipe(
                tap(res => console.log(`districtService.lookupDistrictByName( ${name} ) returned: `, res)),
                map(dls => {
                    if (dls === null) {
                        return new detailsActions.LookupDistrictByNameFailure({ name, error: 'Districts Not Found' });
                    }

                    return new detailsActions.LookupDistrictByNameSuccess({ name, dls });
                }),
                catchError(error => of(new detailsActions.LookupDistrictByNameFailure({ name, error }))),
            );
        }),
        tap(a => console.log('Exiting districtLookupByName$ Effect: ', a)),
    ));

    //	NOTE: seemed more appropriate to handle the json patch creation here than to send the 2 district objects into the service layer
    
    updateDistrictPanel$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.UpdateDistrictPanel),
        tap(a => {
            console.log('updateDistrictPanel$ Effect');
        }),
        //	get the current value of the district from the store
        withLatestFrom(this.store$.select(fromSelectors.getDetailsStateDistrict)),
        //	create a json patch by comparing the current district with the updated version
        //	typescript note: using array destructuring to pull the withLatestFrom output into individual parameters to map
        map(([action, district]: [detailsActions.UpdateDistrictPanel, fromModels.District]) => {
            const patch = compare(district, action.payload.district);

            console.log('new district:', action.payload.district, 'current district:', district, 'patch:', patch);

            //	the district service needs the id & the json patch
            return { panel: action.payload.panel, district, patch };
        }),
        switchMap(({ panel, district, patch }: { panel: string, district: fromModels.District, patch: Operation[] }) => {
            if (patch.length == 0) {
                console.log('updateDistrictPanel$ Effect: Skipping empty patch...');

                return of(new detailsActions.UpdateDistrictPanelSuccess({ panel, district }));
            }

            console.log('updateDistrictPanel$ Effect: Calling districtService.patchDistrict(', district.id, ', ', patch, ')...');

            return this.districtService.patchDistrict(district.id, patch).pipe(
                map(district => new detailsActions.UpdateDistrictPanelSuccess({ panel, district })),
                catchError(errors => {
                    return of(new detailsActions.UpdateDistrictPanelFailure({ panel, errors }));
                }),
            );
        }),
    ));

    
    updateDistrictPanelFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.UpdateDistrictPanelFailure),
        tap(a => {
            console.log('Entering updateDistrictPanelFailure$ Effect: ', a);
        }),
        map((a: detailsActions.UpdateDistrictPanelFailure) => {
            const httpErrorResponse = a.payload.errors;

            const message = this.getErrorMessage(httpErrorResponse) || 'Unhandled exception has occurred';
            return new fromRoot.DisplayMessage({
                message,
                messageType: MessageType.alert,
                toast: false,
            });
        }),
    ));

    validateDistrictPrimaryId$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.ValidateDistrictPrimaryId),
        tap(a => {
            console.log('Entering validateDistrictPrimaryId$ Effect: ', a);
        }),
        mergeMap((a: detailsActions.ValidateDistrictPrimaryId): any[] => {
            const { panel, validator, primaryId, duplicateId, duplicateIsActive, duplicateIsSupervisory } = a.payload;

            //	clearing the primary id is always valid & requires no other checks
            if (primaryId == '') {
                return [new detailsActions.DistrictPrimaryIdValidated({ panel, validator, errors: null })];
            }

            //	check for a blatantly incorrect primary id
            if (+primaryId < 1 || Number.isNaN(+primaryId)) {
                return [new detailsActions.DistrictPrimaryIdValidated({ panel, validator, errors: { primaryId: 'Primary ID must refer to a valid district.' } })];
            }

            if (+primaryId == duplicateId) {
                return [new detailsActions.DistrictPrimaryIdValidated({ panel, validator, errors: { primaryId: 'Primary ID cannot refer to this district.' } })];
            }

            const districtExistsAction = new detailsActions.ValidateDistrictExists({ panel, validator: 'districtExists', id: +primaryId });

            if (duplicateIsActive) {
                return [districtExistsAction, new detailsActions.ValidateDistrictCanDeactivate({ panel, validator: 'canDeactivate', id: duplicateId, isSupervisory: duplicateIsSupervisory })];
            }

            return [districtExistsAction];
        }),
        tap(a => {
            console.log('Exiting validateDistrictPrimaryId$ Effect: ', a);
        }),
    ));

    
    getDistrictPrimaryIdValidationResults$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.ValidateDistrictPrimaryId),
        tap(a => console.log('Entering getDistrictPrimaryIdValidationResults$ Effect: ', a)),
        //	Aggregator: gather the completion actions to complete the original, split, action
        switchMap((src: detailsActions.ValidateDistrictPrimaryId): Observable<detailsActions.DistrictActions[]> => {
          const districtExists$ = this.actions$.pipe(
                ofType(detailsActions.DistrictActionTypes.DistrictExistsValidated),
                tap(a => console.log('districtExists$: ', a)),
                filter((a: any): any => {
                    return src.payload.primaryId == a.payload.id;	//	correlate the actions via the primary id
                }),
                first(),
            );

            if (src.payload.duplicateIsActive) {
                const canDeactivate$ = this.actions$.pipe(
                    ofType(detailsActions.DistrictActionTypes.DistrictCanDeactivateValidated),
                    tap(a => console.log('canDeactivate$: ', a)),
                    filter((a: any): any => {
                        return src.payload.duplicateId == a.payload.id;	//	correlate the actions via the duplicate id
                    }),
                    first(),
                );

                return forkJoin([of(src), districtExists$, canDeactivate$]);
            }
            else {
                return forkJoin([of(src), districtExists$]);
            }
        }),
        //	map the parent & completed dependent actions to a completed parent action
        map(([primaryId, districtExists, canDeactivate]) => {

            let errors = null;
            if ((districtExists && districtExists.payload.errors) || (canDeactivate && canDeactivate.payload.errors)) {
                errors = { ...(districtExists ? districtExists.payload.errors : []), ...(canDeactivate ? canDeactivate.payload.errors : []) };
            }

            return new detailsActions.DistrictPrimaryIdValidated({ panel: primaryId.payload.panel, validator: primaryId.payload.validator, errors });
        }),
        tap(a => console.log('Exiting getDistrictPrimaryIdValidationResults$ Effect: ', a)),
    ));

    
    validateDistrictExists$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.ValidateDistrictExists),
        tap(a => console.log('Entering validateDistrictExists$ Effect: ', a)),
        switchMap((a: detailsActions.ValidateDistrictExists) => {
            const { panel, validator, id } = a.payload;

            return this.districtService.exists(id).pipe(
                tap(res => console.log(`districtService.exists( ${id} ) returned: `, res)),
                map(res => {
                    const errors = res
                        ? null
                        : { districtExists: `There is no District with ID: ${id}` };

                    return new detailsActions.DistrictExistsValidated({ panel, validator, id, errors });
                }),
                catchError(err => {
                    return of(new detailsActions.DistrictExistsValidated({ panel, validator, id, errors: { districtExists: err.message } }));
                }),
            );
        }),
        tap(a => console.log('Exiting validateDistrictExists$ Effect: ', a)),
    ));

    
    validateDistrictCanDeactivate$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.ValidateDistrictCanDeactivate),
        tap(a => console.log('Entering validateDistrictCanDeactivate$ Effect: ', a)),
        switchMap((a: detailsActions.ValidateDistrictCanDeactivate) => {
            const { panel, validator, id, isSupervisory } = a.payload;

            return this.districtService.canDeactivate(id, isSupervisory).pipe(
                tap(res => console.log(`districtService.canDeactivate( ${id}, ${isSupervisory} ) returned: `, res)),
                map(res => {
                    const errors = res
                        ? null
                        : { canDeactivate: `Associated entities must be disassociated or deactivated prior to deactivating a district: ${id}.` };

                    return new detailsActions.DistrictCanDeactivateValidated({ panel, validator, id, errors });
                }),
                catchError(err => {
                    return of(new detailsActions.DistrictCanDeactivateValidated({ panel, validator, id, errors: { canDeactivate: err.message } }));
                }),
            );
        }),
        tap(a => console.log('Exiting validateDistrictExists$ Effect: ', a)),
    ));

    
    validateDistrictIsSupervisory$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.ValidateDistrictIsSupervisory),
        tap(a => console.log('Entering validateDistrictIsSupervisory$ Effect: ', a)),
        switchMap((a: detailsActions.ValidateDistrictIsSupervisory) => {
            const { panel, validator, id, isSupervisory } = a.payload;

            return this.districtService.hasChildren(id, isSupervisory).pipe(
                tap(res => console.log(`districtService.hasChildren( ${id}, ${isSupervisory} ) returned: `, res)),
                map(res => {
                    const errors = res
                        ? (isSupervisory ? { hasDistricts: true } : { hasSchools: true })
                        : null;

                    return new detailsActions.DistrictIsSupervisoryValidated({ panel, validator, id, errors });
                }),
                catchError(err => {
                    return of(new detailsActions.DistrictIsSupervisoryValidated({ panel, validator, id, errors: { canDeactivate: err.message } }));
                }),
            );
        }),
        tap(a => console.log('Exiting validateDistrictIsSupervisory$ Effect: ', a)),
    ));

    
    lookupCityState$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.DistrictAddressLookupCityState),
        tap(a => console.log('Entering lookupCityState$ Effect: ', a)),
        switchMap((a: detailsActions.DistrictAddressLookupCityState) => {
            const { panel, zip } = a.payload;

            return this.addressService.lookupCityState(zip).pipe(
                tap(res => console.log(`addressService.lookupCityState( ${zip} ) returned: `, res)),
                map(cityState => {
                    return new detailsActions.DistrictAddressLookupCityStateSuccess({ panel, cityState });
                }),
                catchError(errors => {
                    return of(new detailsActions.DistrictAddressLookupCityStateFailure({ panel, errors }));
                }),
            );
        }),
        tap(a => console.log('Exiting lookupCityState$ Effect: ', a)),
    ));

    
    verifyAddress$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.DistrictVerifyAddress),
        tap(a => console.log('Entering verifyAddress$ Effect: ', a)),
        switchMap((a: detailsActions.DistrictVerifyAddress) => {
            const { panel, address } = a.payload;

            return this.addressService.verifyAddress(address).pipe(
                tap(res => console.log(`addressService.verifyAddress( ${address} ) returned: `, res)),
                map(verification => {
                    return new detailsActions.DistrictVerifyAddressSuccess({ panel, verification });
                }),
                catchError(errors => {
                    return of(new detailsActions.DistrictVerifyAddressFailure({ panel }));
                }),
            );
        }),
        tap(a => console.log('Exiting verifyAddress$ Effect: ', a)),
    ));

    
    loadCounties$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.DistrictLoadCounties),
        tap(a => console.log('Entering loadCounties$ Effect: ', a)),
        //	pull in the county lookup state
        withLatestFrom(this.store$.select(fromSelectors.getDetailsStateCountyLookup)),
        //	grab the county list for the request state code
        map(([action, countyLookup]: [detailsActions.DistrictLoadCounties, CountyLookupState]) => {
            const counties = countyLookup.counties[action.payload.stateCode];

            return { action, counties };
        }),
        switchMap(({ action, counties }: { action: detailsActions.DistrictLoadCounties, counties: string[] }) => {
            const { panel, stateCode } = action.payload;

            //	skip service call if we already have a county list for this state
            if (counties) {
                console.log(`loadCounties$: county list for ${action.payload.stateCode} already loaded.`);

                return of(new detailsActions.DistrictLoadCountiesSuccess({ panel, counties }));
            }

            return this.addressService.loadCounties(stateCode).pipe(
                tap(res => console.log(`addressService.loadCounties( ${stateCode} ) returned: `, res)),
                map((counties: string[]) => {
                    return new detailsActions.DistrictLoadCountiesSuccess({ panel, counties });
                }),
            );
        }),
        tap(a => console.log('Exiting loadCounties$ Effect: ', a)),
    ));

    
    createDistrict$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.CreateDistrict),
        switchMap((action: detailsActions.CreateDistrict) => {
            console.log('CreateDistrict Effect');

            return this.districtService.createDistrict(action.payload.district).pipe(
                tap(d => {
                    console.log('createDistrict$ returned:', d);
                }),
                map(createDistrictResult => {
                    if (createDistrictResult === null) {
                        return new detailsActions.CreateDistrictFailure({ errors: 'Error creating district' });
                    }

                    return new detailsActions.CreateDistrictSuccess({ id: createDistrictResult });
                }),
                catchError(error => of(new detailsActions.CreateDistrictFailure({ errors: error })))
            );
        })
    ));

    
    createDistrictFailure$ = createEffect(() => this.actions$.pipe(
        ofType(detailsActions.DistrictActionTypes.CreateDistrictFailure),
        tap(a => console.log('Entering createDistrictFailure$ Effect: ', a)),
        switchMap((action: detailsActions.CreateDistrictFailure) => {
            return of(new fromRoot.DisplayMessage({
                message: this.getErrorMessage(action.payload.errors),
                messageType: MessageType.alert,
                toast: false,
            }));
        }),
        tap(a => console.log('Exiting createDistrictFailure$ Effect: ', a)),
    ));

    private getErrorMessage(response: HttpErrorResponse): string {
        if (response.status === 400 && response.error) {
            try {
                const problemDetails = response.error as ProblemDetails;
                if (problemDetails) {
                    const firstError = problemDetails.errors ? problemDetails.errors[Object.keys(problemDetails.errors)[0]] : null;
                    if (firstError)
                        return firstError;
                    else if (problemDetails.title)
                        return problemDetails.title;
                }
            }
            catch (ex) {
                console.warn(ex);
            }
        }

        return response.error ? response.error.message : response.message;
    }
}
