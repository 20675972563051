import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { ProgramDetails, InactiveReason } from '../models';
import { ProgramType, ProgramTypeLookup } from '../../scp-common/models';
import * as fromStore from '../store';

@Component({
	selector: 'opus-program-info-editor',
	templateUrl: './program-info-editor.component.html',
})
export class ProgramInfoEditorComponent implements OnInit, OnDestroy {
	alive = true;
	programDetails$: Observable<ProgramDetails>;
	panelState$: Observable<fromStore.PanelState>;
	program: ProgramDetails;
	panelState: fromStore.PanelState;
    editor: FormGroup;
    programStartFrom: Date;
    programStartTo: Date;
    months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    days: { [id: string]: string[] } = {};

	programTypes = ProgramTypeLookup.filter(ptl => ptl.id !== ProgramType.NoMTDSubscription);

	constructor(private store: Store<fromStore.ProgramsState>, private fb: FormBuilder) {
		console.log('ProgramInfoEditorComponent()...');
	}

	ngOnInit() {
		console.log('ProgramInfoEditorComponent.ngOnInit()...');

		this.programDetails$ = this.store.pipe(select(fromStore.getProgramDetailsEntity));
		this.programDetails$.pipe(
			takeWhile(() => this.alive)
		).subscribe(s => this.program = s);

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateInfoPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('ProgramInfoEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(s => this.panelState = s);

		this.createForm();
	}

	ngOnDestroy() {
		console.log('ProgramInfoEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

    createForm() {
        this.programStartFrom = this.program.startFrom
            ? new Date(Date.parse(this.program.startFrom))
            : new Date(9999, 8, 1);
        this.programStartTo = this.program.startTo
            ? new Date(Date.parse(this.program.startTo))
            : new Date(9999, 8, 1);

        this.editor = this.fb.group({
            active: [this.isActive],
            inactiveReason: [this.program.inactiveReason == null ? 0 : this.program.inactiveReason],
            programTypeId: [this.program.programTypeId],
            programStartFromMonth: [this.programStartFrom.getMonth()],
            programStartFromDay: [this.programStartFrom.getDate()],
            programStartToMonth: [this.programStartTo.getMonth()],
            programStartToDay: [this.programStartTo.getDate()],
        });

        this.initMonthChanges();
	}

	resetForm() {
		this.editor.reset({
            active: this.isActive,
            inactiveReason: this.program.inactiveReason == null ? 0 : this.program.inactiveReason,
            programTypeId: this.program.programTypeId,
            programStartFromMonth: this.programStartFrom.getMonth(),
            programStartFromDay: this.programStartFrom.getDate(),
            programStartToMonth: this.programStartTo.getMonth(),
            programStartToDay: this.programStartTo.getDate(),
		});
    }

    initMonthChanges() {
        const keys: string[] = ['programStartFrom', 'programStartTo'];

        for (const key of keys) {
            this.days[this[key].getMonth()] = this.getDaysInMonth(this[key].getMonth());

            this.editor.get(key + 'Month').valueChanges.pipe(
                takeWhile(() => this.alive),
            ).subscribe(val => {
                console.log(key + 'Month.valueChanges: ', val);

                if (!this.days[val]) {
                    this.days[val] = this.getDaysInMonth(val);
                }

                const ctrl = this.editor.get(key + 'Day');
                const lastDay = this.days[val][this.days[val].length - 1];

                if (+ctrl.value > +lastDay) {
                    ctrl.setValue(lastDay, { emitEvent: false });
                }
            });
        }
    }

    getDaysInMonth(month) {
        const days: string[] = [];

        for (let i = 1; i <= new Date(9999, +month + 1, 0).getDate(); i++) {
            days.push(i.toString());
        }

        return days;
    }

    get isActive() {
        return this.program.active ? 'Yes' : 'No';
    }

    get inactiveReason(): string {
        return InactiveReason[this.program.inactiveReason];
    }

	get canSave(): boolean {
        return this.editor.valid && this.editor.dirty || (this.editor.valid && this.editor.value.active == 'No' && this.program.inactiveReason == null);
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	onSubmit() {
        console.log('onSubmit: ', this.editor.status, this.editor.value);

        const programStartFrom = `${+this.editor.value.programStartFromMonth + 1}/${this.editor.value.programStartFromDay}/9999`;
        const programStartTo = `${+this.editor.value.programStartToMonth + 1}/${this.editor.value.programStartToDay}/9999`;
        const active = this.editor.value.active == 'Yes';

		const program: ProgramDetails = {
			...this.program,
            active: active,
            inactiveReason: active ? null : this.editor.value.inactiveReason,
            programTypeId: this.editor.value.programTypeId,
            startFrom: programStartFrom,
            startTo: programStartTo,
			//type: +this.editor.value.type,
		};

		console.log('onSubmit: ', program);

		this.store.dispatch(new fromStore.UpdateProgramPanel({ panel: fromStore.PanelId.Info, program }));
	}
}
