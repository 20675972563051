import { HistorySnapshotComponent } from './history-snapshot.component';
import { HistoryComponent } from './history.component';

export const containers: any[] = [
	HistorySnapshotComponent,
	HistoryComponent,
];

export * from './history-snapshot.component';
export * from './history.component';
