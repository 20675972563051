import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { LocationManager, RentalCategory } from '../../models';

export enum ScpActionTypes {
	GetLocation = '[School] Get Location',
	GetLocationSuccess = '[School] Get Location Success',
    GetLocationFailure = '[School] Get Location Failure',
    GetRentalCategories = '[RentalCategory] Get Rental Category',
    GetRentalCategoriesSuccess = '[RentalCategory] Get Rental Category Success',
    GetRentalCategoriesFailure = '[RentalCategory] Get Rental Category Failure',
    GetManagersByName = '[School] Get Managers By Name',
    GetManagersByNameSuccess = '[School] Get Managers By Name Success',
    GetManagersByNameFailure = '[School] Get Managers By Name Failure',
}

export class GetLocation implements Action {
	readonly type = ScpActionTypes.GetLocation;
	constructor(public payload: { code: number }) { }
}

export class GetLocationSuccess implements Action {
	readonly type = ScpActionTypes.GetLocationSuccess;
	constructor(public payload: { code: number, manager: LocationManager }) { }
}

export class GetLocationFailure implements Action {
	readonly type = ScpActionTypes.GetLocationFailure;
	constructor(public payload: { code: number, error: any }) { }
}

export class GetRentalCategories implements Action {
    readonly type = ScpActionTypes.GetRentalCategories;
    constructor() { }
}

export class GetRentalCategoriesSuccess implements Action {
    readonly type = ScpActionTypes.GetRentalCategoriesSuccess;
    constructor(public payload: { rentalCategories: RentalCategory[] }) { }
}

export class GetRentalCategoriesFailure implements Action {
    readonly type = ScpActionTypes.GetRentalCategoriesFailure;
    constructor(public payload: { error: HttpErrorResponse }) { }
}

export class GetManagersByName implements Action {
    readonly type = ScpActionTypes.GetManagersByName;
    constructor(public payload: { name: string }) { }
}

export class GetManagersByNameSuccess implements Action {
    readonly type = ScpActionTypes.GetManagersByNameSuccess;
    constructor(public payload: { managers: LocationManager[] }) { }
}

export class GetManagersByNameFailure implements Action {
    readonly type = ScpActionTypes.GetManagersByNameFailure;
    constructor(public payload: { errors: any }) { }
}


export type ScpActions =
	| GetLocation
	| GetLocationSuccess
    | GetLocationFailure
    | GetRentalCategories
    | GetRentalCategoriesSuccess
    | GetRentalCategoriesFailure
    | GetManagersByName
    | GetManagersByNameSuccess
    | GetManagersByNameFailure;
