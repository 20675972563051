<ul class="property-block__list">
	<li class="property-block__list-item">
		<div class="property-block__label" id="schoolStart">School Start</div>
		<div class="property-block__value" role="definition" aria-labelledby="schoolStart">{{ schoolStart }}</div>
	</li>
	<li class="property-block__list-item">
		<div class="property-block__label" id="schoolEnd">School End</div>
		<div class="property-block__value" role="definition" aria-labelledby="schoolEnd">{{ schoolEnd }}</div>
	</li>
</ul>
