<div class="search">
    <!--<form>-->
    <div class="search__search-box">
        <div class="search__input-wrapper">
            <input id="searchText" #searchText class="search__input" type="search" aria-label="Enter search terms" placeholder="Enter ID or Name">
        </div>
        <div class="search__button-wrapper">
            <button class="search__button" data-toggle="searchDropdown">Search for&hellip;</button>
            <ul class="dropdown-menu dropdown-menu--tiny" id="searchDropdown" #searchDropdown role="menu">
                <li role="menuitem"><button type="button" name="district" class="dropdown-menu__action" (click)="quickSearchClick('districts', 'longName', true)">District</button></li>
                <li role="menuitem"><button type="button" name="school" class="dropdown-menu__action" (click)="quickSearchClick('schools', 'longName', true)">School</button></li>
                <li role="menuitem"><button type="button" name="teacher" class="dropdown-menu__action" (click)="quickSearchClick('teachers', 'name', true)">Teacher</button></li>
                <li role="menuitem"><button type="button" name="event" class="dropdown-menu__action" (click)="quickSearchClick('events', 'name')">Event</button></li>
            </ul>
        </div>
    </div>
    <!--</form>-->
    <!--
    <div class="row">
        <div class="columns columns--tight">
            <a ui-sref="appRoot.advancedSearch" class="u-text--small link--on-dark">Advanced Search <span class="icon icon--forward"></span></a>
        </div>
    </div>
    -->
</div>