import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { takeWhile, tap, filter, first } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { Address } from '../../scp-common/models';
import { TeacherDetails } from '../models';
import * as fromStore from '../store';
import { states } from '../../scp-common/models/states';

@Component({
	selector: 'opus-teacher-address-editor',
	templateUrl: './teacher-address-editor.component.html',
})
export class TeacherAddressEditorComponent implements OnInit, OnDestroy, OnChanges {
	@Input() teacher: TeacherDetails;

    states: string[] = states;
	alive = true;
	panelState$: Observable<fromStore.AddressPanelState>;
	panelState: fromStore.AddressPanelState;
	zipLookup: fromStore.ZipLookupState;
	editor: FormGroup;
	showModal = false;
	enteredAddress: Address = null;
	suggestedAddress: Address = null;
	counties$: Observable<string[]>;

	constructor(private store: Store<fromStore.TeachersState>, private fb: FormBuilder) {
		console.log('TeacherAddressEditorComponent()...');

		this.createForm();
	}

	ngOnInit() {
		console.log('TeacherAddressEditorComponent.ngOnInit()');

		//	subscribe to state code changes
		this.initCounties();

		//	load up the counties for the current state
		const stateCode = this.address.state;
		if (stateCode != '') {
			this.store.dispatch(new fromStore.TeacherLoadCounties({ panel: this.panelId, stateCode }));
		}

		this.counties$ = this.store.pipe(select(fromStore.getDetailsStateCounties));

		this.panelState$ = this.store.pipe(select(fromStore.getDetailsStateMailAddrPanel));
		this.panelState$.pipe(
			takeWhile(() => this.alive),
			tap(s => {
				if (!s.editing && this.panelState && this.panelState.editing) {
					console.log('TeacherAddressEditorComponent.panelState$: clearing form...');
					this.resetForm();
				}
			}),
		).subscribe(ps => {
			console.log('panelState$ sub: ', ps);
			this.panelState = ps;
			this.zipLookup = ps.zipLookup;

			const zl = this.zipLookup;
			if (zl && zl.pending) {
				this.editor.controls.addressCity.disable();
				this.editor.controls.addressCounty.disable();
				this.editor.controls.addressState.disable();
				this.editor.controls.addressZipcode.disable();
			} else {
				this.editor.controls.addressCity.enable();
				this.editor.controls.addressCounty.enable();
				this.editor.controls.addressState.enable();
				this.editor.controls.addressZipcode.enable();
			}

			if (zl && zl.cityState != null) {
				this.editor.controls['addressCity'].patchValue(zl.cityState.city);
				this.editor.controls['addressState'].patchValue(zl.cityState.state);

				if (this.editor.controls.addressCity.value != this.address.city) {
					this.editor.controls.addressCity.markAsDirty();
				}

				if (this.editor.controls.addressState.value != this.address.state) {
					this.editor.controls.addressState.markAsDirty();
				}
			}
		});
	}

	ngOnDestroy() {
		console.log('TeacherAddressEditorComponent.ngOnDestroy()...');
		this.alive = false;
	}

	ngOnChanges() {
		this.resetForm();
	}

	createForm() {
		//	NOTE: FormBuilder.group will ignore the updateOn parameter when constructing the FormGroup
		this.editor = this.fb.group({
			addressLine1: [''],
			addressLine2: [''],
			addressCity: [''],
            addressState: [''],
            addressZipcode: ['', Validators.compose([Validators.pattern(/^\d{5}(?:-\d{4})?$/), Validators.required])],
			addressCounty: [''],
		}/* NOTE: originally tried to do address verification as an async validator, but angular was ignoring the supplied updateOn='submit' even when using FormGroup instead of FormBuilder */);
	}

	initCounties() {
		console.log('initCounties()...');

		this.editor.get('addressState').valueChanges.pipe(
			takeWhile(() => this.alive),
		).subscribe(stateCode => {
			console.log(`addressState.valueChanges: '${this.editor.value.addressState}' -> '${stateCode}'`);

			if (stateCode != '' && stateCode != this.editor.value.addressState) {
				//	if the state is changed, we need to clear out the county so that the county dropdown will show the select option
				//	if the state is changed back to the original state, however, set the county back to that of the original model
				this.editor.controls['addressCounty'].patchValue(stateCode == this.address.state ? this.address.county : '');

				this.store.dispatch(new fromStore.TeacherLoadCounties({ panel: this.panelId, stateCode }));
			}
		});
	}

	resetForm() {
		console.log('resetForm()...');

		const address: Address = this.address;
		this.editor.reset({
			addressLine1: address.line1,
			addressLine2: address.line2,
			addressCity: address.city,
			addressState: address.state,
			addressZipcode: address.zip,
			addressCounty: address.county || '',
		});
	}

	get panelId(): string {
		return fromStore.PanelId.Address;
	}

	get address(): Address {
		return this.teacher.mailingAddress;
	}

	get canSave(): boolean {
		return this.editor.dirty && this.editor.valid;
	}

	get saveButtonLabel(): string {
		return this.panelState.updating ? 'Saving...' : 'Save';
	}

	get inZipLookup(): boolean {
		return this.zipLookup != null && this.zipLookup.pending;
	}

	get hasZipLookupError(): boolean {
		const hasError = (this.editor.controls.addressZipcode.enabled && !this.editor.controls.addressZipcode.valid) || (this.zipLookup != null && this.zipLookup.errors != null);

		return hasError;
	}

	get disableZipLookup(): boolean {
		const zipCtrl = this.editor.controls.addressZipcode;

		return zipCtrl.disabled || !zipCtrl.valid || this.inZipLookup;
	}

	onApplyZip() {
		const zip = this.editor.value.addressZipcode;

		console.log('TeacherAddressEditorComponent.onApplyZipcode(): ', zip);
		this.store.dispatch(new fromStore.TeacherAddressLookupCityState({ panel: this.panelId, zip }));
	}

	onModalAction(accept) {
		console.log(accept);

		this.showModal = false;

		if (accept) {
			this.update();
		}
    }

    onModalTertiaryAction(accept) {
        console.log(accept);

        this.showModal = false;

        if (accept) {
            // Clear out the suggested address so that update() will use the entered one
            this.suggestedAddress = null;
            this.update();
        }
    }

	update() {
		console.log('updating...');

		//	suggested address will normally be the address to use unless qas was unable to find a match.  in this case, accepting the modal will use the entered address as is.
		const mailingAddress = this.suggestedAddress || this.enteredAddress;

		const teacher = {
			...this.teacher,
			mailingAddress,
		};

		console.log('update():', teacher);
		this.store.dispatch(new fromStore.UpdateTeacherPanel({ panel: this.panelId, teacher }));
	}

	onSubmit() {
		console.log('onSubmit: ', this.editor.status);

		//	Apparently we're going to have to explicitly invoke the address validator since angular refuses to honor updateOn: 'submit' at the form gropup level
		//	dispatch action to verify address
		const address: Address = {
			line1: this.editor.value.addressLine1,
			line2: this.editor.value.addressLine2,
			city: this.editor.value.addressCity,
			state: this.editor.value.addressState,
			zip: this.editor.value.addressZipcode,
			county: this.editor.value.addressCounty,
		};

		this.enteredAddress = address;

		this.store.dispatch(new fromStore.TeacherVerifyAddress({ panel: this.panelId, address }));

		//	observe results in store
		this.panelState$.pipe(
			tap(ps => console.log('onSubmit: ', ps)),
			filter(ps => !(ps as fromStore.AddressPanelState).verifying),
			first(),
			tap(ps => console.log('onSubmit: ', ps)),
		).subscribe(ps => {
			console.log('subscribe: ', ps);
			const verifiedAddress = (ps as fromStore.AddressPanelState).verifiedAddress;

            // If verified address didn't come back, make sure suggested stays null (so we don't use it later)
            this.suggestedAddress = verifiedAddress;
            if (this.suggestedAddress != null) {
                this.suggestedAddress = {
                    ...verifiedAddress,
                    county: this.editor.value.addressCounty,
                };
            }

			//	if verified address is not identical to entered, or we got a picklist instead, show modal
			if (verifiedAddress !== null && this.compareAddresses(address, verifiedAddress)) {
				this.update();

				return;
			}

			this.showModal = true;
		});
	}

	private compareAddresses(first: Address, second: Address): boolean {
		return first.line1 == second.line1
			&& first.line2 == second.line2
			&& first.city == second.city
			&& first.state == second.state
			&& first.zip == second.zip;
	}
}
