<form aria-label="Teacher Contact Information" [formGroup]="editor" (ngSubmit)="onSubmit()" class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>
    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editPhone" class="property-block__label">Phone</label>
            <div class="property-block__value">
                <input type="text" id="editPhone" formControlName="phone">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editFax" class="property-block__label">Fax</label>
            <div class="property-block__value">
                <input type="text" id="editFax" formControlName="fax">
            </div>
        </li>
        <li class="property-block__list-item">
            <label for="editEmail" class="property-block__label">Email Address</label>
            <div class="property-block__value">
                <input type="email" id="editEmail" formControlName="email">
                <!--<div class="error" [hidden]="this.editor.get('email').valid">
                    <div class="error__message">Invalid email</div>
                </div>-->
            </div>
        </li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
        </div>
    </div>
</form>