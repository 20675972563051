import { Component } from '@angular/core';

@Component({
    selector: 'app-home-component',
    templateUrl: './home.component.html'
})
export class HomeComponent {

    constructor() { }

}
