<form name="schoolInformationForm" aria-label="School Information" [formGroup]="editor" (ngSubmit)="onSubmit()" novalidate class="spinner-section" [class.spinner-section--is-active]="(panelState$ | async).updating">
    <div class="spinner-section__icon"></div>

    <ul class="property-block__list">
        <li class="property-block__list-item">
            <label for="editActive" class="property-block__label">Active</label>
            <div class="property-block__value">
                <select id="editActive" class="ng-pristine ng-untouched ng-valid ng-not-empty" formControlName="active">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item" *ngIf="editor.value.active == 'No' && editor.controls.active.valid">
            <label for="inactiveReason" class="property-block__label">Inactive Reason</label>
            <div class="property-block__value">
                <select id="inactiveReason" formControlName="inactiveReason">
                    <option value="0">Inactive</option>
                    <option value="1">Duplicate</option>
                    <option value="2">Pending</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="programId">Program ID</div>
            <div class="property-block__value ng-binding" role="definition" aria-labelledby="programId">{{ program.id }}</div>
        </li>
        <li class="property-block__list-item">
            <label for="editProgramType" class="property-block__label">Program Type</label>
            <div class="property-block__value">
                <select id="editProgramType" class="ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required" formControlName="programTypeId">
                    <option *ngFor="let programType of programTypes" [value]="programType.id">{{ programType.name }}</option>
                </select>
            </div>
        </li>
        <li class="property-block__list-item" role="group">
            <div id="editProgramStart" class="property-block__label">Program Start</div>
            <div class="property-block__value" role="group" aria-labelledby="editProgramStart">
                <div class="school-calendar">
                    <div class="school-calendar__group">
                        <div class="school-calendar__month">
                            <select aria-label="Month" formControlName="programStartFromMonth">
                                <option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
                            </select>
                        </div>
                        <div class="school-calendar__day">
                            <select aria-label="Day" formControlName="programStartFromDay">
                                <option *ngFor="let d of days[editor.value.programStartFromMonth]" [value]="d">{{ d }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="school-calendar__separator">through</div>
                    <div class="school-calendar__group">
                        <div class="school-calendar__month">
                            <select aria-label="Month" formControlName="programStartToMonth">
                                <option *ngFor="let m of months; let i = index" [value]="i">{{ m }}</option>
                            </select>
                        </div>
                        <div class="school-calendar__day">
                            <select aria-label="Day" formControlName="programStartToDay">
                                <option *ngFor="let d of days[editor.value.programStartToMonth]" [value]="d">{{ d }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="shortName">School ID</div>
            <div class="property-block__value" role="definition" aria-labelledby="shortName"><a [routerLink]="['/schools/' + program.school.id]">{{ program.school.id }}</a></div>
        </li>
        <li class="property-block__list-item">
            <div class="property-block__label" id="shortName">School Name</div>
            <div class="property-block__value" role="definition" aria-labelledby="shortName"><a [routerLink]="['/schools/' + program.school.id]">{{ program.school.longName }}</a></div>
        </li>
    </ul>

    <ul class="error" *ngIf="false">
        <li class="error__message" *ngIf="false">Server error</li>
    </ul>
    <div class="property-block__action-wrapper">
        <div class="property-block__action">
            <button type="submit" class="property-block__action-btn" [disabled]="!canSave">{{ saveButtonLabel }}</button>
        </div>
    </div>
</form>
