import { Injectable } from '@angular/core';
import { OidcSecurityService} from 'angular-auth-oidc-client';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { OidcConfigLoaded, OidcActionTypes, OidcAuthorizationResult, SetUserData } from '../actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SecurityState } from '../reducers';

@Injectable()
export class OidcEffects {

  constructor(private actions$: Actions, 
    private oidcSecurityService: OidcSecurityService, 
    private store: Store<SecurityState>
    ) { }

  
  oidcConfigurationLoaded$ = createEffect(() => this.actions$.pipe(
    ofType<OidcConfigLoaded>(OidcActionTypes.ConfigLoaded),
    tap(() => {
      console.log('Config loaded effect entered');
      this.oidcSecurityService.checkAuth().subscribe((isAuthenticated: boolean) => {
        this.store.dispatch(new OidcAuthorizationResult(isAuthenticated));
        if(!isAuthenticated)
          this.oidcSecurityService.authorize();
      });
    })
  ), { dispatch: false });

  
  oidcAuthorizationResult$ = createEffect(() => this.actions$.pipe(
    ofType<OidcAuthorizationResult>(OidcActionTypes.AuthorizationResult),
    map(result => result),
    distinctUntilChanged(),
    switchMap(isAuthenticated =>
      isAuthenticated
        ? this.oidcSecurityService.userData$
        : of(null)
    ),
    map(userData => new SetUserData(userData))
  ));
}
